import { Trans, useTranslation } from "react-i18next";
import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import {
  BooleanFilter,
  CreatedByFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  TablePageSkeleton,
} from "components/ui";
import { RiAddLine, RiEye2Fill } from "react-icons/ri";
import getColumns from "helpers/getColumns";
import {
  PolicyAssetNode,
  useGenerateSplitCertificateMutation,
  useGetAllCertificateTemplatesByNameLazyQuery,
  useGetAllPolicyAssetsByPolicyTypeQuery,
} from "graphql/queries/generated/queries";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { useNavigate } from "react-router-dom";
import { handleMutation } from "middleware/Toaster";
import parseAmount from "helpers/parseAmount";
import { SPIN_ROUTES } from "routes/constants";

const CUSTOM_FIELDS = [
  "asset",
  "asset.title",
  "asset.author",
  "asset.creationPeriod",
  "asset.createdBy",
  "coveredRiskType",
  "evaluationType",
  "coverType",
  "image",
  "lenderAddress",
  "deliveryAddress",
  "pickupAddress",
  "policy",
  "policy.offerCode",
  "policy.policyCode",
  "insuredValue",
  "asset.actualDateAndPeriod",
  "objectLocationEntity",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Category",
    Footer: "",
    accessor: "asset.category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 101,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "asset.title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 99,
  },
  {
    Header: "Image",
    Footer: "",
    accessor: "asset.mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : "",
    disableFilters: true,
    weight: 102,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "asset.author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 100,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "asset.actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Institution",
    Footer: "",
    accessor: "objectLocationEntity.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Country",
    Footer: "",
    accessor: "",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div />,
    disableFilters: true,
    weight: 97,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    weight: 96,
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyAssetNode } };
    }) => {
      const { insuredValue } = original;
      return parseAmount(insuredValue);
    },
  },
  {
    Header: "Dimensions",
    Footer: "",
    accessor: "asset.genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 95,
  },
  {
    Header: "Technique",
    Footer: "",
    accessor: "asset.technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 95,
  },
  {
    Header: "Inventory #",
    Footer: "",
    accessor: "asset.inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 95,
  },
  {
    Header: "",
    Footer: "",
    accessor: "asset.validated",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    Filter: BooleanFilter,
    weight: 0,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "asset.createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];
const QUICK_ACTIONS = [
  {
    name: "Add new policy",
    href: SPIN_ROUTES.POLICY_PC_NEW,
    icon: <RiAddLine />,
  },
];

const PcPoliciesObjectsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetAllPolicyAssetsByPolicyTypeQuery({
    variables: {
      policy_PolicyType: "PERMANENT_COLLECTION",
    },
  });

  const toast = useToast();

  const [getAllCertificateTemplatesByName] = useGetAllCertificateTemplatesByNameLazyQuery({
    variables: {
      name: "inventory_template_1",
    },
  });
  const [
    generateSplitCertificate,
    {
      data: generateSplitCertificateData,
      error: generateSplitCertificateError,
      loading: generateSplitCertificateLoading,
    },
  ] = useGenerateSplitCertificateMutation({ ...handleMutation("Splitted certificate generated!") });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;
  if (!data || !data.allPolicyAssets || data.allPolicyAssets.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicyAssets: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onDelete = async (id: string) => {
    // await toggleIsActiveUser({
    //   variables: {
    //     input: {
    //       id,
    //     },
    //   },
    // });
    // await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Generate splitted certificates</Trans>,
      action: async (selectedRows: { original: { id: string } }[]) => {
        const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

        if (
          !certificateTemplatesData ||
          !certificateTemplatesData?.allCertificateTemplates ||
          certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
        ) {
          toast({
            title: t("Certificate couldn't be generated."),
            description: t(`Error: No certificate templates has been set!`),
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        }

        const {
          allCertificateTemplates: {
            edges: [baseTemplate],
          },
        } = certificateTemplatesData;

        const {
          node: { id: certificateTemplateId },
        } = baseTemplate;

        await Promise.all(
          selectedRows.map(async ({ original: { id } }) => {
            await generateSplitCertificate({
              variables: {
                input: {
                  policyAssetsId: [id],
                  certificateTemplateId,
                  // id: policyId,
                  language: "en",
                  hasPdf: true,
                },
              },
            });
          })
        );
      },
    },
  ];
  return (
    <Box>
      {/* <QuickActions actions={QUICK_ACTIONS} /> */}
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          hiddenColumns={[
            "lender",
            "lenderAddress",
            "delivery",
            "deliveryAddress",
            "pickupAddress",
            "shipper",
            "catNat",
            "coverTerrorism",
          ]}
          data={data.allPolicyAssets.edges.flatMap((e) => e?.node)}
          deleteAction={(id: string) => onDelete(id)}
          refetch={refetch}
          customActions={[
            {
              icon: <RiEye2Fill />,
              name: "View policy",
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              onClick: (row: { original: { policy: { id: string } } }) => {
                const {
                  original: { policy },
                } = row;
                navigate(`${SPIN_ROUTES.POLICY_PC}/${policy?.id}`);
              },
            },
          ]}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default PcPoliciesObjectsPage;
