import { Text, useDisclosure } from "@chakra-ui/react";
import { Table } from "components/ui";
import { PolicyAssetInput } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
} from "react-hook-form";
import { Trans } from "react-i18next";
import TEPolicyAssetFields from "pages/spin/te-policies/TEPolicyAssetFields";
import PCPolicyAssetFields from "pages/spin/pc-policies/PCPolicyAssetFields";
import { useEffect, useMemo, useState } from "react";
import { toast } from "middleware/Toaster";
import { PC_CUSTOM_COLUMNS, PC_CUSTOM_FIELDS, PC_HIDDEN_FIELDS } from "./PCCustomColumns";
import { TE_CUSTOM_COLUMNS, TE_CUSTOM_FIELDS, TE_HIDDEN_FIELDS } from "./TECustomColumns";
import { PolicyAssetInputExtended, PolicyType } from "../../../types";
import BatchEditPolicyAssetModal from "../../new/BatchEditPolicyAssetModal";

interface PolicyAssetEditableTableProps {
  data: PolicyAssetInputExtended[];
  mode?: "view" | "edit";
  remove?: UseFieldArrayRemove | ((arg0: number | string) => void);
  type: PolicyType;
  update?: UseFieldArrayUpdate<
    { policyAssets: PolicyAssetInputExtended[]; overrideChangeDate: string },
    "policyAssets"
  >;
  batchUpdateDefaults?: BatchFields;
}

export interface BatchFields {
  shipperId?: string;
  lenderId?: string;
  lenderAddress?: {
    raw: string;
  };
  pickupAddress?: {
    raw: string;
  };
  deliveryAddress?: {
    raw: string;
  };
  objectLocationEntityId?: string;
  objectLocationOfficeId?: string;
  catNat?: boolean;
  coverTerrorism?: boolean;
  coverType?: string;
  coveredRiskType?: string;
  evaluationType?: string;
  exemption?: boolean;
}

export const defaultValues: BatchFields = {
  shipperId: null,
  lenderId: null,
  lenderAddress: {
    raw: null,
  },
  pickupAddress: {
    raw: null,
  },
  deliveryAddress: {
    raw: null,
  },
  objectLocationEntityId: null,
  objectLocationOfficeId: null,
  catNat: false,
  coverTerrorism: false,
  coverType: null,
  coveredRiskType: null,
  evaluationType: null,
  exemption: false,

};

const OnEditPolicyAssetsTable = ({
  data,
  remove,
  type,
  mode = "edit",
  update,
  batchUpdateDefaults = defaultValues,
}: PolicyAssetEditableTableProps) => {
  console.log({ batchUpdateDefaults });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batchValues, setBatchValues] = useState<Partial<BatchFields>>(null);
  const [batchRowsToUpdate, setBatchRowsToUpdate] = useState<
    { id: string; original: PolicyAssetInputExtended }[]
  >([]);

  const methods = useForm<Partial<BatchFields>>({
    shouldUseNativeValidation: true,
    resolver: null,
    defaultValues: useMemo(() => batchUpdateDefaults, [batchUpdateDefaults]),
  });

  useEffect(() => {
    methods.reset(batchUpdateDefaults);
  }, [batchUpdateDefaults]);

  if (!data || data?.length === 0)
    return (
      <Text>
        <Trans>No assets has been added yet.</Trans>
      </Text>
    );

  const onSubmit: SubmitHandler<BatchFields> = (formData, e) => {
    const cleanedBatchValues: Partial<BatchFields> = Object.fromEntries(
      Object.entries(formData).filter(
        ([_, v]) =>
          (v !== null && v !== undefined && v !== "") ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (v?.raw &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== "" &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== null &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== undefined)
      )
    );

    setBatchValues(cleanedBatchValues);
    batchRowsToUpdate.forEach((row) => {
      update(Number(row.id), {
        ...row.original,
        ...cleanedBatchValues,
      });
    });
    toast({
      title: (
        <Trans
          defaults="{{count}} assets were updated successfully"
          values={{ count: batchRowsToUpdate.length }}
        />
      ),
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    methods.reset();
    setBatchRowsToUpdate([]);
    onClose();
  };

  const handleBatchUpdate = (rows: { id: string; original: PolicyAssetInputExtended }[]) => {
    if (rows.length === 0) {
      toast({
        title: <Trans>You must select at least one asset to use batch update.</Trans>,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    onOpen();
    setBatchRowsToUpdate(rows);
  };
  const BATCH_ACTIONS = [
    {
      name: <Trans>Update selected assets</Trans>,
      action: handleBatchUpdate,
    },
  ];
  const columns = getColumns<PolicyAssetInput>({
    data: data[0],
    customColumns: type === "PERMANENT_COLLECTION" ? PC_CUSTOM_COLUMNS : TE_CUSTOM_COLUMNS,
    customFields: type === "PERMANENT_COLLECTION" ? PC_CUSTOM_FIELDS : TE_CUSTOM_FIELDS,
  });

  return (
    <>
      <Table
        batchActions={BATCH_ACTIONS}
        // hiddenColumns={[
        //   "pickupAddress",
        //   "lenderAddress",
        //   "deliveryAddress",
        //   "catNat",
        //   "coverTerrorism",
        //   "exemption",
        //   "objectLocationEntity",
        //   "objectLocationOffice",
        //   "shipperId",
        //   "lenderId",
        //   "createdBy",
        // ]}
        hiddenColumns={type === "PERMANENT_COLLECTION" ? PC_HIDDEN_FIELDS : TE_HIDDEN_FIELDS}
        columns={columns}
        data={data}
        deleteAction={(id: number) => {
          remove(id);
        }}
        // TODO: this is a shitshow, need to refactor
        renderRowSubComponent={
          type === "PERMANENT_COLLECTION" ? PCPolicyAssetFields : TEPolicyAssetFields
        }
      />
      <FormProvider {...methods}>
        <BatchEditPolicyAssetModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={methods.handleSubmit(onSubmit)}
          type={type}
        // setBatchValues={setBatchValues}
        />
      </FormProvider>
    </>
  );
};

export default OnEditPolicyAssetsTable;
