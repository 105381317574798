/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface AnyObject {
    [key: string]: any
}



const AssetDating = ({ data }: AnyObject) => {
    const {
        datingType,
        isDateInterval,
        dateStartDate,
        dateEndDate,
        isDateStartDateBc,
        isDateEndDateBc,
        isDateApprox,
        isYearInterval,
        yearStartDate,
        yearEndDate,
        isYearStartDateBc,
        isYearEndDateBc,
        isYearApprox,
        isCenturyInterval,
        centuryStartDate,
        centuryEndDate,
        isCenturyStartDateBc,
        isCenturyEndDateBc,
        isCenturyApprox,
        centuryStartDateWindow,
        centuryEndDateWindow,
        creationPeriod,
    } = data;

    switch (datingType) {
        case 'PERIOD':
            return <Box><Text fontWeight="bold">Dating</Text><Text>Period:&nbsp;{creationPeriod}</Text></Box>
        case 'DATE':
            switch (isDateInterval) {
                case true:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            From date:&nbsp;{dateStartDate}
                            {isDateStartDateBc && " B.C."}
                            <br />
                            To date:&nbsp;{dateEndDate}
                            {isDateEndDateBc && " B.C."}
                            {isDateApprox && " Circa"}
                        </Text>
                    </Box>

                default:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            Date:&nbsp;
                            {dateStartDate}
                            {isDateStartDateBc && " B.C."}
                            {isDateApprox && " Circa"}
                        </Text>
                    </Box>
            }
        case 'YEAR':
            switch (isYearInterval) {
                case true:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            From year:&nbsp;{yearStartDate}
                            {isYearStartDateBc && " B.C."}
                            <br />
                            To year:&nbsp;{yearEndDate}
                            {isYearEndDateBc && " B.C."}
                            {isYearApprox && " Circa"}
                        </Text>
                    </Box>

                default:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            <Trans>Year</Trans>:&nbsp;
                            {yearStartDate}
                            {isYearStartDateBc && " B.C."}
                            {isYearApprox && " Circa"}
                        </Text>
                    </Box>
            }
        case 'CENTURY':
            switch (isCenturyInterval) {
                case true:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            From century:&nbsp;{centuryStartDateWindow && " "}{centuryStartDate}
                            {isCenturyStartDateBc && " B.C."}
                            <br />
                            To century:&nbsp;{centuryEndDateWindow && " "}{centuryEndDate}
                            {isCenturyEndDateBc && " B.C."}
                            {isCenturyApprox && " Circa"}
                        </Text>
                    </Box>

                default:
                    return <Box>
                        <Text variant="muted">Dating</Text>
                        <Text>
                            <Trans>Century</Trans>:&nbsp;
                            {centuryStartDate}
                            {isCenturyStartDateBc && " B.C."}
                            {isCenturyApprox && " Circa"}
                        </Text>
                    </Box>
            }
        default:
            return null;
    }
    return null;
};



export default AssetDating;