import {
  VStack,
  Heading,
  Text,
  Box,
  useDisclosure,
  Button,
  HStack,
  TabPanel,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from "@chakra-ui/react";
import {
  CopyButton,
  DateDialog,
  MoneyInputHook,
  QuickActions,
  DetailsPageSkeleton,
} from "components/ui";
import AddNotesModal from "components/ui/AddNotesModal";
import {
  useGenerateCertificateMutation,
  useGetAllCertificatesByPolicyIdAndIsActiveQuery,
  useGetAllCertificateTemplatesByNameLazyQuery,
  useViewPolicyByIdQuery,
  useGetPolicyDocumentsByPolicyIdQuery,
  useGetPolicyNotesByPolicyIdQuery,
  useUpdatePolicyMutation,
  useRenewPolicyMutation,
  useExtendPolicyMutation,
  useDuplicatePolicyMutation,
  useTerminatePolicyMutation,
  MoneyInput,
  useGenerateSplitCertificateMutation,
  useGetAllCertificatesByPolicyIdQuery,
  PolicyAssetNode,
} from "graphql/queries/generated/queries";
import downloadFile from "helpers/downloadFile";
import parseAmount from "helpers/parseAmount";
import parseDateForInput from "helpers/parseDateForInput";
import { handleMutation, toast } from "middleware/Toaster";
import PolicyCertificatesSummary from "pages/spin/shared/PolicyCertificatesSummary";
import PolicyDocumentsSummary from "pages/spin/shared/PolicyDocumentsSummary";
import PolicyNotesSummary from "pages/spin/shared/PolicyNotesSummary";
import PolicySummary from "pages/spin/shared/PolicySummary";
import OnViewPolicyAssetsTable from "pages/spin/shared/view/OnViewPolicyAssetsTable";
import PolicyBasketHistory from "pages/spin/shared/view/PolicyBasketHistory";
import PolicyHistory from "pages/spin/shared/view/PolicyHistory";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DEFAULT_OPENING_NOTE = process.env.REACT_APP_TE_OPENING_NOTE ?? "";
const DEFAULT_CLOSING_NOTE = process.env.REACT_APP_TE_CLOSING_NOTE ?? "";
interface FromToDates {
  startDate: string;
  endDate: string;
}

export interface InventoryMeta {
  openingNotes?: string;
  closingNotes?: string;
}

const PCPoliciesViewPage = () => {
  const [selectedObjects, setSelectedObjects] = useState<PolicyAssetNode[] | []>([]);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [inventoryMeta, setInventoryMeta] = useState<InventoryMeta>({
    openingNotes: DEFAULT_OPENING_NOTE,
    closingNotes: DEFAULT_CLOSING_NOTE,
  });
  const [getOnlyActiveCertificates, setGetOnlyActiveCertificates] = useState<boolean>(true);
  const { isOpen: isRenewOpen, onOpen: onRenewOpen, onClose: onRenewClose } = useDisclosure();
  const { isOpen: isExtendOpen, onOpen: onExtendOpen, onClose: onExtendClose } = useDisclosure();
  const {
    isOpen: isTerminateOpen,
    onOpen: onTerminateOpen,
    onClose: onTerminateClose,
  } = useDisclosure();
  const {
    isOpen: isDuplicateOpen,
    onOpen: onDuplicateOpen,
    onClose: onDuplicateClose,
  } = useDisclosure();
  const {
    isOpen: isCertificateNotesOpen,
    onOpen: onCertificateNotesOpen,
    onClose: onCertificateCloseOpen,
  } = useDisclosure();
  const {
    isOpen: isSplittedCertificateNotesOpen,
    onOpen: onSplittedCertificateNotesOpen,
    onClose: onSplittedCertificateCloseOpen,
  } = useDisclosure();

  const { policyId } = useParams();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useViewPolicyByIdQuery({
    variables: {
      id: policyId,
    },
  });

  const [
    renewPolicyMutation,
    { data: renewPolicyData, loading: renewPolicyLoading, error: renewPolicyError },
  ] = useRenewPolicyMutation({ ...handleMutation("Policy renewed!") });
  const [
    extendPolicyMutation,
    { data: extendPolicyData, loading: extendPolicyLoading, error: extendPolicyError },
  ] = useExtendPolicyMutation({ ...handleMutation("Policy extended!") });
  const [
    duplicatePolicyMutation,
    { data: duplicatePolicyData, loading: duplicatePolicyLoading, error: duplicatePolicyError },
  ] = useDuplicatePolicyMutation({ ...handleMutation("Policy duplicated!") });
  const [
    terminatePolicyMutation,
    { data: terminatePolicyData, loading: terminatePolicyLoading, error: terminatePolicyError },
  ] = useTerminatePolicyMutation({ ...handleMutation("Policy terminated!") });

  const [
    generateSplitCertificate,
    {
      data: generateSplitCertificateData,
      error: generateSplitCertificateError,
      loading: generateSplitCertificateLoading,
    },
  ] = useGenerateSplitCertificateMutation({ ...handleMutation("Splitted certificate generated!") });
  const {
    data: certificateSet,
    loading: certificateSetLoading,
    error: certificateSetError,
    refetch: refetchCertificates,
  } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
    variables: {
      policy: policyId,
      isValid: getOnlyActiveCertificates,
    },
  });

  const {
    data: allCertificateSet,
    loading: allCertificateSetLoading,
    error: allCertificateSetError,
    refetch: AllCertificateRefetchCertificates,
  } = useGetAllCertificatesByPolicyIdQuery({
    variables: {
      policy: policyId,
    },
  });

  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation({ ...handleMutation("Policy updated!") });

  const {
    data: policyDocumentsData,
    loading: policyDocumentsLoading,
    error: policyDocumentsError,
    refetch: refetchDocuments,
  } = useGetPolicyDocumentsByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const {
    data: policyNotesData,
    loading: policyNotesLoading,
    error: policyNotesError,
  } = useGetPolicyNotesByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const [
    generateCertificate,
    {
      data: generateCertificateData,
      loading: generateCertificateLoading,
      error: generateCertificateError,
    },
  ] = useGenerateCertificateMutation({
    ...handleMutation("Certificate generated!"),
  });

  const [getAllCertificateTemplatesByName] = useGetAllCertificateTemplatesByNameLazyQuery({
    variables: {
      name: "inventory_template_1",
    },
  });

  useEffect(() => {
    console.log(choosenAssets);
  }, [choosenAssets]);

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data || !data?.policy) return null;

  const {
    policy: {
      id,
      policyStatus,
      policyCode,
      offerCode,
      policyAssets,
      policyType,
      expiresAt,
      issuedAt,
      totalInsuredPremium,
    },
  } = data;

  const parsedForTablePolicyAssets = policyAssets.edges.flatMap((e) => {
    const { asset, insuredValue, ...rest } = e.node;
    return {
      ...rest,
      insuredValue: {
        amount: insuredValue?.amount,
        currency: insuredValue?.currency?.code,
      },
      ...asset,
      assetId: asset.id,
      id: rest.id,
    };
  });

  const isExpired = new Date(expiresAt).getTime() < new Date().getTime();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { allPolicyDocuments } = policyDocumentsData || {};
  const { allPolicyNotes } = policyNotesData || {};

  const duplicatePolicy = async () => {
    const { data } = await duplicatePolicyMutation({
      variables: {
        input: {
          policyId: id,
        },
      },
    });
    await refetch();
  };
  const renewPolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await renewPolicyMutation({
      variables: {
        input: {
          policyId: id,
          startDate,
          endDate,
          totalInsuredPremium,
        },
      },
    });

    await refetch();
  };
  const extendPolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await extendPolicyMutation({
      variables: {
        input: {
          policyId: id,
          endDate,
          totalInsuredPremium,
        },
      },
    });

    await refetch();
  };

  const terminatePolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await terminatePolicyMutation({
      variables: {
        input: {
          policyId: id,
          endDate,
          totalInsuredPremium,
        },
      },
    });
    await refetch();
  };

  const generateSplittedInventories = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    ) {
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: No certificate templates has been set!`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;

    const policyAssetsId = selectedObjects.map(({ id }) => id);
    const data = await generateSplitCertificate({
      variables: {
        input: {
          policyAssetsId,
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
          metafields: inventoryMeta,
        },
      },
    });

    await refetchCertificates();
  };

  const generateInventories = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    ) {
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: No certificate templates has been set!`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;
    await generateCertificate({
      variables: {
        input: {
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
          metafields: inventoryMeta,
        },
      },
    });

    await refetchCertificates();
  };

  const deletePolicy = () => {};

  const openAllCertificates = () => {
    certificateSet?.allCertificates?.edges?.map(async ({ node }) => downloadFile(node?.file));
  };

  const BATCH_ACTIONS = [
    // {
    //   name: <Trans>Generate certificates with selected assets</Trans>,
    //   action: async (selectedRows: { original: { id: string } }[]) => {
    //     const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    //     if (
    //       !certificateTemplatesData ||
    //       !certificateTemplatesData?.allCertificateTemplates ||
    //       certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    //     ) {
    //       toast({
    //         title: t("Certificate couldn't be generated."),
    //         description: t(`Error: No certificate templates has been set!`),
    //         status: "error",
    //         duration: 9000,
    //         isClosable: true,
    //       });
    //       return;
    //     }

    //     const {
    //       allCertificateTemplates: {
    //         edges: [baseTemplate],
    //       },
    //     } = certificateTemplatesData;

    //     const {
    //       node: { id: certificateTemplateId },
    //     } = baseTemplate;

    //     const policyAssetsId = selectedRows.map(({ original: { id } }) => id);
    //     const data = await generateSplitCertificate({
    //       variables: {
    //         input: {
    //           policyAssetsId,
    //           certificateTemplateId,
    //           id: policyId,
    //           language: "en",
    //           hasPdf: true,
    //         },
    //       },
    //     });

    //     await refetchCertificates();
    //   },
    // },
    {
      name: <Trans>Generate inventories with selected assets</Trans>,
      loading: generateSplitCertificateLoading,
      action: (selectedRows: { original: PolicyAssetNode }[]) => {
        setSelectedObjects(selectedRows.map(({ original }) => original));
        onSplittedCertificateNotesOpen();
      },
    },
    // {
    //   name: <Trans>Generate splitted certificates</Trans>,
    //   action: async (selectedRows: { original: { id: string } }[]) => {
    //     const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    //     if (
    //       !certificateTemplatesData ||
    //       !certificateTemplatesData?.allCertificateTemplates ||
    //       certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    //     ) {
    //       toast({
    //         title: t("Certificate couldn't be generated."),
    //         description: t(`Error: No certificate templates has been set!`),
    //         status: "error",
    //         duration: 9000,
    //         isClosable: true,
    //       });
    //       return;
    //     }

    //     const {
    //       allCertificateTemplates: {
    //         edges: [baseTemplate],
    //       },
    //     } = certificateTemplatesData;

    //     const {
    //       node: { id: certificateTemplateId },
    //     } = baseTemplate;

    //     await Promise.all(
    //       selectedRows.map(async ({ original: { id } }) => {
    //         await generateSplitCertificate({
    //           variables: {
    //             input: {
    //               policyAssetsId: [id],
    //               certificateTemplateId,
    //               id: policyId,
    //               language: "en",
    //               hasPdf: true,
    //             },
    //           },
    //         });
    //       })
    //     );

    //     await refetchCertificates();
    //   },
    // },
  ];

  const QUICK_ACTIONS = [
    {
      name: t("Edit"),
      href: `/spin/pc-policies/edit/${policyId}`,
      intent: "primary",
    },
    {
      name: t("Duplicate"),
      action: duplicatePolicy,
      loading: duplicatePolicyLoading,
    },
    {
      name: t("Renew"),
      action: onRenewOpen,
      loading: renewPolicyLoading,
    },
    {
      name: t("Extend"),
      action: onExtendOpen,
      loading: extendPolicyLoading,
    },
    {
      name: t("Generate inventories"),
      action: onCertificateNotesOpen,
      loading: generateCertificateLoading,
    },

    {
      name: t("Terminate"),
      action: onTerminateOpen,
      intent: "warn",
      disabled: isExpired,
      loading: terminatePolicyLoading,
    },
    {
      name: t("Delete"),
      action: deletePolicy,
      intent: "danger",
    },
  ];

  const issuedAtDate = new Date(issuedAt);
  const expiresAtDate = new Date(expiresAt);

  const parsedIssuedAtDate = parseDateForInput(issuedAtDate);
  const parsedExpiresAtDate = parseDateForInput(expiresAtDate);

  const parsedIssuedAt = issuedAt.split("+")[0];
  const parsedExpiresAt = expiresAt.split("+")[0];

  const diffTime = Math.abs(
    new Date(parsedExpiresAt).getTime() - new Date(parsedIssuedAt).getTime()
  );

  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  const renewIssuedAt = issuedAtDate.setDate(issuedAtDate.getDate() + diffDays);
  const renewExpiresAt = expiresAtDate.setDate(expiresAtDate.getDate() + diffDays);

  // console.log({ test: new Date(renewIssuedAt) });
  const parsedRenewIssuedAtDate = parseDateForInput(new Date(renewIssuedAt));
  const parsedRenewExpiresAtDate = parseDateForInput(new Date(renewExpiresAt));

  const policyCertificates = certificateSet?.allCertificates?.edges?.map(({ node }) => node);
  const pastCertificateMetas = allCertificateSet?.allCertificates?.edges
    .map(({ node: { metafields, createdAt, creationDate, createdBy } }) => {
      try {
        const parsedMetafields = JSON.parse(metafields as string) as InventoryMeta;
        if (
          !parsedMetafields?.openingNotes ||
          parsedMetafields?.openingNotes === "" ||
          !parsedMetafields?.closingNotes ||
          parsedMetafields?.closingNotes === ""
        )
          return null;
        return {
          ...parsedMetafields,
          createdAt,
          createdBy,
          creationDate,
        };
      } catch {
        toast({
          status: "warning",
          title: t("Couldn't retrieve past certificate notes"),
        });
        return null;
      }
    })
    .filter((e) => e);

  return (
    <>
      <Box backgroundColor="transparent">
        <HStack justifyContent="space-between" alignItems="flex-start" w="full">
          <VStack alignItems="flex-start">
            <Heading>
              <Trans>Policy #</Trans>
              {policyCode}
            </Heading>
            <Text fontSize="lg" variant="muted">
              <Trans>Offer #</Trans>
              {offerCode ?? ""} <CopyButton value={offerCode ?? ""} />
            </Text>
            <HStack>
              <Badge variant={policyType}>
                <Trans>{policyType}</Trans>
              </Badge>
              <Badge variant={policyStatus}>
                {policyStatus === "PENDING" && t("Offer")}
                {policyStatus === "APPROVED" && t("Policy")}
              </Badge>
              <Badge variant={isExpired ? "EXPIRED" : "CURRENT"}>
                {isExpired ? t("Expired") : t("Current")}
              </Badge>
            </HStack>
          </VStack>
          <QuickActions noTitle actions={QUICK_ACTIONS} />
        </HStack>

        <Tabs colorScheme="brand" variant="enclosed" mt="8">
          <TabList>
            <Tab>
              <Trans>Summary</Trans>
            </Tab>
            <Tab>
              <Trans>Objects</Trans>
            </Tab>
            <Tab>
              <Trans>Documents</Trans>
            </Tab>
            <Tab>
              <Trans>Inventories</Trans>
            </Tab>
            <Tab>
              <Trans>History</Trans>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel py={6} px="0">
              <PolicySummary policy={data.policy} actions={QUICK_ACTIONS} />
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <Heading size="md">
                  <Trans>Objects</Trans>
                </Heading>
                <Text variant="muted">
                  <Trans>Objects subtitle in policy view page</Trans>
                </Text>

                {/* TODO: refactor this */}
                <OnViewPolicyAssetsTable
                  data={parsedForTablePolicyAssets}
                  type="PERMANENT_COLLECTION"
                  mode="view"
                  // batchActions={BATCH_ACTIONS}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <Heading size="md">
                  <Trans>Notes</Trans>
                </Heading>
                <PolicyNotesSummary policyNotes={allPolicyNotes?.edges.map(({ node }) => node)} />
              </VStack>
              <VStack my={16} align="flex-start">
                <Heading size="md">
                  <Trans>Documents</Trans>
                </Heading>
                <PolicyDocumentsSummary
                  policyDocuments={allPolicyDocuments?.edges.map(({ node }) => node)}
                  refetch={refetchDocuments}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <HStack>
                  <Heading size="md">
                    <Trans>{t("Emitted inventories")}</Trans>
                  </Heading>
                  <Button variant="ghost" onClick={openAllCertificates}>
                    <Text decoration="underline">
                      <Trans>Download all</Trans>
                    </Text>
                  </Button>
                  <Button
                    variant="ghost"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      await refetchCertificates({
                        isValid: getOnlyActiveCertificates,
                        policy: policyId,
                      });
                      setGetOnlyActiveCertificates((prevState) => !prevState);
                    }}
                  >
                    <Text decoration="underline">
                      {getOnlyActiveCertificates ? (
                        <Trans>Show only invalid</Trans>
                      ) : (
                        <Trans>Show only valid</Trans>
                      )}
                    </Text>
                  </Button>
                </HStack>
                <PolicyCertificatesSummary
                  policyCertificates={policyCertificates}
                  refetchCertificates={refetchCertificates}
                  loading={certificateSetLoading}
                  type={policyType as "TEMPORARY_EXHIBITION" | "PERMANENT_COLLECTION"}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack spacing="8" align="flex-start" w="full">
                <PolicyHistory id={id} />
                <PolicyBasketHistory id={id} />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <DateDialog
        isOpen={isRenewOpen}
        onClose={onRenewClose}
        onOpen={onRenewOpen}
        title="Renew"
        message="If dates aren't updated, we'll renew this policy using the original range you set up."
        defaultStartDate={parsedRenewIssuedAtDate}
        defaultEndDate={parsedRenewExpiresAtDate}
        minDate={parsedRenewIssuedAtDate}
        loading={renewPolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={renewPolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>{" "}
          </Text>
        </VStack>
      </DateDialog>
      <DateDialog
        isOpen={isExtendOpen}
        onClose={onExtendClose}
        onOpen={onExtendOpen}
        title="Extend policy expiration"
        message="Set a new policy expiration that extends your policy"
        defaultStartDate={parsedIssuedAtDate}
        defaultEndDate={parsedExpiresAtDate}
        disabledStartDate
        loading={extendPolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={extendPolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>
          </Text>
        </VStack>
      </DateDialog>
      <DateDialog
        isOpen={isTerminateOpen}
        onClose={onTerminateClose}
        onOpen={onTerminateOpen}
        title="Terminate policy"
        message="Set a new expiration date prior to the one of your policy"
        defaultStartDate={parsedIssuedAtDate}
        defaultEndDate={parsedExpiresAtDate}
        maxDate={parsedExpiresAtDate}
        disabledStartDate
        loading={terminatePolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={terminatePolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>
          </Text>
        </VStack>
      </DateDialog>
      <AddNotesModal<InventoryMeta>
        title="Change certificate notes"
        message="Here you can edit default notes on your inventory"
        isInputTextArea
        isOpen={isCertificateNotesOpen}
        onOpen={onCertificateNotesOpen}
        onClose={onCertificateCloseOpen}
        onSubmit={generateInventories}
        fields={inventoryMeta}
        setFields={setInventoryMeta}
        submitActionTitle="Generate inventory"
        pastNotes={pastCertificateMetas}
      />
      <AddNotesModal<InventoryMeta>
        title="Change certificate notes"
        message="Here you can edit default notes on your inventory"
        isInputTextArea
        isOpen={isSplittedCertificateNotesOpen}
        onOpen={onSplittedCertificateNotesOpen}
        onClose={onSplittedCertificateCloseOpen}
        onSubmit={generateSplittedInventories}
        fields={inventoryMeta}
        setFields={setInventoryMeta}
        submitActionTitle="Generate inventory"
        pastNotes={pastCertificateMetas}
      />
      {/* <DateDialog
        isOpen={isDuplicateOpen}
        onClose={onDuplicateClose}
        onOpen={onDuplicateOpen}
        defaultStartDate={parsedIssuedAtDate}
        defaultEndDate={parsedExpiresAtDate}
        title="Update policy expiration"
        message="Set new starting date and expiration date"
        loading={duplicatePolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={duplicatePolicy}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>{" "}
          </Text>
        </VStack>
      </DateDialog> */}
    </>
  );
};

export default PCPoliciesViewPage;
