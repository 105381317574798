import { useViewPolicyByIdReducedQuery } from "graphql/queries/generated/queries";
import { Navigate, useParams } from "react-router-dom";
import { SPIN_ROUTES } from "routes/constants";

const PolicyRedirect = ({ isEdit }: { isEdit?: boolean }) => {
  const { policyId } = useParams();

  const { data, loading, error } = useViewPolicyByIdReducedQuery({
    variables: {
      id: policyId,
    },
  });

  if (loading) return null;
  if (error) return null;
  if (!data || !data.policy) return null;

  const {
    policy: { policyType },
  } = data;

  if (policyType === "PERMANENT_COLLECTION") {
    return <Navigate to={`${SPIN_ROUTES.POLICY_PC}/${isEdit ? "edit/" : ""}${policyId}`} />;
  }
  return <Navigate to={`${SPIN_ROUTES.POLICY_TE}/${isEdit ? "edit/" : ""}${policyId}`} />;
};

export default PolicyRedirect;
