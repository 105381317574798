import {
  HStack,
  VStack,
  Heading,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

interface BorderedBoxProps {
  title?: string;
  noTransTitle?: string;
  subtitle?: string | React.ReactNode;
  children: ReactNode;
  action?: {
    href: string;
    title: string;
  };
}

const BorderedBox = ({ title, subtitle, children, action, noTransTitle }: BorderedBoxProps) => (
  <Accordion allowToggle w="full" h="full" defaultIndex={0}>
    <AccordionItem borderWidth="1px" borderColor="gray.200" borderRadius="xl" flex="1">
      <AccordionButton p="0" bgColor="cardBg.50" w="full" display="block">
        <HStack borderBottomColor="gray.200" borderBottomWidth="1px" justify="space-between">
          <VStack align="flex-start" spacing="0">
            {/* <RiMapPinTimeLine size="2rem" /> */}
            <HStack justify="space-between" align="center" w="full" p="4">
              <VStack align="flex-start" spacing="0">
                <Heading size="md" mb="1">
                  {noTransTitle ? <Text>{noTransTitle}</Text> : <Trans>{title}</Trans>}
                </Heading>
                <Text variant="muted">{subtitle}</Text>
              </VStack>
              {action && (
                <Button>
                  <Link to={action.href}>
                    <Trans>{action.title}</Trans>
                  </Link>
                </Button>
              )}
            </HStack>
          </VStack>
          <Box px="4">
            <AccordionIcon />
          </Box>
        </HStack>
      </AccordionButton>

      <AccordionPanel>
        <VStack align="flex-start" spacing="4" p="4">
          {children}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);

export default BorderedBox;
