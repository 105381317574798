import { Icon } from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";
import {
  BiAward,
  BiCollection,
  BiMoney,
  BiPaint,
  BiPaperclip,
  BiQuestionMark,
} from "react-icons/bi";
import { BsFillCollectionFill } from "react-icons/bs";
import {
  MdAutoFixHigh,
  MdOutlineLocalShipping,
  MdOutlineMuseum,
  MdOutlinePolicy,
} from "react-icons/md";
import { RiMapPinTimeLine, RiNewspaperLine, RiPassportLine } from "react-icons/ri";
import {
  MGMT_ROUTES,
  SPIN_ROUTES,
  REGISTRY_ROUTES,
  OTHER_ROUTES,
  USER_ROUTES,
} from "routes/constants";
interface SingleMenuEntry {
  to: string;
  icon: React.ReactNode;
  name: React.ReactNode;
  enabled?: boolean;
}

type MenuEntry = Array<SingleMenuEntry & { children?: SingleMenuEntry[] }>;

const tenantCanUseMGMT = process.env.REACT_APP_MGMT_ENABLED === "true" ?? false;
const tenantCanUseSPIN = process.env.REACT_APP_SPIN_ENABLED === "true" ?? false;

const topMenuEntries: MenuEntry = [
  {
    to: MGMT_ROUTES.ASSETS,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Management system</Trans>,
    enabled: tenantCanUseMGMT,
  },
  {
    to: SPIN_ROUTES.SPIN,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Spin</Trans>,
    enabled: tenantCanUseSPIN,
  },
  {
    to: REGISTRY_ROUTES.REGISTRY,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Registry</Trans>,
    enabled: true,
  },
  {
    to: OTHER_ROUTES.ANALYSIS,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Analysis</Trans>,
    enabled: true,
  },
  {
    to: USER_ROUTES.USER,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Users management</Trans>,
    enabled: true,
  },
];

const mgmtMenuEntries: MenuEntry = [
  {
    to: MGMT_ROUTES.ASSETS,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>All assets</Trans>,
    children: [
      {
        to: MGMT_ROUTES.ASSETS_NEW,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>Add new asset</Trans>,
      },
    ],
  },
  {
    to: MGMT_ROUTES.COLLECTIONS,
    icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="black" />,
    name: <Trans>Collections</Trans>,
    children: [
      {
        to: MGMT_ROUTES.COLLECTIONS_NEW,
        icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="black" />,
        name: <Trans>Add new collection</Trans>,
      },
    ],
  },

  {
    to: MGMT_ROUTES.EXHIBITIONS,
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineMuseum} color="black" />,
    name: <Trans>Exhibitions</Trans>,
  },
  {
    to: MGMT_ROUTES.LOGISTIC,
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineLocalShipping} color="black" />,
    name: <Trans>Logistic</Trans>,
  },
  {
    to: MGMT_ROUTES.INSURANCE,
    icon: <Icon mr={3} w={6} h={6} as={MdOutlinePolicy} color="black" />,
    name: <Trans>Insurance</Trans>,
  },
  {
    to: MGMT_ROUTES.APPRAISAL,
    icon: <Icon mr={3} w={6} h={6} as={BiMoney} color="black" />,
    name: <Trans>Appraisal</Trans>,
  },
  {
    to: MGMT_ROUTES.RESTORATION,
    icon: <Icon mr={3} w={6} h={6} as={MdAutoFixHigh} color="black" />,
    name: <Trans>Restoration</Trans>,
  },
  {
    to: MGMT_ROUTES.CRSR,
    icon: <Icon mr={3} w={6} h={6} as={BiAward} color="black" />,
    name: <Trans>CR/SR</Trans>,
  },
  {
    to: MGMT_ROUTES.QUOTATION,
    icon: <Icon mr={3} w={6} h={6} as={BiQuestionMark} color="black" />,
    name: <Trans>Quotation</Trans>,
  },
  {
    to: MGMT_ROUTES.DOCS,
    icon: <Icon mr={3} w={6} h={6} as={BiPaperclip} color="black" />,
    name: <Trans>Documentation</Trans>,
  },
];

const spinMenuEntries: MenuEntry = [
  {
    to: SPIN_ROUTES.POLICY_TE,
    icon: <Icon mr={3} w={6} h={6} as={RiMapPinTimeLine} color="black" />,
    name: <Trans>Temporary Exhibition Policies</Trans>,
    children: [
      {
        to: SPIN_ROUTES.POLICY_TE_NEW,
        name: <Trans>Add new policy</Trans>,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
      },
    ],
  },
  {
    to: SPIN_ROUTES.CERTIFICATES,
    icon: <Icon mr={3} w={6} h={6} as={RiPassportLine} color="black" />,
    name: <Trans>Certificates</Trans>,
  },
  // {
  //   to: SPIN_ROUTES.POLICY_TE_OBJECTS,
  //   icon: (
  //     <Box position="relative">
  //       <Icon mr={3} w={6} h={6} as={RiMapPinTimeLine} color="black" />
  //       <Text fontWeight={500} position="absolute" top="1.2rem">
  //         OBJ
  //       </Text>
  //     </Box>
  //   ),
  //   name: <Trans>Temporary Exhibition Objects</Trans>,
  // },
  {
    to: SPIN_ROUTES.POLICY_PC,
    icon: <Icon mr={3} w={6} h={6} as={BsFillCollectionFill} color="black" />,
    name: <Trans>Permanent Collection Policies</Trans>,
    children: [
      {
        to: SPIN_ROUTES.POLICY_PC_NEW,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>Add new policy</Trans>,
      },
    ],
  },
  {
    to: SPIN_ROUTES.INVENTORIES,
    icon: <Icon mr={3} w={6} h={6} as={RiNewspaperLine} color="black" />,
    name: <Trans>Inventories</Trans>,
  },
  // {
  //   to: SPIN_ROUTES.POLICY_PC_OBJECTS,
  //   icon: (
  //     <Box position="relative">
  //       <Icon mr={3} w={6} h={6} as={BsFillCollectionFill} color="black" />
  //       <Text fontWeight={500} position="absolute" top="1.2rem">
  //         OBJ
  //       </Text>
  //     </Box>
  //   ),
  //   name: <Trans>Permanent Collection Objects</Trans>,
  // },
];

const registryMenuEntries: MenuEntry = [
  // {
  //   to: "/o/analysis",
  //   icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
  //   name: <Trans>Analysis</Trans>,
  // },
  {
    to: REGISTRY_ROUTES.REGISTRY,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Registry</Trans>,
    children: [
      {
        to: REGISTRY_ROUTES.PEOPLE,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>People</Trans>,
      },
      {
        to: REGISTRY_ROUTES.COPMANIES,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>Company</Trans>,
      },
      {
        to: REGISTRY_ROUTES.AUTHORS,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>Authors</Trans>,
      },
    ],
  },
  // {
  //   to: "/u/users",
  //   icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
  //   name: <Trans>Users</Trans>,
  // },
];
const usersMenuEntries: MenuEntry = [
  {
    to: USER_ROUTES.USER,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Users</Trans>,
    children: [],
  },
];
const otherMenuEntries: MenuEntry = [
  {
    to: OTHER_ROUTES.ANALYSIS,
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Analysis</Trans>,
    children: [],
  },
];

export {
  registryMenuEntries,
  mgmtMenuEntries,
  spinMenuEntries,
  topMenuEntries,
  usersMenuEntries,
  otherMenuEntries,
};
