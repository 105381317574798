import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { Trans, useTranslation } from "react-i18next";
import {
  useDeleteUserSignatureMutation,
  UserSignatureNode,
  useSetMainSignatureMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";
import parseDate from "helpers/parseDate";

const UserSignatureCard = ({
  id,
  uuid,
  file,
  createdAt,
  index,
  refetch,
  isMain,
  userId,
}: Pick<UserSignatureNode, "id" | "uuid" | "file" | "createdAt" | "isMain"> & {
  index: number;
  refetch: () => void;
  userId: string;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [
    deleteUserSignature,
    {
      data: deleteUserSignatureData,
      error: deleteUserSignatureError,
      loading: deleteUserSignatureLoading,
    },
  ] = useDeleteUserSignatureMutation({ ...handleMutation("Signature deleted!") });

  const [
    setMainSignature,
    { data: setMainSignatureData, error: setMainSignatureError, loading: setMainSignatureLoading },
  ] = useSetMainSignatureMutation({ ...handleMutation("Main signature set!") });

  const deleteSignature = async (id: string) => {
    await deleteUserSignature({
      variables: {
        input: {
          id,
        },
      },
    });

    refetch();
  };

  return (
    <Box p="2" shadow="xl" w="15rem" borderRadius="md">
      <VStack p="2" align="flex-start">
        <Heading size="md" mb="2">
          Signature #{index + 1}
        </Heading>
        <Image src={file} maxH="5rem" />
        {isMain && (
          <Badge>
            <Trans>Main</Trans>
          </Badge>
        )}
        <Text variant="muted">UUID</Text>
        <Text>{uuid}</Text>
        <Text variant="muted">
          <Trans>Created at</Trans>
        </Text>
        <Text>{parseDate(createdAt)}</Text>
        <HStack justify="flex-start" pt="4">
          {!isMain && (
            <Button
              isLoading={setMainSignatureLoading}
              onClick={() =>
                setMainSignature({
                  variables: {
                    input: {
                      signatureId: id,
                      userId,
                    },
                  },
                })
              }
              variant="outline"
            >
              Set as main
            </Button>
          )}
          <Button
            isLoading={deleteUserSignatureLoading}
            onClick={() => deleteSignature(id)}
            variant="danger"
          >
            Delete
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default UserSignatureCard;
