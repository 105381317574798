import { Avatar, Box, Button, Heading, HStack, Text, VStack } from "@chakra-ui/react";

import { Trans } from "react-i18next";
import { UserNode, UserSignatureNode } from "graphql/queries/generated/queries";
import { ApolloError } from "@apollo/client";
import parseDate from "helpers/parseDate";

const FileCard = ({
  id,
  uuid,
  file,
  createdAt,
  index,
  refetch,
  handleDelete,
  success,
  error,
  loading,
  createdBy,
}: Pick<UserSignatureNode, "id" | "uuid" | "file" | "createdAt"> & {
  index: number;
  refetch: () => void;
  handleDelete: (id: string) => void;
  success: any;
  error: ApolloError;
  loading: boolean;
  createdBy?: Pick<UserNode, "name">;
}) => {
  const deleteAction = (id: string) => {
    handleDelete(id);

    refetch();
  };

  return (
    <Box p="2" shadow="xl" w="15rem" borderRadius="md">
      <VStack p="2" align="flex-start" spacing="4">
        <Heading size="md">File #{index + 1}</Heading>
        <Text variant="muted">UUID</Text>
        <Text>{uuid}</Text>
        <Text variant="muted">
          <Trans>Created at</Trans>
        </Text>
        <Text>{parseDate(createdAt)}</Text>
        <Text variant="muted">
          <Trans>Created by</Trans>
        </Text>

        <HStack mt="1 !important">
          {createdBy ? (
            <>
              <Avatar name={createdBy?.name} src="https://bit.ly/broken-link" size="xs" />
              <Text>{createdBy?.name}</Text>
            </>
          ) : (
            <Text>
              <Trans>User not found</Trans>
            </Text>
          )}
        </HStack>

        <HStack>
          <Button onClick={() => window.open(file)}>
            <Trans>Download</Trans>
          </Button>
          <Button isLoading={loading} onClick={() => deleteAction(id)} variant="danger">
            Delete
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default FileCard;
