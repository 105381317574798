interface ValuesOptionLabels {
    options?: Array<{
        label: string,
        value: string
      }>;
      label: string;
      value?: string;
}

const findCategory = (a: Array<ValuesOptionLabels>, subcategory: string) => {
    let category = "";
    if (subcategory === "") {
        return category;
    }

    a.forEach(element => {
        if (element.options) {
        element.options.forEach(option => {
            if(option.value === subcategory) {
            category = element.value;
            }
        })
        }
    })
    return category;
}

export default findCategory;