/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import algoliasearch from "algoliasearch";

import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  Heading,
  Select,
  Image,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {
  InstantSearch,
  useHitsPerPage,
  HitsPerPageProps,
  SortBy,
} from "react-instantsearch-hooks-web";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import placeholderPNG from "assets/placeholder.png";
import { useAuth } from "contexts/AuthProvider";
import Facets from "./Facets";
import CustomSearchBox from "./CustomSearchBox";
import CustomResults from "./CustomResults";
import { AlgoliaSearchBoxProps, SelectedAsset } from "./types";

const CustomHitsPerPage = (props: HitsPerPageProps) => {
  const { items, hasNoResults, refine } = useHitsPerPage(props);
  const { value } = items.find(({ isRefined }) => isRefined) || {};

  return (
    <Select
      size="md"
      width="auto"
      onChange={(event) => {
        refine(Number(event.target.value));
      }}
      value={String(value)}
      disabled={hasNoResults}
    >
      {items.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  );
};

const AssetSearchBox = ({
  model,
  index,
  addAsset,
  choosenAssets,
  singleSelectionMode,
}: AlgoliaSearchBoxProps) => {
  const [resultsLayout, setResultsLayout] = useState<"table" | "grid">("table");
  const [error, setError] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState<SelectedAsset[]>([]);
  const humanReadableSelectedAssets = selectedAssets?.map(({ title }) => title).join(", ");
  const toast = useToast();
  const { t } = useTranslation();
  const modelName = model ?? "policy";
  const messages = {
    toastTitle: "Assets added to {{modelName}}.",
    toastDescription: "We've added selected assets to your {{modelName}}.",
    headingText: "Review selected assets and add them to {{modelName}}",
  };
  const { user, algoliaUserKey } = useAuth();
  const searchClient = algoliasearch(
    "YVOVKSNXLK",
    user.isSuperuser ? "a591098fbc9e749104b5fbfe593aa007" : algoliaUserKey
  );

  const addSelectedAssetsToPolicy = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      selectedAssets.map((asset) => addAsset(asset));
      toast({
        // title: model? t("Assets added to policy.") : t("Assets added to policy."),
        title: t(messages.toastTitle, { modelName }),
        // description: t("We've added selected assets to your policy."),
        description: t(messages.toastDescription, { modelName }),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      setError(e);
      toast({
        title: t("Assets couldn'be added."),
        description: t(`Error: ${JSON.stringify(e)}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setSelectedAssets([]);
    }
  };

  return (
    <Box>
      <InstantSearch searchClient={searchClient} indexName={index}>
        <HStack flexGrow={1} display="flex" width="100%" alignItems="flex-start" spacing="16">
          <Flex height="100%" my={4} minWidth="15rem">
            <Facets
              hierarchicalAttributes={[
                { title: "Category", attributes: ["category", "subcategory"] },
              ]}
            />
          </Flex>

          <VStack flex={1} align="flex-start">
            <HStack my={4} w="100%">
              <CustomSearchBox />
              <CustomHitsPerPage
                items={[
                  { label: "10 hits per page", value: 10, default: true },
                  { label: "20 hits per page", value: 20 },
                  { label: "50 hits per page", value: 50 },
                  { label: "100 hits per page", value: 100 },
                ]}
              />
              {process.env.REACT_APP_ENABLE_ALGOLIA_SORTING && (
                <SortBy
                  items={[
                    { label: "Featured", value: "instant_search" },
                    { label: "Price (asc)", value: "instant_search_price_asc" },
                    { label: "Price (desc)", value: "instant_search_price_desc" },
                  ]}
                />
              )}
              <Select
                onChange={(e) => setResultsLayout(e.target.value as "table" | "grid")}
                w="max-content"
                size="md"
              >
                <option value="table">Show table</option>
                <option value="grid">Show grid</option>
              </Select>
            </HStack>
            <CustomResults
              layout={resultsLayout}
              selectedAssets={selectedAssets}
              setSelectedAssets={setSelectedAssets}
              addAsset={addAsset}
              choosenAssets={choosenAssets}
              singleSelectionMode={singleSelectionMode}
            />
          </VStack>
        </HStack>
        <Box bg="white" my={16}>
          <HStack justify="space-between">
            <VStack align="flex-start" w="100%">
              <HStack align="flex-end" w="100%" justify="space-between">
                <VStack align="flex-start">
                  <Badge mr="2" mb="1">
                    Step 2
                  </Badge>
                  <Heading size="lg">
                    <Trans defaults={messages.headingText} values={{ modelName }} />
                  </Heading>
                </VStack>
                {humanReadableSelectedAssets.length > 0 && (
                  <Box>
                    {error && JSON.stringify(error)}
                    <Button onClick={addSelectedAssetsToPolicy} mr="2">
                      <Trans
                        defaults="Add {{count}} asset(s) to {{modelName}}"
                        values={{ count: selectedAssets?.length, modelName }}
                      />
                    </Button>

                    <Button onClick={() => setSelectedAssets([])} variant="danger">
                      <Trans>Clear selection</Trans>
                    </Button>
                  </Box>
                )}
              </HStack>

              {humanReadableSelectedAssets.length > 0 ? (
                <Flex wrap="wrap">
                  {selectedAssets.map(({ title, author, creationPeriod, image }) => (
                    <HStack
                      bg="gray.200"
                      justify="space-between"
                      align="flex-start"
                      rounded="md"
                      shadow="md"
                      _hover={{
                        shadow: "lg",
                        transition: "all 0.5s",
                      }}
                      height="4rem"
                      borderWidth={2}
                      borderColor="white"
                      mr="2"
                      mb="2"
                    >
                      <VStack align="flex-start" px="4" py="3">
                        <Heading size="sm" mb="0">
                          {title}
                        </Heading>
                        <Text variant="muted" mt="0 !important">
                          <Trans>Made by</Trans>&nbsp; &quot;
                          {author}
                          &quot; &nbsp;<Trans>and dated</Trans>&nbsp;
                          {creationPeriod}
                        </Text>
                      </VStack>
                      <Image
                        alignSelf="center"
                        src={image ?? placeholderPNG}
                        alt=""
                        maxH="100%"
                        maxW="100%"
                        w="5rem"
                        objectFit="contain"
                      />
                    </HStack>
                  ))}
                </Flex>
              ) : (
                <Trans>Choose an item by clicking on it...</Trans>
              )}
            </VStack>
          </HStack>
        </Box>
      </InstantSearch>
    </Box>
  );
};

export default AssetSearchBox;
