import { EventLogNode, useGetEventLogByAssetIdQuery, useDeleteEventLogMutation } from "graphql/queries/generated/queries";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import { Box, Button, ButtonGroup, Divider, Skeleton } from "@chakra-ui/react";
import { NoDataPlaceholder, Table } from "components/ui";
import { handleMutation } from "middleware/Toaster";
import getColumns from "helpers/getColumns";
import NewEventLogDrawer from "./NewEventLogDrawer";
import EditEventLogDrawer from "./EditEventLogDrawer";
import ViewEventLogDrawer from "./ViewEventLogDrawer";
const HIDDEN_FIELDS = ["locationDescription", "node"];
const CUSTOM_FIELDS = [ "eventType", "owner" ];
const CUSTOM_COLUMNS = [
  {
    Header: "Event",
    Footer: "",
    accessor: "eventType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Start date",
    Footer: "",
    accessor: "startDate",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
    weight: 0
  },
  {
    Header: "End date",
    Footer: "",
    accessor: "endDate",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
    weight: 0
  },
  {
    Header: "Owner",
    Footer: "",
    accessor: "owner",
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null) return <div>{value.fullName}</div>;

      return <div />;
    },
    disableFilters: true,
  },
  {
    Header: "External ID",
    Footer: "",
    accessor: "externalId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];

interface EventLogFormProps {
  assetId: string;
}

const EventLogs = ({ assetId }: EventLogFormProps) => {

  const { t } = useTranslation();
  const [ eventLogId, setEventLogId ] = useState("");
  const [ showNewEventLogDrawer, setShowNewEventLogDrawer ] = useState(false);
  const [ showEditEventLogDrawer, setShowEditEventLogDrawer ] = useState(false);
  const [ showViewEventLogDrawer, setShowViewEventLogDrawer ] = useState(false);
  
  const { data, loading, error, refetch } = useGetEventLogByAssetIdQuery({
    variables: {
      id: assetId,
    },
  });

  const [deleteEventLog, { data: deleteData, error: deleteError, loading: deleteLoading }] = useDeleteEventLogMutation({
    ...handleMutation("Event Log deleted!"),
  });

  const onAddNewEventLog = () => {
    setEventLogId("");
    setShowNewEventLogDrawer(true);
  };

  const onCloseNewDrawer = async () => {
    setShowNewEventLogDrawer(false);
    
    await refetch();
  }

  const onCloseEditDrawer = async () => {
    setShowEditEventLogDrawer(false);
    
    await refetch();
  }

  const onCloseViewDrawer = () => {
    setShowViewEventLogDrawer(false);
  }

  const onEdit = ({ row: { original } }: { row: { original: EventLogNode } }) => {
    const { id } = original;
    
    setEventLogId(id);
    setShowEditEventLogDrawer(true);
  };
  
  const onView = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setEventLogId(row.original.id);
      setShowViewEventLogDrawer(true);
    }
  };

  const onDelete = async (id: string) => {
    await deleteEventLog({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  if (!data || !data.asset || !data.asset.eventLogs || !data.asset.eventLogs.edges || data.asset.eventLogs.edges.length <= 0)
    return (
      <Box>       
        <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
          <Button onClick={onAddNewEventLog} variant="primary">
            <Trans>Add new Event log</Trans>
          </Button>
        </ButtonGroup> 
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
        <NewEventLogDrawer show={showNewEventLogDrawer} onClose={onCloseNewDrawer} assetId={assetId} />
        <EditEventLogDrawer show={showEditEventLogDrawer} onClose={onCloseEditDrawer} assetId={assetId} eventLogId={eventLogId} />
      </Box>
    );

  if (data.asset.eventLogs.edges[0] === null) return null;

  const dataToGetColumns = data.asset.eventLogs.edges[0];

  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });  

  return (
    <>
      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onAddNewEventLog} variant="primary">
          <Trans>Add new Event log</Trans>
        </Button>
      </ButtonGroup>
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table hiddenColumns={HIDDEN_FIELDS} columns={columns} data={data.asset.eventLogs.edges.flatMap((edge) => edge?.node)} editAction={onEdit} viewAction={onView} deleteAction={(id: string) => onDelete(id)} />
      </Skeleton>
      <NewEventLogDrawer show={showNewEventLogDrawer} onClose={onCloseNewDrawer} assetId={assetId} />
      <EditEventLogDrawer show={showEditEventLogDrawer} onClose={onCloseEditDrawer} assetId={assetId} eventLogId={eventLogId} />
      <ViewEventLogDrawer show={showViewEventLogDrawer} onClose={onCloseViewDrawer} assetId={assetId} eventLogId={eventLogId} />
    </>
  );

}

export default EventLogs;