/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, ButtonGroup, Button, FormLabel, Text, Flex, Badge } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Trans } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";

import { Wrapper } from "../../../components/ui/FormInputHook/Wrapper";

const formatGroupLabel = (data: any) => (
  <Flex align="center" justify="space-between" mt="1">
    <Text variant="muted" textTransform="uppercase" fontWeight="600" fontSize="xs">
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {data.label}
    </Text>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
    <Badge>{data.options.length}</Badge>
  </Flex>
);

interface ChooseCategoryFormProps {
  onSubmit: any;
  // handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void;
  resetSubCategory: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // value: string;
  onChange: (value: string) => void;
}

// eslint-disable-next-line react/prop-types
const ChooseCategoryForm = ({
  onSubmit,
  // handleRadioButtons,
  resetSubCategory,
  // value,
  onChange,
}: ChooseCategoryFormProps) => (
  <form onSubmit={onSubmit}>
    {/* <CustomSelect
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            options={options}
            useBasicStyles
            value={options.find(({ value: optionValue }) => value === optionValue)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => onChange(newValue.value)}
          /> */}
    <Box>
      <Wrapper name="subcategory">
        <FormLabel htmlFor="subcategory" mb="1" fontSize="xs" display="inline-flex">
          <Text>
            <Trans>Category</Trans>
          </Text>
        </FormLabel>
        <Box minW="12rem" background="white">
          <Select<{ label: string; value: string }>
            size="sm"
            name="subcategory"
            useBasicStyles
            placeholder="Select a category..."
            options={ASSET_CONSTANTS.SUBCATEGORY}
            onChange={(newValue: { value: string; label: string }) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              onChange(newValue.value);
            }}
            formatGroupLabel={formatGroupLabel}
          />
        </Box>
      </Wrapper>

      <ButtonGroup variant="outline" spacing="6">
        <Button type="submit" variant="primary" colorScheme="blue">
          <Trans>Continue</Trans>
        </Button>
        <Button onClick={(e) => resetSubCategory(e)}>Annulla</Button>
      </ButtonGroup>
    </Box>

  </form>
);

export default ChooseCategoryForm;
