import {
  Box,
  Stack,
  StackDivider,
  HStack,
  ButtonGroup,
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import {
  useAddRestorationMediaMutation,
  useDeleteRestorationMediaMutation,
  useUpdateMediaMutation,
  useGetMediaRestorationByIdQuery,
  MediaInput,
  MediaNode,
} from "graphql/queries/generated/queries";
import ASSET_CONSTANTS from "constants/asset";
import { yupResolver } from "@hookform/resolvers/yup";
import { MediaInputSchema } from "graphql/queries/generated/validation-schema";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { ValidationProvider } from "components/form/ValidationContext";
import { FormInputHook, Table } from "components/ui";
import clearAssetMediaValues from "pages/assets/utils/ClearAssetMediaValues";
import getColumns from "helpers/getColumns";
import {
  DOCUMENT_CUSTOM_FIELDS,
  DOCUMENT_CUSTOM_COLUMNS,
  DOCUMENT_HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import { handleMutation } from "middleware/Toaster";

interface AnyObject {
  [key: string]: any;
}

const findLabelByValue = (value: string, array: { value: string; label: string }[]) => {
  const obj = array.find((x) => x.value === value);
  return obj ? obj.label : "";
};

interface DocumentsFormProps {
  restorationId: string;
}

const DocumentsForm = ({ restorationId }: DocumentsFormProps) => {
  const { t } = useTranslation();

  const methods = useForm<MediaInput>({
    defaultValues: {
      mediaType: ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value,
      name: "",
      file: "",
      description: "",
    },
    resolver: yupResolver(MediaInputSchema()),
    mode: "onChange",
  });

  const { setValue, watch } = methods;

  const { data, loading, error, refetch } = useGetMediaRestorationByIdQuery({
    variables: {
      id: restorationId,
    },
  });

  const [selectedId, setSelectedId] = useState<string>(null);
  const [currentFile, setCurrentFile] = useState<string>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [
    addRestorationMedia,
    {
      data: addRestorationMediaData,
      loading: addRestorationMediaLoading,
      error: addRestorationMediaError,
    },
  ] = useAddRestorationMediaMutation({
    ...handleMutation("Media added!"),
  });
  const [
    updateMedia,
    { data: updateMediaData, loading: updateMediaLoading, error: updateMediaError },
  ] = useUpdateMediaMutation({
    ...handleMutation("Media updated!"),
  });
  const [
    deleteRestorationMedia,
    {
      data: deleteRestorationMediaData,
      error: deleteRestorationMediaError,
      loading: deleteRestorationMediaLoading,
    },
  ] = useDeleteRestorationMediaMutation({
    ...handleMutation("Restoration deleted!"),
  });

  if (!data || !data.restoration) return null;

  let dataToGetColumns;
  let columnsDocument;
  const documents: MediaNode[] = [];

  if (
    data &&
    data.restoration &&
    data.restoration.media &&
    data.restoration.media.edges &&
    data.restoration.media.edges.length > 0
  ) {
    dataToGetColumns = data.restoration.media.edges[0].node;

    columnsDocument = getColumns({
      data: dataToGetColumns,
      customFields: DOCUMENT_CUSTOM_FIELDS,
      customColumns: DOCUMENT_CUSTOM_COLUMNS,
    });

    for (let i = 0; i < data.restoration.media.edges.length; i += 1)
      if (
        data.restoration.media.edges[i].node.mediaType ===
        ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value
      )
        documents.push(data.restoration.media.edges[i].node as MediaNode);
  }

  const onEditModalOpen = (row: { original: MediaNode }) => {
    if (
      row &&
      row.original &&
      row.original.id &&
      row.original.name &&
      row.original.description &&
      row.original.file
    ) {
      setSelectedId(row.original.id);

      setValue("name", row.original.name);

      setValue("file", row.original.file);

      setValue("description", row.original.description);

      setCurrentFile(row.original.file);

      onOpen();
    }
  };

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("file", "");
    setValue("description", "");
    setCurrentFile(null);

    onClose();
  };

  const onDelete = async (id: string) => {
    await deleteRestorationMedia({
      variables: {
        input: {
          mediaId: id,
          id: restorationId,
        },
      },
    });
  };

  const onViewDocument = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id && row.original.file) window.open(row.original.file);
  };

  const onSubmit: SubmitHandler<MediaInput> = async (values: AnyObject, e: any) => {
    const mediaData = clearAssetMediaValues(values);

    let response;

    if (selectedId) {
      response = await updateMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
            },
            id: selectedId,
          },
        },
      });

      if (
        response.data &&
        response.data.updateMedia &&
        response.data.updateMedia.media &&
        response.data.updateMedia.media.id
      )
        onCloseModal();
    } else {
      response = await addRestorationMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
              mediaType: mediaData.mediaType,
            },
            restorationId,
          },
        },
      });

      if (
        response.data &&
        response.data.addRestorationMedia &&
        response.data.addRestorationMedia.restoration
      )
        onCloseModal();

      await refetch();
    }
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const handleSubmit = methods.handleSubmit(onSubmit, onError);
  const mediaType = watch("mediaType");

  return (
    <Stack spacing="4">
      <Table
        hiddenColumns={DOCUMENT_HIDDEN_FIELDS}
        columns={columnsDocument}
        data={documents}
        deleteAction={onDelete}
        editAction={onEditModalOpen}
        viewAction={onViewDocument}
      />

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onOpen} variant="primary">
          <Trans>Add new report document</Trans>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? (
              <Trans>Update report document</Trans>
            ) : (
              <Trans>Add new report document</Trans>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormProvider {...methods}>
              <ValidationProvider schema={MediaInputSchema()}>
                <form id="addRestorationMedia" onSubmit={handleSubmit}>
                  <Box maxW="6xl">
                    <Stack spacing="4" divider={<StackDivider />}>
                      <HStack alignItems="flex-end">
                        <FormInputHook label="Document name" name="name" />
                      </HStack>

                      <HStack alignItems="flex-end">
                        <FormInputHook
                          minW="30rem"
                          label="Description"
                          name="description"
                          type="textarea"
                        />
                      </HStack>

                      <HStack alignItems="flex-end">
                        <Button
                          title="Open in new window"
                          onClick={() => window.open(currentFile)}
                          fontWeight="600"
                        >
                          Vedi file
                        </Button>
                        <FormInputHook label="File" name="file.0.file" type="file" />
                      </HStack>
                    </Stack>
                  </Box>
                </form>
              </ValidationProvider>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <Button
                variant="primary"
                onClick={handleSubmit}
                mr={1}
                isLoading={addRestorationMediaLoading || updateMediaLoading}
                loadingText="Loading"
              >
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onCloseModal}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default DocumentsForm;
