import { Image, Button } from "@chakra-ui/react";
import placeholderPNG from "assets/placeholder.png";

export const CUSTOM_FIELDS: string[] = [
  "mainImage",
  "category",
  "author",
  "title",
  "actualDateAndPeriod",
  "genericDimensions",
];

export const HIDDEN_FIELDS: string[] = ["description", "creationDate"];

export const CUSTOM_COLUMNS = [
  {
    Header: "Image",
    footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button style={{ height: "auto", maxWidth: "120px" }} variant="unstyled" title="Open in new window" onClick={() => window.open(value)} >
          <Image src={value} alt="" h="3rem" w="full" />
        </Button>
      ) : (
        <Image src={placeholderPNG} alt="" />
      ),
    disableFilters: true,
  },
  {
    Header: "Category",
    footer: "",
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Author",
    footer: "",
    accessor: "author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Title",
    footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Creation Date",
    footer: "",
    accessor: "actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Dimensions",
    footer: "",
    accessor: "genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];

export const DOCUMENT_CUSTOM_FIELDS: string[] = [
  "name",
  "description"
];

export const DOCUMENT_HIDDEN_FIELDS: string[] = ["audioVideoFileType", "creationDate", "file", "fileAuthor", "fileSize", "imageType", "isGlassPlexyAcetate", "isMainImage", "matrixType", "mediaType", "notes", "photographicEquipment"];

export const DOCUMENT_CUSTOM_COLUMNS = [
  {
    Header: "Name",
    footer: "",
    accessor: "name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Description",
    footer: "",
    accessor: "description",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];

export const MEDIA_CUSTOM_FIELDS: string[] = [
  "name",
  "description",
  "mediaType",
  "notes"
];

export const MEDIA_HIDDEN_FIELDS: string[] = ["audioVideoFileType", "creationDate", "file", "fileAuthor", "fileSize", "imageType", "isGlassPlexyAcetate", "isMainImage", "matrixType", "photographicEquipment"];

export const MEDIA_CUSTOM_COLUMNS = [
  {
    Header: "Media type",
    footer: "",
    accessor: "mediaType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Name",
    footer: "",
    accessor: "name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Description",
    footer: "",
    accessor: "description",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },  
  {
    Header: "Notes",
    footer: "",
    accessor: "notes",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  }
];