import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFooter } from "components/ui";
import { ValidationProvider } from "components/form/ValidationContext";
import { useCreateCollectionMutation, CollectionInput } from "graphql/queries/generated/queries";
import { CollectionInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MGMT_ROUTES } from "routes/constants";
import { handleMutation } from "middleware/Toaster";
import CollectionFields from "../shared/CollectionFields";

const NewCollectionPage = () => {
  const [createCollection, { data, loading, error, client, called }] = useCreateCollectionMutation({
    ...handleMutation("Collection created!"),
  });
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const methods = useForm<CollectionInput>({
    defaultValues: {},
    resolver: yupResolver(CollectionInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof CollectionInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<CollectionInput> = async (formData, e) => {
    console.log(formData);

    const result = await createCollection({
      variables: {
        input: {
          collectionData: {
            ...formData,
          },
        },
      },
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.COLLECTIONS}/edit/${result.data.createCollection.collection.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 3000);

    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <ValidationProvider schema={CollectionInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CollectionFields watch={methods.watch} />
            <FormFooter data={data} loading={loading} errors={[error]} title="Collection entry" />
          </form>
        </ValidationProvider>
      </FormProvider>
    </Stack>
  );
};

export default NewCollectionPage;
