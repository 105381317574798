/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { AssetInput } from "graphql/queries/generated/queries";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
interface AnyObject {
    [key: string]: any
}
export default (values : AnyObject) => {
    const assetData = { ...values };
    if (!assetData.hasFrame) {
      assetData.hasOriginalFrame = Boolean(false);
      assetData.frameMaterial =  "";
      assetData.isValuableFrame = Boolean(false);
      assetData.frameManufacturer = "";
      assetData.frameDescription = "";
    }



      return assetData as Partial<AssetInput>;
}