/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Field } from "components/ui";
import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const DiscoveryView = ({ data }: AnyObject) => {
  const {
    category,
    discoveryPlace,
    discoverySite,
    discoveryDate,
    discoveryNotes,
    discoveryAuthor,
    catalogingAuthor,
    objectSetupAuthor,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Discovery")} subtitle="Lorem ipsum">
      <HStack spacing="16" w="full">
        <Field label="Discovery place" value={discoveryPlace} />
        <Field label="Discovery site" value={discoverySite} />
        <Field label="Discovery date" value={discoveryDate} />
      </HStack>
      <Field label="Notes" value={discoveryNotes} />
      <HStack spacing="16" w="full">
        <Field label="Discovery author" value={discoveryAuthor} />
        <Field label="Cataloging author" value={catalogingAuthor} />
        <Field label="Setup author" value={objectSetupAuthor} />
      </HStack>
    </BorderedBox>
  );
};

export default DiscoveryView;
