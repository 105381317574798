import { Money } from "graphql/queries/generated/queries";

export interface MoneyProp {
  amount?: number;
  currency?: Pick<Money["currency"], "code">;
}

const parseAmount = (money: MoneyProp | { amount: number; currency: string }) => {
  const { currency, amount } = money || {};

  let resolvedCurrency = null;
  let resolvedAmount = amount;

  if (!resolvedAmount) {
    resolvedAmount = 0;
  }

  if (!currency) {
    resolvedCurrency = "EUR";
  }

  if (currency && typeof currency !== "string" && Object.hasOwn(currency, "code")) {
    resolvedCurrency = currency.code;
  }

  if (typeof currency === "string") {
    resolvedCurrency = currency;
  }

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: resolvedCurrency,
  });

  return formatter.format(resolvedAmount);
};

export default parseAmount;
