import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import {
  GetConditionStatusReportByIdQuery,
  ConditionStatusReportInput,
  useUpdateConditionStatusReportMutation,
  useCloseConditionStatusReportMutation,
  Exact,
  AssetNode,
  useGetAssetByIdLazyQuery,
} from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { ConditionStatusReportInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  VStack,
  Stack,
  Text,
  HStack,
  ButtonGroup,
  Button,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import BorderedBox from "pages/assets/shared/BorderedBox";
import { ApolloQueryResult } from "@apollo/client";
import { Dot, Field } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { handleMutation, toast } from "middleware/Toaster";
import { MGMT_ROUTES } from "routes/constants";
import ConditionStatusReportFields from "../shared/ConditionStatusReportFields";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import NewAssetDrawer from "./NewAssetDrawer";

interface PageProps {
  data: GetConditionStatusReportByIdQuery;
  asset?: AssetNode;
  refetchConditionStatusReport: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetConditionStatusReportByIdQuery>>;
}

const Page = ({ data, asset, refetchConditionStatusReport }: PageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (
    data != null &&
    data.conditionStatusReport != null &&
    data.conditionStatusReport.reportStatus === "closed"
  ) {
    console.log("Report chiuso, procedo a visualizzazione.");

    toast({
      title: "Only view.",
      description: "This report is closed: you will be redirect to the view page.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.CRSR}/${data.conditionStatusReport.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  }

  const preloadedAsset: AssetNode = data.conditionStatusReport.reportAsset as AssetNode;

  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [userWantsToCreateAssets, setUserWantsToCreateAssets] = useState<boolean>(false);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [assetAdded, setAssetAdded] = useState(false);
  const [choosenAssetBigData, setChoosenAssetBigData] = useState(preloadedAsset);

  /**
   * onCompleteEvent e getAsset funzionano sia per il drawer degli
   * oggetti preesistenti sia per quello della creazione di nuovi
   * oggetti
   */

  const onCompleteEvent = (assetNode: AssetNode) => {
    toast({
      title: "Report not saved.",
      description: "Press the 'save' button to finalize the changes.",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });

    setChoosenAssetBigData(assetNode);
    setAssetAdded(true);
  };

  const [getAsset, { loading: loadingAsset, error: errorAsset, data: dataAsset }] =
    useGetAssetByIdLazyQuery({
      onCompleted: (result) => {
        onCompleteEvent(result.asset as AssetNode);
      },
    });

  const addExistingAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const onOpenExistingAssetDrawer = () => {
    setUserWantsToAddAssets(true);
  };

  const onOpenNewAssetDrawer = () => {
    setUserWantsToCreateAssets(true);
  };

  const {
    conditionStatusReport: { __typename, id, ...conditionStatusReport },
  } = data;

  const [updateConditionStatusReport, { data: updateConditionStatusReportData, error, loading }] =
    useUpdateConditionStatusReportMutation({
      ...handleMutation("Report updated!"),
    });

  const methods = useForm<ConditionStatusReportInput>({
    defaultValues: {
      environmentalConditionsAbnormalHumidityEvent:
        conditionStatusReport.environmentalConditionsAbnormalHumidityEvent,
      environmentalConditionsAbnormalLightEvent:
        conditionStatusReport.environmentalConditionsAbnormalLightEvent,
      environmentalConditionsAbnormalTemperatureEvent:
        conditionStatusReport.environmentalConditionsAbnormalTemperatureEvent,
      environmentalConditionsLightUnit: conditionStatusReport.environmentalConditionsLightUnit,
      environmentalConditionsOptimalHumidity:
        conditionStatusReport.environmentalConditionsOptimalHumidity,
      environmentalConditionsOptimalLightLevelIntensity:
        conditionStatusReport.environmentalConditionsOptimalLightLevelIntensity,
      environmentalConditionsOptimalTemperature:
        conditionStatusReport.environmentalConditionsOptimalTemperature,
      environmentalConditionsTemperatureUnit:
        conditionStatusReport.environmentalConditionsTemperatureUnit,
      exhibitionCurator: conditionStatusReport.exhibitionCurator
        ? conditionStatusReport.exhibitionCurator.id
        : "",
      exhibitionEventFrom: conditionStatusReport.exhibitionEventFrom,
      exhibitionEventTo: conditionStatusReport.exhibitionEventTo,
      exhibitionHostEntity: conditionStatusReport.exhibitionHostEntity
        ? conditionStatusReport.exhibitionHostEntity.id
        : "",
      exhibitionLender: conditionStatusReport.exhibitionLender
        ? conditionStatusReport.exhibitionLender.id
        : "",
      exhibitionLoanFrom: conditionStatusReport.exhibitionLoanFrom,
      exhibitionLoanTo: conditionStatusReport.exhibitionLoanTo,
      exhibitionLocation: conditionStatusReport.exhibitionLocation,
      exhibitionName: conditionStatusReport.exhibitionName,
      exhibitionNotes: conditionStatusReport.exhibitionNotes,
      exhibitionPlanner: conditionStatusReport.exhibitionPlanner
        ? conditionStatusReport.exhibitionPlanner.id
        : "",
      exhibitionPermanent: conditionStatusReport.exhibitionPermanent,
      exhibitionTitle: conditionStatusReport.exhibitionTitle,
      logisticsAirConditioningTransportConditions:
        conditionStatusReport.logisticsAirConditioningTransportConditions,
      logisticsCarrier: conditionStatusReport.logisticsCarrier
        ? conditionStatusReport.logisticsCarrier.id
        : "",
      logisticsDeliveryAddress: conditionStatusReport.logisticsDeliveryAddress,
      logisticsDeliveryCity: conditionStatusReport.logisticsDeliveryCity,
      logisticsDeliveryCountry: conditionStatusReport.logisticsDeliveryCountry,
      logisticsDeliveryDate: conditionStatusReport.logisticsDeliveryDate,
      logisticsDeliveryState: conditionStatusReport.logisticsDeliveryState,
      logisticsDeliveryZip: conditionStatusReport.logisticsDeliveryZip,
      logisticsId: conditionStatusReport.logisticsId,
      logisticsNumberOfPackages: conditionStatusReport.logisticsNumberOfPackages,
      logisticsPickupAddress: conditionStatusReport.logisticsPickupAddress,
      logisticsPickupCity: conditionStatusReport.logisticsPickupCity,
      logisticsPickupCountry: conditionStatusReport.logisticsPickupCountry,
      logisticsPickupDate: conditionStatusReport.logisticsPickupDate,
      logisticsPickupState: conditionStatusReport.logisticsPickupState,
      logisticsPickupZip: conditionStatusReport.logisticsPickupZip,
      logisticsRecipient: conditionStatusReport.logisticsRecipient
        ? conditionStatusReport.logisticsRecipient.id
        : "",
      logisticsSender: conditionStatusReport.logisticsSender
        ? conditionStatusReport.logisticsSender.id
        : "",
      reportStatus: conditionStatusReport.reportStatus,
      reportAsset: conditionStatusReport.reportAsset ? conditionStatusReport.reportAsset.id : "",
      reportCompany: conditionStatusReport.reportCompany
        ? conditionStatusReport.reportCompany.id
        : "",
      reportDate: conditionStatusReport.reportDate,
      reportDetailsAssetCondition: conditionStatusReport.reportDetailsAssetCondition,
      reportDetailsAssetDamageDescription:
        conditionStatusReport.reportDetailsAssetDamageDescription,
      reportDetailsExamConditions: conditionStatusReport.reportDetailsExamConditions,
      reportDetailsExamConditionsNote: conditionStatusReport.reportDetailsExamConditionsNote,
      reportDetailsExamLocation: conditionStatusReport.reportDetailsExamLocation,
      reportDetailsExhaminationTimeTechnicalDescription:
        conditionStatusReport.reportDetailsExhaminationTimeTechnicalDescription,
      reportDetailsPackagingGeneralConditions:
        conditionStatusReport.reportDetailsPackagingGeneralConditions,
      reportDetailsExaminedPackages: conditionStatusReport.reportDetailsExaminedPackages,
      reportDetailsPackagingStatusDescription:
        conditionStatusReport.reportDetailsPackagingStatusDescription,
      reportDetailsProtectionsSupportsCondition:
        conditionStatusReport.reportDetailsProtectionsSupportsCondition,
      reportDetailsProtectionsSupportsDamageDescription:
        conditionStatusReport.reportDetailsProtectionsSupportsDamageDescription,
      reportExaminationType: conditionStatusReport.reportExaminationType,
      reportExaminer: conditionStatusReport.reportExaminer
        ? conditionStatusReport.reportExaminer.id
        : "",
      reportMotivation: conditionStatusReport.reportMotivation,
      reportMotivationDescription: conditionStatusReport.reportMotivationDescription,
      reportType: conditionStatusReport.reportType,
      settingsConservation: conditionStatusReport.settingsConservation,
      settingsExaminerComments: conditionStatusReport.settingsExaminerComments,
      settingsHandlingRequirements: conditionStatusReport.settingsHandlingRequirements,
      settingsHasBarriers: conditionStatusReport.settingsHasBarriers
        ? conditionStatusReport.settingsHasBarriers
        : false,
      settingsInjuryRisk: conditionStatusReport.settingsInjuryRisk,
      settingsLenderRequests: conditionStatusReport.settingsLenderRequests,
      settingsNotes: conditionStatusReport.settingsNotes,
      valueEstimatedValue: conditionStatusReport.valueEstimatedValue
        ? {
            amount: conditionStatusReport.valueEstimatedValue.amount,
            currency: conditionStatusReport.valueEstimatedValue.currency.code,
          }
        : {
            amount: 0,
            currency: "EUR",
          },
      valueEstimatedValueExaminationTime: conditionStatusReport.valueEstimatedValueExaminationTime
        ? {
            amount: conditionStatusReport.valueEstimatedValueExaminationTime.amount,
            currency: conditionStatusReport.valueEstimatedValueExaminationTime.currency.code,
          }
        : {
            amount: 0,
            currency: "EUR",
          },
      valuePercentageDepreciation: conditionStatusReport.valuePercentageDepreciation,
    },
    resolver: yupResolver(ConditionStatusReportInputSchema()),
  });

  const addNewAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    console.log(errors);

    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof ConditionStatusReportInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const [closeConditionStatusReport, { loading: closeLoading }] =
    useCloseConditionStatusReportMutation({
      ...handleMutation("Report closed!"),
    });

  const onCloseReport = async (id: string) => {
    await closeConditionStatusReport({
      variables: {
        input: {
          id,
        },
      },
    });

    toast({
      title: "Report succeffuly closed.",
      description: "This report is closed: you will be redirect to the view page.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.CRSR}/${data.conditionStatusReport.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  };

  const onSubmit: SubmitHandler<ConditionStatusReportInput> = async (formData, e) => {
    let selectedReportAsset: string = null;
    let payload = null;

    if (assetAdded) {
      selectedReportAsset = choosenAssetBigData.id;

      payload = {
        variables: {
          input: {
            id,
            conditionStatusReportData: {
              ...formData,

              reportAsset: selectedReportAsset,
            },
          },
        },
      };
    } else {
      payload = {
        variables: {
          input: {
            id,
            conditionStatusReportData: {
              ...formData,
            },
          },
        },
      };
    }

    await updateConditionStatusReport(payload);

    setAssetAdded(false);
    setChoosenAssets([]);

    toast({
      title: "Report succeffuly saved.",
      description: "This report is closed: you will be redirect to the view page.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    await refetchConditionStatusReport({ id });

    return null;
  };

  return (
    <Stack spacing="4">
      <VStack alignItems="flex-start" pr={8}>
        <Dot
          positiveLabel={t("Draft")}
          negativeLabel={t("Closed")}
          status={data.conditionStatusReport.reportStatus === "draft"}
        />
        {data.conditionStatusReport.reportStatus === "draft" && (
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button variant="primary">
                    <Trans>Close</Trans>
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                    <PopoverArrow />
                    <PopoverHeader>{t("You are closing the report")}</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>{t("Warning! the operation is not reversible!")}</PopoverBody>

                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pb={4}
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="danger"
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            onCloseReport(id);
                            onClose();
                          }}
                        >
                          <Trans>Close it</Trans>
                        </Button>
                        <Button onClick={onClose}>
                          <Trans>Cancel</Trans>
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        )}
      </VStack>

      <FormProvider {...methods}>
        <ValidationProvider schema={ConditionStatusReportInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <BorderedBox title={t("Asset")} subtitle="Lorem ipsum dolor sit amet">
                <HStack justifyContent="space-between" alignItems="flex-start">
                  <Field
                    label="ID"
                    value={
                      choosenAssetBigData != null && choosenAssetBigData.id != null
                        ? choosenAssetBigData.id
                        : ""
                    }
                  />
                  <Field
                    label="Title"
                    value={
                      choosenAssetBigData != null && choosenAssetBigData.title != null
                        ? choosenAssetBigData.title
                        : ""
                    }
                  />
                  <Field
                    label="Author"
                    value={
                      choosenAssetBigData.authorEntity != null &&
                      choosenAssetBigData.authorEntity.fullName
                        ? choosenAssetBigData.authorEntity.fullName
                        : ""
                    }
                  />
                  <Field
                    label="Date of creation"
                    value={
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      choosenAssetBigData.actualDateAndPeriod !== null &&
                      choosenAssetBigData.actualDateAndPeriod !== ""
                        ? choosenAssetBigData.actualDateAndPeriod
                        : ""
                    }
                  />

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button onClick={onOpenExistingAssetDrawer} variant="primary">
                      <Trans>Choose new object</Trans>
                    </Button>
                    <Button onClick={onOpenNewAssetDrawer} variant="primary">
                      <Trans>Create and add new object</Trans>
                    </Button>
                  </ButtonGroup>
                </HStack>
              </BorderedBox>

              <ConditionStatusReportFields
                footerData={updateConditionStatusReportData}
                loading={loading}
                errors={[error]}
                disabled={data.conditionStatusReport.reportStatus !== "draft"}
                watch={methods.watch}
                data={data}
                asset={choosenAssetBigData}
              />
            </Stack>
          </form>
        </ValidationProvider>
      </FormProvider>

      {/**
       * Per lo meno il NewAssetDrawer DEVE stare fuori dal tag <form> (e quindi da ValidationProvider e FormProvider)
       * perche' altrimenti il submit del drawer causa il submit della form esterna.
       * Poi in generale non e' certo una buona idea annidare due form...
       */}
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addAsset={addExistingAsset}
        choosenAssets={choosenAssets}
      />
      <NewAssetDrawer
        show={userWantsToCreateAssets}
        onClose={() => setUserWantsToCreateAssets(false)}
        addAsset={addNewAsset}
      />

      {assetAdded && (
        <Alert status="warning">
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="Press the 'save' button to finalize the changes" />
            </Text>
          </VStack>
        </Alert>
      )}
    </Stack>
  );
};

export default Page;
