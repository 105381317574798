/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useTranslation, Trans } from "react-i18next";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Heading,
  useToast,
} from "@chakra-ui/react";
import {
  GetAssetByIdQuery,
  useUpdateAssetMutation,
  AssetInput,
  UpdateAssetInput,
  Exact,
  useDeleteAssetCarryingValueFileMutation,
} from "graphql/queries/generated/queries";
import { AssetInputSchema } from "graphql/queries/overrides";

import { AssetCard, FormFooter } from "components/ui";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// import cleanObject from "helpers/cleanObject";
import { ValidationProvider } from "components/form/ValidationContext";
import { ApolloQueryResult } from "@apollo/client";
import { handleMutation } from "middleware/Toaster";
import PanelNewCategoryForm from "../new/PanelNewCategoryForm";
import OriginNewCategoryForm from "../new/origin/OriginNewCategoryForm";
import BibliographyNewCategoryForm from "../new/origin/BibliographyNewCategoryForm";
import AssetCarryingValueForm from "../new/values/AssetCarryingValueForm";
import AssetPresentValueForm from "../new/values/AssetPresentValueForm";

import clearHistoricDateValues from "../utils/ClearHistoricDateValues";
import clearPaintingFrameValues from "../utils/ClearPaintingFrameValues";
import AssetMediaForm from "../new/media/AssetMediaForm";
import AssetDocumentForm from "../new/documents/AssetDocumentForm";
import EventLogs from "../new/eventlog/EventLogs";

interface EditAssetFormProps {
  data: GetAssetByIdQuery;
  refetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAssetByIdQuery>>;
}

// interface NewCategoryFormProps {
//   category: string;
//   children?: React.ReactNode;
//   resetCategory:  (e: React.MouseEvent<HTMLButtonElement>) => void

// }

const all_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "note", label: "Notes" },
];

const archeo_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "rinvenimento", label: "Discovery" },
  { key: "note", label: "Notes" },
];

const natural_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "tassonomia", label: "Taxonomy/Classification" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "rinvenimento", label: "Discovery" },
  { key: "note", label: "Notes" },
];

const EditAssetForm = ({ data, refetch }: EditAssetFormProps) => {
  const {
    asset: {
      policyAsset,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      uuid,
      mainImage,
      genericDimensions,
      actualDateAndPeriod,
      fineness,
      carryingValueAmountCurrency,
      authorEntity,
      printer,
      videomaker,
      manufacturer,
      producer,
      collection,
      exhibition,
      media,
      presentValues,
      physicalFeaturesElements,
      documents,
      __typename,
      deletedByCascade,
      deleted,
      id,
      ...asset
    },
  } = data;

  let steps = all_steps;
  if (asset.category.toUpperCase() === "ARCHEOLOGY") {
    steps = archeo_steps;
  }
  if (asset.category.toUpperCase() === "NATURALISTIC_GOODS") {
    steps = natural_steps;
  }
  if (asset.category.toUpperCase() === "NUMISMATIC_COINS") {
    steps = natural_steps;
  }
  if (asset.category.toUpperCase() === "NUMISMATIC_BANKNOTES") {
    steps = natural_steps;
  }
  if (asset.category.toUpperCase() === "PHILATELY") {
    steps = natural_steps;
  }

  const toast = useToast();

  const { t } = useTranslation();

  // const [id, setId] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState(0);

  const [currentCarryingValueFile, setCurrentCarryingValueFile] = useState<string | null>(
    data.asset?.carryingValueFile ?? null
  );

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  // const goToNextTab = () => {
  //   setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  // };

  const [
    updateAsset,
    { data: updateAssetData, loading: updateAssetLoading, error: updateAssetError },
  ] = useUpdateAssetMutation({
    ...handleMutation("Asset updated!"),
  });

  const [
    deleteAssetCarryingValueFile,
    {
      data: deleteAssetCarryingValueFileData,
      loading: deleteAssetCarryingValueFileLoading,
      error: deleteAssetCarryingValueFileError,
    },
  ] = useDeleteAssetCarryingValueFileMutation({
    ...handleMutation("Value deleted!"),
  });

  const deleteCarryingValueFile = async (assetId: string) => {
    const response = await deleteAssetCarryingValueFile({
      variables: {
        input: {
          assetId,
        },
      },
    });
    if (
      response &&
      response.data &&
      response.data.deleteAssetCarryingValueFile &&
      response.data.deleteAssetCarryingValueFile.asset &&
      response.data.deleteAssetCarryingValueFile.asset.id
    ) {
      setCurrentCarryingValueFile(null);
    }
    return response.data.deleteAssetCarryingValueFile.asset;
  };

  const methods = useForm<AssetInput>({
    defaultValues: {
      ...asset,

      carryingValueAmount: {
        amount: asset?.carryingValueAmount?.amount,
        currency: asset?.carryingValueAmount?.currency.code,
      },
      authorEntityId: authorEntity?.id,
      printerId: printer?.id,
      videomakerId: videomaker?.id,

      manufacturerId: manufacturer?.id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      producerId: producer?.id,
      // authorEntityId: asset?.authorEntity?.id,
      hasBarriers: asset.hasBarriers === null ? Boolean(false) : asset.hasBarriers,
      hasBase: asset.hasBase === null ? Boolean(false) : asset.hasBase,
      hasLabelsStamps: asset.hasLabelsStamps === null ? Boolean(false) : asset.hasLabelsStamps,
      hasOtherAutographed:
        asset.hasOtherAutographed === null ? Boolean(false) : asset.hasOtherAutographed,
      hasWoodFrame: asset.hasWoodFrame === null ? Boolean(false) : asset.hasWoodFrame,
      hasContainer: asset.hasContainer === null ? Boolean(false) : asset.hasContainer,
      isArtMultiple: asset.isArtMultiple === null ? Boolean(false) : asset.isArtMultiple,
      isEdition: asset.isEdition === null ? Boolean(false) : asset.isEdition,
      isMaster: asset.isMaster === null ? Boolean(false) : asset.isMaster,
      isArtistProof: asset.isArtistProof === null ? Boolean(false) : asset.isArtistProof,
      isComposed: asset.isComposed === null ? Boolean(false) : asset.isComposed,
      isDatedObject: asset.isDatedObject === null ? Boolean(false) : asset.isDatedObject,
      isNotifiedCulturalProperty:
        asset.isNotifiedCulturalProperty === null
          ? Boolean(false)
          : asset.isNotifiedCulturalProperty,
      isSignedObject: asset.isSignedObject === null ? Boolean(false) : asset.isSignedObject,
      isTitledObject: asset.isTitledObject === null ? Boolean(false) : asset.isTitledObject,
    },
    resolver: yupResolver(AssetInputSchema()),
    mode: "onChange",
  });

  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmit: SubmitHandler<AssetInput> = async (values, e) => {
    console.log("ON SUBMIT");
    let assetData = clearHistoricDateValues(values);
    assetData = clearPaintingFrameValues(assetData);

    assetData.carryingValueAmount = {
      ...assetData.carryingValueAmount,
      // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
      currency: assetData.carryingValueAmount.currency || "EUR",
    };
    const response = await updateAsset({
      variables: {
        input: {
          assetData: {
            ...(assetData as UpdateAssetInput["assetData"]),
            validated: assetData.category !== "NOT_VALIDATED",
            // dateStartDate: (assetData.dateStartDate === "" || assetData.dateStartDate === undefined) ? null : `${assetData.dateStartDate as string}T00:00:00`,
            // dateEndDate: (assetData.dateEndDate === "" || assetData.dateEndDate === undefined) ? null : `${assetData.dateEndDate as string}T00:00:00`,
          },
          id: String(data.asset.id),
        },
      },
    });

    if (response.data && response.data.updateAsset && response.data.updateAsset.asset) {
      setCurrentCarryingValueFile(response.data.updateAsset.asset?.carryingValueFile ?? null);
    }
  };

  const handleSubmit = methods.handleSubmit(onSubmit, onError);
  // const handleSubmitStep = async (nextStepFunction: string) => {
  //   setNextStepName(nextStepFunction);
  //   await handleSubmit();
  // }
  // return <div>Category: {category}, step : {activeStep}</div>;
  console.log({ asset });
  return (
    <>
      <AssetCard {...asset} authorEntity={authorEntity} />
      <FormProvider {...methods}>
        <ValidationProvider schema={AssetInputSchema()}>
          <form
            id="update_asset"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit}
          >
            {asset.category.toString() === "NOT_VALIDATED" && (
              <PanelNewCategoryForm
                category={asset.category}
                subcategory={asset.subcategory}
                id={data.asset.id}
                panel={undefined}
                handleSubmit={handleSubmit}
                getValues={methods.getValues}
                register={methods.register}
                setValue={methods.setValue}
                setError={methods.setError}
                watch={methods.watch}
                refetchAsset={refetch}
              >
                {/* <Button onClick={nextStep}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button> */}
                {/* {children} */}

                {/* <Button type="reset" onClick={(e) => resetCategory(e)}>
                    Annulla
                  </Button> */}
              </PanelNewCategoryForm>
            )}
            {asset.category.toString() !== "NOT_VALIDATED" && (
              <Tabs
                colorScheme="brand"
                variant="enclosed"
                mt="4"
                index={tabIndex}
                onChange={handleTabChange}
              >
                <TabList>
                  <Tab>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      1
                    </Badge>
                    <Trans>Info</Trans>
                  </Tab>

                  <Tab isDisabled={data.asset.id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      2
                    </Badge>
                    <Trans>Origin and bibliography</Trans>
                  </Tab>
                  <Tab isDisabled={data.asset.id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      3
                    </Badge>
                    <Trans>Value</Trans>
                  </Tab>
                  <Tab isDisabled={data.asset.id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      4
                    </Badge>
                    <Trans>Event log</Trans>
                  </Tab>
                  <Tab isDisabled={data.asset.id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      5
                    </Badge>
                    <Trans>Documents</Trans>
                  </Tab>
                  <Tab isDisabled={data.asset.id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      6
                    </Badge>
                    <Trans>Media</Trans>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={3}>
                    <Accordion allowToggle defaultIndex={0} allowMultiple>
                      {steps.map(({ label, key }, index) => {
                        const panelStyle = {
                          display: "block",
                          margin: "0 1.2rem",
                          borderLeft: "2px solid #e2e8f0",
                        };
                        if (index === steps.length - 1) {
                          panelStyle.borderLeft = "none;";
                        }
                        if (
                          key === "info_generali" ||
                          key === "dimensioni" ||
                          key === "tassonomia" ||
                          key === "aspetti_legali" ||
                          key === "dettagli_tecnici" ||
                          key === "caratteristiche_fisiche" ||
                          key === "allestimento" ||
                          key === "condizioni_ambientali" ||
                          key === "rinvenimento" ||
                          key === "note"
                        ) {
                          return (
                            <AccordionItem border={0}>
                              <h2>
                                <AccordionButton
                                  mb={0}
                                  pl={1}
                                  style={{ width: "100%", border: "0" }}
                                >
                                  <Badge
                                    style={{
                                      borderRadius: "50%",
                                      width: 32,
                                      height: 32,
                                      lineHeight: "28px",
                                      border: "2px solid #E2E8F0",
                                    }}
                                    colorScheme="blackAlpha"
                                    ml={0}
                                    mr={2}
                                    p={0}
                                    rounded="full"
                                    fontWeight={700}
                                  >
                                    {index + 1}
                                  </Badge>
                                  <Heading as="h2" size="sm">
                                    <Trans>{label}</Trans>
                                  </Heading>

                                  <AccordionIcon style={{ marginLeft: "auto" }} />
                                </AccordionButton>
                                {index !== steps.length - 1 && (
                                  <div
                                    style={{
                                      height: "1rem",
                                      marginLeft: "1.2rem",
                                      borderLeft: "2px solid #e2e8f0",
                                    }}
                                  />
                                )}
                              </h2>
                              <AccordionPanel pt={3} pb={4} style={panelStyle}>
                                <PanelNewCategoryForm
                                  category={asset.category}
                                  subcategory={asset.subcategory}
                                  id={data.asset.id}
                                  panel={key}
                                  handleSubmit={handleSubmit}
                                  getValues={methods.getValues}
                                  register={methods.register}
                                  setValue={methods.setValue}
                                  setError={methods.setError}
                                  watch={methods.watch}
                                  refetchAsset={refetch}
                                />
                              </AccordionPanel>
                            </AccordionItem>
                          );
                        }
                        return null;
                      })}
                    </Accordion>
                  </TabPanel>
                  <TabPanel p={3}>
                    <Accordion allowToggle defaultIndex={[0, 1]} allowMultiple>
                      <AccordionItem border={0}>
                        <h2>
                          <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                            <Badge
                              style={{
                                borderRadius: "50%",
                                width: 32,
                                height: 32,
                                lineHeight: "28px",
                                border: "2px solid #E2E8F0",
                              }}
                              colorScheme="blackAlpha"
                              ml={0}
                              mr={2}
                              p={0}
                              rounded="full"
                              fontWeight={700}
                            >
                              1
                            </Badge>
                            <Heading as="h2" size="sm">
                              <Trans>Origin</Trans>
                            </Heading>

                            <AccordionIcon style={{ marginLeft: "auto" }} />
                          </AccordionButton>
                          <div
                            style={{
                              height: "1rem",
                              marginLeft: "1.2rem",
                              borderLeft: "2px solid #e2e8f0",
                            }}
                          />
                        </h2>
                        <AccordionPanel
                          pt={3}
                          pb={4}
                          style={{
                            display: "block",
                            margin: "0 1.2rem",
                            borderLeft: "2px solid #e2e8f0",
                          }}
                        >
                          <OriginNewCategoryForm
                            // handleSubmit={formik.handleSubmit}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}

                            category={asset.category}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem border={0}>
                        <h2>
                          <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                            <Badge
                              style={{
                                borderRadius: "50%",
                                width: 32,
                                height: 32,
                                lineHeight: "28px",
                                border: "2px solid #E2E8F0",
                              }}
                              colorScheme="blackAlpha"
                              ml={0}
                              mr={2}
                              p={0}
                              rounded="full"
                              fontWeight={700}
                            >
                              2
                            </Badge>
                            <Heading as="h2" size="sm">
                              <Trans>Bibliography</Trans>
                            </Heading>

                            <AccordionIcon style={{ marginLeft: "auto" }} />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pt={3}
                          pb={4}
                          style={{ display: "block", margin: "0 1.2rem" }}
                        >
                          <BibliographyNewCategoryForm
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            category={asset.category}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </TabPanel>
                  <TabPanel p={3}>
                    <Accordion allowToggle defaultIndex={[0, 1]} allowMultiple>
                      <AccordionItem border={0}>
                        <h2>
                          <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                            <Badge
                              style={{
                                borderRadius: "50%",
                                width: 32,
                                height: 32,
                                lineHeight: "28px",
                                border: "2px solid #E2E8F0",
                              }}
                              colorScheme="blackAlpha"
                              ml={0}
                              mr={2}
                              p={0}
                              rounded="full"
                              fontWeight={700}
                            >
                              1
                            </Badge>
                            <Heading as="h2" size="sm">
                              <Trans>Carrying value</Trans>
                            </Heading>

                            <AccordionIcon style={{ marginLeft: "auto" }} />
                          </AccordionButton>
                          <div
                            style={{
                              height: "1rem",
                              marginLeft: "1.2rem",
                              borderLeft: "2px solid #e2e8f0",
                            }}
                          />
                        </h2>
                        <AccordionPanel
                          pt={3}
                          pb={4}
                          style={{
                            display: "block",
                            margin: "0 1.2rem",
                            borderLeft: "2px solid #e2e8f0",
                          }}
                        >
                          <AssetCarryingValueForm
                            // handleSubmit={formik.handleSubmit}
                            // currentFile={asset.carryingValueFile}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}

                            category={asset.category}
                            currentCarryingValueFile={currentCarryingValueFile}
                            assetId={data.asset.id}
                            deleteCarryingValueFile={deleteCarryingValueFile}
                            deleteCarryingValueFileLoading={deleteAssetCarryingValueFileLoading}
                          />
                        </AccordionPanel>
                      </AccordionItem>

                      <AccordionItem border={0}>
                        <h2>
                          <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                            <Badge
                              style={{
                                borderRadius: "50%",
                                width: 32,
                                height: 32,
                                lineHeight: "28px",
                                border: "2px solid #E2E8F0",
                              }}
                              colorScheme="blackAlpha"
                              ml={0}
                              mr={2}
                              p={0}
                              rounded="full"
                              fontWeight={700}
                            >
                              2
                            </Badge>
                            <Heading as="h2" size="sm">
                              <Trans>Present values</Trans>
                            </Heading>

                            <AccordionIcon style={{ marginLeft: "auto" }} />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pt={3}
                          pb={4}
                          style={{ display: "block", margin: "0 1.2rem" }}
                        >
                          <AssetPresentValueForm assetId={data.asset.id} />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </TabPanel>
                  <TabPanel>
                    <EventLogs assetId={data.asset.id} />
                  </TabPanel>
                  <TabPanel>
                    <AssetDocumentForm assetId={data.asset.id} />
                  </TabPanel>
                  <TabPanel>
                    <AssetMediaForm assetId={data.asset.id} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
            <FormFooter
              sticky
              data={updateAssetData}
              errors={[updateAssetError]}
              loading={updateAssetLoading}
              title="Asset entry"
            />
          </form>
        </ValidationProvider>
      </FormProvider>
    </>
  );
};

export default EditAssetForm;
