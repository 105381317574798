/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Badge,
  Button,
  Divider,
  Heading,
  HStack,
  VStack,
  Skeleton,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useToast,
  Image,
} from "@chakra-ui/react";
import {
  QuickActions,
  RegistryAwareField,
  Field,
  DetailsPageSkeleton,
  BorderedBox,
  NoDataPlaceholder,
  SBox,
} from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import { useState } from "react";
import {
  MediaNode,
  useDeleteAssetDocumentMutation,
  useDeleteAssetMediaMutation,
  useGetAssetByIdQuery,
  useDeleteAssetPresentValueMutation,
  useExportAssetPdfMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import parseDate from "helpers/parseDate";
import AssetDating from "../shared/AssetDating";
import GlobalInfoView from "../shared/GlobalInfoView";
import DimensionsView from "../shared/DimensionsView";
import LegalAspectsView from "../shared/LegalAspectsView";
import TechnicalDetailsView from "../shared/TechnicalDetailsView";
import PhysicalFeaturesView from "../shared/PhysicalFeaturesView";
import SettingView from "../shared/SettingView";
import EnvironmentalConditionsView from "../shared/EnvironmentalConditionsView";
import DiscoveryView from "../shared/DiscoveryView";
import TaxonomyView from "../shared/TaxonomyView";
import CarryingValueView from "../shared/CarryingValueView";
import PresentValueView from "../shared/PresentValueView";
import OnViewAssetMediaTable from "../shared/OnViewAssetMediaTable";
import OnViewAssetDocumentsTable from "../shared/OnViewAssetDocumentsTable";
import AssetDocumentView from "../shared/AssetDocumentView";
import OnViewAssetPresentValuesTable from "../shared/OnViewAssetPresentValuesTable";
import SidebarInfo from "../../../components/ui/SidebarInfo/index";
import OnViewEventLogs from "../new/eventlog/OnViewEventLogs";

const AssetViewPage = () => {
  const { t } = useTranslation();
  const { assetId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [
    exportAssetPdf,
    { data: exportAssetPdfData, loading: exportAssetPdfLoading, error: exportAssetPdfError },
  ] = useExportAssetPdfMutation({
    ...handleMutation("Asset exported!"),
  });
  const toast = useToast();

  const onGeneratePdf = async () => {
    const { data } = await exportAssetPdf({
      variables: {
        input: {
          assetId,
        },
      },
    });

    const {
      exportAssetPdf: { url },
    } = data;

    // window.open(url);
    await downloadFile(url);
  };

  const [selectedDocument, setSelectedDocument] = useState<{
    id: string;
    title: string;
    description: string;
    documentType: string;
    coaType: string;
    issuer: string;
    recipient: string;
    isClassified: boolean;
    releaseDate: string;
    expirationDate: string;
    notes: string;
    archiveNumber: string;
    fineArtsIssueType: string;
    isTemporaryExport: boolean;
    isTemporaryImport: boolean;
    isCriticalCatalog: boolean;
    editor: string;
    curator: string;
    subscriptionDate: string;
    contractSubscriber: string;
    publishingDate: string;
    otherDocumentType: string;
    attachments: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string;
      file: string;
    }[];
  } | null>(null);

  const [selectedPresentValue, setSelectedPresentValue] = useState<{
    id: string;
    authorId: string;
    authorName: string;
    estimateDate: string;
    estimateReason: string;
    notes: string;
    fileTitle: string;
    file: string;
    amount: string;
  } | null>(null);

  const {
    isOpen: isOpenDocumentModal,
    onOpen: onOpenDocumentModal,
    onClose: onCloseDocumentModal,
  } = useDisclosure();
  const {
    isOpen: isOpenPresentValueModal,
    onOpen: onOpenPresentValueModal,
    onClose: onClosePresentValueModal,
  } = useDisclosure();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetAssetByIdQuery({
    variables: { id: assetId ?? "" },
  });

  const [
    deleteAssetMedia,
    { data: deleteAssetMediaData, error: deleteAssetMediaError, loading: deleteAssetMediaLoading },
  ] = useDeleteAssetMediaMutation({
    ...handleMutation("Asset media deleted!"),
  });

  const [
    deleteAssetDocument,
    {
      data: deleteAssetDocumentData,
      error: deleteAssetDocumentError,
      loading: deleteAssetDocumentLoading,
    },
  ] = useDeleteAssetDocumentMutation({
    ...handleMutation("Asset document deleted!"),
  });

  const [
    deleteAssetPresentValue,
    {
      data: deleteAssetPresentValueData,
      error: deleteAssetPresentValueError,
      loading: deleteAssetPresentValueLoading,
    },
  ] = useDeleteAssetPresentValueMutation({
    ...handleMutation("Asset present value deleted!"),
  });

  const onDeletePresentValue = async (id: string) => {
    await deleteAssetPresentValue({
      variables: {
        input: {
          id,
        },
      },
    });
    const result = await refetch({
      id: assetId,
    });
    const edges = result?.data?.asset?.presentValues?.edges ?? [];
    return edges.flatMap((e) => ({ ...e.node }));
  };

  const onDeleteMedia = async (id: string) => {
    await deleteAssetMedia({
      variables: {
        input: {
          mediaId: id,
          id: assetId,
        },
      },
    });

    const result = await refetch({
      id: assetId,
    });

    const edges = result?.data?.asset?.media?.edges ?? [];
    return edges.flatMap((e) => ({ ...e.node }));
  };

  const onDeleteDocument = async (id: string) => {
    await deleteAssetDocument({
      variables: {
        input: {
          id,
        },
      },
    });

    const result = await refetch({
      id: assetId,
    });

    const edges = result?.data?.asset?.documents?.edges ?? [];
    return edges.flatMap((e) => {
      const edges = e?.node?.attachments.edges ?? [];
      const attachments = edges.flatMap((a) => ({ ...a.node }));
      return { ...e.node, attachments, attachmentCount: attachments.length };
    });
  };

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.asset) return null;

  const handleTabChange = (index: number) => setTabIndex(index);

  const {
    asset: {
      // summary
      id,
      title,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      technique,
      genericDimensions,
      category,
      subcategory,
      description,

      // datazione
      datingType,
      isDateInterval,
      dateStartDate,
      dateEndDate,
      isDateStartDateBc,
      isDateEndDateBc,
      isDateApprox,
      isYearInterval,
      yearStartDate,
      yearEndDate,
      isYearStartDateBc,
      isYearEndDateBc,
      isYearApprox,
      isCenturyInterval,
      centuryStartDate,
      centuryEndDate,
      isCenturyStartDateBc,
      isCenturyEndDateBc,
      isCenturyApprox,
      centuryStartDateWindow,
      centuryEndDateWindow,
      creationPeriod,
      creationDateNotes,

      // global info
      series,
      seriesDetails,
      catalogNumber,
      inventoryNumber,
      archiveNumber,
      criticalCatalogue,
      nft,
      nftNotes,
      printer,
      paperType,
      productionMode,
      placement,
      videomaker,

      manufacturer,
      producer,
      otherModel,
      banknotePrinter,
      commonName,
      scientificName,
      material,
      internationalCode,
      coinMintIssuer,
      coinPlacement,
      coinPeriod,
      coinPlacementNotes,
      coinIssueReason,
      coinCirculationPeriod,
      coinComposition,
      coinIdentificationCode,
      coverType,
      printingType,

      // dimensions
      netLengthUnit,
      netWidth,
      netHeight,
      netDepth,
      netDiameter,
      netWeightUnit,
      netWeight,
      netDimensionsAcquisition,
      grossLengthUnit,
      grossWidth,
      grossHeight,
      grossDepth,
      grossDiameter,
      grossWeightUnit,
      grossWeight,
      grossDimensionsAcquisition,
      dimensionsNotes,
      audioVideoLengthUnit,
      audioVideoLength,
      fileSizeUnit,
      fileSize,
      resolutionUnit,
      resolution,

      // legal aspects
      isNotifiedCulturalProperty,
      notifiedPropertyNote,
      legalStatus,
      copyrightAndPropertyRestrictions,

      // technical details
      isArtistProof,
      artistProofNotes,
      isEdition,
      editionNotes,
      isArtMultiple,
      multipleNumber,
      isSignedObject,
      signedObjectNotes,
      isTitledObject,
      titledObjectNotes,
      isDatedObject,
      datedObjectNotes,
      hasLabelsStamps,
      labelsStampsNotes,
      hasOtherAutographed,
      otherAutographedNotes,
      relevantAwards,
      isDynamic,
      dynamicNotes,
      hasHumanAnimalPresence,
      humanAnimalPresenceNotes,
      isAudio,
      isVideo,
      hasLoop,
      isMaster,
      masterLocation,
      coloring,
      variants,
      nominalValue,
      fiscalValue,
      currentValue,
      currency,
      score,
      circulation,
      manufactoringTechniques,
      mintingLastDate,
      mintingLastDateNotes,
      reminting,
      printingLastDate,
      printingLastDateNotes,
      reprinting,
      isFirstDraft,
      firstDraftNotes,
      mechanism,
      carat,

      // physical features
      stateOfConservation,
      isComposed,
      numberOfElements,
      physicalFeaturesElements,
      surfaceCharacteristics,
      hasBase,
      baseDetails,
      hasContainer,
      containerDetails,
      hasOriginalFrame,
      frameMaterial,
      isValuableFrame,
      frameManufacturer,
      frameDescription,
      hasFrame,
      hasWoodFrame,
      woodFrameDetails,
      compositionDescription,
      methodOfUse,
      relatedPhysicalWork,
      conservationMethod,
      distribution,
      edge,
      orientation,
      shape,
      rim,
      rotationAxes,
      legend,
      coinTitle,
      mintMadeErrors,
      signings,
      relief,
      watermark,
      holographicStripe,
      securityThread,
      printingErrors,
      color,
      postageMark,
      rubber,
      stampCenteringAndMargins,
      stampPrintingTechnique,
      stampFormat,
      stampRealization,
      stampIndentationType,
      bookContainer,
      dustJacket,
      frontCover,
      rearCover,
      topEdge,
      foreEdge,
      footEdge,
      bookbinding,
      interiorDecorations,
      externalDecorations,
      flyleaf,
      glueing,
      headband,
      headcap,
      corner,
      square,
      slots,
      spine,
      clips,
      raisedBands,
      label,
      clothCovering,
      usage,
      replacedParts,

      // setting
      hasBarriers,
      barriersDetails,
      handlingRequirements,
      injuryRisk,
      injuryRiskDescription,
      mouldingSupportBase,
      hardwareToolsInstallation,
      conservation,
      lenderRequests,
      settingNotes,

      // environmental conditions
      optimalLightLevelIntensity,
      lightUnit,
      optimalTemperature,
      temperatureUnit,
      optimalHumidity,
      enviromentalConditionsNotes,

      infoNotes,

      // campi discovery
      discoveryPlace,
      discoverySite,
      discoveryDate,
      discoveryNotes,
      discoveryAuthor,
      catalogingAuthor,
      objectSetupAuthor,

      // campi taxonomy
      taxonomyDomain,
      taxonomyKingdom,
      taxonomyPhylum,
      taxonomyClass,
      taxonomyOrder,
      taxonomyFamily,
      taxonomyTribe,
      taxonomyGenus,
      taxonomyNotes,
      classificationUsa,
      classificationIta,
      classificationEng,
      classificationFra,
      classificationSpa,
      classificationGer,
      classificationPhilatelic,
      rarityDegreePhilatelic,
      rarityDegree,
      conservationState,
      rarityDegreeNotes,

      // TAB provenanca and literature
      originNotes,
      bibliographyNotes,

      // TAB values
      presentValues,
      carryingValueAmountCurrency,
      carryingValueAcquisition,
      carryingValueAmount,
      carryingValueFile,
      carryingValueFileTitle,
      carryingValueLastAcquisitionDate,
      carryingValueNotes,
      carryingValueOrigin,

      authorEntity,
      mainImage,
      media,
      documents,
    },
  } = data;

  const { id: authorId, fullName: authorName } = authorEntity ?? {};
  const { id: printerId, fullName: printerName } = printer ?? {};
  const { id: videomakerId, fullName: videomakerName } = videomaker ?? {};

  const { id: manufacturerId, fullName: manufacturerName } = manufacturer ?? {};
  const { id: producerId, fullName: producerName } = producer ?? {};

  const datingData = {
    datingType,
    isDateInterval,
    dateStartDate,
    dateEndDate,
    isDateStartDateBc,
    isDateEndDateBc,
    isDateApprox,
    isYearInterval,
    yearStartDate,
    yearEndDate,
    isYearStartDateBc,
    isYearEndDateBc,
    isYearApprox,
    isCenturyInterval,
    centuryStartDate,
    centuryEndDate,
    isCenturyStartDateBc,
    isCenturyEndDateBc,
    isCenturyApprox,
    centuryStartDateWindow,
    centuryEndDateWindow,
    creationPeriod,
  };

  const globalInfoData = {
    category,
    creationDateNotes,
    series,
    seriesDetails,
    catalogNumber,
    inventoryNumber,
    archiveNumber,
    criticalCatalogue,
    nft,
    nftNotes,
    printerId,
    printerName,
    paperType,
    productionMode,
    placement,
    videomakerId,
    videomakerName,

    manufacturerId,

    manufacturerName,
    banknotePrinter,
    commonName,
    scientificName,
    material,
    internationalCode,
    coinMintIssuer,
    coinPlacement,
    coinPeriod,
    coinPlacementNotes,
    coinIssueReason,
    coinCirculationPeriod,
    coinComposition,
    coinIdentificationCode,
    coverType,
    printingType,
    producerId,
    producerName,
    otherModel,
  };

  const dimensionsData = {
    category,
    netLengthUnit,
    netWidth,
    netHeight,
    netDepth,
    netDiameter,
    netWeightUnit,
    netWeight,
    netDimensionsAcquisition,
    grossLengthUnit,
    grossWidth,
    grossHeight,
    grossDepth,
    grossDiameter,
    grossWeightUnit,
    grossWeight,
    grossDimensionsAcquisition,
    dimensionsNotes,
    audioVideoLengthUnit,
    audioVideoLength,
    fileSizeUnit,
    fileSize,
    resolutionUnit,
    resolution,
  };

  const legalAspectsData = {
    category,
    isNotifiedCulturalProperty,
    notifiedPropertyNote,
    legalStatus,
    copyrightAndPropertyRestrictions,
  };

  const technicalDetailsData = {
    category,
    isArtistProof,
    artistProofNotes,
    isEdition,
    editionNotes,
    isArtMultiple,
    multipleNumber,
    isSignedObject,
    signedObjectNotes,
    isTitledObject,
    titledObjectNotes,
    isDatedObject,
    datedObjectNotes,
    hasLabelsStamps,
    labelsStampsNotes,
    hasOtherAutographed,
    otherAutographedNotes,
    relevantAwards,
    isDynamic,
    dynamicNotes,
    hasHumanAnimalPresence,
    humanAnimalPresenceNotes,
    isAudio,
    isVideo,
    hasLoop,
    isMaster,
    masterLocation,
    coloring,
    variants,
    nominalValue,
    fiscalValue,
    currentValue,
    currency,
    score,
    circulation,
    manufactoringTechniques,
    mintingLastDate,
    mintingLastDateNotes,
    reminting,
    printingLastDate,
    printingLastDateNotes,
    reprinting,
    isFirstDraft,
    firstDraftNotes,
    mechanism,
    carat,
  };

  const physicalFeaturesData = {
    category,
    stateOfConservation,
    isComposed,
    numberOfElements,
    physicalFeaturesElements,
    surfaceCharacteristics,
    hasBase,
    baseDetails,
    hasContainer,
    containerDetails,
    hasOriginalFrame,
    frameMaterial,
    isValuableFrame,
    frameManufacturer,
    frameDescription,
    hasFrame,
    hasWoodFrame,
    woodFrameDetails,
    compositionDescription,
    methodOfUse,
    relatedPhysicalWork,
    conservationMethod,
    distribution,
    edge,
    orientation,
    shape,
    rim,
    rotationAxes,
    legend,
    coinTitle,
    mintMadeErrors,
    signings,
    relief,
    watermark,
    holographicStripe,
    securityThread,
    printingErrors,
    color,
    postageMark,
    rubber,
    stampCenteringAndMargins,
    stampPrintingTechnique,
    stampFormat,
    stampRealization,
    stampIndentationType,
    bookContainer,
    dustJacket,
    frontCover,
    rearCover,
    topEdge,
    foreEdge,
    footEdge,
    bookbinding,
    interiorDecorations,
    externalDecorations,
    flyleaf,
    glueing,
    headband,
    headcap,
    corner,
    square,
    slots,
    spine,
    clips,
    raisedBands,
    label,
    clothCovering,
    usage,
    replacedParts,
  };

  const settingData = {
    category,
    hasBarriers,
    barriersDetails,
    handlingRequirements,
    injuryRisk,
    injuryRiskDescription,
    mouldingSupportBase,
    hardwareToolsInstallation,
    conservation,
    lenderRequests,
    settingNotes,
  };

  const environmentalConditionsData = {
    category,
    optimalLightLevelIntensity,
    lightUnit,
    optimalTemperature,
    temperatureUnit,
    optimalHumidity,
    enviromentalConditionsNotes,
  };

  const discoveryData = {
    category,
    discoveryPlace,
    discoverySite,
    discoveryDate,
    discoveryNotes,
    discoveryAuthor,
    catalogingAuthor,
    objectSetupAuthor,
  };

  const taxonomyData = {
    category,
    taxonomyDomain,
    taxonomyKingdom,
    taxonomyPhylum,
    taxonomyClass,
    taxonomyOrder,
    taxonomyFamily,
    taxonomyTribe,
    taxonomyGenus,
    taxonomyNotes,
    classificationUsa,
    classificationIta,
    classificationEng,
    classificationFra,
    classificationSpa,
    classificationGer,
    rarityDegree,
    conservationState,
    rarityDegreeNotes,
    classificationPhilatelic,
    rarityDegreePhilatelic,
  };

  const carryingValueData = {
    assetId: id,
    category,
    carryingValueAmountCurrency,
    carryingValueAcquisition,
    carryingValueAmount,
    carryingValueFile,
    carryingValueFileTitle,
    carryingValueLastAcquisitionDate,
    carryingValueNotes,
    carryingValueOrigin,
  };

  const presentValuesData = {
    category,
    presentValues,
  };

  const { edges: values } = presentValues;
  const parsedForTablePresentValues = presentValues.edges.flatMap((e) => ({
    ...e.node,
    presentValueId: e.node.id,
  }));

  const parsedForTableMedia = media.edges.flatMap((e) => ({ ...e.node }));

  const parsedForTableDocuments = documents.edges.flatMap((e) => {
    const attachments = e.node.attachments.edges.flatMap((a) => ({ ...a.node }));
    return { ...e.node, attachments, attachmentCount: attachments.length, documentId: e.node.id };
  });

  const loadSelectedDocument = (id: string) => {
    const document = parsedForTableDocuments.find((e) => e.id === id);
    if (document) {
      setSelectedDocument({
        id: document.documentId,
        title: document.title,
        description: document.description,
        documentType: document.documentType,
        coaType: document.coaType,
        issuer: document.issuer,
        recipient: document.recipient,
        isClassified: document.isClassified,
        releaseDate: document.releaseDate,
        expirationDate: document.expirationDate,
        notes: document.notes,
        archiveNumber: document.archiveNumber,
        fineArtsIssueType: document.fineArtsIssueType,
        isTemporaryExport: document.isTemporaryExport,
        isTemporaryImport: document.isTemporaryImport,
        isCriticalCatalog: document.isCriticalCatalog,
        editor: document.editor,
        curator: document.curator,
        subscriptionDate: document.subscriptionDate,
        contractSubscriber: document.contractSubscriber,
        publishingDate: document.publishingDate,
        otherDocumentType: document.otherDocumentType,
        attachments: document.attachments,
      });
    } else {
      setSelectedDocument(null);
    }
  };

  const loadSelectedPresentValue = (id: string) => {
    const presentValue = parsedForTablePresentValues.find((e) => e.id === id);
    if (presentValue) {
      setSelectedPresentValue({
        id: presentValue.presentValueId,
        authorId: presentValue?.author?.id,
        authorName: presentValue?.author?.fullName,
        estimateDate: presentValue.estimateDate,
        estimateReason: presentValue.estimateReason,
        notes: presentValue.notes,
        fileTitle: presentValue.fileTitle,
        file: presentValue.file,
        amount: presentValue.amount
          ? `${presentValue.amount.amount} ${presentValue.amount.currency.code}`
          : "",
      });
    }
  };

  const closePresentValueModal = () => {
    setSelectedPresentValue(null);
    onClosePresentValueModal();
  };

  const openPresentValueModal = (id: string) => {
    loadSelectedPresentValue(id);
    onOpenPresentValueModal();
  };

  const closeDocumentModal = () => {
    setSelectedDocument(null);
    onCloseDocumentModal();
  };

  const openDocumentModal = (id: string) => {
    loadSelectedDocument(id);
    onOpenDocumentModal();
  };

  const filterParsedForTableMedia = (nodes: Partial<MediaNode>[], type: string) =>
    nodes.filter(
      (node) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        node.mediaType === type
    );

  const QUICK_ACTIONS = [
    {
      name: t("Edit asset"),
      href: `/mgmt/assets/edit/${id}`,
      icon: <BiEdit />,
    },
    {
      name: t("Export pdf"),
      action: onGeneratePdf,
      loading: exportAssetPdfLoading,
      icon: <BiExport />,
    },
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <VStack spacing="6" align="flex-start" w="full">
        <HStack justifyContent="space-between" alignItems="flex-start" w="full">
          <HStack justifyContent="flex-start" alignItems="flex-start">
            <SBox>
              <VStack maxWidth="600px" alignItems="flex-start" justify="flex-start" spacing="8">
                <Badge variant={category}>{category}</Badge>
                <Heading size="2xl">{title}</Heading>
                <HStack justify="space-between" align="flex-start">
                  {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    mainImage && (
                      <Button
                        style={{ height: "300px", width: "auto" }}
                        variant="unstyled"
                        onClick={() => window.open(mainImage)}
                        p="4"
                        background="gray.100"
                        _hover={{
                          background: "gray.200",
                        }}
                      >
                        <Image alt="Main" objectFit="contain" height="100%" src={mainImage} />
                      </Button>
                    )
                  }

                  <VStack align="flex-start" pl="2">
                    <>
                      <HStack justify="flex-start" spacing="12" w="full">
                        {category !== "PRINT_OR_PHOTO" &&
                          category !== "NUMISMATIC_COINS" &&
                          category !== "OTHER" &&
                          category !== "NUMISMATIC_BANKNOTES" &&
                          category !== "PHILATELY" &&
                          category !== "BOOKS" &&
                          category !== "NATURALISTIC_GOODS" && (
                            <Field label="Technique" value={technique} />
                          )}

                        {category === "OTHER" /* && (otherModel || material) */ && (
                          <>
                            <Field label="Model" value={otherModel} />
                            <Field label="Material" value={material} />
                          </>
                        )}

                        {category === "BOOKS" /* && (paperType || coverType || printingType) */ && (
                          <>
                            <Field label="Paper type" value={paperType} />
                            <Field label="Cover type" value={coverType} />
                            <Field label="Printing type" value={printingType} />
                          </>
                        )}

                        {(category === "NUMISMATIC_BANKNOTES" ||
                          category === "PHILATELY") /* && paperType */ && (
                          <Field label="Paper type" value={paperType} />
                        )}

                        {category === "NUMISMATIC_COINS" && coinComposition && (
                          <Field label="Composition" value={coinComposition} />
                        )}

                        {category === "PRINT_OR_PHOTO" /* && (technique || paperType) */ && (
                          <>
                            <Field label="Technique" value={technique} />
                            <Field label="Paper type" value={paperType} />
                          </>
                        )}
                        {category === "PRINT_OR_PHOTO" && productionMode && (
                          <Field label="Production mode" value={productionMode} />
                        )}
                      </HStack>
                      {authorId && (
                        <VStack align="flex-start">
                          <Text variant="muted">
                            <Trans>Author</Trans>
                          </Text>
                          <RegistryAwareField id={authorId} />
                        </VStack>
                      )}

                      {category === "NATURALISTIC_GOODS" && (
                        <HStack>
                          <Field label="Common name" value={commonName} />

                          <Field label="Scientific name" value={scientificName} />

                          <Field
                            label="International code of nomenclature"
                            value={internationalCode}
                          />
                        </HStack>
                      )}
                      {datingData && (
                        <HStack justify="flex-start" spacing="12" w="full">
                          <AssetDating data={datingData} />
                        </HStack>
                      )}
                      <Field label="Description" value={description} />
                    </>
                  </VStack>
                </HStack>
              </VStack>
            </SBox>
          </HStack>
          <QuickActions noTitle actions={QUICK_ACTIONS} />
        </HStack>
        {category.toString() !== "NOT_VALIDATED" && (
          <Tabs
            colorScheme="brand"
            variant="enclosed"
            mt="4"
            w="full"
            index={tabIndex}
            onChange={handleTabChange}
          >
            <TabList>
              <Tab>
                {/* <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      1
                    </Badge> */}
                <Trans>Info</Trans>
              </Tab>

              <Tab isDisabled={id === null}>
                {/* <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      2
                    </Badge> */}
                <Trans>Origin and bibliography</Trans>
              </Tab>

              <Tab isDisabled={id === null}>
                {/* <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      3
                    </Badge> */}
                <Trans>Value</Trans>
              </Tab>
              <Tab isDisabled={id === null}>
                <Trans>Event log</Trans>
              </Tab>
              <Tab isDisabled={id === null}>
                {/* <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      4
                    </Badge> */}
                <Trans>Documents</Trans>
              </Tab>
              <Tab isDisabled={id === null}>
                {/* <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      5
                    </Badge> */}
                <Trans>Media</Trans>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={3}>
                <HStack flex="1" w="full" spacing="6" align="flex-start">
                  <VStack flex="3" direction="column" spacing="6">
                    {/* GLOBAL INFO */}
                    <GlobalInfoView data={globalInfoData} />

                    {/* DIMENSIONS */}
                    <DimensionsView data={dimensionsData} />

                    {/* TAXONOMY */}
                    {(category.toString() === "ARCHEOLOGY" ||
                      category.toString() === "NATURALISTIC_GOODS" ||
                      category.toString() === "NUMISMATIC_COINS" ||
                      category.toString() === "NUMISMATIC_BANKNOTES" ||
                      category.toString() === "PHILATELY") && <TaxonomyView data={taxonomyData} />}

                    {/* LEGAL ASPECTS */}
                    <LegalAspectsView data={legalAspectsData} />

                    {/* TECHNICAL DETAILS */}

                    <TechnicalDetailsView data={technicalDetailsData} />

                    {/* PHYSICAL FEATURES */}
                    <PhysicalFeaturesView
                      assetId={assetId}
                      refetchAsset={refetch}
                      data={physicalFeaturesData}
                    />

                    {/* SETTING */}
                    <SettingView data={settingData} />

                    {/* ENVIRONMENTAL CONDITIONS */}
                    <EnvironmentalConditionsView data={environmentalConditionsData} />

                    {/* DISCOVERY */}
                    {(category.toString() === "ARCHEOLOGY" ||
                      category.toString() === "NATURALISTIC_GOODS") && (
                      <DiscoveryView data={discoveryData} />
                    )}
                  </VStack>
                  <SidebarInfo
                    createdAt={createdAt}
                    createdBy={createdBy}
                    updatedBy={updatedBy}
                    updatedAt={updatedAt}
                    notes={{ content: infoNotes }}
                  />
                </HStack>
              </TabPanel>
              <TabPanel p={3}>
                <Field label="Origin" value={originNotes} />

                <Field label="Literature" value={bibliographyNotes} />
              </TabPanel>
              <TabPanel p={3}>
                <VStack flex="3" direction="column" spacing="6">
                  <CarryingValueView data={carryingValueData} />

                  {/* <PresentValuesView data={presentValuesData} /> */}
                  <BorderedBox title={t("Present values")} subtitle="Lorem ipsum">
                    <OnViewAssetPresentValuesTable
                      data={parsedForTablePresentValues}
                      mode="view"
                      editAction={null}
                      viewAction={(row: any) => openPresentValueModal(row?.values?.presentValueId)}
                      onDelete={onDeletePresentValue}
                    />
                  </BorderedBox>
                </VStack>
              </TabPanel>
              <TabPanel p={3}>
                <OnViewEventLogs assetId={id} />
              </TabPanel>
              <TabPanel>
                <OnViewAssetDocumentsTable
                  data={parsedForTableDocuments}
                  mode="view"
                  editAction={null}
                  viewAction={(row: any) => openDocumentModal(row?.values?.documentId)}
                  onDelete={onDeleteDocument}
                  refetch={refetch}
                />
              </TabPanel>
              <TabPanel>
                <OnViewAssetMediaTable
                  data={filterParsedForTableMedia(parsedForTableMedia, "IMAGE")}
                  type="IMAGE"
                  mode="view"
                  editAction={null}
                  onDelete={onDeleteMedia}
                  refetch={refetch}
                />
                {/* <div>Media</div> */}
                <OnViewAssetMediaTable
                  data={filterParsedForTableMedia(parsedForTableMedia, "AUDIO_VIDEO")}
                  type="AUDIO_VIDEO"
                  mode="view"
                  editAction={null}
                  onDelete={onDeleteMedia}
                  refetch={refetch}
                />
                <OnViewAssetMediaTable
                  data={filterParsedForTableMedia(parsedForTableMedia, "FINGERPRINT")}
                  type="FINGERPRINT"
                  mode="view"
                  editAction={null}
                  onDelete={onDeleteMedia}
                  refetch={refetch}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}

        {category.toString() === "NOT_VALIDATED" && (
          <HStack flex="1" w="full" spacing="6" align="flex-start">
            <VStack flex="3" direction="column" spacing="6">
              <GlobalInfoView data={globalInfoData} />
            </VStack>
            <VStack flex="1" justify="flex-start" align="flex-start">
              <Text variant="muted">
                <Trans>Created by</Trans>
              </Text>
              <Text>{createdBy?.name}</Text>
              <Divider />
              <Text variant="muted">
                <Trans>Owner</Trans>
              </Text>
              <Text>{createdBy.email}</Text>
              <Divider />
              <Text variant="muted">
                <Trans>Created at</Trans>
              </Text>
              <Text>{parseDate(createdAt)}</Text>
              <Divider />

              <Text variant="muted">
                <Trans>Updated at</Trans>
              </Text>
              <Text>{parseDate(updatedAt)}</Text>
              <Divider />
            </VStack>
          </HStack>
        )}
      </VStack>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenDocumentModal}
        size="xl"
        onClose={closeDocumentModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>View document</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <AssetDocumentView document={selectedDocument} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeDocumentModal}>
              <Trans>Cancel</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenPresentValueModal}
        size="xl"
        onClose={closePresentValueModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>View present value</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <PresentValueView presentValue={selectedPresentValue} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={closePresentValueModal}>
              <Trans>Cancel</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Skeleton>
  );
};

export default AssetViewPage;
