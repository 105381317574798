import { Badge, Box, Divider, Skeleton, } from "@chakra-ui/react";
// import { FocusableElement } from "@chakra-ui/utils";
import {
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  TablePageSkeleton,
} from "components/ui";
import { useAuth } from "contexts/AuthProvider";

import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  UserNode,
  UserNodeEdge,
  useToggleUserIsActiveMutation,
  useRenewUserMutation,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { Trans } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { USER_ROUTES } from "routes/constants";
import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";

const CUSTOM_FIELDS = ["name", "userType", "country", "createdBy"];
const CUSTOM_COLUMNS = [
  {
    Header: "name",
    Footer: "",
    accessor: "name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "User Type",
    Footer: "User Type",
    accessor: "userType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 20,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "country.code",
    // Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
    disableFilters: true,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

const FILTERABLE_COLUMNS = ["isActive", "status"];

const UsersPage = () => {
  const { user } = useAuth() || {};
  const { data, loading, error, refetch } = useGetAllUsersQuery();
  console.log({ data, error });
  const [
    toggleIsActiveUser,
    {
      data: toggleIsActiveUserData,
      loading: toggleIsActiveUserLoading,
      error: toggleIsActiveUserError,
    },
  ] = useToggleUserIsActiveMutation({ ...handleMutation("User updated!") });

  const [renewUser, { data: renewUserData, loading: renewUserLoading, error: renewUserError }] =
    useRenewUserMutation({ ...handleMutation("User renewed!") });

  const [deleteUser, { data: deleteUserData, error: deleteUserError, loading: deleteUserLoading }] =
    useDeleteUserMutation({ ...handleMutation("User deleted!") });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.allUsers || data.allUsers.edges.length === 0) return null;

  const {
    allUsers: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await toggleIsActiveUser({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Disable selected users</Trans>,
      action: (selectedRows: UserNodeEdge[]) => console.log(selectedRows),
    },
    {
      name: <Trans>Delete users</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: UserNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await deleteUser({
              variables: {
                input: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  id: original.id,
                },
              },
            });
            return null;
          })
        );
        await refetch();
      },
    },
    {
      name: <Trans>Renew users</Trans>,
      action: async (selectedRows: { original: UserNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await renewUser({
              variables: {
                input: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  id: original.id,
                },
              },
            });
            return null;
          })
        );
        await refetch();
      },
    },
  ];

  const QUICK_ACTIONS = [
    {
      name: "Add new users",
      href: "new",
      icon: <RiAddLine />,
    },
    {
      name: "Invite user",
      href: "invite",
      icon: <RiAddLine />,
      disabled: user?.userType === "EXTERNAL",
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allUsers.edges.flatMap((edge) => edge?.node)}
          pathname={`${USER_ROUTES.USER}/`}
          deleteAction={(id: string) => onDelete(id)}
          deleteActionLoading={toggleIsActiveUserLoading}
          isLoading={deleteUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default UsersPage;
