import { BaseRow, Importer, ImporterField, ParseCallback } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { useTranslation } from "react-i18next";

interface Field {
  name: string;
  optional?: boolean;
}

interface CsvImporterProps {
  fields: Field[];
  processChunk: ParseCallback<BaseRow>;
  onClose: () => void;
}

const CsvImporter = ({ fields, processChunk, onClose }: CsvImporterProps) => {
  const { t } = useTranslation();
  return (
    <Importer
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      onStart={({ file, preview, fields, columnFields }) => {
        // optional, invoked when user has mapped columns and started import
        // prepMyAppForIncomingData();
      }}
      processChunk={processChunk}
      onComplete={({ file, preview, fields, columnFields }) => {
        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
        // showMyAppToastNotification();
      }}
      onClose={({ file, preview, fields, columnFields }) => {
        // optional, if this is specified the user will see a "Finish" button after import is done,
        // which will call this when clicked
        // goToMyAppNextPage();
        onClose();
      }}

    // CSV options passed directly to PapaParse if specified:
    // delimiter={...}
    // newline={...}
    // quoteChar={...}
    // escapeChar={...}
    // comments={...}
    // skipEmptyLines={...}
    // delimitersToGuess={...}
    // chunkSize={...} // defaults to 10000
    // encoding={...} // defaults to utf-8, see FileReader API
    >
      {fields.map((field) => (
        <ImporterField name={field.name} label={t(field.name)} optional={field?.optional} />
      ))}
    </Importer>
  );
};

export default CsvImporter;
