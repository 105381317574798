import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  Heading,
  VStack,
  Avatar,
  HStack,
  Badge,
  Box,
} from "@chakra-ui/react";
import { UserNode } from "graphql/queries/generated/queries";
import parseDate from "helpers/parseDate";
import { Dispatch, SetStateAction } from "react";
import { Trans } from "react-i18next";

interface PastNoteMetadata {
  createdAt: string;
  createdBy: Pick<UserNode, "id" | "name">;
  creationDate: string;
}

type PastNote = {
  policyNote?: string;
  openingNotes?: string;
  closingNotes?: string;
} & PastNoteMetadata;

interface AddNotesModalProps<T> {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
  title?: string;
  message?: string;
  loading?: boolean;
  children?: React.ReactNode;
  fields: T;
  submitActionTitle?: string;
  isInputTextArea?: boolean;
  setFields: Dispatch<SetStateAction<T>>;
  pastNotes?: PastNote[];
}

function AddNotesModal<T extends object>({
  isOpen,
  onOpen,
  onClose,
  title,
  message,
  loading,
  children,
  fields,
  setFields,
  onSubmit,
  isInputTextArea = false,
  submitActionTitle = "Save",
  pastNotes = [],
}: AddNotesModalProps<T>) {
  const onPastNoteClick = (note: PastNote) => {
    if (Object.hasOwn(note, "policyNote")) {
      setFields({ policyNote: note?.policyNote } as T);
    }

    if (Object.hasOwn(note, "openingNotes") && Object.hasOwn(note, "closingNotes")) {
      setFields({ openingNotes: note?.openingNotes, closingNotes: note?.closingNotes } as T);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>{title}</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="4">
            <Trans>{message}</Trans>
          </Text>
          {Object.keys(fields).map((field) => (
            <FormControl>
              <FormLabel>
                <Trans>{field}</Trans>
              </FormLabel>
              {isInputTextArea ? (
                <Textarea
                  name={field}
                  value={fields[field] as string}
                  onChange={(e) => {
                    setFields((prevState) => ({
                      ...prevState,
                      [field]: e.target.value,
                    }));
                  }}
                />
              ) : (
                <Input
                  name={field}
                  value={fields[field] as string}
                  onChange={(e) => {
                    setFields((prevState) => ({
                      ...prevState,
                      [field]: e.target.value,
                    }));
                  }}
                />
              )}
            </FormControl>
          ))}
          {pastNotes && pastNotes.length > 0 && (
            <VStack align="flex-start" my="4" position="relative">
              <Heading size="md">
                <Trans>Past notes</Trans>
                <Badge mx="2" rounded="full">
                  {pastNotes?.length}
                </Badge>
              </Heading>
              <VStack align="flex-start" w="full" maxH="30vh" overflowY="scroll">
                {pastNotes.map((note) => (
                  <HStack
                    bgColor="brand.50"
                    p="4"
                    w="full"
                    rounded="lg"
                    align="flex-start"
                    justify="space-between"
                    borderColor="brand.500"
                    borderWidth="2px"
                    onClick={() => onPastNoteClick(note)}
                    _hover={{
                      backgroundColor: "brand.200",
                    }}
                  >
                    <VStack maxW="70%">
                      {Object.hasOwn(note, "policyNote") && <p>{note?.policyNote}</p>}
                      {Object.hasOwn(note, "openingNotes") && (
                        <>
                          <p>{note?.openingNotes}</p>
                          <p>{note?.closingNotes}</p>
                        </>
                      )}
                    </VStack>

                    <VStack align="flex-end" alignSelf="flex-start">
                      <Text variant="muted">{parseDate(note?.createdAt)}</Text>
                      <HStack>
                        <Avatar
                          name={note?.createdBy?.name}
                          src="https://bit.ly/broken-link"
                          size="xs"
                        />
                        <Text>{note?.createdBy?.name}</Text>
                      </HStack>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
              <Box
                position="absolute"
                bottom="0"
                bgGradient="linear(to-b, transparent, white)"
                w="full"
                py="2"
              >
                <Text variant="muted" align="center">
                  <Trans>Scroll to see more</Trans>
                </Text>
              </Box>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={loading}
            variant="primary"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            <Trans>{submitActionTitle}</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddNotesModal;
