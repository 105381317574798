import { DetailsPageSkeleton } from "components/ui";
import { useGetPolicyByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import { policyAssetFields } from "../PCPolicyAssetFields";
import Page from "./Page";

const PCPoliciesEditPage = () => {
  const { policyId } = useParams();

  const { data, loading, error, refetch } = useGetPolicyByIdQuery({
    variables: {
      id: policyId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  return <Page data={data} policyAssetsFields={policyAssetFields} refetch={refetch} />;
};

export default PCPoliciesEditPage;
