import { Box, Divider, Skeleton } from "@chakra-ui/react";
import {
  Dot,
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
} from "components/ui";
import {
  useGetAllConditionStatusReportsQuery,
  useDeleteConditionStatusReportMutation,
  ConditionStatusReportNode,
  useExportConditionStatusReportPdfMutation,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { Trans, useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";

const QUICK_ACTIONS = [
  {
    name: "Add new CR/SR entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const CUSTOM_FIELDS = [
  "reportStatus",
  "reportType",
  "reportDate",
  "reportMotivation",
  "reportExaminer",
  "reportMotivationDescription",
  "reportAsset",
  "reportAssetAuthor",
  "createdBy",
  "createdAt",
  "assetReport.actualDateAndPeriod",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Report Type",
    Footer: "Report Type",
    accessor: "reportType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value === "condition_report" ? <div>CR</div> : <div>SR</div>,
  },
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  },
  {
    Header: "Report Date",
    Footer: "Report Date",
    accessor: "reportDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
  },
  {
    Header: "Motivation",
    Footer: "Motivation",
    accessor: "reportMotivation",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value === "exhibition") return <span>Exhibition</span>;
      if (value === "restauration") return <span>Restauration</span>;
      if (value === "loan") return <span>Loan</span>;
      if (value === "evaluation") return <span>Evaluation</span>;
      if (value === "auction") return <span>Auction</span>;
      if (value === "sale") return <span>Sale</span>;
      if (value === "transport") return <span>Transport</span>;
      if (value === "asset_evaluation") return <span>Asset Evaluation</span>;
      if (value === "damage") return <span>Damage</span>;
      if (value === "catalog") return <span>Catalog</span>;

      return <span>Other</span>;
    },
  },
  {
    Header: "Examiner",
    Footer: "Examiner",
    accessor: "reportExaminer",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null)
        return <div>{value.fullName}</div>;
      return <div />;
    },
  },
  {
    Header: "Object Author",
    Footer: "Object Author",
    accessor: "reportAsset.authorEntity",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null)
        return <div>{value.fullName}</div>;
      return <div />;
    },
  },
  {
    Header: "Object Title",
    Footer: "Object Title",
    accessor: "reportAsset",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: { id: string; title: string } } }) => {
      if (value !== undefined && value !== null && value.title !== null)
        return <div>{value.title}</div>;
      return <div />;
    },
  },
  {
    Header: "Created On",
    Footer: "Created On",
    accessor: "reportAsset.actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "STATUS",
    Footer: "STATUS",
    accessor: "reportStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Dot positiveLabel="Draft" negativeLabel="Closed" status={value === "draft"} />
    ),
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];
const FILTERABLE_COLUMNS = ["deleted", "type"];

const ConditionStatusReportsPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllConditionStatusReportsQuery();
  const [deleteConditionStatusReport, { loading: deleteLoading }] =
    useDeleteConditionStatusReportMutation({
      ...handleMutation("Report deleted!"),
    });

  const [exportReportPdf, { loading: exportReportPdfLoading }] =
    useExportConditionStatusReportPdfMutation({
      ...handleMutation("Report exported!"),
    });

  if (loading) return <TablePageSkeleton loading={loading} />;

  if (!data || !data.allConditionStatusReports || data.allConditionStatusReports.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allConditionStatusReports: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;

  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await deleteConditionStatusReport({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onGeneratePdf = async (conditionStatusReportId: string) => {
    const { data } = await exportReportPdf({
      variables: {
        input: {
          conditionStatusReportId,
        },
      },
    });

    const {
      exportConditionStatusReportPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete report(s)</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: ConditionStatusReportNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onDelete(original.id);
            return null;
          })
        );
        await refetch();
      },
    },
    {
      name: <Trans>Export PDF</Trans>,
      action: async (selectedRows: { original: ConditionStatusReportNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onGeneratePdf(original.id);
            return null;
          })
        );
      },
      loading: exportReportPdfLoading,
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allConditionStatusReports.edges.flatMap((edge) => edge?.node)}
          pathname="/mgmt/cr-sr/"
          deleteAction={(id: string) => onDelete(id)}
        />
      </Skeleton>
    </Box>
  );
};

export default ConditionStatusReportsPage;
