import { Badge, Box, Divider, Skeleton } from "@chakra-ui/react";
import {
  CreatedByFilter,
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  TablePageSkeleton,
} from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import {
  useGetAllRegistriesQuery,
  useDeleteRegistryMutation,
  RegistryNode,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { Trans } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { REGISTRY_ROUTES } from "routes/constants";

// const BATCH_ACTIONS = [
//   {
//     name: <Trans>Disable selected users</Trans>,
//     action: (selectedRows: UserNodeEdge) => console.log(selectedRows),
//   },
// ];
// const FILTERABLE_COLUMNS = ["isActive", "status"];
const CUSTOM_FIELDS = [
  "createdBy",
  "role",
  "mainOfficeAddress",
  "actualCompanyContactsNumber",
  "fullName",
  "businessName",
  "email",
  "phone",
  "mobilePhone",
  "officesCount",
  "categories",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Categories",
    Footer: "Categories",
    accessor: "categories",
    Filter: DropdownFilter,
    // Cell: ({ cell: { value } }: { cell: { value: string[] } }) => (

    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: RegistryNode } };
    }) => {
      if (original.isAuthor)
        return (
          <Badge variant="AUTHOR" mr="1">
            <Trans>AUTHOR</Trans>
          </Badge>
        );
      return original.categories?.map((v) => (
        <Badge variant={v} mr="1">
          <Trans>{v}</Trans>
        </Badge>
      ));
    },
    weight: 10,
  },
  {
    Header: "Full Name",
    Footer: "Full Name",
    disableFilters: true,
    accessor: "fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  // {
  //   Header: "Business Name",
  //   Footer: "Business Name",
  //   disableFilters: true,
  //   accessor: "businessName",
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  // },
  {
    Header: "Role",
    Footer: "Role",
    disableFilters: true,
    accessor: "role",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Email",
    Footer: "Email",
    disableFilters: true,
    accessor: "email",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Phone",
    Footer: "Phone",
    disableFilters: true,
    accessor: "phone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Mobile Phone",
    Footer: "Mobile Phone",
    disableFilters: true,
    accessor: "mobilePhone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Main Office Address",
    Footer: "Main Office Address",
    disableFilters: true,
    accessor: "mainOfficeAddress",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Offices Count",
    Footer: "Offices Count",
    disableFilters: true,
    accessor: "officesCount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Contacts Number",
    Footer: "Contacts Number",
    disableFilters: true,
    accessor: "actualCompanyContactsNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Created by",
    Footer: "Created by",
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
];

const QUICK_ACTIONS = [
  {
    name: "Add new registry entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const RegistriesPage = () => {
  const { data, loading, error, refetch } = useGetAllRegistriesQuery();
  const [
    deleteRegistry,
    { data: deleteRegistryData, error: deleteRegistryError, loading: deleteRegistryLoading },
  ] = useDeleteRegistryMutation({
    ...handleMutation("Registry deleted!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.allRegistries || data.allRegistries.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allRegistries: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    // filterableColumns: FILTERABLE_COLUMNS,
  });

  // const onDelete = (id: string) => console.log(id);

  const onDelete = async (id: string) => {
    await deleteRegistry({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete registries</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: RegistryNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onDelete(original?.id);
            return null;
          })
        );
        await refetch();
      },
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allRegistries.edges.flatMap((edge) => edge?.node)}
          pathname={`${REGISTRY_ROUTES.REGISTRY}/`}
          deleteAction={(id: string) => onDelete(id)}
          hiddenColumns={["businessName", "isAuthor"]}
        />
      </Skeleton>
    </Box>
  );
};

export default RegistriesPage;
