import { Heading, Text, VStack } from "@chakra-ui/react";
import { RegistryNode } from "graphql/queries/generated/queries";
import { Trans, useTranslation } from "react-i18next";
import BorderedBox from "../BorderedBox";
import RegistryAwareField from "../RegistryAwareField";

interface AssetCardProps {
  title?: string;
  authorEntity?: Partial<RegistryNode>;
  technique?: string;
}

const AssetCard = ({ title, authorEntity, technique }: AssetCardProps) => {
  const { t } = useTranslation();

  return (
    <BorderedBox title="Asset">
      <VStack align="flex-start">
        {title && <Heading>{title}</Heading>}
        <Text variant="muted" mb="0" pb="0">
          <Trans>Author</Trans>
        </Text>
        {authorEntity && <RegistryAwareField id={authorEntity.id} />}
        <Text variant="muted" mb="0" pb="0">
          <Trans>Technique</Trans>
        </Text>
        <Text>{technique}</Text>
      </VStack>
    </BorderedBox>
  );
};

export default AssetCard;
