/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from "@apollo/client";
import { VStack } from "@chakra-ui/react";
import { FieldGroup, FormInputHook, MoneyInputHook } from "components/ui";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { useTranslation } from "react-i18next";

export const eventTypeOptions: FormSelectOptionProps[] = [
  { value: "acquisition", label: "Acquisition" },
  { value: "change_location", label: "Change location" },
  { value: "consignment", label: "Consignment" },
  { value: "auction", label: "Auction" },
  { value: "sale", label: "Sale" },
  { value: "transfer_donation", label: "Transfer/Donation" },
  { value: "lost_stolen", label: "Lost/Stolen" },
  { value: "destruction", label: "Destruction" },
];

export const acquisitionTypeOptions: FormSelectOptionProps[] = [
  { value: "purchased", label: "Purchased" },
  { value: "gift", label: "Gift" },
  { value: "inherited", label: "Inherited" },
  { value: "other", label: "Other" },
];

interface EventLogFieldsProps {
  watch: any;
  errors: ApolloError[];
  loading: boolean;
}

const EventLogFields = ({ watch, loading, errors }: EventLogFieldsProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const eventType = watch("eventType");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const sold = watch("sold");

  return (
    <>
      <FieldGroup title={t("Event info")} subtitle={t("Lorem ipsum dolor sit amet")}>
        <VStack alignItems="flex-start">
          <FormInputHook
            name="eventType"
            type="select"
            label={t("Event type")}
            options={eventTypeOptions}
            required
          />
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Event info")} subtitle={t("Lorem ipsum dolor sit amet")}>
        <VStack alignItems="flex-start">
          <FormInputHook name="owner" label={t("Owner")} type="registry" />
          {eventType === "acquisition" && (
            <FormInputHook name="origin" label={t("Come from")} type="registry" />
          )}
          {(eventType === "change_location" || eventType === "auction") && (
            <FormInputHook name="host" label={t("Host")} type="registry" />
          )}
          {(eventType === "sale" || eventType === "transfer_donation") && (
            <FormInputHook name="newOwner" label={t("New owner")} type="registry" />
          )}
          {(eventType === "consignment" || eventType === "sale") && (
            <FormInputHook name="broker" label={t("Broker")} type="registry" />
          )}
          {eventType !== "sale" && eventType !== "lost_stolen" && eventType !== "destruction" && (
            <FormInputHook
              name="collectionPublicName"
              label={t("Collection public name")}
              type="textarea"
            />
          )}
          {eventType !== "sale" && eventType !== "lost_stolen" && eventType !== "destruction" && (
            <FormInputHook
              name="locationDescription"
              label={t("Location description")}
              type="textarea"
            />
          )}
          {eventType === "acquisition" && (
            <FormInputHook name="hideOwner" label={t("Hide owner")} type="checkbox" />
          )}
          {eventType === "acquisition" && (
            <FormInputHook
              name="commerciallyAvailable"
              label={t("Commercially available")}
              type="checkbox"
            />
          )}
          {eventType !== "consignment" &&
            eventType !== "auction" &&
            eventType !== "lost_stolen" && (
              <FormInputHook name="startDate" required label={t("Data")} type="datetime-local" />
            )}
          {(eventType === "consignment" ||
            eventType === "auction" ||
            eventType === "lost_stolen") && (
            <FormInputHook
              name="startDate"
              required
              label={t("Data inizio")}
              type="datetime-local"
            />
          )}
          {(eventType === "consignment" ||
            eventType === "auction" ||
            eventType === "lost_stolen") && (
            <FormInputHook name="endDate" label={t("Data fine")} type="datetime-local" />
          )}
          {eventType === "acquisition" && (
            <FormInputHook
              name="acquisitionType"
              type="select"
              label={t("Acquisition type")}
              options={acquisitionTypeOptions}
            />
          )}
          {eventType === "acquisition" && (
            <MoneyInputHook name="value.amount" label={t("Carrying value")} />
          )}
          {eventType === "consignment" && (
            <FormInputHook name="commission" label={t("Commission")} />
          )}

          {eventType === "sale" && (
            <>
              <FormInputHook name="commission" label={t("Commission")} />
              <FormInputHook name="sellingLocation" label={t("Selling location")} />
              <MoneyInputHook name="value.amount" label={t("Selling price")} />
            </>
          )}

          {eventType === "auction" && (
            <>
              <FormInputHook
                name="auctionDescription"
                label={t("Auction description")}
                type="textarea"
              />
              <FormInputHook name="lotNumber" label={t("Lot number")} />

              <MoneyInputHook name="minimumPrice.amount" label={t("Minimum price")} />
              <MoneyInputHook name="maximumPrice.amount" label={t("Maximum price")} />
              <MoneyInputHook name="reservePrice.amount" label={t("Reserve price")} />
              <FormInputHook name="sold" label={t("Sold")} type="checkbox" />
              {sold && <MoneyInputHook name="value.amount" label={t("Selling price")} />}
              {sold && <MoneyInputHook name="auctionRights.amount" label={t("Auction rights")} />}
            </>
          )}

          <FormInputHook name="document.0.file" label={t("Document")} type="file" />
          <FormInputHook name="note" label={t("Note")} type="textarea" />
        </VStack>
      </FieldGroup>
    </>
  );
};

export default EventLogFields;
