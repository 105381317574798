import { Heading, HStack, VStack, Skeleton, Text } from "@chakra-ui/react";
import ASSET_CONSTANTS from "constants/asset";
import {
  BorderedBox,
  CopyButton,
  Dot,
  QuickActions,
  Field,
  RegistryAwareField,
  DetailsPageSkeleton,
  Table,
  NoDataPlaceholder,
} from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import {
  useGetRestorationByIdQuery,
  useGetMediaRestorationByIdQuery,
  useExportRestorationPdfMutation,
  MediaNode,
} from "graphql/queries/generated/queries";
import {
  DOCUMENT_CUSTOM_FIELDS,
  DOCUMENT_CUSTOM_COLUMNS,
  DOCUMENT_HIDDEN_FIELDS,
  MEDIA_CUSTOM_FIELDS,
  MEDIA_CUSTOM_COLUMNS,
  MEDIA_HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import parseDate from "helpers/parseDate";

const RestorationViewPage = () => {
  const { t } = useTranslation();
  const { restorationId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetRestorationByIdQuery({
    variables: { id: restorationId ?? "" },
  });

  const {
    data: dataMedia,
    loading: loadingMedia,
    error: errorMedia,
    refetch: refetchMedia,
  } = useGetMediaRestorationByIdQuery({
    variables: {
      id: restorationId,
    },
  });

  const [
    exportRestorationPdf,
    {
      data: exportRestorationPdfData,
      loading: exportRestorationPdfLoading,
      error: exportRestorationPdfError,
    },
  ] = useExportRestorationPdfMutation({
    ...handleMutation("Restoration exported!"),
  });

  const onGeneratePdf = async () => {
    const { data } = await exportRestorationPdf({
      variables: {
        input: {
          restorationId,
        },
      },
    });

    const {
      exportRestorationPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.restoration) return null;

  const {
    restoration: { ...formData },
  } = data;

  let dataToGetColumns;
  let columnsDocument;
  let columnsMedia;
  const documents: MediaNode[] = [];
  const media: MediaNode[] = [];

  if (
    dataMedia &&
    dataMedia.restoration &&
    dataMedia.restoration.media &&
    dataMedia.restoration.media.edges &&
    dataMedia.restoration.media.edges.length > 0
  ) {
    dataToGetColumns = dataMedia.restoration.media.edges[0].node;

    columnsDocument = getColumns({
      data: dataToGetColumns,
      customFields: DOCUMENT_CUSTOM_FIELDS,
      customColumns: DOCUMENT_CUSTOM_COLUMNS,
    });

    columnsMedia = getColumns({
      data: dataToGetColumns,
      customFields: MEDIA_CUSTOM_FIELDS,
      customColumns: MEDIA_CUSTOM_COLUMNS,
    });

    for (let i = 0; i < dataMedia.restoration.media.edges.length; i += 1)
      if (
        dataMedia.restoration.media.edges[i].node.mediaType ===
        ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value
      )
        documents.push(dataMedia.restoration.media.edges[i].node as MediaNode);
      else media.push(dataMedia.restoration.media.edges[i].node as MediaNode);
  }

  const valueCarryingValue = `${formData.restorationCost.amount} ${formData.restorationCost.currency.code}`;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Restoration"),
      href: `/mgmt/restoration/edit/${formData.id}`,
      icon: <BiEdit />,
    },
    {
      name: t("Export pdf"),
      action: onGeneratePdf,
      icon: <BiExport />,
      loading: exportRestorationPdfLoading,
    },
  ];

  const onViewDocument = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id && row.original.file) window.open(row.original.file);
  };

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          {formData.restorationAsset !== null &&
          formData.restorationAsset.title !== null &&
          formData.restorationAsset.title !== "" ? (
            <Heading>{formData.restorationAsset.title}</Heading>
          ) : (
            <Heading />
          )}

          {formData.restorationAsset.authorEntity !== null && (
            <RegistryAwareField id={formData.restorationAsset.authorEntity.id} />
          )}

          {formData.restorationAsset.actualDateAndPeriod !== null &&
            formData.restorationAsset.actualDateAndPeriod !== "" && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Field
                label="Date of creation"
                value={formData.restorationAsset?.actualDateAndPeriod}
              />
            )}

          <Text fontSize="lg" variant="muted">
            #{restorationId ?? ""} <CopyButton value={restorationId ?? ""} />
          </Text>
          <Dot
            positiveLabel={t("Draft")}
            negativeLabel={t("Closed")}
            status={formData.restorationStatus === "draft"}
          />
          <Text>
            {formData.restorationDocumentDate ? parseDate(formData.restorationDocumentDate) : ""}
          </Text>
        </VStack>

        {formData.restorationStatus === "draft" && <QuickActions noTitle actions={QUICK_ACTIONS} />}
      </HStack>

      <VStack flex="3" direction="column" spacing="6">
        <BorderedBox title="Restoration" subtitle="Lorem ipsum dolor sit amet">
          <HStack w="100%" my={4}>
            <Field
              value={formData.restorationDirector ? formData.restorationDirector.fullName : "..."}
              label={t("Restored by")}
            />
            <Field
              value={formData.restorationCompany ? formData.restorationCompany.fullName : "..."}
              label={t("Restored by")}
            />
            <Field value={formData.restorationRestoredBy} label={t("Restored by")} />
            <Field
              value={formData.restorationInventoryNumber}
              label={t("Reference number from recognized institution")}
            />
            <Field value={valueCarryingValue} label={t("Restoration Cost")} />
          </HStack>
          <HStack w="100%" my={4}>
            <Field value={formData.restorationStartDate} label={t("Start date")} />
            <Field value={formData.restorationEndDate} label={t("End date")} />
            <Field value={formData.restorationLocation} label={t("location")} />
            <Field
              value={formData.restorationCollocationFeatures}
              label={t("Collocation features")}
            />
            <Field value={formData.restorationDocumentDate} label={t("Document date")} />
          </HStack>
        </BorderedBox>

        <BorderedBox
          title="Object conservative data and interventions"
          subtitle="Lorem ipsum dolor sit amet"
        >
          <HStack w="100%" my={4}>
            <Field
              value={formData.conservativeDataHistoryInterventions}
              label={t("History interventions")}
            />
            <Field
              value={formData.conservativeDataConservationStatus}
              label={t("Conservatio status")}
            />
            <Field
              value={formData.conservativeDataAssetAlterations}
              label={t("Object alterations")}
            />
            <Field
              value={formData.conservativeDataDeteriorationCauses}
              label={t("Deterioration causes")}
            />
            <Field
              value={formData.conservativeDataExaminationMethod}
              label={t("Examination method")}
            />
            <Field
              value={formData.conservativeDataInterventionProposal}
              label={t("Intervention proposal")}
            />
          </HStack>
          <HStack w="100%" my={4}>
            <Field
              value={formData.conservativeDataPreliminaryOperations}
              label={t("Preliminary operations")}
            />
            <Field value={formData.conservativeDataIntervention} label={t("Intervention")} />
            <Field
              value={formData.conservativeDataInterventionMaterials}
              label={t("Intervention materials")}
            />
            <Field
              value={formData.conservativeDataTechnicalMethodologicalConsiderations}
              label={t("Technical methodological considerations")}
            />
            <Field
              value={formData.conservativeDataMaintenancePreparationAssembly}
              label={t("Maintenance, preparation, assembly")}
            />
            <Field
              value={formData.conservativeDataRestorationBibliography}
              label={t("Restoration bibliography")}
            />
            <Field
              value={formData.conservativeDataNotesRestorationSheet}
              label={t("Restoration sheet notes")}
            />
          </HStack>
        </BorderedBox>

        <BorderedBox title="Image editor" subtitle="Lorem ipsum dolor sit amet">
          <Table
            hiddenColumns={MEDIA_HIDDEN_FIELDS}
            columns={columnsMedia}
            data={media}
            viewAction={onViewDocument}
          />
        </BorderedBox>

        <BorderedBox title="Documents" subtitle="Lorem ipsum dolor sit amet">
          <Table
            hiddenColumns={DOCUMENT_HIDDEN_FIELDS}
            columns={columnsDocument}
            data={documents}
            viewAction={onViewDocument}
          />
        </BorderedBox>
      </VStack>
    </Skeleton>
  );
};

export default RestorationViewPage;
