/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  HStack,
  Text,
  Heading,
  GridItem,
  Checkbox,
  Image,
  Stack,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { Highlight } from "react-instantsearch-hooks-web";
import { Trans } from "react-i18next";
import { RiCheckFill, RiEyeLine } from "react-icons/ri";
import { Dispatch, SetStateAction } from "react";
import { PolicyAssetInput } from "graphql/queries/generated/queries";
import { Hit, BaseHit } from "instantsearch.js";
import placeholderPNG from "assets/placeholder.png";
import { Link } from "react-router-dom";
import { MGMT_ROUTES } from "routes/constants";
import { AlgoliaSearchBoxProps, SelectedAsset } from "./types";

interface HitProps<T> {
  hit: Hit<BaseHit> & Partial<T>;
  addPolicyAsset: AlgoliaSearchBoxProps["addPolicyAsset"];
  selectedAssets: SelectedAsset[];
  setSelectedAssets: Dispatch<SetStateAction<SelectedAsset[]>>;
  alreadyAddedPolicyAssets: PolicyAssetInput[];
  layout?: "table" | "grid";
}

const CustomHit = ({
  hit,
  addPolicyAsset,
  setSelectedAssets,
  selectedAssets,
  alreadyAddedPolicyAssets,
  layout = "table",
}: HitProps<SelectedAsset>) => {
  const isItemAlreadyInPolicy = alreadyAddedPolicyAssets.find(({ asset }) => asset === hit.gqlId);
  const isItemAlreadySelected = selectedAssets.find(({ id }) => id === hit.id);

  const handleBoxClick = ({
    id,
    title,
    author,
    creationPeriod,
    gqlId,
    image,
    objectID,
  }: SelectedAsset) => {
    if (isItemAlreadyInPolicy) return;
    if (isItemAlreadySelected) {
      setSelectedAssets((prevState: SelectedAsset[]) =>
        prevState.filter(({ id: selectedId }) => selectedId !== id)
      );
      return;
    }
    setSelectedAssets((prevState) => [
      { id, title, author, creationPeriod, gqlId, image, objectID },
      ...prevState,
    ]);
  };

  return (
    <GridItem>
      <Stack
        direction={layout === "grid" ? "column" : "row"}
        bg={isItemAlreadyInPolicy ? "gray.200" : "white"}
        justify="flex-start"
        align="flex-start"
        rounded="md"
        shadow={isItemAlreadySelected && !isItemAlreadyInPolicy ? "lg" : "md"}
        _hover={{
          shadow: isItemAlreadyInPolicy ? "sm" : "lg",
          transition: "all 0.5s",
        }}
        height="100%"
        borderWidth={2}
        borderColor={isItemAlreadySelected && !isItemAlreadyInPolicy ? "brand.500" : "white"}
      >
        {!isItemAlreadyInPolicy && (
          <Checkbox
            isChecked={!!isItemAlreadySelected}
            bg="white"
            m={4}
            onChange={() => {
              if (!isItemAlreadyInPolicy) handleBoxClick({ ...(hit as unknown as SelectedAsset) });
            }}
          />
        )}

        <Image
          alignSelf="flex-start"
          src={(hit.mainImage as string) ?? placeholderPNG}
          alt=""
          px="2"
          maxH="100%"
          maxW="100%"
          w={layout === "table" ? "5rem" : "10rem"}
          objectFit="contain"
        />
        <Stack
          direction={layout === "grid" ? "column" : "row"}
          align={layout === "grid" ? "flex-start" : "center"}
          justifyContent={layout === "grid" ? "flex-start" : "flex-start"}
          px="6"
          py="3"
          w="100%"
          onClick={() =>
            !isItemAlreadyInPolicy && handleBoxClick({ ...(hit as unknown as SelectedAsset) })
          }
        >
          <HStack>
            <Heading size="sm" color={isItemAlreadyInPolicy ? "gray.500" : "black"}>
              <Highlight attribute="title" hit={hit} />
            </Heading>
          </HStack>

          <Text variant="muted">
            <Highlight attribute="author" hit={hit} />
          </Text>
          {hit?.actualDateAndPeriod !== "" && (
            <Text>
              <Highlight attribute="actualDateAndPeriod" hit={hit} />
            </Text>
          )}
          {hit?.technique !== "" && (
            <Text>
              <Highlight attribute="technique" hit={hit} />
            </Text>
          )}
          {hit?.genericDimensions !== "" && (
            <Text>
              <Highlight attribute="genericDimensions" hit={hit} />
            </Text>
          )}
          {layout === "grid" && (
            <Link
              to={`${MGMT_ROUTES.ASSETS}/${hit?.gqlId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack>
                <RiEyeLine />
                <Text fontSize="xs">
                  <Trans>Go to item in new tab</Trans>
                </Text>
              </HStack>
            </Link>
          )}

          {isItemAlreadyInPolicy && (
            <HStack>
              <RiCheckFill size={20} />
              <Text>
                <Trans>This is item is already in your policy</Trans>
              </Text>
            </HStack>
          )}
        </Stack>
        {layout === "table" && (
          <Flex align="center" justify="center" h="100%" px="4">
            <Link
              to={`${MGMT_ROUTES.ASSETS}/${hit?.gqlId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip label="View item in new tab">
                <span>
                  <RiEyeLine />
                </span>
              </Tooltip>
            </Link>
          </Flex>
        )}

        {/* {!isItemAlreadyInPolicy && (
          <Button onClick={handleButtonClick}>
            <Trans>Add to policy</Trans>
          </Button>
        )} */}
      </Stack>
    </GridItem>
  );
};

export default CustomHit;
