import {
  PolicyInputSchema as generatedInputSchema,
  UpdatePolicyInputDataSchema as generatedUpdatePolicyInputDataSchema,
  AssetInputSchema as generatedAssetInputSchema
} from "graphql/queries/generated/validation-schema";
import * as Yup from "yup";
import { AssetInput, PolicyInput, UpdatePolicyInputData } from "../generated/queries";

export function PolicyInputSchema(): Yup.SchemaOf<PolicyInput> {
  return generatedInputSchema()
    .shape(
      {
        offerCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "PENDING",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "offerCode"]]
    )
    .shape(
      {
        policyCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "APPROVED",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "policyCode"]]
    );
}

export function UpdatePolicyInputDataSchema(): Yup.SchemaOf<UpdatePolicyInputData> {
  return generatedUpdatePolicyInputDataSchema()
    .shape(
      {
        offerCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "PENDING",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "offerCode"]]
    )
    .shape(
      {
        policyCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "APPROVED",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "policyCode"]]
    );
}

export function AssetInputSchema(): Yup.SchemaOf<AssetInput> {
  return generatedAssetInputSchema()
    .shape(
      {
        subcategory: Yup.string().when(["category"], {
          is: (category: string) => category !== "not_validated",
          then: Yup.string().required(),
        }),
      },
      [["category", "subcategory"]]
    )
}