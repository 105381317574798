import { Icon, Image, Button } from "@chakra-ui/react";
import placeholderPNG from "assets/placeholder.png";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { DropdownFilter } from 'components/ui';
import { RiDownloadLine } from "react-icons/ri";
import truncate from "pages/assets/utils/TruncateString";



export const MEDIA_IMAGE_CUSTOM_COLUMNS = [
    {
        Header: "Image",
        footer: "",
        accessor: "file",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? <Button style={{ height: "auto", maxWidth: "120px" }} variant="unstyled" title="Open in new window" onClick={() => window.open(value)} ><img src={value} alt="" /></Button> : <Image src={placeholderPNG} alt="" />,

        disableFilters: true,
    },
    {
        Header: "Dimensions",
        footer: "",
        accessor: "actualImageDimensions",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Type",
        Footer: "",
        accessor: "imageType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "Name",
        Footer: "",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Creation date",
        Footer: "",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Description",
        Footer: "",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{truncate(value, 20)}</div>,

        disableFilters: true,
    },
    {
        Header: "Main",
        Footer: "",
        accessor: "isMainImage",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? (
                <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
            ) : (
                <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
            ),
        Filter: DropdownFilter,
        // disableFilters: true,
    },

];



export const MEDIA_AUDIO_VIDEO_CUSTOM_COLUMNS = [
    {
        Header: "File",
        footer: "",
        accessor: "file",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <a href={value} target="_blank" rel="noreferrer">
                <RiDownloadLine />
            </a>
        ),

        disableFilters: true,
    },
    {
        Header: "Type",
        Footer: "",
        accessor: "audioVideoFileType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "Name",
        Footer: "",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Author",
        Footer: "",
        accessor: "fileAuthor",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },

    {
        Header: "Creation date",
        Footer: "",
        accessor: "creationDate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Upload date",
        Footer: "",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Size",
        Footer: "",
        accessor: "fileSize",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },


];

export const MEDIA_FINGERPRINT_CUSTOM_COLUMNS = [
    {
        Header: "File",
        footer: "",
        accessor: "file",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? <Button style={{ height: "auto", maxWidth: "120px" }} variant="unstyled" title="Download" onClick={() => window.open(value)} ><Image src={placeholderPNG} alt="" /></Button> : <Image src={placeholderPNG} alt="" />,

        disableFilters: true,
    },
    {
        Header: "Matrix type",
        Footer: "",
        accessor: "matrixType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "Name",
        Footer: "",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },

    // {
    //     Header: "Photographic equipment",
    //     Footer: "",
    //     accessor: "photographicEquipment",
    //     Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>value</div>,

    //     disableFilters: true,
    // },
    {
        Header: "Creation date",
        Footer: "",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Glass/Plexy/Acetate",
        Footer: "",
        accessor: "isGlassPlexyAcetate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? (
                <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
            ) : (
                <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
            ),
        Filter: DropdownFilter,
        // disableFilters: true,
    },

];


export const MEDIA_GENERIC_CUSTOM_COLUMNS = [

    {
        Header: "Media type",
        Footer: "",
        accessor: "mediaType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "File",
        footer: "",
        accessor: "file",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
            <a href={value} target="_blank" rel="noreferrer">
                <RiDownloadLine />
            </a>
        ),

        disableFilters: true,
    },
    {
        Header: "Name",
        Footer: "",
        accessor: "name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Description",
        Footer: "",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{truncate(value, 20)}</div>,

        disableFilters: true,
    },
    {
        Header: "Dimensions",
        footer: "",
        accessor: "actualImageDimensions",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Image type",
        Footer: "",
        accessor: "imageType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "Creation date",
        Footer: "",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Main image",
        Footer: "",
        accessor: "isMainImage",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? (
                <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
            ) : (
                <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
            ),
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "Matrix type",
        Footer: "",
        accessor: "matrixType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "Glass/Plexy/Acetate",
        Footer: "",
        accessor: "isGlassPlexyAcetate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? (
                <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
            ) : (
                <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
            ),
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "Audio/Video type",
        Footer: "",
        accessor: "audioVideoFileType",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "File author",
        Footer: "",
        accessor: "fileAuthor",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "File size",
        Footer: "",
        accessor: "fileSize",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Photographic equipment",
        Footer: "",
        accessor: "photographicEquipment",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },

];


export const MEDIA_IMAGE_CUSTOM_FIELDS = MEDIA_IMAGE_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);
export const MEDIA_AUDIO_VIDEO_CUSTOM_FIELDS = MEDIA_AUDIO_VIDEO_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);
export const MEDIA_FINGERPRINT_CUSTOM_FIELDS = MEDIA_FINGERPRINT_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);
export const MEDIA_GENERIC_CUSTOM_FIELDS = MEDIA_GENERIC_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);





export const MEDIA_IMAGE_HIDDEN_FIELDS = [
    "audioVideoFileType",
    "mediaType",
    "creationDate",
    "notes",
    "fileAuthor",
    "fileSize",
    "photographicEquipment",
    "matrixType",
    "isGlassPlexyAcetate",
];

export const MEDIA_AUDIO_VIDEO_HIDDEN_FIELDS = [
    "mediaType",
    "isMainImage",
    "imageType",
    "notes",
    "photographicEquipment",
    "matrixType",
    "isGlassPlexyAcetate",
    "actualImageDimensions",
    "description"
];

export const MEDIA_FINGERPRINT_HIDDEN_FIELDS = [
    "mediaType",
    "isMainImage",
    "imageType",
    "notes",
    "audioVideoFileType",
    "creationDate",
    "fileAuthor",
    "fileSize",
    "actualImageDimensions",
    "description",
    'photographicEquipment'
];


export const MEDIA_GENERIC_HIDDEN_FIELDS = [
    "notes",
    "photographicEquipment",
    "creationDate"
];