/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { AssetInput } from "graphql/queries/generated/queries";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
interface AnyObject {
    [key: string]: any
}
export default (values : AnyObject) => {
    const assetData = { ...values };

    switch(assetData.datingType) {
        case 'PERIOD':

          assetData.isDateInterval = Boolean(false);
          assetData.dateStartDate = null;
          assetData.dateEndDate = null;
          assetData.isDateStartDateBc = Boolean(false);
          assetData.isDateEndDateBc = Boolean(false);
          assetData.isDateApprox = Boolean(false);
          assetData.isYearInterval = Boolean(false);
          assetData.yearStartDate =  null;
          assetData.yearEndDate = null;
          assetData.isYearStartDateBc = Boolean(false);
          assetData.isYearEndDateBc = Boolean(false);
          assetData.isYearApprox = Boolean(false);
          assetData.isCenturyInterval = Boolean(false);
          assetData.centuryStartDate = null;
          assetData.centuryEndDate = null;
          assetData.isCenturyStartDateBc = Boolean(false);
          assetData.isCenturyEndDateBc = Boolean(false);
          assetData.isCenturyApprox = Boolean(false);
          assetData.centuryStartDateWindow = "";
          assetData.centuryEndDateWindow = "";
          break;
        case 'DATE':
          assetData.creationPeriod = "";
          assetData.isYearInterval = Boolean(false);
          assetData.yearStartDate =  null;
          assetData.yearEndDate = null;
          assetData.isYearStartDateBc = Boolean(false);
          assetData.isYearEndDateBc = Boolean(false);
          assetData.isYearApprox = Boolean(false);
          assetData.isCenturyInterval = Boolean(false);
          assetData.centuryStartDate = null;
          assetData.centuryEndDate = null;
          assetData.isCenturyStartDateBc = Boolean(false);
          assetData.isCenturyEndDateBc = Boolean(false);
          assetData.isCenturyApprox = Boolean(false);
          assetData.centuryStartDateWindow = "";
          assetData.centuryEndDateWindow = "";
          if (!assetData.isDateInterval) {
            assetData.dateEndDate = null;
            assetData.isDateEndDateBc = Boolean(false);
          }
          break;
        case 'YEAR':
          assetData.creationPeriod = "";
          assetData.isDateInterval = Boolean(false);
          assetData.dateStartDate = null;
          assetData.dateEndDate = null;
          assetData.isDateStartDateBc = Boolean(false);
          assetData.isDateEndDateBc = Boolean(false);
          assetData.isDateApprox = Boolean(false);
          assetData.isCenturyInterval = Boolean(false);
          assetData.centuryStartDate = null;
          assetData.centuryEndDate = null;
          assetData.isCenturyStartDateBc = Boolean(false);
          assetData.isCenturyEndDateBc = Boolean(false);
          assetData.isCenturyApprox = Boolean(false);
          assetData.centuryStartDateWindow = "";
          assetData.centuryEndDateWindow = "";
          if (!assetData.isYearInterval) {
            assetData.yearEndDate = null;
            assetData.isYearEndDateBc = Boolean(false);
          }
          break;
        case 'CENTURY':
          assetData.creationPeriod = "";
          assetData.isDateInterval = Boolean(false);
          assetData.dateStartDate = null;
          assetData.dateEndDate = null;
          assetData.isDateStartDateBc = Boolean(false);
          assetData.isDateEndDateBc = Boolean(false);
          assetData.isDateApprox = Boolean(false);
          assetData.isYearInterval = Boolean(false);
          assetData.yearStartDate =  null;
          assetData.yearEndDate = null;
          assetData.isYearStartDateBc = Boolean(false);
          assetData.isYearEndDateBc = Boolean(false);
          assetData.isYearApprox = Boolean(false);
          if (!assetData.isCenturyInterval) {
            assetData.centuryEndDate = null;
            assetData.isCenturyEndDateBc = Boolean(false);
            assetData.centuryEndDateWindow = "";
          }
          break;

        default:
          assetData.creationPeriod = "";
          assetData.isDateInterval = Boolean(false);
          assetData.dateStartDate = null;
          assetData.dateEndDate = null;
          assetData.isDateStartDateBc = Boolean(false);
          assetData.isDateEndDateBc = Boolean(false);
          assetData.isDateApprox = Boolean(false);
          assetData.isYearInterval = Boolean(false);
          assetData.yearStartDate =  null;
          assetData.yearEndDate = null;
          assetData.isYearStartDateBc = Boolean(false);
          assetData.isYearEndDateBc = Boolean(false);
          assetData.isYearApprox = Boolean(false);
          assetData.isCenturyInterval = Boolean(false);
          assetData.centuryStartDate = null;
          assetData.centuryEndDate = null;
          assetData.isCenturyStartDateBc = Boolean(false);
          assetData.isCenturyEndDateBc = Boolean(false);
          assetData.isCenturyApprox = Boolean(false);
          assetData.centuryStartDateWindow = "";
          assetData.centuryEndDateWindow = "";
          break;
      }

      return assetData as Partial<AssetInput>;
}