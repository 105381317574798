/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Options,
  AsyncCreatableSelect as CustomAsyncCreatableSelect,
  AsyncSelect as CustomAsyncSelect,
} from "chakra-react-select";
import { Box, Button, FormHelperText, FormLabel, HStack, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  useGetRegistriesBySearchLazyQuery,
  useCreateRegistryMutation,
  useGetRegistryByIdLazyQuery,
  useGetUniqueAnonymousRegistryLazyQuery,
  useGetUniqueUnknownRegistryLazyQuery,
} from "graphql/queries/generated/queries";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useTenantContext } from "contexts/TenantContext";
import { handleMutation } from "middleware/Toaster";
import { Wrapper } from "./Wrapper";
import { FormInputProps } from "./type";

export interface RegistryOption {
  label: string;
  value: string;
}

const Registry = ({
  name,
  label,
  errors,
  control,
  required,
  registryCategory,
  acceptAnonymous = false,
  acceptUnknown = false,
  disableCreation = false,
}: FormInputProps) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [lastOptions, setLastOptions] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isUnknown, setIsUnknown] = useState(false);
  const [getRegistryBySearch] = useGetRegistriesBySearchLazyQuery();
  const [createRegistry, { data, error, loading }] = useCreateRegistryMutation({
    ...handleMutation("Registry created!"),
  });
  const [getRegistryByIdLazy] = useGetRegistryByIdLazyQuery();
  const [getAnonymous] = useGetUniqueAnonymousRegistryLazyQuery();
  const [getUnknown] = useGetUniqueUnknownRegistryLazyQuery();
  const { tenant } = useTenantContext() ?? {};

  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: false },
    defaultValue: "",
  });

  useEffect(() => {
    if (registryCategory === "insurance_company" && tenant?.defaultInsuranceCompany) {
      const newValue = {
        value: tenant?.defaultInsuranceCompany.id,
        label: tenant?.defaultInsuranceCompany.fullName,
      };
      setSelectedValue(newValue);
      onChange(tenant?.defaultInsuranceCompany.id);
    }
  }, [registryCategory, tenant]);

  useEffect(() => {
    if (registryCategory === "broker_seller_agency" && tenant?.defaultBroker) {
      const newValue = {
        value: tenant?.defaultBroker.id,
        label: tenant?.defaultBroker.fullName,
      };
      setSelectedValue(newValue);
      onChange(tenant?.defaultBroker.id);
    }
  }, [registryCategory, tenant]);

  useEffect(() => {
    const asyncGetRegistryByIdLazy = async () => {
      const { data } = await getRegistryByIdLazy({
        variables: {
          id: value,
        },
      });

      if (data.registry) {
        const {
          registry: { fullName },
        } = data;
        setSelectedValue({ label: fullName, value });
      }
    };

    if (value && !isTouched) {
      asyncGetRegistryByIdLazy().catch((e) => console.error(e));
    }
  }, [value, isTouched]);

  const loadOptions = async ({
    inputValue,
    isTouched,
    value,
  }: {
    inputValue: string;
    isTouched: boolean;
    value: string | RegistryOption[];
  }) => {
    // if (inputValue.length <= 3) return [];

    const { data } = await getRegistryBySearch({
      variables: {
        search: inputValue,
      },
    });

    if (!data.registries || data.registries.length === 0) return [];

    const options = data.registries
      .map(({ id, fullName, categories, isAuthor }) => {
        if (!registryCategory) {
          return { label: fullName, value: id };
        }

        if (registryCategory === "author" && isAuthor) {
          return { label: fullName, value: id };
        }

        if (categories?.includes(registryCategory)) {
          return { label: fullName, value: id };
        }

        return null;
      })
      .filter((e) => e);

    setLastOptions(options);
    return options;
  };

  const handleCreateOption = async (inputValue: string) => {
    const { data } = await createRegistry({
      variables: {
        input: {
          registryData: {
            fullName: inputValue,
            categories: registryCategory === "author" ? [] : [registryCategory],
            isCompany: !!["broker_seller_agency", "insurance_company"].includes(registryCategory),
            isAuthor: registryCategory === "author",
          },
        },
      },
    });

    if (data) {
      const newValue = {
        value: data.createRegistry.registry.id,
        label: data.createRegistry.registry.fullName,
      };
      setSelectedValue(newValue);
      onChange(data.createRegistry.registry.id);
    }
  };

  const isOptionSelected = (option: any, values: Options<any>) =>
    values.some(({ value }) => option?.value === value);

  const getOrCreateAnonymous = async () => {
    const { data: getAnonymousData, error: getAnonymousError } = await getAnonymous();
    if (getAnonymousData.allRegistries.edges.length === 0) {
      const { data: createRegistryData, errors: createRegistryErrors } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: "Anonymous",
              isCompany: false,
              isAnonymousTenantDefault: true,
            },
          },
        },
      });

      if (createRegistryErrors) throw new Error("Unable to create anonymous registry entry!");
      const {
        createRegistry: { registry },
      } = createRegistryData;

      return { label: registry.fullName, value: registry.id };
    }

    if (getAnonymousError) throw new Error("Unable to retrieve anonymous registry entry!");
    const {
      allRegistries: { edges },
    } = getAnonymousData;
    const anonymousRegistry = edges[0]?.node;
    return { label: anonymousRegistry.fullName, value: anonymousRegistry.id };
    // return {value: registry.id, label:  registry.fullName}
  };

  const getOrCreateUnknown = async () => {
    const { data: getUnknownData, error: getUnknownError } = await getUnknown();
    if (getUnknownData.allRegistries.edges.length === 0) {
      const { data: createRegistryData, errors: createRegistryErrors } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: "Unknown",
              isCompany: false,
              isUnknownTenantDefault: true,
            },
          },
        },
      });
      if (createRegistryErrors) throw new Error("Unable to create unknown registry entry!"); // return {value: registry.id, label:  registry.fullName}
      const {
        createRegistry: { registry },
      } = createRegistryData;

      return { label: registry.fullName, value: registry.id };
    }

    if (getUnknownError) throw new Error("Unable to retrieve unknown registry entry!");
    const {
      allRegistries: { edges },
    } = getUnknownData;
    const unknownRegistry = edges[0]?.node;
    return { label: unknownRegistry.fullName, value: unknownRegistry.id };
  };

  const setAnonymous = async () => {
    const option = await getOrCreateAnonymous();
    onChange(option.value);
    setIsAnonymous(true);
    setIsUnknown(false);
  };

  const setUnknown = async () => {
    const option = await getOrCreateUnknown();
    onChange(option.value);
    setIsUnknown(true);
    setIsAnonymous(false);
  };

  return (
    <Wrapper name={name} error={errors[name]}>
      <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
      <Box minW="12rem">
        {disableCreation ? (
          <CustomAsyncSelect
            cacheOptions
            isLoading={loading}
            isOptionSelected={isOptionSelected}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            loadOptions={(inputValue) => loadOptions({ isTouched, inputValue, value })}
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            useBasicStyles
            chakraStyles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "0.375rem",
              }),
            }}
            value={selectedValue}
            // value={value}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => {
              onChange(newValue?.value);
              setIsAnonymous(false);
              setIsUnknown(false);
            }}
          />
        ) : (
          <CustomAsyncCreatableSelect
            cacheOptions
            isLoading={loading}
            isOptionSelected={isOptionSelected}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCreateOption={handleCreateOption}
            loadOptions={(inputValue) => loadOptions({ isTouched, inputValue, value })}
            size="sm"
            chakraStyles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "0.375rem",
              }),
            }}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            useBasicStyles
            value={selectedValue}
            // value={value}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => {
              onChange(newValue?.value);
              setIsAnonymous(false);
              setIsUnknown(false);
            }}
          />
        )}

        <FormHelperText fontSize="xs">
          {!data && !error && <Trans>Start typing to search (min 3 characters).</Trans>}

          {error && (
            <Text color="red.500">
              <Trans>Ops! Something went wrong creating a registry entry!</Trans>
              Reason: {error.message}
            </Text>
          )}
        </FormHelperText>
        <HStack mt="2">
          {acceptAnonymous && (
            <Button onClick={setAnonymous} variant={isAnonymous ? "black" : "outline"}>
              <Trans>Anonymous</Trans>
            </Button>
          )}
          {acceptUnknown && (
            <Button onClick={setUnknown} variant={isUnknown ? "black" : "outline"}>
              <Trans>Unknown</Trans>
            </Button>
          )}
        </HStack>
      </Box>
    </Wrapper>
  );
};

export default Registry;
