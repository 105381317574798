/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { RegistryWithOfficeInput } from "components/form";
import { FieldGroup, FormInputHook, MoneyInputHook, RangePicker } from "components/ui";
import POLICY_CONSTANTS from "constants/policy";

import { Trans, useTranslation } from "react-i18next";
import { PolicyType } from "../types";

interface PolicyDetailsFieldsProps {
  policyType: PolicyType;
}

const PolicyDetailsFields = ({ policyType }: PolicyDetailsFieldsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FieldGroup title={t("Parties")} subtitle={t("Parties fields subtitle")} py={8}>
        <VStack width="full" align="left">
          <FormInputHook
            label="Insurance Company"
            name="insuranceCompanyId"
            type="registry"
            registryCategory="insurance_company"
          />
          <FormInputHook
            name="brokerId"
            label="Broker"
            type="registry"
            registryCategory="broker_seller_agency"
          />
          <FormInputHook label="Contracting Party" name="contractingPartyId" type="registry" />
        </VStack>
      </FieldGroup>
      <FieldGroup title={t("Details")} subtitle={t("Details fields subtitle")} py={8}>
        <VStack width="full" align="left">
          <FormInputHook
            label="Policy Status"
            options={POLICY_CONSTANTS.POLICY_STATUS}
            name="policyStatus"
          />

          <HStack>
            <FormInputHook name="policyCode" label="Policy Code" />
            <FormInputHook name="offerCode" label="Offer Code" />
          </HStack>
          <Text variant="muted" fontWeight="400" mt="0 !important" fontSize="xs" pb={2}>
            <Trans>
              Offer code is required to add a new offer, otherwise Policy code is required
            </Trans>
          </Text>
          <HStack alignItems="flex-end">
            <RangePicker names={["issuedAt", "expiresAt"]} />
            {policyType === "PERMANENT_COLLECTION" && (
              <FormInputHook label="Auto renewal?" type="checkbox" name="autoRenewal" />
            )}
          </HStack>
        </VStack>
      </FieldGroup>
      <FieldGroup
        title={policyType === "PERMANENT_COLLECTION" ? t("Collection") : t("Exhibition")}
        subtitle={
          policyType === "PERMANENT_COLLECTION"
            ? t("Collection fields subtitle")
            : t("Exhibition fields subtitle")
        }
        py={8}
      >
        <VStack width="full" align="left">
          <FormInputHook
            label={
              policyType === "PERMANENT_COLLECTION"
                ? "Collection name"
                : "Exhibition Name/Event Name"
            }
            name="eventName"
          />
          {/* <FormInputHook
            name="eventEntityId"
            label={
              policyType === "PERMANENT_COLLECTION"
                ? "Museum/Collector"
                : "Institution/Museum/Location name"
            }
            type="registry"
          /> */}
          <RegistryWithOfficeInput
            registryInputName="eventEntityId"
            officeInputName="eventLocationEntityId"
            officeLabel="Location"
            registryLabel={
              policyType === "PERMANENT_COLLECTION"
                ? "Museum/Collector"
                : "Institution/Museum/Location name"
            }
          />
          {policyType === "TEMPORARY_EXHIBITION" && (
            <RangePicker names={["eventFrom", "eventTo"]} />
          )}
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Parameters")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" spacing="6" align="left">
          <HStack>
            <FormInputHook label="Policy covers terrorism?" type="checkbox" name="coverTerrorism" />
            <FormInputHook label="Exemption" type="checkbox" name="exemption" />
            <FormInputHook label="Policy covers natural disasters?" type="checkbox" name="catNat" />
          </HStack>
          <HStack>
            <FormInputHook
              label="Cover Type"
              options={POLICY_CONSTANTS.COVER_TYPE}
              name="coverType"
            />
            <FormInputHook
              label="Covered Risk Type"
              options={POLICY_CONSTANTS.RISK_TYPE}
              name="coveredRiskType"
            />
          </HStack>
          <FormInputHook
            label="Evaluation Type"
            options={POLICY_CONSTANTS.EVALUATION_TYPE}
            name="evaluationType"
          />
          <FormInputHook name="hasVariableBasket" type="checkbox" label="Has Variable Basket" />
        </VStack>
      </FieldGroup>
      <FieldGroup title={t("Values")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" spacing="6" align="left">
          <MoneyInputHook
            name="totalInsuredValue.amount"
            label="Total Insured Value"
            helperText="This value will be computed by adding all assets values. If you overwrite it, your selected value will be reported in certificates."
          />

          <MoneyInputHook name="totalInsuredPremium.amount" label="Total Insured Premium" />
        </VStack>
      </FieldGroup>
    </>
  );
};

export default PolicyDetailsFields;
