/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import { AssetDocumentInput } from 'graphql/queries/generated/queries';
import { Trans, useTranslation } from "react-i18next";
import { /* Heading, */ Text, useToast, VStack } from "@chakra-ui/react";
import { Table } from "components/ui";
import getColumns from "helpers/getColumns";
import {
  AssetDocumentNode,
  Exact,
  GetAssetByIdQuery,
  useDeleteAssetDocumentMutation,
} from "graphql/queries/generated/queries";
import { ApolloQueryResult } from "@apollo/client";
import { handleMutation } from "middleware/Toaster";
import {
  DOCUMENTS_CUSTOM_COLUMNS,
  DOCUMENTS_CUSTOM_FIELDS,
  DOCUMENTS_HIDDEN_FIELDS,
} from "./DocumentsCustomColumns";

interface AssetDocumentsTableProps {
  data: any[];
  mode?: "view" | "edit";
  // remove?: UseFieldArrayRemove | ((arg0: number) => void);
  onDelete: (id: string) => any;
  // batchActions?: {
  //   name: string | React.ReactNode;
  //   action: (rows: { original: { id: string } }[]) => void;
  // }[];
  viewAction: (row: any) => any;
  editAction: (row: any) => any;
  refetch?: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAssetByIdQuery>>;
}

const OnViewAssetDocumentsTable = ({
  data,
  mode = "edit",
  onDelete,
  viewAction,
  editAction,
  refetch,
}: AssetDocumentsTableProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [
    deleteDocuments,
    { data: deleteDocumentsData, error: deleteDocumentsError, loading: deleteDocumentsLoading },
  ] = useDeleteAssetDocumentMutation({
    ...handleMutation("Document deleted!"),
  });

  if (!data || data?.length === 0)
    return (
      // <Heading size="md" alignSelf="flex-start">
      <Text>
        <Trans>No document has been added yet.</Trans>
      </Text>
      // </Heading>
    );

  const customColumns = DOCUMENTS_CUSTOM_COLUMNS;
  const customFields = DOCUMENTS_CUSTOM_FIELDS;
  const hiddenFields = DOCUMENTS_HIDDEN_FIELDS;

  const columns = getColumns<any>({
    data: data[0],
    customColumns,
    customFields,
  });

  const BATCH_ACTIONS = [
    {
      name: <Trans>Download files</Trans>,
      action: (selectedRows: { original: AssetDocumentNode }[]) => {
        selectedRows.map(({ original: { attachments } }) => {
          (attachments as unknown as any[])?.map(({ file }: { file: string }) => window.open(file));
          return null;
        });
      },
    },
    {
      name: <Trans>Delete documents</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: AssetDocumentNode }[]) => {
        selectedRows.map(async ({ original: { id } }) => {
          await deleteDocuments({
            variables: {
              input: {
                id,
              },
            },
          });
          return null;
        });

        await refetch();
      },
    },
  ];

  return (
    <VStack w="full">
      {/* <Heading size="md" alignSelf="flex-start">
          <Trans>Documents</Trans>
        </Heading> */}
      <Table
        batchActions={BATCH_ACTIONS}
        hiddenColumns={hiddenFields}
        columns={columns}
        data={data}
        editAction={editAction}
        viewAction={viewAction}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        deleteAction={(id: string) => onDelete(id)}
      />
    </VStack>
  );
};

export default OnViewAssetDocumentsTable;
