/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Exact,
  GetPolicyByIdQuery,
  UpdatePolicyInput,
  useUpdatePolicyMutation,
} from "graphql/queries/generated/queries";
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { PolicyType } from "pages/spin/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import { ValidationProvider } from "components/form/ValidationContext";
import { UpdatePolicyInputDataSchema } from "graphql/queries/overrides/";
import { PolicyCard } from "components/ui";
import { ApolloQueryResult } from "@apollo/client";
import { handleMutation } from "middleware/Toaster";
import { PCPolicyAssetFields, TEPolicyAssetFields } from "../types";
import PolicyDetailsForm from "./edit/PolicyDetailsForm";
import PolicyAssetsForm from "./edit/PolicyAssetsForm";
import PolicyNotesForm from "./edit/PolicyNotesForm";
import PolicyDocumentsForm from "./edit/PolicyDocumentsForm";

const EditPolicyPage = ({
  data,
  policyAssetsFields,
  refetch,
}: {
  data: GetPolicyByIdQuery;
  policyAssetsFields: PCPolicyAssetFields | TEPolicyAssetFields;
  refetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetPolicyByIdQuery>>;
}) => {
  const [parsedForTablePolicyAssets, setParsedForTablePolicyAssets] = useState([]);
  const toast = useToast();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation({ ...handleMutation("Policy updated!") });

  const {
    policy: {
      isExpired,
      policyAssets,
      policyDocuments,
      policyNotes,
      totalInsuredPremium,
      totalInsuredValue,
      broker,
      insuranceCompany,
      contractingParty,
      eventEntity,
      eventLocationEntity,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __typename,
      policyType,
      ...policy
    },
  } = data;

  useEffect(() => {
    const parsed = policyAssets.edges.flatMap((e) => {
      // this is needed otherwise asset id could overwrite policy asset id
      const { asset, insuredValue, __typename: removedPolicyAssetTypename, ...rest } = e.node;
      const { id, __typename: removedAssetTypename, ...assetRest } = asset;
      return {
        ...rest,
        insuredValue: {
          amount: insuredValue?.amount,
          currency: insuredValue?.currency?.code,
        },
        asset: asset.id,
        ...assetRest,
      };
    });

    setParsedForTablePolicyAssets(parsed);
  }, [data]);

  const policyDetailsFormMethods = useForm<UpdatePolicyInput["policyData"]>({
    defaultValues: {
      ...policy,
      brokerId: broker?.id,
      insuranceCompanyId: insuranceCompany.id,
      contractingPartyId: contractingParty.id,
      eventEntityId: eventEntity?.id,
      eventLocationEntityId: eventLocationEntity?.id,
      totalInsuredPremium: {
        ...totalInsuredPremium,
        currency: totalInsuredPremium?.currency?.code || "EUR",
      },
      totalInsuredValue: {
        ...totalInsuredValue,
        currency: totalInsuredValue?.currency?.code || "EUR",
      },
    },
    resolver: yupResolver(UpdatePolicyInputDataSchema()),
  });

  useEffect(() => {
    policyDetailsFormMethods.setValue('totalInsuredValue', {
      ...totalInsuredValue,
      currency: totalInsuredValue?.currency?.code || "EUR",
    });
  }, [data]);

  const {
    formState: { errors, isDirty },
    setFocus,
  } = policyDetailsFormMethods;

  useEffect(() => {
    console.log(errors);

    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof UpdatePolicyInput["policyData"]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const policyDetailsFormSubmit: SubmitHandler<
    UpdatePolicyInput["policyData"] & { id: string }
  > = async (formData, e) => {
    const {
      totalInsuredPremium: totalInsuredPremiumEdited,
      totalInsuredValue: totalInsuredValueEdited,
      id,
      ...policyData
    } = formData;

    await updatePolicy({
      variables: {
        input: {
          id,
          policyData: {
            ...policyData,
            totalInsuredPremium: {
              amount: totalInsuredPremiumEdited?.amount,
              currency: totalInsuredPremiumEdited?.currency || "EUR",
            },
            totalInsuredValue: {
              amount: totalInsuredValueEdited?.amount,
              currency: totalInsuredValueEdited?.currency || "EUR",
            },
          },
        },
      },
    });
  };

  const watchPolicy = policyDetailsFormMethods.watch();
  console.log({ watchPolicy });
  // eslint-disable-next-line @typescript-eslint/no-shadow

  return (
    <>
      <PolicyCard
        policyCode={policy?.policyCode}
        policyStatus={policy?.policyStatus}
        offerCode={policy?.offerCode}
        policyType={policyType}
        isExpired={isExpired}
        id={policy?.id}
      />
      <Tabs
        colorScheme="brand"
        variant="enclosed"
        mt="4"
        index={tabIndex}
        onChange={handleTabChange}
      >
        <TabList>
          <Tab>
            <Trans>Details</Trans>
          </Tab>
          <Tab>
            <Trans>Assets</Trans>
          </Tab>
          <Tab>
            <Trans>Notes</Trans>
          </Tab>
          <Tab>
            <Trans>Documents</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <FormProvider {...policyDetailsFormMethods}>
              <ValidationProvider schema={UpdatePolicyInputDataSchema()}>
                <PolicyDetailsForm
                  policyType={policyType as PolicyType}
                  onSubmit={policyDetailsFormMethods.handleSubmit(policyDetailsFormSubmit)}
                  data={updatePolicyData}
                  loading={updatePolicyLoading}
                  error={updatePolicyError}
                  isDirty={isDirty}
                />
              </ValidationProvider>
            </FormProvider>
          </TabPanel>
          <TabPanel p={0}>
            <PolicyAssetsForm
              type={policyType as "TEMPORARY_EXHIBITION" | "PERMANENT_COLLECTION"}
              policy={policy}
              data={parsedForTablePolicyAssets}
              policyAssetFields={policyAssetsFields}
              refetch={refetch}
              policyDetailsFormMethods={policyDetailsFormMethods}
            />
          </TabPanel>
          <TabPanel p={0}>
            <PolicyNotesForm data={policyNotes?.edges?.map(({ node }) => node)} />
          </TabPanel>
          <TabPanel p={0}>
            <PolicyDocumentsForm
              policyId={policy.id}
              refetch={refetch}
              data={policyDocuments?.edges?.map(({ node }) => node)}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Stack spacing="4" />
    </>
  );
};

export default EditPolicyPage;
