import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AssetInput, Money, useCreateAssetMutation } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";
import ShortAssetForm from "pages/mgmt/ShortAssetForm";
import cleanObject from "helpers/cleanObject";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { handleMutation } from "middleware/Toaster";

interface NewAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  addPolicyAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
}

const defaultValues: Partial<AssetInput> = {
  title: "",
  creationDateNotes: "",
  authorEntityId: null,
  category: null,
  subcategory: null,
  series: "",
  seriesDetails: "",
  sku: "",
  nft: false,
  nftNotes: "",
  technique: "",
  netHeight: null,
  netWidth: null,
  netDepth: null,
  netDiameter: null,
  netLengthUnit: null,
  netWeightUnit: null,
  netWeight: null,
  netDimensionsAcquisition: "",
  grossHeight: null,
  grossWidth: null,
  grossDepth: null,
  grossDiameter: null,
  grossLengthUnit: "",
  grossWeightUnit: "",
  grossWeight: null,
  grossDimensionsAcquisition: "",
  carryingValueAmount: {
    amount: null,
    currency: "EUR",
  },
  validated: false,
};

const NewAssetDrawer = ({ show, onClose, addPolicyAsset }: NewAssetDrawerProps) => {
  const methods = useForm<AssetInput & { insuredValue: Money }>({
    defaultValues,
    // resolver: yupResolver(AssetInputSchema()),
  });

  const [createAsset, { data, error, loading }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });

  const {
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<AssetInput & { insuredValue: Money }> = async (formData, e) => {
    const { insuredValue, ...rest } = formData;
    const assetData = cleanObject(rest) as AssetInput;
    const { data: createAssetData } = await createAsset({
      variables: {
        input: {
          assetData: {
            ...assetData,
            validated: true,
          },
        },
      },
    });

    methods.reset({
      ...defaultValues,
    });

    addPolicyAsset({
      ...createAssetData.createAsset.asset,
      gqlId: createAssetData.createAsset.asset.id,
    });

    if (createAssetData) onClose();
  };

  return (
    <Drawer
      isOpen={show}
      placement="right"
      onClose={onClose}
      size="xl"
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay maxW="80vw" w="80vw" />
      <DrawerContent maxW="80vw" w="80vw">
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>Create a new asset</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>
              Here you can create a new asset and immediately add it to the policy. Note that the
              asset will be inserted even if the policy is not saved.
            </Trans>
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <FormProvider {...methods}>
            <ShortAssetForm
              onSubmit={methods.handleSubmit(onSubmit)}
              data={data}
              errors={error && [error]}
              loading={loading}
              reset={() => methods.reset()}
              onClose={onClose}
            />
          </FormProvider>
        </DrawerBody>

        <DrawerFooter>
          {/* <Button variant='outline' mr={3} onClick={onClose}>
          Cancel
        </Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NewAssetDrawer;
