// import { useMutation, useQuery } from "@apollo/client";
import {
  //  Badge,
  Box,
  Button,
  Divider,
  //  Heading,
  //  HStack,
  // Image,
  Icon,
  Image,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
// import { FocusableElement } from "@chakra-ui/utils";
import {
  NoDataPlaceholder,
  QuickActions,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
  BooleanFilter,
} from "components/ui";
// import placeholderPNG from "assets/placeholder.png";
// import { GET_ALL_ASSETS } from "graphql/queries";
// import { SEARCH_ASSETS } from "graphql/queries/assets.gql";
import {
  useGetAllAssetsQuery,
  useDeleteAssetMutation,
  AssetNode,
  useExportAssetPdfMutation,
  // GetAllAssetsDocument,
  // GetAllAssets_allAssets_edges,
  // GetAllAssets_allAssets_edges_node,
} from "graphql/queries/generated/queries";

import getColumns from "helpers/getColumns";
// import { MutableRefObject, useRef /* , useState, useEffect */ } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiPlusCircle, BiCheckCircle, BiXCircle } from "react-icons/bi";
// import { Link } from "react-router-dom";

// import { SearchAssets_assets, SearchAssetsVariables, SearchAssets } from '../../graphql/queries/types/SearchAssets';
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import ASSET_CONSTANTS from "constants/asset";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import findLabelsArray from "./shared/FindLabelsArray";

// const CUSTOM_FIELDS = ["userType", "country"];
// const CUSTOM_COLUMNS = [
//   {
//     Header: "User Type",
//     Footer: "User Type",
//     accessor: "userType",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
//   {
//     Header: "Country",
//     Footer: "Country",
//     accessor: "country.code",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
// ];

// interface GetAllAssetsNodeDimensions extends GetAllAssets_allAssets_edges_node{
//   dimensions?: string
// }

const CUSTOM_FIELDS = [
  "genericDimensions",
  "netLengthUnit",
  "creationDate",
  "nft",
  "sku",
  "description",
  "validated",
  "inventoryNumber",
  "archiveNumber",
  "catalogNumber",
  "deleted",
  "createdBy",
  "authorEntity",
  "mainImage",
  "title",
  "category",
  "subcategory",
  "technique",
  "actualDateAndPeriod",
  "author",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Thumbnail",
    footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button
          style={{ height: "auto", maxWidth: "120px" }}
          variant="unstyled"
          title="Open in new window"
          onClick={() => window.open(value)}
        >
          <Image src={value} alt="" h="3rem" w="full" />
        </Button>
      ) : (
        ""
      ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: "Category",
    Footer: "Category",
    disableFilters: true,
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Trans>{value}</Trans>,
  },
  {
    Header: "Author",
    Footer: "Author",
    disableFilters: true,
    accessor: "authorEntity.fullName",
    sortInverted: true,
    sortType: "basic",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Title",
    Footer: "Title",
    disableFilters: true,
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  {
    Header: "Subcategory",
    Footer: "Subcategory",
    disableFilters: true,
    accessor: "subcategory",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Trans>{value}</Trans>,
  },
  {
    Header: "Creation Date",
    Footer: "Creation Date",
    accessor: "actualDateAndPeriod",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  // {
  //   Header: "Creation Date",
  //   Footer: "Creation Date",
  //   accessor: "creationDate",
  //   disableFilters: true,
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => {
  //     if (value) {
  //       const d = new Date(value).toLocaleDateString("it-IT");
  //       return <div>{d}</div>;
  //     }
  //     return <div />;
  //   },
  // },

  {
    Header: "Dimensions",
    Footer: "Dimensions",
    disableFilters: true,
    accessor: "genericDimensions",
    disableSortBy: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Technique",
    Footer: "Technique",
    disableFilters: true,
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "N.inventory",
    Footer: "N.inventory",
    disableFilters: true,
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "N.archive",
    Footer: "N.archive",
    disableFilters: true,
    accessor: "archiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  // {
  //   Header: "N.catalog",
  //   Footer: "N.catalog",
  //   disableFilters: true,
  //   accessor: "catalogNumber",
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{ value }</div>
  // },
  {
    Header: "nft",
    Footer: "nft",
    accessor: "nft",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  {
    Header: "validated",
    Footer: "validated",
    accessor: "validated",
    Filter: BooleanFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

const FILTERABLE_COLUMNS = ["deleted" /* , "status" */];
const QUICK_ACTIONS = [
  {
    name: "Add new assets",
    href: "new",
    icon: <BiPlusCircle />,
  },
  //   {
  //     name: "Invite user",
  //     href: "invite",
  //     icon: <BiPlusCircle />,
  //   },
];

const lengthUnitLabels = findLabelsArray(ASSET_CONSTANTS.LENGTH_UNIT);

const calculateDimensions = (
  netHeight?: number | null,
  netWidth?: number | null,
  netDepth?: number | null,
  netDiameter?: number | null,
  netLengthUnit?: string | null
): string => {
  let dimensions = "";
  if (netHeight != null && netWidth != null && netDepth != null) {
    dimensions = `${netHeight}x${netWidth}x${netDepth}` || "";
    if (netDiameter) {
      dimensions = `HxWxD: ${dimensions} Diam: ${netDiameter}`;
    }
    if (netLengthUnit != null) {
      dimensions = `${dimensions} ${netLengthUnit}`;
    }
  } else {
    if (netHeight) {
      dimensions = `${dimensions}H: ${netHeight} `;
    }
    if (netWidth) {
      dimensions = `${dimensions}W: ${netWidth} `;
    }
    if (netDepth) {
      dimensions = `${dimensions}D: ${netDepth} `;
    }
    if (netDiameter) {
      dimensions = `Diam: ${netDiameter} `;
    }
    if (netLengthUnit) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const label: string = lengthUnitLabels[netLengthUnit] ?? netLengthUnit;
      dimensions = `${dimensions} ${label}`;
    }
  }

  return dimensions;
};

const AssetsPage = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const [
    exportAssetPdf,
    { data: exportAssetPdfData, error: exportAssetPdfError, loading: exportAssetPdfLoading },
  ] = useExportAssetPdfMutation({
    ...handleMutation("Asset exported!"),
  });

  const { data, loading, error, refetch } = useGetAllAssetsQuery();

  const [
    deleteAsset,
    { data: deleteAssetData, error: deleteAssetError, loading: deleteAssetLoading },
  ] = useDeleteAssetMutation({
    ...handleMutation("Asset deleted!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.allAssets || data.allAssets.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  if (!data || !data.allAssets || data.allAssets.edges.length === 0) return null;
  const {
    allAssets: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = data.allAssets.edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await deleteAsset({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onGeneratePdf = async (assetId: string) => {
    const { data } = await exportAssetPdf({
      variables: {
        input: {
          assetId,
        },
      },
    });

    const {
      exportAssetPdf: { url },
    } = data;

    // window.open(url);
    await downloadFile(url);
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete asset(s)</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: AssetNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onDelete(original.id);
            return null;
          })
        );
        await refetch();
      },
      loading: deleteAssetLoading,
    },
    {
      name: <Trans>Export PDF</Trans>,
      action: async (selectedRows: { original: AssetNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onGeneratePdf(original.id);
            return null;
          })
        );
      },
      loading: exportAssetPdfLoading,
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allAssets.edges.map(({ node }) => node)}
          pathname="/mgmt/assets/"
          deleteAction={(id: string) => onDelete(id)}
          isLoading={deleteAssetLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default AssetsPage;
