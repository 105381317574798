import { Drawer, DrawerContent, DrawerOverlay, Flex, Spinner } from "@chakra-ui/react";

const Overlay = ({ show }: { show: boolean }) => (
  <Drawer placement="right" isOpen={show} onClose={() => {}} size="full">
    <DrawerOverlay />
    <DrawerContent bg="transparent">
      <Flex w="full" h="full" justify="center" align="center">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.500" size="xl" />
      </Flex>
    </DrawerContent>
  </Drawer>
);

export default Overlay;
