/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */

import { /* Trans, */ Trans, useTranslation } from "react-i18next";
import {
  Button,
  Box,
  Stack,
  StackDivider,
  useDisclosure,
  useToast,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  VStack,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { MediaInputSchema } from "graphql/queries/generated/validation-schema";
// import { FormInputHook, MoneyInputHook } from "components/ui";
import { useState, useEffect } from "react";
// import  cleanObject from 'helpers/cleanObject';
import {
  MediaInput,
  useGetMediaAssetByIdLazyQuery,
  useAddAssetMediaMutation,
  useDeleteAssetMediaMutation,
  useUpdateMediaMutation,
} from "graphql/queries/generated/queries";

import ASSET_CONSTANTS from "constants/asset";
import { ValidationProvider } from "components/form/ValidationContext";
import { FormInputHook } from "components/ui";
import clearAssetMediaValues from "pages/assets/utils/ClearAssetMediaValues";
import OnViewAssetMediaTable from "pages/assets/shared/OnViewAssetMediaTable";
import { handleMutation } from "middleware/Toaster";

interface AssetMediaFormProps {
  assetId: string;
  // children: any;
}

const AssetMediaForm = ({ assetId }: AssetMediaFormProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm<MediaInput>({
    defaultValues: {
      name: "",
      file: "",
      description: "",
      mediaType: "",
      isMainImage: Boolean(false),
      imageType: "",
      notes: "",
      audioVideoFileType: "",
      creationDate: "",
      fileAuthor: "",
      fileSize: "",
      photographicEquipment: "",
      matrixType: "",
      isGlassPlexyAcetate: Boolean(false),
      // assetId
    },
    resolver: yupResolver(MediaInputSchema()),
    mode: "onChange",
  });

  const { setValue, watch } = methods;

  const [selectedId, setSelectedId] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);

  const toast = useToast();

  const { t } = useTranslation();

  const onEditModalOpen = (
    id: string,
    name: string,
    file: string,
    description: string,
    mediaType: string,
    isMainImage: boolean,
    imageType: string,
    notes: string,
    audioVideoFileType: string,
    creationDate: string | null,
    fileAuthor: string,
    fileSize: string,
    photographicEquipment: string,
    matrixType: string,
    isGlassPlexyAcetate: boolean
  ) => {
    setSelectedId(id);
    setValue("name", name);
    setValue("file", file);
    setValue("description", description);
    setValue("mediaType", mediaType);
    setValue("isMainImage", isMainImage);
    setValue("imageType", imageType);
    setValue("notes", notes);
    setValue("audioVideoFileType", audioVideoFileType);
    setValue("creationDate", creationDate);
    setValue("fileAuthor", fileAuthor);
    setValue("fileSize", fileSize);
    setValue("photographicEquipment", photographicEquipment);
    setValue("matrixType", matrixType);
    setValue("isGlassPlexyAcetate", isGlassPlexyAcetate);
    setCurrentFile(file);
    onOpen();
  };

  const [loadMediaAssetByIdValues, refetch] = useGetMediaAssetByIdLazyQuery();

  const [
    deleteAssetMedia,
    { data: deleteAssetMediaData, error: deleteAssetMediaError, loading: deleteAssetMediaLoading },
  ] = useDeleteAssetMediaMutation({
    ...handleMutation("Asset media deleted!"),
  });

  const [assetMedias, setAssetMedias] = useState<
    Array<{
      id: string;
      name: string;
      file: string;
      description: string;
      mediaType: string;
      isMainImage: boolean;
      imageType: string;
      notes: string;
      audioVideoFileType: string;
      creationDate: string;
      fileAuthor: string;
      fileSize: string;
      photographicEquipment: string;
      matrixType: string;
      isGlassPlexyAcetate: boolean;
    }>
  >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedMediaAssetByIdValues = async () => {
    const {
      data: assetMedias,
      loading: assetMediasLoading,
      error: assetMediasError,
    } = await loadMediaAssetByIdValues({
      variables: {
        id: assetId,
      },
    });

    if (!assetMedias || !assetMedias.asset || !assetMedias.asset.media) return null;
    const {
      media: { edges },
    } = assetMedias.asset;
    const nodes:
      | ((
          prevState: {
            id: string;
            name: string;
            file: string;
            /* eslint-disable @typescript-eslint/no-unsafe-member-access */
            /* eslint-disable react/display-name */
            /* eslint-disable react/prop-types */
            /* eslint-disable @typescript-eslint/no-unsafe-assignment */
            /* eslint-disable react/jsx-key */
            description: string;
            mediaType: string;
            isMainImage: boolean;
            imageType: string;
            notes: string;
            audioVideoFileType: string;
            creationDate: string;
            fileAuthor: string;
            fileSize: string;
            photographicEquipment: string;
            matrixType: string;
            isGlassPlexyAcetate: boolean;
          }[]
        ) => {
          id: string;
          name: string;
          file: string;
          description: string;
          mediaType: string;
          isMainImage: boolean;
          imageType: string;
          notes: string;
          audioVideoFileType: string;
          creationDate: string;
          fileAuthor: string;
          fileSize: string;
          photographicEquipment: string;
          matrixType: string;
          isGlassPlexyAcetate: boolean;
        }[])
      | {
          id: string;
          name: string;
          file: string;
          description: string;
          mediaType: string;
          isMainImage: boolean;
          imageType: string;
          notes: string;
          audioVideoFileType: string;
          creationDate: any;
          fileAuthor: string;
          fileSize: string;
          photographicEquipment: string;
          matrixType: string;
          isGlassPlexyAcetate: boolean;
        }[] = [];
    edges.forEach((element) => {
      if (element.node) {
        nodes.push({
          id: element.node.id,
          name: element.node.name,
          file: element.node.file,
          description: element.node.description,
          mediaType: element.node.mediaType,
          isMainImage: element.node.isMainImage,
          imageType: element.node.imageType,
          notes: element.node.notes,
          audioVideoFileType: element.node.audioVideoFileType,
          creationDate: element.node.creationDate,
          fileAuthor: element.node.fileAuthor,
          fileSize: element.node.fileSize,
          photographicEquipment: element.node.photographicEquipment,
          matrixType: element.node.matrixType,
          isGlassPlexyAcetate: element.node.isGlassPlexyAcetate,
        });
      }
    });
    setAssetMedias(nodes);
    // setAssetMedias(edges.flatMap(( { node } ) => ({
    //     id: node.id,
    //     name: node.name,
    //     file: node.file,
    //     description: node.description,
    //     mediaType: node.mediaType,
    //     isMainImage: node.isMainImage,
    //     imageType: node.imageType,
    //     notes: node.notes,
    //     audioVideoFileType: node.audioVideoFileType,
    //     creationDate: node.creationDate,
    //     fileAuthor: node.fileAuthor,
    //     fileSize: node.fileSize,
    //     photographicEquipment: node.photographicEquipment,
    //     matrixType: node.matrixType,
    //     isGlassPlexyAcetate: node.isGlassPlexyAcetate,
    // })));
    return assetMedias;
  };

  const onDelete = async (id: string) => {
    await deleteAssetMedia({
      variables: {
        input: {
          mediaId: id,
          id: assetId,
        },
      },
    });
    const assetMedias = await loadParsedMediaAssetByIdValues();
    return assetMedias;
  };

  // const onDelete = async (id: string) => {
  //     await deleteAssetPresentValue({
  //       variables: {
  //         input: {
  //           id,
  //         },
  //       },
  //     });

  //     const assetPresentValues = await loadParsedAssetPresentValues();
  //     return assetPresentValues;
  // };

  const [
    addAssetMedia,
    { data: addAssetMediaData, loading: addAssetMediaLoading, error: addAssetMediaError },
  ] = useAddAssetMediaMutation({
    ...handleMutation("Asset media added!"),
  });

  const [
    updateMedia,
    { data: updateMediaData, loading: updateMediaLoading, error: updateMediaError },
  ] = useUpdateMediaMutation({
    ...handleMutation("Asset media updated!"),
  });

  // const [
  //     createAssetPresentValue,
  //     { data: createAssetPresentValueData, loading: createAssetPresentValueLoading, error: createAssetPresentValueError },
  //   ] = useCreateAssetPresentValueMutation();

  // const [
  //     updateAssetPresentValue, {
  //         data: updateAssetPresentValueData, loading: updateAssetPresentValueLoading, error: updateAssetPresentValueError
  //     }

  // ] = useUpdateAssetPresentValueMutation();

  const findLabelByValue = (value: string, array: { value: string; label: string }[]) => {
    const obj = array.find((x) => x.value === value);
    return obj ? obj.label : "";
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadParsedMediaAssetByIdValues();
    return undefined;
  }, []);
  // useEffect(() => {}, [assetPresentValues]);

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("file", "");
    setValue("description", "");
    setValue("mediaType", "");
    setValue("isMainImage", Boolean(false));
    setValue("imageType", "");
    setValue("notes", "");
    setValue("audioVideoFileType", "");
    setValue("creationDate", null);
    setValue("fileAuthor", "");
    setValue("fileSize", "");
    setValue("photographicEquipment", "");
    setValue("matrixType", "");
    setValue("isGlassPlexyAcetate", Boolean(false));
    setCurrentFile(null);
    onClose();
  };

  const onSubmit: SubmitHandler<MediaInput> = async (values: any, e: any) => {
    // TODO a seconda del mediaType si devono pulire i valori da postare
    const mediaData = clearAssetMediaValues(values);
    let assetMedias;
    let response;
    if (selectedId) {
      response = await updateMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
              mediaType: mediaData.mediaType,
              isMainImage: mediaData.isMainImage,
              imageType: mediaData.imageType,
              notes: mediaData.notes,
              audioVideoFileType: mediaData.audioVideoFileType,
              creationDate: mediaData.creationDate === "" ? null : mediaData.creationDate,
              fileAuthor: mediaData.fileAuthor,
              fileSize: mediaData.fileSize,
              photographicEquipment: mediaData.photographicEquipment,
              matrixType: mediaData.matrixType,
              isGlassPlexyAcetate: mediaData.isGlassPlexyAcetate,
            },
            id: selectedId,
          },
        },
      });
      if (
        response.data &&
        response.data.updateMedia &&
        response.data.updateMedia.media &&
        response.data.updateMedia.media.id
      ) {
        assetMedias = await loadParsedMediaAssetByIdValues();
        onCloseModal();
      }
    } else {
      response = await addAssetMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
              mediaType: mediaData.mediaType,
              isMainImage: mediaData.isMainImage,
              imageType: mediaData.imageType,
              notes: mediaData.notes,
              audioVideoFileType: mediaData.audioVideoFileType,
              creationDate: mediaData.creationDate === "" ? null : mediaData.creationDate,
              fileAuthor: mediaData.fileAuthor,
              fileSize: mediaData.fileSize,
              photographicEquipment: mediaData.photographicEquipment,
              matrixType: mediaData.matrixType,
              isGlassPlexyAcetate: mediaData.isGlassPlexyAcetate,
            },
            assetId,
          },
        },
      });
      if (response.data && response.data.addAssetMedia && response.data.addAssetMedia.asset) {
        assetMedias = await loadParsedMediaAssetByIdValues();
        onCloseModal();
      }
    }

    return assetMedias;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  const mediaType = watch("mediaType");

  const { file, name } = methods.getValues();
  console.log({ mediaType });
  return (
    <Box p={6} maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}>
        <OnViewAssetMediaTable
          data={assetMedias}
          editAction={({ row }) =>
            onEditModalOpen(
              row?.original?.id,
              row?.original?.name,
              row?.original?.file,
              row?.original?.description,
              row?.original?.mediaType,
              row?.original?.isMainImage,
              row?.original?.imageType,
              row?.original?.notes,
              row?.original?.audioVideoFileType,
              row?.original?.creationDate ?? null,
              row?.original?.fileAuthor,
              row?.original?.fileSize,
              row?.original?.photographicEquipment,
              row?.original?.matrixType,
              row?.original?.isGlassPlexyAcetate
            )
          }
          type="GENERIC"
          mode="edit"
          onDelete={(id: string) => onDelete(id)}
        />

        <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
          <Button onClick={onOpen} variant="primary">
            <Trans>Add new object media</Trans>
          </Button>
        </ButtonGroup>

        <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedId ? <Trans>Update asset media</Trans> : <Trans>Add new asset media</Trans>}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormProvider {...methods}>
                <ValidationProvider schema={MediaInputSchema()}>
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form id="addAssetMedia" onSubmit={handleSubmit}>
                    <Box maxW="6xl">
                      <Stack spacing="4" divider={<StackDivider />}>
                        <HStack alignItems="flex-end">
                          {/* <FormInputHook label="Estimate date" name="estimateDate" type="date" /> */}
                          <FormInputHook
                            label="Media type"
                            options={ASSET_CONSTANTS.MEDIA_TYPES.filter(
                              (element, index) =>
                                index !== ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION
                            )}
                            name="mediaType"
                          />
                          {mediaType && <FormInputHook label="Media name" name="name" />}
                        </HStack>
                        {mediaType && (
                          <HStack alignItems="flex-end">
                            <FormInputHook
                              minW="30rem"
                              label="Description"
                              name="description"
                              type="textarea"
                            />
                          </HStack>
                        )}

                        {mediaType.toString() === "IMAGE" && (
                          <VStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Image type"
                                options={ASSET_CONSTANTS.IMAGE_TYPES}
                                name="imageType"
                              />
                              <FormInputHook
                                label="Is main image?"
                                name="isMainImage"
                                type="checkbox"
                              />
                            </HStack>
                          </VStack>
                        )}
                        {mediaType.toString() === "AUDIO_VIDEO" && (
                          <VStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Audio video file type"
                                name="audioVideoFileType"
                              />
                              <FormInputHook label="File size" name="fileSize" />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Author" name="fileAuthor" />
                              <FormInputHook
                                label="Creation date"
                                name="creationDate"
                                type="date"
                              />
                            </HStack>
                          </VStack>
                        )}

                        {mediaType.toString() === "FINGERPRINT" && (
                          <VStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Matrix type"
                                options={ASSET_CONSTANTS.MATRIX_TYPES}
                                name="matrixType"
                              />
                              <FormInputHook
                                label="Glass/Plexy/Acetate?"
                                name="isGlassPlexyAcetate"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Photographic equipment"
                                name="photographicEquipment"
                              />
                            </HStack>
                          </VStack>
                        )}

                        <HStack alignItems="flex-end">
                          {currentFile &&
                            mediaType === "IMAGE" &&
                            typeof currentFile === "string" && (
                              <img
                                alt=""
                                style={{ width: "60px", height: "auto" }}
                                src={currentFile as string}
                              />
                            )}
                          {currentFile && mediaType !== "IMAGE" && typeof currentFile === "string" && (
                            <Button
                              title="Open in new window"
                              onClick={() => window.open(currentFile)}
                              fontWeight="600"
                            >
                              Vedi file
                            </Button>
                          )}

                          {/* {file && <Text onClick={() => window.open(file)} fontWeight="600">{name}</Text>} */}

                          {mediaType && (
                            <FormInputHook
                              label="File"
                              name="file.0.file"
                              type="file"
                              accept={mediaType === "AUDIO_VIDEO" ? "audio/*,video/*" : "*"}
                            />
                          )}
                          {/* { mediaType && (!file || Array.isArray(file)) && <FormInputHook label="File" name="file.0.file" type="file" /> } */}
                        </HStack>

                        {mediaType && (
                          <HStack alignItems="flex-end">
                            <FormInputHook
                              minW="30rem"
                              label="notes"
                              name="notes"
                              type="textarea"
                            />
                          </HStack>
                        )}
                      </Stack>
                    </Box>
                  </form>
                </ValidationProvider>
              </FormProvider>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  mr={1}
                  disabled={mediaType.length === 0}
                  isLoading={addAssetMediaLoading || updateMediaLoading}
                  loadingText="Loading"
                >
                  <Trans>Save</Trans>
                </Button>
                <Button onClick={onCloseModal}>
                  <Trans>Cancel</Trans>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </Box>
  );
};

export default AssetMediaForm;
