import { ApolloQueryResult, InternalRefetchQueryDescriptor } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { FileCard } from "components/ui";
import {
  Exact,
  GetPolicyDocumentsByPolicyIdQuery,
  PolicyDocumentNode,
  useDeletePolicyDocumentMutation,
  UserNode,
} from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";

interface PolicyDocumentSummaryProps {
  policyDocuments: (Omit<Partial<PolicyDocumentNode>, "createdBy"> & {
    createdBy?: Pick<UserNode, "name">;
  })[];
  refetch?: (
    variables?: Partial<
      Exact<{
        policyId: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetPolicyDocumentsByPolicyIdQuery>> | void;
}

const PolicyDocumentsSummary = ({
  policyDocuments,
  refetch = () => {},
}: PolicyDocumentSummaryProps) => {
  const [
    deletePolicyDocument,
    {
      data: deletePolicyDocumentData,
      loading: deletePolicyDocumentLoading,
      error: deletePolicyDocumentError,
    },
  ] = useDeletePolicyDocumentMutation({
    refetchQueries: [refetch as unknown as InternalRefetchQueryDescriptor],
  });

  if (!policyDocuments || policyDocuments.length === 0)
    return (
      <Text>
        <Trans>No documents added</Trans>
      </Text>
    );

  const onDelete = async (id: string) => {
    await deletePolicyDocument({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <>
      {policyDocuments.map(({ file, id, uuid, createdAt, createdBy }, index) => (
        <FileCard
          id={id}
          uuid={uuid}
          createdAt={createdAt}
          index={index}
          createdBy={createdBy}
          file={file}
          refetch={refetch}
          handleDelete={onDelete}
          success={deletePolicyDocumentData}
          error={deletePolicyDocumentError}
          loading={deletePolicyDocumentLoading}
        />
      ))}
    </>
  );
};

export default PolicyDocumentsSummary;
