import {
    HStack,
    VStack,
    Button,
  } from "@chakra-ui/react";
  
  import { Trans, useTranslation } from "react-i18next";


  // import { CreateAsset } from 'graphql/queries/types/CreateAsset';
  // import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
  import {
    AssetInput,
  } from "graphql/queries/generated/queries";
  import {
    UseFormGetValues,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
  } from "react-hook-form";
  import { FormInputHook, MoneyInputHook, FieldGroup } from "components/ui";
  import { BaseSyntheticEvent } from "react";
  import ASSET_CONSTANTS from "constants/asset";



  interface AssetCarryingValueFormProps {
    onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
    register: UseFormRegister<AssetInput>;
    getValues: UseFormGetValues<AssetInput>;
    watch: any; // UseFormWatch<AssetInput>,
    setValue: UseFormSetValue<AssetInput>;
    setError: UseFormSetError<AssetInput>;
    category: string;
    children?: React.ReactNode;
    currentCarryingValueFile?: string,
    assetId: string,
    deleteCarryingValueFile?: (assetId: string) => Promise<{
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string;
      creationPeriod?: string;
      carryingValueFile?: string;
    }> | undefined;
    deleteCarryingValueFileLoading?: boolean | undefined;

  }

  const AssetCarryingValueForm = ({
    // eslint-disable-next-line react/prop-types
    onSubmit,
    // handleChange,
    // handleRadioButtons,
    // values,
    register,
    setValue,
    setError,
    getValues,
    watch,
    category,
    children,
    assetId,
    currentCarryingValueFile,
    deleteCarryingValueFile,
    deleteCarryingValueFileLoading,
  }: AssetCarryingValueFormProps) => {
    const { t } = useTranslation();
    // const { carryingValueFile: currentFile, title } = getValues();




    return (
      <>
        {/* <Box p={6} maxW="6xl">
          <Stack spacing="4" divider={<StackDivider />}> */}

            <FieldGroup title={t("Origin and acquisition")} subtitle={t("Value - Carrying value - Origin and acquisition - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label={t("Asset origin")}
                    name="carryingValueOrigin"
                  />
                </HStack>

                <HStack>
                  <FormInputHook
                    label="Acquisition"
                    options={ASSET_CONSTANTS.ASSET_VALUES_ACQUISITIONS}
                    name="carryingValueAcquisition"
                  />


                  <FormInputHook
                    label="Carrying value notes"
                    name="carryingValueNotes"
                    type="textarea"
                  />
                </HStack>


                <HStack>
                  <FormInputHook label="Last acquisition date" type="date" name="carryingValueLastAcquisitionDate" />
                </HStack>
              </VStack>
            </FieldGroup>

            <FieldGroup title={t("Value")} subtitle={t("Value - Carrying value - Value - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <MoneyInputHook name="carryingValueAmount.amount" label="Amount" />
                </HStack>
              </VStack>
            </FieldGroup>



            <FieldGroup title={t("File")} subtitle={t("Value - Carrying value - File - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook label="File title" name="carryingValueFileTitle" />
                    <HStack paddingBottom="2">
                    {currentCarryingValueFile && (typeof currentCarryingValueFile ===  'string') &&
                    <Button paddingX="6" title="Download" onClick={() => window.open(currentCarryingValueFile)} fontWeight="600"><Trans>Download</Trans></Button>}

                    {currentCarryingValueFile && (typeof currentCarryingValueFile ===  'string') &&
                    <Button paddingX="6" title="Delete file" isLoading={deleteCarryingValueFileLoading} onClick={() => deleteCarryingValueFile(assetId)} fontWeight="600"><Trans>Delete</Trans></Button>}

                    <FormInputHook label="File" name="carryingValueFile.0.file" type="file" />
                    </HStack>


                </HStack>
              </VStack>
            </FieldGroup>

            {/* <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
            >
              {t("Save")}
            </Button>
            {children} */}
          {/* </Stack>
        </Box> */}
        {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
          <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
              variant="primary"
            >
              {t("Update")}
          </Button>
          {children}
        </ButtonGroup> */}
      </>
    );
  };

  export default AssetCarryingValueForm;
