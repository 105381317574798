import { ApolloError } from "@apollo/client";
import { Text, Alert, AlertIcon, VStack, Button, HStack, StackProps, Box } from "@chakra-ui/react";
import { isArray } from "@chakra-ui/utils";
import { ReactElement } from "react";
import { Trans } from "react-i18next";
import { To, useLocation, useNavigate } from "react-router-dom";
import QuickActionsContainer from "../QuickActions/QuickActionsContainer";

interface FormFooterProps {
  errors: ApolloError[];
  data: any | any[];
  loading: boolean;
  title: string;
  cancelAction?: () => void;
  children?: ReactElement;
  disabled?: boolean;
  sticky?: boolean;
}

const FormFooter = ({
  errors,
  data,
  loading,
  title,
  cancelAction,
  disabled = false,
  children,
  sticky = false,
}: FormFooterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stickyProps = sticky
    ? ({
        position: "sticky",
        bottom: "0",
      } as StackProps)
    : {};
  return (
    <VStack align="flex-start" w="100%" {...stickyProps} mt="4">
      <QuickActionsContainer>
        <Box w="full">
          {errors &&
            errors.filter(Boolean).map((error) => (
              <Alert status="error" rounded="md" mb="4">
                <>
                  <AlertIcon />
                  {error.message}
                </>
              </Alert>
            ))}
          {isArray(data) && data.filter(Boolean).length > 0 && (
            <Alert status="success" rounded="md" mb="4">
              <>
                <AlertIcon />
                <VStack alignItems="flex-start">
                  <Text>
                    <Trans defaults="{{title}} was created successfully" values={{ title }} />
                  </Text>
                </VStack>
              </>
            </Alert>
          )}
          {data && !errors && !isArray(data) && (
            <Alert status="success" rounded="md" mb="4">
              <>
                <AlertIcon />
                <VStack alignItems="flex-start">
                  <Text>
                    <Trans defaults="{{title}} was created successfully" values={{ title }} />
                  </Text>
                </VStack>
              </>
            </Alert>
          )}

          {children ?? (
            <HStack>
              <Button
                isLoading={loading}
                loadingText="Loading"
                type="submit"
                variant="primary"
                disabled={disabled}
              >
                <Trans>Save</Trans>
              </Button>

              <Button
                onClick={
                  cancelAction ??
                  (() =>
                    navigate(-1 as unknown as To, {
                      state: { from: location },
                    }))
                }
                variant="danger"
              >
                <Trans>Cancel</Trans>
              </Button>
            </HStack>
          )}
        </Box>
      </QuickActionsContainer>
    </VStack>
  );
};
export default FormFooter;
