interface ValuesOptionLabels {
    options?: Array<{
        label: string,
        value: string
      }>;
      label: string;
      value?: string;
}

const findLabel = (a: Array<ValuesOptionLabels>, v: string) => {
    let label = "";
    if (v === "") {
        return label;
    }

    a.forEach(element => {
        if (element.value) {
            if(element.value === v) {
                label = element.label;
            }
        }

        if (element.options) {
        element.options.forEach(option => {
            if(option.value === v) {
            label = option.label;
            }
        })
        }
    })
    return label;
}

export default findLabel;