/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
import { FormLabel, Textarea, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { FormInputProps } from "./type";
import { Wrapper } from "./Wrapper";

const TextArea = ({
  name,
  label,
  type,
  errors,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  required,
  watch,
  minW,
}: FormInputProps) => (
  <Wrapper name={name} error={errors[name]}>
    {label && (
      <FormLabel htmlFor={name} mb="1" fontSize="xs">
        {" "}
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
    )}
    <Textarea
      width="auto"
      minW={minW}
      id={name}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      {...register(name)}
      borderRadius="md"
      borderWidth="2px"
      borderColor="gray.200"
    />
  </Wrapper>
);

export default TextArea;
