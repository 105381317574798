/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { AssetDocumentInput } from "graphql/queries/generated/queries";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
interface AnyObject {
    [key: string]: any
}
export default (values : AnyObject) => {
    const assetDocumentData = { ...values };


    switch(assetDocumentData.documentType) {
        case 'AUTHENTICITY_CERTIFICATE':
          assetDocumentData.title = "";
          assetDocumentData.expirationDate = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'ARCHIVING_CERTIFICATE':
          assetDocumentData.title = "";
          assetDocumentData.description = "";
          assetDocumentData.coaType = "";
          assetDocumentData.expirationDate = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";

          break;
        case 'FINE_ARTS_ISSUE':
          assetDocumentData.title = "";
          assetDocumentData.description = "";
          assetDocumentData.coaType = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'CUSTOM_DOCUMENT':
          assetDocumentData.title = "";
          assetDocumentData.description = "";
          assetDocumentData.coaType = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'CATALOG':
          assetDocumentData.coaType = "";
          assetDocumentData.issuer = "";
          assetDocumentData.recipient = "";
          assetDocumentData.expirationDate = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'CONTRACT':
          assetDocumentData.title = "";
          assetDocumentData.coaType = "";
          assetDocumentData.issuer = "";
          assetDocumentData.recipient = "";
          assetDocumentData.releaseDate = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.publishingDate = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'PUBLICATION':
          assetDocumentData.coaType = "";
          assetDocumentData.issuer = "";
          assetDocumentData.recipient = "";
          assetDocumentData.releaseDate = "";
          assetDocumentData.expirationDate = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.otherDocumentType = "";
          break;
        case 'OTHER':
          assetDocumentData.title = "";
          assetDocumentData.coaType = "";
          assetDocumentData.issuer = "";
          assetDocumentData.recipient = "";
          assetDocumentData.expirationDate = "";
          assetDocumentData.archiveNumber = "";
          assetDocumentData.fineArtsIssueType = "";
          assetDocumentData.isTemporaryExport = Boolean(false);
          assetDocumentData.isTemporaryImport = Boolean(false);
          assetDocumentData.isCriticalCatalog = Boolean(false);
          assetDocumentData.editor = "";
          assetDocumentData.curator = "";
          assetDocumentData.subscriptionDate = "";
          assetDocumentData.contractSubscriber = "";
          assetDocumentData.publishingDate = "";
          break;
        default:
      }

      return assetDocumentData as Partial<AssetDocumentInput>;
}