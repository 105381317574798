import { Button } from "@chakra-ui/react";
import { CSVLink, CSVDownload } from "react-csv";
import { useTranslation } from "react-i18next";

const getColumnsForCsv = (columns) => columns.map(({ accessor }) => accessor);

const getDataForCsv = (data) => {
  return data.map((row) => {
    const parsedValues = Object.values(row.values).map((value) => {
      if (!value) return "";
      if (typeof value === "object") {
        try {
          return value?.name ?? value.fullName;
        } catch {
          return "UNABLE TO PARSE FIELD!";
        }
      }
      return value;
    });
    return parsedValues;
  });
};

const TableCSVDownload = ({ data, columns, selectedFlatRows }) => {
  const { t } = useTranslation();
  if (!data || !columns) return <Button disabled>{t("Export to csv")}</Button>;

  const selectedOrAllData =
    selectedFlatRows.length > 0 ? [...getDataForCsv(selectedFlatRows)] : [...getDataForCsv(data)];
  const csvData = [getColumnsForCsv(columns), ...selectedOrAllData];
  if (!csvData) return <Button disabled>{t("Export to csv")}</Button>;

  return (
    <Button size={"sm"}>
      <CSVLink data={csvData}>{t("Export to csv")}</CSVLink>
    </Button>
  );
};

export default TableCSVDownload;
