import { useGetEventLogByAssetIdQuery } from "graphql/queries/generated/queries";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { Table } from "components/ui";
import getColumns from "helpers/getColumns";
import ViewEventLogDrawer from "./ViewEventLogDrawer";
const HIDDEN_FIELDS = ["locationDescription", "node"];
const CUSTOM_FIELDS = [ "eventType", "owner" ];
const CUSTOM_COLUMNS = [
  {
    Header: "Event",
    Footer: "",
    accessor: "eventType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Start date",
    Footer: "",
    accessor: "startDate",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
    weight: 0
  },
  {
    Header: "End date",
    Footer: "",
    accessor: "endDate",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
    weight: 0
  },
  {
    Header: "Owner",
    Footer: "",
    accessor: "owner",
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null) return <div>{value.fullName}</div>;

      return <div />;
    },
    disableFilters: true,
  },
  {
    Header: "External ID",
    Footer: "",
    accessor: "externalId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];

interface EventLogsFormProps {
  assetId: string;
}

const OnViewEventLogs = ({ assetId }: EventLogsFormProps) => {

  const { t } = useTranslation();
  const [ eventLogId, setEventLogId ] = useState("");
  const [ showViewEventLogDrawer, setShowViewEventLogDrawer ] = useState(false);

  const { data, loading, error, refetch } = useGetEventLogByAssetIdQuery({
    variables: {
      id: assetId,
    },
  });

  const onCloseViewDrawer = () => {
    setShowViewEventLogDrawer(false);
  }

  const onView = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setEventLogId(row.original.id);
      setShowViewEventLogDrawer(true);
    }
  };

  if (!data || !data.asset || !data.asset.eventLogs || !data.asset.eventLogs.edges || data.asset.eventLogs.edges.length <= 0) return (
    <Text>
      <Trans>No event log has been added yet.</Trans>
    </Text>
  );
  if (data.asset.eventLogs.edges[0] === null) return (
    <Text>
      <Trans>No event log has been added yet.</Trans>
    </Text>
  );

  const dataToGetColumns = data.asset.eventLogs.edges[0];

  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  return (
    <>      
      <Skeleton isLoaded={!loading}>
        <Table hiddenColumns={HIDDEN_FIELDS} columns={columns} data={data.asset.eventLogs.edges.flatMap((edge) => edge?.node)} viewAction={onView} />
      </Skeleton>
      <ViewEventLogDrawer show={showViewEventLogDrawer} onClose={onCloseViewDrawer} assetId={assetId} eventLogId={eventLogId} />
    </>
  );
}

export default OnViewEventLogs;