import { Trans, useTranslation } from "react-i18next";
import { Box, Heading, VStack, Text, HStack } from "@chakra-ui/react";
import { FieldGroup, FormInputHook, FormFooter, FileCard } from "components/ui";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  useCreateCertificateTemplateMutation,
  useGetAllCertificateTemplatesQuery,
  useDeleteCertificateTemplateMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";

const CertificateTemplatePage = () => {
  const { t } = useTranslation();
  const methods = useForm();

  const { data, error, loading } = useGetAllCertificateTemplatesQuery();
  const [
    createCertificateTemplate,
    {
      data: createCertificateTemplateData,
      error: createCertificateTemplateError,
      loading: createCertificateTemplateLoading,
    },
  ] = useCreateCertificateTemplateMutation({ ...handleMutation("Certificate template created!") });

  const [
    deleteCertificateTemplate,
    {
      data: deleteCertificateTemplateData,
      error: deleteCertificateTemplateError,
      loading: deleteCertificateTemplateLoading,
    },
  ] = useDeleteCertificateTemplateMutation({
    ...handleMutation("Certificate templated deleted!"),
  });

  const onDelete = async (id: string) => {
    await deleteCertificateTemplate({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const onSubmit: SubmitHandler<{ file: FileList; name: string }> = async (formData, e) => {
    const data = await createCertificateTemplate({
      variables: {
        input: {
          file: formData.file[0],
          name: formData.name,
        },
      },
    });
  };

  return (
    <Box my={4}>
      <Heading size="lg" mb={4}>
        <Trans>Files</Trans>
      </Heading>
      <HStack>
        {data?.allCertificateTemplates?.edges?.length > 0 ? (
          data?.allCertificateTemplates?.edges?.map(
            ({ node: { id, file, uuid, createdAt } }, index) => (
              <FileCard
                index={index}
                id={id}
                file={file}
                createdAt={createdAt}
                uuid={uuid}
                handleDelete={onDelete}
                refetch={() => null}
                success={deleteCertificateTemplateData}
                loading={deleteCertificateTemplateLoading}
                error={deleteCertificateTemplateError}
              />
            )
          )
        ) : (
          <Text>
            <Trans>No files have been uploaded yet!</Trans>
          </Text>
        )}
      </HStack>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <QuickActions actions={QUICK_ACTIONS} /> */}
          <VStack mt={8} align="flex-start" w="100%">
            <Heading>
              <Trans>Cert name</Trans>
            </Heading>
            <Text variant="muted">Lorem ipsum dolor sit amet</Text>
            <FieldGroup
              title={t("Policy Documents")}
              subtitle={t("Lorem ipsum dolor sit amet")}
              py={8}
            >
              <VStack width="full" align="left">
                <FormInputHook label="File name" name="name" />
                <FormInputHook label="File" name="file" type="file" />
              </VStack>
            </FieldGroup>
          </VStack>

          <FormFooter data={[]} errors={[]} title="Policy document" loading={false} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default CertificateTemplatePage;
