import "./index.css";
import "./i18n/i18n";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Fallback from "components/ui/Fallback";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "middleware/Toaster";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_SENTRY_ENABLED && process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  const wrappedForProduction = (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Fallback error={error} componentStack={componentStack} resetError={resetError} />
      )}
      showDialog
    >
      <Suspense>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </Suspense>
    </Sentry.ErrorBoundary>
  );

  root.render(wrappedForProduction);
}

const unwrappedForDevelopment = (
  <Suspense>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </Suspense>
);

root.render(unwrappedForDevelopment);
