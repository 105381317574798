/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Dot, Field, RegistryAwareField } from "components/ui";
import { Trans, useTranslation } from "react-i18next";

import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const GlobalInfoView = ({ data }: AnyObject) => {
  const {
    category,
    series,
    seriesDetails,
    material,
    catalogNumber,
    inventoryNumber,
    archiveNumber,
    criticalCatalogue,
    nft,
    nftNotes,
    creationDateNotes,
    printerId,
    printerName,
    videomakerId,
    videomakerName,

    manufacturerId,

    manufacturerName,
    producerId,
    producerName,
    paperType,
    productionMode,
    placement,
    banknotePrinter,
    coinMintIssuer,
    coinPlacement,
    coinPeriod,
    coinPlacementNotes,
    coinIssueReason,
    coinCirculationPeriod,
    coinComposition,
    coinIdentificationCode,
    coverType,
    printingType,
    otherModel,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Global info")} subtitle="Lorem ipsum">
      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && (
          <Field label="Minter and issuing authority" value={coinMintIssuer} />
        )}
      {(category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
        <Field label="Printer" value={banknotePrinter} />
      )}
      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && (
          <>
            <HStack spacing="16" w="full">
              <Field label="Placement" value={coinPlacement} />
              {category !== "NUMISMATIC_COINS" && (
                <Field label="Period" value={coinPeriod} />
              )}
            </HStack>
            {/* {coinPlacementNotes && ( */}
              <Field label="Placement and period notes" value={coinPlacementNotes} />
               {/* <VStack>
                 <Text variant="muted">
                   <Trans>Placement and period notes</Trans>
                 </Text>
                 <Text>{coinPlacementNotes}</Text>
               </VStack> */}
            {/* )} */}
            {/* {coinIssueReason && ( */}
              <Field label="Issuing reason" value={coinIssueReason} />
              {/* <VStack>
                <Text variant="muted">
                  <Trans>Issuing reason</Trans>
                </Text>
                <Text>{coinIssueReason}</Text>
              </VStack>
            )} */}
          </>
        )}

      {/* coinCirculationPeriod && */category === "NUMISMATIC_COINS" && (
        <Field label="Circulation period" value={coinCirculationPeriod} />
        // <VStack>
        //   <Text variant="muted">
        //     <Trans>Circulation period</Trans>
        //   </Text>
        //   <Text>{coinCirculationPeriod}</Text>
        // </VStack>
      )}

      {category === "URBAN" && <Field label="Placement" value={placement} />}
      <Field label="Creation date notes" value={creationDateNotes} />
      {/* printerId && */category === "PRINT_OR_PHOTO" && (
        <VStack alignItems="flex-start" pr={8}>
          <Text fontStyle="normal" variant="muted">
            <Trans>Printer</Trans>
          </Text>
          { printerId && <RegistryAwareField id={printerId} /> }
          { !printerId && <Text fontStyle="italic" fontWeight="400" color="gray.300">
            <Trans>Value not found</Trans>
          </Text> }
        </VStack>
      )}
      {/* {
      artisanId && category === 'DESIGN_OR_ANTIQUE' &&
      <VStack alignItems="flex-start">
        <Text variant="muted">
          <Trans>Artisan/designer</Trans>
        </Text>
        <RegistryAwareField id={artisanId} />
      </VStack>

    } */}
      {/* manufacturerId && */ category === "DESIGN_OR_ANTIQUE" && (
        <VStack alignItems="flex-start" pr={8}>
          <Text fontStyle="normal" variant="muted">
            <Trans>Manufacturer/producer</Trans>
          </Text>
          { manufacturerId && <RegistryAwareField id={manufacturerId} /> }
          { !manufacturerId && <Text fontStyle="italic" fontWeight="400" color="gray.300">
            <Trans>Value not found</Trans>
          </Text> }
        </VStack>
      )}
      {/* producerId && */category === "OTHER" && (
        <VStack alignItems="flex-start" pr={8}>
          <Text fontStyle="normal" variant="muted">
            <Trans>Producer</Trans>
          </Text>
          {producerId && <RegistryAwareField id={producerId} />}
          { !producerId && <Text fontStyle="italic" fontWeight="400" color="gray.300">
            <Trans>Value not found</Trans>
          </Text> }
        </VStack>
      )}
      {/* videomakerId && */category === "DIGITAL" && (
        <VStack alignItems="flex-start" pr={8}>
          <Text fontStyle="normal" variant="muted">
            <Trans>Videomaker</Trans>
          </Text>
          { videomakerId && <RegistryAwareField id={videomakerId} /> }
          { !videomakerId && <Text fontStyle="italic" fontWeight="400" color="gray.300">
            <Trans>Value not found</Trans>
          </Text> }
        </VStack>
      )}

      <HStack spacing="16" w="full">
        <Field label="Series" value={series} />
        <Field label="Series details" value={seriesDetails} />
      </HStack>


      {/* {
      otherModel && category === 'OTHER'  &&
        <VStack>
          <Text variant="muted">
            <Trans>Model</Trans>
          </Text>
          <Text>
            {otherModel}
          </Text>
        </VStack>
    }

    {
      material && category === 'OTHER'  &&
        <VStack>
          <Text variant="muted">
            <Trans>Material</Trans>
          </Text>
          <Text>
            {material}
          </Text>
        </VStack>
    } */}

      {/* {
      paperType && category === 'BOOKS'  &&
        <VStack>
          <Text variant="muted">
            <Trans>Paper type</Trans>
          </Text>
          <Text>
            {paperType}
          </Text>
        </VStack>
    }

    {
      coverType && category === 'BOOKS' &&
        <VStack>
          <Text variant="muted">
            <Trans>Cover type</Trans>
          </Text>
          <Text>
            {coverType}
          </Text>
        </VStack>
    }

    {
      printingType && category === 'BOOKS' &&
        <VStack>
          <Text variant="muted">
            <Trans>Printing type</Trans>
          </Text>
          <Text>
            {printingType}
          </Text>
        </VStack>
    } */}

      {/* {
      paperType && ((category === 'NUMISMATIC_BANKNOTES') || (category === 'PHILATELY')) &&
        <VStack>
          <Text variant="muted">
            <Trans>Paper type</Trans>
          </Text>
          <Text>
            {paperType}
          </Text>
        </VStack>
    } */}

      {category === "NATURALISTIC_GOODS" && <Field label="Material" value={material} />}

      <HStack spacing="16" w="full">
        <Field label="Inventory number" value={inventoryNumber} />
        <Field label="Archive number" value={archiveNumber} />
        <Field label="Catalog number" value={catalogNumber} />
        <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Critical catalog</Trans>
          </Text>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <Dot status={criticalCatalogue} />
        </VStack>
      </HStack>

      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && (
          <VStack>
            <Text variant="muted">
              <Trans>Identification code</Trans>
            </Text>
            <Text>{coinIdentificationCode}</Text>
          </VStack>
        )}

      {nft && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Nft</Trans>
            </Text>
            <Dot status={nft} />
          </VStack>
          <Field label="Nft notes" value={nftNotes} />
        </HStack>
      )}
    </BorderedBox>
  );
};

export default GlobalInfoView;
