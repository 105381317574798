import { DropdownFilter, CreatedByFilter, BooleanFilter } from "components/ui";
import { Badge } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { BooleanCell } from "helpers/getColumns";
import { PolicyNode } from "graphql/queries/generated/queries";
import parseAmount from "helpers/parseAmount";
import parseDate from "helpers/parseDate";

export default [
  {
    Header: "Status",
    accessor: "policyStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>{value === "APPROVED" ? <Trans>P</Trans> : <Trans>O</Trans>}</Badge>
    ),
    weight: 10,
  },
  {
    Header: "Policy #",
    Footer: "Policy #",
    accessor: "policyCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Offer #",
    Footer: "Offer #",
    accessor: "offerCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  {
    Header: "Ins. Company",
    Footer: "IC",
    disableFilters: true,
    accessor: "insuranceCompany.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Broker",
    Footer: "Broker",
    disableFilters: true,
    accessor: "broker.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Contr. Party",
    Footer: "CP",
    disableFilters: true,
    accessor: "contractingParty.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Issued at",
    Footer: "Issued at",
    disableFilters: true,
    accessor: "issuedAt",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => parseDate(value),
  },
  {
    Header: "Expires at",
    Footer: "Expires at",
    disableFilters: true,
    accessor: "expiresAt",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => parseDate(value),
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "totalInsuredValue.amount",
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyNode } };
    }) => {
      const { totalInsuredValue } = original;
      return parseAmount(totalInsuredValue);
    },
  },
  {
    Header: "N. assets",
    Footer: "N. assets",
    disableFilters: true,
    accessor: "assetsCount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge colorScheme="blackAlpha">{value}</Badge>
    ),
  },
  {
    Header: "Output",
    Footer: "Output",
    accessor: "hasValidCertificate",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
  {
    Header: "Created by",
    Footer: "Created by",
    disableFilters: false,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
  },
  {
    Header: "",
    Footer: "",
    disableFilters: false,
    Filter: BooleanFilter,
    accessor: "isExpired",
    Cell: () => <div />,
  },
];
