/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { HStack, Center, Grid } from "@chakra-ui/react";
import {
  useInstantSearch,
  useHits,
  usePagination,
  Pagination,
} from "react-instantsearch-hooks-web";
import { Trans } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import CustomHit from "./CustomHit";
import { AlgoliaSearchBoxProps, EmptyQueryBoundaryProps, SelectedAsset } from "./types";

function EmptyQueryBoundary({ children, fallback }: EmptyQueryBoundaryProps) {
  const { indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return fallback;
  }

  return children;
}

const CustomResults = ({
  addAsset,
  choosenAssets,
  selectedAssets,
  setSelectedAssets,
  layout,
  singleSelectionMode
}: {
  addAsset: AlgoliaSearchBoxProps["addAsset"];
  choosenAssets?: string[];
  selectedAssets: SelectedAsset[];
  setSelectedAssets: Dispatch<SetStateAction<SelectedAsset[]>>;
  layout: "table" | "grid";
  singleSelectionMode: boolean
}) => {
  const { results } = useInstantSearch();
  const { hits } = useHits();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    pages,
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    canRefine,
    refine,
    createURL,
  } = usePagination();

  const templateColumns = layout === "table" ? "repeat(1, 1fr)" : "repeat(4, 1fr)";
  return results && results.nbHits ? (
    <>
      <Grid templateColumns={templateColumns} gap={6} m={8} w="100%">
        {hits.map((hit) => (
          <CustomHit
            key={hit.gqlId as string}
            hit={hit}
            setSelectedAssets={setSelectedAssets}
            selectedAssets={selectedAssets}
            choosenAssets={choosenAssets}
            layout={layout}
            singleSelectionMode={singleSelectionMode}
          />
        ))}
      </Grid>
      <HStack justify="center" w="100%">
        <Pagination showLast />
      </HStack>
    </>
  ) : (
    <Center pt="5rem">
      <Trans>We didn&apos;t find any results for your query</Trans>
    </Center>
  );
};

export default CustomResults;
