import { Badge } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export const OFFICE_CUSTOM_FIELDS: string[] = [
  "name",
  "address",
  "city",
  "zip",
  "state",
  "country",
  "phone",
  "email",
  "officeAddress",
];

export const OFFICE_HIDDEN_FIELDS: string[] = ["main", "active", "city", "state", "country", "zip"];

export const OFFICE_CUSTOM_COLUMNS = [
  {
    Header: "Name",
    footer: "",
    accessor: "name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Address",
    footer: "",
    accessor: "officeAddress.raw",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "City",
    footer: "",
    accessor: "city",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Zip",
    footer: "",
    accessor: "zip",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "PROVINCE/REGION/STATE",
    footer: "",
    accessor: "state",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Country",
    footer: "",
    accessor: "country",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Phone",
    footer: "",
    accessor: "phone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Email",
    footer: "",
    accessor: "email",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];

export const REGISTRY_CUSTOM_FIELDS: string[] = [
  "fullName",
  "main",
  "alias",
  "role",
  "phone",
  "mobilePhone",
  "email",
  "officeAddress",
];

export const REGISTRY_HIDDEN_FIELDS: string[] = [
  "note",
  "printCode",
  "printName",
  "webSite",
  "countryCode",
  "taxId",
  "invoiceAddress",
  "relatedCompanyId",
  "companyContactId",
];

export const REGISTRY_CUSTOM_COLUMNS = [
  {
    Header: "Full name",
    footer: "",
    accessor: "fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Main",
    footer: "",
    accessor: "main",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <div>
        {value ? (
          <Badge>
            <Trans>Main</Trans>
          </Badge>
        ) : (
          <span />
        )}
      </div>
    ),
    disableFilters: true,
  },
  {
    Header: "Alias",
    footer: "",
    accessor: "alias",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Role",
    footer: "",
    accessor: "role",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Phone",
    footer: "",
    accessor: "phone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Mobile Phone",
    footer: "",
    accessor: "mobilePhone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Email",
    footer: "",
    accessor: "email",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
];
