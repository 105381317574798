/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { /* Trans, */ Trans, useTranslation } from "react-i18next";
import {
  Alert,
  AlertIcon,
  Text,
  Button,
  Box,
  IconButton,
  Stack,
  Heading,
  StackDivider,
  Table,
  Tbody,
  Td,
  Tr,
  useDisclosure,
  useToast,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  VStack,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AssetDocumentInputSchema,
  AssetDocumentAttachmentInputSchema,
} from "graphql/queries/generated/validation-schema";
// import { FormInputHook, MoneyInputHook } from "components/ui";
import { useState, useEffect, SetStateAction } from "react";
// import  cleanObject from 'helpers/cleanObject';
import {
  AssetDocumentInput,
  useCreateAssetDocumentAttachmentMutation,
  useCreateAssetDocumentMutation,
  useUpdateAssetDocumentMutation,
  useDeleteAssetDocumentAttachmentMutation,
  useGetAssetDocumentsByAssetIdLazyQuery,
  useDeleteAssetDocumentMutation,
  useGetAssetDocumentAttachmentsByDocumentIdLazyQuery,
  AssetDocumentAttachmentInput,
} from "graphql/queries/generated/queries";

import { RiDeleteBinLine, RiFileDownloadLine } from "react-icons/ri";
import ASSET_CONSTANTS from "constants/asset";
import { ValidationProvider } from "components/form/ValidationContext";
import { FormInputHook } from "components/ui";
// import clearAssetMediaValues from "pages/assets/utils/ClearAssetMediaValues";
import clearAssetDocumentValues from "pages/assets/utils/ClearAssetDocumentValues";
import OnViewAssetDocumentsTable from "pages/assets/shared/OnViewAssetDocumentsTable";
import { handleMutation } from "middleware/Toaster";

interface AssetDocumentFormProps {
  assetId: string;
  // children: any;
}

const AssetDocumentForm = ({ assetId }: AssetDocumentFormProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const methods = useForm<AssetDocumentInput>({
    defaultValues: {
      title: "",
      description: "",
      documentType: "",
      coaType: "",
      issuer: "",
      recipient: "",
      isClassified: Boolean(false),
      releaseDate: "",
      expirationDate: "",
      notes: "",
      archiveNumber: "",
      fineArtsIssueType: "",
      isTemporaryExport: Boolean(false),
      isTemporaryImport: Boolean(false),
      isCriticalCatalog: Boolean(false),
      editor: "",
      curator: "",
      subscriptionDate: "",
      contractSubscriber: "",
      publishingDate: "",
      otherDocumentType: "",
      assetId: "",
    },
    resolver: yupResolver(AssetDocumentInputSchema()),
    mode: "onChange",
  });

  const { setValue, watch } = methods;

  const [selectedId, setSelectedId] = useState(null);

  const toast = useToast();

  const { t } = useTranslation();

  const methodsAttachment = useForm<AssetDocumentAttachmentInput>({
    defaultValues: {
      name: "",
      file: "",
      assetDocumentId: selectedId,
    },
    resolver: yupResolver(AssetDocumentAttachmentInputSchema()),
    mode: "onChange",
  });

  const [loadAssetDocuments, refetchDocuments] = useGetAssetDocumentsByAssetIdLazyQuery();

  const [loadDocumentAttachments, refetchAttachments] =
    useGetAssetDocumentAttachmentsByDocumentIdLazyQuery();

  const [
    deleteAssetDocument,
    {
      data: deleteAssetDocumentData,
      error: deleteAssetDocumentError,
      loading: deleteAssetDocumentLoading,
    },
  ] = useDeleteAssetDocumentMutation({
    ...handleMutation("Asset document deleted!"),
  });

  const [
    deleteAssetDocumentAttachment,
    {
      data: deleteAssetDocumentAttachmentData,
      error: deleteAssetDocumentAttachmentError,
      loading: deleteAssetDocumentAttachmentLoading,
    },
  ] = useDeleteAssetDocumentAttachmentMutation({
    ...handleMutation("Asset document attachment deleted!"),
  });

  const [assetDocuments, setAssetDocuments] = useState<
    Array<{
      id: string;
      title: string;
      description: string;
      documentType: string;
      coaType: string;
      issuer: string;
      recipient: string;
      isClassified: boolean;
      releaseDate: string;
      expirationDate: string;
      notes: string;
      archiveNumber: string;
      fineArtsIssueType: string;
      isTemporaryExport: boolean;
      isTemporaryImport: boolean;
      isCriticalCatalog: boolean;
      editor: string;
      curator: string;
      subscriptionDate: string;
      contractSubscriber: string;
      publishingDate: string;
      otherDocumentType: string;
      assetId: string;
    }>
  >([]);

  const [documentAttachments, setDocumentAttachments] = useState<
    Array<{
      id: string;
      name: string;
      file: string;
      documentId: string;
    }>
  >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedDocumentAttachments = async (documentId: string) => {
    const {
      data: fetchedDocumentAttachments,
      loading: assetDocumentAttachmentsLoading,
      error: assetDocumentAttachmentsError,
    } = await loadDocumentAttachments({
      variables: {
        documentId,
      },
    });
    if (!fetchedDocumentAttachments || !fetchedDocumentAttachments.allAssetDocumentAttachments)
      return null;
    const {
      allAssetDocumentAttachments: { edges: edgesAttachments },
    } = fetchedDocumentAttachments;

    const nodeAttachments: SetStateAction<
      { id: string; name: string; file: string; documentId: string }[]
    > = [];
    edgesAttachments.forEach((element) => {
      if (element.node) {
        nodeAttachments.push({
          id: element.node.id,
          name: element.node.name,
          file: element.node.file,
          documentId,
        });
      }
    });
    setDocumentAttachments(nodeAttachments);
    return documentAttachments;
  };

  // eslint-disable-next-line consistent-return
  const loadParsedAssetDocuments = async () => {
    const {
      data: fetchedAssetDocuments,
      loading: assetDocumentsLoading,
      error: assetDocumentsError,
    } = await loadAssetDocuments({
      variables: {
        assetId,
      },
    });

    if (!fetchedAssetDocuments || !fetchedAssetDocuments.allAssetDocuments) return null;

    const {
      allAssetDocuments: { edges: edgesDocuments },
    } = fetchedAssetDocuments;

    const nodesDocuments:
      | ((
          prevState: {
            id: string;
            title: string;
            description: string;
            documentType: string;
            coaType: string;
            issuer: string;
            recipient: string;
            isClassified: boolean;
            releaseDate: string;
            expirationDate: string;
            notes: string /* eslint-disable react/prop-types */;
            archiveNumber: string;
            fineArtsIssueType: string;
            isTemporaryExport: boolean;
            isTemporaryImport: /* eslint-disable react/jsx-key */ boolean;
            isCriticalCatalog: boolean;
            editor: string;
            curator: string;
            subscriptionDate: string;
            contractSubscriber: string;
            publishingDate: string;
            otherDocumentType: string;
            assetId: string;
          }[]
        ) => {
          id: string;
          title: string;
          description: string;
          documentType: string;
          coaType: string;
          issuer: string;
          recipient: string;
          isClassified: boolean;
          releaseDate: string;
          expirationDate: string;
          notes: string;
          archiveNumber: string;
          fineArtsIssueType: string;
          isTemporaryExport: boolean;
          isTemporaryImport: boolean;
          isCriticalCatalog: boolean;
          editor: string;
          curator: string;
          subscriptionDate: string;
          contractSubscriber: string;
          publishingDate: string;
          otherDocumentType: string;
        }[])
      | {
          id: string;
          title: string;
          description: string;
          documentType: string;
          coaType: string;
          issuer: string;
          recipient: string;
          isClassified: boolean;
          releaseDate: any;
          expirationDate: any;
          notes: string;
          archiveNumber: string;
          fineArtsIssueType: string;
          isTemporaryExport: boolean;
          isTemporaryImport: boolean;
          isCriticalCatalog: boolean;
          editor: string;
          curator: string;
          subscriptionDate: any;
          contractSubscriber: string;
          publishingDate: any;
          otherDocumentType: string;
          assetId: string;
        }[] = [];

    edgesDocuments.forEach((element) => {
      if (element.node) {
        nodesDocuments.push({
          id: element.node.id,
          title: element.node.title,
          description: element.node.description,
          documentType: element.node.documentType,
          coaType: element.node.coaType,
          issuer: element.node.issuer,
          recipient: element.node.recipient,
          isClassified: element.node.isClassified,
          releaseDate: element.node.releaseDate,
          expirationDate: element.node.expirationDate,
          notes: element.node.notes,
          archiveNumber: element.node.archiveNumber,
          fineArtsIssueType: element.node.fineArtsIssueType,
          isTemporaryExport: element.node.isTemporaryExport,
          isTemporaryImport: element.node.isTemporaryImport,
          isCriticalCatalog: element.node.isCriticalCatalog,
          editor: element.node.editor,
          curator: element.node.curator,
          subscriptionDate: element.node.subscriptionDate,
          contractSubscriber: element.node.contractSubscriber,
          publishingDate: element.node.publishingDate,
          otherDocumentType: element.node.otherDocumentType,
          assetId,
        });
      }
    });

    setAssetDocuments(nodesDocuments);

    return assetDocuments;
  };

  const onEditModalOpen = async (
    id: string,
    title: string,
    description: string,
    documentType: string,
    coaType: string,
    issuer: string,
    recipient: string,
    isClassified: boolean,
    releaseDate: string | null,
    expirationDate: string | null,
    notes: string,
    archiveNumber: string,
    fineArtsIssueType: string,
    isTemporaryExport: boolean,
    isTemporaryImport: boolean,
    isCriticalCatalog: boolean,
    editor: string,
    curator: string,
    subscriptionDate: string | null,
    contractSubscriber: string,
    publishingDate: string | null,
    otherDocumentType: string
  ) => {
    await loadParsedDocumentAttachments(id);
    setSelectedId(id);
    setValue("title", title);
    setValue("description", description);
    setValue("documentType", documentType);
    setValue("coaType", coaType);
    setValue("issuer", issuer);
    setValue("recipient", recipient);
    setValue("isClassified", isClassified);
    setValue("releaseDate", releaseDate);
    setValue("expirationDate", expirationDate);
    setValue("notes", notes);
    setValue("archiveNumber", archiveNumber);
    setValue("fineArtsIssueType", fineArtsIssueType);
    setValue("isTemporaryExport", isTemporaryExport);
    setValue("isTemporaryImport", isTemporaryImport);
    setValue("isCriticalCatalog", isCriticalCatalog);
    setValue("editor", editor);
    setValue("curator", curator);
    setValue("subscriptionDate", subscriptionDate);
    setValue("contractSubscriber", contractSubscriber);
    setValue("publishingDate", publishingDate);
    setValue("otherDocumentType", otherDocumentType);
    methodsAttachment.setValue("assetDocumentId", id);

    onOpen();
  };

  const onDeleteDocument = async (id: string) => {
    await deleteAssetDocument({
      variables: {
        input: {
          id,
        },
      },
    });
    const assetDocuments = await loadParsedAssetDocuments();
    return assetDocuments;
  };

  const onDeleteAttachment = async (id: string) => {
    await deleteAssetDocumentAttachment({
      variables: {
        input: {
          id,
        },
      },
    });
    const documentAttachments = await loadParsedDocumentAttachments(selectedId);
    return documentAttachments;
  };

  const [
    createAssetDocument,
    {
      data: createAssetDocumentData,
      loading: createAssetDocumentLoading,
      error: createAssetDocumentError,
    },
  ] = useCreateAssetDocumentMutation({
    ...handleMutation("Asset document created!"),
  });

  const [
    updateAssetDocument,
    {
      data: updateAssetDocumentData,
      loading: updateAssetDocumentLoading,
      error: updateAssetDocumentError,
    },
  ] = useUpdateAssetDocumentMutation({
    ...handleMutation("Asset document updated!"),
  });

  const [
    createAssetDocumentAttachment,
    {
      data: createAssetDocumentAttachmentData,
      loading: createAssetDocumentAttachmentLoading,
      error: createAssetDocumentAttachmentError,
    },
  ] = useCreateAssetDocumentAttachmentMutation({
    ...handleMutation("Attachment created!"),
  });

  const findLabelByValue = (value: string, array: { value: string; label: string }[]) => {
    const obj = array.find((x) => x.value === value);
    return obj ? obj.label : "";
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadParsedAssetDocuments();
    return undefined;
  }, []);

  const onCloseModal = () => {
    setSelectedId(null);
    setDocumentAttachments([]);
    setValue("title", "");
    setValue("description", "");
    setValue("documentType", "");
    setValue("coaType", "");
    setValue("issuer", "");
    setValue("recipient", "");
    setValue("isClassified", Boolean(false));
    setValue("releaseDate", null);
    setValue("expirationDate", null);
    setValue("notes", "");
    setValue("archiveNumber", "");
    setValue("fineArtsIssueType", "");
    setValue("isTemporaryExport", Boolean(false));
    setValue("isTemporaryImport", Boolean(false));
    setValue("isCriticalCatalog", Boolean(false));
    setValue("editor", "");
    setValue("curator", "");
    setValue("subscriptionDate", null);
    setValue("contractSubscriber", "");
    setValue("publishingDate", null);
    setValue("otherDocumentType", "");
    methodsAttachment.setValue("assetDocumentId", null);
    onClose();
  };

  const onSubmitAttachment: SubmitHandler<AssetDocumentAttachmentInput> = async (
    values: any,
    e: any
  ) => {
    const assetDocumentAttchmentData = { ...values };
    let documentAttachments;
    const response = await createAssetDocumentAttachment({
      variables: {
        input: {
          assetDocumentAttachmentData: {
            name: assetDocumentAttchmentData.name,
            file: assetDocumentAttchmentData.file,
            assetDocumentId: assetDocumentAttchmentData.assetDocumentId,
          },
        },
      },
    });
    if (
      response.data &&
      response.data.createAssetDocumentAttachment &&
      response.data.createAssetDocumentAttachment.assetDocumentAttachment
    ) {
      documentAttachments = await loadParsedDocumentAttachments(selectedId);
      methodsAttachment.setValue("name", "");
      methodsAttachment.setValue("file", "");
    }
    return documentAttachments;
  };

  const onErrorAttachment = (errors: any, e: any) => console.log(errors, e);

  const handleSubmitDocumentAttachment = methodsAttachment.handleSubmit(
    onSubmitAttachment,
    onErrorAttachment
  );

  const onSubmitDocument: SubmitHandler<AssetDocumentInput> = async (values: any, e: any) => {
    const assetDocumentData = clearAssetDocumentValues(values);
    let assetDocuments;
    let response;

    if (selectedId) {
      // update
      response = await updateAssetDocument({
        variables: {
          input: {
            assetDocumentData: {
              title: assetDocumentData.title,
              description: assetDocumentData.description,
              documentType: assetDocumentData.documentType,
              coaType: assetDocumentData.coaType,
              issuer: assetDocumentData.issuer,
              recipient: assetDocumentData.recipient,
              isClassified: assetDocumentData.isClassified,
              releaseDate:
                assetDocumentData.releaseDate === "" ? null : assetDocumentData.releaseDate,
              expirationDate:
                assetDocumentData.expirationDate === "" ? null : assetDocumentData.expirationDate,
              notes: assetDocumentData.notes,
              archiveNumber: assetDocumentData.archiveNumber,
              fineArtsIssueType: assetDocumentData.fineArtsIssueType,
              isTemporaryExport: assetDocumentData.isTemporaryExport,
              isTemporaryImport: assetDocumentData.isTemporaryImport,
              isCriticalCatalog: assetDocumentData.isCriticalCatalog,
              editor: assetDocumentData.editor,
              curator: assetDocumentData.curator,
              subscriptionDate:
                assetDocumentData.subscriptionDate === ""
                  ? null
                  : assetDocumentData.subscriptionDate,
              contractSubscriber: assetDocumentData.contractSubscriber,
              publishingDate:
                assetDocumentData.publishingDate === "" ? null : assetDocumentData.publishingDate,
              otherDocumentType: assetDocumentData.otherDocumentType,
            },
            id: selectedId,
          },
        },
      });
      if (
        response.data &&
        response.data.updateAssetDocument &&
        response.data.updateAssetDocument.assetDocument &&
        response.data.updateAssetDocument.assetDocument.id
      ) {
        assetDocuments = await loadParsedAssetDocuments();
        onCloseModal();
      }
    } else {
      response = await createAssetDocument({
        variables: {
          input: {
            assetDocumentData: {
              title: assetDocumentData.title,
              description: assetDocumentData.description,
              documentType: assetDocumentData.documentType,
              coaType: assetDocumentData.coaType,
              issuer: assetDocumentData.issuer,
              recipient: assetDocumentData.recipient,
              isClassified: assetDocumentData.isClassified,
              releaseDate:
                assetDocumentData.releaseDate === "" ? null : assetDocumentData.releaseDate,
              expirationDate:
                assetDocumentData.expirationDate === "" ? null : assetDocumentData.expirationDate,
              notes: assetDocumentData.notes,
              archiveNumber: assetDocumentData.archiveNumber,
              fineArtsIssueType: assetDocumentData.fineArtsIssueType,
              isTemporaryExport: assetDocumentData.isTemporaryExport,
              isTemporaryImport: assetDocumentData.isTemporaryImport,
              isCriticalCatalog: assetDocumentData.isCriticalCatalog,
              editor: assetDocumentData.editor,
              curator: assetDocumentData.curator,
              subscriptionDate:
                assetDocumentData.subscriptionDate === ""
                  ? null
                  : assetDocumentData.subscriptionDate,
              contractSubscriber: assetDocumentData.contractSubscriber,
              publishingDate:
                assetDocumentData.publishingDate === "" ? null : assetDocumentData.publishingDate,
              otherDocumentType: assetDocumentData.otherDocumentType,
              assetId,
            },
          },
        },
      });
      if (
        response.data &&
        response.data.createAssetDocument &&
        response.data.createAssetDocument.assetDocument
      ) {
        assetDocuments = await loadParsedAssetDocuments();
        setSelectedId(response.data.createAssetDocument.assetDocument.id);
        methodsAttachment.setValue(
          "assetDocumentId",
          response.data.createAssetDocument.assetDocument.id
        );
        // onCloseModal();
      }
    }
    return assetDocuments;
  };

  const onErrorDocument = (errors: any, e: any) => console.log(errors, e);
  const handleSubmitAssetDocument = methods.handleSubmit(onSubmitDocument, onErrorDocument);

  const documentType = watch("documentType");

  return (
    <Box p={6}>
      <Stack spacing="4" divider={<StackDivider />}>
        <OnViewAssetDocumentsTable
          data={assetDocuments}
          mode="edit"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          editAction={({ row }) =>
            onEditModalOpen(
              row?.original?.id,
              row?.original?.title,
              row?.original?.description,
              row?.original?.documentType,
              row?.original?.coaType,
              row?.original?.issuer,
              row?.original?.recipient,
              row?.original?.isClassified,
              row?.original?.releaseDate ? row?.original?.releaseDate.toString() : null,
              row?.original?.expirationDate ? row?.original?.expirationDate.toString() : null,
              row?.original?.notes,
              row?.original?.archiveNumber,
              row?.original?.fineArtsIssueType,
              row?.original?.isTemporaryExport,
              row?.original?.isTemporaryImport,
              row?.original?.isCriticalCatalog,
              row?.original?.editor,
              row?.original?.curator,
              row?.original?.subscriptionDate ? row?.original?.subscriptionDate.toString() : null,
              row?.original?.contractSubscriber,
              row?.original?.publishingDate ? row?.original?.publishingDate.toString() : null,
              row?.original?.otherDocumentType
            )
          }
          // viewAction={(row: any) => openElementModal(row?.values?.elementId)}
          viewAction={null}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onDelete={(id: string) => onDeleteDocument(id)}
        />
        <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
          <Button onClick={onOpen} variant="primary">
            <Trans>Add new object document</Trans>
          </Button>
        </ButtonGroup>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedId ? (
                <Trans>Update asset document</Trans>
              ) : (
                <Trans>Add new asset document</Trans>
              )}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormProvider {...methods}>
                <ValidationProvider schema={AssetDocumentInputSchema()}>
                  <form id="addAssetDocument" onSubmit={handleSubmitAssetDocument}>
                    <Box maxW="6xl">
                      <Stack spacing="4" divider={<StackDivider />}>
                        <FormInputHook
                          label="Document type"
                          options={ASSET_CONSTANTS.ASSET_DOCUMENT_TYPES}
                          name="documentType"
                        />

                        {documentType.toString() === "AUTHENTICITY_CERTIFICATE" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="COA type"
                                options={ASSET_CONSTANTS.ASSET_DOCUMENT_COA_TYPES}
                                name="coaType"
                              />
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Who issued certificate" name="issuer" />
                              <FormInputHook
                                label="To whom certificate was issued"
                                name="recipient"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Description" name="description" />
                              <FormInputHook label="Date" name="releaseDate" type="date" />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "ARCHIVING_CERTIFICATE" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Who issued certificate" name="issuer" />
                              <FormInputHook
                                label="To whom certificate was issued"
                                name="recipient"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Archive number" name="archiveNumber" />
                              <FormInputHook label="Date" name="releaseDate" type="date" />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "FINE_ARTS_ISSUE" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Issue type"
                                options={ASSET_CONSTANTS.FINE_ARTS_ISSUE_TYPES}
                                name="fineArtsIssueType"
                              />

                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Release date" name="releaseDate" type="date" />
                              <FormInputHook
                                label="Expiration date"
                                name="expirationDate"
                                type="date"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Who issued certificate" name="issuer" />
                              <FormInputHook
                                label="To whom certificate was issued"
                                name="recipient"
                              />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "CUSTOM_DOCUMENT" && (
                          <>
                            <HStack alignItems="flex-end">
                              <VStack>
                                <FormInputHook
                                  label="Temporary export"
                                  name="isTemporaryExport"
                                  type="checkbox"
                                />

                                <FormInputHook
                                  label="Temporary import"
                                  name="isTemporaryimport"
                                  type="checkbox"
                                />
                              </VStack>

                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Release date" name="releaseDate" type="date" />
                              <FormInputHook
                                label="Expiration date"
                                name="expirationDate"
                                type="date"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Issuing authority" name="issuer" />
                              <FormInputHook
                                label="To whom certificate was issued"
                                name="recipient"
                              />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "CATALOG" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Is critical catalog?"
                                name="isCriticalCatalog"
                                type="checkbox"
                              />
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Title" name="title" />
                              <FormInputHook label="Description" name="description" />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Editor" name="editor" />
                              <FormInputHook label="Curator" name="curator" />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Date" name="releaseDate" type="date" />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "CONTRACT" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Description" name="description" />
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Subscription date"
                                name="subscriptionDate"
                                type="date"
                              />
                              <FormInputHook
                                label="Expiration date"
                                name="expirationDate"
                                type="date"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook
                                label="Contract Subscriber"
                                name="contractSubscriber"
                              />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "PUBLICATION" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Title" name="title" />
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Description" name="description" />
                              <FormInputHook
                                label="Publishing date"
                                name="publishingDate"
                                type="date"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Editor" name="editor" />
                              <FormInputHook label="Curator" name="curator" />
                            </HStack>
                          </>
                        )}

                        {documentType.toString() === "OTHER" && (
                          <>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Document type" name="otherDocumentType" />
                              <FormInputHook
                                label="Is Classified?"
                                name="isClassified"
                                type="checkbox"
                              />
                            </HStack>
                            <HStack alignItems="flex-end">
                              <FormInputHook label="Description" name="description" />
                              <FormInputHook label="Date" name="releaseDate" type="date" />
                            </HStack>
                          </>
                        )}

                        {documentType && (
                          <HStack alignItems="flex-end">
                            <FormInputHook
                              minW="30rem"
                              label="notes"
                              name="notes"
                              type="textarea"
                            />
                          </HStack>
                        )}
                      </Stack>
                    </Box>
                  </form>
                </ValidationProvider>
              </FormProvider>
              {documentType && !selectedId && (
                <Alert status="warning">
                  <AlertIcon />
                  <VStack alignItems="flex-start">
                    <Text>
                      <Trans defaults="You'll be able to add files once you save the document." />
                    </Text>
                  </VStack>
                </Alert>
              )}
              {selectedId && (
                <>
                  <Box my="4">
                    <Heading size="md">
                      <Trans>Currently attached</Trans>
                    </Heading>
                    {documentAttachments.length === 0 && (
                      <Text>
                        <Trans>No files present</Trans>
                      </Text>
                    )}
                  </Box>

                  <Table>
                    {/* <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                        </Tr>
                                        <Th />
                                    </Thead> */}
                    <Tbody style={{ display: "block", maxHeight: "9rem", overflowY: "auto" }}>
                      {documentAttachments.map((attachment, i) => (
                        <Tr>
                          <Td>{attachment.name}</Td>
                          <Td>
                            {attachment.file && (
                              <IconButton
                                variant="outline"
                                onClick={() => window.open(attachment.file)}
                                icon={<RiFileDownloadLine />}
                                aria-label="Download file"
                              />
                            )}
                            {/* <Popover>
                                                        <PopoverTrigger> */}
                            <IconButton
                              icon={<RiDeleteBinLine color="red.500" />}
                              bg="white"
                              variant="outline"
                              size="sm"
                              aria-label="Delete media"
                              onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                onDeleteAttachment(attachment.id);
                              }}
                            />
                            {/* </PopoverTrigger>
                                                        <Portal>
                                                        <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                                                            <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                                <Trans>Are you sure you want to delete it?</Trans>
                                                            </PopoverHeader>
                                                            <PopoverArrow />
                                                            <PopoverCloseButton />
                                                            <PopoverBody><Trans>Warning message</Trans></PopoverBody>
                                                            <PopoverFooter
                                                                border="0"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                                pb={4}
                                                            >
                                                                <ButtonGroup size="sm">
                                                                    <Button
                                                                    variant="danger"
                                                                    isLoading={deleteAssetDocumentAttachmentLoading}
                                                                    onClick={() => {
                                                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                                                        onDeleteAttachment(attachment.id);
                                                                    }}
                                                                    >
                                                                    <Trans>Delete it</Trans>
                                                                    </Button>
                                                                    <Button><Trans>Cancel</Trans></Button>
                                                                </ButtonGroup>
                                                            </PopoverFooter>

                                                        </PopoverContent>
                                                        </Portal>
                                                    </Popover> */}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <FormProvider {...methodsAttachment}>
                    <Heading size="md">
                      <Trans>Attach a file</Trans>
                    </Heading>
                    <ValidationProvider schema={AssetDocumentAttachmentInputSchema()}>
                      <form
                        id="addAssetDocumentAttachment"
                        onSubmit={handleSubmitDocumentAttachment}
                      >
                        <Box maxW="6xl">
                          <Stack spacing="4" divider={<StackDivider />}>
                            <HStack alignItems="flex-start">
                              <FormInputHook label="Name" name="name" />
                              <FormInputHook label="File" name="file.0.file" type="file" />
                              {/* <FormInputHook name="assetDocumentId" hidden={true} value={selectedId} /> */}
                            </HStack>
                          </Stack>
                        </Box>
                      </form>
                    </ValidationProvider>
                  </FormProvider>
                  <ButtonGroup pb={6} mt={2} display="block" textAlign="right" variant="outline">
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Button
                      variant="primary"
                      onClick={handleSubmitDocumentAttachment}
                      mr={1}
                      // disabled={documentType.length === 0}
                      isLoading={createAssetDocumentAttachmentLoading}
                      loadingText="Loading"
                    >
                      <Trans>Add file</Trans>
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <Button
                  variant="primary"
                  onClick={handleSubmitAssetDocument}
                  mr={1}
                  disabled={documentType.length === 0}
                  isLoading={createAssetDocumentLoading || updateAssetDocumentLoading}
                  loadingText="Loading"
                >
                  <Trans>Save</Trans>
                </Button>
                <Button onClick={onCloseModal}>
                  <Trans>Cancel</Trans>
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </Box>
  );
  // return <div>Documents</div>;
};

export default AssetDocumentForm;
