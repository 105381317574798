import { Select as CustomSelect } from "chakra-react-select";
import { Box, FormLabel, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Wrapper } from "./Wrapper";
import { FormInputProps } from "./type";

const Select = ({
  name,
  label,
  type,
  errors,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  watch,
  control,
  required,
}: FormInputProps) => {
  const { t } = useTranslation();

  const translatedOptions = options.map(({ label, value }) => ({
    label: t(label),
    value,
  }));

  return (
    <Wrapper name={name} error={errors[name]}>
      <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
      <Box minW="12rem" background="white">
        <Controller
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          control={control}
          name={name}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          render={({ field: { onChange, value, name, ref } }) => (
            <CustomSelect
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isDisabled={disabled}
              size="sm"
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  zIndex: 999,
                  overflow: "visible",
                  backgroundColor: "white",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 999,
                  overflow: "unset",
                  overflowX: "visible",
                  backgroundColor: "red.500",
                  // zIndex: 100,
                  // height: 200,
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  zIndex: 999,
                  backgroundColor: "white",
                  // position: "absolute",
                }),
              }}
              isClearable
              // eslint-disable-next-line @typescript-eslint/no-shadow
              options={translatedOptions}
              useBasicStyles
              value={translatedOptions.find(({ value: optionValue }) => value === optionValue)}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={(newValue) => (newValue ? onChange(newValue.value) : onChange(""))}
            />
          )}
        />
      </Box>
    </Wrapper>
  );
};

export default Select;
