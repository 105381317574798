import { Heading, HStack, VStack, Skeleton, Text } from "@chakra-ui/react";
import {
  BorderedBox,
  CopyButton,
  Dot,
  QuickActions,
  Field,
  RegistryAwareField,
  DetailsPageSkeleton,
  Table,
  NoDataPlaceholder,
} from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import {
  useGetAppraisalByIdQuery,
  useGetMediaAppraisalByIdQuery,
  useExportAppraisalPdfMutation,
  MediaNode,
} from "graphql/queries/generated/queries";
import ASSET_CONSTANTS from "constants/asset";
import {
  DOCUMENT_CUSTOM_FIELDS,
  DOCUMENT_CUSTOM_COLUMNS,
  DOCUMENT_HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import { MGMT_ROUTES } from "routes/constants";
import parseDate from "helpers/parseDate";
import { appraisalMotivationOptions } from "../shared/AppraisalFields";

const AppraisalViewPage = () => {
  const { t } = useTranslation();
  const { appraisalId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetAppraisalByIdQuery({
    variables: { id: appraisalId ?? "" },
  });

  const [
    exportAppraisalPdf,
    {
      data: exportAppraisalPdfData,
      loading: exportAppraisalPdfLoading,
      error: exportAppraisalPdfError,
    },
  ] = useExportAppraisalPdfMutation({
    ...handleMutation("Appraisal exported!"),
  });

  const onGeneratePdf = async () => {
    const { data } = await exportAppraisalPdf({
      variables: {
        input: {
          appraisalId,
        },
      },
    });

    const {
      exportAppraisalPdf: { url },
    } = data;

    // window.open(url);
    await downloadFile(url);
  };

  const {
    data: dataMedia,
    loading: loadingMedia,
    error: errorMedia,
    refetch: refetchMedia,
  } = useGetMediaAppraisalByIdQuery({
    variables: {
      id: appraisalId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.appraisal) return null;

  const {
    appraisal: { ...formData },
  } = data;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Appraisal"),
      href: `${MGMT_ROUTES.APPRAISAL}/edit/${formData.id}`,
      icon: <BiEdit />,
    },
    {
      name: t("Export pdf"),
      action: onGeneratePdf,
      loading: exportAppraisalPdfLoading,
      icon: <BiExport />,
    },
  ];

  let appraisalMotivation = "";
  for (const option of appraisalMotivationOptions)
    if (option.value === formData.appraisalMotivation) appraisalMotivation = option.label;

  const valueCarryingValue = `${formData.valueCarryingValue.amount} ${formData.valueCarryingValue.currency.code}`;
  const valueEstimatedValue = `${formData.valueEstimatedValue.amount} ${formData.valueEstimatedValue.currency.code}`;

  let dataToGetColumns;
  let columns;
  const documents: MediaNode[] = [];

  if (
    dataMedia &&
    dataMedia.appraisal &&
    dataMedia.appraisal.media &&
    dataMedia.appraisal.media.edges &&
    dataMedia.appraisal.media.edges.length > 0
  ) {
    dataToGetColumns = dataMedia.appraisal.media.edges[0].node;

    columns = getColumns({
      data: dataToGetColumns,
      customFields: DOCUMENT_CUSTOM_FIELDS,
      customColumns: DOCUMENT_CUSTOM_COLUMNS,
    });

    for (let i = 0; i < dataMedia.appraisal.media.edges.length; i += 1)
      if (
        dataMedia.appraisal.media.edges[i].node.mediaType ===
        ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value
      )
        documents.push(dataMedia.appraisal.media.edges[i].node as MediaNode);
  }

  const onViewDocument = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id && row.original.file) window.open(row.original.file);
  };

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          {formData.appraisalAsset !== null &&
          formData.appraisalAsset.title !== null &&
          formData.appraisalAsset.title !== "" ? (
            <Heading>{formData.appraisalAsset.title}</Heading>
          ) : (
            <Heading />
          )}

          {formData.appraisalAsset.authorEntity !== null && (
            <RegistryAwareField id={formData.appraisalAsset.authorEntity.id} />
          )}

          {formData.appraisalAsset.actualDateAndPeriod !== null &&
            formData.appraisalAsset.actualDateAndPeriod !== "" && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Field
                label={t("Date of creation")}
                value={formData.appraisalAsset?.actualDateAndPeriod}
              />
            )}

          <Text fontSize="lg" variant="muted">
            #{appraisalId ?? ""} <CopyButton value={appraisalId ?? ""} />
          </Text>
          <Dot
            positiveLabel={t("Draft")}
            negativeLabel={t("Closed")}
            status={formData.appraisalStatus === "draft"}
          />
          <Text>{formData.appraisalDate ? parseDate(formData.appraisalDate) : ""}</Text>
        </VStack>

        {formData.appraisalStatus === "draft" && <QuickActions noTitle actions={QUICK_ACTIONS} />}
      </HStack>

      <VStack flex="3" direction="column" spacing="6">
        <BorderedBox title="Appraisal" subtitle="Lorem ipsum dolor sit amet">
          <HStack w="100%" my={4}>
            <Field
              label={t("Examiner")}
              value={formData.appraisalEvaluator ? formData.appraisalEvaluator.fullName : ""}
            />
            <Field label={t("Conservation status")} value={formData.appraisalConservationStatus} />
            <Field label={t("Appraisal motivation")} value={appraisalMotivation} />
            <Field label={t("Appraisal location")} value={formData.appraisalLocation} />
            <Field
              label={t("Appraisal date")}
              value={formData.appraisalDate ? parseDate(formData.appraisalDate) : ""}
            />
          </HStack>
        </BorderedBox>

        <BorderedBox title="Value informations" subtitle="Lorem ipsum dolor sit amet">
          <VStack alignItems="flex-start" pr={8}>
            <HStack w="100%" my={4}>
              <Field
                label={t("Author coefficient")}
                value={formData.valueInformationAuthorCoefficient}
              />
              <Field
                label={t("Information from previous sales")}
                value={formData.valueInformationInfo}
              />
              <Field
                label={t("Quotes similar objects")}
                value={formData.valueInformationQuotesSimilarAssets}
              />
              <Field label={t("Carrying value")} value={valueCarryingValue} />
              <Field label={t("Estimated value")} value={valueEstimatedValue} />
              <Field label={t("Notes")} value={formData.valueInformationNote} />
            </HStack>
          </VStack>
        </BorderedBox>

        <BorderedBox title="Documents" subtitle="Lorem ipsum dolor sit amet">
          <Table
            hiddenColumns={DOCUMENT_HIDDEN_FIELDS}
            columns={columns}
            data={documents}
            viewAction={onViewDocument}
          />
        </BorderedBox>
      </VStack>
    </Skeleton>
  );
};

export default AppraisalViewPage;
