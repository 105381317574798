import { useTranslation } from "react-i18next";
import { FormLabel, HStack, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import ASSET_CONSTANTS from "constants/asset";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface EnvironmentalConditionsNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}


const EnvironmentalConditionsNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: EnvironmentalConditionsNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();


  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}

          <FieldGroup title={t("Environment details")} subtitle={t("Environmental conditions - Environment details - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook label="Optimal light" name="optimalLightLevelIntensity" type="number" />
                  <FormInputHook
                    label="Light Unit"
                    options={ASSET_CONSTANTS.LIGHT_UNIT}
                    name="lightUnit"
                  />
                </HStack>
                <HStack alignItems="flex-end">
                    <HStack mr="3">
                      <FormInputHook name="optimalTemperature" label="Optimal temperature" type="number" />
                      <FormInputHook
                        label="Temperature Unit"
                        options={ASSET_CONSTANTS.TEMPERATURE_UNIT}
                        name="temperatureUnit"
                      />
                    </HStack>

                    <FormInputHook name="optimalHumidity" label="Optimal humidity" type="number"/>
                    <FormLabel pb="3.5">%</FormLabel>

                </HStack>



            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Environmental conditions Notes")} subtitle={t("Environmental conditions - Environmental conditions notes - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Environmental conditions notes"
                  name="enviromentalConditionsNotes"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup>




    </>
  );
};

export default EnvironmentalConditionsNewCategoryForm;
