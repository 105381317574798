/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Dot, Field } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const TechnicalDetailsView = ({ data }: AnyObject) => {
  const {
    category,
    isArtistProof,
    artistProofNotes,
    isEdition,
    editionNotes,
    isArtMultiple,
    multipleNumber,
    isSignedObject,
    signedObjectNotes,
    isTitledObject,
    titledObjectNotes,
    isDatedObject,
    datedObjectNotes,
    hasLabelsStamps,
    labelsStampsNotes,
    hasOtherAutographed,
    otherAutographedNotes,
    relevantAwards,
    isDynamic,
    dynamicNotes,
    hasHumanAnimalPresence,
    humanAnimalPresenceNotes,
    isAudio,
    isVideo,
    hasLoop,
    isMaster,
    masterLocation,
    coloring,
    variants,
    nominalValue,
    fiscalValue,
    currentValue,
    currency,
    score,
    circulation,
    manufactoringTechniques,
    mintingLastDate,
    mintingLastDateNotes,
    reminting,
    printingLastDate,
    printingLastDateNotes,
    reprinting,
    isFirstDraft,
    firstDraftNotes,
    mechanism,
    carat,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Technical details")} subtitle="Lorem ipsum">
      {category !== "NATURALISTIC_GOODS" &&
        category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Artist proof</Trans>
              </Text>
              <Dot status={isArtistProof} />
            </VStack>
            <Field label="Artist proof notes" value={artistProofNotes} />
          </HStack>
        )}

      {category === "BOOKS" && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Is first draft</Trans>
            </Text>
            <Dot status={isFirstDraft} />
          </VStack>
          <Field label="First draft notes" value={firstDraftNotes} />
        </HStack>
      )}

      {(category === "PRINT_OR_PHOTO" || category === "DIGITAL" || category === "BOOKS") && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Edition</Trans>
            </Text>
            <Dot status={isEdition} />
          </VStack>
          <Field label="Edition notes" value={editionNotes} />
        </HStack>
      )}

      {category === "DIGITAL" && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Master</Trans>
            </Text>
            <Dot status={isMaster} />
          </VStack>
          <Field label="Master location" value={masterLocation} />
        </HStack>
      )}

      {category !== "NATURALISTIC_GOODS" &&
        category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" &&
        category !== "BOOKS" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Multiple</Trans>
              </Text>
              <Dot status={isArtMultiple} />
            </VStack>

            <Field label="Multiple number" value={multipleNumber} />
          </HStack>
        )}

      {category !== "NATURALISTIC_GOODS" &&
        category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Signed</Trans>
              </Text>
              <Dot status={isSignedObject} />
            </VStack>
            <Field label="Sign notes" value={signedObjectNotes} />
          </HStack>
        )}

      {category !== "NATURALISTIC_GOODS" &&
        category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Titled</Trans>
              </Text>
              <Dot status={isTitledObject} />
            </VStack>
            <Field label="Title notes" value={titledObjectNotes} />
          </HStack>
        )}

      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Dated</Trans>
              </Text>
              <Dot status={isDatedObject} />
            </VStack>
            <Field label="Date note" value={datedObjectNotes} />
          </HStack>
        )}
      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Has label or stamps</Trans>
              </Text>
              <Dot status={hasLabelsStamps} />
            </VStack>
            <Field label="Label & stamps notes" value={labelsStampsNotes} />
          </HStack>
        )}

      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Has other autopgraphs</Trans>
              </Text>
              <Dot status={hasOtherAutographed} />
            </VStack>
            <Field label="Other autographs notes" value={otherAutographedNotes} />

          </HStack>
        )}
      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" &&
        (
          <Field label="Relevant awards and aknowledgments" value={relevantAwards} />
        )}

      {category === "OTHER" && <Field label="Mechanism/engine" value={mechanism} />}

      {category === "OTHER" && <Field label="Carats" value={carat} />}

      {category === "INSTALLATION" && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Is dynamic installation</Trans>
            </Text>
            <Dot status={isDynamic} />
          </VStack>
          <Field label="Dynamic installation notes" value={dynamicNotes} />
        </HStack>
      )}

      {category === "INSTALLATION" && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has human/animal presence</Trans>
            </Text>
            <Dot status={hasHumanAnimalPresence} />
          </VStack>
          <Field label="Human/animal notes" value={humanAnimalPresenceNotes} />

        </HStack>
      )}

      {(category === "INSTALLATION" || category === "DIGITAL") && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Is Audio</Trans>
            </Text>
            <Dot status={isAudio} />

            <Text variant="muted">
              <Trans>Is video</Trans>
            </Text>
            <Dot status={isVideo} />
          </VStack>
          <VStack>
            <Text variant="muted">
              <Trans>Has audio/video loop</Trans>
            </Text>
            <Dot status={hasLoop} />
          </VStack>
        </HStack>
      )}

      {category === "DIGITAL" && <Field label="Coloring" value={coloring} />}

      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && <Field label="Variants" value={variants} />}

      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && <Field label="Nominal value" value={nominalValue} />}

      {(category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
        <Field label="Fiscal value" value={fiscalValue} />
      )}

      {currentValue &&
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && (
          <VStack alignItems="flex-start" pr={8}>
            <Text fontStyle="normal" variant="muted">
              <Trans>Current value</Trans>
            </Text>
            { currentValue &&
              <HStack>
                <Text>{currentValue}</Text>
                <Text>{currency}</Text>
              </HStack>
            }
            {
            !currentValue && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </VStack>
        )}

      {(category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
        <Field label="Score" value={score} />
      )}

      {
        (category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY") && <Field label="Circulation" value={circulation} />}

      {
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <Field label="Minting/Manufactoring techniques" value={manufactoringTechniques} />
        )}

      { category === "NUMISMATIC_COINS" && (
        <Field label="Minting last date" value={mintingLastDate} />
      )}

      {category === "NUMISMATIC_COINS" && (
        <Field label="Minting last date notes" value={mintingLastDateNotes} />
      )}

      {category === "NUMISMATIC_COINS" && (
        <Field label="Reminting" value={reminting} />
      )}

      {(category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
        <Field label="Printing last date" value={printingLastDate} />
      )}

      {
        (category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
          <Field label="Printing last date notes" value={printingLastDateNotes} />
        )}

      {category === "NUMISMATIC_BANKNOTES" && (
        <Field label="Reprinting" value={reprinting} />
      )}
    </BorderedBox>
  );
};

export default TechnicalDetailsView;
