interface ValuesOptionLabels {
    options?: Array<{
        label: string,
        value: string
      }>;
      label: string;
      value?: string;
}

const findLabelsArray = (a: Array<ValuesOptionLabels>) => {
    const result = {};


    a.forEach(element => {
        if (element.value) {
            result[element.value] = element.label;
        }

        if (element.options) {
        element.options.forEach(option => {
            result[option.value] = option.label;
        })
        }
    })
    return result;
}

export default findLabelsArray;