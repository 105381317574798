import { HStack, Skeleton, VStack } from "@chakra-ui/react";

const TablePage = ({ loading }: { loading: boolean }) => (
  <VStack w="full">
    <Skeleton w="full" h="15vh" isLoaded={!loading} mb="8" />
    <HStack w="full" my="2">
      <Skeleton w="20%" h="4vh" isLoaded={!loading} />
      <Skeleton w="20%" h="4vh" isLoaded={!loading} />
      <Skeleton w="20%" h="4vh" isLoaded={!loading} />
      <Skeleton w="20%" h="4vh" isLoaded={!loading} />
    </HStack>
    <Skeleton w="100%" h="5vh" isLoaded={!loading} />
    <Skeleton w="100%" h="5vh" isLoaded={!loading} />
    <Skeleton w="100%" h="5vh" isLoaded={!loading} />
    <Skeleton w="100%" h="5vh" isLoaded={!loading} />
  </VStack>
);

export default TablePage;
