import { useEffect, useMemo, useState } from "react";
import { Select } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { isArray } from "lodash";

function DropdownFilter({
  title,
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    console.log({ filterValue, setFilter, preFilteredRows, id, Header });
  }, [filterValue, setFilter, preFilteredRows, id, Header]);
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (isArray(row.values[id])) {
        const splittedValues = row.values[id];
        row?.values[id]?.forEach((value) => {
          if (![...options.values()].includes(value)) options.add(value);
        });
      } else options.add(row.values[id]);
    });
    return [...options.values()].filter((option) => {
      // avoid filtering out boolean values
      if (typeof option == "boolean") return true;
      return option;
    });
  }, [id, preFilteredRows]);

  console.log(options);
  // Render a multi-select box
  console.log("HEADER: ", Header);
  return (
    <Select
      value={filterValue}
      width="auto"
      shadow={"sm"}
      fontWeight="semibold"
      size="sm"
      borderRadius="md"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        if (!e.target.value) {
          setIsActive(false);
          return;
        }
        setIsActive(true);
      }}
    >
      <option value="">
        <Trans>{Header === "" ? title : Header}</Trans>
      </option>
      {options.map((option, i) => {
        let text = "";
        switch (option) {
          case true:
            text = "Yes";
            break;
          case false:
            text = "No";
            break;
          case "PENDING":
            text = "Offer";
            break;
          case "APPROVED":
            text = "Policy";
            break;
          default:
            text = option;
        }

        return (
          <option key={i} value={option}>
            <Trans>{text}</Trans>
          </option>
        );
      })}
    </Select>
  );
}

export default DropdownFilter;
