/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
import {
  Box,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  VStack,
  Text,
  Heading,
  Table,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  /* Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text, */
} from "@chakra-ui/react";
import { Dot, Field } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { RiFileDownloadLine } from "react-icons/ri";
// import { RegistryAwareField } from 'components/ui';
// import style from "components/ui/Table/scss/index.module.scss"

import ASSET_CONSTANTS from "constants/asset";
import findLabel from "./FindLabel";

interface AssetDocumentViewProps {
  document: {
    id: string;
    title: string;
    description: string;
    documentType: string;
    coaType: string;
    issuer: string;
    recipient: string;
    isClassified: boolean;
    releaseDate: string;
    expirationDate: string;
    notes: string;
    archiveNumber: string;
    fineArtsIssueType: string;
    isTemporaryExport: boolean;
    isTemporaryImport: boolean;
    isCriticalCatalog: boolean;
    editor: string;
    curator: string;
    subscriptionDate: string;
    contractSubscriber: string;
    publishingDate: string;
    otherDocumentType: string;
    attachments: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string;
      file: string;
    }[];
  } | null;
}

const AssetDocumentView = ({ document }: AssetDocumentViewProps) => {
  if (!document) return null;
  const {
    id,
    title,
    description,
    documentType,
    coaType,
    issuer,
    recipient,
    isClassified,
    releaseDate,
    expirationDate,
    notes,
    archiveNumber,
    fineArtsIssueType,
    isTemporaryExport,
    isTemporaryImport,
    isCriticalCatalog,
    editor,
    curator,
    subscriptionDate,
    contractSubscriber,
    publishingDate,
    otherDocumentType,
    attachments,
  } = document;

  const { t } = useTranslation();

  return (
    <Box maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}>
        {documentType.toString() === "AUTHENTICITY_CERTIFICATE" && (
          <>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start" pr={8}>
                <Text fontStyle="normal" variant="muted">
                  <Trans>COA type</Trans>
                </Text>
                <Text fontWeight="600">
                  {findLabel(ASSET_CONSTANTS.ASSET_DOCUMENT_COA_TYPES, coaType)}
                </Text>
                {!coaType && (
                  <Text fontStyle="italic" fontWeight="400" color="gray.300">
                    <Trans>Value not found</Trans>
                  </Text>
                )}
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Issuer" value={issuer} />
              {/* <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Issuer</Trans>
              </Text>
              <Text fontWeight="600">
                {issuer}
                </Text>
            </VStack> */}
              <Field label="Recipient" value={recipient} />
              {/* <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Recipient</Trans>
              </Text>
              <Text fontWeight="600">
                {recipient}
                </Text>
            </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Description" value={description} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Description</Trans>
              </Text>
              <Text fontWeight="600">
                {description}
                </Text>
            </VStack> */}
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Release date</Trans></Text>
              <Text fontWeight="600">
                {parseDate(releaseDate)}
                </Text>
            </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text>
            </VStack> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "ARCHIVING_CERTIFICATE" && (
          <>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Issuer" value={issuer} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Issuer</Trans></Text>
              <Text fontWeight="600">
                {issuer}
                </Text>
            </VStack> */}
              <Field label="Recipient" value={recipient} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Recipient</Trans></Text>
              <Text fontWeight="600">
                {recipient}
                </Text>
            </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Archive number" value={archiveNumber} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Archive number</Trans></Text>
              <Text fontWeight="600">
                {archiveNumber}
                </Text>
            </VStack> */}
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Release date</Trans></Text>
              <Text fontWeight="600">
                {parseDate(releaseDate)}
                </Text>
            </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
              <Trans>Notes</Trans>
            </Text>
            <Text>
              {notes}
            </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "FINE_ARTS_ISSUE" && (
          <>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start" pr={8}>
                <Text fontStyle="normal" variant="muted">
                  <Trans>Fine arts issue type</Trans>
                </Text>
                {fineArtsIssueType && (
                  <Text fontWeight="600">
                    {findLabel(ASSET_CONSTANTS.FINE_ARTS_ISSUE_TYPES, fineArtsIssueType)}
                  </Text>
                )}
                {!fineArtsIssueType && (
                  <Text fontStyle="italic" fontWeight="400" color="gray.300">
                    <Trans>Value not found</Trans>
                  </Text>
                )}
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start" pr={8}>
                <Text fontStyle="normal" variant="muted">
                  <Trans>Release date</Trans>
                </Text>
                <Text fontWeight="600">
                  {parseDate(releaseDate)}
                  </Text>
              </VStack> */}
              <Field label="Expiration date" value={new Date(expirationDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Expiration date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(expirationDate)}
                  </Text>
              </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Issuer" value={issuer} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Issuer</Trans></Text>
                <Text fontWeight="600">
                  {issuer}
                  </Text>
              </VStack> */}
              <Field label="Recipient" value={recipient} />
              {/* <VStack alignItems="flex-start"> */}
              {/* <Text variant="muted"><Trans>Recipient</Trans></Text> */}
              {/* <Text fontWeight="600"> */}
              {/* {recipient} */}
              {/* </Text> */}
              {/* </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "CUSTOM_DOCUMENT" && (
          <>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is temporary export?</Trans>
                </Text>
                <Dot status={isTemporaryExport} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is temporary import?</Trans>
                </Text>
                <Dot status={isTemporaryImport} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Release date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(releaseDate)}
                  </Text>
              </VStack> */}
              <Field label="Expiration date" value={new Date(expirationDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Expiration date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(expirationDate)}
                  </Text>
              </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Issuer" value={issuer} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Issuer</Trans></Text>
                <Text fontWeight="600">
                  {issuer}
                  </Text>
              </VStack> */}
              <Field label="Recipient" value={recipient} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Recipient</Trans></Text>
                <Text fontWeight="600">
                  {recipient}
                  </Text>
              </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "CATALOG" && (
          <>
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is critical catalog?</Trans>
                </Text>
                <Dot status={isCriticalCatalog} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Title" value={title} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Title</Trans></Text>
                <Text fontWeight="600">
                  {title}
                  </Text>
              </VStack> */}
              <Field label="Description" value={description} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Description</Trans></Text>
                <Text fontWeight="600">
                  {description}
                  </Text>
              </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Editor" value={editor} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Editor</Trans></Text>
                <Text fontWeight="600">
                  {editor}
                  </Text>
              </VStack> */}
              <Field label="Curator" value={curator} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Curator</Trans></Text>
                <Text fontWeight="600">
                  {curator}
                  </Text>
              </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Release date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(releaseDate)}
                  </Text>
              </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "CONTRACT" && (
          <>
            <HStack spacing="16" w="full">
              <Field label="Description" value={description} />
              {/* <VStack alignItems="flex-start">
                  <Text variant="muted"><Trans>Description</Trans></Text>
                  <Text fontWeight="600">
                    {description}
                    </Text>
                </VStack> */}
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Subscription date" value={new Date(subscriptionDate)} />
              {/* <VStack alignItems="flex-start">
                  <Text variant="muted"><Trans>Subscription date</Trans></Text>
                  <Text fontWeight="600">
                    {parseDate(subscriptionDate)}
                    </Text>
                </VStack> */}
              <Field label="Expiration date" value={new Date(expirationDate)} />
              {/* <VStack alignItems="flex-start">
                  <Text variant="muted"><Trans>Expiration date</Trans></Text>
                  <Text fontWeight="600">
                    {parseDate(expirationDate)}
                    </Text>
                </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Contract subscriber" value={contractSubscriber} />
              {/* <VStack alignItems="flex-start">
                  <Text variant="muted"><Trans>Contract subscriber</Trans></Text>
                  <Text fontWeight="600">
                    {contractSubscriber}
                    </Text>
                </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                  <Trans>Notes</Trans>
                </Text>
                <Text>
                  {notes}
                </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "PUBLICATION" && (
          <>
            <HStack spacing="16" w="full">
              <Field label="Title" value={title} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Title</Trans></Text>
                <Text fontWeight="600">
                  {title}
                  </Text>
              </VStack> */}
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Description" value={description} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Description</Trans></Text>
                <Text fontWeight="600">
                  {description}
                  </Text>
              </VStack> */}
              <Field label="Publishing date" value={new Date(publishingDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Publishing date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(publishingDate)}
                  </Text>
              </VStack> */}
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Editor" value={editor} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Editor</Trans></Text>
                <Text fontWeight="600">
                  {editor}
                  </Text>
              </VStack> */}
              <Field label="Curator" value={curator} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Curator</Trans></Text>
                <Text fontWeight="600">
                  {curator}
                  </Text>
              </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text> */}
            </HStack>
          </>
        )}

        {documentType.toString() === "OTHER" && (
          <>
            <HStack spacing="16" w="full">
              <Field label="Document type" value={otherDocumentType} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Document type</Trans></Text>
                <Text fontWeight="600">
                  {otherDocumentType}
                  </Text>
              </VStack> */}
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Is classified?</Trans>
                </Text>
                <Dot status={isClassified} />
              </VStack>
            </HStack>
            <HStack spacing="16" w="full">
              <Field label="Description" value={description} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Description</Trans></Text>
                <Text fontWeight="600">
                  {description}
                  </Text>
              </VStack> */}
              <Field label="Release date" value={new Date(releaseDate)} />
              {/* <VStack alignItems="flex-start">
                <Text variant="muted"><Trans>Release date</Trans></Text>
                <Text fontWeight="600">
                  {parseDate(releaseDate)}
                  </Text>
              </VStack> */}
            </HStack>

            <HStack spacing="16" w="full">
              <Field label="Notes" value={notes} />
              {/* <Text variant="muted">
                <Trans>Notes</Trans>
              </Text>
              <Text>
                {notes}
              </Text> */}
            </HStack>
          </>
        )}

        <Heading size="sm">
          <Trans>Files</Trans>
        </Heading>
        {attachments.length === 0 && <Text>No file attached to document</Text>}
        {attachments.length > 0 && (
          <Table>
            <Thead>
              <Tr>
                <Th>File</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {attachments.map((attachment) => (
                <Tr>
                  <Td>{attachment.name}</Td>
                  <Td>
                    <IconButton
                      variant="outline"
                      onClick={() => window.open(attachment.file)}
                      icon={<RiFileDownloadLine />}
                      aria-label="Download file"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Stack>
    </Box>
  );
};

export default AssetDocumentView;
