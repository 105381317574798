import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import {
  Dot,
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
} from "components/ui";
import {
  useGetAllRestorationsQuery,
  useDeleteRestorationMutation,
  RestorationNode,
  useExportRestorationPdfMutation,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { Trans, useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";

const QUICK_ACTIONS = [
  {
    name: "Add new Restoration entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const CUSTOM_FIELDS = [
  "restorationStatus",
  "restorationDocumentDate",
  "restorationStartDate",
  "restorationEndDate",
  "restorationDirector",
  "restorationAsset",
  "restorationAssetAuthor",
  "createdAt",
  "actualMediaStatus",
  "restorationAsset.actualDateAndPeriod",
  "mediaNumber",
  "createdBy",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  },
  {
    Header: "Director",
    Footer: "Director",
    accessor: "restorationDirector",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null)
        return <div>{value.fullName}</div>;
      return <div />;
    },
  },
  {
    Header: "Restoration Date",
    Footer: "Restoration Date",
    accessor: "restorationDocumentDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
  },

  {
    Header: "Restoration Start Date",
    Footer: "Restoration Start Date",
    accessor: "restorationStartDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
  },
  {
    Header: "Restoration End Date",
    Footer: "Restoration End Date",
    accessor: "restorationEndDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
  },

  {
    Header: "Object Author",
    Footer: "Object Author",
    accessor: "restorationAsset.authorEntity",
    Cell: ({ cell: { value } }: { cell: { value: { id: string; fullName: string } } }) => {
      if (value !== undefined && value !== null && value.fullName !== null)
        return <div>{value.fullName}</div>;
      return <div />;
    },
    disableFilters: true,
  },
  {
    Header: "Object Title",
    Footer: "Object Title",
    accessor: "restorationAsset",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: { id: string; title: string } } }) => {
      if (value !== undefined && value !== null && value.title !== null)
        return <div>{value.title}</div>;
      return <div />;
    },
  },

  {
    Header: "Created On",
    Footer: "Created On",
    accessor: "restorationAsset.actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 0,
    disableFilters: true,
  },
  {
    Header: "Documents",
    accessor: "mediaNumber",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 0,
  },
  {
    Header: "STATUS",
    Footer: "STATUS",
    accessor: "restorationStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Dot positiveLabel="Draft" negativeLabel="Closed" status={value === "draft"} />
    ),
  },
  {
    Header: "",
    accessor: "actualMediaStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    weight: 0,
  },
  {
    Header: "",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];
const FILTERABLE_COLUMNS = ["deleted", "type"];

const RestorationPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllRestorationsQuery();
  const [deleteRestoration, { data: deleteData, error: deleteError, loading: deleteLoading }] =
    useDeleteRestorationMutation({
      ...handleMutation("Restoration deleted!"),
    });

  const [
    exportRestorationPdf,
    {
      data: exportRestorationPdfData,
      error: exportRestorationPdfError,
      loading: exportRestorationPdfLoading,
    },
  ] = useExportRestorationPdfMutation({
    ...handleMutation("Restoration exported!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;

  if (!data || !data.allRestorations || data.allRestorations.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allRestorations: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;

  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await deleteRestoration({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onGeneratePdf = async (restorationId: string) => {
    const { data } = await exportRestorationPdf({
      variables: {
        input: {
          restorationId,
        },
      },
    });

    const {
      exportRestorationPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete exhibition(s)</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: RestorationNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onDelete(original.id);
            return null;
          })
        );
        await refetch();
      },
    },
    {
      name: <Trans>Export PDF</Trans>,
      action: async (selectedRows: { original: RestorationNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onGeneratePdf(original.id);
            return null;
          })
        );
      },
      loading: exportRestorationPdfLoading,
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allRestorations.edges.flatMap((edge) => edge?.node)}
          pathname="/mgmt/restoration/"
          deleteAction={(id: string) => onDelete(id)}
        />
      </Skeleton>
    </Box>
  );
};

export default RestorationPage;
