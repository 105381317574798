import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Footer, LanguageSelector } from "components/ui";
import { useAuth } from "contexts/AuthProvider";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import { RiSettingsFill, RiUser5Fill } from "react-icons/ri";
import { ImBell } from "react-icons/im";
import { NavLink, Outlet, useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import arteGeneraliPNG from "assets/arte-generali-data.png";
import logoSVG from "assets/logo.svg";
import { MGMT_ROUTES, SPIN_ROUTES } from "routes/constants";
import styles from "../scss/Layout.module.scss";

import {
  registryMenuEntries,
  mgmtMenuEntries,
  spinMenuEntries,
  topMenuEntries,
  usersMenuEntries,
  otherMenuEntries,
} from "../sidebarMenuEntries";

interface LayoutProps {
  onLocaleChange?: (locale: string) => void;
}

interface NavLinkActiveAwareProps {
  to: string;
  icon: React.ReactNode;
  name: React.ReactNode;
  onClick?: () => void;
  hidden?: boolean;
}

const NavLinkActiveAwareTop = ({ to, icon, name, onClick, hidden }: NavLinkActiveAwareProps) => (
  <Link
    as={NavLink}
    to={to}
    py="1"
    className={styles["with-sidebar__sidebar__navItem__top"]}
    _activeLink={{
      borderBottom: "4px solid var(--chakra-colors-primaryFuchsia)",
      "& > button": {
        color: "primaryFuchsia",
      },
      "& > svg": {
        color: "primaryFuchsia",
      },
      "& > p": {
        color: "white",
      },
    }}
  >
    <Button size="sm" variant="ghost" hidden={hidden} px="0">
      {name}
    </Button>
  </Link>
);
const NavLinkActiveAware = ({ to, icon, name, onClick, hidden }: NavLinkActiveAwareProps) => (
  <VStack alignItems="flex-start" onClick={onClick}>
    <Link
      end
      as={NavLink}
      to={to}
      className={styles["with-sidebar__sidebar__navItem"]}
      _activeLink={{
        borderLeft: "2px solid var(--chakra-colors-primaryFuchsia)",
        "& > svg": {
          color: "primaryFuchsia",
        },
        "& > p": {
          fontWeight: "700",
          color: "primaryFuchsia",
        },
      }}
    >
      {icon}
      <Text fontSize="sm" fontWeight={400} hidden={hidden} pl="2">
        {name}
      </Text>
    </Link>
  </VStack>
);

const Layout = ({ onLocaleChange }: LayoutProps) => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const { t, i18n } = useTranslation();

  const { user, signout } = useAuth();

  const { pathname } = useLocation();
  const hostname = window.location.host;
  const navigate = useNavigate();

  const [showSubMenu, setShowSubMenu] = useState<string | null>(null);

  const tenantCanUseMGMT = process.env.REACT_APP_MGMT_ENABLED === "true" ?? false;
  const tenantCanUseSPIN = process.env.REACT_APP_SPIN_ENABLED === "true" ?? false;

  useEffect(() => {
    console.log(i18n.language, i18n);
    onLocaleChange(i18n.language);
  }, [i18n]);

  const onLanguageSelect = (e: React.FormEvent<HTMLSelectElement>): void => {
    const {
      currentTarget: { value },
    } = e;
    if (!onLocaleChange) return;
    onLocaleChange(value);
  };

  return (
    <Flex direction="column" minH="100vh" w="100vw">
      <header>
        <VStack position="sticky" top="0" w="full" spacing="0" zIndex="sticky">
          <Flex
            px={20}
            borderBottom="1px"
            borderColor="gray.200"
            bgColor="#24292f"
            w="full"
            h="4rem"
          >
            <Flex>
              <Stack direction="row" alignItems="center">
                <RouterLink to="/">
                  <Image
                    src={hostname === "staging" ? arteGeneraliPNG : logoSVG}
                    h="2.5rem"
                    mr={4}
                  />
                </RouterLink>

                {/* <Avatar name={user?.name} src="https://bit.ly/broken-link" size="xs" />
            <Text fontSize="md">{user?.name}</Text> */}
              </Stack>
            </Flex>
            <Spacer />
            <Stack direction="row" alignItems="center">
              {/* <Button variant="outline" mx={4}>
            Make an action
          </Button> */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiChevronDown />}
                  size="xs"
                  mx={12}
                  variant="outline"
                >
                  {t("Add new")}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => navigate(MGMT_ROUTES.ASSETS_NEW)}>
                    {t("Add an asset")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate(SPIN_ROUTES.POLICY_PC_NEW)}>
                    {t("Create a PC policy")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate(SPIN_ROUTES.POLICY_TE_NEW)}>
                    {t("Create a TE policy")}
                  </MenuItem>
                </MenuList>
              </Menu>

              <LanguageSelector onLanguageSelect={onLanguageSelect} />

              <HStack px={8} spacing={-4}>
                <IconButton
                  aria-label="Search database"
                  icon={<ImBell size={18} />}
                  variant="ghost"
                  bgColor="transparent"
                  colorScheme="whiteAlpha"
                  size="lg"
                  px={2}
                />{" "}
                <IconButton
                  aria-label="Search database"
                  icon={<RiSettingsFill size={20} />}
                  variant="ghost"
                  bgColor="transparent"
                  size="lg"
                  colorScheme="whiteAlpha"
                  px={2}
                />{" "}
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Search database"
                    icon={<RiUser5Fill size={20} />}
                    variant="ghost"
                    bgColor="transparent"
                    colorScheme="whiteAlpha"
                    size="lg"
                    px={2}
                  />
                  <MenuList>
                    <MenuItem onClick={(e) => signout(() => navigate("/login"))}>
                      {t("Log out")}
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Stack direction="row" alignItems="center" pl="8">
                  <Avatar name={user?.name} src="https://bit.ly/broken-link" size="xs" />
                  <Text fontSize="md" color="whiteAlpha.900">
                    {user?.name}
                  </Text>
                </Stack>
              </HStack>
              <Button leftIcon={<Icon />} variant="link" mx={8} bgColor="whiteAlpha">
                Help
              </Button>
            </Stack>
          </Flex>
          <HStack
            spacing="6"
            px={16}
            pt={2}
            borderBottom="1px"
            borderColor="gray.100"
            w="full"
            bgColor="white"
          >
            {topMenuEntries &&
              topMenuEntries.map(({ to, icon, name, enabled }) =>
                enabled ? <NavLinkActiveAwareTop key={to} to={to} icon={icon} name={name} /> : <></>
              )}
          </HStack>
        </VStack>
      </header>

      <main className={styles.wrapper}>
        <div className={`"bp4-dark" ${styles["with-sidebar"]}`}>
          {/* <!-- sidebar --> */}
          <Box
            pl="5rem"
            pr="1rem"
            mt={8}
            position="sticky"
            top="10rem"
            alignSelf="flex-start"
            style={{
              flexBasis: isSidebarHidden ? "100px" : "250px",
              paddingLeft: isSidebarHidden ? "1rem" : "5rem",
              paddingRight: isSidebarHidden ? "1rem" : "5rem",
            }}
            transition="all 1s ease"
          >
            {pathname.includes("spin") && tenantCanUseSPIN && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  <Trans>Policies</Trans>
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            {pathname.includes("mgmt") && tenantCanUseMGMT && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  <Trans>Management</Trans>
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            {pathname.includes("/r/") && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  <Trans>Registry</Trans>
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            {pathname.includes("/u/") && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  <Trans>Users</Trans>
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            <Stack direction="column" spacing="4" mt={3} borderLeft="1px" borderColor="gray.100">
              {mgmtMenuEntries &&
                pathname.includes("mgmt") &&
                tenantCanUseMGMT &&
                mgmtMenuEntries.map(({ to, icon, name, children }) => (
                  <>
                    <NavLinkActiveAware
                      key={to}
                      to={to}
                      icon={icon}
                      name={name}
                      onClick={() => setShowSubMenu(to)}
                      hidden={isSidebarHidden}
                    />
                    {children?.map(
                      ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                        showSubMenu &&
                        childrenTo.includes(showSubMenu) && (
                          <Link
                            key={childrenTo}
                            as={NavLink}
                            pl="6"
                            to={childrenTo}
                            className="child-link"
                            hidden={isSidebarHidden}
                          >
                            <Text fontSize="xs">{childrenName}</Text>
                          </Link>
                        )
                    )}
                  </>
                ))}
              {spinMenuEntries && pathname.includes("spin") && tenantCanUseSPIN && (
                <>
                  {spinMenuEntries.map(({ to, icon, name, children }) => (
                    <>
                      <NavLinkActiveAware
                        to={to}
                        key={to}
                        icon={icon}
                        name={name}
                        onClick={() => setShowSubMenu(to)}
                        hidden={isSidebarHidden}
                      />
                      {children?.map(
                        ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                          showSubMenu &&
                          (childrenTo.includes(showSubMenu) || childrenTo === showSubMenu) && (
                            <Link
                              key={childrenTo}
                              as={NavLink}
                              pl="6"
                              to={childrenTo}
                              className="child-link"
                              hidden={isSidebarHidden}
                            >
                              <Text fontSize="xs">{childrenName}</Text>
                            </Link>
                          )
                      )}
                    </>
                  ))}
                </>
              )}
              {registryMenuEntries &&
                pathname.includes("/r/") &&
                registryMenuEntries.map(({ to, icon, name, children }) => (
                  <>
                    <NavLinkActiveAware
                      to={to}
                      icon={icon}
                      key={to}
                      name={name}
                      onClick={() => setShowSubMenu(to)}
                      hidden={isSidebarHidden}
                    />
                    {children?.map(
                      ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                        (childrenTo.includes(showSubMenu) || childrenTo === showSubMenu) && (
                          <Link
                            key={childrenTo}
                            as={NavLink}
                            pl="6"
                            to={childrenTo}
                            className="child-link"
                            hidden={isSidebarHidden}
                          >
                            <Text fontSize="xs">{childrenName}</Text>
                          </Link>
                        )
                    )}
                  </>
                ))}
              {usersMenuEntries &&
                pathname.includes("/u/") &&
                usersMenuEntries.map(({ to, icon, name, children }) => (
                  <>
                    <NavLinkActiveAware
                      to={to}
                      icon={icon}
                      key={to}
                      name={name}
                      onClick={() => setShowSubMenu(to)}
                      hidden={isSidebarHidden}
                    />
                    {children?.map(
                      ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                        true &&
                        (childrenTo.includes(showSubMenu) || childrenTo === showSubMenu) && (
                          <Link
                            key={childrenTo}
                            as={NavLink}
                            pl="6"
                            to={childrenTo}
                            className="child-link"
                            hidden={isSidebarHidden}
                          >
                            <Text fontSize="xs">{childrenName}</Text>
                          </Link>
                        )
                    )}
                  </>
                ))}
              {otherMenuEntries &&
                pathname.includes("/o/") &&
                otherMenuEntries.map(({ to, icon, name, children }) => (
                  <>
                    <NavLinkActiveAware
                      to={to}
                      icon={icon}
                      key={to}
                      name={name}
                      onClick={() => setShowSubMenu(to)}
                      hidden={isSidebarHidden}
                    />
                    {children?.map(
                      ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                        true &&
                        (childrenTo.includes(showSubMenu) || childrenTo === showSubMenu) && (
                          <Link
                            key={childrenTo}
                            as={NavLink}
                            pl="6"
                            to={childrenTo}
                            className="child-link"
                            hidden={isSidebarHidden}
                          >
                            <Text fontSize="xs">{childrenName}</Text>
                          </Link>
                        )
                    )}
                  </>
                ))}
            </Stack>
          </Box>

          <div>
            <Box pr={24} py={6}>
              <Outlet />
            </Box>
          </div>
        </div>
      </main>
      <Footer />
    </Flex>
  );
};

export default Layout;
