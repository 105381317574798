export default {
  LENGTH_UNIT: [
    {
      label: "mm",
      value: "MM",
    },
    {
      label: "cm",
      value: "CM",
    },
    {
      label: "m",
      value: "M",
    },
    {
      label: "in",
      value: "IN",
    },
    {
      label: "ft",
      value: "FT",
    },
  ],
  WEIGHT_UNIT: [
    {
      label: "g",
      value: "G",
    },
    {
      label: "hg",
      value: "HG",
    },
    {
      label: "kg",
      value: "KG",
    },
    {
      label: "oz",
      value: "OZ",
    },
    {
      label: "lb",
      value: "LB",
    },
  ],
  LIGHT_UNIT: [
    { label: "Lux", value: "LUX" },
    { label: "Foot candles", value: "FOOT_CANDLES" },
  ],
  TEMPERATURE_UNIT: [
    { label: "C°", value: "C" },
    { label: "°F", value: "F" },
  ],
  DIMENSIONS_ACQUISITIONS: [
    { label: "Given", value: "GIVEN" },
    { label: "Measured", value: "MEASURED" },
    { label: "Not measured", value: "NOT_MEASURED" },
  ],
  INJURY_RISK: [
    { label: "None", value: "NONE" },
    { label: "Low", value: "LOW" },
    { label: "Average", value: "AVERAGE" },
    { label: "High", value: "HIGH" },
  ],
  ASSET_VALUES_ACQUISITIONS: [
    { label: "Bought", value: "BOUGHT" },
    { label: "Received as a gift", value: "GIFT" },
    { label: "Inherited", value: "INHERITED" },
    { label: "Other", value: "OTHER" },
  ],
  ASSET_VALUES_REASONS: [
    { label: "Alienation", value: "ALIENATION" },
    { label: "CR", value: "CR" },
    { label: "Insurance quote", value: "INSURANCE_QUOTE" },
    { label: "Asset analysis", value: "ASSET_ANALYSIS" },
    { label: "Legal dispute", value: "LEGAL_DISPUTE" },
  ],
  ASSET_DATING_TYPES: [
    { label: "Period", value: "PERIOD" },
    { label: "Date", value: "DATE" },
    { label: "Year", value: "YEAR" },
    { label: "Century", value: "CENTURY" },
  ],
  ASSET_DATE_WINDOWS: [
    { label: "End", value: "END" },
    { label: "Half", value: "HALF" },
    { label: "Begin", value: "BEGIN" },
    { label: "First half", value: "FIRST_HALF" },
    { label: "Second half", value: "SECOND_HALF" },
  ],
  ASSET_DATE_ERAS: [
    {
      label: "Precambrian hadean (4.6 billions B.C. - 4 billions B.C.)",
      value: "PRECAMBRIAN_HADEAN",
    },
    {
      label: "Precambrian archean (4 billions B.C. - 2.5 billions B.C.)",
      value: "PRECAMBRIAN_ARCHEAN",
    },
    {
      label: "Precambrian proterozoic (2.5 billions B.C. - 541,000,000 B.C.)",
      value: "PRECAMBRIAN_PROTEROZOIC",
    },
    { label: "Precambrian (4.6 billions B.C. - 541,000,000 B.C.)", value: "PRECAMBRIAN" },
    {
      label: "Cambrian paleozoic (541,000,000 B.C. - 485,400,000 B.C.)",
      value: "CAMBRIAN_PALEOZOIC",
    },
    {
      label: "Ordovician paleozoic (485,400,000 B.C. - 443,800,000 B.C.)",
      value: "ORDOVICIAN_PALEOZOIC",
    },
    {
      label: "Silurian paleozoic (443,800,000 B.C. - 416,000,000 B.C.)",
      value: "SILURIAN_PALEOZOIC",
    },
    {
      label: "Paleozoic devonian (416,000,000 B.C. - 359,200,000 B.C.)",
      value: "PALEOZOIC_DEVONIAN",
    },
    {
      label: "Paleozoic carboniferous (359,200,000 B.C. -  299,000,000 B.C.)",
      value: "PALEOZOIC_CARBONIFEROUS",
    },
    {
      label: "Paleozoic permian (299,000,000 B.C. -  251,000,000 B.C.)",
      value: "PALEOZOIC_PERMIAN",
    },
    { label: "Paleozoic (541,000,000 B.C. -  251,000,000 B.C.)", value: "PALEOZOIC" },
    {
      label: "Triassic mesozoic (252,200,000 B.C. -  201,300,000 B.C.)",
      value: "TRIASSIC_MESOZOIC",
    },
    {
      label: "Jurassic mesozoic (201,300,000 B.C. -  145,500,000 B.C.)",
      value: "JURASSIC_MESOZOIC",
    },
    {
      label: "Creataceous mesozoic (145,500,000 B.C. -  65,500,000 B.C.)",
      value: "CREATACEOUS_MESOZOIC",
    },
    { label: "Mesozoic (252,200,000 B.C. -  65,500,000 B.C.)", value: "MESOZOIC" },
    {
      label: "Paleogene cenozoic (65,500,000 B.C. -  23,030,000 B.C.)",
      value: "PALEOGENE_CENOZOIC",
    },
    { label: "Cenozoic neogene (23,030,000 B.C. -  2,588,000 B.C.)", value: "CENOZOIC_NEOGENE" },
    { label: "Cenozoic (65,500,000 B.C. -  2,588,000 B.C.)", value: "CENOZOIC" },
    { label: "Lower paleolithic (2,588,000 B.C. -  120,000 B.C.)", value: "LOWER_PALEOLITHIC" },
    { label: "Middle paleolithic (120,000 B.C. -  36,000 B.C.)", value: "MIDDLE_PALEOLITHIC" },
    { label: "Upper paleolithic (36,000 B.C. -  10,000 B.C.)", value: "UPPER_PALEOLITHIC" },
    { label: "Paleolithic (2,588,000 B.C. -  10,000 B.C.)", value: "PALEOLITHIC" },
    { label: "Mesolithic (10,000 B.C. -  8,000 B.C.)", value: "MESOLITHIC" },
    { label: "Neolithic (8,000 B.C. -  3,000 B.C.)", value: "NEOLITHIC" },
    {
      label: "Copper or cuprolytic age (5,000 B.C. -  3,000 B.C.)",
      value: "COPPER_OR_CUPROLYTIC_AGE",
    },
    { label: "Bronze age (3,000 B.C. - approximately 1,200 B.C.)", value: "BRONZE_AGE" },
    { label: "Iron age (approximately 1,200 B.C. - 1 B.C.)", value: "IRON_AGE" },
    { label: "Ancient age (3,500 B.C. - 476 A.C.)", value: "ANCIENT_AGE" },
    { label: "Medieval age (476 A.C. - 1492 A.C.)", value: "MEDIEVAL_AGE" },
    { label: "Modern age (1492 - 1789)", value: "MODERN_AGE" },
  ],

  ASSET_FRAME_MATERIALS: [
    { label: "Legno", value: "WOOD" },
    { label: "Metallo", value: "METAL" },
    { label: "Carta", value: "PAPER" },
    { label: "Perspex", value: "PERSPEX" },
    { label: "Plastica", value: "PLASTIC" },
    { label: "Multimateriale", value: "MULTIMATERIAL" },
    { label: "Pietra", value: "STONE" },
    { label: "Laminato", value: "LAMINATE" },
    { label: "Tessuto", value: "FABRIC" },
  ],

  FILE_SIZE_UNIT: [
    {
      label: "kb",
      value: "KB",
    },
    {
      label: "mb",
      value: "MB",
    },
    {
      label: "gb",
      value: "GB",
    },
    {
      label: "tb",
      value: "TB",
    },
  ],

  MEDIA_LENGTH_UNIT: [
    {
      label: "s",
      value: "S",
    },
    {
      label: "m",
      value: "M",
    },
    {
      label: "h",
      value: "H",
    },
  ],

  RESOLUTION_UNIT: [
    {
      label: "p",
      value: "P",
    },
    {
      label: "k",
      value: "K",
    },
  ],

  STAMP_PRINTING_TECHNIQUE: [
    { value: "LITHOGRAPHY", label: "Lithography" },
    { value: "OFFSET", label: "Offset" },
    { value: "SERIGRAPHY", label: "Serigraphy" },
    { value: "RELIEF_PRINTING", label: "Relief printing" },
    { value: "LETTERPRESS_PRINTING", label: "Letterpress printing" },
    { value: "CHALCOGRAPHY", label: "Chalcography" },
    { value: "ROTOGRAVURE", label: "Rotogravure" },
    { value: "PHOTOGRAVURE", label: "Photogravure" },
  ],

  STAMP_REALIZATION: [
    { value: "INDENTED", label: "Indented" },
    { value: "NOT_INDENTED", label: "Not indented" },
    { value: "SELF_ADHESIVE", label: "Self-adhesive" },
    { value: "AUTOMATIC", label: "Automatic" },
  ],

  STAMP_INDENTATION_TYPES: [
    { value: "LINEAR", label: "Linear" },
    { value: "COMB", label: "Comb" },
    { value: "BLOCK", label: "Block" },
  ],

  STAMP_CENTERING: [
    { value: "SUPERB", label: "Superb" },
    { value: "EXTRA_FINE", label: "Extra fine" },
    { value: "FINE", label: "Fine" },
    { value: "AVERAGE", label: "Average" },
    { value: "POOR", label: "Poor" },
  ],

  STAMP_FORMATS: [
    { value: "SHEETS", label: "Sheets" },
    { value: "COIL", label: "Coil" },
    { value: "BOOKLET", label: "Booklet" },
  ],

  PHILATELIC_CONSERVATION: [
    { value: "MNH", label: "Mint never-hinged" },
    { value: "MLH", label: "Mint lightly hinged" },
    { value: "MVLH", label: "Mint very lightly hinged" },
    { value: "MHH", label: "Mint heavily hinged" },
    { value: "HR", label: "Hinge remaining" },
    { value: "OG", label: "Original gum" },
    { value: "NG", label: "No gum" },
    { value: "RG", label: "Regummed" },
    { value: "FC", label: "First day cover" },
  ],

  NUMISMATIC_CONSERVATION_USA: [
    { value: "PO1", label: "PO 1" },
    { value: "FA2", label: "FA 2" },
    { value: "AG3", label: "AG 3" },
    { value: "G4", label: "G 4" },
    { value: "G6", label: "G 6" },
    { value: "VG8", label: "VG 8" },
    { value: "VG10", label: "VG 10" },
    { value: "F12", label: "F 12" },
    { value: "F15", label: "F 15" },
    { value: "VF20", label: "VF 20" },
    { value: "VF25", label: "VF 25" },
    { value: "VF30", label: "VF 30" },
    { value: "VF35", label: "VF 35" },
    { value: "XF40", label: "XF 40" },
    { value: "XF45", label: "XF 45" },
    { value: "AU50", label: "AU 50" },
    { value: "AU53", label: "AU 53" },
    { value: "AU55", label: "AU 55" },
    { value: "AU58", label: "AU 58" },
    { value: "MS60", label: "MS 60" },
    { value: "MS61", label: "MS 61" },
    { value: "MS62", label: "MS 62" },
    { value: "MS63", label: "MS 63" },
    { value: "MS64", label: "MS 64" },
    { value: "MS65", label: "MS 65" },
    { value: "MS66", label: "MS 66" },
    { value: "MS67", label: "MS 67" },
    { value: "MS68", label: "MS 68" },
    { value: "MS69", label: "MS 69" },
    { value: "MS70", label: "MS 70" },
  ],
  NUMISMATIC_CONSERVATION_ITA: [
    { value: "M", label: "M - Mediocre" },
    { value: "D", label: "D - Discreto" },
    { value: "B", label: "B - Bello" },
    { value: "MB", label: "MB - Molto bello" },
    { value: "BB", label: "BB - Bellissimo" },
    { value: "SPL", label: "SPL - Splendido" },
    { value: "SUP", label: "SUP - Superbo" },
    { value: "FDC_FDS", label: "FDC/FDS - Fior di conio" },
  ],

  NUMISMATIC_CONSERVATION_ENG: [
    { value: "PR_F", label: "PR_F - Poor fair" },
    { value: "AG", label: "AG - About good" },
    { value: "G", label: "G - Good" },
    { value: "VG", label: "VG - Very good" },
    { value: "F", label: "F - Fine" },
    { value: "VF", label: "VF - Very fine" },
    { value: "EF_XF", label: "EF/XF - Extremely fine" },
    { value: "AU", label: "AU - About uncirculated" },
    { value: "UNC", label: "UNC - Uncirculated" },
  ],

  NUMISMATIC_CONSERVATION_FRA: [
    { value: "M", label: "M - Mediocre" },
    { value: "BC", label: "BC - Bien conservée" },
    { value: "AB", label: "AB - Assenz beau" },
    { value: "B", label: "B - Beau" },
    { value: "TB", label: "TB - Tres beau" },
    { value: "TTB", label: "TTB - Tres tres beau" },
    { value: "SUP", label: "SUP - Superbe" },
    { value: "SPL", label: "SPL - Splendid" },
    { value: "NEUF", label: "NEUF - Neuf" },
    { value: "FDC", label: "FDC - Fleur de coin" },
  ],

  NUMISMATIC_CONSERVATION_SPA: [
    { value: "MC", label: "MC - Mal conservada" },
    { value: "RC", label: "RC - Regular conservada" },
    { value: "BC", label: "BC - Bien conservada" },
    { value: "MBC", label: "MBC - Muy bien conservada" },
    { value: "EBC", label: "EBC - Extraordinariamente bien conservada" },
    { value: "SC", label: "SC - Sin circular" },
  ],

  NUMISMATIC_CONSERVATION_GER: [
    { value: "GES", label: "GES - Gering enhalten schiecht" },
    { value: "GE", label: "GE - Gut erhalten" },
    { value: "SGE", label: "SGE - Ser gut erhalter" },
    { value: "S", label: "S - Schon" },
    { value: "SS", label: "SS - Ser schon" },
    { value: "VZ", label: "VZ - Vorzuglich" },
    { value: "ST_BFR", label: "ST/BFR - Stempelglanz bankfrisch" },
  ],

  NUMISMATIC_RARITY_DEGREE: [
    { value: "NC", label: "NC - Not common" },
    { value: "CC", label: "CC - Very common" },
    { value: "C", label: "C - Common" },
    { value: "U", label: "U - Unique" },
    { value: "R6", label: "R6 - Unique survivor" },
    { value: "R5", label: "R5 - Few known" },
    { value: "R4", label: "R4 - Extremely rare" },
    { value: "R3", label: "R3 - Very very rare" },
    { value: "R2", label: "R2 - Very rare" },
    { value: "R", label: "R - Rare" },
  ],

  PHILATELIC_RARITY_DEGREE: [
    { value: "C2", label: "Very_common" },
    { value: "C1", label: "More than 1000 known exemplars" },
    { value: "C", label: "Up to 1000 known exemplars" },
    { value: "R", label: "Up to 500 known exemplars" },
    { value: "R1", label: "Up to 250 known exemplars" },
    { value: "R2", label: "Up to 100 known exemplars" },
    { value: "R3", label: "Up to 50 known exemplars" },
    { value: "R4", label: "Up to 25 known exemplars" },
    { value: "R5", label: "Up to 5 known exemplars" },
    { value: "R6", label: "Probably unique" },
  ],

  MEDIA_TYPE_DOCUMENT_POSITION: 0, // questo serve per individuare in modo sicuro la posizione della voce document nel sottostante MEDIA_TYPES

  MEDIA_TYPES: [
    { value: "DOCUMENT", label: "Document" },
    { value: "IMAGE", label: "Image" },
    { value: "AUDIO_VIDEO", label: "Audio/Video" },
    { value: "FINGERPRINT", label: "Fingerprint" },
  ],

  IMAGE_TYPES: [
    { value: "FRONT", label: "Front" },
    { value: "AUDIO_VIDEO", label: "Rear" },
    { value: "FRONT_DETAIL", label: "Front detail" },
    { value: "REAR_DETAIL", label: "Rear detail" },
    { value: "OTHER", label: "Other" },
  ],

  MATRIX_TYPES: [
    { value: "FRONT", label: "Front" },
    { value: "REAR", label: "Rear" },
    { value: "FRONT_DETAIL", label: "Front Detail" },
    { value: "REAR_DETAIL", label: "Rear Detail" },
    { value: "THREE_D", label: "3D" },
  ],

  ASSET_DOCUMENT_TYPES: [
    { value: "AUTHENTICITY_CERTIFICATE", label: "Authenticity certificate" },
    { value: "ARCHIVING_CERTIFICATE", label: "Archiving certificate" },
    { value: "FINE_ARTS_ISSUE", label: "Fine arts issue" },
    { value: "CUSTOM_DOCUMENT", label: "Custom document" },
    { value: "CATALOG", label: "Catalog" },
    { value: "CONTRACT", label: "Contract" },
    { value: "PUBLICATION", label: "Publication" },
    { value: "OTHER", label: "Other" },
  ],

  ASSET_DOCUMENT_COA_TYPES: [
    { value: "AUTHENTICITY_CERTIFICATE", label: "Authenticity certificate" },
    { value: "COA_SIMILAR", label: "COA Similar" },
  ],

  FINE_ARTS_ISSUE_TYPES: [
    {
      value: "SELF_CERTIFICATION_CONTEMPORARY_ART",
      label: "Self-certification of contemporary art (AAC)",
    },
    {
      value: "TEMPORARY_CIRCULATION_CERTIFICATE",
      label: "Temporary circulation certificate (ACT)",
    },
    { value: "FREE_CIRCULATION_CERTIFICATE", label: "Free circulation certificate (ALC)" },
    { value: "CERTIFICATE_SUCCESSFUL_IMPORT", label: "Certificate of successful import (CAI)" },
    { value: "CERTIFICATE_SHIPMENT", label: "Certificate of shipment (CAS)" },
    { value: "STATEMENT_50_70", label: "Statement 50-70" },
    { value: "STATEMENT_VAL", label: "Statement VAL" },
    { value: "DEFINITIVE_EXPORT_LICENSE", label: "Definitive export license (LCD)" },
    { value: "TEMPORARY_EXPORT_LICENSE", label: "Temporary export license (LCT)" },
    { value: "RENEWAL_CAI", label: "Renewal CAI" },
    { value: "RENEWAL_CAS", label: "Renewal CAS" },
    { value: "EXPIRATION_CAI", label: "Expiration CAI" },
    { value: "EXPIRATION_CAS", label: "Expiration CAS" },
    { value: "OTHER_EXPORT_LICENSES", label: "Other export licenses" },
  ],

  CATEGORY: [
    { value: "sculpture", label: "Sculpture" },
    { value: "painting", label: "Painting" },
    { value: "print_or_photo", label: "Print or photo" },
    { value: "urban", label: "Urban art" },
    { value: "installation", label: "Installation" },
    { value: "digital", label: "Digital" },
    { value: "design_or_antique", label: "Design or antique" },
    { value: "archeology", label: "Archeology" },
    { value: "naturalistic_goods", label: "Naturalistic goods" },
    { value: "numismatic_coins", label: "Numismatic, coins and medals" },
    { value: "numismatic_banknotes", label: "Numismatic and banknotes" },
    { value: "philately", label: "Philately" },
    { value: "books", label: "Books" },
    { value: "not_validated", label: "---" },
    { value: "other", label: "Other" },
  ],
  SUBCATEGORY: [
    {
      value: "sculpture",
      label: "sculpture",
      options: [{ value: "sculpture", label: "Sculpture" }],
    },
    {
      value: "painting",
      label: "Painting",
      options: [
        { value: "painting", label: "Painting" },
        { value: "drawing", label: "Drawing" },
        { value: "artist_proof", label: "Artist Proof" },
        { value: "work_on_paper", label: "Work on Paper" },
        { value: "tapestries", label: "Tapestries" },
        { value: "other_bidimensional_works", label: "Other Bidimensional Works" },
      ],
    },
    {
      value: "print_or_photo",
      label: "Print or photo",
      options: [
        { value: "photography", label: "Photography" },
        { value: "graphic", label: "Graphic" },
        { value: "prints", label: "Prints" },
        { value: "lithography", label: "Lithography" },
        { value: "serigraphy", label: "Serigraphy" },
        { value: "xylography", label: "Xylography" },
      ],
    },
    { value: "urban", label: "Urban", options: [{ value: "urban", label: "Urban art" }] },
    {
      value: "installation",
      label: "Installation",
      options: [{ value: "installation", label: "Installation" }],
    },
    {
      value: "digital",
      label: "Digital work",
      options: [
        { value: "video", label: "Video" },
        { value: "audio", label: "Audio" },
        { value: "static_work", label: "Static work" },
        { value: "dynamic_work", label: "Dynamic work" },
      ],
    },
    {
      value: "design_or_antique",
      label: "Design or antique",
      options: [{ value: "design_or_antique", label: "Design or antique" }],
    },
    {
      value: "archeology",
      label: "Archeology",
      options: [{ value: "archeology", label: "Archeology" }],
    },
    {
      value: "naturalistic_goods",
      label: "Naturalistic goods",
      options: [{ value: "naturalistic_goods", label: "Naturalistic goods" }],
    },
    {
      value: "numismatic_coins",
      label: "Numismatic, coins and medals",
      options: [{ value: "numismatic_coins", label: "Numismatic, coins and medals" }],
    },
    {
      value: "numismatic_banknotes",
      label: "Numismatic and banknotes",
      options: [{ value: "numismatic_banknotes", label: "Numismatic and banknotes" }],
    },
    {
      value: "philately",
      label: "Philately",
      options: [{ value: "philately", label: "Philately" }],
    },
    { value: "books", label: "Books", options: [{ value: "books", label: "Books" }] },
    {
      value: "other",
      label: "Other",
      options: [
        { value: "automobile", label: "Automobile" },
        { value: "jewelry", label: "Jewelry" },
        { value: "wine", label: "Wine" },
        { value: "watches", label: "Watches" },
        { value: "clothes", label: "Clothes" },
        { value: "musical_instruments", label: "Musical instruments" },
        { value: "toys", label: "Toys" },
        { value: "historical_objects", label: "Historical objects" },
        { value: "memorabilia", label: "Memorabilia" },
        { value: "other", label: "Other" },
      ],
    },
    { value: "not_validated", label: "---", options: [{ value: "not_validated", label: "---" }] },
  ],
};
