/* eslint-disable import/prefer-default-export */
import { Badge, Image } from "@chakra-ui/react";
import placeholderPNG from "assets/placeholder.png";
import { BooleanFilter, CountryCell, CreatedByFilter } from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { PolicyAssetNode } from "graphql/queries/generated/queries";
import { BooleanCell } from "helpers/getColumns";
import parseAmount from "helpers/parseAmount";
import { Trans } from "react-i18next";

export const TE_CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,
    disableFilters: true,
  },
  {
    Header: "Certificate Number",
    Footer: "",
    accessor: "certificateProgressiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lender.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Lender country",
    Footer: "",
    accessor: "lenderAddress.country",
    Cell: CountryCell,
    disableFilters: true,
  },
  {
    Header: "Category",
    Footer: "",
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Trans>{value}</Trans>,

    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "authorEntity.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue",
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyAssetNode } };
    }) => {
      const { insuredValue } = original;
      return parseAmount(insuredValue);
    },
  },
  {
    Header: "Dimensions",
    Footer: "",
    accessor: "genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Technique",
    Footer: "",
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Inventory #",
    Footer: "",
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Shipper",
    Footer: "",
    accessor: "shipper.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),

    disableFilters: true,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },

  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Exemption",
    Footer: "",
    accessor: "exemption",
    Cell: BooleanCell,
    sortType: "basic",
    Filter: BooleanFilter,
  },
  // {
  //   Header: "Validated",
  //   Footer: "",
  //   accessor: "validated",
  //   Cell: BooleanCell,
  //   Filter: BooleanFilter,
  // },
  {
    Header: "Natural Disasters",
    Footer: "",
    accessor: "catNat",
    Cell: BooleanCell,
    Filter: BooleanFilter,
  },
  {
    Header: "Cover Terrorism",
    Footer: "",
    accessor: "coverTerrorism",
    Cell: BooleanCell,
    Filter: BooleanFilter,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

export const TE_CUSTOM_FIELDS = TE_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

export const TE_HIDDEN_FIELDS = [
  "pickupAddress",
  "lenderAddress",
  "deliveryAddress",
  // "catNat",
  // "coverTerrorism",
  // "exemption",
  "objectLocationEntity",
  "objectLocationOffice",
  // "shipper",
  "shipperId",
  "creationPeriod",
  "validated",
  "assetId",
  // "asset.actualDateAndPeriod",
  // "asset.createdBy",
];
