import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: string;
  Decimal: number;
  GenericScalar: any;
  StringMoney: string;
  UUID: string;
  Upload: unknown;
};

export type AddAppraisalMediaInput = {
  appraisalId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type AddAppraisalMediaPayload = {
  __typename?: "AddAppraisalMediaPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddAssetMediaInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type AddAssetMediaPayload = {
  __typename?: "AddAssetMediaPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddConditionStatusReportMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportId: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type AddConditionStatusReportMediaPayload = {
  __typename?: "AddConditionStatusReportMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type AddRestorationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
  restorationId: Scalars["ID"];
};

export type AddRestorationMediaPayload = {
  __typename?: "AddRestorationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type AddUserObjectPermissionFromCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type AddUserObjectPermissionFromCollectionPayload = {
  __typename?: "AddUserObjectPermissionFromCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserObjectPermissionInput = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type AddUserObjectPermissionPayload = {
  __typename?: "AddUserObjectPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type AddUserPermissionPayload = {
  __typename?: "AddUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type AddUserSignaturePayload = {
  __typename?: "AddUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  userSignature?: Maybe<UserSignatureType>;
};

export type Address = {
  __typename?: "Address";
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  formatted?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  postalCode?: Maybe<Scalars["String"]>;
  raw?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateCode?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  country?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  formatted?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["String"]>;
  route?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  stateCode?: InputMaybe<Scalars["String"]>;
  streetNumber?: InputMaybe<Scalars["String"]>;
};

export type AppraisalInput = {
  appraisalAsset?: InputMaybe<Scalars["ID"]>;
  appraisalConservationStatus?: InputMaybe<Scalars["String"]>;
  appraisalDate?: InputMaybe<Scalars["DateTime"]>;
  appraisalEvaluator: Scalars["ID"];
  appraisalLocation?: InputMaybe<Scalars["String"]>;
  appraisalMotivation: Scalars["String"];
  appraisalStatus: Scalars["String"];
  location?: InputMaybe<AddressInput>;
  valueCarryingValue?: InputMaybe<MoneyInput>;
  valueEstimatedValue?: InputMaybe<MoneyInput>;
  valueInformationAuthorCoefficient?: InputMaybe<Scalars["String"]>;
  valueInformationInfo?: InputMaybe<Scalars["String"]>;
  valueInformationNote?: InputMaybe<Scalars["String"]>;
  valueInformationQuotesSimilarAssets?: InputMaybe<Scalars["String"]>;
};

export type AppraisalNode = Node & {
  __typename?: "AppraisalNode";
  actualMediaStatus?: Maybe<Scalars["String"]>;
  appraisalAsset: AssetNode;
  appraisalConservationStatus?: Maybe<Scalars["String"]>;
  appraisalDate?: Maybe<Scalars["DateTime"]>;
  appraisalEvaluator: RegistryNode;
  appraisalLocation?: Maybe<Scalars["String"]>;
  appraisalMotivation?: Maybe<Scalars["String"]>;
  appraisalStatus?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  location?: Maybe<Address>;
  media: MediaNodeConnection;
  mediaNumber?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  valueCarryingValue?: Maybe<Money>;
  valueCarryingValueCurrency: ManagementAppraisalValueCarryingValueCurrencyChoices;
  valueEstimatedValue?: Maybe<Money>;
  valueEstimatedValueCurrency: ManagementAppraisalValueEstimatedValueCurrencyChoices;
  valueInformationAuthorCoefficient?: Maybe<Scalars["String"]>;
  valueInformationInfo?: Maybe<Scalars["String"]>;
  valueInformationNote?: Maybe<Scalars["String"]>;
  valueInformationQuotesSimilarAssets?: Maybe<Scalars["String"]>;
};

export type AppraisalNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AppraisalNodeConnection = {
  __typename?: "AppraisalNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppraisalNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AppraisalNode` and its cursor. */
export type AppraisalNodeEdge = {
  __typename?: "AppraisalNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AppraisalNode>;
};

export type AssetDocumentAttachmentInput = {
  assetDocumentId: Scalars["ID"];
  file?: InputMaybe<Scalars["Upload"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type AssetDocumentAttachmentNode = Node & {
  __typename?: "AssetDocumentAttachmentNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  document?: Maybe<AssetDocumentNode>;
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type AssetDocumentAttachmentNodeConnection = {
  __typename?: "AssetDocumentAttachmentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetDocumentAttachmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetDocumentAttachmentNode` and its cursor. */
export type AssetDocumentAttachmentNodeEdge = {
  __typename?: "AssetDocumentAttachmentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetDocumentAttachmentNode>;
};

export type AssetDocumentInput = {
  archiveNumber?: InputMaybe<Scalars["String"]>;
  assetId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<InputMaybe<AssetDocumentAttachmentInput>>>;
  coaType?: InputMaybe<Scalars["String"]>;
  contractSubscriber?: InputMaybe<Scalars["String"]>;
  curator?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  documentType?: InputMaybe<Scalars["String"]>;
  editor?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["Date"]>;
  fineArtsIssueType?: InputMaybe<Scalars["String"]>;
  isClassified?: InputMaybe<Scalars["Boolean"]>;
  isCriticalCatalog?: InputMaybe<Scalars["Boolean"]>;
  isTemporaryExport?: InputMaybe<Scalars["Boolean"]>;
  isTemporaryImport?: InputMaybe<Scalars["Boolean"]>;
  issuer?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  otherDocumentType?: InputMaybe<Scalars["String"]>;
  publishingDate?: InputMaybe<Scalars["Date"]>;
  recipient?: InputMaybe<Scalars["String"]>;
  releaseDate?: InputMaybe<Scalars["Date"]>;
  subscriptionDate?: InputMaybe<Scalars["Date"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type AssetDocumentNode = Node & {
  __typename?: "AssetDocumentNode";
  archiveNumber?: Maybe<Scalars["String"]>;
  asset?: Maybe<AssetNode>;
  attachments: AssetDocumentAttachmentNodeConnection;
  coaType?: Maybe<Scalars["String"]>;
  contractSubscriber?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  curator?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  documentType?: Maybe<Scalars["String"]>;
  editor?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["Date"]>;
  fineArtsIssueType?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isClassified?: Maybe<Scalars["Boolean"]>;
  isCriticalCatalog?: Maybe<Scalars["Boolean"]>;
  isTemporaryExport?: Maybe<Scalars["Boolean"]>;
  isTemporaryImport?: Maybe<Scalars["Boolean"]>;
  issuer?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  otherDocumentType?: Maybe<Scalars["String"]>;
  publishingDate?: Maybe<Scalars["Date"]>;
  recipient?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Date"]>;
  subscriptionDate?: Maybe<Scalars["Date"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type AssetDocumentNodeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetDocumentNodeConnection = {
  __typename?: "AssetDocumentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetDocumentNode` and its cursor. */
export type AssetDocumentNodeEdge = {
  __typename?: "AssetDocumentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetDocumentNode>;
};

export type AssetElementInput = {
  assetId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetElementNode = Node & {
  __typename?: "AssetElementNode";
  asset?: Maybe<AssetNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  materials?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type AssetElementNodeConnection = {
  __typename?: "AssetElementNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetElementNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetElementNode` and its cursor. */
export type AssetElementNodeEdge = {
  __typename?: "AssetElementNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetElementNode>;
};

export type AssetInput = {
  archiveNumber?: InputMaybe<Scalars["String"]>;
  artistProofNotes?: InputMaybe<Scalars["String"]>;
  assetPresentValues?: InputMaybe<Array<InputMaybe<AssetPresentValueInput>>>;
  audioVideoLength?: InputMaybe<Scalars["Float"]>;
  audioVideoLengthUnit?: InputMaybe<Scalars["String"]>;
  authorEntityId: Scalars["ID"];
  banknotePrinter?: InputMaybe<Scalars["String"]>;
  barriersDetails?: InputMaybe<Scalars["String"]>;
  baseDetails?: InputMaybe<Scalars["String"]>;
  bibliographyNotes?: InputMaybe<Scalars["String"]>;
  bookContainer?: InputMaybe<Scalars["String"]>;
  bookbinding?: InputMaybe<Scalars["String"]>;
  carat?: InputMaybe<Scalars["String"]>;
  carryingValueAcquisition?: InputMaybe<Scalars["String"]>;
  carryingValueAmount?: InputMaybe<MoneyInput>;
  carryingValueFile?: InputMaybe<Scalars["Upload"]>;
  carryingValueFileTitle?: InputMaybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: InputMaybe<Scalars["Date"]>;
  carryingValueNotes?: InputMaybe<Scalars["String"]>;
  carryingValueOrigin?: InputMaybe<Scalars["String"]>;
  catalogNumber?: InputMaybe<Scalars["String"]>;
  catalogingAuthor?: InputMaybe<Scalars["String"]>;
  category: Scalars["String"];
  centuryEndDate?: InputMaybe<Scalars["Int"]>;
  centuryEndDateWindow?: InputMaybe<Scalars["String"]>;
  centuryStartDate?: InputMaybe<Scalars["Int"]>;
  centuryStartDateWindow?: InputMaybe<Scalars["String"]>;
  circulation?: InputMaybe<Scalars["String"]>;
  classificationEng?: InputMaybe<Scalars["String"]>;
  classificationFra?: InputMaybe<Scalars["String"]>;
  classificationGer?: InputMaybe<Scalars["String"]>;
  classificationIta?: InputMaybe<Scalars["String"]>;
  classificationPhilatelic?: InputMaybe<Scalars["String"]>;
  classificationSpa?: InputMaybe<Scalars["String"]>;
  classificationUsa?: InputMaybe<Scalars["String"]>;
  clips?: InputMaybe<Scalars["String"]>;
  clothCovering?: InputMaybe<Scalars["String"]>;
  coinCirculationPeriod?: InputMaybe<Scalars["String"]>;
  coinComposition?: InputMaybe<Scalars["String"]>;
  coinIdentificationCode?: InputMaybe<Scalars["String"]>;
  coinIssueReason?: InputMaybe<Scalars["String"]>;
  coinMintIssuer?: InputMaybe<Scalars["String"]>;
  coinPeriod?: InputMaybe<Scalars["String"]>;
  coinPlacement?: InputMaybe<Scalars["String"]>;
  coinPlacementNotes?: InputMaybe<Scalars["String"]>;
  coinTitle?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  coloring?: InputMaybe<Scalars["String"]>;
  commonName?: InputMaybe<Scalars["String"]>;
  compositionDescription?: InputMaybe<Scalars["String"]>;
  conservation?: InputMaybe<Scalars["String"]>;
  conservationMethod?: InputMaybe<Scalars["String"]>;
  conservationState?: InputMaybe<Scalars["String"]>;
  containerDetails?: InputMaybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: InputMaybe<Scalars["String"]>;
  corner?: InputMaybe<Scalars["String"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  creationDateNotes?: InputMaybe<Scalars["String"]>;
  creationPeriod?: InputMaybe<Scalars["String"]>;
  criticalCatalogue?: InputMaybe<Scalars["Boolean"]>;
  currency?: InputMaybe<Scalars["String"]>;
  currentValue?: InputMaybe<Scalars["Float"]>;
  dateEndDate?: InputMaybe<Scalars["Date"]>;
  dateStartDate?: InputMaybe<Scalars["Date"]>;
  datedObjectNotes?: InputMaybe<Scalars["String"]>;
  datingType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  discoveryAuthor?: InputMaybe<Scalars["String"]>;
  discoveryDate?: InputMaybe<Scalars["Date"]>;
  discoveryNotes?: InputMaybe<Scalars["String"]>;
  discoveryPlace?: InputMaybe<Scalars["String"]>;
  discoverySite?: InputMaybe<Scalars["String"]>;
  distribution?: InputMaybe<Scalars["String"]>;
  dustJacket?: InputMaybe<Scalars["String"]>;
  dynamicNotes?: InputMaybe<Scalars["String"]>;
  edge?: InputMaybe<Scalars["String"]>;
  editionNotes?: InputMaybe<Scalars["String"]>;
  enviromentalConditionsNotes?: InputMaybe<Scalars["String"]>;
  externalDecorations?: InputMaybe<Scalars["String"]>;
  fileSize?: InputMaybe<Scalars["Float"]>;
  fileSizeUnit?: InputMaybe<Scalars["String"]>;
  finishing?: InputMaybe<Scalars["String"]>;
  firstDraftNotes?: InputMaybe<Scalars["String"]>;
  fiscalValue?: InputMaybe<Scalars["Float"]>;
  flyleaf?: InputMaybe<Scalars["String"]>;
  footEdge?: InputMaybe<Scalars["String"]>;
  foreEdge?: InputMaybe<Scalars["String"]>;
  frameDescription?: InputMaybe<Scalars["String"]>;
  frameManufacturer?: InputMaybe<Scalars["String"]>;
  frameMaterial?: InputMaybe<Scalars["String"]>;
  frontCover?: InputMaybe<Scalars["String"]>;
  glueing?: InputMaybe<Scalars["String"]>;
  grossDepth?: InputMaybe<Scalars["Float"]>;
  grossDiameter?: InputMaybe<Scalars["Float"]>;
  grossDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  grossHeight?: InputMaybe<Scalars["Float"]>;
  grossLengthUnit?: InputMaybe<Scalars["String"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnit?: InputMaybe<Scalars["String"]>;
  grossWidth?: InputMaybe<Scalars["Float"]>;
  handlingRequirements?: InputMaybe<Scalars["String"]>;
  hardwareToolsInstallation?: InputMaybe<Scalars["String"]>;
  hasBarriers?: InputMaybe<Scalars["Boolean"]>;
  hasBase?: InputMaybe<Scalars["Boolean"]>;
  hasContainer?: InputMaybe<Scalars["Boolean"]>;
  hasFrame?: InputMaybe<Scalars["Boolean"]>;
  hasFullDatetime?: InputMaybe<Scalars["Boolean"]>;
  hasHumanAnimalPresence?: InputMaybe<Scalars["Boolean"]>;
  hasLabelsStamps?: InputMaybe<Scalars["Boolean"]>;
  hasLoop?: InputMaybe<Scalars["Boolean"]>;
  hasOriginalFrame?: InputMaybe<Scalars["Boolean"]>;
  hasOtherAutographed?: InputMaybe<Scalars["Boolean"]>;
  hasPeriod?: InputMaybe<Scalars["Boolean"]>;
  hasWoodFrame?: InputMaybe<Scalars["Boolean"]>;
  hasYear?: InputMaybe<Scalars["Boolean"]>;
  headband?: InputMaybe<Scalars["String"]>;
  headcap?: InputMaybe<Scalars["String"]>;
  holographicStripe?: InputMaybe<Scalars["String"]>;
  humanAnimalPresenceNotes?: InputMaybe<Scalars["String"]>;
  infoNotes?: InputMaybe<Scalars["String"]>;
  injuryRisk?: InputMaybe<Scalars["String"]>;
  injuryRiskDescription?: InputMaybe<Scalars["String"]>;
  interiorDecorations?: InputMaybe<Scalars["String"]>;
  internationalCode?: InputMaybe<Scalars["String"]>;
  inventoryNumber?: InputMaybe<Scalars["String"]>;
  isArtMultiple?: InputMaybe<Scalars["Boolean"]>;
  isArtistProof?: InputMaybe<Scalars["Boolean"]>;
  isAudio?: InputMaybe<Scalars["Boolean"]>;
  isCenturyApprox?: InputMaybe<Scalars["Boolean"]>;
  isCenturyEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isCenturyInterval?: InputMaybe<Scalars["Boolean"]>;
  isCenturyStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isComposed?: InputMaybe<Scalars["Boolean"]>;
  isDateApprox?: InputMaybe<Scalars["Boolean"]>;
  isDateEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDateInterval?: InputMaybe<Scalars["Boolean"]>;
  isDateStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDatedObject?: InputMaybe<Scalars["Boolean"]>;
  isDynamic?: InputMaybe<Scalars["Boolean"]>;
  isEdition?: InputMaybe<Scalars["Boolean"]>;
  isFirstDraft?: InputMaybe<Scalars["Boolean"]>;
  isMaster?: InputMaybe<Scalars["Boolean"]>;
  isNotifiedCulturalProperty?: InputMaybe<Scalars["Boolean"]>;
  isSignedObject?: InputMaybe<Scalars["Boolean"]>;
  isTitledObject?: InputMaybe<Scalars["Boolean"]>;
  isValuableFrame?: InputMaybe<Scalars["Boolean"]>;
  isVideo?: InputMaybe<Scalars["Boolean"]>;
  isYearApprox?: InputMaybe<Scalars["Boolean"]>;
  isYearEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isYearInterval?: InputMaybe<Scalars["Boolean"]>;
  isYearStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  label?: InputMaybe<Scalars["String"]>;
  labelsStampsNotes?: InputMaybe<Scalars["String"]>;
  legalStatus?: InputMaybe<Scalars["String"]>;
  legend?: InputMaybe<Scalars["String"]>;
  lenderRequests?: InputMaybe<Scalars["String"]>;
  lightUnit?: InputMaybe<Scalars["String"]>;
  manufactoringTechniques?: InputMaybe<Scalars["String"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]>;
  masterLocation?: InputMaybe<Scalars["String"]>;
  material?: InputMaybe<Scalars["String"]>;
  mechanism?: InputMaybe<Scalars["String"]>;
  methodOfUse?: InputMaybe<Scalars["String"]>;
  mintMadeErrors?: InputMaybe<Scalars["String"]>;
  mintingLastDate?: InputMaybe<Scalars["Date"]>;
  mintingLastDateNotes?: InputMaybe<Scalars["String"]>;
  mouldingSupportBase?: InputMaybe<Scalars["String"]>;
  multipleNumber?: InputMaybe<Scalars["String"]>;
  netDepth?: InputMaybe<Scalars["Float"]>;
  netDiameter?: InputMaybe<Scalars["Float"]>;
  netDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  netHeight?: InputMaybe<Scalars["Float"]>;
  netLengthUnit?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnit?: InputMaybe<Scalars["String"]>;
  netWidth?: InputMaybe<Scalars["Float"]>;
  nft?: InputMaybe<Scalars["Boolean"]>;
  nftNotes?: InputMaybe<Scalars["String"]>;
  nominalValue?: InputMaybe<Scalars["Float"]>;
  notifiedPropertyNote?: InputMaybe<Scalars["String"]>;
  numberOfElements?: InputMaybe<Scalars["Int"]>;
  objectSetupAuthor?: InputMaybe<Scalars["String"]>;
  optimalHumidity?: InputMaybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: InputMaybe<Scalars["Int"]>;
  optimalTemperature?: InputMaybe<Scalars["Int"]>;
  orientation?: InputMaybe<Scalars["String"]>;
  originNotes?: InputMaybe<Scalars["String"]>;
  otherAutographedNotes?: InputMaybe<Scalars["String"]>;
  otherModel?: InputMaybe<Scalars["String"]>;
  paperType?: InputMaybe<Scalars["String"]>;
  physicalFeaturesElements?: InputMaybe<Array<InputMaybe<AssetElementInput>>>;
  placement?: InputMaybe<Scalars["String"]>;
  postageMark?: InputMaybe<Scalars["String"]>;
  printerId?: InputMaybe<Scalars["ID"]>;
  printingErrors?: InputMaybe<Scalars["String"]>;
  printingLastDate?: InputMaybe<Scalars["Date"]>;
  printingLastDateNotes?: InputMaybe<Scalars["String"]>;
  printingType?: InputMaybe<Scalars["String"]>;
  producerId?: InputMaybe<Scalars["ID"]>;
  productionMode?: InputMaybe<Scalars["String"]>;
  raisedBands?: InputMaybe<Scalars["String"]>;
  rarityDegree?: InputMaybe<Scalars["String"]>;
  rarityDegreeNotes?: InputMaybe<Scalars["String"]>;
  rarityDegreePhilatelic?: InputMaybe<Scalars["String"]>;
  rearCover?: InputMaybe<Scalars["String"]>;
  relatedPhysicalWork?: InputMaybe<Scalars["String"]>;
  relevantAwards?: InputMaybe<Scalars["String"]>;
  relief?: InputMaybe<Scalars["String"]>;
  reminting?: InputMaybe<Scalars["String"]>;
  replacedParts?: InputMaybe<Scalars["String"]>;
  reprinting?: InputMaybe<Scalars["String"]>;
  resolution?: InputMaybe<Scalars["Float"]>;
  resolutionUnit?: InputMaybe<Scalars["String"]>;
  rim?: InputMaybe<Scalars["String"]>;
  rotationAxes?: InputMaybe<Scalars["String"]>;
  rubber?: InputMaybe<Scalars["String"]>;
  scientificName?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["String"]>;
  securityThread?: InputMaybe<Scalars["String"]>;
  series?: InputMaybe<Scalars["String"]>;
  seriesDetails?: InputMaybe<Scalars["String"]>;
  settingNotes?: InputMaybe<Scalars["String"]>;
  shape?: InputMaybe<Scalars["String"]>;
  signedObjectNotes?: InputMaybe<Scalars["String"]>;
  signings?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  slots?: InputMaybe<Scalars["String"]>;
  spine?: InputMaybe<Scalars["String"]>;
  square?: InputMaybe<Scalars["String"]>;
  stampCenteringAndMargins?: InputMaybe<Scalars["String"]>;
  stampFormat?: InputMaybe<Scalars["String"]>;
  stampIndentationType?: InputMaybe<Scalars["String"]>;
  stampPrintingTechnique?: InputMaybe<Scalars["String"]>;
  stampRealization?: InputMaybe<Scalars["String"]>;
  stateOfConservation?: InputMaybe<Scalars["String"]>;
  subcategory: Scalars["String"];
  surfaceCharacteristics?: InputMaybe<Scalars["String"]>;
  taxonomyClass?: InputMaybe<Scalars["String"]>;
  taxonomyDomain?: InputMaybe<Scalars["String"]>;
  taxonomyFamily?: InputMaybe<Scalars["String"]>;
  taxonomyGenus?: InputMaybe<Scalars["String"]>;
  taxonomyKingdom?: InputMaybe<Scalars["String"]>;
  taxonomyNotes?: InputMaybe<Scalars["String"]>;
  taxonomyOrder?: InputMaybe<Scalars["String"]>;
  taxonomyPhylum?: InputMaybe<Scalars["String"]>;
  taxonomyTribe?: InputMaybe<Scalars["String"]>;
  technique?: InputMaybe<Scalars["String"]>;
  temperatureUnit?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
  titledObjectNotes?: InputMaybe<Scalars["String"]>;
  topEdge?: InputMaybe<Scalars["String"]>;
  usage?: InputMaybe<Scalars["String"]>;
  validated: Scalars["Boolean"];
  variants?: InputMaybe<Scalars["String"]>;
  videomakerId?: InputMaybe<Scalars["ID"]>;
  watermark?: InputMaybe<Scalars["String"]>;
  woodFrameDetails?: InputMaybe<Scalars["String"]>;
  yearEndDate?: InputMaybe<Scalars["Int"]>;
  yearStartDate?: InputMaybe<Scalars["Int"]>;
};

export type AssetNode = Node & {
  __typename?: "AssetNode";
  actualDateAndPeriod?: Maybe<Scalars["String"]>;
  actualValue?: Maybe<Money>;
  appraisalAsset: AppraisalNodeConnection;
  archiveNumber?: Maybe<Scalars["String"]>;
  artistProofNotes?: Maybe<Scalars["String"]>;
  assetCurrentValue?: Maybe<Scalars["String"]>;
  audioVideoLength?: Maybe<Scalars["Float"]>;
  audioVideoLengthUnit?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  authorEntity?: Maybe<RegistryNode>;
  banknotePrinter?: Maybe<Scalars["String"]>;
  barriersDetails?: Maybe<Scalars["String"]>;
  baseDetails?: Maybe<Scalars["String"]>;
  bibliographyNotes?: Maybe<Scalars["String"]>;
  bookContainer?: Maybe<Scalars["String"]>;
  bookbinding?: Maybe<Scalars["String"]>;
  carat?: Maybe<Scalars["String"]>;
  carryingValueAcquisition?: Maybe<Scalars["String"]>;
  carryingValueAmount?: Maybe<Money>;
  carryingValueAmountCurrency: Scalars["String"];
  carryingValueFile?: Maybe<Scalars["String"]>;
  carryingValueFileTitle?: Maybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: Maybe<Scalars["Date"]>;
  carryingValueNotes?: Maybe<Scalars["String"]>;
  carryingValueOrigin?: Maybe<Scalars["String"]>;
  catalogNumber?: Maybe<Scalars["String"]>;
  catalogingAuthor?: Maybe<Scalars["String"]>;
  category: Scalars["String"];
  centuryEndDate?: Maybe<Scalars["Int"]>;
  centuryEndDateWindow?: Maybe<Scalars["String"]>;
  centuryStartDate?: Maybe<Scalars["Int"]>;
  centuryStartDateWindow?: Maybe<Scalars["String"]>;
  circulation?: Maybe<Scalars["String"]>;
  classificationEng?: Maybe<Scalars["String"]>;
  classificationFra?: Maybe<Scalars["String"]>;
  classificationGer?: Maybe<Scalars["String"]>;
  classificationIta?: Maybe<Scalars["String"]>;
  classificationPhilatelic?: Maybe<Scalars["String"]>;
  classificationSpa?: Maybe<Scalars["String"]>;
  classificationUsa?: Maybe<Scalars["String"]>;
  clips?: Maybe<Scalars["String"]>;
  clothCovering?: Maybe<Scalars["String"]>;
  coinCirculationPeriod?: Maybe<Scalars["String"]>;
  coinComposition?: Maybe<Scalars["String"]>;
  coinIdentificationCode?: Maybe<Scalars["String"]>;
  coinIssueReason?: Maybe<Scalars["String"]>;
  coinMintIssuer?: Maybe<Scalars["String"]>;
  coinPeriod?: Maybe<Scalars["String"]>;
  coinPlacement?: Maybe<Scalars["String"]>;
  coinPlacementNotes?: Maybe<Scalars["String"]>;
  coinTitle?: Maybe<Scalars["String"]>;
  collection: CollectionNodeConnection;
  color?: Maybe<Scalars["String"]>;
  coloring?: Maybe<Scalars["String"]>;
  commonName?: Maybe<Scalars["String"]>;
  compositionDescription?: Maybe<Scalars["String"]>;
  conditionstatusreportReportAsset: ConditionStatusReportNodeConnection;
  conservation?: Maybe<Scalars["String"]>;
  conservationMethod?: Maybe<Scalars["String"]>;
  conservationState?: Maybe<Scalars["String"]>;
  containerDetails?: Maybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: Maybe<Scalars["String"]>;
  corner?: Maybe<Scalars["String"]>;
  coverType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  creationDate?: Maybe<Scalars["DateTime"]>;
  creationDateNotes?: Maybe<Scalars["String"]>;
  creationPeriod?: Maybe<Scalars["String"]>;
  criticalCatalogue: Scalars["Boolean"];
  currency?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["Decimal"]>;
  dateAndPeriod?: Maybe<Scalars["String"]>;
  dateEndDate?: Maybe<Scalars["Date"]>;
  dateStartDate?: Maybe<Scalars["Date"]>;
  datedObjectNotes?: Maybe<Scalars["String"]>;
  datingType?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  dimensionsNotes?: Maybe<Scalars["String"]>;
  discoveryAuthor?: Maybe<Scalars["String"]>;
  discoveryDate?: Maybe<Scalars["Date"]>;
  discoveryNotes?: Maybe<Scalars["String"]>;
  discoveryPlace?: Maybe<Scalars["String"]>;
  discoverySite?: Maybe<Scalars["String"]>;
  distribution?: Maybe<Scalars["String"]>;
  documents: AssetDocumentNodeConnection;
  dustJacket?: Maybe<Scalars["String"]>;
  dynamicNotes?: Maybe<Scalars["String"]>;
  edge?: Maybe<Scalars["String"]>;
  editionNotes?: Maybe<Scalars["String"]>;
  enviromentalConditionsNotes?: Maybe<Scalars["String"]>;
  eventLogs: EventLogNodeConnection;
  exhibition: ExhibitionNodeConnection;
  exportedDocument?: Maybe<Scalars["String"]>;
  externalDecorations?: Maybe<Scalars["String"]>;
  fileSize?: Maybe<Scalars["Float"]>;
  fileSizeUnit?: Maybe<Scalars["String"]>;
  fineness?: Maybe<Scalars["String"]>;
  finishing?: Maybe<Scalars["String"]>;
  firstDraftNotes?: Maybe<Scalars["String"]>;
  fiscalValue?: Maybe<Scalars["Decimal"]>;
  flyleaf?: Maybe<Scalars["String"]>;
  footEdge?: Maybe<Scalars["String"]>;
  foreEdge?: Maybe<Scalars["String"]>;
  frameDescription?: Maybe<Scalars["String"]>;
  frameManufacturer?: Maybe<Scalars["String"]>;
  frameMaterial?: Maybe<Scalars["String"]>;
  frontCover?: Maybe<Scalars["String"]>;
  genericDimensions?: Maybe<Scalars["String"]>;
  glueing?: Maybe<Scalars["String"]>;
  grossDepth?: Maybe<Scalars["Float"]>;
  grossDiameter?: Maybe<Scalars["Float"]>;
  grossDimensionsAcquisition?: Maybe<Scalars["String"]>;
  grossHeight?: Maybe<Scalars["Float"]>;
  grossLengthUnit?: Maybe<Scalars["String"]>;
  grossWeight?: Maybe<Scalars["Float"]>;
  grossWeightUnit?: Maybe<Scalars["String"]>;
  grossWidth?: Maybe<Scalars["Float"]>;
  handlingRequirements?: Maybe<Scalars["String"]>;
  hardwareToolsInstallation?: Maybe<Scalars["String"]>;
  hasBarriers?: Maybe<Scalars["Boolean"]>;
  hasBase?: Maybe<Scalars["Boolean"]>;
  hasContainer?: Maybe<Scalars["Boolean"]>;
  hasFrame?: Maybe<Scalars["Boolean"]>;
  hasFullDatetime: Scalars["Boolean"];
  hasHumanAnimalPresence?: Maybe<Scalars["Boolean"]>;
  hasLabelsStamps?: Maybe<Scalars["Boolean"]>;
  hasLoop?: Maybe<Scalars["Boolean"]>;
  hasOriginalFrame?: Maybe<Scalars["Boolean"]>;
  hasOtherAutographed?: Maybe<Scalars["Boolean"]>;
  hasPeriod: Scalars["Boolean"];
  hasWoodFrame?: Maybe<Scalars["Boolean"]>;
  hasYear: Scalars["Boolean"];
  headband?: Maybe<Scalars["String"]>;
  headcap?: Maybe<Scalars["String"]>;
  holographicStripe?: Maybe<Scalars["String"]>;
  humanAnimalPresenceNotes?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  infoNotes?: Maybe<Scalars["String"]>;
  injuryRisk?: Maybe<Scalars["String"]>;
  injuryRiskDescription?: Maybe<Scalars["String"]>;
  interiorDecorations?: Maybe<Scalars["String"]>;
  internationalCode?: Maybe<Scalars["String"]>;
  inventoryNumber?: Maybe<Scalars["String"]>;
  isArtMultiple?: Maybe<Scalars["Boolean"]>;
  isArtistProof?: Maybe<Scalars["Boolean"]>;
  isAudio?: Maybe<Scalars["Boolean"]>;
  isCenturyApprox: Scalars["Boolean"];
  isCenturyEndDateBc: Scalars["Boolean"];
  isCenturyInterval: Scalars["Boolean"];
  isCenturyStartDateBc: Scalars["Boolean"];
  isComposed?: Maybe<Scalars["Boolean"]>;
  isDateApprox: Scalars["Boolean"];
  isDateEndDateBc: Scalars["Boolean"];
  isDateInterval: Scalars["Boolean"];
  isDateStartDateBc: Scalars["Boolean"];
  isDatedObject?: Maybe<Scalars["Boolean"]>;
  isDynamic?: Maybe<Scalars["Boolean"]>;
  isEdition?: Maybe<Scalars["Boolean"]>;
  isFirstDraft?: Maybe<Scalars["Boolean"]>;
  isMaster?: Maybe<Scalars["Boolean"]>;
  isNotifiedCulturalProperty?: Maybe<Scalars["Boolean"]>;
  isSignedObject?: Maybe<Scalars["Boolean"]>;
  isTitledObject?: Maybe<Scalars["Boolean"]>;
  isValuableFrame?: Maybe<Scalars["Boolean"]>;
  isVideo?: Maybe<Scalars["Boolean"]>;
  isYearApprox: Scalars["Boolean"];
  isYearEndDateBc: Scalars["Boolean"];
  isYearInterval: Scalars["Boolean"];
  isYearStartDateBc: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  labelsStampsNotes?: Maybe<Scalars["String"]>;
  legalStatus?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  lenderRequests?: Maybe<Scalars["String"]>;
  lightUnit?: Maybe<Scalars["String"]>;
  mainImage?: Maybe<Scalars["String"]>;
  manufactoringTechniques?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<RegistryNode>;
  masterLocation?: Maybe<Scalars["String"]>;
  material?: Maybe<Scalars["String"]>;
  mechanism?: Maybe<Scalars["String"]>;
  media: MediaNodeConnection;
  methodOfUse?: Maybe<Scalars["String"]>;
  mintMadeErrors?: Maybe<Scalars["String"]>;
  mintingLastDate?: Maybe<Scalars["Date"]>;
  mintingLastDateNotes?: Maybe<Scalars["String"]>;
  mouldingSupportBase?: Maybe<Scalars["String"]>;
  multipleNumber?: Maybe<Scalars["String"]>;
  netDepth?: Maybe<Scalars["Float"]>;
  netDiameter?: Maybe<Scalars["Float"]>;
  netDimensionsAcquisition?: Maybe<Scalars["String"]>;
  netHeight?: Maybe<Scalars["Float"]>;
  netLengthUnit?: Maybe<Scalars["String"]>;
  netWeight?: Maybe<Scalars["Float"]>;
  netWeightUnit?: Maybe<Scalars["String"]>;
  netWidth?: Maybe<Scalars["Float"]>;
  nft?: Maybe<Scalars["Boolean"]>;
  nftNotes?: Maybe<Scalars["String"]>;
  nominalValue?: Maybe<Scalars["Decimal"]>;
  notifiedPropertyNote?: Maybe<Scalars["String"]>;
  numberOfElements?: Maybe<Scalars["Int"]>;
  objectSetupAuthor?: Maybe<Scalars["String"]>;
  optimalHumidity?: Maybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: Maybe<Scalars["Int"]>;
  optimalTemperature?: Maybe<Scalars["Int"]>;
  orientation?: Maybe<Scalars["String"]>;
  originNotes?: Maybe<Scalars["String"]>;
  otherAutographedNotes?: Maybe<Scalars["String"]>;
  otherModel?: Maybe<Scalars["String"]>;
  owner?: Maybe<RegistryNode>;
  paperType?: Maybe<Scalars["String"]>;
  physicalFeaturesElements: AssetElementNodeConnection;
  placement?: Maybe<Scalars["String"]>;
  policyAsset: PolicyAssetNodeConnection;
  policyAssetHistoric: PolicyAssetBasketHistoryNodeConnection;
  policySet: PolicyNodeConnection;
  postageMark?: Maybe<Scalars["String"]>;
  presentValues: AssetPresentValueNodeConnection;
  printer?: Maybe<RegistryNode>;
  printingErrors?: Maybe<Scalars["String"]>;
  printingLastDate?: Maybe<Scalars["Date"]>;
  printingLastDateNotes?: Maybe<Scalars["String"]>;
  printingType?: Maybe<Scalars["String"]>;
  producer?: Maybe<RegistryNode>;
  productionMode?: Maybe<Scalars["String"]>;
  raisedBands?: Maybe<Scalars["String"]>;
  rarityDegree?: Maybe<Scalars["String"]>;
  rarityDegreeNotes?: Maybe<Scalars["String"]>;
  rarityDegreePhilatelic?: Maybe<Scalars["String"]>;
  rearCover?: Maybe<Scalars["String"]>;
  relatedPhysicalWork?: Maybe<Scalars["String"]>;
  relevantAwards?: Maybe<Scalars["String"]>;
  relief?: Maybe<Scalars["String"]>;
  reminting?: Maybe<Scalars["String"]>;
  replacedParts?: Maybe<Scalars["String"]>;
  reprinting?: Maybe<Scalars["String"]>;
  resolution?: Maybe<Scalars["Float"]>;
  resolutionUnit?: Maybe<Scalars["String"]>;
  restorationAsset: RestorationNodeConnection;
  rim?: Maybe<Scalars["String"]>;
  rotationAxes?: Maybe<Scalars["String"]>;
  rubber?: Maybe<Scalars["String"]>;
  scientificName?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["String"]>;
  securityThread?: Maybe<Scalars["String"]>;
  series?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<Scalars["String"]>;
  settingNotes?: Maybe<Scalars["String"]>;
  shape?: Maybe<Scalars["String"]>;
  signedObjectNotes?: Maybe<Scalars["String"]>;
  signings?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  slots?: Maybe<Scalars["String"]>;
  spine?: Maybe<Scalars["String"]>;
  square?: Maybe<Scalars["String"]>;
  stampCenteringAndMargins?: Maybe<Scalars["String"]>;
  stampFormat?: Maybe<Scalars["String"]>;
  stampIndentationType?: Maybe<Scalars["String"]>;
  stampPrintingTechnique?: Maybe<Scalars["String"]>;
  stampRealization?: Maybe<Scalars["String"]>;
  stateOfConservation?: Maybe<Scalars["String"]>;
  subcategory: Scalars["String"];
  surfaceCharacteristics?: Maybe<Scalars["String"]>;
  taxonomyClass?: Maybe<Scalars["String"]>;
  taxonomyDomain?: Maybe<Scalars["String"]>;
  taxonomyFamily?: Maybe<Scalars["String"]>;
  taxonomyGenus?: Maybe<Scalars["String"]>;
  taxonomyKingdom?: Maybe<Scalars["String"]>;
  taxonomyNotes?: Maybe<Scalars["String"]>;
  taxonomyOrder?: Maybe<Scalars["String"]>;
  taxonomyPhylum?: Maybe<Scalars["String"]>;
  taxonomyTribe?: Maybe<Scalars["String"]>;
  technique?: Maybe<Scalars["String"]>;
  temperatureUnit?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  titledObjectNotes?: Maybe<Scalars["String"]>;
  topEdge?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  usage?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  validated: Scalars["Boolean"];
  variants?: Maybe<Scalars["String"]>;
  videomaker?: Maybe<RegistryNode>;
  visibleBy?: Maybe<Array<Maybe<Scalars["String"]>>>;
  watermark?: Maybe<Scalars["String"]>;
  woodFrameDetails?: Maybe<Scalars["String"]>;
  yearEndDate?: Maybe<Scalars["Int"]>;
  yearStartDate?: Maybe<Scalars["Int"]>;
};

export type AssetNodeAppraisalAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeCollectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeConditionstatusreportReportAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeEventLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeExhibitionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePhysicalFeaturesElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicyAssetHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeRestorationAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeConnection = {
  __typename?: "AssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetNode` and its cursor. */
export type AssetNodeEdge = {
  __typename?: "AssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetNode>;
};

export type AssetPresentValueInput = {
  amount?: InputMaybe<MoneyInput>;
  assetId?: InputMaybe<Scalars["ID"]>;
  authorId?: InputMaybe<Scalars["ID"]>;
  estimateDate?: InputMaybe<Scalars["Date"]>;
  estimateReason?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  fileTitle?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetPresentValueNode = Node & {
  __typename?: "AssetPresentValueNode";
  amount: Money;
  amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
  asset?: Maybe<AssetNode>;
  author?: Maybe<RegistryNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  estimateDate?: Maybe<Scalars["Date"]>;
  estimateReason?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  fileTitle?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type AssetPresentValueNodeConnection = {
  __typename?: "AssetPresentValueNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPresentValueNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPresentValueNode` and its cursor. */
export type AssetPresentValueNodeEdge = {
  __typename?: "AssetPresentValueNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetPresentValueNode>;
};

/** An enumeration. */
export enum BaseEventLogAuctionRightsCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum BaseEventLogExternalEntityChoices {
  /** appraisal */
  Appraisal = "APPRAISAL",
  /** collection */
  Collection = "COLLECTION",
  /** cs_report */
  CsReport = "CS_REPORT",
  /** exhibition */
  Exhibition = "EXHIBITION",
  /** logistics */
  Logistics = "LOGISTICS",
  /** no_entity */
  NoEntity = "NO_ENTITY",
  /** policy */
  Policy = "POLICY",
  /** restoration */
  Restoration = "RESTORATION",
}

/** An enumeration. */
export enum BaseEventLogMaximumPriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum BaseEventLogMinimumPriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum BaseEventLogReservePriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum BaseEventLogValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export type CertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  grouping?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  printTemplate?: InputMaybe<Scalars["String"]>;
};

export type CertificateNode = Node & {
  __typename?: "CertificateNode";
  cancelReplace: Scalars["Boolean"];
  cancelReplaceNotes?: Maybe<Scalars["String"]>;
  certificateType: SpinCertificateCertificateTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  creationDate: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  grouping?: Maybe<SpinCertificateGroupingChoices>;
  /** The ID of the object */
  id: Scalars["ID"];
  isExpired?: Maybe<Scalars["Boolean"]>;
  isSplitted: Scalars["Boolean"];
  isValid: Scalars["Boolean"];
  language: Scalars["String"];
  lender?: Maybe<Scalars["String"]>;
  lenderCountry?: Maybe<Scalars["String"]>;
  mainOfficeCountry?: Maybe<Scalars["String"]>;
  metafields?: Maybe<Scalars["GenericScalar"]>;
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  policyAsset: PolicyAssetNodeConnection;
  policyAssetsCount?: Maybe<Scalars["Int"]>;
  printTemplate: CertificateTemplateNode;
  progressiveNumber?: Maybe<Scalars["Int"]>;
  shipper?: Maybe<Scalars["String"]>;
  signed: Scalars["Boolean"];
  signedAt?: Maybe<Scalars["DateTime"]>;
  signedBy?: Maybe<UserNode>;
  totalInsuredValue?: Maybe<Money>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CertificateNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateNodeConnection = {
  __typename?: "CertificateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CertificateNode` and its cursor. */
export type CertificateNodeEdge = {
  __typename?: "CertificateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateNode>;
};

export type CertificateTemplateNode = Node & {
  __typename?: "CertificateTemplateNode";
  certificateSet: CertificateNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CertificateTemplateNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateTemplateNodeConnection = {
  __typename?: "CertificateTemplateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CertificateTemplateNode` and its cursor. */
export type CertificateTemplateNodeEdge = {
  __typename?: "CertificateTemplateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateTemplateNode>;
};

export type ChangeAssetCategoryInput = {
  authorEntityId?: InputMaybe<Scalars["String"]>;
  category: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  subcategory: Scalars["String"];
};

export type ChangeAssetCategoryPayload = {
  __typename?: "ChangeAssetCategoryPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ChangePasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword: Scalars["String"];
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordPayload = {
  __typename?: "ChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ClientNode = Node & {
  __typename?: "ClientNode";
  createdOn: Scalars["Date"];
  currency: Scalars["String"];
  customerType: Scalars["String"];
  dashboardBaseUrl: Scalars["String"];
  defaultBroker?: Maybe<RegistryNode>;
  defaultInsuranceCompany?: Maybe<RegistryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  location: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onTrial: Scalars["Boolean"];
  paidUntil: Scalars["Date"];
  schemaName: Scalars["String"];
  storageName: Scalars["String"];
  tier: Scalars["String"];
  timezone: Scalars["String"];
};

export type ClientNodeConnection = {
  __typename?: "ClientNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ClientNode` and its cursor. */
export type ClientNodeEdge = {
  __typename?: "ClientNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ClientNode>;
};

export type CloseAppraisalInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseAppraisalPayload = {
  __typename?: "CloseAppraisalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeAppraisal?: Maybe<AppraisalNode>;
};

export type CloseConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseConditionStatusReportPayload = {
  __typename?: "CloseConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeConditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type CloseRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseRestorationPayload = {
  __typename?: "CloseRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeRestoration?: Maybe<RestorationNode>;
};

export type CollectionInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type CollectionNode = Node & {
  __typename?: "CollectionNode";
  actualStatusObjects?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdFrom?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  totalAssetsNumber?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  validatedAssetsNumber?: Maybe<Scalars["Int"]>;
};

export type CollectionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CollectionNodeConnection = {
  __typename?: "CollectionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionNode` and its cursor. */
export type CollectionNodeEdge = {
  __typename?: "CollectionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CollectionNode>;
};

export type CompanyContactInput = {
  companyContact: Scalars["ID"];
  main: Scalars["Boolean"];
  relatedCompany: Scalars["ID"];
};

export type CompanyContactNode = Node & {
  __typename?: "CompanyContactNode";
  companyContact: RegistryNode;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  relatedCompany: RegistryNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CompanyContactNodeConnection = {
  __typename?: "CompanyContactNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyContactNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompanyContactNode` and its cursor. */
export type CompanyContactNodeEdge = {
  __typename?: "CompanyContactNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CompanyContactNode>;
};

export type CompanyContactType = {
  __typename?: "CompanyContactType";
  companyContact: RegistryNode;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  relatedCompany: RegistryNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type ConditionStatusReportInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  environmentalConditionsAbnormalHumidityEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsAbnormalLightEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsAbnormalTemperatureEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsLightUnit?: InputMaybe<Scalars["String"]>;
  environmentalConditionsOptimalHumidity?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsOptimalLightLevelIntensity?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsOptimalTemperature?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsTemperatureUnit?: InputMaybe<Scalars["String"]>;
  exhibitionAddress?: InputMaybe<AddressInput>;
  exhibitionCurator?: InputMaybe<Scalars["ID"]>;
  exhibitionEventFrom?: InputMaybe<Scalars["DateTime"]>;
  exhibitionEventTo?: InputMaybe<Scalars["DateTime"]>;
  exhibitionHostEntity?: InputMaybe<Scalars["ID"]>;
  exhibitionLender?: InputMaybe<Scalars["ID"]>;
  exhibitionLoanFrom?: InputMaybe<Scalars["DateTime"]>;
  exhibitionLoanTo?: InputMaybe<Scalars["DateTime"]>;
  exhibitionLocation?: InputMaybe<Scalars["String"]>;
  exhibitionName?: InputMaybe<Scalars["String"]>;
  exhibitionNotes?: InputMaybe<Scalars["String"]>;
  exhibitionPermanent?: InputMaybe<Scalars["Boolean"]>;
  exhibitionPlanner?: InputMaybe<Scalars["ID"]>;
  exhibitionTitle?: InputMaybe<Scalars["String"]>;
  logisticsAirConditioningTransportConditions?: InputMaybe<Scalars["String"]>;
  logisticsCarrier?: InputMaybe<Scalars["ID"]>;
  logisticsDeliveryAddress?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryCity?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryCountry?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryDate?: InputMaybe<Scalars["DateTime"]>;
  logisticsDeliveryLocation?: InputMaybe<AddressInput>;
  logisticsDeliveryState?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryZip?: InputMaybe<Scalars["String"]>;
  logisticsId?: InputMaybe<Scalars["String"]>;
  logisticsNumberOfPackages?: InputMaybe<Scalars["Int"]>;
  logisticsPickupAddress?: InputMaybe<Scalars["String"]>;
  logisticsPickupCity?: InputMaybe<Scalars["String"]>;
  logisticsPickupCountry?: InputMaybe<Scalars["String"]>;
  logisticsPickupDate?: InputMaybe<Scalars["DateTime"]>;
  logisticsPickupLocation?: InputMaybe<AddressInput>;
  logisticsPickupState?: InputMaybe<Scalars["String"]>;
  logisticsPickupZip?: InputMaybe<Scalars["String"]>;
  logisticsRecipient?: InputMaybe<Scalars["ID"]>;
  logisticsSender?: InputMaybe<Scalars["ID"]>;
  reportAsset?: InputMaybe<Scalars["ID"]>;
  reportCompany?: InputMaybe<Scalars["ID"]>;
  reportDate?: InputMaybe<Scalars["DateTime"]>;
  reportDetailsAssetCondition?: InputMaybe<Scalars["String"]>;
  reportDetailsAssetDamageDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsExamConditions?: InputMaybe<Scalars["String"]>;
  reportDetailsExamConditionsNote?: InputMaybe<Scalars["String"]>;
  reportDetailsExamLocation?: InputMaybe<Scalars["String"]>;
  reportDetailsExaminedPackages?: InputMaybe<Scalars["String"]>;
  reportDetailsExhaminationTimeTechnicalDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsPackagingGeneralConditions?: InputMaybe<Scalars["String"]>;
  reportDetailsPackagingStatusDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsCondition?: InputMaybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsDamageDescription?: InputMaybe<Scalars["String"]>;
  reportExaminationType: Scalars["String"];
  reportExaminer?: InputMaybe<Scalars["ID"]>;
  reportMotivation: Scalars["String"];
  reportMotivationDescription?: InputMaybe<Scalars["String"]>;
  reportStatus: Scalars["String"];
  reportType: Scalars["String"];
  settingsConservation?: InputMaybe<Scalars["String"]>;
  settingsExaminerComments?: InputMaybe<Scalars["String"]>;
  settingsHandlingRequirements?: InputMaybe<Scalars["String"]>;
  settingsHasBarriers?: InputMaybe<Scalars["Boolean"]>;
  settingsInjuryRisk?: InputMaybe<Scalars["String"]>;
  settingsLenderRequests?: InputMaybe<Scalars["String"]>;
  settingsNotes?: InputMaybe<Scalars["String"]>;
  valueEstimatedValue?: InputMaybe<MoneyInput>;
  valueEstimatedValueExaminationTime?: InputMaybe<MoneyInput>;
  valuePercentageDepreciation?: InputMaybe<Scalars["Float"]>;
};

export type ConditionStatusReportNode = Node & {
  __typename?: "ConditionStatusReportNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  environmentalConditionsAbnormalHumidityEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsAbnormalLightEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsAbnormalTemperatureEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsLightUnit?: Maybe<Scalars["String"]>;
  environmentalConditionsOptimalHumidity?: Maybe<Scalars["Int"]>;
  environmentalConditionsOptimalLightLevelIntensity?: Maybe<Scalars["Int"]>;
  environmentalConditionsOptimalTemperature?: Maybe<Scalars["Int"]>;
  environmentalConditionsTemperatureUnit?: Maybe<Scalars["String"]>;
  exhibitionAddress?: Maybe<Address>;
  exhibitionCurator?: Maybe<RegistryNode>;
  exhibitionEventFrom?: Maybe<Scalars["DateTime"]>;
  exhibitionEventTo?: Maybe<Scalars["DateTime"]>;
  exhibitionHostEntity?: Maybe<RegistryNode>;
  exhibitionLender?: Maybe<RegistryNode>;
  exhibitionLoanFrom?: Maybe<Scalars["DateTime"]>;
  exhibitionLoanTo?: Maybe<Scalars["DateTime"]>;
  exhibitionLocation?: Maybe<Scalars["String"]>;
  exhibitionName?: Maybe<Scalars["String"]>;
  exhibitionNotes?: Maybe<Scalars["String"]>;
  exhibitionPermanent: Scalars["Boolean"];
  exhibitionPlanner?: Maybe<RegistryNode>;
  exhibitionTitle?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  logisticsAirConditioningTransportConditions?: Maybe<Scalars["String"]>;
  logisticsCarrier?: Maybe<RegistryNode>;
  logisticsDeliveryAddress?: Maybe<Scalars["String"]>;
  logisticsDeliveryCity?: Maybe<Scalars["String"]>;
  logisticsDeliveryCountry?: Maybe<Scalars["String"]>;
  logisticsDeliveryDate?: Maybe<Scalars["DateTime"]>;
  logisticsDeliveryLocation?: Maybe<Address>;
  logisticsDeliveryState?: Maybe<Scalars["String"]>;
  logisticsDeliveryZip?: Maybe<Scalars["String"]>;
  logisticsId?: Maybe<Scalars["String"]>;
  logisticsNumberOfPackages?: Maybe<Scalars["Int"]>;
  logisticsPickupAddress?: Maybe<Scalars["String"]>;
  logisticsPickupCity?: Maybe<Scalars["String"]>;
  logisticsPickupCountry?: Maybe<Scalars["String"]>;
  logisticsPickupDate?: Maybe<Scalars["DateTime"]>;
  logisticsPickupLocation?: Maybe<Address>;
  logisticsPickupState?: Maybe<Scalars["String"]>;
  logisticsPickupZip?: Maybe<Scalars["String"]>;
  logisticsRecipient?: Maybe<RegistryNode>;
  logisticsSender?: Maybe<RegistryNode>;
  media: MediaNodeConnection;
  reportAsset?: Maybe<AssetNode>;
  reportCompany?: Maybe<RegistryNode>;
  reportDate?: Maybe<Scalars["DateTime"]>;
  reportDetailsAssetCondition?: Maybe<Scalars["String"]>;
  reportDetailsAssetDamageDescription?: Maybe<Scalars["String"]>;
  reportDetailsExamConditions?: Maybe<Scalars["String"]>;
  reportDetailsExamConditionsNote?: Maybe<Scalars["String"]>;
  reportDetailsExamLocation?: Maybe<Scalars["String"]>;
  reportDetailsExaminedPackages?: Maybe<Scalars["String"]>;
  reportDetailsExhaminationTimeTechnicalDescription?: Maybe<Scalars["String"]>;
  reportDetailsPackagingGeneralConditions?: Maybe<Scalars["String"]>;
  reportDetailsPackagingStatusDescription?: Maybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsCondition?: Maybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsDamageDescription?: Maybe<Scalars["String"]>;
  reportExaminationType?: Maybe<Scalars["String"]>;
  reportExaminer?: Maybe<RegistryNode>;
  reportMotivation?: Maybe<Scalars["String"]>;
  reportMotivationDescription?: Maybe<Scalars["String"]>;
  reportStatus?: Maybe<Scalars["String"]>;
  reportType?: Maybe<Scalars["String"]>;
  settingsConservation?: Maybe<Scalars["String"]>;
  settingsExaminerComments?: Maybe<Scalars["String"]>;
  settingsHandlingRequirements?: Maybe<Scalars["String"]>;
  settingsHasBarriers?: Maybe<Scalars["Boolean"]>;
  settingsInjuryRisk?: Maybe<Scalars["String"]>;
  settingsLenderRequests?: Maybe<Scalars["String"]>;
  settingsNotes?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  valueEstimatedValue?: Maybe<Money>;
  valueEstimatedValueCurrency: ManagementConditionStatusReportValueEstimatedValueCurrencyChoices;
  valueEstimatedValueExaminationTime?: Maybe<Money>;
  valueEstimatedValueExaminationTimeCurrency: ManagementConditionStatusReportValueEstimatedValueExaminationTimeCurrencyChoices;
  valuePercentageDepreciation?: Maybe<Scalars["Float"]>;
};

export type ConditionStatusReportNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ConditionStatusReportNodeConnection = {
  __typename?: "ConditionStatusReportNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConditionStatusReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ConditionStatusReportNode` and its cursor. */
export type ConditionStatusReportNodeEdge = {
  __typename?: "ConditionStatusReportNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ConditionStatusReportNode>;
};

export type ContentTypeNode = Node & {
  __typename?: "ContentTypeNode";
  appLabel: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  model: Scalars["String"];
  permissionSet: PermissionNodeConnection;
};

export type ContentTypeNodePermissionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ContentTypeNodeConnection = {
  __typename?: "ContentTypeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContentTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContentTypeNode` and its cursor. */
export type ContentTypeNodeEdge = {
  __typename?: "ContentTypeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ContentTypeNode>;
};

export type Country = {
  __typename?: "Country";
  /** ISO 3166-1 three character country code */
  alpha3?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 two character country code */
  code?: Maybe<Scalars["String"]>;
  /** International Olympic Committee country code */
  iocCode?: Maybe<Scalars["String"]>;
  /** Country name */
  name?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 numeric country code */
  numeric?: Maybe<Scalars["Int"]>;
};

export type CreateAppraisalInput = {
  appraisalData?: InputMaybe<AppraisalInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAppraisalPayload = {
  __typename?: "CreateAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetDocumentAttachmentInput = {
  assetDocumentAttachmentData?: InputMaybe<AssetDocumentAttachmentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetDocumentAttachmentPayload = {
  __typename?: "CreateAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetDocumentInput = {
  assetDocumentData?: InputMaybe<AssetDocumentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetDocumentPayload = {
  __typename?: "CreateAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetElementPayload = {
  __typename?: "CreateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPayload = {
  __typename?: "CreateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPresentValuePayload = {
  __typename?: "CreateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateCertificatePayload = {
  __typename?: "CreateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateCertificateTemplateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
};

export type CreateCertificateTemplatePayload = {
  __typename?: "CreateCertificateTemplatePayload";
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CreateCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionData?: InputMaybe<CollectionInput>;
};

export type CreateCollectionPayload = {
  __typename?: "CreateCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type CreateCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
};

export type CreateCompanyContactPayload = {
  __typename?: "CreateCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
};

export type CreateConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportData?: InputMaybe<ConditionStatusReportInput>;
};

export type CreateConditionStatusReportPayload = {
  __typename?: "CreateConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type CreateEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventLogData?: InputMaybe<EventLogInput>;
};

export type CreateEventLogPayload = {
  __typename?: "CreateEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventLog?: Maybe<EventLogNode>;
};

export type CreateExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionData?: InputMaybe<ExhibitionInput>;
};

export type CreateExhibitionPayload = {
  __typename?: "CreateExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type CreateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type CreateInsurancePayload = {
  __typename?: "CreateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type CreateMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type CreateMediaPayload = {
  __typename?: "CreateMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type CreateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  officeData?: InputMaybe<OfficeInput>;
};

export type CreateOfficePayload = {
  __typename?: "CreateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type CreatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type CreatePolicyAssetPayload = {
  __typename?: "CreatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type CreatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  policyId: Scalars["ID"];
};

export type CreatePolicyDocumentPayload = {
  __typename?: "CreatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CreatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyData?: InputMaybe<PolicyInput>;
};

export type CreatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type CreatePolicyNotePayload = {
  __typename?: "CreatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type CreatePolicyPayload = {
  __typename?: "CreatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type CreateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  registryData?: InputMaybe<RegistryInput>;
};

export type CreateRegistryPayload = {
  __typename?: "CreateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type CreateRegistryWithCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
  registryData?: InputMaybe<RegistryInput>;
};

export type CreateRegistryWithCompanyContactPayload = {
  __typename?: "CreateRegistryWithCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
  registry?: Maybe<RegistryNode>;
};

export type CreateRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  restorationData?: InputMaybe<RestorationInput>;
};

export type CreateRestorationPayload = {
  __typename?: "CreateRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  userData?: InputMaybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type Currency = {
  __typename?: "Currency";
  /** A ISO-421 3-letter currency. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  code: Scalars["String"];
  /** A human readable name, e.g. US Dollar */
  name?: Maybe<Scalars["String"]>;
  /** A ISO-421 numeric code. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  numeric?: Maybe<Scalars["String"]>;
  /** The currency's prefix, e.g. $ for USD */
  prefix?: Maybe<Scalars["String"]>;
  /** The currency's symbol, e.g. € for EUR */
  suffix?: Maybe<Scalars["String"]>;
  /** The currency's symbol, e.g. $ for USD */
  symbol?: Maybe<Scalars["String"]>;
};

export type DeleteAppraisalInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAppraisalMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteAppraisalMediaPayload = {
  __typename?: "DeleteAppraisalMediaPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAppraisalPayload = {
  __typename?: "DeleteAppraisalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteAppraisal?: Maybe<AppraisalNode>;
};

export type DeleteAssetCarryingValueFileInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteAssetCarryingValueFilePayload = {
  __typename?: "DeleteAssetCarryingValueFilePayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetDocumentAttachmentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAssetDocumentAttachmentPayload = {
  __typename?: "DeleteAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetDocumentPayload = {
  __typename?: "DeleteAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetElementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetElementPayload = {
  __typename?: "DeleteAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAssetMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteAssetMediaPayload = {
  __typename?: "DeleteAssetMediaPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetPayload = {
  __typename?: "DeleteAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetPresentValueInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetPresentValuePayload = {
  __typename?: "DeleteAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteCertificatePayload = {
  __typename?: "DeleteCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCertificateTemplateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteCertificateTemplatePayload = {
  __typename?: "DeleteCertificateTemplatePayload";
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteCollectionPayload = {
  __typename?: "DeleteCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DeleteCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteCompanyContactPayload = {
  __typename?: "DeleteCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
};

export type DeleteConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteConditionStatusReportMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteConditionStatusReportMediaPayload = {
  __typename?: "DeleteConditionStatusReportMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DeleteConditionStatusReportPayload = {
  __typename?: "DeleteConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteConditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DeleteEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteEventLogPayload = {
  __typename?: "DeleteEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteEventLog?: Maybe<EventLogNode>;
};

export type DeleteExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteExhibitionPayload = {
  __typename?: "DeleteExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DeleteInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteInsurancePayload = {
  __typename?: "DeleteInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type DeleteMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteMediaPayload = {
  __typename?: "DeleteMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type DeleteOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteOfficePayload = {
  __typename?: "DeleteOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type DeletePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
};

export type DeletePolicyAssetPayload = {
  __typename?: "DeletePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type DeletePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeletePolicyDocumentPayload = {
  __typename?: "DeletePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type DeletePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNotePayload = {
  __typename?: "DeletePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type DeletePolicyPayload = {
  __typename?: "DeletePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type DeleteRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRegistryPayload = {
  __typename?: "DeleteRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type DeleteRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRestorationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteRestorationMediaPayload = {
  __typename?: "DeleteRestorationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type DeleteRestorationPayload = {
  __typename?: "DeleteRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteRestoration?: Maybe<RestorationNode>;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type DeleteUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserSignaturePayload = {
  __typename?: "DeleteUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSignature?: Maybe<UserSignatureNode>;
};

export type DetachAssetFromAppraisalInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromAppraisalPayload = {
  __typename?: "DetachAssetFromAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DetachAssetFromCollectionInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromCollectionPayload = {
  __typename?: "DetachAssetFromCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DetachAssetFromConditionStatusReportInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromConditionStatusReportPayload = {
  __typename?: "DetachAssetFromConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DetachAssetFromExhibitionInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromExhibitionPayload = {
  __typename?: "DetachAssetFromExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DetachAssetFromRestorationInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromRestorationPayload = {
  __typename?: "DetachAssetFromRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type DiffChangesNode = {
  __typename?: "DiffChangesNode";
  changeReason?: Maybe<Scalars["String"]>;
  changedAt?: Maybe<Scalars["DateTime"]>;
  field?: Maybe<Scalars["String"]>;
  historyId?: Maybe<Scalars["String"]>;
  new?: Maybe<Scalars["String"]>;
  old?: Maybe<Scalars["String"]>;
};

export type DuplicatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type DuplicatePolicyPayload = {
  __typename?: "DuplicatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type EventLogInput = {
  acquisitionType?: InputMaybe<Scalars["String"]>;
  actionType?: InputMaybe<Scalars["String"]>;
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  auctionDescription?: InputMaybe<Scalars["String"]>;
  auctionRights?: InputMaybe<MoneyInput>;
  broker?: InputMaybe<Scalars["ID"]>;
  collectionPublicName?: InputMaybe<Scalars["String"]>;
  commerciallyAvailable?: InputMaybe<Scalars["Boolean"]>;
  commission?: InputMaybe<Scalars["String"]>;
  document?: InputMaybe<Scalars["Upload"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  eventType: Scalars["String"];
  externalEntity: Scalars["String"];
  externalId?: InputMaybe<Scalars["Int"]>;
  hideOwner?: InputMaybe<Scalars["Boolean"]>;
  host?: InputMaybe<Scalars["ID"]>;
  locationDescription?: InputMaybe<Scalars["String"]>;
  lotNumber?: InputMaybe<Scalars["String"]>;
  maximumPrice?: InputMaybe<MoneyInput>;
  minimumPrice?: InputMaybe<MoneyInput>;
  newOwner?: InputMaybe<Scalars["ID"]>;
  note?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Scalars["ID"]>;
  owner?: InputMaybe<Scalars["ID"]>;
  reason?: InputMaybe<Scalars["String"]>;
  reservePrice?: InputMaybe<MoneyInput>;
  sellingLocation?: InputMaybe<Scalars["String"]>;
  sold?: InputMaybe<Scalars["Boolean"]>;
  startDate: Scalars["DateTime"];
  value?: InputMaybe<MoneyInput>;
};

export type EventLogNode = Node & {
  __typename?: "EventLogNode";
  acquisitionType?: Maybe<Scalars["String"]>;
  actionType?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  auctionDescription?: Maybe<Scalars["String"]>;
  auctionRights?: Maybe<Money>;
  auctionRightsCurrency: BaseEventLogAuctionRightsCurrencyChoices;
  broker?: Maybe<RegistryNode>;
  collectionPublicName?: Maybe<Scalars["String"]>;
  commerciallyAvailable?: Maybe<Scalars["Boolean"]>;
  commission?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  document?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  eventType?: Maybe<Scalars["String"]>;
  externalEntity: BaseEventLogExternalEntityChoices;
  externalId?: Maybe<Scalars["Int"]>;
  hideOwner?: Maybe<Scalars["Boolean"]>;
  host?: Maybe<RegistryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  locationDescription?: Maybe<Scalars["String"]>;
  lotNumber?: Maybe<Scalars["String"]>;
  maximumPrice?: Maybe<Money>;
  maximumPriceCurrency: BaseEventLogMaximumPriceCurrencyChoices;
  minimumPrice?: Maybe<Money>;
  minimumPriceCurrency: BaseEventLogMinimumPriceCurrencyChoices;
  newOwner?: Maybe<RegistryNode>;
  note?: Maybe<Scalars["String"]>;
  origin?: Maybe<RegistryNode>;
  owner?: Maybe<RegistryNode>;
  reason?: Maybe<Scalars["String"]>;
  reservePrice?: Maybe<Money>;
  reservePriceCurrency: BaseEventLogReservePriceCurrencyChoices;
  sellingLocation?: Maybe<Scalars["String"]>;
  sold?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  value?: Maybe<Money>;
  valueCurrency: BaseEventLogValueCurrencyChoices;
};

export type EventLogNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type EventLogNodeConnection = {
  __typename?: "EventLogNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventLogNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EventLogNode` and its cursor. */
export type EventLogNodeEdge = {
  __typename?: "EventLogNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<EventLogNode>;
};

export type ExhibitionInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  curator?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  eventFrom?: InputMaybe<Scalars["DateTime"]>;
  eventLocation?: InputMaybe<Scalars["ID"]>;
  eventLocationEntity?: InputMaybe<Scalars["ID"]>;
  eventTo?: InputMaybe<Scalars["DateTime"]>;
  hostEntity: Scalars["ID"];
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  organizer?: InputMaybe<Scalars["ID"]>;
  permanent?: InputMaybe<Scalars["Boolean"]>;
};

export type ExhibitionNode = Node & {
  __typename?: "ExhibitionNode";
  actualTimingStatus?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdFrom?: Maybe<Scalars["String"]>;
  curator?: Maybe<RegistryNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocation?: Maybe<Address>;
  eventLocationEntity?: Maybe<OfficeNode>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  hostEntity?: Maybe<RegistryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  organizer?: Maybe<RegistryNode>;
  permanent: Scalars["Boolean"];
  policy?: Maybe<PolicyNode>;
  totalAssetsNumber?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type ExhibitionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExhibitionNodeConnection = {
  __typename?: "ExhibitionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExhibitionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExhibitionNode` and its cursor. */
export type ExhibitionNodeEdge = {
  __typename?: "ExhibitionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExhibitionNode>;
};

export type ExportAppraisalPdfInput = {
  appraisalId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportAppraisalPdfPayload = {
  __typename?: "ExportAppraisalPDFPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportAssetPdfInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportAssetPdfPayload = {
  __typename?: "ExportAssetPDFPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportCollectionPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionId: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportCollectionPdfPayload = {
  __typename?: "ExportCollectionPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportConditionStatusReportPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportId: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportConditionStatusReportPdfPayload = {
  __typename?: "ExportConditionStatusReportPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportExhibitionPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionId: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportExhibitionPdfPayload = {
  __typename?: "ExportExhibitionPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportPolicyPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type ExportPolicyPdfPayload = {
  __typename?: "ExportPolicyPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ExportRestorationPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  lang?: InputMaybe<Scalars["String"]>;
  restorationId: Scalars["ID"];
};

export type ExportRestorationPdfPayload = {
  __typename?: "ExportRestorationPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExtendPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type ExtendPolicyPayload = {
  __typename?: "ExtendPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type FilteredInsuranceNode = Node & {
  __typename?: "FilteredInsuranceNode";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type FilteredInsuranceNodeConnection = {
  __typename?: "FilteredInsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FilteredInsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FilteredInsuranceNode` and its cursor. */
export type FilteredInsuranceNodeEdge = {
  __typename?: "FilteredInsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<FilteredInsuranceNode>;
};

export type GenerateCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type GenerateCertificatePayload = {
  __typename?: "GenerateCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type GenerateGroupedCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
  policyAssetsId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type GenerateGroupedCertificatePayload = {
  __typename?: "GenerateGroupedCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type GenerateSplitCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
  policyAssetsId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type GenerateSplitCertificatePayload = {
  __typename?: "GenerateSplitCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type GroupedPolicyAssetInput = {
  asset?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  deliveryAddress?: InputMaybe<AddressInput>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  insuredValue?: InputMaybe<MoneyInput>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  lenderAddress?: InputMaybe<AddressInput>;
  lenderId?: InputMaybe<Scalars["ID"]>;
  objectLocationEntityId?: InputMaybe<Scalars["ID"]>;
  objectLocationOfficeId?: InputMaybe<Scalars["ID"]>;
  pickupAddress?: InputMaybe<AddressInput>;
  policy?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type GroupsNode = {
  __typename?: "GroupsNode";
  groups?: Maybe<Array<Maybe<Array<Maybe<PolicyAssetNode>>>>>;
  length?: Maybe<Scalars["Int"]>;
};

export type HistoryListItemNode = {
  __typename?: "HistoryListItemNode";
  autoRenewal?: Maybe<Scalars["Boolean"]>;
  catNat?: Maybe<Scalars["Boolean"]>;
  coverTerrorism?: Maybe<Scalars["Boolean"]>;
  coverType?: Maybe<Scalars["String"]>;
  coveredRiskType?: Maybe<Scalars["String"]>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventName?: Maybe<Scalars["String"]>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exemption?: Maybe<Scalars["Boolean"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  hasValidCertificate?: Maybe<Scalars["Boolean"]>;
  hasVariableBasket?: Maybe<Scalars["Boolean"]>;
  historyChangeReason?: Maybe<Scalars["String"]>;
  historyDate?: Maybe<Scalars["DateTime"]>;
  historyId?: Maybe<Scalars["ID"]>;
  historyType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  offerCode?: Maybe<Scalars["String"]>;
  policyCode?: Maybe<Scalars["String"]>;
  policyStatus?: Maybe<Scalars["String"]>;
  policyType?: Maybe<Scalars["String"]>;
  totalInsuredPremium?: Maybe<Scalars["Float"]>;
  totalInsuredPremiumCurrency?: Maybe<Scalars["String"]>;
  totalInsuredValue?: Maybe<Scalars["Float"]>;
  totalInsuredValueCurrency?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

export type InsuranceInput = {
  catNat: Scalars["Boolean"];
  contractType: Scalars["String"];
  coverageType: Scalars["String"];
  currencyInsurancePremium: Scalars["String"];
  currencyInsuredValue: Scalars["String"];
  evaluationType: Scalars["String"];
  insurancePremium: Scalars["String"];
  insuranceReason: Scalars["String"];
  insuredValue: Scalars["String"];
  note: Scalars["String"];
  policyNumber: Scalars["String"];
  riskCoveredType: Scalars["String"];
  terrorism: Scalars["Boolean"];
};

export type InsuranceNode = Node & {
  __typename?: "InsuranceNode";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type InsuranceNodeConnection = {
  __typename?: "InsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InsuranceNode` and its cursor. */
export type InsuranceNodeEdge = {
  __typename?: "InsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<InsuranceNode>;
};

export type InsuranceType = {
  __typename?: "InsuranceType";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type InvalidateCertificateInput = {
  certificateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type InvalidateCertificatePayload = {
  __typename?: "InvalidateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type InviteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type InviteUserPayload = {
  __typename?: "InviteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum ManagementAppraisalValueCarryingValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementAppraisalValueEstimatedValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementAssetPresentValueAmountCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementConditionStatusReportValueEstimatedValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementConditionStatusReportValueEstimatedValueExaminationTimeCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementInsuranceContractTypeChoices {
  /** basket */
  Basket = "BASKET",
  /** punctual */
  Punctual = "PUNCTUAL",
}

/** An enumeration. */
export enum ManagementInsuranceCoverageTypeChoices {
  /** nail_to_nail */
  NailToNail = "NAIL_TO_NAIL",
  /** stay_risk */
  StayRisk = "STAY_RISK",
  /** travel_risk */
  TravelRisk = "TRAVEL_RISK",
}

/** An enumeration. */
export enum ManagementInsuranceEvaluationTypeChoices {
  /** all */
  All = "ALL",
  /** Declared Value */
  DeclaredValue = "DECLARED_VALUE",
  /** estimate_accepted */
  EstimateAccepted = "ESTIMATE_ACCEPTED",
}

/** An enumeration. */
export enum ManagementInsuranceInsuranceReasonChoices {
  /** basket */
  Exhibition = "EXHIBITION",
  /** punctual */
  ExhibitionTransport = "EXHIBITION_TRANSPORT",
  /** loan */
  Loan = "LOAN",
  /** punctual */
  Restauration = "RESTAURATION",
  /** punctual */
  RestaurationTransport = "RESTAURATION_TRANSPORT",
  /** punctual */
  StayInPlace = "STAY_IN_PLACE",
  /** punctual */
  Transport = "TRANSPORT",
}

/** An enumeration. */
export enum ManagementInsuranceRiskCoveredTypeChoices {
  /** all_risk */
  AllRisk = "ALL_RISK",
  /** first_risk */
  FirstRisk = "FIRST_RISK",
  /** none */
  None = "NONE",
}

/** An enumeration. */
export enum ManagementRestorationRestorationCostCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export type MediaInput = {
  audioVideoFileType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["Date"]>;
  description?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  fileAuthor?: InputMaybe<Scalars["String"]>;
  fileSize?: InputMaybe<Scalars["String"]>;
  imageType?: InputMaybe<Scalars["String"]>;
  isGlassPlexyAcetate?: InputMaybe<Scalars["Boolean"]>;
  isMainImage?: InputMaybe<Scalars["Boolean"]>;
  matrixType?: InputMaybe<Scalars["String"]>;
  mediaType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  photographicEquipment?: InputMaybe<Scalars["String"]>;
};

export type MediaNode = Node & {
  __typename?: "MediaNode";
  actualImageDimensions?: Maybe<Scalars["String"]>;
  appraisalSet: AppraisalNodeConnection;
  assetSet: AssetNodeConnection;
  audioVideoFileType?: Maybe<Scalars["String"]>;
  conditionstatusreportSet: ConditionStatusReportNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  creationDate?: Maybe<Scalars["Date"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  file: Scalars["String"];
  fileAuthor?: Maybe<Scalars["String"]>;
  fileDuration?: Maybe<Scalars["String"]>;
  fileHeight?: Maybe<Scalars["Float"]>;
  fileSize?: Maybe<Scalars["String"]>;
  fileWidth?: Maybe<Scalars["Float"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  imageType?: Maybe<Scalars["String"]>;
  isGlassPlexyAcetate?: Maybe<Scalars["Boolean"]>;
  isMainImage?: Maybe<Scalars["Boolean"]>;
  matrixType?: Maybe<Scalars["String"]>;
  mediaType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  photographicEquipment?: Maybe<Scalars["String"]>;
  restorationSet: RestorationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type MediaNodeAppraisalSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeConditionstatusreportSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeRestorationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeConnection = {
  __typename?: "MediaNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MediaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MediaNode` and its cursor. */
export type MediaNodeEdge = {
  __typename?: "MediaNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<MediaNode>;
};

export type Money = {
  __typename?: "Money";
  /** The numerical amount. */
  amount?: Maybe<Scalars["Decimal"]>;
  /** The string version of the numerical amount. */
  amountStr?: Maybe<Scalars["String"]>;
  asString?: Maybe<Scalars["StringMoney"]>;
  currency?: Maybe<Currency>;
  formatAmount?: Maybe<Scalars["String"]>;
};

export type MoneyFormatAmountArgs = {
  decimals?: InputMaybe<Scalars["Int"]>;
};

export type MoneyInput = {
  /** The numerical amount. */
  amount?: InputMaybe<Scalars["Decimal"]>;
  /** The ISO-421 3-letter currency code. */
  currency: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAppraisalMedia?: Maybe<AddAppraisalMediaPayload>;
  addAssetMedia?: Maybe<AddAssetMediaPayload>;
  addConditionStatusReportMedia?: Maybe<AddConditionStatusReportMediaPayload>;
  addRestorationMedia?: Maybe<AddRestorationMediaPayload>;
  addUserObjectPermission?: Maybe<AddUserObjectPermissionPayload>;
  addUserObjectPermissionFromCollection?: Maybe<AddUserObjectPermissionFromCollectionPayload>;
  addUserPermission?: Maybe<AddUserPermissionPayload>;
  addUserSignature?: Maybe<AddUserSignaturePayload>;
  changeAssetCategory?: Maybe<ChangeAssetCategoryPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  closeAppraisal?: Maybe<CloseAppraisalPayload>;
  closeConditionStatusReport?: Maybe<CloseConditionStatusReportPayload>;
  closeRestoration?: Maybe<CloseRestorationPayload>;
  createAppraisal?: Maybe<CreateAppraisalPayload>;
  createAsset?: Maybe<CreateAssetPayload>;
  createAssetDocument?: Maybe<CreateAssetDocumentPayload>;
  createAssetDocumentAttachment?: Maybe<CreateAssetDocumentAttachmentPayload>;
  createAssetElement?: Maybe<CreateAssetElementPayload>;
  createAssetPresentValue?: Maybe<CreateAssetPresentValuePayload>;
  createCertificate?: Maybe<CreateCertificatePayload>;
  createCertificateTemplate?: Maybe<CreateCertificateTemplatePayload>;
  createCollection?: Maybe<CreateCollectionPayload>;
  createCompanyContact?: Maybe<CreateCompanyContactPayload>;
  createConditionStatusReport?: Maybe<CreateConditionStatusReportPayload>;
  createEventLog?: Maybe<CreateEventLogPayload>;
  createExhibition?: Maybe<CreateExhibitionPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createMedia?: Maybe<CreateMediaPayload>;
  createOffice?: Maybe<CreateOfficePayload>;
  createPolicy?: Maybe<CreatePolicyPayload>;
  createPolicyAsset?: Maybe<CreatePolicyAssetPayload>;
  createPolicyDocument?: Maybe<CreatePolicyDocumentPayload>;
  createPolicyNote?: Maybe<CreatePolicyNotePayload>;
  createRegistry?: Maybe<CreateRegistryPayload>;
  createRegistryWithCompanyContact?: Maybe<CreateRegistryWithCompanyContactPayload>;
  createRestoration?: Maybe<CreateRestorationPayload>;
  createUser?: Maybe<CreateUserPayload>;
  deleteAppraisal?: Maybe<DeleteAppraisalPayload>;
  deleteAppraisalMedia?: Maybe<DeleteAppraisalMediaPayload>;
  deleteAsset?: Maybe<DeleteAssetPayload>;
  deleteAssetCarryingValueFile?: Maybe<DeleteAssetCarryingValueFilePayload>;
  deleteAssetDocument?: Maybe<DeleteAssetDocumentPayload>;
  deleteAssetDocumentAttachment?: Maybe<DeleteAssetDocumentAttachmentPayload>;
  deleteAssetElement?: Maybe<DeleteAssetElementPayload>;
  deleteAssetMedia?: Maybe<DeleteAssetMediaPayload>;
  deleteAssetPresentValue?: Maybe<DeleteAssetPresentValuePayload>;
  deleteCertificate?: Maybe<DeleteCertificatePayload>;
  deleteCertificateTemplate?: Maybe<DeleteCertificateTemplatePayload>;
  deleteCollection?: Maybe<DeleteCollectionPayload>;
  deleteCompanyContact?: Maybe<DeleteCompanyContactPayload>;
  deleteConditionStatusReport?: Maybe<DeleteConditionStatusReportPayload>;
  deleteConditionStatusReportMedia?: Maybe<DeleteConditionStatusReportMediaPayload>;
  deleteEventLog?: Maybe<DeleteEventLogPayload>;
  deleteExhibition?: Maybe<DeleteExhibitionPayload>;
  deleteInsurance?: Maybe<DeleteInsurancePayload>;
  deleteMedia?: Maybe<DeleteMediaPayload>;
  deleteOffice?: Maybe<DeleteOfficePayload>;
  deletePolicy?: Maybe<DeletePolicyPayload>;
  deletePolicyAsset?: Maybe<DeletePolicyAssetPayload>;
  deletePolicyDocument?: Maybe<DeletePolicyDocumentPayload>;
  deletePolicyNote?: Maybe<DeletePolicyNotePayload>;
  deleteRegistry?: Maybe<DeleteRegistryPayload>;
  deleteRestoration?: Maybe<DeleteRestorationPayload>;
  deleteRestorationMedia?: Maybe<DeleteRestorationMediaPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserSignature?: Maybe<DeleteUserSignaturePayload>;
  detachAssetFromAppraisal?: Maybe<DetachAssetFromAppraisalPayload>;
  detachAssetFromCollection?: Maybe<DetachAssetFromCollectionPayload>;
  detachAssetFromConditionStatusReport?: Maybe<DetachAssetFromConditionStatusReportPayload>;
  detachAssetFromExhibition?: Maybe<DetachAssetFromExhibitionPayload>;
  detachAssetFromRestoration?: Maybe<DetachAssetFromRestorationPayload>;
  duplicatePolicy?: Maybe<DuplicatePolicyPayload>;
  exportAppraisalPdf?: Maybe<ExportAppraisalPdfPayload>;
  exportAssetPdf?: Maybe<ExportAssetPdfPayload>;
  exportCollectionPdf?: Maybe<ExportCollectionPdfPayload>;
  exportConditionStatusReportPdf?: Maybe<ExportConditionStatusReportPdfPayload>;
  exportExhibitionPdf?: Maybe<ExportExhibitionPdfPayload>;
  exportPolicyPdf?: Maybe<ExportPolicyPdfPayload>;
  exportRestorationPdf?: Maybe<ExportRestorationPdfPayload>;
  extendPolicy?: Maybe<ExtendPolicyPayload>;
  generateCertificate?: Maybe<GenerateCertificatePayload>;
  generateGroupedCertificate?: Maybe<GenerateGroupedCertificatePayload>;
  generateSplitCertificate?: Maybe<GenerateSplitCertificatePayload>;
  invalidateCertificate?: Maybe<InvalidateCertificatePayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  refreshToken?: Maybe<Refresh>;
  removeUserObjectPermission?: Maybe<RemoveUserObjectPermissionPayload>;
  renewPolicy?: Maybe<RenewPolicyPayload>;
  renewUser?: Maybe<RenewUserPayload>;
  requestUserResetPassword?: Maybe<RequestUserResetPasswordPayload>;
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  revertPolicy?: Maybe<RevertPolicyPayload>;
  revokeToken?: Maybe<Revoke>;
  setMainSignature?: Maybe<SetMainSignaturePayload>;
  signCertificate?: Maybe<SignCertificatePayload>;
  terminatePolicy?: Maybe<TerminatePolicyPayload>;
  toggleUserIsactive?: Maybe<ToggleUserIsActivePayload>;
  toggleUserUserType?: Maybe<ToggleUserUserTypePayload>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateAppraisal?: Maybe<UpdateAppraisalPayload>;
  updateAsset?: Maybe<UpdateAssetPayload>;
  updateAssetDocument?: Maybe<UpdateAssetDocumentPayload>;
  updateAssetDocumentAttachment?: Maybe<UpdateAssetDocumentAttachmentPayload>;
  updateAssetElement?: Maybe<UpdateAssetElementPayload>;
  updateAssetPresentValue?: Maybe<UpdateAssetPresentValuePayload>;
  updateCertificate?: Maybe<UpdateCertificatePayload>;
  updateCollection?: Maybe<UpdateCollectionPayload>;
  updateCompanyContact?: Maybe<UpdateCompanyContactPayload>;
  updateConditionStatusReport?: Maybe<UpdateConditionStatusReportPayload>;
  updateEventLog?: Maybe<UpdateEventLogPayload>;
  updateExhibition?: Maybe<UpdateExhibitionPayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateMedia?: Maybe<UpdateMediaPayload>;
  updateOffice?: Maybe<UpdateOfficePayload>;
  updatePolicy?: Maybe<UpdatePolicyPayload>;
  updatePolicyAsset?: Maybe<UpdatePolicyAssetPayload>;
  updatePolicyAssetGroup?: Maybe<UpdatePolicyAssetGroupPayload>;
  updatePolicyDocument?: Maybe<UpdatePolicyDocumentPayload>;
  updatePolicyNote?: Maybe<UpdatePolicyNotePayload>;
  updateRegistry?: Maybe<UpdateRegistryPayload>;
  updateRestoration?: Maybe<UpdateRestorationPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserPermissions?: Maybe<UpdateUserPermissionPayload>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAddAppraisalMediaArgs = {
  input: AddAppraisalMediaInput;
};

export type MutationAddAssetMediaArgs = {
  input: AddAssetMediaInput;
};

export type MutationAddConditionStatusReportMediaArgs = {
  input: AddConditionStatusReportMediaInput;
};

export type MutationAddRestorationMediaArgs = {
  input: AddRestorationMediaInput;
};

export type MutationAddUserObjectPermissionArgs = {
  input: AddUserObjectPermissionInput;
};

export type MutationAddUserObjectPermissionFromCollectionArgs = {
  input: AddUserObjectPermissionFromCollectionInput;
};

export type MutationAddUserPermissionArgs = {
  input: AddUserPermissionInput;
};

export type MutationAddUserSignatureArgs = {
  input: AddUserSignatureInput;
};

export type MutationChangeAssetCategoryArgs = {
  input: ChangeAssetCategoryInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCloseAppraisalArgs = {
  input: CloseAppraisalInput;
};

export type MutationCloseConditionStatusReportArgs = {
  input: CloseConditionStatusReportInput;
};

export type MutationCloseRestorationArgs = {
  input: CloseRestorationInput;
};

export type MutationCreateAppraisalArgs = {
  input: CreateAppraisalInput;
};

export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};

export type MutationCreateAssetDocumentArgs = {
  input: CreateAssetDocumentInput;
};

export type MutationCreateAssetDocumentAttachmentArgs = {
  input: CreateAssetDocumentAttachmentInput;
};

export type MutationCreateAssetElementArgs = {
  input: CreateAssetElementInput;
};

export type MutationCreateAssetPresentValueArgs = {
  input: CreateAssetPresentValueInput;
};

export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput;
};

export type MutationCreateCertificateTemplateArgs = {
  input: CreateCertificateTemplateInput;
};

export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};

export type MutationCreateCompanyContactArgs = {
  input: CreateCompanyContactInput;
};

export type MutationCreateConditionStatusReportArgs = {
  input: CreateConditionStatusReportInput;
};

export type MutationCreateEventLogArgs = {
  input: CreateEventLogInput;
};

export type MutationCreateExhibitionArgs = {
  input: CreateExhibitionInput;
};

export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};

export type MutationCreateMediaArgs = {
  input: CreateMediaInput;
};

export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};

export type MutationCreatePolicyArgs = {
  input: CreatePolicyInput;
};

export type MutationCreatePolicyAssetArgs = {
  input: CreatePolicyAssetInput;
};

export type MutationCreatePolicyDocumentArgs = {
  input: CreatePolicyDocumentInput;
};

export type MutationCreatePolicyNoteArgs = {
  input: CreatePolicyNoteInput;
};

export type MutationCreateRegistryArgs = {
  input: CreateRegistryInput;
};

export type MutationCreateRegistryWithCompanyContactArgs = {
  input: CreateRegistryWithCompanyContactInput;
};

export type MutationCreateRestorationArgs = {
  input: CreateRestorationInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteAppraisalArgs = {
  input: DeleteAppraisalInput;
};

export type MutationDeleteAppraisalMediaArgs = {
  input: DeleteAppraisalMediaInput;
};

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};

export type MutationDeleteAssetCarryingValueFileArgs = {
  input: DeleteAssetCarryingValueFileInput;
};

export type MutationDeleteAssetDocumentArgs = {
  input: DeleteAssetDocumentInput;
};

export type MutationDeleteAssetDocumentAttachmentArgs = {
  input: DeleteAssetDocumentAttachmentInput;
};

export type MutationDeleteAssetElementArgs = {
  input: DeleteAssetElementInput;
};

export type MutationDeleteAssetMediaArgs = {
  input: DeleteAssetMediaInput;
};

export type MutationDeleteAssetPresentValueArgs = {
  input: DeleteAssetPresentValueInput;
};

export type MutationDeleteCertificateArgs = {
  input: DeleteCertificateInput;
};

export type MutationDeleteCertificateTemplateArgs = {
  input: DeleteCertificateTemplateInput;
};

export type MutationDeleteCollectionArgs = {
  input: DeleteCollectionInput;
};

export type MutationDeleteCompanyContactArgs = {
  input: DeleteCompanyContactInput;
};

export type MutationDeleteConditionStatusReportArgs = {
  input: DeleteConditionStatusReportInput;
};

export type MutationDeleteConditionStatusReportMediaArgs = {
  input: DeleteConditionStatusReportMediaInput;
};

export type MutationDeleteEventLogArgs = {
  input: DeleteEventLogInput;
};

export type MutationDeleteExhibitionArgs = {
  input: DeleteExhibitionInput;
};

export type MutationDeleteInsuranceArgs = {
  input: DeleteInsuranceInput;
};

export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};

export type MutationDeleteOfficeArgs = {
  input: DeleteOfficeInput;
};

export type MutationDeletePolicyArgs = {
  input: DeletePolicyInput;
};

export type MutationDeletePolicyAssetArgs = {
  input: DeletePolicyAssetInput;
};

export type MutationDeletePolicyDocumentArgs = {
  input: DeletePolicyDocumentInput;
};

export type MutationDeletePolicyNoteArgs = {
  input: DeletePolicyNoteInput;
};

export type MutationDeleteRegistryArgs = {
  input: DeleteRegistryInput;
};

export type MutationDeleteRestorationArgs = {
  input: DeleteRestorationInput;
};

export type MutationDeleteRestorationMediaArgs = {
  input: DeleteRestorationMediaInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDeleteUserSignatureArgs = {
  input: DeleteUserSignatureInput;
};

export type MutationDetachAssetFromAppraisalArgs = {
  input: DetachAssetFromAppraisalInput;
};

export type MutationDetachAssetFromCollectionArgs = {
  input: DetachAssetFromCollectionInput;
};

export type MutationDetachAssetFromConditionStatusReportArgs = {
  input: DetachAssetFromConditionStatusReportInput;
};

export type MutationDetachAssetFromExhibitionArgs = {
  input: DetachAssetFromExhibitionInput;
};

export type MutationDetachAssetFromRestorationArgs = {
  input: DetachAssetFromRestorationInput;
};

export type MutationDuplicatePolicyArgs = {
  input: DuplicatePolicyInput;
};

export type MutationExportAppraisalPdfArgs = {
  input: ExportAppraisalPdfInput;
};

export type MutationExportAssetPdfArgs = {
  input: ExportAssetPdfInput;
};

export type MutationExportCollectionPdfArgs = {
  input: ExportCollectionPdfInput;
};

export type MutationExportConditionStatusReportPdfArgs = {
  input: ExportConditionStatusReportPdfInput;
};

export type MutationExportExhibitionPdfArgs = {
  input: ExportExhibitionPdfInput;
};

export type MutationExportPolicyPdfArgs = {
  input: ExportPolicyPdfInput;
};

export type MutationExportRestorationPdfArgs = {
  input: ExportRestorationPdfInput;
};

export type MutationExtendPolicyArgs = {
  input: ExtendPolicyInput;
};

export type MutationGenerateCertificateArgs = {
  input: GenerateCertificateInput;
};

export type MutationGenerateGroupedCertificateArgs = {
  input: GenerateGroupedCertificateInput;
};

export type MutationGenerateSplitCertificateArgs = {
  input: GenerateSplitCertificateInput;
};

export type MutationInvalidateCertificateArgs = {
  input: InvalidateCertificateInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRemoveUserObjectPermissionArgs = {
  input: RemoveUserObjectPermissionInput;
};

export type MutationRenewPolicyArgs = {
  input: RenewPolicyInput;
};

export type MutationRenewUserArgs = {
  input: RenewUserInput;
};

export type MutationRequestUserResetPasswordArgs = {
  input: RequestUserResetPasswordInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationRevertPolicyArgs = {
  input: RevertPolicyInput;
};

export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationSetMainSignatureArgs = {
  input: SetMainSignatureInput;
};

export type MutationSignCertificateArgs = {
  input: SignCertificateInput;
};

export type MutationTerminatePolicyArgs = {
  input: TerminatePolicyInput;
};

export type MutationToggleUserIsactiveArgs = {
  input: ToggleUserIsActiveInput;
};

export type MutationToggleUserUserTypeArgs = {
  input: ToggleUserUserTypeInput;
};

export type MutationTokenAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUpdateAppraisalArgs = {
  input: UpdateAppraisalInput;
};

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};

export type MutationUpdateAssetDocumentArgs = {
  input: UpdateAssetDocumentInput;
};

export type MutationUpdateAssetDocumentAttachmentArgs = {
  input: UpdateAssetDocumentAttachmentInput;
};

export type MutationUpdateAssetElementArgs = {
  input: UpdateAssetElementInput;
};

export type MutationUpdateAssetPresentValueArgs = {
  input: UpdateAssetPresentValueInput;
};

export type MutationUpdateCertificateArgs = {
  input: UpdateCertificateInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationUpdateCompanyContactArgs = {
  input: UpdateCompanyContactInput;
};

export type MutationUpdateConditionStatusReportArgs = {
  input: UpdateConditionStatusReportInput;
};

export type MutationUpdateEventLogArgs = {
  input: UpdateEventLogInput;
};

export type MutationUpdateExhibitionArgs = {
  input: UpdateExhibitionInput;
};

export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};

export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};

export type MutationUpdatePolicyAssetArgs = {
  input: UpdatePolicyAssetInput;
};

export type MutationUpdatePolicyAssetGroupArgs = {
  input: UpdatePolicyAssetGroupInput;
};

export type MutationUpdatePolicyDocumentArgs = {
  input: UpdatePolicyDocumentInput;
};

export type MutationUpdatePolicyNoteArgs = {
  input: UpdatePolicyNoteInput;
};

export type MutationUpdateRegistryArgs = {
  input: UpdateRegistryInput;
};

export type MutationUpdateRestorationArgs = {
  input: UpdateRestorationInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserPermissionsArgs = {
  input: UpdateUserPermissionInput;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars["ID"];
};

export type ObtainJsonWebToken = {
  __typename?: "ObtainJSONWebToken";
  algoliaUserKey?: Maybe<Scalars["String"]>;
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
  user?: Maybe<UserType>;
};

export type OfficeInput = {
  active: Scalars["Boolean"];
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  main: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  officeAddress?: InputMaybe<AddressInput>;
  phone?: InputMaybe<Scalars["String"]>;
  registry: Scalars["ID"];
  state?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type OfficeNode = Node & {
  __typename?: "OfficeNode";
  active: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  email?: Maybe<Scalars["String"]>;
  eventLocationEntity: ExhibitionNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  objectLocationOffice: PolicyAssetNodeConnection;
  objectLocationOfficeHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeAddress?: Maybe<Address>;
  phone?: Maybe<Scalars["String"]>;
  policyEventLocationEntity: PolicyNodeConnection;
  registry: RegistryNode;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeNodeEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeObjectLocationOfficeHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeConnection = {
  __typename?: "OfficeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OfficeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OfficeNode` and its cursor. */
export type OfficeNodeEdge = {
  __typename?: "OfficeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<OfficeNode>;
};

export type OfficeType = {
  __typename?: "OfficeType";
  active: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  email?: Maybe<Scalars["String"]>;
  eventLocationEntity: ExhibitionNodeConnection;
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  objectLocationOffice: PolicyAssetNodeConnection;
  objectLocationOfficeHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeAddress?: Maybe<Address>;
  phone?: Maybe<Scalars["String"]>;
  policyEventLocationEntity: PolicyNodeConnection;
  registry: RegistryNode;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeTypeEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypeObjectLocationOfficeHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type PermissionNode = Node & {
  __typename?: "PermissionNode";
  codename: Scalars["String"];
  contentType: ContentTypeNode;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  /** Specific permissions for this user. */
  userSet: UserNodeConnection;
};

export type PermissionNodeUserSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PermissionNodeConnection = {
  __typename?: "PermissionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PermissionNode` and its cursor. */
export type PermissionNodeEdge = {
  __typename?: "PermissionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
};

export type PolicyAssetBasketHistoryNode = Node & {
  __typename?: "PolicyAssetBasketHistoryNode";
  asset: AssetNode;
  catNat: Scalars["Boolean"];
  coverTerrorism: Scalars["Boolean"];
  coverType: SpinPolicyAssetBasketHistoryCoverTypeChoices;
  coveredRiskType: SpinPolicyAssetBasketHistoryCoveredRiskTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliveryAddress?: Maybe<Address>;
  diff?: Maybe<Scalars["String"]>;
  evaluationType: SpinPolicyAssetBasketHistoryEvaluationTypeChoices;
  exemption: Scalars["Boolean"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  insuredValue?: Maybe<Money>;
  insuredValueCurrency: SpinPolicyAssetBasketHistoryInsuredValueCurrencyChoices;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  lender?: Maybe<RegistryNode>;
  lenderAddress?: Maybe<Address>;
  objectLocationEntity?: Maybe<RegistryNode>;
  objectLocationOffice?: Maybe<OfficeNode>;
  pickupAddress?: Maybe<Address>;
  policy: PolicyNode;
  policyAsset: PolicyAssetNode;
  policyBasketHistory: PolicyBasketHistoryNode;
  shipper?: Maybe<RegistryNode>;
  status: SpinPolicyAssetBasketHistoryStatusChoices;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyAssetBasketHistoryNodeConnection = {
  __typename?: "PolicyAssetBasketHistoryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyAssetBasketHistoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyAssetBasketHistoryNode` and its cursor. */
export type PolicyAssetBasketHistoryNodeEdge = {
  __typename?: "PolicyAssetBasketHistoryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyAssetBasketHistoryNode>;
};

export type PolicyAssetInput = {
  asset?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryAddress?: InputMaybe<AddressInput>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  insuredValue?: InputMaybe<MoneyInput>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  lenderAddress?: InputMaybe<AddressInput>;
  lenderId?: InputMaybe<Scalars["ID"]>;
  objectLocationEntityId?: InputMaybe<Scalars["ID"]>;
  objectLocationOfficeId?: InputMaybe<Scalars["ID"]>;
  pickupAddress?: InputMaybe<AddressInput>;
  policy?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type PolicyAssetNode = Node & {
  __typename?: "PolicyAssetNode";
  asset: AssetNode;
  catNat: Scalars["Boolean"];
  certificateProgressiveNumber?: Maybe<Scalars["Int"]>;
  certificateSet: CertificateNodeConnection;
  coverTerrorism: Scalars["Boolean"];
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliveryAddress?: Maybe<Address>;
  evaluationType: Scalars["String"];
  exemption: Scalars["Boolean"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  historicalPolicyAsset: PolicyAssetBasketHistoryNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  insuredValue?: Maybe<Money>;
  insuredValueCurrency: Scalars["String"];
  issuedAt?: Maybe<Scalars["DateTime"]>;
  lender?: Maybe<RegistryNode>;
  lenderAddress?: Maybe<Address>;
  objectLocationEntity?: Maybe<RegistryNode>;
  objectLocationOffice?: Maybe<OfficeNode>;
  pickupAddress?: Maybe<Address>;
  policy: PolicyNode;
  shipper?: Maybe<RegistryNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyAssetNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyAssetNodeHistoricalPolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyAssetNodeConnection = {
  __typename?: "PolicyAssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyAssetNode` and its cursor. */
export type PolicyAssetNodeEdge = {
  __typename?: "PolicyAssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyAssetNode>;
};

export type PolicyBasketHistoryNode = Node & {
  __typename?: "PolicyBasketHistoryNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  overrideChangeDate?: Maybe<Scalars["String"]>;
  policy: PolicyNode;
  policyassetbaskethistorySet: PolicyAssetBasketHistoryNodeConnection;
  reason: Scalars["String"];
  status: SpinPolicyBasketHistoryStatusChoices;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyBasketHistoryNodePolicyassetbaskethistorySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyBasketHistoryNodeConnection = {
  __typename?: "PolicyBasketHistoryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyBasketHistoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyBasketHistoryNode` and its cursor. */
export type PolicyBasketHistoryNodeEdge = {
  __typename?: "PolicyBasketHistoryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyBasketHistoryNode>;
};

export type PolicyDocumentInput = {
  description?: InputMaybe<Scalars["String"]>;
  extension?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

export type PolicyDocumentNode = Node & {
  __typename?: "PolicyDocumentNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  policy: PolicyNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  weight?: Maybe<Scalars["Float"]>;
};

export type PolicyDocumentNodeConnection = {
  __typename?: "PolicyDocumentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyDocumentNode` and its cursor. */
export type PolicyDocumentNodeEdge = {
  __typename?: "PolicyDocumentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyDocumentNode>;
};

export type PolicyEventsNode = Node & {
  __typename?: "PolicyEventsNode";
  action?: Maybe<SpinPolicyEventsActionChoices>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  policy: PolicyNode;
  progressiveNumber: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyEventsNodeConnection = {
  __typename?: "PolicyEventsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyEventsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyEventsNode` and its cursor. */
export type PolicyEventsNodeEdge = {
  __typename?: "PolicyEventsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyEventsNode>;
};

export type PolicyInput = {
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  brokerId?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  contractingPartyId: Scalars["ID"];
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntityId: Scalars["ID"];
  eventFrom?: InputMaybe<Scalars["String"]>;
  eventLocationEntityId: Scalars["ID"];
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt: Scalars["DateTime"];
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompanyId: Scalars["ID"];
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyAssets?: InputMaybe<Array<InputMaybe<PolicyAssetInput>>>;
  policyCode: Scalars["String"];
  policyDocuments?: InputMaybe<Array<InputMaybe<PolicyDocumentInput>>>;
  policyNote?: InputMaybe<Array<InputMaybe<PolicyNoteInput>>>;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  totalInsuredPremium?: InputMaybe<MoneyInput>;
  totalInsuredValue?: InputMaybe<MoneyInput>;
};

export type PolicyNode = Node & {
  __typename?: "PolicyNode";
  assets: AssetNodeConnection;
  assetsCount?: Maybe<Scalars["Int"]>;
  autoRenewal: Scalars["Boolean"];
  basketChanges?: Maybe<Array<Maybe<PolicyAssetNode>>>;
  broker?: Maybe<RegistryNode>;
  catNat: Scalars["Boolean"];
  certificateSet: CertificateNodeConnection;
  collection?: Maybe<CollectionNode>;
  contractingParty: RegistryNode;
  coverTerrorism: Scalars["Boolean"];
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  diffChanges?: Maybe<Array<Maybe<DiffChangesNode>>>;
  evaluationType: Scalars["String"];
  eventEntity?: Maybe<RegistryNode>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocationEntity?: Maybe<OfficeNode>;
  eventName?: Maybe<Scalars["String"]>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exemption: Scalars["Boolean"];
  exhibition?: Maybe<ExhibitionNode>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  hasValidCertificate: Scalars["Boolean"];
  hasVariableBasket: Scalars["Boolean"];
  history?: Maybe<Array<Maybe<HistoryListItemNode>>>;
  /** The ID of the object */
  id: Scalars["ID"];
  insuranceCompany: RegistryNode;
  isExpired?: Maybe<Scalars["Boolean"]>;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  offerCode: Scalars["String"];
  policyAssets: PolicyAssetNodeConnection;
  policyAssetsHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyCode: Scalars["String"];
  policyDocuments: PolicyDocumentNodeConnection;
  policyEvents: PolicyEventsNodeConnection;
  policyNotes: PolicyNoteNodeConnection;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  policybaskethistorySet: PolicyBasketHistoryNodeConnection;
  totalInsuredPremium?: Maybe<Money>;
  totalInsuredPremiumCurrency: Scalars["String"];
  totalInsuredValue?: Maybe<Money>;
  totalInsuredValueCurrency: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyNodeAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyAssetsHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicybaskethistorySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodeConnection = {
  __typename?: "PolicyNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyNode` and its cursor. */
export type PolicyNodeEdge = {
  __typename?: "PolicyNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNode>;
};

export type PolicyNoteInput = {
  content?: InputMaybe<Scalars["String"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type PolicyNoteNode = Node & {
  __typename?: "PolicyNoteNode";
  content?: Maybe<Scalars["String"]>;
  createdBy: UserNode;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  policy: PolicyNode;
  uuid: Scalars["UUID"];
};

export type PolicyNoteNodeConnection = {
  __typename?: "PolicyNoteNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyNoteNode` and its cursor. */
export type PolicyNoteNodeEdge = {
  __typename?: "PolicyNoteNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNoteNode>;
};

export type Query = {
  __typename?: "Query";
  allAppraisals?: Maybe<AppraisalNodeConnection>;
  allAssetDocumentAttachments?: Maybe<AssetDocumentAttachmentNodeConnection>;
  allAssetDocuments?: Maybe<AssetDocumentNodeConnection>;
  allAssetElements?: Maybe<AssetElementNodeConnection>;
  allAssetPresentValues?: Maybe<AssetPresentValueNodeConnection>;
  allAssets?: Maybe<AssetNodeConnection>;
  allAssetsForUser?: Maybe<AssetNodeConnection>;
  allCertificateTemplates?: Maybe<CertificateTemplateNodeConnection>;
  allCertificates?: Maybe<CertificateNodeConnection>;
  allClient?: Maybe<ClientNodeConnection>;
  allCollections?: Maybe<CollectionNodeConnection>;
  allCompanyContacts?: Maybe<CompanyContactNodeConnection>;
  allConditionStatusReports?: Maybe<ConditionStatusReportNodeConnection>;
  allContenttypes?: Maybe<ContentTypeNodeConnection>;
  allEventLogs?: Maybe<EventLogNodeConnection>;
  allExhibitions?: Maybe<ExhibitionNodeConnection>;
  allInsurances?: Maybe<InsuranceNodeConnection>;
  allMedias?: Maybe<MediaNodeConnection>;
  allOffices?: Maybe<OfficeNodeConnection>;
  allPermissions?: Maybe<PermissionNodeConnection>;
  allPolicies?: Maybe<PolicyNodeConnection>;
  allPolicyAssetBasketHistories?: Maybe<PolicyAssetBasketHistoryNodeConnection>;
  allPolicyAssets?: Maybe<PolicyAssetNodeConnection>;
  allPolicyBasketHistories?: Maybe<PolicyBasketHistoryNodeConnection>;
  allPolicyDocuments?: Maybe<PolicyDocumentNodeConnection>;
  allPolicyNotes?: Maybe<PolicyNoteNodeConnection>;
  allRegistries?: Maybe<RegistryNodeConnection>;
  allRegistriesWithDeleted?: Maybe<RegistryNodeConnection>;
  allRestorations?: Maybe<RestorationNodeConnection>;
  allUsers?: Maybe<UserNodeConnection>;
  appraisal?: Maybe<AppraisalNode>;
  appraisals?: Maybe<Array<Maybe<AppraisalNode>>>;
  asset?: Maybe<AssetNode>;
  assetDocument?: Maybe<AssetDocumentNode>;
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  assetDocumentAttachments?: Maybe<Array<Maybe<AssetDocumentAttachmentNode>>>;
  assetElement?: Maybe<AssetElementNode>;
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  certificate?: Maybe<CertificateNode>;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  client?: Maybe<ClientNode>;
  collection?: Maybe<CollectionNode>;
  companyContact?: Maybe<CompanyContactNode>;
  companyContacts?: Maybe<Array<Maybe<CompanyContactNode>>>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
  conditionStatusReports?: Maybe<Array<Maybe<ConditionStatusReportNode>>>;
  contenttype?: Maybe<ContentTypeNode>;
  createAppraisal?: Maybe<CreateAppraisalPayload>;
  createConditionStatusReport?: Maybe<CreateConditionStatusReportPayload>;
  createRestoration?: Maybe<CreateRestorationPayload>;
  eventLog?: Maybe<EventLogNode>;
  exhibition?: Maybe<ExhibitionNode>;
  filteredInsurances?: Maybe<FilteredInsuranceNodeConnection>;
  insurance?: Maybe<InsuranceNode>;
  insurances?: Maybe<Array<Maybe<InsuranceNode>>>;
  media?: Maybe<MediaNode>;
  medias?: Maybe<Array<Maybe<MediaNode>>>;
  notUserAssets?: Maybe<AssetNodeConnection>;
  office?: Maybe<OfficeNode>;
  offices?: Maybe<Array<Maybe<OfficeNode>>>;
  permission?: Maybe<PermissionNode>;
  policy?: Maybe<PolicyNode>;
  policyAsset?: Maybe<PolicyAssetNode>;
  policyAssetBasketHistory?: Maybe<PolicyAssetBasketHistoryNode>;
  policyAssetGroups?: Maybe<GroupsNode>;
  policyBasketHistory?: Maybe<PolicyBasketHistoryNode>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  policyNote?: Maybe<PolicyNoteNode>;
  registries?: Maybe<Array<Maybe<RegistryNode>>>;
  registry?: Maybe<RegistryNode>;
  restoration?: Maybe<RestorationNode>;
  restorations?: Maybe<Array<Maybe<RestorationNode>>>;
  searchAssets?: Maybe<AssetNodeConnection>;
  user?: Maybe<UserNode>;
};

export type QueryAllAppraisalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appraisalMotivation?: InputMaybe<Scalars["String"]>;
  appraisalMotivation_Contains?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  document?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetPresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetsForUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type QueryAllCertificateTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCertificatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grouping?: InputMaybe<Scalars["String"]>;
  isSplitted?: InputMaybe<Scalars["Boolean"]>;
  isValid?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policyAsset?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  printTemplate?: InputMaybe<Scalars["ID"]>;
  progressiveNumber?: InputMaybe<Scalars["Int"]>;
  signed?: InputMaybe<Scalars["Boolean"]>;
  signedAt?: InputMaybe<Scalars["DateTime"]>;
  signedBy?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllClientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdOn?: InputMaybe<Scalars["Date"]>;
  currency?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  dashboardBaseUrl?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onTrial?: InputMaybe<Scalars["Boolean"]>;
  paidUntil?: InputMaybe<Scalars["Date"]>;
  schemaName?: InputMaybe<Scalars["String"]>;
  storageName?: InputMaybe<Scalars["String"]>;
  tier?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Contains?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCompanyContactsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  companyContact?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  main?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  relatedCompany?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllConditionStatusReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  reportMotivation?: InputMaybe<Scalars["String"]>;
  reportMotivation_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllContenttypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appLabel?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  model?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllEventLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  eventType?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllExhibitionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Contains?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllMediasArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllOfficesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  registry?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codename?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  assets?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  collection?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntity?: InputMaybe<Scalars["ID"]>;
  eventFrom?: InputMaybe<Scalars["DateTime"]>;
  eventLocationEntity?: InputMaybe<Scalars["ID"]>;
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["DateTime"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  exhibition?: InputMaybe<Scalars["ID"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  hasValidCertificate?: InputMaybe<Scalars["Boolean"]>;
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offerCode?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyCode?: InputMaybe<Scalars["String"]>;
  policyStatus?: InputMaybe<Scalars["String"]>;
  policyType?: InputMaybe<Scalars["String"]>;
  totalInsuredPremium?: InputMaybe<Scalars["String"]>;
  totalInsuredPremiumCurrency?: InputMaybe<Scalars["String"]>;
  totalInsuredValue?: InputMaybe<Scalars["String"]>;
  totalInsuredValueCurrency?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyAssetBasketHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  deliveryAddress?: InputMaybe<Scalars["ID"]>;
  diff?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  insuredValue?: InputMaybe<Scalars["String"]>;
  insuredValueCurrency?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lender?: InputMaybe<Scalars["ID"]>;
  lenderAddress?: InputMaybe<Scalars["ID"]>;
  objectLocationEntity?: InputMaybe<Scalars["ID"]>;
  objectLocationOffice?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupAddress?: InputMaybe<Scalars["ID"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policyAsset?: InputMaybe<Scalars["ID"]>;
  policyBasketHistory?: InputMaybe<Scalars["ID"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  deliveryAddress?: InputMaybe<Scalars["ID"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  insuredValue?: InputMaybe<Scalars["String"]>;
  insuredValueCurrency?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lender?: InputMaybe<Scalars["ID"]>;
  lenderAddress?: InputMaybe<Scalars["ID"]>;
  objectLocationEntity?: InputMaybe<Scalars["ID"]>;
  objectLocationOffice?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  pickupAddress?: InputMaybe<Scalars["ID"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policy_PolicyType?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyBasketHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  reason?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllRegistriesArgs = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  invoiceAddress?: InputMaybe<Scalars["ID"]>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type QueryAllRegistriesWithDeletedArgs = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  invoiceAddress?: InputMaybe<Scalars["ID"]>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type QueryAllRestorationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  currency?: InputMaybe<Scalars["String"]>;
  dateJoined?: InputMaybe<Scalars["DateTime"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  email?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groups?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lastLogin?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
  resetTokenExpiry?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  userPermissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userType?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type QueryAppraisalArgs = {
  id: Scalars["ID"];
};

export type QueryAppraisalsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryAssetArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentAttachmentArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentAttachmentsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryAssetElementArgs = {
  id: Scalars["ID"];
};

export type QueryAssetPresentValueArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryClientArgs = {
  id: Scalars["ID"];
};

export type QueryCollectionArgs = {
  id: Scalars["ID"];
};

export type QueryCompanyContactArgs = {
  id: Scalars["ID"];
};

export type QueryCompanyContactsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  registryId?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryConditionStatusReportArgs = {
  id: Scalars["ID"];
};

export type QueryConditionStatusReportsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryContenttypeArgs = {
  id: Scalars["ID"];
};

export type QueryCreateAppraisalArgs = {
  input: CreateAppraisalInput;
};

export type QueryCreateConditionStatusReportArgs = {
  input: CreateConditionStatusReportInput;
};

export type QueryCreateRestorationArgs = {
  input: CreateRestorationInput;
};

export type QueryEventLogArgs = {
  id: Scalars["ID"];
};

export type QueryExhibitionArgs = {
  id: Scalars["ID"];
};

export type QueryFilteredInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type QueryInsuranceArgs = {
  id: Scalars["ID"];
};

export type QueryInsurancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryMediaArgs = {
  id: Scalars["ID"];
};

export type QueryMediasArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryNotUserAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type QueryOfficeArgs = {
  id: Scalars["ID"];
};

export type QueryOfficesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  registryId?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryPermissionArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetBasketHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetGroupsArgs = {
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type QueryPolicyBasketHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyNoteArgs = {
  id: Scalars["ID"];
};

export type QueryRegistriesArgs = {
  exactSearch?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryRegistryArgs = {
  id: Scalars["ID"];
};

export type QueryRestorationArgs = {
  id: Scalars["ID"];
};

export type QueryRestorationsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type Refresh = {
  __typename?: "Refresh";
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
};

export type RegistryInput = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categoriesCompany?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categoriesPerson?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName: Scalars["String"];
  invoiceAddress?: InputMaybe<AddressInput>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  mainContact?: InputMaybe<Scalars["Boolean"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type RegistryNode = Node & {
  __typename?: "RegistryNode";
  activityPeriod?: Maybe<Scalars["String"]>;
  activityPlace?: Maybe<Scalars["String"]>;
  actualCompanyContactsNumber?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  appraisalEvaluator: AppraisalNodeConnection;
  artisticMovement?: Maybe<Scalars["String"]>;
  assetAuthor: AssetNodeConnection;
  assetManufacturer: AssetNodeConnection;
  assetOwner: AssetNodeConnection;
  assetPresentValueAuthor: AssetPresentValueNodeConnection;
  assetPrinter: AssetNodeConnection;
  assetProducer: AssetNodeConnection;
  assetVideomaker: AssetNodeConnection;
  authorType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  authoredAssetsCount?: Maybe<Scalars["Int"]>;
  billingAddress?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["String"]>;
  birthPlace?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  company?: Maybe<RegistryNode>;
  companyContact: CompanyContactNodeConnection;
  conditionstatusreportExhibitionCurator: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionHostEntity: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionLender: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionPlanner: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsCarrier: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsRecipient: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsSender: ConditionStatusReportNodeConnection;
  conditionstatusreportReportCompany: ConditionStatusReportNodeConnection;
  conditionstatusreportReportExaminer: ConditionStatusReportNodeConnection;
  confidentialNotes?: Maybe<Scalars["String"]>;
  contactDescription?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deathDate?: Maybe<Scalars["String"]>;
  deathPlace?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  eventlogBroker: EventLogNodeConnection;
  eventlogHost: EventLogNodeConnection;
  eventlogNewOwner: EventLogNodeConnection;
  eventlogOrigin: EventLogNodeConnection;
  eventlogOwner: EventLogNodeConnection;
  exhibitionCurator: ExhibitionNodeConnection;
  exhibitionOrganizer: ExhibitionNodeConnection;
  foundation?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  hostEntity: ExhibitionNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  invoiceAddress?: Maybe<Address>;
  isAnonymousTenantDefault?: Maybe<Scalars["Boolean"]>;
  isAuthor?: Maybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: Maybe<Scalars["Boolean"]>;
  isCompany: Scalars["Boolean"];
  isInsuranceCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: Maybe<Scalars["Boolean"]>;
  mainOfficeAddress?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  objectLocationEntity: PolicyAssetNodeConnection;
  objectLocationEntityHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeRegistry: OfficeNodeConnection;
  officesCount?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  policyAssetLender: PolicyAssetNodeConnection;
  policyAssetLenderHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyAssetShipper: PolicyAssetNodeConnection;
  policyAssetShipperHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyBroker: PolicyNodeConnection;
  policyContractingParty: PolicyNodeConnection;
  policyEventEntity: PolicyNodeConnection;
  policyInsuranceCompany: PolicyNodeConnection;
  printCode?: Maybe<Scalars["String"]>;
  printName?: Maybe<Scalars["String"]>;
  registryCompany: RegistryNodeConnection;
  relatedCompany: CompanyContactNodeConnection;
  restorationCompany: RestorationNodeConnection;
  restorationDirector: RestorationNodeConnection;
  role?: Maybe<Scalars["String"]>;
  taxId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type RegistryNodeAppraisalEvaluatorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetManufacturerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetPresentValueAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetPrinterArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetProducerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetVideomakerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeCompanyContactArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionPlannerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsCarrierArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsRecipientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsSenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportReportCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportReportExaminerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogHostArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogNewOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogOriginArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeExhibitionOrganizerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeObjectLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeObjectLocationEntityHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeOfficeRegistryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetLenderHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetShipperHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyEventEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRegistryCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRelatedCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRestorationCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRestorationDirectorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConnection = {
  __typename?: "RegistryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RegistryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RegistryNode` and its cursor. */
export type RegistryNodeEdge = {
  __typename?: "RegistryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RegistryNode>;
};

export type RemoveUserObjectPermissionInput = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type RemoveUserObjectPermissionPayload = {
  __typename?: "RemoveUserObjectPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RenewPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type RenewPolicyPayload = {
  __typename?: "RenewPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type RenewUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type RenewUserPayload = {
  __typename?: "RenewUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RequestUserResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type RequestUserResetPasswordPayload = {
  __typename?: "RequestUserResetPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ResetUserPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword?: InputMaybe<Scalars["String"]>;
  newPassword?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
};

export type ResetUserPasswordPayload = {
  __typename?: "ResetUserPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RestorationInput = {
  conservativeDataAssetAlterations?: InputMaybe<Scalars["String"]>;
  conservativeDataConservationStatus?: InputMaybe<Scalars["String"]>;
  conservativeDataDeteriorationCauses?: InputMaybe<Scalars["String"]>;
  conservativeDataExaminationMethod?: InputMaybe<Scalars["String"]>;
  conservativeDataHistoryInterventions?: InputMaybe<Scalars["String"]>;
  conservativeDataIntervention?: InputMaybe<Scalars["String"]>;
  conservativeDataInterventionMaterials?: InputMaybe<Scalars["String"]>;
  conservativeDataInterventionProposal?: InputMaybe<Scalars["String"]>;
  conservativeDataMaintenancePreparationAssembly?: InputMaybe<Scalars["String"]>;
  conservativeDataNotesRestorationSheet?: InputMaybe<Scalars["String"]>;
  conservativeDataPreliminaryOperations?: InputMaybe<Scalars["String"]>;
  conservativeDataRestorationBibliography?: InputMaybe<Scalars["String"]>;
  conservativeDataTechnicalMethodologicalConsiderations?: InputMaybe<Scalars["String"]>;
  restorationAsset?: InputMaybe<Scalars["ID"]>;
  restorationCollocationFeatures?: InputMaybe<Scalars["String"]>;
  restorationCompany?: InputMaybe<Scalars["ID"]>;
  restorationCost?: InputMaybe<MoneyInput>;
  restorationDirector: Scalars["ID"];
  restorationDocumentDate?: InputMaybe<Scalars["DateTime"]>;
  restorationEndDate?: InputMaybe<Scalars["DateTime"]>;
  restorationInventoryNumber?: InputMaybe<Scalars["String"]>;
  restorationLocation?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy?: InputMaybe<Scalars["String"]>;
  restorationStartDate?: InputMaybe<Scalars["DateTime"]>;
  restorationStatus: Scalars["String"];
};

export type RestorationNode = Node & {
  __typename?: "RestorationNode";
  actualMediaStatus?: Maybe<Scalars["String"]>;
  conservativeDataAssetAlterations?: Maybe<Scalars["String"]>;
  conservativeDataConservationStatus?: Maybe<Scalars["String"]>;
  conservativeDataDeteriorationCauses?: Maybe<Scalars["String"]>;
  conservativeDataExaminationMethod?: Maybe<Scalars["String"]>;
  conservativeDataHistoryInterventions?: Maybe<Scalars["String"]>;
  conservativeDataIntervention?: Maybe<Scalars["String"]>;
  conservativeDataInterventionMaterials?: Maybe<Scalars["String"]>;
  conservativeDataInterventionProposal?: Maybe<Scalars["String"]>;
  conservativeDataMaintenancePreparationAssembly?: Maybe<Scalars["String"]>;
  conservativeDataNotesRestorationSheet?: Maybe<Scalars["String"]>;
  conservativeDataPreliminaryOperations?: Maybe<Scalars["String"]>;
  conservativeDataRestorationBibliography?: Maybe<Scalars["String"]>;
  conservativeDataTechnicalMethodologicalConsiderations?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  media: MediaNodeConnection;
  mediaNumber?: Maybe<Scalars["Int"]>;
  restorationAsset: AssetNode;
  restorationCollocationFeatures?: Maybe<Scalars["String"]>;
  restorationCompany?: Maybe<RegistryNode>;
  restorationCost?: Maybe<Money>;
  restorationCostCurrency: ManagementRestorationRestorationCostCurrencyChoices;
  restorationDirector: RegistryNode;
  restorationDocumentDate?: Maybe<Scalars["DateTime"]>;
  restorationEndDate?: Maybe<Scalars["DateTime"]>;
  restorationInventoryNumber?: Maybe<Scalars["String"]>;
  restorationLocation?: Maybe<Scalars["String"]>;
  restorationRestoredBy?: Maybe<Scalars["String"]>;
  restorationStartDate?: Maybe<Scalars["DateTime"]>;
  restorationStatus?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type RestorationNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RestorationNodeConnection = {
  __typename?: "RestorationNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RestorationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RestorationNode` and its cursor. */
export type RestorationNodeEdge = {
  __typename?: "RestorationNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RestorationNode>;
};

export type RevertPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  historyId?: InputMaybe<Scalars["Int"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type RevertPolicyPayload = {
  __typename?: "RevertPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  phrase?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type Revoke = {
  __typename?: "Revoke";
  revoked: Scalars["Int"];
};

export type SetMainSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  signatureId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type SetMainSignaturePayload = {
  __typename?: "SetMainSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSignature?: Maybe<UserSignatureType>;
};

export type SignCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type SignCertificatePayload = {
  __typename?: "SignCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum SpinCertificateCertificateTypeChoices {
  /** Permanent Collection */
  PermanentCollection = "PERMANENT_COLLECTION",
  /** Temporary Exhibition */
  TemporaryExhibition = "TEMPORARY_EXHIBITION",
}

/** An enumeration. */
export enum SpinCertificateGroupingChoices {
  /** grouped */
  Grouped = "GROUPED",
  /** splitted */
  Splitted = "SPLITTED",
  /** total */
  Total = "TOTAL",
}

/** An enumeration. */
export enum SpinPolicyAssetBasketHistoryCoverTypeChoices {
  /** Nail to Nail */
  NailToNail = "NAIL_TO_NAIL",
  /** Stay Risk */
  StayRisk = "STAY_RISK",
  /** Travel Risk */
  TravelRisk = "TRAVEL_RISK",
}

/** An enumeration. */
export enum SpinPolicyAssetBasketHistoryCoveredRiskTypeChoices {
  /** All Risk */
  AllRisk = "ALL_RISK",
  /** First Risk */
  FirstRisk = "FIRST_RISK",
}

/** An enumeration. */
export enum SpinPolicyAssetBasketHistoryEvaluationTypeChoices {
  /** Agreed value */
  AgreedValue = "AGREED_VALUE",
  /** Declared value */
  DeclaredValue = "DECLARED_VALUE",
}

/** An enumeration. */
export enum SpinPolicyAssetBasketHistoryInsuredValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum SpinPolicyAssetBasketHistoryStatusChoices {
  /** Added */
  Added = "ADDED",
  /** Initial */
  Initial = "INITIAL",
  /** Other */
  Other = "OTHER",
  /** Unchanged */
  Unchanged = "UNCHANGED",
  /** Updated */
  Updated = "UPDATED",
}

/** An enumeration. */
export enum SpinPolicyBasketHistoryStatusChoices {
  /** Add */
  Add = "ADD",
  /** Delete */
  Delete = "DELETE",
  /** Initial */
  Initial = "INITIAL",
  /** Other */
  Other = "OTHER",
  /** Update */
  Update = "UPDATE",
}

/** An enumeration. */
export enum SpinPolicyEventsActionChoices {
  /** add policy_asset */
  AddPolicyAsset = "ADD_POLICY_ASSET",
  /** certificate generation */
  CertificateGeneration = "CERTIFICATE_GENERATION",
  /** extension */
  Extension = "EXTENSION",
  /** manual invalidation certificate */
  ManualInvalidationCertificate = "MANUAL_INVALIDATION_CERTIFICATE",
  /** manual termination policy */
  ManualTerminationPolicy = "MANUAL_TERMINATION_POLICY",
  /** modified driver Policy */
  ModifiedDriverPolicy = "MODIFIED_DRIVER_POLICY",
  /** modified driver policy_asset */
  ModifiedDriverPolicyAsset = "MODIFIED_DRIVER_POLICY_ASSET",
  /** remove policy_asset */
  RemovePolicyAsset = "REMOVE_POLICY_ASSET",
  /** termination */
  Termination = "TERMINATION",
}

export type TerminatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type TerminatePolicyPayload = {
  __typename?: "TerminatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type ToggleUserIsActiveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ToggleUserIsActivePayload = {
  __typename?: "ToggleUserIsActivePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ToggleUserUserTypeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiration?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type ToggleUserUserTypePayload = {
  __typename?: "ToggleUserUserTypePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UpdateAppraisalInput = {
  appraisalData?: InputMaybe<AppraisalInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAppraisalPayload = {
  __typename?: "UpdateAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetDocumentAttachmentInput = {
  assetDocumentAttachmentData?: InputMaybe<AssetDocumentAttachmentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetDocumentAttachmentPayload = {
  __typename?: "UpdateAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetDocumentInput = {
  assetDocumentData?: InputMaybe<AssetDocumentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetDocumentPayload = {
  __typename?: "UpdateAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetElementPayload = {
  __typename?: "UpdateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPayload = {
  __typename?: "UpdateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPresentValuePayload = {
  __typename?: "UpdateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateCertificatePayload = {
  __typename?: "UpdateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionData?: InputMaybe<CollectionInput>;
  id: Scalars["ID"];
};

export type UpdateCollectionPayload = {
  __typename?: "UpdateCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type UpdateCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
  id: Scalars["ID"];
};

export type UpdateCompanyContactPayload = {
  __typename?: "UpdateCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactType>;
};

export type UpdateConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportData?: InputMaybe<ConditionStatusReportInput>;
  id: Scalars["ID"];
};

export type UpdateConditionStatusReportPayload = {
  __typename?: "UpdateConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type UpdateEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventLogData?: InputMaybe<EventLogInput>;
  id: Scalars["ID"];
};

export type UpdateEventLogPayload = {
  __typename?: "UpdateEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventLog?: Maybe<EventLogNode>;
};

export type UpdateExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionData?: InputMaybe<ExhibitionInput>;
  id: Scalars["ID"];
};

export type UpdateExhibitionPayload = {
  __typename?: "UpdateExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type UpdateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type UpdateInsurancePayload = {
  __typename?: "UpdateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type UpdateMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type UpdateMediaPayload = {
  __typename?: "UpdateMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type UpdateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  officeData?: InputMaybe<OfficeInput>;
};

export type UpdateOfficePayload = {
  __typename?: "UpdateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeType>;
};

export type UpdatePolicyAssetGroupInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policyAssetsData?: InputMaybe<Array<InputMaybe<GroupedPolicyAssetInput>>>;
};

export type UpdatePolicyAssetGroupPayload = {
  __typename?: "UpdatePolicyAssetGroupPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAssets?: Maybe<Array<Maybe<PolicyAssetNode>>>;
};

export type UpdatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type UpdatePolicyAssetPayload = {
  __typename?: "UpdatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type UpdatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyDocumentData?: InputMaybe<PolicyDocumentInput>;
};

export type UpdatePolicyDocumentPayload = {
  __typename?: "UpdatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type UpdatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyData?: InputMaybe<UpdatePolicyInputData>;
};

export type UpdatePolicyInputData = {
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  brokerId?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  contractingPartyId: Scalars["ID"];
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntityId: Scalars["ID"];
  eventFrom?: InputMaybe<Scalars["String"]>;
  eventLocationEntityId: Scalars["ID"];
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt: Scalars["DateTime"];
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompanyId: Scalars["ID"];
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyCode: Scalars["String"];
  policyStatus: Scalars["String"];
  totalInsuredPremium?: InputMaybe<MoneyInput>;
  totalInsuredValue?: InputMaybe<MoneyInput>;
};

export type UpdatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type UpdatePolicyNotePayload = {
  __typename?: "UpdatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type UpdatePolicyPayload = {
  __typename?: "UpdatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type UpdateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  registryData?: InputMaybe<RegistryInput>;
};

export type UpdateRegistryPayload = {
  __typename?: "UpdateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type UpdateRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  restorationData?: InputMaybe<RestorationInput>;
};

export type UpdateRestorationPayload = {
  __typename?: "UpdateRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  userData: UserInput;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UpdateUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UpdateUserPermissionPayload = {
  __typename?: "UpdateUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UserInput = {
  country?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  language?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  userType?: InputMaybe<Scalars["String"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type UserNode = Node & {
  __typename?: "UserNode";
  certificateSet: CertificateNodeConnection;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdbaseCompanycontactRelated: CompanyContactNodeConnection;
  createdbaseEventlogRelated: EventLogNodeConnection;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAppraisalRelated: AppraisalNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  createdmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  createdmanagementAssetelementRelated: AssetElementNodeConnection;
  createdmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdmanagementRestorationRelated: RestorationNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  createdspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdspinPolicyeventsRelated: PolicyEventsNodeConnection;
  createdusersUserRelated: UserNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  updatedbaseCompanycontactRelated: CompanyContactNodeConnection;
  updatedbaseEventlogRelated: EventLogNodeConnection;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAppraisalRelated: AppraisalNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  updatedmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  updatedmanagementAssetelementRelated: AssetElementNodeConnection;
  updatedmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedmanagementRestorationRelated: RestorationNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  updatedspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedspinPolicyeventsRelated: PolicyEventsNodeConnection;
  updatedusersUserRelated: UserNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
  validFrom?: Maybe<Scalars["DateTime"]>;
};

export type UserNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeCreatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeUpdatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeConnection = {
  __typename?: "UserNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: "UserNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type UserSignatureNode = Node & {
  __typename?: "UserSignatureNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isMain?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  user: UserNode;
  uuid: Scalars["UUID"];
};

export type UserSignatureNodeConnection = {
  __typename?: "UserSignatureNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserSignatureNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserSignatureNode` and its cursor. */
export type UserSignatureNodeEdge = {
  __typename?: "UserSignatureNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserSignatureNode>;
};

export type UserSignatureType = {
  __typename?: "UserSignatureType";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file: Scalars["String"];
  id: Scalars["ID"];
  isMain?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  user: UserNode;
  uuid: Scalars["UUID"];
};

export type UserType = {
  __typename?: "UserType";
  certificateSet: CertificateNodeConnection;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdbaseCompanycontactRelated: CompanyContactNodeConnection;
  createdbaseEventlogRelated: EventLogNodeConnection;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAppraisalRelated: AppraisalNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  createdmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  createdmanagementAssetelementRelated: AssetElementNodeConnection;
  createdmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdmanagementRestorationRelated: RestorationNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  createdspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdspinPolicyeventsRelated: PolicyEventsNodeConnection;
  createdusersUserRelated: UserNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  updatedbaseCompanycontactRelated: CompanyContactNodeConnection;
  updatedbaseEventlogRelated: EventLogNodeConnection;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAppraisalRelated: AppraisalNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  updatedmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  updatedmanagementAssetelementRelated: AssetElementNodeConnection;
  updatedmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedmanagementRestorationRelated: RestorationNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  updatedspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedspinPolicyeventsRelated: PolicyEventsNodeConnection;
  updatedusersUserRelated: UserNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
  validFrom?: Maybe<Scalars["DateTime"]>;
};

export type UserTypeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeCreatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeUpdatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum UsersUserUserTypeChoices {
  /** Default */
  Default = "DEFAULT",
  /** External */
  External = "EXTERNAL",
  /** Internal */
  Internal = "INTERNAL",
}

export type Verify = {
  __typename?: "Verify";
  payload: Scalars["GenericScalar"];
};

export type AppraisalFindingFieldsFragment = {
  __typename?: "AppraisalNode";
  id: string;
  appraisalStatus?: string | null;
  appraisalDate?: string | null;
  appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  appraisalAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
};

export type AppraisalAnagraphicFieldsFragment = {
  __typename?: "AppraisalNode";
  id: string;
  appraisalStatus?: string | null;
  appraisalConservationStatus?: string | null;
  appraisalMotivation?: string | null;
  appraisalLocation?: string | null;
  appraisalDate?: string | null;
  valueInformationAuthorCoefficient?: string | null;
  valueInformationInfo?: string | null;
  valueInformationQuotesSimilarAssets?: string | null;
  valueInformationNote?: string | null;
  appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  appraisalAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  valueCarryingValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  valueEstimatedValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllAppraisalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAppraisalsQuery = {
  __typename?: "Query";
  allAppraisals?: {
    __typename?: "AppraisalNodeConnection";
    edges: Array<{
      __typename?: "AppraisalNodeEdge";
      node?: {
        __typename?: "AppraisalNode";
        mediaNumber?: number | null;
        createdAt: string;
        actualMediaStatus?: string | null;
        id: string;
        appraisalStatus?: string | null;
        appraisalDate?: string | null;
        valueEstimatedValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
        appraisalAsset: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type GetAppraisalByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAppraisalByIdQuery = {
  __typename?: "Query";
  appraisal?: {
    __typename?: "AppraisalNode";
    mediaNumber?: number | null;
    id: string;
    appraisalStatus?: string | null;
    appraisalConservationStatus?: string | null;
    appraisalMotivation?: string | null;
    appraisalLocation?: string | null;
    appraisalDate?: string | null;
    valueInformationAuthorCoefficient?: string | null;
    valueInformationInfo?: string | null;
    valueInformationQuotesSimilarAssets?: string | null;
    valueInformationNote?: string | null;
    appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    appraisalAsset: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    };
    valueCarryingValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    valueEstimatedValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateAppraisalMutationVariables = Exact<{
  input: CreateAppraisalInput;
}>;

export type CreateAppraisalMutation = {
  __typename?: "Mutation";
  createAppraisal?: {
    __typename?: "CreateAppraisalPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      appraisalStatus?: string | null;
      appraisalConservationStatus?: string | null;
      appraisalMotivation?: string | null;
      appraisalLocation?: string | null;
      appraisalDate?: string | null;
      valueInformationAuthorCoefficient?: string | null;
      valueInformationInfo?: string | null;
      valueInformationQuotesSimilarAssets?: string | null;
      valueInformationNote?: string | null;
      appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      appraisalAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      valueCarryingValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAppraisalMutationVariables = Exact<{
  input: UpdateAppraisalInput;
}>;

export type UpdateAppraisalMutation = {
  __typename?: "Mutation";
  updateAppraisal?: {
    __typename?: "UpdateAppraisalPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      appraisalStatus?: string | null;
      appraisalConservationStatus?: string | null;
      appraisalMotivation?: string | null;
      appraisalLocation?: string | null;
      appraisalDate?: string | null;
      valueInformationAuthorCoefficient?: string | null;
      valueInformationInfo?: string | null;
      valueInformationQuotesSimilarAssets?: string | null;
      valueInformationNote?: string | null;
      appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      appraisalAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      valueCarryingValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAppraisalMutationVariables = Exact<{
  input: DeleteAppraisalInput;
}>;

export type DeleteAppraisalMutation = {
  __typename?: "Mutation";
  deleteAppraisal?: {
    __typename?: "DeleteAppraisalPayload";
    deleteAppraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type CloseAppraisalMutationVariables = Exact<{
  input: CloseAppraisalInput;
}>;

export type CloseAppraisalMutation = {
  __typename?: "Mutation";
  closeAppraisal?: {
    __typename?: "CloseAppraisalPayload";
    closeAppraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromAppraisalMutationVariables = Exact<{
  input: DetachAssetFromAppraisalInput;
}>;

export type DetachAssetFromAppraisalMutation = {
  __typename?: "Mutation";
  detachAssetFromAppraisal?: {
    __typename?: "DetachAssetFromAppraisalPayload";
    appraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type GetMediaAppraisalByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaAppraisalByIdQuery = {
  __typename?: "Query";
  appraisal?: {
    __typename?: "AppraisalNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddAppraisalMediaMutationVariables = Exact<{
  input: AddAppraisalMediaInput;
}>;

export type AddAppraisalMediaMutation = {
  __typename?: "Mutation";
  addAppraisalMedia?: {
    __typename?: "AddAppraisalMediaPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteAppraisalMediaMutationVariables = Exact<{
  input: DeleteAppraisalMediaInput;
}>;

export type DeleteAppraisalMediaMutation = {
  __typename?: "Mutation";
  deleteAppraisalMedia?: {
    __typename?: "DeleteAppraisalMediaPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportAppraisalPdfMutationVariables = Exact<{
  input: ExportAppraisalPdfInput;
}>;

export type ExportAppraisalPdfMutation = {
  __typename?: "Mutation";
  exportAppraisalPdf?: {
    __typename?: "ExportAppraisalPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type AssetFieldsFragment = {
  __typename: "AssetNode";
  id: string;
  deleted?: string | null;
  deletedByCascade: boolean;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  netWeightUnit?: string | null;
  grossWeightUnit?: string | null;
  netLengthUnit?: string | null;
  grossLengthUnit?: string | null;
  netHeight?: number | null;
  grossHeight?: number | null;
  netWidth?: number | null;
  grossWidth?: number | null;
  netDepth?: number | null;
  grossDepth?: number | null;
  netDiameter?: number | null;
  grossDiameter?: number | null;
  netWeight?: number | null;
  grossWeight?: number | null;
  netDimensionsAcquisition?: string | null;
  grossDimensionsAcquisition?: string | null;
  dimensionsNotes?: string | null;
  audioVideoLengthUnit?: string | null;
  audioVideoLength?: number | null;
  fileSizeUnit?: string | null;
  fileSize?: number | null;
  resolutionUnit?: string | null;
  resolution?: number | null;
  optimalLightLevelIntensity?: number | null;
  lightUnit?: string | null;
  optimalTemperature?: number | null;
  temperatureUnit?: string | null;
  optimalHumidity?: number | null;
  enviromentalConditionsNotes?: string | null;
  isNotifiedCulturalProperty?: boolean | null;
  notifiedPropertyNote?: string | null;
  legalStatus?: string | null;
  copyrightAndPropertyRestrictions?: string | null;
  hasBarriers?: boolean | null;
  barriersDetails?: string | null;
  handlingRequirements?: string | null;
  injuryRisk?: string | null;
  injuryRiskDescription?: string | null;
  mouldingSupportBase?: string | null;
  hardwareToolsInstallation?: string | null;
  conservation?: string | null;
  lenderRequests?: string | null;
  settingNotes?: string | null;
  infoNotes?: string | null;
  isArtistProof?: boolean | null;
  artistProofNotes?: string | null;
  isFirstDraft?: boolean | null;
  firstDraftNotes?: string | null;
  isEdition?: boolean | null;
  editionNotes?: string | null;
  isMaster?: boolean | null;
  masterLocation?: string | null;
  isArtMultiple?: boolean | null;
  multipleNumber?: string | null;
  isSignedObject?: boolean | null;
  signedObjectNotes?: string | null;
  isTitledObject?: boolean | null;
  titledObjectNotes?: string | null;
  isDatedObject?: boolean | null;
  datedObjectNotes?: string | null;
  hasLabelsStamps?: boolean | null;
  labelsStampsNotes?: string | null;
  hasOtherAutographed?: boolean | null;
  otherAutographedNotes?: string | null;
  relevantAwards?: string | null;
  mechanism?: string | null;
  carat?: string | null;
  isDynamic?: boolean | null;
  dynamicNotes?: string | null;
  hasHumanAnimalPresence?: boolean | null;
  humanAnimalPresenceNotes?: string | null;
  isAudio?: boolean | null;
  isVideo?: boolean | null;
  hasLoop?: boolean | null;
  coloring?: string | null;
  coinMintIssuer?: string | null;
  coinPlacement?: string | null;
  coinPeriod?: string | null;
  coinPlacementNotes?: string | null;
  coinIssueReason?: string | null;
  coinCirculationPeriod?: string | null;
  coinComposition?: string | null;
  coinIdentificationCode?: string | null;
  classificationUsa?: string | null;
  classificationIta?: string | null;
  classificationEng?: string | null;
  classificationFra?: string | null;
  classificationSpa?: string | null;
  classificationGer?: string | null;
  classificationPhilatelic?: string | null;
  rarityDegree?: string | null;
  rarityDegreePhilatelic?: string | null;
  conservationState?: string | null;
  rarityDegreeNotes?: string | null;
  variants?: string | null;
  nominalValue?: number | null;
  fiscalValue?: number | null;
  currentValue?: number | null;
  currency?: string | null;
  score?: string | null;
  circulation?: string | null;
  manufactoringTechniques?: string | null;
  mintingLastDate?: any | null;
  mintingLastDateNotes?: string | null;
  reminting?: string | null;
  printingLastDate?: any | null;
  printingLastDateNotes?: string | null;
  reprinting?: string | null;
  stateOfConservation?: string | null;
  isComposed?: boolean | null;
  numberOfElements?: number | null;
  surfaceCharacteristics?: string | null;
  hasBase?: boolean | null;
  baseDetails?: string | null;
  hasWoodFrame?: boolean | null;
  woodFrameDetails?: string | null;
  hasFrame?: boolean | null;
  hasOriginalFrame?: boolean | null;
  frameMaterial?: string | null;
  isValuableFrame?: boolean | null;
  frameManufacturer?: string | null;
  frameDescription?: string | null;
  compositionDescription?: string | null;
  usage?: string | null;
  finishing?: string | null;
  hasContainer?: boolean | null;
  containerDetails?: string | null;
  replacedParts?: string | null;
  methodOfUse?: string | null;
  relatedPhysicalWork?: string | null;
  bookContainer?: string | null;
  dustJacket?: string | null;
  frontCover?: string | null;
  rearCover?: string | null;
  topEdge?: string | null;
  foreEdge?: string | null;
  footEdge?: string | null;
  bookbinding?: string | null;
  interiorDecorations?: string | null;
  externalDecorations?: string | null;
  flyleaf?: string | null;
  glueing?: string | null;
  headband?: string | null;
  headcap?: string | null;
  corner?: string | null;
  square?: string | null;
  slots?: string | null;
  spine?: string | null;
  clips?: string | null;
  raisedBands?: string | null;
  label?: string | null;
  clothCovering?: string | null;
  distribution?: string | null;
  edge?: string | null;
  orientation?: string | null;
  shape?: string | null;
  rim?: string | null;
  rotationAxes?: string | null;
  legend?: string | null;
  fineness?: string | null;
  coinTitle?: string | null;
  mintMadeErrors?: string | null;
  signings?: string | null;
  relief?: string | null;
  watermark?: string | null;
  holographicStripe?: string | null;
  securityThread?: string | null;
  printingErrors?: string | null;
  conservationMethod?: string | null;
  color?: string | null;
  postageMark?: string | null;
  rubber?: string | null;
  stampPrintingTechnique?: string | null;
  stampRealization?: string | null;
  stampIndentationType?: string | null;
  stampCenteringAndMargins?: string | null;
  stampFormat?: string | null;
  discoveryPlace?: string | null;
  discoverySite?: string | null;
  discoveryDate?: any | null;
  discoveryNotes?: string | null;
  discoveryAuthor?: string | null;
  catalogingAuthor?: string | null;
  objectSetupAuthor?: string | null;
  originNotes?: string | null;
  bibliographyNotes?: string | null;
  carryingValueOrigin?: string | null;
  carryingValueAcquisition?: string | null;
  carryingValueNotes?: string | null;
  carryingValueLastAcquisitionDate?: any | null;
  carryingValueAmountCurrency: string;
  carryingValueFileTitle?: string | null;
  carryingValueFile?: string | null;
  title: string;
  technique?: string | null;
  genericDimensions?: string | null;
  actualDateAndPeriod?: string | null;
  creationDate?: string | null;
  creationDateNotes?: string | null;
  category: string;
  subcategory: string;
  otherModel?: string | null;
  banknotePrinter?: string | null;
  paperType?: string | null;
  coverType?: string | null;
  printingType?: string | null;
  productionMode?: string | null;
  placement?: string | null;
  sku?: string | null;
  series?: string | null;
  seriesDetails?: string | null;
  commonName?: string | null;
  scientificName?: string | null;
  material?: string | null;
  internationalCode?: string | null;
  taxonomyDomain?: string | null;
  taxonomyKingdom?: string | null;
  taxonomyPhylum?: string | null;
  taxonomyClass?: string | null;
  taxonomyOrder?: string | null;
  taxonomyFamily?: string | null;
  taxonomyTribe?: string | null;
  taxonomyGenus?: string | null;
  taxonomyNotes?: string | null;
  hasPeriod: boolean;
  hasYear: boolean;
  hasFullDatetime: boolean;
  datingType?: string | null;
  creationPeriod?: string | null;
  isDateInterval: boolean;
  dateStartDate?: any | null;
  dateEndDate?: any | null;
  isDateStartDateBc: boolean;
  isDateEndDateBc: boolean;
  isDateApprox: boolean;
  isYearInterval: boolean;
  yearStartDate?: number | null;
  yearEndDate?: number | null;
  isYearStartDateBc: boolean;
  isYearEndDateBc: boolean;
  isYearApprox: boolean;
  isCenturyInterval: boolean;
  centuryStartDate?: number | null;
  centuryEndDate?: number | null;
  isCenturyStartDateBc: boolean;
  isCenturyEndDateBc: boolean;
  isCenturyApprox: boolean;
  centuryStartDateWindow?: string | null;
  centuryEndDateWindow?: string | null;
  nft?: boolean | null;
  nftNotes?: string | null;
  description?: string | null;
  inventoryNumber?: string | null;
  archiveNumber?: string | null;
  catalogNumber?: string | null;
  criticalCatalogue: boolean;
  validated: boolean;
  mainImage?: string | null;
  createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  carryingValueAmount?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  printer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  videomaker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  manufacturer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  producer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  collection: {
    __typename?: "CollectionNodeConnection";
    edges: Array<{
      __typename?: "CollectionNodeEdge";
      node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
    } | null>;
  };
  exhibition: {
    __typename?: "ExhibitionNodeConnection";
    edges: Array<{
      __typename?: "ExhibitionNodeEdge";
      node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
    } | null>;
  };
  documents: {
    __typename?: "AssetDocumentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentNodeEdge";
      node?: {
        __typename?: "AssetDocumentNode";
        id: string;
        title?: string | null;
        description?: string | null;
        documentType?: string | null;
        coaType?: string | null;
        issuer?: string | null;
        recipient?: string | null;
        isClassified?: boolean | null;
        releaseDate?: any | null;
        expirationDate?: any | null;
        notes?: string | null;
        archiveNumber?: string | null;
        fineArtsIssueType?: string | null;
        isTemporaryExport?: boolean | null;
        isTemporaryImport?: boolean | null;
        isCriticalCatalog?: boolean | null;
        editor?: string | null;
        curator?: string | null;
        subscriptionDate?: any | null;
        contractSubscriber?: string | null;
        publishingDate?: any | null;
        otherDocumentType?: string | null;
        attachments: {
          __typename?: "AssetDocumentAttachmentNodeConnection";
          edges: Array<{
            __typename?: "AssetDocumentAttachmentNodeEdge";
            node?: {
              __typename?: "AssetDocumentAttachmentNode";
              id: string;
              name?: string | null;
              file: string;
            } | null;
          } | null>;
        };
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  };
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
        createdAt: string;
        actualImageDimensions?: string | null;
      } | null;
    } | null>;
  };
  physicalFeaturesElements: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: {
        __typename?: "AssetElementNode";
        id: string;
        name: string;
        materials?: string | null;
        description?: string | null;
        notes?: string | null;
      } | null;
    } | null>;
  };
  presentValues: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        estimateDate?: any | null;
        estimateReason?: string | null;
        notes?: string | null;
        file?: string | null;
        fileTitle?: string | null;
        author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        amount: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        };
      } | null;
    } | null>;
  };
  policyAsset: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type AssetFieldsCreationReviewFragment = {
  __typename: "AssetNode";
  id: string;
  title: string;
  inventoryNumber?: string | null;
  technique?: string | null;
  creationDate?: string | null;
  genericDimensions?: string | null;
  actualDateAndPeriod?: string | null;
  category: string;
  mainImage?: string | null;
  authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type GetAllAssetsByTitleQueryVariables = Exact<{
  title_Contains?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllAssetsByTitleQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        author?: string | null;
        creationDate?: string | null;
        actualDateAndPeriod?: string | null;
        genericDimensions?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetByIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename: "AssetNode";
    id: string;
    deleted?: string | null;
    deletedByCascade: boolean;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    netWeightUnit?: string | null;
    grossWeightUnit?: string | null;
    netLengthUnit?: string | null;
    grossLengthUnit?: string | null;
    netHeight?: number | null;
    grossHeight?: number | null;
    netWidth?: number | null;
    grossWidth?: number | null;
    netDepth?: number | null;
    grossDepth?: number | null;
    netDiameter?: number | null;
    grossDiameter?: number | null;
    netWeight?: number | null;
    grossWeight?: number | null;
    netDimensionsAcquisition?: string | null;
    grossDimensionsAcquisition?: string | null;
    dimensionsNotes?: string | null;
    audioVideoLengthUnit?: string | null;
    audioVideoLength?: number | null;
    fileSizeUnit?: string | null;
    fileSize?: number | null;
    resolutionUnit?: string | null;
    resolution?: number | null;
    optimalLightLevelIntensity?: number | null;
    lightUnit?: string | null;
    optimalTemperature?: number | null;
    temperatureUnit?: string | null;
    optimalHumidity?: number | null;
    enviromentalConditionsNotes?: string | null;
    isNotifiedCulturalProperty?: boolean | null;
    notifiedPropertyNote?: string | null;
    legalStatus?: string | null;
    copyrightAndPropertyRestrictions?: string | null;
    hasBarriers?: boolean | null;
    barriersDetails?: string | null;
    handlingRequirements?: string | null;
    injuryRisk?: string | null;
    injuryRiskDescription?: string | null;
    mouldingSupportBase?: string | null;
    hardwareToolsInstallation?: string | null;
    conservation?: string | null;
    lenderRequests?: string | null;
    settingNotes?: string | null;
    infoNotes?: string | null;
    isArtistProof?: boolean | null;
    artistProofNotes?: string | null;
    isFirstDraft?: boolean | null;
    firstDraftNotes?: string | null;
    isEdition?: boolean | null;
    editionNotes?: string | null;
    isMaster?: boolean | null;
    masterLocation?: string | null;
    isArtMultiple?: boolean | null;
    multipleNumber?: string | null;
    isSignedObject?: boolean | null;
    signedObjectNotes?: string | null;
    isTitledObject?: boolean | null;
    titledObjectNotes?: string | null;
    isDatedObject?: boolean | null;
    datedObjectNotes?: string | null;
    hasLabelsStamps?: boolean | null;
    labelsStampsNotes?: string | null;
    hasOtherAutographed?: boolean | null;
    otherAutographedNotes?: string | null;
    relevantAwards?: string | null;
    mechanism?: string | null;
    carat?: string | null;
    isDynamic?: boolean | null;
    dynamicNotes?: string | null;
    hasHumanAnimalPresence?: boolean | null;
    humanAnimalPresenceNotes?: string | null;
    isAudio?: boolean | null;
    isVideo?: boolean | null;
    hasLoop?: boolean | null;
    coloring?: string | null;
    coinMintIssuer?: string | null;
    coinPlacement?: string | null;
    coinPeriod?: string | null;
    coinPlacementNotes?: string | null;
    coinIssueReason?: string | null;
    coinCirculationPeriod?: string | null;
    coinComposition?: string | null;
    coinIdentificationCode?: string | null;
    classificationUsa?: string | null;
    classificationIta?: string | null;
    classificationEng?: string | null;
    classificationFra?: string | null;
    classificationSpa?: string | null;
    classificationGer?: string | null;
    classificationPhilatelic?: string | null;
    rarityDegree?: string | null;
    rarityDegreePhilatelic?: string | null;
    conservationState?: string | null;
    rarityDegreeNotes?: string | null;
    variants?: string | null;
    nominalValue?: number | null;
    fiscalValue?: number | null;
    currentValue?: number | null;
    currency?: string | null;
    score?: string | null;
    circulation?: string | null;
    manufactoringTechniques?: string | null;
    mintingLastDate?: any | null;
    mintingLastDateNotes?: string | null;
    reminting?: string | null;
    printingLastDate?: any | null;
    printingLastDateNotes?: string | null;
    reprinting?: string | null;
    stateOfConservation?: string | null;
    isComposed?: boolean | null;
    numberOfElements?: number | null;
    surfaceCharacteristics?: string | null;
    hasBase?: boolean | null;
    baseDetails?: string | null;
    hasWoodFrame?: boolean | null;
    woodFrameDetails?: string | null;
    hasFrame?: boolean | null;
    hasOriginalFrame?: boolean | null;
    frameMaterial?: string | null;
    isValuableFrame?: boolean | null;
    frameManufacturer?: string | null;
    frameDescription?: string | null;
    compositionDescription?: string | null;
    usage?: string | null;
    finishing?: string | null;
    hasContainer?: boolean | null;
    containerDetails?: string | null;
    replacedParts?: string | null;
    methodOfUse?: string | null;
    relatedPhysicalWork?: string | null;
    bookContainer?: string | null;
    dustJacket?: string | null;
    frontCover?: string | null;
    rearCover?: string | null;
    topEdge?: string | null;
    foreEdge?: string | null;
    footEdge?: string | null;
    bookbinding?: string | null;
    interiorDecorations?: string | null;
    externalDecorations?: string | null;
    flyleaf?: string | null;
    glueing?: string | null;
    headband?: string | null;
    headcap?: string | null;
    corner?: string | null;
    square?: string | null;
    slots?: string | null;
    spine?: string | null;
    clips?: string | null;
    raisedBands?: string | null;
    label?: string | null;
    clothCovering?: string | null;
    distribution?: string | null;
    edge?: string | null;
    orientation?: string | null;
    shape?: string | null;
    rim?: string | null;
    rotationAxes?: string | null;
    legend?: string | null;
    fineness?: string | null;
    coinTitle?: string | null;
    mintMadeErrors?: string | null;
    signings?: string | null;
    relief?: string | null;
    watermark?: string | null;
    holographicStripe?: string | null;
    securityThread?: string | null;
    printingErrors?: string | null;
    conservationMethod?: string | null;
    color?: string | null;
    postageMark?: string | null;
    rubber?: string | null;
    stampPrintingTechnique?: string | null;
    stampRealization?: string | null;
    stampIndentationType?: string | null;
    stampCenteringAndMargins?: string | null;
    stampFormat?: string | null;
    discoveryPlace?: string | null;
    discoverySite?: string | null;
    discoveryDate?: any | null;
    discoveryNotes?: string | null;
    discoveryAuthor?: string | null;
    catalogingAuthor?: string | null;
    objectSetupAuthor?: string | null;
    originNotes?: string | null;
    bibliographyNotes?: string | null;
    carryingValueOrigin?: string | null;
    carryingValueAcquisition?: string | null;
    carryingValueNotes?: string | null;
    carryingValueLastAcquisitionDate?: any | null;
    carryingValueAmountCurrency: string;
    carryingValueFileTitle?: string | null;
    carryingValueFile?: string | null;
    title: string;
    technique?: string | null;
    genericDimensions?: string | null;
    actualDateAndPeriod?: string | null;
    creationDate?: string | null;
    creationDateNotes?: string | null;
    category: string;
    subcategory: string;
    otherModel?: string | null;
    banknotePrinter?: string | null;
    paperType?: string | null;
    coverType?: string | null;
    printingType?: string | null;
    productionMode?: string | null;
    placement?: string | null;
    sku?: string | null;
    series?: string | null;
    seriesDetails?: string | null;
    commonName?: string | null;
    scientificName?: string | null;
    material?: string | null;
    internationalCode?: string | null;
    taxonomyDomain?: string | null;
    taxonomyKingdom?: string | null;
    taxonomyPhylum?: string | null;
    taxonomyClass?: string | null;
    taxonomyOrder?: string | null;
    taxonomyFamily?: string | null;
    taxonomyTribe?: string | null;
    taxonomyGenus?: string | null;
    taxonomyNotes?: string | null;
    hasPeriod: boolean;
    hasYear: boolean;
    hasFullDatetime: boolean;
    datingType?: string | null;
    creationPeriod?: string | null;
    isDateInterval: boolean;
    dateStartDate?: any | null;
    dateEndDate?: any | null;
    isDateStartDateBc: boolean;
    isDateEndDateBc: boolean;
    isDateApprox: boolean;
    isYearInterval: boolean;
    yearStartDate?: number | null;
    yearEndDate?: number | null;
    isYearStartDateBc: boolean;
    isYearEndDateBc: boolean;
    isYearApprox: boolean;
    isCenturyInterval: boolean;
    centuryStartDate?: number | null;
    centuryEndDate?: number | null;
    isCenturyStartDateBc: boolean;
    isCenturyEndDateBc: boolean;
    isCenturyApprox: boolean;
    centuryStartDateWindow?: string | null;
    centuryEndDateWindow?: string | null;
    nft?: boolean | null;
    nftNotes?: string | null;
    description?: string | null;
    inventoryNumber?: string | null;
    archiveNumber?: string | null;
    catalogNumber?: string | null;
    criticalCatalogue: boolean;
    validated: boolean;
    mainImage?: string | null;
    createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    carryingValueAmount?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    printer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    videomaker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    manufacturer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    producer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    collection: {
      __typename?: "CollectionNodeConnection";
      edges: Array<{
        __typename?: "CollectionNodeEdge";
        node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
      } | null>;
    };
    exhibition: {
      __typename?: "ExhibitionNodeConnection";
      edges: Array<{
        __typename?: "ExhibitionNodeEdge";
        node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
      } | null>;
    };
    documents: {
      __typename?: "AssetDocumentNodeConnection";
      edges: Array<{
        __typename?: "AssetDocumentNodeEdge";
        node?: {
          __typename?: "AssetDocumentNode";
          id: string;
          title?: string | null;
          description?: string | null;
          documentType?: string | null;
          coaType?: string | null;
          issuer?: string | null;
          recipient?: string | null;
          isClassified?: boolean | null;
          releaseDate?: any | null;
          expirationDate?: any | null;
          notes?: string | null;
          archiveNumber?: string | null;
          fineArtsIssueType?: string | null;
          isTemporaryExport?: boolean | null;
          isTemporaryImport?: boolean | null;
          isCriticalCatalog?: boolean | null;
          editor?: string | null;
          curator?: string | null;
          subscriptionDate?: any | null;
          contractSubscriber?: string | null;
          publishingDate?: any | null;
          otherDocumentType?: string | null;
          attachments: {
            __typename?: "AssetDocumentAttachmentNodeConnection";
            edges: Array<{
              __typename?: "AssetDocumentAttachmentNodeEdge";
              node?: {
                __typename?: "AssetDocumentAttachmentNode";
                id: string;
                name?: string | null;
                file: string;
              } | null;
            } | null>;
          };
          createdBy?: { __typename?: "UserNode"; name: string } | null;
        } | null;
      } | null>;
    };
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
          createdAt: string;
          actualImageDimensions?: string | null;
        } | null;
      } | null>;
    };
    physicalFeaturesElements: {
      __typename?: "AssetElementNodeConnection";
      edges: Array<{
        __typename?: "AssetElementNodeEdge";
        node?: {
          __typename?: "AssetElementNode";
          id: string;
          name: string;
          materials?: string | null;
          description?: string | null;
          notes?: string | null;
        } | null;
      } | null>;
    };
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          id: string;
          estimateDate?: any | null;
          estimateReason?: string | null;
          notes?: string | null;
          file?: string | null;
          fileTitle?: string | null;
          author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          amount: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          };
        } | null;
      } | null>;
    };
    policyAsset: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: { __typename?: "PolicyAssetNode"; id: string } | null;
      } | null>;
    };
  } | null;
};

export type GetAssetByIdForReviewQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetByIdForReviewQuery = {
  __typename?: "Query";
  asset?: {
    __typename: "AssetNode";
    id: string;
    title: string;
    inventoryNumber?: string | null;
    technique?: string | null;
    creationDate?: string | null;
    genericDimensions?: string | null;
    actualDateAndPeriod?: string | null;
    category: string;
    mainImage?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
};

export type GetMediaAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaAssetByIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAllAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAssetsQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category: string;
        subcategory: string;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated: boolean;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: {
          __typename?: "UserNode";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllAssetsForUserQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetAllAssetsForUserQuery = {
  __typename?: "Query";
  allAssetsForUser?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category: string;
        subcategory: string;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated: boolean;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllAssetExceptUserQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetAllAssetExceptUserQuery = {
  __typename?: "Query";
  notUserAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category: string;
        subcategory: string;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated: boolean;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetMutationVariables = Exact<{
  input: CreateAssetInput;
}>;

export type CreateAssetMutation = {
  __typename?: "Mutation";
  createAsset?: {
    __typename?: "CreateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
      actualDateAndPeriod?: string | null;
      category: string;
      genericDimensions?: string | null;
      inventoryNumber?: string | null;
      technique?: string | null;
    } | null;
  } | null;
};

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAssetInput;
}>;

export type UpdateAssetMutation = {
  __typename?: "Mutation";
  updateAsset?: {
    __typename?: "UpdateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
    } | null;
  } | null;
};

export type ChangeAssetCategoryMutationVariables = Exact<{
  input: ChangeAssetCategoryInput;
}>;

export type ChangeAssetCategoryMutation = {
  __typename?: "Mutation";
  changeAssetCategory?: {
    __typename?: "ChangeAssetCategoryPayload";
    asset?: { __typename?: "AssetNode"; id: string; category: string; subcategory: string } | null;
  } | null;
};

export type DeleteAssetMutationVariables = Exact<{
  input: DeleteAssetInput;
}>;

export type DeleteAssetMutation = {
  __typename?: "Mutation";
  deleteAsset?: {
    __typename?: "DeleteAssetPayload";
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type GetAssetElementsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetElementsByAssetIdQuery = {
  __typename?: "Query";
  allAssetElements?: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: {
        __typename?: "AssetElementNode";
        id: string;
        name: string;
        description?: string | null;
        notes?: string | null;
        materials?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetPresentValuesByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetPresentValuesByAssetIdQuery = {
  __typename?: "Query";
  allAssetPresentValues?: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        estimateDate?: any | null;
        estimateReason?: string | null;
        notes?: string | null;
        fileTitle?: string | null;
        file?: string | null;
        amount: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        };
        author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetElementMutationVariables = Exact<{
  input: CreateAssetElementInput;
}>;

export type CreateAssetElementMutation = {
  __typename?: "Mutation";
  createAssetElement?: {
    __typename?: "CreateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type CreateAssetPresentValueMutationVariables = Exact<{
  input: CreateAssetPresentValueInput;
}>;

export type CreateAssetPresentValueMutation = {
  __typename?: "Mutation";
  createAssetPresentValue?: {
    __typename?: "CreateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type UpdateAssetElementMutationVariables = Exact<{
  input: UpdateAssetElementInput;
}>;

export type UpdateAssetElementMutation = {
  __typename?: "Mutation";
  updateAssetElement?: {
    __typename?: "UpdateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type UpdateAssetPresentValueMutationVariables = Exact<{
  input: UpdateAssetPresentValueInput;
}>;

export type UpdateAssetPresentValueMutation = {
  __typename?: "Mutation";
  updateAssetPresentValue?: {
    __typename?: "UpdateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type DeleteAssetElementMutationVariables = Exact<{
  input: DeleteAssetElementInput;
}>;

export type DeleteAssetElementMutation = {
  __typename?: "Mutation";
  deleteAssetElement?: {
    __typename?: "DeleteAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type DeleteAssetPresentValueMutationVariables = Exact<{
  input: DeleteAssetPresentValueInput;
}>;

export type DeleteAssetPresentValueMutation = {
  __typename?: "Mutation";
  deleteAssetPresentValue?: {
    __typename?: "DeleteAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type AddAssetMediaMutationVariables = Exact<{
  input: AddAssetMediaInput;
}>;

export type AddAssetMediaMutation = {
  __typename?: "Mutation";
  addAssetMedia?: {
    __typename?: "AddAssetMediaPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteAssetMediaMutationVariables = Exact<{
  input: DeleteAssetMediaInput;
}>;

export type DeleteAssetMediaMutation = {
  __typename?: "Mutation";
  deleteAssetMedia?: {
    __typename?: "DeleteAssetMediaPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetAssetDocumentsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetDocumentsByAssetIdQuery = {
  __typename?: "Query";
  allAssetDocuments?: {
    __typename?: "AssetDocumentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentNodeEdge";
      node?: {
        __typename?: "AssetDocumentNode";
        id: string;
        title?: string | null;
        description?: string | null;
        documentType?: string | null;
        coaType?: string | null;
        issuer?: string | null;
        recipient?: string | null;
        isClassified?: boolean | null;
        releaseDate?: any | null;
        expirationDate?: any | null;
        notes?: string | null;
        archiveNumber?: string | null;
        fineArtsIssueType?: string | null;
        isTemporaryExport?: boolean | null;
        isTemporaryImport?: boolean | null;
        isCriticalCatalog?: boolean | null;
        editor?: string | null;
        curator?: string | null;
        subscriptionDate?: any | null;
        contractSubscriber?: string | null;
        publishingDate?: any | null;
        otherDocumentType?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        asset?: { __typename?: "AssetNode"; id: string } | null;
        attachments: {
          __typename?: "AssetDocumentAttachmentNodeConnection";
          edges: Array<{
            __typename?: "AssetDocumentAttachmentNodeEdge";
            node?: {
              __typename?: "AssetDocumentAttachmentNode";
              id: string;
              name?: string | null;
              file: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetDocumentMutationVariables = Exact<{
  input: CreateAssetDocumentInput;
}>;

export type CreateAssetDocumentMutation = {
  __typename?: "Mutation";
  createAssetDocument?: {
    __typename?: "CreateAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type UpdateAssetDocumentMutationVariables = Exact<{
  input: UpdateAssetDocumentInput;
}>;

export type UpdateAssetDocumentMutation = {
  __typename?: "Mutation";
  updateAssetDocument?: {
    __typename?: "UpdateAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type DeleteAssetDocumentMutationVariables = Exact<{
  input: DeleteAssetDocumentInput;
}>;

export type DeleteAssetDocumentMutation = {
  __typename?: "Mutation";
  deleteAssetDocument?: {
    __typename?: "DeleteAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type GetAssetDocumentAttachmentsByDocumentIdQueryVariables = Exact<{
  documentId: Scalars["ID"];
}>;

export type GetAssetDocumentAttachmentsByDocumentIdQuery = {
  __typename?: "Query";
  allAssetDocumentAttachments?: {
    __typename?: "AssetDocumentAttachmentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentAttachmentNodeEdge";
      node?: {
        __typename?: "AssetDocumentAttachmentNode";
        id: string;
        name?: string | null;
        file: string;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetDocumentAttachmentMutationVariables = Exact<{
  input: CreateAssetDocumentAttachmentInput;
}>;

export type CreateAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  createAssetDocumentAttachment?: {
    __typename?: "CreateAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type UpdateAssetDocumentAttachmentMutationVariables = Exact<{
  input: UpdateAssetDocumentAttachmentInput;
}>;

export type UpdateAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  updateAssetDocumentAttachment?: {
    __typename?: "UpdateAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type DeleteAssetDocumentAttachmentMutationVariables = Exact<{
  input: DeleteAssetDocumentAttachmentInput;
}>;

export type DeleteAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  deleteAssetDocumentAttachment?: {
    __typename?: "DeleteAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type DeleteAssetCarryingValueFileMutationVariables = Exact<{
  input: DeleteAssetCarryingValueFileInput;
}>;

export type DeleteAssetCarryingValueFileMutation = {
  __typename?: "Mutation";
  deleteAssetCarryingValueFile?: {
    __typename?: "DeleteAssetCarryingValueFilePayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
    } | null;
  } | null;
};

export type ExportAssetPdfMutationVariables = Exact<{
  input: ExportAssetPdfInput;
}>;

export type ExportAssetPdfMutation = {
  __typename?: "Mutation";
  exportAssetPdf?: {
    __typename?: "ExportAssetPDFPayload";
    url?: string | null;
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type GetCurrentClientQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCurrentClientQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "ClientNode";
    createdOn: any;
    customerType: string;
    currency: string;
    dashboardBaseUrl: string;
    id: string;
    location: string;
    logo?: string | null;
    name: string;
    onTrial: boolean;
    paidUntil: any;
    schemaName: string;
    storageName: string;
    tier: string;
    timezone: string;
    defaultInsuranceCompany?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    defaultBroker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
};

export type CollectionAnagraphicFieldsFragment = {
  __typename?: "CollectionNode";
  id: string;
  name: string;
  description?: string | null;
  notes?: string | null;
  image?: string | null;
  totalAssetsNumber?: number | null;
  validatedAssetsNumber?: number | null;
  createdFrom?: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category: string;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        actualDateAndPeriod?: string | null;
      } | null;
    } | null>;
  };
};

export type GetCollectionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCollectionByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "CollectionNode";
    id: string;
    name: string;
    description?: string | null;
    notes?: string | null;
    image?: string | null;
    totalAssetsNumber?: number | null;
    validatedAssetsNumber?: number | null;
    createdFrom?: string | null;
    createdAt: string;
    updatedAt: string;
    createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category: string;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          actualDateAndPeriod?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CollectionFindingFieldsFragment = {
  __typename?: "CollectionNode";
  id: string;
  name: string;
  description?: string | null;
  notes?: string | null;
};

export type GetAllCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCollectionsQuery = {
  __typename?: "Query";
  allCollections?: {
    __typename?: "CollectionNodeConnection";
    edges: Array<{
      __typename?: "CollectionNodeEdge";
      node?: {
        __typename?: "CollectionNode";
        id: string;
        name: string;
        description?: string | null;
        image?: string | null;
        notes?: string | null;
        totalAssetsNumber?: number | null;
        validatedAssetsNumber?: number | null;
        createdFrom?: string | null;
        actualStatusObjects?: string | null;
        createdAt: string;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateCollectionMutationVariables = Exact<{
  input: CreateCollectionInput;
}>;

export type CreateCollectionMutation = {
  __typename?: "Mutation";
  createCollection?: {
    __typename?: "CreateCollectionPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      name: string;
      description?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateCollectionInput;
}>;

export type UpdateCollectionMutation = {
  __typename?: "Mutation";
  updateCollection?: {
    __typename?: "UpdateCollectionPayload";
    collection?: { __typename?: "CollectionNode"; id: string } | null;
  } | null;
};

export type DeleteCollectionMutationVariables = Exact<{
  input: DeleteCollectionInput;
}>;

export type DeleteCollectionMutation = {
  __typename?: "Mutation";
  deleteCollection?: {
    __typename?: "DeleteCollectionPayload";
    collection?: { __typename?: "CollectionNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromCollectionMutationVariables = Exact<{
  input: DetachAssetFromCollectionInput;
}>;

export type DetachAssetFromCollectionMutation = {
  __typename?: "Mutation";
  detachAssetFromCollection?: {
    __typename?: "DetachAssetFromCollectionPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: { __typename?: "AssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportCollectionPdfMutationVariables = Exact<{
  input: ExportCollectionPdfInput;
}>;

export type ExportCollectionPdfMutation = {
  __typename?: "Mutation";
  exportCollectionPdf?: {
    __typename?: "ExportCollectionPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type ConditionStatusReportFindingFieldsFragment = {
  __typename?: "ConditionStatusReportNode";
  id: string;
  reportStatus?: string | null;
  reportDate?: string | null;
  reportType?: string | null;
  reportMotivation?: string | null;
  reportMotivationDescription?: string | null;
  reportAsset?: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
  reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type ConditionStatusReportAnagraphicFieldsFragment = {
  __typename?: "ConditionStatusReportNode";
  id: string;
  reportStatus?: string | null;
  environmentalConditionsAbnormalHumidityEvent?: string | null;
  environmentalConditionsAbnormalLightEvent?: string | null;
  environmentalConditionsAbnormalTemperatureEvent?: string | null;
  environmentalConditionsLightUnit?: string | null;
  environmentalConditionsOptimalHumidity?: number | null;
  environmentalConditionsOptimalLightLevelIntensity?: number | null;
  environmentalConditionsOptimalTemperature?: number | null;
  environmentalConditionsTemperatureUnit?: string | null;
  exhibitionEventFrom?: string | null;
  exhibitionEventTo?: string | null;
  exhibitionLoanFrom?: string | null;
  exhibitionLoanTo?: string | null;
  exhibitionLocation?: string | null;
  exhibitionName?: string | null;
  exhibitionNotes?: string | null;
  exhibitionPermanent: boolean;
  exhibitionTitle?: string | null;
  logisticsAirConditioningTransportConditions?: string | null;
  logisticsDeliveryAddress?: string | null;
  logisticsDeliveryCity?: string | null;
  logisticsDeliveryCountry?: string | null;
  logisticsDeliveryDate?: string | null;
  logisticsDeliveryState?: string | null;
  logisticsDeliveryZip?: string | null;
  logisticsId?: string | null;
  logisticsNumberOfPackages?: number | null;
  logisticsPickupAddress?: string | null;
  logisticsPickupCity?: string | null;
  logisticsPickupCountry?: string | null;
  logisticsPickupDate?: string | null;
  logisticsPickupState?: string | null;
  logisticsPickupZip?: string | null;
  reportDate?: string | null;
  reportDetailsAssetCondition?: string | null;
  reportDetailsAssetDamageDescription?: string | null;
  reportDetailsExamConditions?: string | null;
  reportDetailsExamConditionsNote?: string | null;
  reportDetailsExamLocation?: string | null;
  reportDetailsExhaminationTimeTechnicalDescription?: string | null;
  reportDetailsPackagingGeneralConditions?: string | null;
  reportDetailsExaminedPackages?: string | null;
  reportDetailsPackagingStatusDescription?: string | null;
  reportDetailsProtectionsSupportsCondition?: string | null;
  reportDetailsProtectionsSupportsDamageDescription?: string | null;
  reportExaminationType?: string | null;
  reportMotivation?: string | null;
  reportMotivationDescription?: string | null;
  reportType?: string | null;
  settingsConservation?: string | null;
  settingsExaminerComments?: string | null;
  settingsHandlingRequirements?: string | null;
  settingsHasBarriers?: boolean | null;
  settingsInjuryRisk?: string | null;
  settingsLenderRequests?: string | null;
  settingsNotes?: string | null;
  valuePercentageDepreciation?: number | null;
  exhibitionCurator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  exhibitionHostEntity?: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
  } | null;
  exhibitionLender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  exhibitionPlanner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsCarrier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsRecipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsSender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportAsset?: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    hasBarriers?: boolean | null;
    injuryRisk?: string | null;
    injuryRiskDescription?: string | null;
    conservation?: string | null;
    lenderRequests?: string | null;
    optimalLightLevelIntensity?: number | null;
    lightUnit?: string | null;
    optimalTemperature?: number | null;
    temperatureUnit?: string | null;
    optimalHumidity?: number | null;
    handlingRequirements?: string | null;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
  valueEstimatedValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  valueEstimatedValueExaminationTime?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllConditionStatusReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllConditionStatusReportsQuery = {
  __typename?: "Query";
  allConditionStatusReports?: {
    __typename?: "ConditionStatusReportNodeConnection";
    edges: Array<{
      __typename?: "ConditionStatusReportNodeEdge";
      node?: {
        __typename?: "ConditionStatusReportNode";
        createdAt: string;
        id: string;
        reportStatus?: string | null;
        reportDate?: string | null;
        reportType?: string | null;
        reportMotivation?: string | null;
        reportMotivationDescription?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        reportAsset?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
        reportExaminer?: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetConditionStatusReportByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetConditionStatusReportByIdQuery = {
  __typename?: "Query";
  conditionStatusReport?: {
    __typename?: "ConditionStatusReportNode";
    id: string;
    reportStatus?: string | null;
    environmentalConditionsAbnormalHumidityEvent?: string | null;
    environmentalConditionsAbnormalLightEvent?: string | null;
    environmentalConditionsAbnormalTemperatureEvent?: string | null;
    environmentalConditionsLightUnit?: string | null;
    environmentalConditionsOptimalHumidity?: number | null;
    environmentalConditionsOptimalLightLevelIntensity?: number | null;
    environmentalConditionsOptimalTemperature?: number | null;
    environmentalConditionsTemperatureUnit?: string | null;
    exhibitionEventFrom?: string | null;
    exhibitionEventTo?: string | null;
    exhibitionLoanFrom?: string | null;
    exhibitionLoanTo?: string | null;
    exhibitionLocation?: string | null;
    exhibitionName?: string | null;
    exhibitionNotes?: string | null;
    exhibitionPermanent: boolean;
    exhibitionTitle?: string | null;
    logisticsAirConditioningTransportConditions?: string | null;
    logisticsDeliveryAddress?: string | null;
    logisticsDeliveryCity?: string | null;
    logisticsDeliveryCountry?: string | null;
    logisticsDeliveryDate?: string | null;
    logisticsDeliveryState?: string | null;
    logisticsDeliveryZip?: string | null;
    logisticsId?: string | null;
    logisticsNumberOfPackages?: number | null;
    logisticsPickupAddress?: string | null;
    logisticsPickupCity?: string | null;
    logisticsPickupCountry?: string | null;
    logisticsPickupDate?: string | null;
    logisticsPickupState?: string | null;
    logisticsPickupZip?: string | null;
    reportDate?: string | null;
    reportDetailsAssetCondition?: string | null;
    reportDetailsAssetDamageDescription?: string | null;
    reportDetailsExamConditions?: string | null;
    reportDetailsExamConditionsNote?: string | null;
    reportDetailsExamLocation?: string | null;
    reportDetailsExhaminationTimeTechnicalDescription?: string | null;
    reportDetailsPackagingGeneralConditions?: string | null;
    reportDetailsExaminedPackages?: string | null;
    reportDetailsPackagingStatusDescription?: string | null;
    reportDetailsProtectionsSupportsCondition?: string | null;
    reportDetailsProtectionsSupportsDamageDescription?: string | null;
    reportExaminationType?: string | null;
    reportMotivation?: string | null;
    reportMotivationDescription?: string | null;
    reportType?: string | null;
    settingsConservation?: string | null;
    settingsExaminerComments?: string | null;
    settingsHandlingRequirements?: string | null;
    settingsHasBarriers?: boolean | null;
    settingsInjuryRisk?: string | null;
    settingsLenderRequests?: string | null;
    settingsNotes?: string | null;
    valuePercentageDepreciation?: number | null;
    exhibitionCurator?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    exhibitionHostEntity?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    exhibitionLender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    exhibitionPlanner?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    logisticsCarrier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    logisticsRecipient?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    logisticsSender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportAsset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      hasBarriers?: boolean | null;
      injuryRisk?: string | null;
      injuryRiskDescription?: string | null;
      conservation?: string | null;
      lenderRequests?: string | null;
      optimalLightLevelIntensity?: number | null;
      lightUnit?: string | null;
      optimalTemperature?: number | null;
      temperatureUnit?: string | null;
      optimalHumidity?: number | null;
      handlingRequirements?: string | null;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
    valueEstimatedValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    valueEstimatedValueExaminationTime?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateConditionStatusReportMutationVariables = Exact<{
  input: CreateConditionStatusReportInput;
}>;

export type CreateConditionStatusReportMutation = {
  __typename?: "Mutation";
  createConditionStatusReport?: {
    __typename?: "CreateConditionStatusReportPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      reportStatus?: string | null;
      environmentalConditionsAbnormalHumidityEvent?: string | null;
      environmentalConditionsAbnormalLightEvent?: string | null;
      environmentalConditionsAbnormalTemperatureEvent?: string | null;
      environmentalConditionsLightUnit?: string | null;
      environmentalConditionsOptimalHumidity?: number | null;
      environmentalConditionsOptimalLightLevelIntensity?: number | null;
      environmentalConditionsOptimalTemperature?: number | null;
      environmentalConditionsTemperatureUnit?: string | null;
      exhibitionEventFrom?: string | null;
      exhibitionEventTo?: string | null;
      exhibitionLoanFrom?: string | null;
      exhibitionLoanTo?: string | null;
      exhibitionLocation?: string | null;
      exhibitionName?: string | null;
      exhibitionNotes?: string | null;
      exhibitionPermanent: boolean;
      exhibitionTitle?: string | null;
      logisticsAirConditioningTransportConditions?: string | null;
      logisticsDeliveryAddress?: string | null;
      logisticsDeliveryCity?: string | null;
      logisticsDeliveryCountry?: string | null;
      logisticsDeliveryDate?: string | null;
      logisticsDeliveryState?: string | null;
      logisticsDeliveryZip?: string | null;
      logisticsId?: string | null;
      logisticsNumberOfPackages?: number | null;
      logisticsPickupAddress?: string | null;
      logisticsPickupCity?: string | null;
      logisticsPickupCountry?: string | null;
      logisticsPickupDate?: string | null;
      logisticsPickupState?: string | null;
      logisticsPickupZip?: string | null;
      reportDate?: string | null;
      reportDetailsAssetCondition?: string | null;
      reportDetailsAssetDamageDescription?: string | null;
      reportDetailsExamConditions?: string | null;
      reportDetailsExamConditionsNote?: string | null;
      reportDetailsExamLocation?: string | null;
      reportDetailsExhaminationTimeTechnicalDescription?: string | null;
      reportDetailsPackagingGeneralConditions?: string | null;
      reportDetailsExaminedPackages?: string | null;
      reportDetailsPackagingStatusDescription?: string | null;
      reportDetailsProtectionsSupportsCondition?: string | null;
      reportDetailsProtectionsSupportsDamageDescription?: string | null;
      reportExaminationType?: string | null;
      reportMotivation?: string | null;
      reportMotivationDescription?: string | null;
      reportType?: string | null;
      settingsConservation?: string | null;
      settingsExaminerComments?: string | null;
      settingsHandlingRequirements?: string | null;
      settingsHasBarriers?: boolean | null;
      settingsInjuryRisk?: string | null;
      settingsLenderRequests?: string | null;
      settingsNotes?: string | null;
      valuePercentageDepreciation?: number | null;
      exhibitionCurator?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionHostEntity?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionLender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionPlanner?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsCarrier?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsRecipient?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsSender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportAsset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValueExaminationTime?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateConditionStatusReportMutationVariables = Exact<{
  input: UpdateConditionStatusReportInput;
}>;

export type UpdateConditionStatusReportMutation = {
  __typename?: "Mutation";
  updateConditionStatusReport?: {
    __typename?: "UpdateConditionStatusReportPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      reportStatus?: string | null;
      environmentalConditionsAbnormalHumidityEvent?: string | null;
      environmentalConditionsAbnormalLightEvent?: string | null;
      environmentalConditionsAbnormalTemperatureEvent?: string | null;
      environmentalConditionsLightUnit?: string | null;
      environmentalConditionsOptimalHumidity?: number | null;
      environmentalConditionsOptimalLightLevelIntensity?: number | null;
      environmentalConditionsOptimalTemperature?: number | null;
      environmentalConditionsTemperatureUnit?: string | null;
      exhibitionEventFrom?: string | null;
      exhibitionEventTo?: string | null;
      exhibitionLoanFrom?: string | null;
      exhibitionLoanTo?: string | null;
      exhibitionLocation?: string | null;
      exhibitionName?: string | null;
      exhibitionNotes?: string | null;
      exhibitionPermanent: boolean;
      exhibitionTitle?: string | null;
      logisticsAirConditioningTransportConditions?: string | null;
      logisticsDeliveryAddress?: string | null;
      logisticsDeliveryCity?: string | null;
      logisticsDeliveryCountry?: string | null;
      logisticsDeliveryDate?: string | null;
      logisticsDeliveryState?: string | null;
      logisticsDeliveryZip?: string | null;
      logisticsId?: string | null;
      logisticsNumberOfPackages?: number | null;
      logisticsPickupAddress?: string | null;
      logisticsPickupCity?: string | null;
      logisticsPickupCountry?: string | null;
      logisticsPickupDate?: string | null;
      logisticsPickupState?: string | null;
      logisticsPickupZip?: string | null;
      reportDate?: string | null;
      reportDetailsAssetCondition?: string | null;
      reportDetailsAssetDamageDescription?: string | null;
      reportDetailsExamConditions?: string | null;
      reportDetailsExamConditionsNote?: string | null;
      reportDetailsExamLocation?: string | null;
      reportDetailsExhaminationTimeTechnicalDescription?: string | null;
      reportDetailsPackagingGeneralConditions?: string | null;
      reportDetailsExaminedPackages?: string | null;
      reportDetailsPackagingStatusDescription?: string | null;
      reportDetailsProtectionsSupportsCondition?: string | null;
      reportDetailsProtectionsSupportsDamageDescription?: string | null;
      reportExaminationType?: string | null;
      reportMotivation?: string | null;
      reportMotivationDescription?: string | null;
      reportType?: string | null;
      settingsConservation?: string | null;
      settingsExaminerComments?: string | null;
      settingsHandlingRequirements?: string | null;
      settingsHasBarriers?: boolean | null;
      settingsInjuryRisk?: string | null;
      settingsLenderRequests?: string | null;
      settingsNotes?: string | null;
      valuePercentageDepreciation?: number | null;
      exhibitionCurator?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionHostEntity?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionLender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionPlanner?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsCarrier?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsRecipient?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsSender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportAsset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValueExaminationTime?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteConditionStatusReportMutationVariables = Exact<{
  input: DeleteConditionStatusReportInput;
}>;

export type DeleteConditionStatusReportMutation = {
  __typename?: "Mutation";
  deleteConditionStatusReport?: {
    __typename?: "DeleteConditionStatusReportPayload";
    deleteConditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type CloseConditionStatusReportMutationVariables = Exact<{
  input: CloseConditionStatusReportInput;
}>;

export type CloseConditionStatusReportMutation = {
  __typename?: "Mutation";
  closeConditionStatusReport?: {
    __typename?: "CloseConditionStatusReportPayload";
    closeConditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromConditionStatusReportMutationVariables = Exact<{
  input: DetachAssetFromConditionStatusReportInput;
}>;

export type DetachAssetFromConditionStatusReportMutation = {
  __typename?: "Mutation";
  detachAssetFromConditionStatusReport?: {
    __typename?: "DetachAssetFromConditionStatusReportPayload";
    conditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type GetMediaConditionStatusReportByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaConditionStatusReportByIdQuery = {
  __typename?: "Query";
  conditionStatusReport?: {
    __typename?: "ConditionStatusReportNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddConditionStatusReportMediaMutationVariables = Exact<{
  input: AddConditionStatusReportMediaInput;
}>;

export type AddConditionStatusReportMediaMutation = {
  __typename?: "Mutation";
  addConditionStatusReportMedia?: {
    __typename?: "AddConditionStatusReportMediaPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteConditionStatusReportMediaMutationVariables = Exact<{
  input: DeleteConditionStatusReportMediaInput;
}>;

export type DeleteConditionStatusReportMediaMutation = {
  __typename?: "Mutation";
  deleteConditionStatusReportMedia?: {
    __typename?: "DeleteConditionStatusReportMediaPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportConditionStatusReportPdfMutationVariables = Exact<{
  input: ExportConditionStatusReportPdfInput;
}>;

export type ExportConditionStatusReportPdfMutation = {
  __typename?: "Mutation";
  exportConditionStatusReportPdf?: {
    __typename?: "ExportConditionStatusReportPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type EventLogFindingFieldsFragment = {
  __typename?: "EventLogNode";
  id: string;
  eventType?: string | null;
  hideOwner?: boolean | null;
  commerciallyAvailable?: boolean | null;
  sold?: boolean | null;
  collectionPublicName?: string | null;
  locationDescription?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type EventLogAnagraphicFieldsFragment = {
  __typename?: "EventLogNode";
  id: string;
  eventType?: string | null;
  hideOwner?: boolean | null;
  commerciallyAvailable?: boolean | null;
  sold?: boolean | null;
  collectionPublicName?: string | null;
  locationDescription?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  note?: string | null;
  acquisitionType?: string | null;
  actionType?: string | null;
  commission?: string | null;
  auctionDescription?: string | null;
  lotNumber?: string | null;
  sellingLocation?: string | null;
  externalId?: number | null;
  externalEntity: BaseEventLogExternalEntityChoices;
  document?: string | null;
  value?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  minimumPrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  maximumPrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  reservePrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  auctionRights?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category: string;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        actualDateAndPeriod?: string | null;
      } | null;
    } | null>;
  };
};

export type GetAllEventLogsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEventLogsQuery = {
  __typename?: "Query";
  allEventLogs?: {
    __typename?: "EventLogNodeConnection";
    edges: Array<{
      __typename?: "EventLogNodeEdge";
      node?: {
        __typename?: "EventLogNode";
        id: string;
        eventType?: string | null;
        hideOwner?: boolean | null;
        commerciallyAvailable?: boolean | null;
        sold?: boolean | null;
        collectionPublicName?: string | null;
        locationDescription?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetEventLogByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetEventLogByIdQuery = {
  __typename?: "Query";
  eventLog?: {
    __typename?: "EventLogNode";
    id: string;
    eventType?: string | null;
    hideOwner?: boolean | null;
    commerciallyAvailable?: boolean | null;
    sold?: boolean | null;
    collectionPublicName?: string | null;
    locationDescription?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    note?: string | null;
    acquisitionType?: string | null;
    actionType?: string | null;
    commission?: string | null;
    auctionDescription?: string | null;
    lotNumber?: string | null;
    sellingLocation?: string | null;
    externalId?: number | null;
    externalEntity: BaseEventLogExternalEntityChoices;
    document?: string | null;
    value?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    minimumPrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    maximumPrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    reservePrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    auctionRights?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category: string;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          actualDateAndPeriod?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateEventLogMutationVariables = Exact<{
  input: CreateEventLogInput;
}>;

export type CreateEventLogMutation = {
  __typename?: "Mutation";
  createEventLog?: {
    __typename?: "CreateEventLogPayload";
    eventLog?: {
      __typename?: "EventLogNode";
      id: string;
      eventType?: string | null;
      hideOwner?: boolean | null;
      commerciallyAvailable?: boolean | null;
      sold?: boolean | null;
      collectionPublicName?: string | null;
      locationDescription?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      note?: string | null;
      acquisitionType?: string | null;
      actionType?: string | null;
      commission?: string | null;
      auctionDescription?: string | null;
      lotNumber?: string | null;
      sellingLocation?: string | null;
      document?: string | null;
      value?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      minimumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      maximumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      reservePrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      auctionRights?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: {
            __typename?: "AssetNode";
            id: string;
            title: string;
            description?: string | null;
            author?: string | null;
            category: string;
            genericDimensions?: string | null;
            creationDate?: string | null;
            mainImage?: string | null;
            actualDateAndPeriod?: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateEventLogMutationVariables = Exact<{
  input: UpdateEventLogInput;
}>;

export type UpdateEventLogMutation = {
  __typename?: "Mutation";
  updateEventLog?: {
    __typename?: "UpdateEventLogPayload";
    eventLog?: {
      __typename?: "EventLogNode";
      id: string;
      eventType?: string | null;
      hideOwner?: boolean | null;
      commerciallyAvailable?: boolean | null;
      sold?: boolean | null;
      collectionPublicName?: string | null;
      locationDescription?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      note?: string | null;
      acquisitionType?: string | null;
      actionType?: string | null;
      commission?: string | null;
      auctionDescription?: string | null;
      lotNumber?: string | null;
      sellingLocation?: string | null;
      externalId?: number | null;
      externalEntity: BaseEventLogExternalEntityChoices;
      document?: string | null;
      value?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      minimumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      maximumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      reservePrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      auctionRights?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: {
            __typename?: "AssetNode";
            id: string;
            title: string;
            description?: string | null;
            author?: string | null;
            category: string;
            genericDimensions?: string | null;
            creationDate?: string | null;
            mainImage?: string | null;
            actualDateAndPeriod?: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteEventLogMutationVariables = Exact<{
  input: DeleteEventLogInput;
}>;

export type DeleteEventLogMutation = {
  __typename?: "Mutation";
  deleteEventLog?: {
    __typename?: "DeleteEventLogPayload";
    deleteEventLog?: { __typename?: "EventLogNode"; id: string } | null;
  } | null;
};

export type GetEventLogByAssetIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetEventLogByAssetIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    eventLogs: {
      __typename?: "EventLogNodeConnection";
      edges: Array<{
        __typename?: "EventLogNodeEdge";
        node?: {
          __typename?: "EventLogNode";
          id: string;
          eventType?: string | null;
          hideOwner?: boolean | null;
          commerciallyAvailable?: boolean | null;
          sold?: boolean | null;
          collectionPublicName?: string | null;
          locationDescription?: string | null;
          startDate?: string | null;
          endDate?: string | null;
          externalId?: number | null;
          externalEntity: BaseEventLogExternalEntityChoices;
          actionType?: string | null;
          owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type ExhibitionFindingFieldsFragment = {
  __typename?: "ExhibitionNode";
  id: string;
  name: string;
  description?: string | null;
  eventFrom?: string | null;
  eventTo?: string | null;
  notes?: string | null;
  permanent: boolean;
};

export type ExhibitionAnagraphicFieldsFragment = {
  __typename?: "ExhibitionNode";
  id: string;
  name: string;
  description?: string | null;
  eventFrom?: string | null;
  eventTo?: string | null;
  notes?: string | null;
  image?: string | null;
  permanent: boolean;
  totalAssetsNumber?: number | null;
  createdAt: string;
  updatedAt: string;
  createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
  curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category: string;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        actualDateAndPeriod?: string | null;
      } | null;
    } | null>;
  };
};

export type GetAllExhibitionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllExhibitionsQuery = {
  __typename?: "Query";
  allExhibitions?: {
    __typename?: "ExhibitionNodeConnection";
    edges: Array<{
      __typename?: "ExhibitionNodeEdge";
      node?: {
        __typename?: "ExhibitionNode";
        id: string;
        name: string;
        description?: string | null;
        eventFrom?: string | null;
        eventTo?: string | null;
        notes?: string | null;
        permanent: boolean;
        totalAssetsNumber?: number | null;
        actualTimingStatus?: string | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
        eventLocation?: { __typename?: "Address"; raw?: string | null } | null;
        eventLocationEntity?: {
          __typename?: "OfficeNode";
          id: string;
          name?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetExhibitionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetExhibitionByIdQuery = {
  __typename?: "Query";
  exhibition?: {
    __typename?: "ExhibitionNode";
    id: string;
    name: string;
    description?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    notes?: string | null;
    image?: string | null;
    permanent: boolean;
    totalAssetsNumber?: number | null;
    createdAt: string;
    updatedAt: string;
    createdBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; id: string; name: string; email: string } | null;
    curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category: string;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          actualDateAndPeriod?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateExhibitionMutationVariables = Exact<{
  input: CreateExhibitionInput;
}>;

export type CreateExhibitionMutation = {
  __typename?: "Mutation";
  createExhibition?: {
    __typename?: "CreateExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      name: string;
      description?: string | null;
      eventFrom?: string | null;
      eventTo?: string | null;
      notes?: string | null;
      permanent: boolean;
      curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type UpdateExhibitionMutationVariables = Exact<{
  input: UpdateExhibitionInput;
}>;

export type UpdateExhibitionMutation = {
  __typename?: "Mutation";
  updateExhibition?: {
    __typename?: "UpdateExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      name: string;
      description?: string | null;
      eventFrom?: string | null;
      eventTo?: string | null;
      notes?: string | null;
      permanent: boolean;
      curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type DeleteExhibitionMutationVariables = Exact<{
  input: DeleteExhibitionInput;
}>;

export type DeleteExhibitionMutation = {
  __typename?: "Mutation";
  deleteExhibition?: {
    __typename?: "DeleteExhibitionPayload";
    exhibition?: { __typename?: "ExhibitionNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromExhibitionMutationVariables = Exact<{
  input: DetachAssetFromExhibitionInput;
}>;

export type DetachAssetFromExhibitionMutation = {
  __typename?: "Mutation";
  detachAssetFromExhibition?: {
    __typename?: "DetachAssetFromExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: { __typename?: "AssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportExhibitionPdfMutationVariables = Exact<{
  input: ExportExhibitionPdfInput;
}>;

export type ExportExhibitionPdfMutation = {
  __typename?: "Mutation";
  exportExhibitionPdf?: {
    __typename?: "ExportExhibitionPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type CreateMediaMutationVariables = Exact<{
  input: CreateMediaInput;
}>;

export type CreateMediaMutation = {
  __typename?: "Mutation";
  createMedia?: {
    __typename?: "CreateMediaPayload";
    media?: {
      __typename?: "MediaNode";
      id: string;
      name?: string | null;
      file: string;
      description?: string | null;
      mediaType?: string | null;
      isMainImage?: boolean | null;
      imageType?: string | null;
      notes?: string | null;
      audioVideoFileType?: string | null;
      creationDate?: any | null;
      fileAuthor?: string | null;
      fileSize?: string | null;
      photographicEquipment?: string | null;
      matrixType?: string | null;
      isGlassPlexyAcetate?: boolean | null;
    } | null;
  } | null;
};

export type UpdateMediaMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;

export type UpdateMediaMutation = {
  __typename?: "Mutation";
  updateMedia?: {
    __typename?: "UpdateMediaPayload";
    media?: {
      __typename?: "MediaNode";
      id: string;
      name?: string | null;
      file: string;
      description?: string | null;
      mediaType?: string | null;
      isMainImage?: boolean | null;
      imageType?: string | null;
      notes?: string | null;
      audioVideoFileType?: string | null;
      creationDate?: any | null;
      fileAuthor?: string | null;
      fileSize?: string | null;
      photographicEquipment?: string | null;
      matrixType?: string | null;
      isGlassPlexyAcetate?: boolean | null;
    } | null;
  } | null;
};

export type PolicyAssetFieldsFragment = {
  __typename?: "PolicyAssetNode";
  catNat: boolean;
  coverTerrorism: boolean;
  coverType: string;
  coveredRiskType: string;
  evaluationType: string;
  exemption: boolean;
  id: string;
  lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
  deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
  lenderAddress?: {
    __typename?: "Address";
    raw?: string | null;
    locality?: string | null;
    state?: string | null;
    country?: string | null;
    streetNumber?: string | null;
    route?: string | null;
  } | null;
  shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  insuredValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
};

export type PolicyCoreFieldsFragment = {
  __typename?: "PolicyNode";
  id: string;
  offerCode: string;
  policyCode: string;
  policyStatus: string;
  issuedAt?: string | null;
  expiresAt?: string | null;
  policyType: string;
  contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
  insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
  broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
};

export type PolicyAssetOnPolicyFragment = {
  __typename?: "PolicyNode";
  policyAssets: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type PolicyMoneyFieldsFragment = {
  __typename?: "PolicyNode";
  evaluationType: string;
  totalInsuredPremium?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  totalInsuredValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
};

export type PolicyDriverFieldsFragment = {
  __typename?: "PolicyNode";
  catNat: boolean;
  coverTerrorism: boolean;
  coverType: string;
  coveredRiskType: string;
  exemption: boolean;
  hasVariableBasket: boolean;
};

export type GetAllPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPoliciesQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        isExpired?: boolean | null;
        hasValidCertificate: boolean;
        assetsCount?: number | null;
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt?: string | null;
        expiresAt?: string | null;
        policyType: string;
        evaluationType: string;
        createdBy?: {
          __typename?: "UserNode";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        totalInsuredPremium?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPoliciesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
}>;

export type GetAllPoliciesByTypeQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        isExpired?: boolean | null;
        assetsCount?: number | null;
        hasValidCertificate: boolean;
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt?: string | null;
        expiresAt?: string | null;
        policyType: string;
        evaluationType: string;
        createdBy?: {
          __typename?: "UserNode";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        exhibition?: { __typename?: "ExhibitionNode"; name: string } | null;
        eventEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        totalInsuredPremium?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ViewPolicyByIdReducedQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ViewPolicyByIdReducedQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
    insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
    broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  } | null;
};

export type ViewPolicyByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ViewPolicyByIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    autoRenewal: boolean;
    eventName?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    catNat: boolean;
    hasVariableBasket: boolean;
    coverTerrorism: boolean;
    coverType: string;
    coveredRiskType: string;
    exemption: boolean;
    createdAt: string;
    updatedAt: string;
    isExpired?: boolean | null;
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    evaluationType: string;
    broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    insuranceCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    contractingParty: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    eventEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    createdBy?: {
      __typename?: "UserNode";
      id: string;
      createdAt: string;
      name: string;
      email: string;
    } | null;
    updatedBy?: {
      __typename?: "UserNode";
      id: string;
      createdAt: string;
      name: string;
      email: string;
    } | null;
    policyAssets: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: {
          __typename?: "PolicyAssetNode";
          certificateProgressiveNumber?: number | null;
          catNat: boolean;
          coverTerrorism: boolean;
          coverType: string;
          coveredRiskType: string;
          evaluationType: string;
          exemption: boolean;
          id: string;
          asset: {
            __typename?: "AssetNode";
            id: string;
            creationPeriod?: string | null;
            title: string;
            category: string;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            validated: boolean;
            actualDateAndPeriod?: string | null;
            mainImage?: string | null;
            authorEntity?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
            createdBy?: { __typename?: "UserNode"; name: string } | null;
          };
          objectLocationEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          objectLocationOffice?: {
            __typename?: "OfficeNode";
            id: string;
            name?: string | null;
          } | null;
          lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
          deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
          lenderAddress?: {
            __typename?: "Address";
            raw?: string | null;
            locality?: string | null;
            state?: string | null;
            country?: string | null;
            streetNumber?: string | null;
            route?: string | null;
          } | null;
          shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          insuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
      } | null>;
    };
    policyNotes: {
      __typename?: "PolicyNoteNodeConnection";
      edges: Array<{
        __typename?: "PolicyNoteNodeEdge";
        node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
      } | null>;
    };
    policyDocuments: {
      __typename?: "PolicyDocumentNodeConnection";
      edges: Array<{
        __typename?: "PolicyDocumentNodeEdge";
        node?: {
          __typename?: "PolicyDocumentNode";
          id: string;
          uuid: string;
          createdAt: string;
          file?: string | null;
          createdBy?: { __typename?: "UserNode"; id: string; name: string } | null;
        } | null;
      } | null>;
    };
    totalInsuredPremium?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    totalInsuredValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
  } | null;
};

export type GetPolicyByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPolicyByIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    autoRenewal: boolean;
    eventName?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    catNat: boolean;
    hasVariableBasket: boolean;
    coverTerrorism: boolean;
    coverType: string;
    coveredRiskType: string;
    exemption: boolean;
    isExpired?: boolean | null;
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    evaluationType: string;
    broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    insuranceCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    contractingParty: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    eventEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    policyAssets: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: {
          __typename?: "PolicyAssetNode";
          certificateProgressiveNumber?: number | null;
          catNat: boolean;
          coverTerrorism: boolean;
          coverType: string;
          coveredRiskType: string;
          evaluationType: string;
          exemption: boolean;
          id: string;
          asset: {
            __typename?: "AssetNode";
            id: string;
            creationPeriod?: string | null;
            title: string;
            category: string;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            validated: boolean;
            actualDateAndPeriod?: string | null;
            mainImage?: string | null;
            authorEntity?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
            createdBy?: { __typename?: "UserNode"; name: string } | null;
          };
          objectLocationEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          objectLocationOffice?: {
            __typename?: "OfficeNode";
            id: string;
            name?: string | null;
          } | null;
          lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
          deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
          lenderAddress?: {
            __typename?: "Address";
            raw?: string | null;
            locality?: string | null;
            state?: string | null;
            country?: string | null;
            streetNumber?: string | null;
            route?: string | null;
          } | null;
          shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          insuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
      } | null>;
    };
    policyNotes: {
      __typename?: "PolicyNoteNodeConnection";
      edges: Array<{
        __typename?: "PolicyNoteNodeEdge";
        node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
      } | null>;
    };
    policyDocuments: {
      __typename?: "PolicyDocumentNodeConnection";
      edges: Array<{
        __typename?: "PolicyDocumentNodeEdge";
        node?: {
          __typename?: "PolicyDocumentNode";
          id: string;
          uuid: string;
          file?: string | null;
          createdAt: string;
        } | null;
      } | null>;
    };
    totalInsuredPremium?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    totalInsuredValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
  } | null;
};

export type GetPolicyNotesByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyNotesByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyNotes?: {
    __typename?: "PolicyNoteNodeConnection";
    edges: Array<{
      __typename?: "PolicyNoteNodeEdge";
      node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
    } | null>;
  } | null;
};

export type GetPolicyDocumentsByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyDocumentsByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyDocuments?: {
    __typename?: "PolicyDocumentNodeConnection";
    edges: Array<{
      __typename?: "PolicyDocumentNodeEdge";
      node?: {
        __typename?: "PolicyDocumentNode";
        id: string;
        file?: string | null;
        uuid: string;
        createdAt: string;
        createdBy?: { __typename?: "UserNode"; name: string; id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetBasketHistoryByPolicyIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBasketHistoryByPolicyIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    policybaskethistorySet: {
      __typename?: "PolicyBasketHistoryNodeConnection";
      edges: Array<{
        __typename?: "PolicyBasketHistoryNodeEdge";
        node?: {
          __typename?: "PolicyBasketHistoryNode";
          createdAt: string;
          reason: string;
          status: SpinPolicyBasketHistoryStatusChoices;
          uuid: string;
          overrideChangeDate?: string | null;
          createdBy?: { __typename?: "UserNode"; name: string } | null;
          policyassetbaskethistorySet: {
            __typename?: "PolicyAssetBasketHistoryNodeConnection";
            edges: Array<{
              __typename?: "PolicyAssetBasketHistoryNodeEdge";
              node?: {
                __typename?: "PolicyAssetBasketHistoryNode";
                diff?: string | null;
                asset: {
                  __typename?: "AssetNode";
                  title: string;
                  author?: string | null;
                  authorEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type CreatePolicyMutationVariables = Exact<{
  input: CreatePolicyInput;
}>;

export type CreatePolicyMutation = {
  __typename?: "Mutation";
  createPolicy?: {
    __typename?: "CreatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type CreatePolicyAssetMutationVariables = Exact<{
  input: CreatePolicyAssetInput;
}>;

export type CreatePolicyAssetMutation = {
  __typename?: "Mutation";
  createPolicyAsset?: {
    __typename?: "CreatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type CreatePolicyNoteMutationVariables = Exact<{
  input: CreatePolicyNoteInput;
}>;

export type CreatePolicyNoteMutation = {
  __typename?: "Mutation";
  createPolicyNote?: {
    __typename?: "CreatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type CreatePolicyDocumentMutationVariables = Exact<{
  input: CreatePolicyDocumentInput;
}>;

export type CreatePolicyDocumentMutation = {
  __typename?: "Mutation";
  createPolicyDocument?: {
    __typename?: "CreatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyMutationVariables = Exact<{
  input: UpdatePolicyInput;
}>;

export type UpdatePolicyMutation = {
  __typename?: "Mutation";
  updatePolicy?: {
    __typename?: "UpdatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type RenewPolicyMutationVariables = Exact<{
  input: RenewPolicyInput;
}>;

export type RenewPolicyMutation = {
  __typename?: "Mutation";
  renewPolicy?: {
    __typename?: "RenewPolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type ExtendPolicyMutationVariables = Exact<{
  input: ExtendPolicyInput;
}>;

export type ExtendPolicyMutation = {
  __typename?: "Mutation";
  extendPolicy?: {
    __typename?: "ExtendPolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type TerminatePolicyMutationVariables = Exact<{
  input: TerminatePolicyInput;
}>;

export type TerminatePolicyMutation = {
  __typename?: "Mutation";
  terminatePolicy?: {
    __typename?: "TerminatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type DuplicatePolicyMutationVariables = Exact<{
  input: DuplicatePolicyInput;
}>;

export type DuplicatePolicyMutation = {
  __typename?: "Mutation";
  duplicatePolicy?: {
    __typename?: "DuplicatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyAssetMutationVariables = Exact<{
  input: UpdatePolicyAssetInput;
}>;

export type UpdatePolicyAssetMutation = {
  __typename?: "Mutation";
  updatePolicyAsset?: {
    __typename?: "UpdatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyAssetGroupMutationVariables = Exact<{
  input: UpdatePolicyAssetGroupInput;
}>;

export type UpdatePolicyAssetGroupMutation = {
  __typename?: "Mutation";
  updatePolicyAssetGroup?: {
    __typename?: "UpdatePolicyAssetGroupPayload";
    policyAssets?: Array<{ __typename?: "PolicyAssetNode"; id: string } | null> | null;
  } | null;
};

export type UpdatePolicyNoteMutationVariables = Exact<{
  input: UpdatePolicyNoteInput;
}>;

export type UpdatePolicyNoteMutation = {
  __typename?: "Mutation";
  updatePolicyNote?: {
    __typename?: "UpdatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyDocumentMutationVariables = Exact<{
  input: UpdatePolicyDocumentInput;
}>;

export type UpdatePolicyDocumentMutation = {
  __typename?: "Mutation";
  updatePolicyDocument?: {
    __typename?: "UpdatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type DeletePolicyMutationVariables = Exact<{
  input: DeletePolicyInput;
}>;

export type DeletePolicyMutation = {
  __typename?: "Mutation";
  deletePolicy?: {
    __typename?: "DeletePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type DeletePolicyAssetMutationVariables = Exact<{
  input: DeletePolicyAssetInput;
}>;

export type DeletePolicyAssetMutation = {
  __typename?: "Mutation";
  deletePolicyAsset?: {
    __typename?: "DeletePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type DeletePolicyNoteMutationVariables = Exact<{
  input: DeletePolicyNoteInput;
}>;

export type DeletePolicyNoteMutation = {
  __typename?: "Mutation";
  deletePolicyNote?: {
    __typename?: "DeletePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type DeletePolicyDocumentMutationVariables = Exact<{
  input: DeletePolicyDocumentInput;
}>;

export type DeletePolicyDocumentMutation = {
  __typename?: "Mutation";
  deletePolicyDocument?: {
    __typename?: "DeletePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type GenerateCertificateMutationVariables = Exact<{
  input: GenerateCertificateInput;
}>;

export type GenerateCertificateMutation = {
  __typename?: "Mutation";
  generateCertificate?: {
    __typename?: "GenerateCertificatePayload";
    s3Load?: boolean | null;
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type DeleteCertificateMutationVariables = Exact<{
  input: DeleteCertificateInput;
}>;

export type DeleteCertificateMutation = {
  __typename?: "Mutation";
  deleteCertificate?: {
    __typename?: "DeleteCertificatePayload";
    certificate?: { __typename?: "CertificateNode"; id: string } | null;
  } | null;
};

export type SignCertificateMutationVariables = Exact<{
  input: SignCertificateInput;
}>;

export type SignCertificateMutation = {
  __typename?: "Mutation";
  signCertificate?: {
    __typename?: "SignCertificatePayload";
    certificate?: {
      __typename?: "CertificateNode";
      file?: string | null;
      signed: boolean;
      signedAt?: string | null;
      signedBy?: { __typename?: "UserNode"; name: string } | null;
    } | null;
  } | null;
};

export type GetAllCertificatesByPolicyIdAndIsActiveQueryVariables = Exact<{
  policy: Scalars["ID"];
  isValid: Scalars["Boolean"];
}>;

export type GetAllCertificatesByPolicyIdAndIsActiveQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        file?: string | null;
        uuid: string;
        creationDate: string;
        isValid: boolean;
        language: string;
        progressiveNumber?: number | null;
        createdAt: string;
        signed: boolean;
        metafields?: any | null;
        isSplitted: boolean;
        signedAt?: string | null;
        lender?: string | null;
        lenderCountry?: string | null;
        policyAssetsCount?: number | null;
        createdBy?: { __typename?: "UserNode"; id: string; name: string } | null;
        signedBy?: { __typename?: "UserNode"; id: string; name: string } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificatesByPolicyIdQueryVariables = Exact<{
  policy: Scalars["ID"];
}>;

export type GetAllCertificatesByPolicyIdQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        file?: string | null;
        uuid: string;
        creationDate: string;
        isValid: boolean;
        language: string;
        createdAt: string;
        signed: boolean;
        progressiveNumber?: number | null;
        metafields?: any | null;
        isSplitted: boolean;
        signedAt?: string | null;
        createdBy?: { __typename?: "UserNode"; id: string; name: string } | null;
        signedBy?: { __typename?: "UserNode"; id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificatesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
}>;

export type GetAllCertificatesByTypeQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        uuid: string;
        certificateType: SpinCertificateCertificateTypeChoices;
        file?: string | null;
        isValid: boolean;
        language: string;
        updatedAt: string;
        createdAt: string;
        progressiveNumber?: number | null;
        creationDate: string;
        lenderCountry?: string | null;
        mainOfficeCountry?: string | null;
        lender?: string | null;
        shipper?: string | null;
        signed: boolean;
        policy?: {
          __typename?: "PolicyNode";
          offerCode: string;
          policyCode: string;
          isExpired?: boolean | null;
          broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
          contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
          exhibition?: { __typename?: "ExhibitionNode"; name: string } | null;
          eventLocationEntity?: { __typename?: "OfficeNode"; name?: string | null } | null;
          totalInsuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificateTemplatesByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type GetAllCertificateTemplatesByNameQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPolicyAssetsQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat: boolean;
        coverTerrorism: boolean;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption: boolean;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author?: string | null;
          title: string;
        };
        policy: { __typename?: "PolicyNode"; offerCode: string; policyCode: string };
        lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
        shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsByPolicyTypeQueryVariables = Exact<{
  policy_PolicyType: Scalars["String"];
}>;

export type GetAllPolicyAssetsByPolicyTypeQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat: boolean;
        coverTerrorism: boolean;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption: boolean;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author?: string | null;
          title: string;
          category: string;
          genericDimensions?: string | null;
          technique?: string | null;
          inventoryNumber?: string | null;
          validated: boolean;
          actualDateAndPeriod?: string | null;
          mainImage?: string | null;
          createdBy?: { __typename?: "UserNode"; name: string } | null;
        };
        policy: { __typename?: "PolicyNode"; id: string; offerCode: string; policyCode: string };
        objectLocationEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
        shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetLastPresentValueByAssetIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetLastPresentValueByAssetIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
          amount: { __typename?: "Money"; amount?: number | null };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssetCarryingValueQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetCarryingValueQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    carryingValueAmountCurrency: string;
    carryingValueAmount?: { __typename?: "Money"; amount?: number | null } | null;
  } | null;
};

export type GenerateSplitCertificateMutationVariables = Exact<{
  input: GenerateSplitCertificateInput;
}>;

export type GenerateSplitCertificateMutation = {
  __typename?: "Mutation";
  generateSplitCertificate?: {
    __typename?: "GenerateSplitCertificatePayload";
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type GenerateGroupedCertificateMutationVariables = Exact<{
  input: GenerateGroupedCertificateInput;
}>;

export type GenerateGroupedCertificateMutation = {
  __typename?: "Mutation";
  generateGroupedCertificate?: {
    __typename?: "GenerateGroupedCertificatePayload";
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type GetAllCertificateTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCertificateTemplatesQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
        uuid: string;
        createdAt: string;
      } | null;
    } | null>;
  } | null;
};

export type CreateCertificateTemplateMutationVariables = Exact<{
  input: CreateCertificateTemplateInput;
}>;

export type CreateCertificateTemplateMutation = {
  __typename?: "Mutation";
  createCertificateTemplate?: {
    __typename?: "CreateCertificateTemplatePayload";
    certificateTemplate?: { __typename?: "CertificateTemplateNode"; id: string } | null;
  } | null;
};

export type DeleteCertificateTemplateMutationVariables = Exact<{
  input: DeleteCertificateTemplateInput;
}>;

export type DeleteCertificateTemplateMutation = {
  __typename?: "Mutation";
  deleteCertificateTemplate?: {
    __typename?: "DeleteCertificateTemplatePayload";
    certificateTemplate?: { __typename?: "CertificateTemplateNode"; id: string } | null;
  } | null;
};

export type GetPolicyDiffChangesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPolicyDiffChangesQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    diffChanges?: Array<{
      __typename?: "DiffChangesNode";
      changeReason?: string | null;
      changedAt?: string | null;
      field?: string | null;
      old?: string | null;
      new?: string | null;
      historyId?: string | null;
    } | null> | null;
    history?: Array<{
      __typename?: "HistoryListItemNode";
      historyId?: string | null;
      historyChangeReason?: string | null;
      historyDate?: string | null;
    } | null> | null;
    contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
    insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
    broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  } | null;
};

export type RegistryInfoFieldsFragment = {
  __typename?: "RegistryNode";
  createdAt: string;
  updatedAt: string;
  createdBy?: { __typename?: "UserNode"; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserNode"; name: string; email: string } | null;
};

export type RegistryAnagraphicFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  isCompany: boolean;
  fullName?: string | null;
  businessName?: string | null;
  alias?: string | null;
  role?: string | null;
  displayName?: string | null;
  webSite?: string | null;
  note?: string | null;
  countryCode?: string | null;
  taxId?: string | null;
  printName?: string | null;
  printCode?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  isAuthor?: boolean | null;
  birthDate?: string | null;
  birthPlace?: string | null;
  deathDate?: string | null;
  deathPlace?: string | null;
  activityPeriod?: string | null;
  activityPlace?: string | null;
  artisticMovement?: string | null;
  foundation?: string | null;
  categories?: Array<string | null> | null;
  authorType?: Array<string | null> | null;
  invoiceAddress?: {
    __typename?: "Address";
    raw?: string | null;
    formatted?: string | null;
  } | null;
};

export type RegistryFindingFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  categories?: Array<string | null> | null;
  fullName?: string | null;
  businessName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  officesCount?: number | null;
  createdBy?: { __typename?: "UserNode"; name: string } | null;
};

export type OfficeAnagraphicFieldsFragment = {
  __typename?: "OfficeNode";
  id: string;
  name?: string | null;
};

export type CompanyContactAnagraphicFieldsFragment = {
  __typename?: "CompanyContactNode";
  id: string;
  main: boolean;
  companyContact: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    alias?: string | null;
    role?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
  };
  relatedCompany: { __typename?: "RegistryNode"; id: string };
};

export type GetAllRegistriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        mainOfficeAddress?: string | null;
        role?: string | null;
        actualCompanyContactsNumber?: string | null;
        isAuthor?: boolean | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUniqueAnonymousRegistryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUniqueAnonymousRegistryQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type GetUniqueUnknownRegistryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUniqueUnknownRegistryQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesAuthorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesAuthorsQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        alias?: string | null;
        birthDate?: string | null;
        deathDate?: string | null;
        authorType?: Array<string | null> | null;
        authoredAssetsCount?: number | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesPeopleQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesPeopleQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        role?: string | null;
        mainOfficeAddress?: string | null;
        actualCompanyContactsNumber?: string | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesCompaniesQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        role?: string | null;
        mainOfficeAddress?: string | null;
        actualCompanyContactsNumber?: string | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRegistriesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetRegistriesBySearchQuery = {
  __typename?: "Query";
  registries?: Array<{
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      formatted?: string | null;
    } | null;
    createdBy?: { __typename?: "UserNode"; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; name: string; email: string } | null;
  } | null> | null;
};

export type GetRegistriesByExactSearchQueryVariables = Exact<{
  exactSearch: Scalars["String"];
}>;

export type GetRegistriesByExactSearchQuery = {
  __typename?: "Query";
  registries?: Array<{
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      formatted?: string | null;
    } | null;
    createdBy?: { __typename?: "UserNode"; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; name: string; email: string } | null;
  } | null> | null;
};

export type GetRegistryByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    createdAt: string;
    updatedAt: string;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      formatted?: string | null;
    } | null;
    createdBy?: { __typename?: "UserNode"; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserNode"; name: string; email: string } | null;
  } | null;
};

export type GetRegistryByIdToEditQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdToEditQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      formatted?: string | null;
    } | null;
  } | null;
};

export type CreateRegistryMutationVariables = Exact<{
  input: CreateRegistryInput;
}>;

export type CreateRegistryMutation = {
  __typename?: "Mutation";
  createRegistry?: {
    __typename?: "CreateRegistryPayload";
    registry?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
      businessName?: string | null;
      alias?: string | null;
      displayName?: string | null;
    } | null;
  } | null;
};

export type UpdateRegistryMutationVariables = Exact<{
  input: UpdateRegistryInput;
}>;

export type UpdateRegistryMutation = {
  __typename?: "Mutation";
  updateRegistry?: {
    __typename?: "UpdateRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type DeleteRegistryMutationVariables = Exact<{
  input: DeleteRegistryInput;
}>;

export type DeleteRegistryMutation = {
  __typename?: "Mutation";
  deleteRegistry?: {
    __typename?: "DeleteRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type GetOfficesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
  registryId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetOfficesBySearchQuery = {
  __typename?: "Query";
  offices?: Array<{ __typename?: "OfficeNode"; id: string; name?: string | null } | null> | null;
};

export type GetOfficeByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOfficeByIdQuery = {
  __typename?: "Query";
  office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
};

export type CreateOfficeMutationVariables = Exact<{
  input: CreateOfficeInput;
}>;

export type CreateOfficeMutation = {
  __typename?: "Mutation";
  createOffice?: {
    __typename?: "CreateOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
  } | null;
};

export type UpdateOfficeMutationVariables = Exact<{
  input: UpdateOfficeInput;
}>;

export type UpdateOfficeMutation = {
  __typename?: "Mutation";
  updateOffice?: {
    __typename?: "UpdateOfficePayload";
    office?: { __typename?: "OfficeType"; id: string } | null;
  } | null;
};

export type DeleteOfficeMutationVariables = Exact<{
  input: DeleteOfficeInput;
}>;

export type DeleteOfficeMutation = {
  __typename?: "Mutation";
  deleteOffice?: {
    __typename?: "DeleteOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string } | null;
  } | null;
};

export type GetOfficesByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetOfficesByRegistryIdQuery = {
  __typename?: "Query";
  allOffices?: {
    __typename?: "OfficeNodeConnection";
    edges: Array<{
      __typename?: "OfficeNodeEdge";
      node?: {
        __typename?: "OfficeNode";
        id: string;
        name?: string | null;
        address?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
        country?: string | null;
        phone?: string | null;
        email?: string | null;
        main: boolean;
        active: boolean;
        officeAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCompanyContactsByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetCompanyContactsByRegistryIdQuery = {
  __typename?: "Query";
  allCompanyContacts?: {
    __typename?: "CompanyContactNodeConnection";
    edges: Array<{
      __typename?: "CompanyContactNodeEdge";
      node?: {
        __typename?: "CompanyContactNode";
        id: string;
        main: boolean;
        companyContact: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
          alias?: string | null;
          role?: string | null;
          phone?: string | null;
          mobilePhone?: string | null;
          email?: string | null;
          note?: string | null;
          webSite?: string | null;
          printCode?: string | null;
          printName?: string | null;
          countryCode?: string | null;
          taxId?: string | null;
          billingAddress?: string | null;
        };
        relatedCompany: { __typename?: "RegistryNode"; id: string };
      } | null;
    } | null>;
  } | null;
};

export type DeleteCompanyContactMutationVariables = Exact<{
  input: DeleteCompanyContactInput;
}>;

export type DeleteCompanyContactMutation = {
  __typename?: "Mutation";
  deleteCompanyContact?: {
    __typename?: "DeleteCompanyContactPayload";
    companyContact?: { __typename?: "CompanyContactNode"; id: string } | null;
  } | null;
};

export type CreateCompanyContactMutationVariables = Exact<{
  input: CreateCompanyContactInput;
}>;

export type CreateCompanyContactMutation = {
  __typename?: "Mutation";
  createCompanyContact?: {
    __typename?: "CreateCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactNode";
      id: string;
      main: boolean;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
      relatedCompany: { __typename?: "RegistryNode"; id: string };
    } | null;
  } | null;
};

export type UpdateCompanyContactMutationVariables = Exact<{
  input: UpdateCompanyContactInput;
}>;

export type UpdateCompanyContactMutation = {
  __typename?: "Mutation";
  updateCompanyContact?: {
    __typename?: "UpdateCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactType";
      id: string;
      main: boolean;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
        note?: string | null;
        webSite?: string | null;
        printCode?: string | null;
        printName?: string | null;
        countryCode?: string | null;
        taxId?: string | null;
        billingAddress?: string | null;
      };
      relatedCompany: { __typename?: "RegistryNode"; id: string };
    } | null;
  } | null;
};

export type CreateRegistryWithCompanyContactMutationVariables = Exact<{
  input: CreateRegistryWithCompanyContactInput;
}>;

export type CreateRegistryWithCompanyContactMutation = {
  __typename?: "Mutation";
  createRegistryWithCompanyContact?: {
    __typename?: "CreateRegistryWithCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactNode";
      id: string;
      main: boolean;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
      relatedCompany: { __typename?: "RegistryNode"; id: string };
    } | null;
  } | null;
};

export type RestorationFindingFieldsFragment = {
  __typename?: "RestorationNode";
  id: string;
  restorationStatus?: string | null;
  restorationDocumentDate?: string | null;
  restorationStartDate?: string | null;
  restorationEndDate?: string | null;
  restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  restorationAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  createdBy?: { __typename?: "UserNode"; name: string } | null;
};

export type RestorationAnagraphicFieldsFragment = {
  __typename?: "RestorationNode";
  id: string;
  restorationStatus?: string | null;
  restorationRestoredBy?: string | null;
  restorationInventoryNumber?: string | null;
  restorationStartDate?: string | null;
  restorationEndDate?: string | null;
  restorationLocation?: string | null;
  restorationCollocationFeatures?: string | null;
  restorationDocumentDate?: string | null;
  conservativeDataHistoryInterventions?: string | null;
  conservativeDataConservationStatus?: string | null;
  conservativeDataAssetAlterations?: string | null;
  conservativeDataDeteriorationCauses?: string | null;
  conservativeDataExaminationMethod?: string | null;
  conservativeDataInterventionProposal?: string | null;
  conservativeDataPreliminaryOperations?: string | null;
  conservativeDataIntervention?: string | null;
  conservativeDataInterventionMaterials?: string | null;
  conservativeDataTechnicalMethodologicalConsiderations?: string | null;
  conservativeDataMaintenancePreparationAssembly?: string | null;
  conservativeDataRestorationBibliography?: string | null;
  conservativeDataNotesRestorationSheet?: string | null;
  restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  restorationCompany?: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    businessName?: string | null;
  } | null;
  restorationAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  restorationCost?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllRestorationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRestorationsQuery = {
  __typename?: "Query";
  allRestorations?: {
    __typename?: "RestorationNodeConnection";
    edges: Array<{
      __typename?: "RestorationNodeEdge";
      node?: {
        __typename?: "RestorationNode";
        createdAt: string;
        actualMediaStatus?: string | null;
        mediaNumber?: number | null;
        id: string;
        restorationStatus?: string | null;
        restorationDocumentDate?: string | null;
        restorationStartDate?: string | null;
        restorationEndDate?: string | null;
        restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
        restorationAsset: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        };
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRestorationByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRestorationByIdQuery = {
  __typename?: "Query";
  restoration?: {
    __typename?: "RestorationNode";
    id: string;
    restorationStatus?: string | null;
    restorationRestoredBy?: string | null;
    restorationInventoryNumber?: string | null;
    restorationStartDate?: string | null;
    restorationEndDate?: string | null;
    restorationLocation?: string | null;
    restorationCollocationFeatures?: string | null;
    restorationDocumentDate?: string | null;
    conservativeDataHistoryInterventions?: string | null;
    conservativeDataConservationStatus?: string | null;
    conservativeDataAssetAlterations?: string | null;
    conservativeDataDeteriorationCauses?: string | null;
    conservativeDataExaminationMethod?: string | null;
    conservativeDataInterventionProposal?: string | null;
    conservativeDataPreliminaryOperations?: string | null;
    conservativeDataIntervention?: string | null;
    conservativeDataInterventionMaterials?: string | null;
    conservativeDataTechnicalMethodologicalConsiderations?: string | null;
    conservativeDataMaintenancePreparationAssembly?: string | null;
    conservativeDataRestorationBibliography?: string | null;
    conservativeDataNotesRestorationSheet?: string | null;
    restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    restorationCompany?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
      businessName?: string | null;
    } | null;
    restorationAsset: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    };
    restorationCost?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateRestorationMutationVariables = Exact<{
  input: CreateRestorationInput;
}>;

export type CreateRestorationMutation = {
  __typename?: "Mutation";
  createRestoration?: {
    __typename?: "CreateRestorationPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      restorationStatus?: string | null;
      restorationRestoredBy?: string | null;
      restorationInventoryNumber?: string | null;
      restorationStartDate?: string | null;
      restorationEndDate?: string | null;
      restorationLocation?: string | null;
      restorationCollocationFeatures?: string | null;
      restorationDocumentDate?: string | null;
      conservativeDataHistoryInterventions?: string | null;
      conservativeDataConservationStatus?: string | null;
      conservativeDataAssetAlterations?: string | null;
      conservativeDataDeteriorationCauses?: string | null;
      conservativeDataExaminationMethod?: string | null;
      conservativeDataInterventionProposal?: string | null;
      conservativeDataPreliminaryOperations?: string | null;
      conservativeDataIntervention?: string | null;
      conservativeDataInterventionMaterials?: string | null;
      conservativeDataTechnicalMethodologicalConsiderations?: string | null;
      conservativeDataMaintenancePreparationAssembly?: string | null;
      conservativeDataRestorationBibliography?: string | null;
      conservativeDataNotesRestorationSheet?: string | null;
      restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      restorationCompany?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        businessName?: string | null;
      } | null;
      restorationAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      restorationCost?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: {
            __typename?: "MediaNode";
            id: string;
            name?: string | null;
            file: string;
            description?: string | null;
            mediaType?: string | null;
            isMainImage?: boolean | null;
            imageType?: string | null;
            notes?: string | null;
            audioVideoFileType?: string | null;
            creationDate?: any | null;
            fileAuthor?: string | null;
            fileSize?: string | null;
            photographicEquipment?: string | null;
            matrixType?: string | null;
            isGlassPlexyAcetate?: boolean | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateRestorationMutationVariables = Exact<{
  input: UpdateRestorationInput;
}>;

export type UpdateRestorationMutation = {
  __typename?: "Mutation";
  updateRestoration?: {
    __typename?: "UpdateRestorationPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      restorationStatus?: string | null;
      restorationRestoredBy?: string | null;
      restorationInventoryNumber?: string | null;
      restorationStartDate?: string | null;
      restorationEndDate?: string | null;
      restorationLocation?: string | null;
      restorationCollocationFeatures?: string | null;
      restorationDocumentDate?: string | null;
      conservativeDataHistoryInterventions?: string | null;
      conservativeDataConservationStatus?: string | null;
      conservativeDataAssetAlterations?: string | null;
      conservativeDataDeteriorationCauses?: string | null;
      conservativeDataExaminationMethod?: string | null;
      conservativeDataInterventionProposal?: string | null;
      conservativeDataPreliminaryOperations?: string | null;
      conservativeDataIntervention?: string | null;
      conservativeDataInterventionMaterials?: string | null;
      conservativeDataTechnicalMethodologicalConsiderations?: string | null;
      conservativeDataMaintenancePreparationAssembly?: string | null;
      conservativeDataRestorationBibliography?: string | null;
      conservativeDataNotesRestorationSheet?: string | null;
      restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      restorationCompany?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        businessName?: string | null;
      } | null;
      restorationAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      restorationCost?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: {
            __typename?: "MediaNode";
            id: string;
            name?: string | null;
            file: string;
            description?: string | null;
            mediaType?: string | null;
            isMainImage?: boolean | null;
            imageType?: string | null;
            notes?: string | null;
            audioVideoFileType?: string | null;
            creationDate?: any | null;
            fileAuthor?: string | null;
            fileSize?: string | null;
            photographicEquipment?: string | null;
            matrixType?: string | null;
            isGlassPlexyAcetate?: boolean | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteRestorationMutationVariables = Exact<{
  input: DeleteRestorationInput;
}>;

export type DeleteRestorationMutation = {
  __typename?: "Mutation";
  deleteRestoration?: {
    __typename?: "DeleteRestorationPayload";
    deleteRestoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type CloseRestorationMutationVariables = Exact<{
  input: CloseRestorationInput;
}>;

export type CloseRestorationMutation = {
  __typename?: "Mutation";
  closeRestoration?: {
    __typename?: "CloseRestorationPayload";
    closeRestoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromRestorationMutationVariables = Exact<{
  input: DetachAssetFromRestorationInput;
}>;

export type DetachAssetFromRestorationMutation = {
  __typename?: "Mutation";
  detachAssetFromRestoration?: {
    __typename?: "DetachAssetFromRestorationPayload";
    restoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type GetMediaRestorationByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaRestorationByIdQuery = {
  __typename?: "Query";
  restoration?: {
    __typename?: "RestorationNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddRestorationMediaMutationVariables = Exact<{
  input: AddRestorationMediaInput;
}>;

export type AddRestorationMediaMutation = {
  __typename?: "Mutation";
  addRestorationMedia?: {
    __typename?: "AddRestorationMediaPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteRestorationMediaMutationVariables = Exact<{
  input: DeleteRestorationMediaInput;
}>;

export type DeleteRestorationMediaMutation = {
  __typename?: "Mutation";
  deleteRestorationMedia?: {
    __typename?: "DeleteRestorationMediaPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportRestorationPdfMutationVariables = Exact<{
  input: ExportRestorationPdfInput;
}>;

export type ExportRestorationPdfMutation = {
  __typename?: "Mutation";
  exportRestorationPdf?: {
    __typename?: "ExportRestorationPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type TokenAuthMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type TokenAuthMutation = {
  __typename?: "Mutation";
  tokenAuth?: {
    __typename?: "ObtainJSONWebToken";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
    algoliaUserKey?: string | null;
    user?: {
      __typename?: "UserType";
      name: string;
      currency?: string | null;
      dateJoined: string;
      email: string;
      id: string;
      isActive: boolean;
      isStaff: boolean;
      isSuperuser: boolean;
      language?: string | null;
      lastLogin?: string | null;
      phone?: string | null;
      timezone?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      username: string;
      uuid: string;
      country?: { __typename?: "Country"; code?: string | null } | null;
      userPermissions: {
        __typename?: "PermissionNodeConnection";
        edges: Array<{
          __typename?: "PermissionNodeEdge";
          node?: {
            __typename?: "PermissionNode";
            id: string;
            codename: string;
            name: string;
            contentType: { __typename?: "ContentTypeNode"; model: string; id: string };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars["String"];
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken?: {
    __typename?: "Refresh";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
  } | null;
};

export type UserSignatureFieldsFragment = {
  __typename?: "UserNode";
  usersignatureSet: {
    __typename?: "UserSignatureNodeConnection";
    edges: Array<{
      __typename?: "UserSignatureNodeEdge";
      node?: {
        __typename?: "UserSignatureNode";
        createdAt: string;
        deleted?: string | null;
        deletedByCascade: boolean;
        file?: string | null;
        id: string;
        name?: string | null;
        updatedAt: string;
        uuid: string;
        isMain?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  allUsers?: {
    __typename?: "UserNodeConnection";
    edges: Array<{
      __typename?: "UserNodeEdge";
      node?: {
        __typename?: "UserNode";
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        userType?: UsersUserUserTypeChoices | null;
        isActive: boolean;
        validFrom?: string | null;
        expiresAt?: string | null;
        country?: { __typename?: "Country"; code?: string | null } | null;
        createdBy?: {
          __typename?: "UserNode";
          id: string;
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "UserNode";
    id: string;
    lastLogin?: string | null;
    dateJoined: string;
    name: string;
    isActive: boolean;
    email: string;
    userType?: UsersUserUserTypeChoices | null;
    phone?: string | null;
    language?: string | null;
    timezone?: string | null;
    currency?: string | null;
    validFrom?: string | null;
    expiresAt?: string | null;
    country?: { __typename?: "Country"; code?: string | null } | null;
    userPermissions: {
      __typename?: "PermissionNodeConnection";
      edges: Array<{
        __typename?: "PermissionNodeEdge";
        node?: {
          __typename?: "PermissionNode";
          id: string;
          codename: string;
          name: string;
          contentType: { __typename?: "ContentTypeNode"; model: string; id: string };
        } | null;
      } | null>;
    };
    usersignatureSet: {
      __typename?: "UserSignatureNodeConnection";
      edges: Array<{
        __typename?: "UserSignatureNodeEdge";
        node?: {
          __typename?: "UserSignatureNode";
          createdAt: string;
          deleted?: string | null;
          deletedByCascade: boolean;
          file?: string | null;
          id: string;
          name?: string | null;
          updatedAt: string;
          uuid: string;
          isMain?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword?: {
    __typename?: "ChangePasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "CreateUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser?: {
    __typename?: "InviteUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "UpdateUserPayload";
    user?: {
      __typename?: "UserNode";
      id: string;
      password: string;
      lastLogin?: string | null;
      username: string;
      dateJoined: string;
      name: string;
      email: string;
      phone?: string | null;
      language?: string | null;
      timezone?: string | null;
      currency?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      isActive: boolean;
      country?: { __typename?: "Country"; code?: string | null } | null;
    } | null;
  } | null;
};

export type RequestUserResetPasswordMutationVariables = Exact<{
  input: RequestUserResetPasswordInput;
}>;

export type RequestUserResetPasswordMutation = {
  __typename?: "Mutation";
  requestUserResetPassword?: {
    __typename?: "RequestUserResetPasswordPayload";
    user?: { __typename?: "UserNode"; resetPasswordToken?: string | null } | null;
  } | null;
};

export type ResetUserPasswordMutationVariables = Exact<{
  input: ResetUserPasswordInput;
}>;

export type ResetUserPasswordMutation = {
  __typename?: "Mutation";
  resetUserPassword?: {
    __typename?: "ResetUserPasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type GetAllPermissionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPermissionQuery = {
  __typename?: "Query";
  allPermissions?: {
    __typename?: "PermissionNodeConnection";
    edges: Array<{
      __typename?: "PermissionNodeEdge";
      node?: {
        __typename?: "PermissionNode";
        codename: string;
        id: string;
        name: string;
        contentType: { __typename?: "ContentTypeNode"; model: string };
      } | null;
    } | null>;
  } | null;
};

export type UpdateUserPermissionsMutationVariables = Exact<{
  input: UpdateUserPermissionInput;
}>;

export type UpdateUserPermissionsMutation = {
  __typename?: "Mutation";
  updateUserPermissions?: {
    __typename?: "UpdateUserPermissionPayload";
    user?: {
      __typename?: "UserNode";
      userPermissions: {
        __typename?: "PermissionNodeConnection";
        edges: Array<{
          __typename?: "PermissionNodeEdge";
          node?: { __typename?: "PermissionNode"; codename: string; name: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ToggleUserIsActiveMutationVariables = Exact<{
  input: ToggleUserIsActiveInput;
}>;

export type ToggleUserIsActiveMutation = {
  __typename?: "Mutation";
  toggleUserIsactive?: {
    __typename?: "ToggleUserIsActivePayload";
    user?: { __typename?: "UserNode"; isActive: boolean } | null;
  } | null;
};

export type ToggleUserUserTypeMutationVariables = Exact<{
  input: ToggleUserUserTypeInput;
}>;

export type ToggleUserUserTypeMutation = {
  __typename?: "Mutation";
  toggleUserUserType?: {
    __typename?: "ToggleUserUserTypePayload";
    user?: { __typename?: "UserNode"; userType?: UsersUserUserTypeChoices | null } | null;
  } | null;
};

export type RenewUserMutationVariables = Exact<{
  input: RenewUserInput;
}>;

export type RenewUserMutation = {
  __typename?: "Mutation";
  renewUser?: {
    __typename?: "RenewUserPayload";
    user?: { __typename?: "UserNode"; validFrom?: string | null; expiresAt?: string | null } | null;
  } | null;
};

export type AddUserSignatureMutationVariables = Exact<{
  input: AddUserSignatureInput;
}>;

export type AddUserSignatureMutation = {
  __typename?: "Mutation";
  addUserSignature?: {
    __typename?: "AddUserSignaturePayload";
    success?: boolean | null;
    userSignature?: { __typename?: "UserSignatureType"; file: string } | null;
  } | null;
};

export type DeleteUserSignatureMutationVariables = Exact<{
  input: DeleteUserSignatureInput;
}>;

export type DeleteUserSignatureMutation = {
  __typename?: "Mutation";
  deleteUserSignature?: {
    __typename?: "DeleteUserSignaturePayload";
    userSignature?: { __typename?: "UserSignatureNode"; id: string } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: {
    __typename?: "DeleteUserPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type SetMainSignatureMutationVariables = Exact<{
  input: SetMainSignatureInput;
}>;

export type SetMainSignatureMutation = {
  __typename?: "Mutation";
  setMainSignature?: {
    __typename?: "SetMainSignaturePayload";
    userSignature?: { __typename?: "UserSignatureType"; id: string } | null;
  } | null;
};

export type AddUserObjectPermissionMutationVariables = Exact<{
  input: AddUserObjectPermissionInput;
}>;

export type AddUserObjectPermissionMutation = {
  __typename?: "Mutation";
  addUserObjectPermission?: {
    __typename?: "AddUserObjectPermissionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type AddUserObjectPermissionFromCollectionMutationVariables = Exact<{
  input: AddUserObjectPermissionFromCollectionInput;
}>;

export type AddUserObjectPermissionFromCollectionMutation = {
  __typename?: "Mutation";
  addUserObjectPermissionFromCollection?: {
    __typename?: "AddUserObjectPermissionFromCollectionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type RemoveUserObjectPermissionMutationVariables = Exact<{
  input: RemoveUserObjectPermissionInput;
}>;

export type RemoveUserObjectPermissionMutation = {
  __typename?: "Mutation";
  removeUserObjectPermission?: {
    __typename?: "RemoveUserObjectPermissionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export const AppraisalFindingFieldsFragmentDoc = gql`
  fragment AppraisalFindingFields on AppraisalNode {
    id
    appraisalStatus
    appraisalEvaluator {
      id
      fullName
    }
    appraisalAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    appraisalDate
  }
`;
export const AppraisalAnagraphicFieldsFragmentDoc = gql`
  fragment AppraisalAnagraphicFields on AppraisalNode {
    id
    appraisalStatus
    appraisalEvaluator {
      id
      fullName
    }
    appraisalAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    appraisalConservationStatus
    appraisalMotivation
    appraisalLocation
    appraisalDate
    valueInformationAuthorCoefficient
    valueInformationInfo
    valueInformationQuotesSimilarAssets
    valueCarryingValue {
      amount
      currency {
        code
      }
    }
    valueEstimatedValue {
      amount
      currency {
        code
      }
    }
    valueInformationNote
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on AssetNode {
    id
    deleted
    deletedByCascade
    createdAt
    updatedAt
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    uuid
    netWeightUnit
    grossWeightUnit
    netLengthUnit
    grossLengthUnit
    netHeight
    grossHeight
    netWidth
    grossWidth
    netDepth
    grossDepth
    netDiameter
    grossDiameter
    netWeight
    grossWeight
    netDimensionsAcquisition
    grossDimensionsAcquisition
    dimensionsNotes
    audioVideoLengthUnit
    audioVideoLength
    fileSizeUnit
    fileSize
    resolutionUnit
    resolution
    optimalLightLevelIntensity
    lightUnit
    optimalTemperature
    temperatureUnit
    optimalHumidity
    enviromentalConditionsNotes
    isNotifiedCulturalProperty
    notifiedPropertyNote
    legalStatus
    copyrightAndPropertyRestrictions
    hasBarriers
    barriersDetails
    handlingRequirements
    injuryRisk
    injuryRiskDescription
    mouldingSupportBase
    hardwareToolsInstallation
    conservation
    lenderRequests
    settingNotes
    infoNotes
    isArtistProof
    artistProofNotes
    isFirstDraft
    firstDraftNotes
    isEdition
    editionNotes
    isMaster
    masterLocation
    isArtMultiple
    multipleNumber
    isSignedObject
    signedObjectNotes
    isTitledObject
    titledObjectNotes
    isDatedObject
    datedObjectNotes
    hasLabelsStamps
    labelsStampsNotes
    hasOtherAutographed
    otherAutographedNotes
    relevantAwards
    mechanism
    carat
    isDynamic
    dynamicNotes
    hasHumanAnimalPresence
    humanAnimalPresenceNotes
    isAudio
    isVideo
    hasLoop
    coloring
    coinMintIssuer
    coinPlacement
    coinPeriod
    coinPlacementNotes
    coinIssueReason
    coinCirculationPeriod
    coinComposition
    coinIdentificationCode
    classificationUsa
    classificationIta
    classificationEng
    classificationFra
    classificationSpa
    classificationGer
    classificationPhilatelic
    rarityDegree
    rarityDegreePhilatelic
    conservationState
    rarityDegreeNotes
    variants
    nominalValue
    fiscalValue
    currentValue
    currency
    score
    circulation
    manufactoringTechniques
    mintingLastDate
    mintingLastDateNotes
    reminting
    printingLastDate
    printingLastDateNotes
    reprinting
    stateOfConservation
    isComposed
    numberOfElements
    surfaceCharacteristics
    hasBase
    baseDetails
    hasWoodFrame
    woodFrameDetails
    hasFrame
    hasOriginalFrame
    frameMaterial
    isValuableFrame
    frameManufacturer
    frameDescription
    compositionDescription
    usage
    finishing
    hasContainer
    containerDetails
    replacedParts
    methodOfUse
    relatedPhysicalWork
    bookContainer
    dustJacket
    frontCover
    rearCover
    topEdge
    foreEdge
    footEdge
    bookbinding
    interiorDecorations
    externalDecorations
    flyleaf
    glueing
    headband
    headcap
    corner
    square
    slots
    spine
    clips
    raisedBands
    label
    clothCovering
    distribution
    edge
    orientation
    shape
    rim
    rotationAxes
    legend
    fineness
    coinTitle
    mintMadeErrors
    signings
    relief
    watermark
    holographicStripe
    securityThread
    printingErrors
    conservationMethod
    color
    postageMark
    rubber
    stampPrintingTechnique
    stampRealization
    stampIndentationType
    stampCenteringAndMargins
    stampFormat
    discoveryPlace
    discoverySite
    discoveryDate
    discoveryNotes
    discoveryAuthor
    catalogingAuthor
    objectSetupAuthor
    originNotes
    bibliographyNotes
    carryingValueOrigin
    carryingValueAcquisition
    carryingValueNotes
    carryingValueLastAcquisitionDate
    carryingValueAmountCurrency
    carryingValueAmount {
      amount
      currency {
        code
      }
    }
    carryingValueFileTitle
    carryingValueFile
    title
    technique
    genericDimensions
    actualDateAndPeriod
    creationDate
    creationDateNotes
    category
    subcategory
    authorEntity {
      id
      fullName
    }
    printer {
      id
      fullName
    }
    videomaker {
      id
      fullName
    }
    manufacturer {
      id
      fullName
    }
    producer {
      id
      fullName
    }
    otherModel
    banknotePrinter
    paperType
    coverType
    printingType
    productionMode
    placement
    sku
    series
    seriesDetails
    commonName
    scientificName
    material
    internationalCode
    taxonomyDomain
    taxonomyKingdom
    taxonomyPhylum
    taxonomyClass
    taxonomyOrder
    taxonomyFamily
    taxonomyTribe
    taxonomyGenus
    taxonomyNotes
    hasPeriod
    hasYear
    hasFullDatetime
    datingType
    creationPeriod
    isDateInterval
    dateStartDate
    dateEndDate
    isDateStartDateBc
    isDateEndDateBc
    isDateApprox
    isYearInterval
    yearStartDate
    yearEndDate
    isYearStartDateBc
    isYearEndDateBc
    isYearApprox
    isCenturyInterval
    centuryStartDate
    centuryEndDate
    isCenturyStartDateBc
    isCenturyEndDateBc
    isCenturyApprox
    centuryStartDateWindow
    centuryEndDateWindow
    nft
    nftNotes
    description
    inventoryNumber
    archiveNumber
    catalogNumber
    collection {
      edges {
        node {
          id
          name
        }
      }
    }
    exhibition {
      edges {
        node {
          id
          name
        }
      }
    }
    documents {
      edges {
        node {
          id
          title
          description
          documentType
          coaType
          issuer
          recipient
          isClassified
          releaseDate
          expirationDate
          notes
          archiveNumber
          fineArtsIssueType
          isTemporaryExport
          isTemporaryImport
          isCriticalCatalog
          editor
          curator
          subscriptionDate
          contractSubscriber
          publishingDate
          otherDocumentType
          attachments {
            edges {
              node {
                id
                name
                file
              }
            }
          }
          createdBy {
            name
          }
        }
      }
    }
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
          createdAt
          actualImageDimensions
        }
      }
    }
    criticalCatalogue
    technique
    validated
    physicalFeaturesElements {
      edges {
        node {
          id
          name
          materials
          description
          notes
        }
      }
    }
    presentValues {
      edges {
        node {
          id
          author {
            id
            fullName
          }
          estimateDate
          estimateReason
          notes
          file
          fileTitle
          amount {
            amount
            currency {
              code
            }
          }
        }
      }
    }
    policyAsset {
      edges {
        node {
          id
        }
      }
    }
    mainImage
    __typename
  }
`;
export const AssetFieldsCreationReviewFragmentDoc = gql`
  fragment AssetFieldsCreationReview on AssetNode {
    id
    title
    authorEntity {
      id
      fullName
    }
    inventoryNumber
    technique
    creationDate
    genericDimensions
    actualDateAndPeriod
    category
    mainImage
    __typename
  }
`;
export const CollectionAnagraphicFieldsFragmentDoc = gql`
  fragment CollectionAnagraphicFields on CollectionNode {
    id
    name
    description
    notes
    image
    totalAssetsNumber
    validatedAssetsNumber
    createdFrom
    createdAt
    updatedAt
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          category
          genericDimensions
          creationDate
          mainImage
          actualDateAndPeriod
        }
      }
    }
  }
`;
export const CollectionFindingFieldsFragmentDoc = gql`
  fragment CollectionFindingFields on CollectionNode {
    id
    name
    description
    notes
  }
`;
export const ConditionStatusReportFindingFieldsFragmentDoc = gql`
  fragment ConditionStatusReportFindingFields on ConditionStatusReportNode {
    id
    reportStatus
    reportDate
    reportType
    reportAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    reportMotivation
    reportMotivationDescription
    reportExaminer {
      id
      fullName
    }
  }
`;
export const ConditionStatusReportAnagraphicFieldsFragmentDoc = gql`
  fragment ConditionStatusReportAnagraphicFields on ConditionStatusReportNode {
    id
    reportStatus
    environmentalConditionsAbnormalHumidityEvent
    environmentalConditionsAbnormalLightEvent
    environmentalConditionsAbnormalTemperatureEvent
    environmentalConditionsLightUnit
    environmentalConditionsOptimalHumidity
    environmentalConditionsOptimalLightLevelIntensity
    environmentalConditionsOptimalTemperature
    environmentalConditionsTemperatureUnit
    exhibitionCurator {
      id
      fullName
    }
    exhibitionEventFrom
    exhibitionEventTo
    exhibitionHostEntity {
      id
      fullName
    }
    exhibitionLender {
      id
      fullName
    }
    exhibitionLoanFrom
    exhibitionLoanTo
    exhibitionLocation
    exhibitionName
    exhibitionNotes
    exhibitionPlanner {
      id
      fullName
    }
    exhibitionPermanent
    exhibitionTitle
    logisticsAirConditioningTransportConditions
    logisticsCarrier {
      id
      fullName
    }
    logisticsDeliveryAddress
    logisticsDeliveryCity
    logisticsDeliveryCountry
    logisticsDeliveryDate
    logisticsDeliveryState
    logisticsDeliveryZip
    logisticsId
    logisticsNumberOfPackages
    logisticsPickupAddress
    logisticsPickupCity
    logisticsPickupCountry
    logisticsPickupDate
    logisticsPickupState
    logisticsPickupZip
    logisticsRecipient {
      id
      fullName
    }
    logisticsSender {
      id
      fullName
    }
    reportCompany {
      id
      fullName
    }
    reportDate
    reportDetailsAssetCondition
    reportDetailsAssetDamageDescription
    reportDetailsExamConditions
    reportDetailsExamConditionsNote
    reportDetailsExamLocation
    reportDetailsExhaminationTimeTechnicalDescription
    reportDetailsPackagingGeneralConditions
    reportDetailsExaminedPackages
    reportDetailsPackagingStatusDescription
    reportDetailsProtectionsSupportsCondition
    reportDetailsProtectionsSupportsDamageDescription
    reportExaminationType
    reportExaminer {
      id
      fullName
    }
    reportMotivation
    reportMotivationDescription
    reportType
    reportAsset {
      id
      title
      hasBarriers
      injuryRisk
      injuryRiskDescription
      conservation
      lenderRequests
      optimalLightLevelIntensity
      lightUnit
      optimalTemperature
      temperatureUnit
      optimalHumidity
      hasBarriers
      handlingRequirements
      injuryRisk
      injuryRiskDescription
      conservation
      lenderRequests
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    settingsConservation
    settingsExaminerComments
    settingsHandlingRequirements
    settingsHasBarriers
    settingsInjuryRisk
    settingsLenderRequests
    settingsNotes
    valueEstimatedValue {
      amount
      currency {
        code
      }
    }
    valueEstimatedValueExaminationTime {
      amount
      currency {
        code
      }
    }
    valuePercentageDepreciation
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
  }
`;
export const EventLogFindingFieldsFragmentDoc = gql`
  fragment EventLogFindingFields on EventLogNode {
    id
    eventType
    hideOwner
    commerciallyAvailable
    sold
    collectionPublicName
    locationDescription
    startDate
    endDate
    owner {
      id
      fullName
    }
  }
`;
export const EventLogAnagraphicFieldsFragmentDoc = gql`
  fragment EventLogAnagraphicFields on EventLogNode {
    id
    eventType
    hideOwner
    commerciallyAvailable
    sold
    collectionPublicName
    locationDescription
    startDate
    endDate
    note
    acquisitionType
    actionType
    commission
    auctionDescription
    lotNumber
    sellingLocation
    value {
      amount
      currency {
        code
      }
    }
    minimumPrice {
      amount
      currency {
        code
      }
    }
    maximumPrice {
      amount
      currency {
        code
      }
    }
    reservePrice {
      amount
      currency {
        code
      }
    }
    auctionRights {
      amount
      currency {
        code
      }
    }
    externalId
    externalEntity
    owner {
      id
      fullName
    }
    origin {
      id
      fullName
    }
    host {
      id
      fullName
    }
    broker {
      id
      fullName
    }
    newOwner {
      id
      fullName
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          category
          genericDimensions
          creationDate
          mainImage
          actualDateAndPeriod
        }
      }
    }
    document
  }
`;
export const ExhibitionFindingFieldsFragmentDoc = gql`
  fragment ExhibitionFindingFields on ExhibitionNode {
    id
    name
    description
    eventFrom
    eventTo
    notes
    permanent
  }
`;
export const ExhibitionAnagraphicFieldsFragmentDoc = gql`
  fragment ExhibitionAnagraphicFields on ExhibitionNode {
    id
    name
    description
    eventFrom
    eventTo
    notes
    image
    permanent
    totalAssetsNumber
    createdAt
    updatedAt
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    curator {
      id
      fullName
    }
    organizer {
      id
      fullName
    }
    hostEntity {
      id
      fullName
    }
    eventLocationEntity {
      id
      name
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          category
          genericDimensions
          creationDate
          mainImage
          actualDateAndPeriod
        }
      }
    }
  }
`;
export const PolicyAssetFieldsFragmentDoc = gql`
  fragment policyAssetFields on PolicyAssetNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    evaluationType
    exemption
    id
    lender {
      id
      fullName
    }
    pickupAddress {
      raw
    }
    deliveryAddress {
      raw
    }
    lenderAddress {
      raw
      locality
      state
      country
      streetNumber
      route
    }
    shipper {
      id
      fullName
    }
    insuredValue {
      currency {
        code
      }
      amount
    }
  }
`;
export const PolicyCoreFieldsFragmentDoc = gql`
  fragment policyCoreFields on PolicyNode {
    id
    offerCode
    policyCode
    policyStatus
    contractingParty {
      fullName
    }
    insuranceCompany {
      fullName
    }
    broker {
      fullName
    }
    issuedAt
    expiresAt
    policyType
  }
`;
export const PolicyAssetOnPolicyFragmentDoc = gql`
  fragment policyAssetOnPolicy on PolicyNode {
    policyAssets {
      edges {
        node {
          id
        }
      }
    }
  }
`;
export const PolicyMoneyFieldsFragmentDoc = gql`
  fragment policyMoneyFields on PolicyNode {
    totalInsuredPremium {
      currency {
        code
      }
      amount
    }
    totalInsuredValue {
      currency {
        code
      }
      amount
    }
    evaluationType
  }
`;
export const PolicyDriverFieldsFragmentDoc = gql`
  fragment policyDriverFields on PolicyNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    exemption
    hasVariableBasket
  }
`;
export const RegistryInfoFieldsFragmentDoc = gql`
  fragment RegistryInfoFields on RegistryNode {
    createdAt
    createdBy {
      name
      email
    }
    updatedAt
    updatedBy {
      name
      email
    }
  }
`;
export const RegistryAnagraphicFieldsFragmentDoc = gql`
  fragment RegistryAnagraphicFields on RegistryNode {
    id
    isCompany
    fullName
    businessName
    alias
    role
    displayName
    webSite
    note
    countryCode
    taxId
    invoiceAddress {
      raw
      formatted
    }
    printName
    printCode
    phone
    mobilePhone
    email
    isAuthor
    birthDate
    birthPlace
    deathDate
    deathPlace
    activityPeriod
    activityPlace
    artisticMovement
    foundation
    categories
    authorType
  }
`;
export const RegistryFindingFieldsFragmentDoc = gql`
  fragment RegistryFindingFields on RegistryNode {
    id
    categories
    fullName
    businessName
    email
    phone
    mobilePhone
    officesCount
    createdBy {
      name
    }
  }
`;
export const OfficeAnagraphicFieldsFragmentDoc = gql`
  fragment OfficeAnagraphicFields on OfficeNode {
    id
    name
  }
`;
export const CompanyContactAnagraphicFieldsFragmentDoc = gql`
  fragment CompanyContactAnagraphicFields on CompanyContactNode {
    id
    main
    companyContact {
      id
      fullName
      alias
      role
      phone
      mobilePhone
      email
    }
    relatedCompany {
      id
    }
  }
`;
export const RestorationFindingFieldsFragmentDoc = gql`
  fragment RestorationFindingFields on RestorationNode {
    id
    restorationStatus
    restorationDirector {
      id
      fullName
    }
    restorationAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    createdBy {
      name
    }
    restorationDocumentDate
    restorationStartDate
    restorationEndDate
  }
`;
export const RestorationAnagraphicFieldsFragmentDoc = gql`
  fragment RestorationAnagraphicFields on RestorationNode {
    id
    restorationStatus
    restorationDirector {
      id
      fullName
    }
    restorationCompany {
      id
      fullName
      businessName
    }
    restorationAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    restorationRestoredBy
    restorationCost {
      amount
      currency {
        code
      }
    }
    restorationInventoryNumber
    restorationStartDate
    restorationEndDate
    restorationLocation
    restorationCollocationFeatures
    restorationDocumentDate
    conservativeDataHistoryInterventions
    conservativeDataConservationStatus
    conservativeDataAssetAlterations
    conservativeDataDeteriorationCauses
    conservativeDataExaminationMethod
    conservativeDataInterventionProposal
    conservativeDataPreliminaryOperations
    conservativeDataIntervention
    conservativeDataInterventionMaterials
    conservativeDataTechnicalMethodologicalConsiderations
    conservativeDataMaintenancePreparationAssembly
    conservativeDataRestorationBibliography
    conservativeDataNotesRestorationSheet
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
  }
`;
export const UserSignatureFieldsFragmentDoc = gql`
  fragment UserSignatureFields on UserNode {
    usersignatureSet {
      edges {
        node {
          createdAt
          deleted
          deletedByCascade
          file
          id
          name
          updatedAt
          uuid
          isMain
        }
      }
    }
  }
`;
export const GetAllAppraisalsDocument = gql`
  query GetAllAppraisals {
    allAppraisals {
      edges {
        node {
          ...AppraisalFindingFields
          mediaNumber
          createdAt
          actualMediaStatus
          valueEstimatedValue {
            amount
            currency {
              code
            }
          }
          createdBy {
            name
          }
        }
      }
    }
  }
  ${AppraisalFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllAppraisalsQuery__
 *
 * To run a query within a React component, call `useGetAllAppraisalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAppraisalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAppraisalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAppraisalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>(
    GetAllAppraisalsDocument,
    options
  );
}
export function useGetAllAppraisalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>(
    GetAllAppraisalsDocument,
    options
  );
}
export type GetAllAppraisalsQueryHookResult = ReturnType<typeof useGetAllAppraisalsQuery>;
export type GetAllAppraisalsLazyQueryHookResult = ReturnType<typeof useGetAllAppraisalsLazyQuery>;
export type GetAllAppraisalsQueryResult = Apollo.QueryResult<
  GetAllAppraisalsQuery,
  GetAllAppraisalsQueryVariables
>;
export const GetAppraisalByIdDocument = gql`
  query GetAppraisalById($id: ID!) {
    appraisal(id: $id) {
      ...AppraisalAnagraphicFields
      mediaNumber
    }
  }
  ${AppraisalAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetAppraisalByIdQuery__
 *
 * To run a query within a React component, call `useGetAppraisalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppraisalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>(
    GetAppraisalByIdDocument,
    options
  );
}
export function useGetAppraisalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>(
    GetAppraisalByIdDocument,
    options
  );
}
export type GetAppraisalByIdQueryHookResult = ReturnType<typeof useGetAppraisalByIdQuery>;
export type GetAppraisalByIdLazyQueryHookResult = ReturnType<typeof useGetAppraisalByIdLazyQuery>;
export type GetAppraisalByIdQueryResult = Apollo.QueryResult<
  GetAppraisalByIdQuery,
  GetAppraisalByIdQueryVariables
>;
export const CreateAppraisalDocument = gql`
  mutation CreateAppraisal($input: CreateAppraisalInput!) {
    createAppraisal(input: $input) {
      appraisal {
        id
        appraisalStatus
        appraisalEvaluator {
          id
          fullName
        }
        appraisalAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        appraisalConservationStatus
        appraisalMotivation
        appraisalLocation
        appraisalDate
        valueInformationAuthorCoefficient
        valueInformationInfo
        valueInformationQuotesSimilarAssets
        valueCarryingValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueInformationNote
      }
    }
  }
`;
export type CreateAppraisalMutationFn = Apollo.MutationFunction<
  CreateAppraisalMutation,
  CreateAppraisalMutationVariables
>;

/**
 * __useCreateAppraisalMutation__
 *
 * To run a mutation, you first call `useCreateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppraisalMutation, { data, loading, error }] = useCreateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppraisalMutation,
    CreateAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppraisalMutation, CreateAppraisalMutationVariables>(
    CreateAppraisalDocument,
    options
  );
}
export type CreateAppraisalMutationHookResult = ReturnType<typeof useCreateAppraisalMutation>;
export type CreateAppraisalMutationResult = Apollo.MutationResult<CreateAppraisalMutation>;
export type CreateAppraisalMutationOptions = Apollo.BaseMutationOptions<
  CreateAppraisalMutation,
  CreateAppraisalMutationVariables
>;
export const UpdateAppraisalDocument = gql`
  mutation UpdateAppraisal($input: UpdateAppraisalInput!) {
    updateAppraisal(input: $input) {
      appraisal {
        id
        appraisalStatus
        appraisalEvaluator {
          id
          fullName
        }
        appraisalAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        appraisalConservationStatus
        appraisalMotivation
        appraisalLocation
        appraisalDate
        valueInformationAuthorCoefficient
        valueInformationInfo
        valueInformationQuotesSimilarAssets
        valueCarryingValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueInformationNote
      }
    }
  }
`;
export type UpdateAppraisalMutationFn = Apollo.MutationFunction<
  UpdateAppraisalMutation,
  UpdateAppraisalMutationVariables
>;

/**
 * __useUpdateAppraisalMutation__
 *
 * To run a mutation, you first call `useUpdateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppraisalMutation, { data, loading, error }] = useUpdateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppraisalMutation,
    UpdateAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>(
    UpdateAppraisalDocument,
    options
  );
}
export type UpdateAppraisalMutationHookResult = ReturnType<typeof useUpdateAppraisalMutation>;
export type UpdateAppraisalMutationResult = Apollo.MutationResult<UpdateAppraisalMutation>;
export type UpdateAppraisalMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppraisalMutation,
  UpdateAppraisalMutationVariables
>;
export const DeleteAppraisalDocument = gql`
  mutation DeleteAppraisal($input: DeleteAppraisalInput!) {
    deleteAppraisal(input: $input) {
      deleteAppraisal {
        id
      }
    }
  }
`;
export type DeleteAppraisalMutationFn = Apollo.MutationFunction<
  DeleteAppraisalMutation,
  DeleteAppraisalMutationVariables
>;

/**
 * __useDeleteAppraisalMutation__
 *
 * To run a mutation, you first call `useDeleteAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppraisalMutation, { data, loading, error }] = useDeleteAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppraisalMutation,
    DeleteAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppraisalMutation, DeleteAppraisalMutationVariables>(
    DeleteAppraisalDocument,
    options
  );
}
export type DeleteAppraisalMutationHookResult = ReturnType<typeof useDeleteAppraisalMutation>;
export type DeleteAppraisalMutationResult = Apollo.MutationResult<DeleteAppraisalMutation>;
export type DeleteAppraisalMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppraisalMutation,
  DeleteAppraisalMutationVariables
>;
export const CloseAppraisalDocument = gql`
  mutation CloseAppraisal($input: CloseAppraisalInput!) {
    closeAppraisal(input: $input) {
      closeAppraisal {
        id
      }
    }
  }
`;
export type CloseAppraisalMutationFn = Apollo.MutationFunction<
  CloseAppraisalMutation,
  CloseAppraisalMutationVariables
>;

/**
 * __useCloseAppraisalMutation__
 *
 * To run a mutation, you first call `useCloseAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAppraisalMutation, { data, loading, error }] = useCloseAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseAppraisalMutation, CloseAppraisalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseAppraisalMutation, CloseAppraisalMutationVariables>(
    CloseAppraisalDocument,
    options
  );
}
export type CloseAppraisalMutationHookResult = ReturnType<typeof useCloseAppraisalMutation>;
export type CloseAppraisalMutationResult = Apollo.MutationResult<CloseAppraisalMutation>;
export type CloseAppraisalMutationOptions = Apollo.BaseMutationOptions<
  CloseAppraisalMutation,
  CloseAppraisalMutationVariables
>;
export const DetachAssetFromAppraisalDocument = gql`
  mutation DetachAssetFromAppraisal($input: DetachAssetFromAppraisalInput!) {
    detachAssetFromAppraisal(input: $input) {
      appraisal {
        id
      }
    }
  }
`;
export type DetachAssetFromAppraisalMutationFn = Apollo.MutationFunction<
  DetachAssetFromAppraisalMutation,
  DetachAssetFromAppraisalMutationVariables
>;

/**
 * __useDetachAssetFromAppraisalMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromAppraisalMutation, { data, loading, error }] = useDetachAssetFromAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromAppraisalMutation,
    DetachAssetFromAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromAppraisalMutation,
    DetachAssetFromAppraisalMutationVariables
  >(DetachAssetFromAppraisalDocument, options);
}
export type DetachAssetFromAppraisalMutationHookResult = ReturnType<
  typeof useDetachAssetFromAppraisalMutation
>;
export type DetachAssetFromAppraisalMutationResult =
  Apollo.MutationResult<DetachAssetFromAppraisalMutation>;
export type DetachAssetFromAppraisalMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromAppraisalMutation,
  DetachAssetFromAppraisalMutationVariables
>;
export const GetMediaAppraisalByIdDocument = gql`
  query GetMediaAppraisalById($id: ID!) {
    appraisal(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaAppraisalByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaAppraisalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaAppraisalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaAppraisalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaAppraisalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaAppraisalByIdQuery,
    GetMediaAppraisalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaAppraisalByIdQuery, GetMediaAppraisalByIdQueryVariables>(
    GetMediaAppraisalByIdDocument,
    options
  );
}
export function useGetMediaAppraisalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaAppraisalByIdQuery,
    GetMediaAppraisalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaAppraisalByIdQuery, GetMediaAppraisalByIdQueryVariables>(
    GetMediaAppraisalByIdDocument,
    options
  );
}
export type GetMediaAppraisalByIdQueryHookResult = ReturnType<typeof useGetMediaAppraisalByIdQuery>;
export type GetMediaAppraisalByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaAppraisalByIdLazyQuery
>;
export type GetMediaAppraisalByIdQueryResult = Apollo.QueryResult<
  GetMediaAppraisalByIdQuery,
  GetMediaAppraisalByIdQueryVariables
>;
export const AddAppraisalMediaDocument = gql`
  mutation AddAppraisalMedia($input: AddAppraisalMediaInput!) {
    addAppraisalMedia(input: $input) {
      appraisal {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddAppraisalMediaMutationFn = Apollo.MutationFunction<
  AddAppraisalMediaMutation,
  AddAppraisalMediaMutationVariables
>;

/**
 * __useAddAppraisalMediaMutation__
 *
 * To run a mutation, you first call `useAddAppraisalMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppraisalMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppraisalMediaMutation, { data, loading, error }] = useAddAppraisalMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAppraisalMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAppraisalMediaMutation,
    AddAppraisalMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAppraisalMediaMutation, AddAppraisalMediaMutationVariables>(
    AddAppraisalMediaDocument,
    options
  );
}
export type AddAppraisalMediaMutationHookResult = ReturnType<typeof useAddAppraisalMediaMutation>;
export type AddAppraisalMediaMutationResult = Apollo.MutationResult<AddAppraisalMediaMutation>;
export type AddAppraisalMediaMutationOptions = Apollo.BaseMutationOptions<
  AddAppraisalMediaMutation,
  AddAppraisalMediaMutationVariables
>;
export const DeleteAppraisalMediaDocument = gql`
  mutation DeleteAppraisalMedia($input: DeleteAppraisalMediaInput!) {
    deleteAppraisalMedia(input: $input) {
      appraisal {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteAppraisalMediaMutationFn = Apollo.MutationFunction<
  DeleteAppraisalMediaMutation,
  DeleteAppraisalMediaMutationVariables
>;

/**
 * __useDeleteAppraisalMediaMutation__
 *
 * To run a mutation, you first call `useDeleteAppraisalMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppraisalMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppraisalMediaMutation, { data, loading, error }] = useDeleteAppraisalMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppraisalMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppraisalMediaMutation,
    DeleteAppraisalMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppraisalMediaMutation, DeleteAppraisalMediaMutationVariables>(
    DeleteAppraisalMediaDocument,
    options
  );
}
export type DeleteAppraisalMediaMutationHookResult = ReturnType<
  typeof useDeleteAppraisalMediaMutation
>;
export type DeleteAppraisalMediaMutationResult =
  Apollo.MutationResult<DeleteAppraisalMediaMutation>;
export type DeleteAppraisalMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppraisalMediaMutation,
  DeleteAppraisalMediaMutationVariables
>;
export const ExportAppraisalPdfDocument = gql`
  mutation ExportAppraisalPDF($input: ExportAppraisalPDFInput!) {
    exportAppraisalPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportAppraisalPdfMutationFn = Apollo.MutationFunction<
  ExportAppraisalPdfMutation,
  ExportAppraisalPdfMutationVariables
>;

/**
 * __useExportAppraisalPdfMutation__
 *
 * To run a mutation, you first call `useExportAppraisalPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAppraisalPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAppraisalPdfMutation, { data, loading, error }] = useExportAppraisalPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAppraisalPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportAppraisalPdfMutation,
    ExportAppraisalPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportAppraisalPdfMutation, ExportAppraisalPdfMutationVariables>(
    ExportAppraisalPdfDocument,
    options
  );
}
export type ExportAppraisalPdfMutationHookResult = ReturnType<typeof useExportAppraisalPdfMutation>;
export type ExportAppraisalPdfMutationResult = Apollo.MutationResult<ExportAppraisalPdfMutation>;
export type ExportAppraisalPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportAppraisalPdfMutation,
  ExportAppraisalPdfMutationVariables
>;
export const GetAllAssetsByTitleDocument = gql`
  query GetAllAssetsByTitle($title_Contains: String) {
    allAssets(title_Contains: $title_Contains, orderBy: "-created_at") {
      edges {
        node {
          id
          title
          author
          creationDate
          actualDateAndPeriod
          genericDimensions
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsByTitleQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsByTitleQuery({
 *   variables: {
 *      title_Contains: // value for 'title_Contains'
 *   },
 * });
 */
export function useGetAllAssetsByTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export function useGetAllAssetsByTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsByTitleQuery,
    GetAllAssetsByTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export type GetAllAssetsByTitleQueryHookResult = ReturnType<typeof useGetAllAssetsByTitleQuery>;
export type GetAllAssetsByTitleLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsByTitleLazyQuery
>;
export type GetAllAssetsByTitleQueryResult = Apollo.QueryResult<
  GetAllAssetsByTitleQuery,
  GetAllAssetsByTitleQueryVariables
>;
export const GetAssetByIdDocument = gql`
  query GetAssetById($id: ID!) {
    asset(id: $id) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useGetAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export function useGetAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export type GetAssetByIdQueryHookResult = ReturnType<typeof useGetAssetByIdQuery>;
export type GetAssetByIdLazyQueryHookResult = ReturnType<typeof useGetAssetByIdLazyQuery>;
export type GetAssetByIdQueryResult = Apollo.QueryResult<
  GetAssetByIdQuery,
  GetAssetByIdQueryVariables
>;
export const GetAssetByIdForReviewDocument = gql`
  query GetAssetByIdForReview($id: ID!) {
    asset(id: $id) {
      ...AssetFieldsCreationReview
    }
  }
  ${AssetFieldsCreationReviewFragmentDoc}
`;

/**
 * __useGetAssetByIdForReviewQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdForReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdForReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetByIdForReviewQuery,
    GetAssetByIdForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdForReviewQuery, GetAssetByIdForReviewQueryVariables>(
    GetAssetByIdForReviewDocument,
    options
  );
}
export function useGetAssetByIdForReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetByIdForReviewQuery,
    GetAssetByIdForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdForReviewQuery, GetAssetByIdForReviewQueryVariables>(
    GetAssetByIdForReviewDocument,
    options
  );
}
export type GetAssetByIdForReviewQueryHookResult = ReturnType<typeof useGetAssetByIdForReviewQuery>;
export type GetAssetByIdForReviewLazyQueryHookResult = ReturnType<
  typeof useGetAssetByIdForReviewLazyQuery
>;
export type GetAssetByIdForReviewQueryResult = Apollo.QueryResult<
  GetAssetByIdForReviewQuery,
  GetAssetByIdForReviewQueryVariables
>;
export const GetMediaAssetByIdDocument = gql`
  query GetMediaAssetById($id: ID!) {
    asset(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>(
    GetMediaAssetByIdDocument,
    options
  );
}
export function useGetMediaAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>(
    GetMediaAssetByIdDocument,
    options
  );
}
export type GetMediaAssetByIdQueryHookResult = ReturnType<typeof useGetMediaAssetByIdQuery>;
export type GetMediaAssetByIdLazyQueryHookResult = ReturnType<typeof useGetMediaAssetByIdLazyQuery>;
export type GetMediaAssetByIdQueryResult = Apollo.QueryResult<
  GetMediaAssetByIdQuery,
  GetMediaAssetByIdQueryVariables
>;
export const GetAllAssetsDocument = gql`
  query GetAllAssets {
    allAssets(orderBy: "-created_at") {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
            userType
          }
          authorEntity {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export function useGetAllAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export type GetAllAssetsQueryHookResult = ReturnType<typeof useGetAllAssetsQuery>;
export type GetAllAssetsLazyQueryHookResult = ReturnType<typeof useGetAllAssetsLazyQuery>;
export type GetAllAssetsQueryResult = Apollo.QueryResult<
  GetAllAssetsQuery,
  GetAllAssetsQueryVariables
>;
export const GetAllAssetsForUserDocument = gql`
  query GetAllAssetsForUser($userId: ID!) {
    allAssetsForUser(orderBy: "-created_at", userId: $userId) {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
          }
          authorEntity {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllAssetsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>(
    GetAllAssetsForUserDocument,
    options
  );
}
export function useGetAllAssetsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsForUserQuery,
    GetAllAssetsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>(
    GetAllAssetsForUserDocument,
    options
  );
}
export type GetAllAssetsForUserQueryHookResult = ReturnType<typeof useGetAllAssetsForUserQuery>;
export type GetAllAssetsForUserLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsForUserLazyQuery
>;
export type GetAllAssetsForUserQueryResult = Apollo.QueryResult<
  GetAllAssetsForUserQuery,
  GetAllAssetsForUserQueryVariables
>;
export const GetAllAssetExceptUserDocument = gql`
  query GetAllAssetExceptUser($userId: ID!) {
    notUserAssets(orderBy: "-created_at", userId: $userId) {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
          }
          authorEntity {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetExceptUserQuery__
 *
 * To run a query within a React component, call `useGetAllAssetExceptUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetExceptUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetExceptUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllAssetExceptUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssetExceptUserQuery,
    GetAllAssetExceptUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetExceptUserQuery, GetAllAssetExceptUserQueryVariables>(
    GetAllAssetExceptUserDocument,
    options
  );
}
export function useGetAllAssetExceptUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetExceptUserQuery,
    GetAllAssetExceptUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetExceptUserQuery, GetAllAssetExceptUserQueryVariables>(
    GetAllAssetExceptUserDocument,
    options
  );
}
export type GetAllAssetExceptUserQueryHookResult = ReturnType<typeof useGetAllAssetExceptUserQuery>;
export type GetAllAssetExceptUserLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetExceptUserLazyQuery
>;
export type GetAllAssetExceptUserQueryResult = Apollo.QueryResult<
  GetAllAssetExceptUserQuery,
  GetAllAssetExceptUserQueryVariables
>;
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
        actualDateAndPeriod
        category
        genericDimensions
        inventoryNumber
        technique
      }
    }
  }
`;
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options
  );
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
      }
    }
  }
`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(
    UpdateAssetDocument,
    options
  );
}
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;
export const ChangeAssetCategoryDocument = gql`
  mutation ChangeAssetCategory($input: ChangeAssetCategoryInput!) {
    changeAssetCategory(input: $input) {
      asset {
        id
        category
        subcategory
      }
    }
  }
`;
export type ChangeAssetCategoryMutationFn = Apollo.MutationFunction<
  ChangeAssetCategoryMutation,
  ChangeAssetCategoryMutationVariables
>;

/**
 * __useChangeAssetCategoryMutation__
 *
 * To run a mutation, you first call `useChangeAssetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAssetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAssetCategoryMutation, { data, loading, error }] = useChangeAssetCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAssetCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAssetCategoryMutation,
    ChangeAssetCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeAssetCategoryMutation, ChangeAssetCategoryMutationVariables>(
    ChangeAssetCategoryDocument,
    options
  );
}
export type ChangeAssetCategoryMutationHookResult = ReturnType<
  typeof useChangeAssetCategoryMutation
>;
export type ChangeAssetCategoryMutationResult = Apollo.MutationResult<ChangeAssetCategoryMutation>;
export type ChangeAssetCategoryMutationOptions = Apollo.BaseMutationOptions<
  ChangeAssetCategoryMutation,
  ChangeAssetCategoryMutationVariables
>;
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      asset {
        id
      }
    }
  }
`;
export type DeleteAssetMutationFn = Apollo.MutationFunction<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(
    DeleteAssetDocument,
    options
  );
}
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;
export const GetAssetElementsByAssetIdDocument = gql`
  query GetAssetElementsByAssetId($assetId: ID!) {
    allAssetElements(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          name
          description
          notes
          materials
        }
      }
    }
  }
`;

/**
 * __useGetAssetElementsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetElementsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetElementsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetElementsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetElementsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetElementsByAssetIdQuery, GetAssetElementsByAssetIdQueryVariables>(
    GetAssetElementsByAssetIdDocument,
    options
  );
}
export function useGetAssetElementsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >(GetAssetElementsByAssetIdDocument, options);
}
export type GetAssetElementsByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdQuery
>;
export type GetAssetElementsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdLazyQuery
>;
export type GetAssetElementsByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetElementsByAssetIdQuery,
  GetAssetElementsByAssetIdQueryVariables
>;
export const GetAssetPresentValuesByAssetIdDocument = gql`
  query GetAssetPresentValuesByAssetId($assetId: ID!) {
    allAssetPresentValues(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          amount {
            amount
            currency {
              code
            }
          }
          author {
            id
            fullName
          }
          estimateDate
          estimateReason
          notes
          fileTitle
          file
        }
      }
    }
  }
`;

/**
 * __useGetAssetPresentValuesByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetPresentValuesByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPresentValuesByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPresentValuesByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetPresentValuesByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export function useGetAssetPresentValuesByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export type GetAssetPresentValuesByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdQuery
>;
export type GetAssetPresentValuesByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdLazyQuery
>;
export type GetAssetPresentValuesByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetPresentValuesByAssetIdQuery,
  GetAssetPresentValuesByAssetIdQueryVariables
>;
export const CreateAssetElementDocument = gql`
  mutation CreateAssetElement($input: CreateAssetElementInput!) {
    createAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type CreateAssetElementMutationFn = Apollo.MutationFunction<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;

/**
 * __useCreateAssetElementMutation__
 *
 * To run a mutation, you first call `useCreateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetElementMutation, { data, loading, error }] = useCreateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetElementMutation,
    CreateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetElementMutation, CreateAssetElementMutationVariables>(
    CreateAssetElementDocument,
    options
  );
}
export type CreateAssetElementMutationHookResult = ReturnType<typeof useCreateAssetElementMutation>;
export type CreateAssetElementMutationResult = Apollo.MutationResult<CreateAssetElementMutation>;
export type CreateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;
export const CreateAssetPresentValueDocument = gql`
  mutation CreateAssetPresentValue($input: CreateAssetPresentValueInput!) {
    createAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type CreateAssetPresentValueMutationFn = Apollo.MutationFunction<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;

/**
 * __useCreateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useCreateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetPresentValueMutation, { data, loading, error }] = useCreateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >(CreateAssetPresentValueDocument, options);
}
export type CreateAssetPresentValueMutationHookResult = ReturnType<
  typeof useCreateAssetPresentValueMutation
>;
export type CreateAssetPresentValueMutationResult =
  Apollo.MutationResult<CreateAssetPresentValueMutation>;
export type CreateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;
export const UpdateAssetElementDocument = gql`
  mutation UpdateAssetElement($input: UpdateAssetElementInput!) {
    updateAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type UpdateAssetElementMutationFn = Apollo.MutationFunction<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;

/**
 * __useUpdateAssetElementMutation__
 *
 * To run a mutation, you first call `useUpdateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetElementMutation, { data, loading, error }] = useUpdateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetElementMutation,
    UpdateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetElementMutation, UpdateAssetElementMutationVariables>(
    UpdateAssetElementDocument,
    options
  );
}
export type UpdateAssetElementMutationHookResult = ReturnType<typeof useUpdateAssetElementMutation>;
export type UpdateAssetElementMutationResult = Apollo.MutationResult<UpdateAssetElementMutation>;
export type UpdateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;
export const UpdateAssetPresentValueDocument = gql`
  mutation UpdateAssetPresentValue($input: UpdateAssetPresentValueInput!) {
    updateAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type UpdateAssetPresentValueMutationFn = Apollo.MutationFunction<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;

/**
 * __useUpdateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useUpdateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetPresentValueMutation, { data, loading, error }] = useUpdateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >(UpdateAssetPresentValueDocument, options);
}
export type UpdateAssetPresentValueMutationHookResult = ReturnType<
  typeof useUpdateAssetPresentValueMutation
>;
export type UpdateAssetPresentValueMutationResult =
  Apollo.MutationResult<UpdateAssetPresentValueMutation>;
export type UpdateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;
export const DeleteAssetElementDocument = gql`
  mutation DeleteAssetElement($input: DeleteAssetElementInput!) {
    deleteAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type DeleteAssetElementMutationFn = Apollo.MutationFunction<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;

/**
 * __useDeleteAssetElementMutation__
 *
 * To run a mutation, you first call `useDeleteAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetElementMutation, { data, loading, error }] = useDeleteAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetElementMutation,
    DeleteAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetElementMutation, DeleteAssetElementMutationVariables>(
    DeleteAssetElementDocument,
    options
  );
}
export type DeleteAssetElementMutationHookResult = ReturnType<typeof useDeleteAssetElementMutation>;
export type DeleteAssetElementMutationResult = Apollo.MutationResult<DeleteAssetElementMutation>;
export type DeleteAssetElementMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;
export const DeleteAssetPresentValueDocument = gql`
  mutation DeleteAssetPresentValue($input: DeleteAssetPresentValueInput!) {
    deleteAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type DeleteAssetPresentValueMutationFn = Apollo.MutationFunction<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;

/**
 * __useDeleteAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useDeleteAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetPresentValueMutation, { data, loading, error }] = useDeleteAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >(DeleteAssetPresentValueDocument, options);
}
export type DeleteAssetPresentValueMutationHookResult = ReturnType<
  typeof useDeleteAssetPresentValueMutation
>;
export type DeleteAssetPresentValueMutationResult =
  Apollo.MutationResult<DeleteAssetPresentValueMutation>;
export type DeleteAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;
export const AddAssetMediaDocument = gql`
  mutation AddAssetMedia($input: AddAssetMediaInput!) {
    addAssetMedia(input: $input) {
      asset {
        id
        title
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddAssetMediaMutationFn = Apollo.MutationFunction<
  AddAssetMediaMutation,
  AddAssetMediaMutationVariables
>;

/**
 * __useAddAssetMediaMutation__
 *
 * To run a mutation, you first call `useAddAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetMediaMutation, { data, loading, error }] = useAddAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssetMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssetMediaMutation, AddAssetMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssetMediaMutation, AddAssetMediaMutationVariables>(
    AddAssetMediaDocument,
    options
  );
}
export type AddAssetMediaMutationHookResult = ReturnType<typeof useAddAssetMediaMutation>;
export type AddAssetMediaMutationResult = Apollo.MutationResult<AddAssetMediaMutation>;
export type AddAssetMediaMutationOptions = Apollo.BaseMutationOptions<
  AddAssetMediaMutation,
  AddAssetMediaMutationVariables
>;
export const DeleteAssetMediaDocument = gql`
  mutation DeleteAssetMedia($input: DeleteAssetMediaInput!) {
    deleteAssetMedia(input: $input) {
      asset {
        id
        title
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteAssetMediaMutationFn = Apollo.MutationFunction<
  DeleteAssetMediaMutation,
  DeleteAssetMediaMutationVariables
>;

/**
 * __useDeleteAssetMediaMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMediaMutation, { data, loading, error }] = useDeleteAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetMediaMutation,
    DeleteAssetMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetMediaMutation, DeleteAssetMediaMutationVariables>(
    DeleteAssetMediaDocument,
    options
  );
}
export type DeleteAssetMediaMutationHookResult = ReturnType<typeof useDeleteAssetMediaMutation>;
export type DeleteAssetMediaMutationResult = Apollo.MutationResult<DeleteAssetMediaMutation>;
export type DeleteAssetMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMediaMutation,
  DeleteAssetMediaMutationVariables
>;
export const GetAssetDocumentsByAssetIdDocument = gql`
  query GetAssetDocumentsByAssetId($assetId: ID!) {
    allAssetDocuments(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          title
          description
          documentType
          coaType
          issuer
          recipient
          isClassified
          releaseDate
          expirationDate
          notes
          archiveNumber
          fineArtsIssueType
          isTemporaryExport
          isTemporaryImport
          isCriticalCatalog
          editor
          curator
          subscriptionDate
          contractSubscriber
          publishingDate
          otherDocumentType
          createdBy {
            name
          }
          asset {
            id
          }
          attachments {
            edges {
              node {
                id
                name
                file
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssetDocumentsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDocumentsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDocumentsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDocumentsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetDocumentsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetDocumentsByAssetIdQuery, GetAssetDocumentsByAssetIdQueryVariables>(
    GetAssetDocumentsByAssetIdDocument,
    options
  );
}
export function useGetAssetDocumentsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >(GetAssetDocumentsByAssetIdDocument, options);
}
export type GetAssetDocumentsByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetDocumentsByAssetIdQuery
>;
export type GetAssetDocumentsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetDocumentsByAssetIdLazyQuery
>;
export type GetAssetDocumentsByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetDocumentsByAssetIdQuery,
  GetAssetDocumentsByAssetIdQueryVariables
>;
export const CreateAssetDocumentDocument = gql`
  mutation CreateAssetDocument($input: CreateAssetDocumentInput!) {
    createAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type CreateAssetDocumentMutationFn = Apollo.MutationFunction<
  CreateAssetDocumentMutation,
  CreateAssetDocumentMutationVariables
>;

/**
 * __useCreateAssetDocumentMutation__
 *
 * To run a mutation, you first call `useCreateAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetDocumentMutation, { data, loading, error }] = useCreateAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetDocumentMutation,
    CreateAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetDocumentMutation, CreateAssetDocumentMutationVariables>(
    CreateAssetDocumentDocument,
    options
  );
}
export type CreateAssetDocumentMutationHookResult = ReturnType<
  typeof useCreateAssetDocumentMutation
>;
export type CreateAssetDocumentMutationResult = Apollo.MutationResult<CreateAssetDocumentMutation>;
export type CreateAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetDocumentMutation,
  CreateAssetDocumentMutationVariables
>;
export const UpdateAssetDocumentDocument = gql`
  mutation UpdateAssetDocument($input: UpdateAssetDocumentInput!) {
    updateAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type UpdateAssetDocumentMutationFn = Apollo.MutationFunction<
  UpdateAssetDocumentMutation,
  UpdateAssetDocumentMutationVariables
>;

/**
 * __useUpdateAssetDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDocumentMutation, { data, loading, error }] = useUpdateAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetDocumentMutation,
    UpdateAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetDocumentMutation, UpdateAssetDocumentMutationVariables>(
    UpdateAssetDocumentDocument,
    options
  );
}
export type UpdateAssetDocumentMutationHookResult = ReturnType<
  typeof useUpdateAssetDocumentMutation
>;
export type UpdateAssetDocumentMutationResult = Apollo.MutationResult<UpdateAssetDocumentMutation>;
export type UpdateAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetDocumentMutation,
  UpdateAssetDocumentMutationVariables
>;
export const DeleteAssetDocumentDocument = gql`
  mutation DeleteAssetDocument($input: DeleteAssetDocumentInput!) {
    deleteAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type DeleteAssetDocumentMutationFn = Apollo.MutationFunction<
  DeleteAssetDocumentMutation,
  DeleteAssetDocumentMutationVariables
>;

/**
 * __useDeleteAssetDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetDocumentMutation, { data, loading, error }] = useDeleteAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetDocumentMutation,
    DeleteAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetDocumentMutation, DeleteAssetDocumentMutationVariables>(
    DeleteAssetDocumentDocument,
    options
  );
}
export type DeleteAssetDocumentMutationHookResult = ReturnType<
  typeof useDeleteAssetDocumentMutation
>;
export type DeleteAssetDocumentMutationResult = Apollo.MutationResult<DeleteAssetDocumentMutation>;
export type DeleteAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetDocumentMutation,
  DeleteAssetDocumentMutationVariables
>;
export const GetAssetDocumentAttachmentsByDocumentIdDocument = gql`
  query GetAssetDocumentAttachmentsByDocumentId($documentId: ID!) {
    allAssetDocumentAttachments(document: $documentId, orderBy: "-created_at") {
      edges {
        node {
          id
          name
          file
        }
      }
    }
  }
`;

/**
 * __useGetAssetDocumentAttachmentsByDocumentIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDocumentAttachmentsByDocumentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDocumentAttachmentsByDocumentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDocumentAttachmentsByDocumentIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetAssetDocumentAttachmentsByDocumentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >(GetAssetDocumentAttachmentsByDocumentIdDocument, options);
}
export function useGetAssetDocumentAttachmentsByDocumentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >(GetAssetDocumentAttachmentsByDocumentIdDocument, options);
}
export type GetAssetDocumentAttachmentsByDocumentIdQueryHookResult = ReturnType<
  typeof useGetAssetDocumentAttachmentsByDocumentIdQuery
>;
export type GetAssetDocumentAttachmentsByDocumentIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetDocumentAttachmentsByDocumentIdLazyQuery
>;
export type GetAssetDocumentAttachmentsByDocumentIdQueryResult = Apollo.QueryResult<
  GetAssetDocumentAttachmentsByDocumentIdQuery,
  GetAssetDocumentAttachmentsByDocumentIdQueryVariables
>;
export const CreateAssetDocumentAttachmentDocument = gql`
  mutation CreateAssetDocumentAttachment($input: CreateAssetDocumentAttachmentInput!) {
    createAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type CreateAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  CreateAssetDocumentAttachmentMutation,
  CreateAssetDocumentAttachmentMutationVariables
>;

/**
 * __useCreateAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetDocumentAttachmentMutation, { data, loading, error }] = useCreateAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetDocumentAttachmentMutation,
    CreateAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetDocumentAttachmentMutation,
    CreateAssetDocumentAttachmentMutationVariables
  >(CreateAssetDocumentAttachmentDocument, options);
}
export type CreateAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useCreateAssetDocumentAttachmentMutation
>;
export type CreateAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<CreateAssetDocumentAttachmentMutation>;
export type CreateAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetDocumentAttachmentMutation,
  CreateAssetDocumentAttachmentMutationVariables
>;
export const UpdateAssetDocumentAttachmentDocument = gql`
  mutation UpdateAssetDocumentAttachment($input: UpdateAssetDocumentAttachmentInput!) {
    updateAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type UpdateAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  UpdateAssetDocumentAttachmentMutation,
  UpdateAssetDocumentAttachmentMutationVariables
>;

/**
 * __useUpdateAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDocumentAttachmentMutation, { data, loading, error }] = useUpdateAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetDocumentAttachmentMutation,
    UpdateAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssetDocumentAttachmentMutation,
    UpdateAssetDocumentAttachmentMutationVariables
  >(UpdateAssetDocumentAttachmentDocument, options);
}
export type UpdateAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useUpdateAssetDocumentAttachmentMutation
>;
export type UpdateAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<UpdateAssetDocumentAttachmentMutation>;
export type UpdateAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetDocumentAttachmentMutation,
  UpdateAssetDocumentAttachmentMutationVariables
>;
export const DeleteAssetDocumentAttachmentDocument = gql`
  mutation DeleteAssetDocumentAttachment($input: DeleteAssetDocumentAttachmentInput!) {
    deleteAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type DeleteAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  DeleteAssetDocumentAttachmentMutation,
  DeleteAssetDocumentAttachmentMutationVariables
>;

/**
 * __useDeleteAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetDocumentAttachmentMutation, { data, loading, error }] = useDeleteAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetDocumentAttachmentMutation,
    DeleteAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetDocumentAttachmentMutation,
    DeleteAssetDocumentAttachmentMutationVariables
  >(DeleteAssetDocumentAttachmentDocument, options);
}
export type DeleteAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useDeleteAssetDocumentAttachmentMutation
>;
export type DeleteAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<DeleteAssetDocumentAttachmentMutation>;
export type DeleteAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetDocumentAttachmentMutation,
  DeleteAssetDocumentAttachmentMutationVariables
>;
export const DeleteAssetCarryingValueFileDocument = gql`
  mutation DeleteAssetCarryingValueFile($input: DeleteAssetCarryingValueFileInput!) {
    deleteAssetCarryingValueFile(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
      }
    }
  }
`;
export type DeleteAssetCarryingValueFileMutationFn = Apollo.MutationFunction<
  DeleteAssetCarryingValueFileMutation,
  DeleteAssetCarryingValueFileMutationVariables
>;

/**
 * __useDeleteAssetCarryingValueFileMutation__
 *
 * To run a mutation, you first call `useDeleteAssetCarryingValueFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetCarryingValueFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetCarryingValueFileMutation, { data, loading, error }] = useDeleteAssetCarryingValueFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetCarryingValueFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetCarryingValueFileMutation,
    DeleteAssetCarryingValueFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetCarryingValueFileMutation,
    DeleteAssetCarryingValueFileMutationVariables
  >(DeleteAssetCarryingValueFileDocument, options);
}
export type DeleteAssetCarryingValueFileMutationHookResult = ReturnType<
  typeof useDeleteAssetCarryingValueFileMutation
>;
export type DeleteAssetCarryingValueFileMutationResult =
  Apollo.MutationResult<DeleteAssetCarryingValueFileMutation>;
export type DeleteAssetCarryingValueFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetCarryingValueFileMutation,
  DeleteAssetCarryingValueFileMutationVariables
>;
export const ExportAssetPdfDocument = gql`
  mutation ExportAssetPDF($input: ExportAssetPDFInput!) {
    exportAssetPdf(input: $input) {
      asset {
        id
      }
      url
    }
  }
`;
export type ExportAssetPdfMutationFn = Apollo.MutationFunction<
  ExportAssetPdfMutation,
  ExportAssetPdfMutationVariables
>;

/**
 * __useExportAssetPdfMutation__
 *
 * To run a mutation, you first call `useExportAssetPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssetPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssetPdfMutation, { data, loading, error }] = useExportAssetPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAssetPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportAssetPdfMutation, ExportAssetPdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportAssetPdfMutation, ExportAssetPdfMutationVariables>(
    ExportAssetPdfDocument,
    options
  );
}
export type ExportAssetPdfMutationHookResult = ReturnType<typeof useExportAssetPdfMutation>;
export type ExportAssetPdfMutationResult = Apollo.MutationResult<ExportAssetPdfMutation>;
export type ExportAssetPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportAssetPdfMutation,
  ExportAssetPdfMutationVariables
>;
export const GetCurrentClientDocument = gql`
  query GetCurrentClient($id: ID!) {
    client(id: $id) {
      createdOn
      customerType
      currency
      dashboardBaseUrl
      id
      location
      logo
      name
      onTrial
      paidUntil
      schemaName
      storageName
      tier
      timezone
      defaultInsuranceCompany {
        id
        fullName
      }
      defaultBroker {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetCurrentClientQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export function useGetCurrentClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export type GetCurrentClientQueryHookResult = ReturnType<typeof useGetCurrentClientQuery>;
export type GetCurrentClientLazyQueryHookResult = ReturnType<typeof useGetCurrentClientLazyQuery>;
export type GetCurrentClientQueryResult = Apollo.QueryResult<
  GetCurrentClientQuery,
  GetCurrentClientQueryVariables
>;
export const GetCollectionByIdDocument = gql`
  query GetCollectionById($id: ID!) {
    collection(id: $id) {
      ...CollectionAnagraphicFields
    }
  }
  ${CollectionAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(
    GetCollectionByIdDocument,
    options
  );
}
export function useGetCollectionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(
    GetCollectionByIdDocument,
    options
  );
}
export type GetCollectionByIdQueryHookResult = ReturnType<typeof useGetCollectionByIdQuery>;
export type GetCollectionByIdLazyQueryHookResult = ReturnType<typeof useGetCollectionByIdLazyQuery>;
export type GetCollectionByIdQueryResult = Apollo.QueryResult<
  GetCollectionByIdQuery,
  GetCollectionByIdQueryVariables
>;
export const GetAllCollectionsDocument = gql`
  query GetAllCollections {
    allCollections(orderBy: "-created_at") {
      edges {
        node {
          id
          name
          description
          image
          notes
          totalAssetsNumber
          validatedAssetsNumber
          createdFrom
          actualStatusObjects
          createdAt
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAllCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>(
    GetAllCollectionsDocument,
    options
  );
}
export function useGetAllCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>(
    GetAllCollectionsDocument,
    options
  );
}
export type GetAllCollectionsQueryHookResult = ReturnType<typeof useGetAllCollectionsQuery>;
export type GetAllCollectionsLazyQueryHookResult = ReturnType<typeof useGetAllCollectionsLazyQuery>;
export type GetAllCollectionsQueryResult = Apollo.QueryResult<
  GetAllCollectionsQuery,
  GetAllCollectionsQueryVariables
>;
export const CreateCollectionDocument = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
        name
        description
        notes
      }
    }
  }
`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(
    CreateCollectionDocument,
    options
  );
}
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;
export const UpdateCollectionDocument = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(
    UpdateCollectionDocument,
    options
  );
}
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;
export const DeleteCollectionDocument = gql`
  mutation DeleteCollection($input: DeleteCollectionInput!) {
    deleteCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionMutation,
    DeleteCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(
    DeleteCollectionDocument,
    options
  );
}
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;
export const DetachAssetFromCollectionDocument = gql`
  mutation DetachAssetFromCollection($input: DetachAssetFromCollectionInput!) {
    detachAssetFromCollection(input: $input) {
      collection {
        id
        assetSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetFromCollectionMutationFn = Apollo.MutationFunction<
  DetachAssetFromCollectionMutation,
  DetachAssetFromCollectionMutationVariables
>;

/**
 * __useDetachAssetFromCollectionMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromCollectionMutation, { data, loading, error }] = useDetachAssetFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromCollectionMutation,
    DetachAssetFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromCollectionMutation,
    DetachAssetFromCollectionMutationVariables
  >(DetachAssetFromCollectionDocument, options);
}
export type DetachAssetFromCollectionMutationHookResult = ReturnType<
  typeof useDetachAssetFromCollectionMutation
>;
export type DetachAssetFromCollectionMutationResult =
  Apollo.MutationResult<DetachAssetFromCollectionMutation>;
export type DetachAssetFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromCollectionMutation,
  DetachAssetFromCollectionMutationVariables
>;
export const ExportCollectionPdfDocument = gql`
  mutation ExportCollectionPdf($input: ExportCollectionPDFInput!) {
    exportCollectionPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportCollectionPdfMutationFn = Apollo.MutationFunction<
  ExportCollectionPdfMutation,
  ExportCollectionPdfMutationVariables
>;

/**
 * __useExportCollectionPdfMutation__
 *
 * To run a mutation, you first call `useExportCollectionPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCollectionPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCollectionPdfMutation, { data, loading, error }] = useExportCollectionPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCollectionPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportCollectionPdfMutation,
    ExportCollectionPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportCollectionPdfMutation, ExportCollectionPdfMutationVariables>(
    ExportCollectionPdfDocument,
    options
  );
}
export type ExportCollectionPdfMutationHookResult = ReturnType<
  typeof useExportCollectionPdfMutation
>;
export type ExportCollectionPdfMutationResult = Apollo.MutationResult<ExportCollectionPdfMutation>;
export type ExportCollectionPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportCollectionPdfMutation,
  ExportCollectionPdfMutationVariables
>;
export const GetAllConditionStatusReportsDocument = gql`
  query GetAllConditionStatusReports {
    allConditionStatusReports(orderBy: "-created_at") {
      edges {
        node {
          ...ConditionStatusReportFindingFields
          createdAt
          createdBy {
            name
          }
        }
      }
    }
  }
  ${ConditionStatusReportFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllConditionStatusReportsQuery__
 *
 * To run a query within a React component, call `useGetAllConditionStatusReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllConditionStatusReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllConditionStatusReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllConditionStatusReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >(GetAllConditionStatusReportsDocument, options);
}
export function useGetAllConditionStatusReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >(GetAllConditionStatusReportsDocument, options);
}
export type GetAllConditionStatusReportsQueryHookResult = ReturnType<
  typeof useGetAllConditionStatusReportsQuery
>;
export type GetAllConditionStatusReportsLazyQueryHookResult = ReturnType<
  typeof useGetAllConditionStatusReportsLazyQuery
>;
export type GetAllConditionStatusReportsQueryResult = Apollo.QueryResult<
  GetAllConditionStatusReportsQuery,
  GetAllConditionStatusReportsQueryVariables
>;
export const GetConditionStatusReportByIdDocument = gql`
  query GetConditionStatusReportById($id: ID!) {
    conditionStatusReport(id: $id) {
      ...ConditionStatusReportAnagraphicFields
    }
  }
  ${ConditionStatusReportAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetConditionStatusReportByIdQuery__
 *
 * To run a query within a React component, call `useGetConditionStatusReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionStatusReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionStatusReportByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConditionStatusReportByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >(GetConditionStatusReportByIdDocument, options);
}
export function useGetConditionStatusReportByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >(GetConditionStatusReportByIdDocument, options);
}
export type GetConditionStatusReportByIdQueryHookResult = ReturnType<
  typeof useGetConditionStatusReportByIdQuery
>;
export type GetConditionStatusReportByIdLazyQueryHookResult = ReturnType<
  typeof useGetConditionStatusReportByIdLazyQuery
>;
export type GetConditionStatusReportByIdQueryResult = Apollo.QueryResult<
  GetConditionStatusReportByIdQuery,
  GetConditionStatusReportByIdQueryVariables
>;
export const CreateConditionStatusReportDocument = gql`
  mutation CreateConditionStatusReport($input: CreateConditionStatusReportInput!) {
    createConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
        reportStatus
        environmentalConditionsAbnormalHumidityEvent
        environmentalConditionsAbnormalLightEvent
        environmentalConditionsAbnormalTemperatureEvent
        environmentalConditionsLightUnit
        environmentalConditionsOptimalHumidity
        environmentalConditionsOptimalLightLevelIntensity
        environmentalConditionsOptimalTemperature
        environmentalConditionsTemperatureUnit
        exhibitionCurator {
          id
          fullName
        }
        exhibitionEventFrom
        exhibitionEventTo
        exhibitionHostEntity {
          id
          fullName
        }
        exhibitionLender {
          id
          fullName
        }
        exhibitionLoanFrom
        exhibitionLoanTo
        exhibitionLocation
        exhibitionName
        exhibitionNotes
        exhibitionPlanner {
          id
          fullName
        }
        exhibitionPermanent
        exhibitionTitle
        logisticsAirConditioningTransportConditions
        logisticsCarrier {
          id
          fullName
        }
        logisticsDeliveryAddress
        logisticsDeliveryCity
        logisticsDeliveryCountry
        logisticsDeliveryDate
        logisticsDeliveryState
        logisticsDeliveryZip
        logisticsId
        logisticsNumberOfPackages
        logisticsPickupAddress
        logisticsPickupCity
        logisticsPickupCountry
        logisticsPickupDate
        logisticsPickupState
        logisticsPickupZip
        logisticsRecipient {
          id
          fullName
        }
        logisticsSender {
          id
          fullName
        }
        reportCompany {
          id
          fullName
        }
        reportDate
        reportDetailsAssetCondition
        reportDetailsAssetDamageDescription
        reportDetailsExamConditions
        reportDetailsExamConditionsNote
        reportDetailsExamLocation
        reportDetailsExhaminationTimeTechnicalDescription
        reportDetailsPackagingGeneralConditions
        reportDetailsExaminedPackages
        reportDetailsPackagingStatusDescription
        reportDetailsProtectionsSupportsCondition
        reportDetailsProtectionsSupportsDamageDescription
        reportExaminationType
        reportExaminer {
          id
          fullName
        }
        reportMotivation
        reportMotivationDescription
        reportType
        reportAsset {
          id
          title
          actualDateAndPeriod
        }
        settingsConservation
        settingsExaminerComments
        settingsHandlingRequirements
        settingsHasBarriers
        settingsInjuryRisk
        settingsLenderRequests
        settingsNotes
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValueExaminationTime {
          amount
          currency {
            code
          }
        }
        valuePercentageDepreciation
      }
    }
  }
`;
export type CreateConditionStatusReportMutationFn = Apollo.MutationFunction<
  CreateConditionStatusReportMutation,
  CreateConditionStatusReportMutationVariables
>;

/**
 * __useCreateConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useCreateConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionStatusReportMutation, { data, loading, error }] = useCreateConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConditionStatusReportMutation,
    CreateConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConditionStatusReportMutation,
    CreateConditionStatusReportMutationVariables
  >(CreateConditionStatusReportDocument, options);
}
export type CreateConditionStatusReportMutationHookResult = ReturnType<
  typeof useCreateConditionStatusReportMutation
>;
export type CreateConditionStatusReportMutationResult =
  Apollo.MutationResult<CreateConditionStatusReportMutation>;
export type CreateConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  CreateConditionStatusReportMutation,
  CreateConditionStatusReportMutationVariables
>;
export const UpdateConditionStatusReportDocument = gql`
  mutation UpdateConditionStatusReport($input: UpdateConditionStatusReportInput!) {
    updateConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
        reportStatus
        environmentalConditionsAbnormalHumidityEvent
        environmentalConditionsAbnormalLightEvent
        environmentalConditionsAbnormalTemperatureEvent
        environmentalConditionsLightUnit
        environmentalConditionsOptimalHumidity
        environmentalConditionsOptimalLightLevelIntensity
        environmentalConditionsOptimalTemperature
        environmentalConditionsTemperatureUnit
        exhibitionCurator {
          id
          fullName
        }
        exhibitionEventFrom
        exhibitionEventTo
        exhibitionHostEntity {
          id
          fullName
        }
        exhibitionLender {
          id
          fullName
        }
        exhibitionLoanFrom
        exhibitionLoanTo
        exhibitionLocation
        exhibitionName
        exhibitionNotes
        exhibitionPlanner {
          id
          fullName
        }
        exhibitionPermanent
        exhibitionTitle
        logisticsAirConditioningTransportConditions
        logisticsCarrier {
          id
          fullName
        }
        logisticsDeliveryAddress
        logisticsDeliveryCity
        logisticsDeliveryCountry
        logisticsDeliveryDate
        logisticsDeliveryState
        logisticsDeliveryZip
        logisticsId
        logisticsNumberOfPackages
        logisticsPickupAddress
        logisticsPickupCity
        logisticsPickupCountry
        logisticsPickupDate
        logisticsPickupState
        logisticsPickupZip
        logisticsRecipient {
          id
          fullName
        }
        logisticsSender {
          id
          fullName
        }
        reportCompany {
          id
          fullName
        }
        reportDate
        reportDetailsAssetCondition
        reportDetailsAssetDamageDescription
        reportDetailsExamConditions
        reportDetailsExamConditionsNote
        reportDetailsExamLocation
        reportDetailsExhaminationTimeTechnicalDescription
        reportDetailsPackagingGeneralConditions
        reportDetailsExaminedPackages
        reportDetailsPackagingStatusDescription
        reportDetailsProtectionsSupportsCondition
        reportDetailsProtectionsSupportsDamageDescription
        reportExaminationType
        reportExaminer {
          id
          fullName
        }
        reportMotivation
        reportMotivationDescription
        reportType
        reportAsset {
          id
          title
          actualDateAndPeriod
        }
        settingsConservation
        settingsExaminerComments
        settingsHandlingRequirements
        settingsHasBarriers
        settingsInjuryRisk
        settingsLenderRequests
        settingsNotes
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValueExaminationTime {
          amount
          currency {
            code
          }
        }
        valuePercentageDepreciation
      }
    }
  }
`;
export type UpdateConditionStatusReportMutationFn = Apollo.MutationFunction<
  UpdateConditionStatusReportMutation,
  UpdateConditionStatusReportMutationVariables
>;

/**
 * __useUpdateConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useUpdateConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionStatusReportMutation, { data, loading, error }] = useUpdateConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConditionStatusReportMutation,
    UpdateConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConditionStatusReportMutation,
    UpdateConditionStatusReportMutationVariables
  >(UpdateConditionStatusReportDocument, options);
}
export type UpdateConditionStatusReportMutationHookResult = ReturnType<
  typeof useUpdateConditionStatusReportMutation
>;
export type UpdateConditionStatusReportMutationResult =
  Apollo.MutationResult<UpdateConditionStatusReportMutation>;
export type UpdateConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateConditionStatusReportMutation,
  UpdateConditionStatusReportMutationVariables
>;
export const DeleteConditionStatusReportDocument = gql`
  mutation DeleteConditionStatusReport($input: DeleteConditionStatusReportInput!) {
    deleteConditionStatusReport(input: $input) {
      deleteConditionStatusReport {
        id
      }
    }
  }
`;
export type DeleteConditionStatusReportMutationFn = Apollo.MutationFunction<
  DeleteConditionStatusReportMutation,
  DeleteConditionStatusReportMutationVariables
>;

/**
 * __useDeleteConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useDeleteConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionStatusReportMutation, { data, loading, error }] = useDeleteConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConditionStatusReportMutation,
    DeleteConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConditionStatusReportMutation,
    DeleteConditionStatusReportMutationVariables
  >(DeleteConditionStatusReportDocument, options);
}
export type DeleteConditionStatusReportMutationHookResult = ReturnType<
  typeof useDeleteConditionStatusReportMutation
>;
export type DeleteConditionStatusReportMutationResult =
  Apollo.MutationResult<DeleteConditionStatusReportMutation>;
export type DeleteConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteConditionStatusReportMutation,
  DeleteConditionStatusReportMutationVariables
>;
export const CloseConditionStatusReportDocument = gql`
  mutation CloseConditionStatusReport($input: CloseConditionStatusReportInput!) {
    closeConditionStatusReport(input: $input) {
      closeConditionStatusReport {
        id
      }
    }
  }
`;
export type CloseConditionStatusReportMutationFn = Apollo.MutationFunction<
  CloseConditionStatusReportMutation,
  CloseConditionStatusReportMutationVariables
>;

/**
 * __useCloseConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useCloseConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeConditionStatusReportMutation, { data, loading, error }] = useCloseConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseConditionStatusReportMutation,
    CloseConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseConditionStatusReportMutation,
    CloseConditionStatusReportMutationVariables
  >(CloseConditionStatusReportDocument, options);
}
export type CloseConditionStatusReportMutationHookResult = ReturnType<
  typeof useCloseConditionStatusReportMutation
>;
export type CloseConditionStatusReportMutationResult =
  Apollo.MutationResult<CloseConditionStatusReportMutation>;
export type CloseConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  CloseConditionStatusReportMutation,
  CloseConditionStatusReportMutationVariables
>;
export const DetachAssetFromConditionStatusReportDocument = gql`
  mutation DetachAssetFromConditionStatusReport(
    $input: DetachAssetFromConditionStatusReportInput!
  ) {
    detachAssetFromConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
      }
    }
  }
`;
export type DetachAssetFromConditionStatusReportMutationFn = Apollo.MutationFunction<
  DetachAssetFromConditionStatusReportMutation,
  DetachAssetFromConditionStatusReportMutationVariables
>;

/**
 * __useDetachAssetFromConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromConditionStatusReportMutation, { data, loading, error }] = useDetachAssetFromConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromConditionStatusReportMutation,
    DetachAssetFromConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromConditionStatusReportMutation,
    DetachAssetFromConditionStatusReportMutationVariables
  >(DetachAssetFromConditionStatusReportDocument, options);
}
export type DetachAssetFromConditionStatusReportMutationHookResult = ReturnType<
  typeof useDetachAssetFromConditionStatusReportMutation
>;
export type DetachAssetFromConditionStatusReportMutationResult =
  Apollo.MutationResult<DetachAssetFromConditionStatusReportMutation>;
export type DetachAssetFromConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromConditionStatusReportMutation,
  DetachAssetFromConditionStatusReportMutationVariables
>;
export const GetMediaConditionStatusReportByIdDocument = gql`
  query GetMediaConditionStatusReportById($id: ID!) {
    conditionStatusReport(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaConditionStatusReportByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaConditionStatusReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaConditionStatusReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaConditionStatusReportByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaConditionStatusReportByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >(GetMediaConditionStatusReportByIdDocument, options);
}
export function useGetMediaConditionStatusReportByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >(GetMediaConditionStatusReportByIdDocument, options);
}
export type GetMediaConditionStatusReportByIdQueryHookResult = ReturnType<
  typeof useGetMediaConditionStatusReportByIdQuery
>;
export type GetMediaConditionStatusReportByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaConditionStatusReportByIdLazyQuery
>;
export type GetMediaConditionStatusReportByIdQueryResult = Apollo.QueryResult<
  GetMediaConditionStatusReportByIdQuery,
  GetMediaConditionStatusReportByIdQueryVariables
>;
export const AddConditionStatusReportMediaDocument = gql`
  mutation AddConditionStatusReportMedia($input: AddConditionStatusReportMediaInput!) {
    addConditionStatusReportMedia(input: $input) {
      conditionStatusReport {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddConditionStatusReportMediaMutationFn = Apollo.MutationFunction<
  AddConditionStatusReportMediaMutation,
  AddConditionStatusReportMediaMutationVariables
>;

/**
 * __useAddConditionStatusReportMediaMutation__
 *
 * To run a mutation, you first call `useAddConditionStatusReportMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConditionStatusReportMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConditionStatusReportMediaMutation, { data, loading, error }] = useAddConditionStatusReportMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConditionStatusReportMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddConditionStatusReportMediaMutation,
    AddConditionStatusReportMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddConditionStatusReportMediaMutation,
    AddConditionStatusReportMediaMutationVariables
  >(AddConditionStatusReportMediaDocument, options);
}
export type AddConditionStatusReportMediaMutationHookResult = ReturnType<
  typeof useAddConditionStatusReportMediaMutation
>;
export type AddConditionStatusReportMediaMutationResult =
  Apollo.MutationResult<AddConditionStatusReportMediaMutation>;
export type AddConditionStatusReportMediaMutationOptions = Apollo.BaseMutationOptions<
  AddConditionStatusReportMediaMutation,
  AddConditionStatusReportMediaMutationVariables
>;
export const DeleteConditionStatusReportMediaDocument = gql`
  mutation DeleteConditionStatusReportMedia($input: DeleteConditionStatusReportMediaInput!) {
    deleteConditionStatusReportMedia(input: $input) {
      conditionStatusReport {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteConditionStatusReportMediaMutationFn = Apollo.MutationFunction<
  DeleteConditionStatusReportMediaMutation,
  DeleteConditionStatusReportMediaMutationVariables
>;

/**
 * __useDeleteConditionStatusReportMediaMutation__
 *
 * To run a mutation, you first call `useDeleteConditionStatusReportMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionStatusReportMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionStatusReportMediaMutation, { data, loading, error }] = useDeleteConditionStatusReportMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConditionStatusReportMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConditionStatusReportMediaMutation,
    DeleteConditionStatusReportMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConditionStatusReportMediaMutation,
    DeleteConditionStatusReportMediaMutationVariables
  >(DeleteConditionStatusReportMediaDocument, options);
}
export type DeleteConditionStatusReportMediaMutationHookResult = ReturnType<
  typeof useDeleteConditionStatusReportMediaMutation
>;
export type DeleteConditionStatusReportMediaMutationResult =
  Apollo.MutationResult<DeleteConditionStatusReportMediaMutation>;
export type DeleteConditionStatusReportMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteConditionStatusReportMediaMutation,
  DeleteConditionStatusReportMediaMutationVariables
>;
export const ExportConditionStatusReportPdfDocument = gql`
  mutation ExportConditionStatusReportPdf($input: ExportConditionStatusReportPDFInput!) {
    exportConditionStatusReportPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportConditionStatusReportPdfMutationFn = Apollo.MutationFunction<
  ExportConditionStatusReportPdfMutation,
  ExportConditionStatusReportPdfMutationVariables
>;

/**
 * __useExportConditionStatusReportPdfMutation__
 *
 * To run a mutation, you first call `useExportConditionStatusReportPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportConditionStatusReportPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportConditionStatusReportPdfMutation, { data, loading, error }] = useExportConditionStatusReportPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportConditionStatusReportPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportConditionStatusReportPdfMutation,
    ExportConditionStatusReportPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportConditionStatusReportPdfMutation,
    ExportConditionStatusReportPdfMutationVariables
  >(ExportConditionStatusReportPdfDocument, options);
}
export type ExportConditionStatusReportPdfMutationHookResult = ReturnType<
  typeof useExportConditionStatusReportPdfMutation
>;
export type ExportConditionStatusReportPdfMutationResult =
  Apollo.MutationResult<ExportConditionStatusReportPdfMutation>;
export type ExportConditionStatusReportPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportConditionStatusReportPdfMutation,
  ExportConditionStatusReportPdfMutationVariables
>;
export const GetAllEventLogsDocument = gql`
  query GetAllEventLogs {
    allEventLogs(orderBy: "-created_at") {
      edges {
        node {
          id
          eventType
          hideOwner
          commerciallyAvailable
          sold
          collectionPublicName
          locationDescription
          startDate
          endDate
          owner {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllEventLogsQuery__
 *
 * To run a query within a React component, call `useGetAllEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>(
    GetAllEventLogsDocument,
    options
  );
}
export function useGetAllEventLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>(
    GetAllEventLogsDocument,
    options
  );
}
export type GetAllEventLogsQueryHookResult = ReturnType<typeof useGetAllEventLogsQuery>;
export type GetAllEventLogsLazyQueryHookResult = ReturnType<typeof useGetAllEventLogsLazyQuery>;
export type GetAllEventLogsQueryResult = Apollo.QueryResult<
  GetAllEventLogsQuery,
  GetAllEventLogsQueryVariables
>;
export const GetEventLogByIdDocument = gql`
  query GetEventLogById($id: ID!) {
    eventLog(id: $id) {
      ...EventLogAnagraphicFields
    }
  }
  ${EventLogAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetEventLogByIdQuery__
 *
 * To run a query within a React component, call `useGetEventLogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLogByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>(
    GetEventLogByIdDocument,
    options
  );
}
export function useGetEventLogByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>(
    GetEventLogByIdDocument,
    options
  );
}
export type GetEventLogByIdQueryHookResult = ReturnType<typeof useGetEventLogByIdQuery>;
export type GetEventLogByIdLazyQueryHookResult = ReturnType<typeof useGetEventLogByIdLazyQuery>;
export type GetEventLogByIdQueryResult = Apollo.QueryResult<
  GetEventLogByIdQuery,
  GetEventLogByIdQueryVariables
>;
export const CreateEventLogDocument = gql`
  mutation CreateEventLog($input: CreateEventLogInput!) {
    createEventLog(input: $input) {
      eventLog {
        id
        eventType
        hideOwner
        commerciallyAvailable
        sold
        collectionPublicName
        locationDescription
        startDate
        endDate
        note
        acquisitionType
        actionType
        commission
        auctionDescription
        lotNumber
        sellingLocation
        value {
          amount
          currency {
            code
          }
        }
        minimumPrice {
          amount
          currency {
            code
          }
        }
        maximumPrice {
          amount
          currency {
            code
          }
        }
        reservePrice {
          amount
          currency {
            code
          }
        }
        auctionRights {
          amount
          currency {
            code
          }
        }
        owner {
          id
          fullName
        }
        origin {
          id
          fullName
        }
        host {
          id
          fullName
        }
        broker {
          id
          fullName
        }
        newOwner {
          id
          fullName
        }
        assetSet {
          edges {
            node {
              id
              title
              description
              author
              category
              genericDimensions
              creationDate
              mainImage
              actualDateAndPeriod
            }
          }
        }
        document
      }
    }
  }
`;
export type CreateEventLogMutationFn = Apollo.MutationFunction<
  CreateEventLogMutation,
  CreateEventLogMutationVariables
>;

/**
 * __useCreateEventLogMutation__
 *
 * To run a mutation, you first call `useCreateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventLogMutation, { data, loading, error }] = useCreateEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventLogMutation, CreateEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventLogMutation, CreateEventLogMutationVariables>(
    CreateEventLogDocument,
    options
  );
}
export type CreateEventLogMutationHookResult = ReturnType<typeof useCreateEventLogMutation>;
export type CreateEventLogMutationResult = Apollo.MutationResult<CreateEventLogMutation>;
export type CreateEventLogMutationOptions = Apollo.BaseMutationOptions<
  CreateEventLogMutation,
  CreateEventLogMutationVariables
>;
export const UpdateEventLogDocument = gql`
  mutation UpdateEventLog($input: UpdateEventLogInput!) {
    updateEventLog(input: $input) {
      eventLog {
        id
        eventType
        hideOwner
        commerciallyAvailable
        sold
        collectionPublicName
        locationDescription
        startDate
        endDate
        note
        acquisitionType
        actionType
        commission
        auctionDescription
        lotNumber
        sellingLocation
        value {
          amount
          currency {
            code
          }
        }
        minimumPrice {
          amount
          currency {
            code
          }
        }
        maximumPrice {
          amount
          currency {
            code
          }
        }
        reservePrice {
          amount
          currency {
            code
          }
        }
        auctionRights {
          amount
          currency {
            code
          }
        }
        externalId
        externalEntity
        owner {
          id
          fullName
        }
        origin {
          id
          fullName
        }
        host {
          id
          fullName
        }
        broker {
          id
          fullName
        }
        newOwner {
          id
          fullName
        }
        assetSet {
          edges {
            node {
              id
              title
              description
              author
              category
              genericDimensions
              creationDate
              mainImage
              actualDateAndPeriod
            }
          }
        }
        document
      }
    }
  }
`;
export type UpdateEventLogMutationFn = Apollo.MutationFunction<
  UpdateEventLogMutation,
  UpdateEventLogMutationVariables
>;

/**
 * __useUpdateEventLogMutation__
 *
 * To run a mutation, you first call `useUpdateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventLogMutation, { data, loading, error }] = useUpdateEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventLogMutation, UpdateEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventLogMutation, UpdateEventLogMutationVariables>(
    UpdateEventLogDocument,
    options
  );
}
export type UpdateEventLogMutationHookResult = ReturnType<typeof useUpdateEventLogMutation>;
export type UpdateEventLogMutationResult = Apollo.MutationResult<UpdateEventLogMutation>;
export type UpdateEventLogMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventLogMutation,
  UpdateEventLogMutationVariables
>;
export const DeleteEventLogDocument = gql`
  mutation DeleteEventLog($input: DeleteEventLogInput!) {
    deleteEventLog(input: $input) {
      deleteEventLog {
        id
      }
    }
  }
`;
export type DeleteEventLogMutationFn = Apollo.MutationFunction<
  DeleteEventLogMutation,
  DeleteEventLogMutationVariables
>;

/**
 * __useDeleteEventLogMutation__
 *
 * To run a mutation, you first call `useDeleteEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventLogMutation, { data, loading, error }] = useDeleteEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventLogMutation, DeleteEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventLogMutation, DeleteEventLogMutationVariables>(
    DeleteEventLogDocument,
    options
  );
}
export type DeleteEventLogMutationHookResult = ReturnType<typeof useDeleteEventLogMutation>;
export type DeleteEventLogMutationResult = Apollo.MutationResult<DeleteEventLogMutation>;
export type DeleteEventLogMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventLogMutation,
  DeleteEventLogMutationVariables
>;
export const GetEventLogByAssetIdDocument = gql`
  query GetEventLogByAssetId($id: ID!) {
    asset(id: $id) {
      eventLogs {
        edges {
          node {
            id
            eventType
            hideOwner
            commerciallyAvailable
            sold
            collectionPublicName
            locationDescription
            startDate
            endDate
            owner {
              id
              fullName
            }
            externalId
            externalEntity
            actionType
          }
        }
      }
    }
  }
`;

/**
 * __useGetEventLogByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetEventLogByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLogByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventLogByAssetIdQuery,
    GetEventLogByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventLogByAssetIdQuery, GetEventLogByAssetIdQueryVariables>(
    GetEventLogByAssetIdDocument,
    options
  );
}
export function useGetEventLogByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventLogByAssetIdQuery,
    GetEventLogByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventLogByAssetIdQuery, GetEventLogByAssetIdQueryVariables>(
    GetEventLogByAssetIdDocument,
    options
  );
}
export type GetEventLogByAssetIdQueryHookResult = ReturnType<typeof useGetEventLogByAssetIdQuery>;
export type GetEventLogByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetEventLogByAssetIdLazyQuery
>;
export type GetEventLogByAssetIdQueryResult = Apollo.QueryResult<
  GetEventLogByAssetIdQuery,
  GetEventLogByAssetIdQueryVariables
>;
export const GetAllExhibitionsDocument = gql`
  query GetAllExhibitions {
    allExhibitions(orderBy: "-created_at") {
      edges {
        node {
          id
          name
          description
          eventFrom
          eventTo
          notes
          permanent
          totalAssetsNumber
          actualTimingStatus
          createdBy {
            name
          }
          eventLocation {
            raw
          }
          eventLocationEntity {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllExhibitionsQuery__
 *
 * To run a query within a React component, call `useGetAllExhibitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExhibitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExhibitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllExhibitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>(
    GetAllExhibitionsDocument,
    options
  );
}
export function useGetAllExhibitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>(
    GetAllExhibitionsDocument,
    options
  );
}
export type GetAllExhibitionsQueryHookResult = ReturnType<typeof useGetAllExhibitionsQuery>;
export type GetAllExhibitionsLazyQueryHookResult = ReturnType<typeof useGetAllExhibitionsLazyQuery>;
export type GetAllExhibitionsQueryResult = Apollo.QueryResult<
  GetAllExhibitionsQuery,
  GetAllExhibitionsQueryVariables
>;
export const GetExhibitionByIdDocument = gql`
  query GetExhibitionById($id: ID!) {
    exhibition(id: $id) {
      ...ExhibitionAnagraphicFields
    }
  }
  ${ExhibitionAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetExhibitionByIdQuery__
 *
 * To run a query within a React component, call `useGetExhibitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExhibitionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(
    GetExhibitionByIdDocument,
    options
  );
}
export function useGetExhibitionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(
    GetExhibitionByIdDocument,
    options
  );
}
export type GetExhibitionByIdQueryHookResult = ReturnType<typeof useGetExhibitionByIdQuery>;
export type GetExhibitionByIdLazyQueryHookResult = ReturnType<typeof useGetExhibitionByIdLazyQuery>;
export type GetExhibitionByIdQueryResult = Apollo.QueryResult<
  GetExhibitionByIdQuery,
  GetExhibitionByIdQueryVariables
>;
export const CreateExhibitionDocument = gql`
  mutation CreateExhibition($input: CreateExhibitionInput!) {
    createExhibition(input: $input) {
      exhibition {
        id
        name
        description
        eventFrom
        eventTo
        notes
        permanent
        curator {
          id
          fullName
        }
        organizer {
          id
          fullName
        }
        hostEntity {
          id
          fullName
        }
      }
    }
  }
`;
export type CreateExhibitionMutationFn = Apollo.MutationFunction<
  CreateExhibitionMutation,
  CreateExhibitionMutationVariables
>;

/**
 * __useCreateExhibitionMutation__
 *
 * To run a mutation, you first call `useCreateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExhibitionMutation, { data, loading, error }] = useCreateExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExhibitionMutation,
    CreateExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExhibitionMutation, CreateExhibitionMutationVariables>(
    CreateExhibitionDocument,
    options
  );
}
export type CreateExhibitionMutationHookResult = ReturnType<typeof useCreateExhibitionMutation>;
export type CreateExhibitionMutationResult = Apollo.MutationResult<CreateExhibitionMutation>;
export type CreateExhibitionMutationOptions = Apollo.BaseMutationOptions<
  CreateExhibitionMutation,
  CreateExhibitionMutationVariables
>;
export const UpdateExhibitionDocument = gql`
  mutation UpdateExhibition($input: UpdateExhibitionInput!) {
    updateExhibition(input: $input) {
      exhibition {
        id
        name
        description
        eventFrom
        eventTo
        notes
        permanent
        curator {
          id
          fullName
        }
        organizer {
          id
          fullName
        }
        hostEntity {
          id
          fullName
        }
      }
    }
  }
`;
export type UpdateExhibitionMutationFn = Apollo.MutationFunction<
  UpdateExhibitionMutation,
  UpdateExhibitionMutationVariables
>;

/**
 * __useUpdateExhibitionMutation__
 *
 * To run a mutation, you first call `useUpdateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExhibitionMutation, { data, loading, error }] = useUpdateExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExhibitionMutation,
    UpdateExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>(
    UpdateExhibitionDocument,
    options
  );
}
export type UpdateExhibitionMutationHookResult = ReturnType<typeof useUpdateExhibitionMutation>;
export type UpdateExhibitionMutationResult = Apollo.MutationResult<UpdateExhibitionMutation>;
export type UpdateExhibitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateExhibitionMutation,
  UpdateExhibitionMutationVariables
>;
export const DeleteExhibitionDocument = gql`
  mutation DeleteExhibition($input: DeleteExhibitionInput!) {
    deleteExhibition(input: $input) {
      exhibition {
        id
      }
    }
  }
`;
export type DeleteExhibitionMutationFn = Apollo.MutationFunction<
  DeleteExhibitionMutation,
  DeleteExhibitionMutationVariables
>;

/**
 * __useDeleteExhibitionMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitionMutation, { data, loading, error }] = useDeleteExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExhibitionMutation,
    DeleteExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>(
    DeleteExhibitionDocument,
    options
  );
}
export type DeleteExhibitionMutationHookResult = ReturnType<typeof useDeleteExhibitionMutation>;
export type DeleteExhibitionMutationResult = Apollo.MutationResult<DeleteExhibitionMutation>;
export type DeleteExhibitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteExhibitionMutation,
  DeleteExhibitionMutationVariables
>;
export const DetachAssetFromExhibitionDocument = gql`
  mutation DetachAssetFromExhibition($input: DetachAssetFromExhibitionInput!) {
    detachAssetFromExhibition(input: $input) {
      exhibition {
        id
        assetSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetFromExhibitionMutationFn = Apollo.MutationFunction<
  DetachAssetFromExhibitionMutation,
  DetachAssetFromExhibitionMutationVariables
>;

/**
 * __useDetachAssetFromExhibitionMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromExhibitionMutation, { data, loading, error }] = useDetachAssetFromExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromExhibitionMutation,
    DetachAssetFromExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromExhibitionMutation,
    DetachAssetFromExhibitionMutationVariables
  >(DetachAssetFromExhibitionDocument, options);
}
export type DetachAssetFromExhibitionMutationHookResult = ReturnType<
  typeof useDetachAssetFromExhibitionMutation
>;
export type DetachAssetFromExhibitionMutationResult =
  Apollo.MutationResult<DetachAssetFromExhibitionMutation>;
export type DetachAssetFromExhibitionMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromExhibitionMutation,
  DetachAssetFromExhibitionMutationVariables
>;
export const ExportExhibitionPdfDocument = gql`
  mutation ExportExhibitionPdf($input: ExportExhibitionPDFInput!) {
    exportExhibitionPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportExhibitionPdfMutationFn = Apollo.MutationFunction<
  ExportExhibitionPdfMutation,
  ExportExhibitionPdfMutationVariables
>;

/**
 * __useExportExhibitionPdfMutation__
 *
 * To run a mutation, you first call `useExportExhibitionPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportExhibitionPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportExhibitionPdfMutation, { data, loading, error }] = useExportExhibitionPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportExhibitionPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportExhibitionPdfMutation,
    ExportExhibitionPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportExhibitionPdfMutation, ExportExhibitionPdfMutationVariables>(
    ExportExhibitionPdfDocument,
    options
  );
}
export type ExportExhibitionPdfMutationHookResult = ReturnType<
  typeof useExportExhibitionPdfMutation
>;
export type ExportExhibitionPdfMutationResult = Apollo.MutationResult<ExportExhibitionPdfMutation>;
export type ExportExhibitionPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportExhibitionPdfMutation,
  ExportExhibitionPdfMutationVariables
>;
export const CreateMediaDocument = gql`
  mutation CreateMedia($input: CreateMediaInput!) {
    createMedia(input: $input) {
      media {
        id
        name
        file
        description
        mediaType
        isMainImage
        imageType
        notes
        audioVideoFileType
        creationDate
        fileAuthor
        fileSize
        photographicEquipment
        matrixType
        isGlassPlexyAcetate
      }
    }
  }
`;
export type CreateMediaMutationFn = Apollo.MutationFunction<
  CreateMediaMutation,
  CreateMediaMutationVariables
>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(
    CreateMediaDocument,
    options
  );
}
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaMutation,
  CreateMediaMutationVariables
>;
export const UpdateMediaDocument = gql`
  mutation UpdateMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input) {
      media {
        id
        name
        file
        description
        mediaType
        isMainImage
        imageType
        notes
        audioVideoFileType
        creationDate
        fileAuthor
        fileSize
        photographicEquipment
        matrixType
        isGlassPlexyAcetate
      }
    }
  }
`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(
    UpdateMediaDocument,
    options
  );
}
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;
export const GetAllPoliciesDocument = gql`
  query GetAllPolicies {
    allPolicies(orderBy: "-created_at") {
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
          isExpired
          hasValidCertificate
          assetsCount
          createdBy {
            name
            userType
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export function useGetAllPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export type GetAllPoliciesQueryHookResult = ReturnType<typeof useGetAllPoliciesQuery>;
export type GetAllPoliciesLazyQueryHookResult = ReturnType<typeof useGetAllPoliciesLazyQuery>;
export type GetAllPoliciesQueryResult = Apollo.QueryResult<
  GetAllPoliciesQuery,
  GetAllPoliciesQueryVariables
>;
export const GetAllPoliciesByTypeDocument = gql`
  query GetAllPoliciesByType($policyType: String!) {
    allPolicies(policyType: $policyType, orderBy: "-created_at") {
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
          createdBy {
            name
            userType
          }
          isExpired
          assetsCount
          hasValidCertificate
          exhibition {
            name
          }
          eventEntity {
            fullName
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *   },
 * });
 */
export function useGetAllPoliciesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export function useGetAllPoliciesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export type GetAllPoliciesByTypeQueryHookResult = ReturnType<typeof useGetAllPoliciesByTypeQuery>;
export type GetAllPoliciesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPoliciesByTypeLazyQuery
>;
export type GetAllPoliciesByTypeQueryResult = Apollo.QueryResult<
  GetAllPoliciesByTypeQuery,
  GetAllPoliciesByTypeQueryVariables
>;
export const ViewPolicyByIdReducedDocument = gql`
  query ViewPolicyByIdReduced($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
`;

/**
 * __useViewPolicyByIdReducedQuery__
 *
 * To run a query within a React component, call `useViewPolicyByIdReducedQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPolicyByIdReducedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPolicyByIdReducedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewPolicyByIdReducedQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewPolicyByIdReducedQuery,
    ViewPolicyByIdReducedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewPolicyByIdReducedQuery, ViewPolicyByIdReducedQueryVariables>(
    ViewPolicyByIdReducedDocument,
    options
  );
}
export function useViewPolicyByIdReducedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewPolicyByIdReducedQuery,
    ViewPolicyByIdReducedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewPolicyByIdReducedQuery, ViewPolicyByIdReducedQueryVariables>(
    ViewPolicyByIdReducedDocument,
    options
  );
}
export type ViewPolicyByIdReducedQueryHookResult = ReturnType<typeof useViewPolicyByIdReducedQuery>;
export type ViewPolicyByIdReducedLazyQueryHookResult = ReturnType<
  typeof useViewPolicyByIdReducedLazyQuery
>;
export type ViewPolicyByIdReducedQueryResult = Apollo.QueryResult<
  ViewPolicyByIdReducedQuery,
  ViewPolicyByIdReducedQueryVariables
>;
export const ViewPolicyByIdDocument = gql`
  query ViewPolicyById($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
      ...policyMoneyFields
      autoRenewal
      broker {
        id
        fullName
      }
      insuranceCompany {
        id
        fullName
      }
      contractingParty {
        id
        fullName
      }
      eventName
      eventFrom
      eventTo
      eventEntity {
        id
        fullName
      }
      eventLocationEntity {
        id
        name
      }
      catNat
      hasVariableBasket
      coverTerrorism
      coverType
      coveredRiskType
      exemption
      createdAt
      updatedAt
      isExpired
      createdBy {
        id
        createdAt
        name
        email
      }
      updatedBy {
        id
        createdAt
        name
        email
      }
      policyAssets {
        edges {
          node {
            asset {
              id
              creationPeriod
              authorEntity {
                id
                fullName
              }
              title
              category
              genericDimensions
              technique
              inventoryNumber
              validated
              actualDateAndPeriod
              mainImage
              createdBy {
                name
              }
            }
            ...policyAssetFields
            certificateProgressiveNumber
            objectLocationEntity {
              id
              fullName
            }
            objectLocationOffice {
              id
              name
            }
          }
        }
      }
      policyNotes {
        edges {
          node {
            id
            content
          }
        }
      }
      policyDocuments {
        edges {
          node {
            id
            uuid
            createdAt
            createdBy {
              id
              name
            }
            file
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useViewPolicyByIdQuery__
 *
 * To run a query within a React component, call `useViewPolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPolicyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewPolicyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>(
    ViewPolicyByIdDocument,
    options
  );
}
export function useViewPolicyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>(
    ViewPolicyByIdDocument,
    options
  );
}
export type ViewPolicyByIdQueryHookResult = ReturnType<typeof useViewPolicyByIdQuery>;
export type ViewPolicyByIdLazyQueryHookResult = ReturnType<typeof useViewPolicyByIdLazyQuery>;
export type ViewPolicyByIdQueryResult = Apollo.QueryResult<
  ViewPolicyByIdQuery,
  ViewPolicyByIdQueryVariables
>;
export const GetPolicyByIdDocument = gql`
  query GetPolicyById($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
      ...policyMoneyFields
      autoRenewal
      broker {
        id
        fullName
      }
      insuranceCompany {
        id
        fullName
      }
      contractingParty {
        id
        fullName
      }
      eventName
      eventFrom
      eventTo
      eventEntity {
        id
        fullName
      }
      eventLocationEntity {
        id
        name
      }
      catNat
      hasVariableBasket
      coverTerrorism
      coverType
      coveredRiskType
      exemption
      isExpired
      policyAssets {
        edges {
          node {
            asset {
              id
              creationPeriod
              authorEntity {
                id
                fullName
              }
              title
              category
              genericDimensions
              technique
              inventoryNumber
              validated
              actualDateAndPeriod
              mainImage
              createdBy {
                name
              }
            }
            certificateProgressiveNumber
            ...policyAssetFields
            objectLocationEntity {
              id
              fullName
            }
            objectLocationOffice {
              id
              name
            }
          }
        }
      }
      policyNotes {
        edges {
          node {
            id
            content
          }
        }
      }
      policyDocuments {
        edges {
          node {
            id
            uuid
            file
            createdAt
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetPolicyByIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export function useGetPolicyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export type GetPolicyByIdQueryHookResult = ReturnType<typeof useGetPolicyByIdQuery>;
export type GetPolicyByIdLazyQueryHookResult = ReturnType<typeof useGetPolicyByIdLazyQuery>;
export type GetPolicyByIdQueryResult = Apollo.QueryResult<
  GetPolicyByIdQuery,
  GetPolicyByIdQueryVariables
>;
export const GetPolicyNotesByPolicyIdDocument = gql`
  query GetPolicyNotesByPolicyId($policyId: ID!) {
    allPolicyNotes(policy: $policyId) {
      edges {
        node {
          id
          content
        }
      }
    }
  }
`;

/**
 * __useGetPolicyNotesByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyNotesByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyNotesByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyNotesByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyNotesByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export function useGetPolicyNotesByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export type GetPolicyNotesByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdQuery
>;
export type GetPolicyNotesByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdLazyQuery
>;
export type GetPolicyNotesByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyNotesByPolicyIdQuery,
  GetPolicyNotesByPolicyIdQueryVariables
>;
export const GetPolicyDocumentsByPolicyIdDocument = gql`
  query GetPolicyDocumentsByPolicyId($policyId: ID!) {
    allPolicyDocuments(policy: $policyId) {
      edges {
        node {
          id
          file
          uuid
          createdBy {
            name
            id
          }
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetPolicyDocumentsByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyDocumentsByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDocumentsByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDocumentsByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyDocumentsByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export function useGetPolicyDocumentsByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export type GetPolicyDocumentsByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdQuery
>;
export type GetPolicyDocumentsByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdLazyQuery
>;
export type GetPolicyDocumentsByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyDocumentsByPolicyIdQuery,
  GetPolicyDocumentsByPolicyIdQueryVariables
>;
export const GetBasketHistoryByPolicyIdDocument = gql`
  query GetBasketHistoryByPolicyId($id: ID!) {
    policy(id: $id) {
      policybaskethistorySet {
        edges {
          node {
            createdAt
            createdBy {
              name
            }
            reason
            status
            uuid
            overrideChangeDate
            policyassetbaskethistorySet {
              edges {
                node {
                  asset {
                    title
                    author
                    authorEntity {
                      fullName
                    }
                  }
                  diff
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBasketHistoryByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetBasketHistoryByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasketHistoryByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasketHistoryByPolicyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasketHistoryByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasketHistoryByPolicyIdQuery, GetBasketHistoryByPolicyIdQueryVariables>(
    GetBasketHistoryByPolicyIdDocument,
    options
  );
}
export function useGetBasketHistoryByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >(GetBasketHistoryByPolicyIdDocument, options);
}
export type GetBasketHistoryByPolicyIdQueryHookResult = ReturnType<
  typeof useGetBasketHistoryByPolicyIdQuery
>;
export type GetBasketHistoryByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetBasketHistoryByPolicyIdLazyQuery
>;
export type GetBasketHistoryByPolicyIdQueryResult = Apollo.QueryResult<
  GetBasketHistoryByPolicyIdQuery,
  GetBasketHistoryByPolicyIdQueryVariables
>;
export const CreatePolicyDocument = gql`
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type CreatePolicyMutationFn = Apollo.MutationFunction<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyMutation, CreatePolicyMutationVariables>(
    CreatePolicyDocument,
    options
  );
}
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<CreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;
export const CreatePolicyAssetDocument = gql`
  mutation CreatePolicyAsset($input: CreatePolicyAssetInput!) {
    createPolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type CreatePolicyAssetMutationFn = Apollo.MutationFunction<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;

/**
 * __useCreatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useCreatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyAssetMutation, { data, loading, error }] = useCreatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyAssetMutation,
    CreatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyAssetMutation, CreatePolicyAssetMutationVariables>(
    CreatePolicyAssetDocument,
    options
  );
}
export type CreatePolicyAssetMutationHookResult = ReturnType<typeof useCreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationResult = Apollo.MutationResult<CreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;
export const CreatePolicyNoteDocument = gql`
  mutation CreatePolicyNote($input: CreatePolicyNoteInput!) {
    createPolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type CreatePolicyNoteMutationFn = Apollo.MutationFunction<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;

/**
 * __useCreatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useCreatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyNoteMutation, { data, loading, error }] = useCreatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyNoteMutation,
    CreatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyNoteMutation, CreatePolicyNoteMutationVariables>(
    CreatePolicyNoteDocument,
    options
  );
}
export type CreatePolicyNoteMutationHookResult = ReturnType<typeof useCreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationResult = Apollo.MutationResult<CreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;
export const CreatePolicyDocumentDocument = gql`
  mutation CreatePolicyDocument($input: CreatePolicyDocumentInput!) {
    createPolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type CreatePolicyDocumentMutationFn = Apollo.MutationFunction<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;

/**
 * __useCreatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyDocumentMutation, { data, loading, error }] = useCreatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyDocumentMutation,
    CreatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyDocumentMutation, CreatePolicyDocumentMutationVariables>(
    CreatePolicyDocumentDocument,
    options
  );
}
export type CreatePolicyDocumentMutationHookResult = ReturnType<
  typeof useCreatePolicyDocumentMutation
>;
export type CreatePolicyDocumentMutationResult =
  Apollo.MutationResult<CreatePolicyDocumentMutation>;
export type CreatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;
export const UpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type UpdatePolicyMutationFn = Apollo.MutationFunction<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>(
    UpdatePolicyDocument,
    options
  );
}
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = Apollo.MutationResult<UpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;
export const RenewPolicyDocument = gql`
  mutation RenewPolicy($input: RenewPolicyInput!) {
    renewPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type RenewPolicyMutationFn = Apollo.MutationFunction<
  RenewPolicyMutation,
  RenewPolicyMutationVariables
>;

/**
 * __useRenewPolicyMutation__
 *
 * To run a mutation, you first call `useRenewPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewPolicyMutation, { data, loading, error }] = useRenewPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenewPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<RenewPolicyMutation, RenewPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenewPolicyMutation, RenewPolicyMutationVariables>(
    RenewPolicyDocument,
    options
  );
}
export type RenewPolicyMutationHookResult = ReturnType<typeof useRenewPolicyMutation>;
export type RenewPolicyMutationResult = Apollo.MutationResult<RenewPolicyMutation>;
export type RenewPolicyMutationOptions = Apollo.BaseMutationOptions<
  RenewPolicyMutation,
  RenewPolicyMutationVariables
>;
export const ExtendPolicyDocument = gql`
  mutation ExtendPolicy($input: ExtendPolicyInput!) {
    extendPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type ExtendPolicyMutationFn = Apollo.MutationFunction<
  ExtendPolicyMutation,
  ExtendPolicyMutationVariables
>;

/**
 * __useExtendPolicyMutation__
 *
 * To run a mutation, you first call `useExtendPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendPolicyMutation, { data, loading, error }] = useExtendPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtendPolicyMutation, ExtendPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendPolicyMutation, ExtendPolicyMutationVariables>(
    ExtendPolicyDocument,
    options
  );
}
export type ExtendPolicyMutationHookResult = ReturnType<typeof useExtendPolicyMutation>;
export type ExtendPolicyMutationResult = Apollo.MutationResult<ExtendPolicyMutation>;
export type ExtendPolicyMutationOptions = Apollo.BaseMutationOptions<
  ExtendPolicyMutation,
  ExtendPolicyMutationVariables
>;
export const TerminatePolicyDocument = gql`
  mutation TerminatePolicy($input: TerminatePolicyInput!) {
    terminatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type TerminatePolicyMutationFn = Apollo.MutationFunction<
  TerminatePolicyMutation,
  TerminatePolicyMutationVariables
>;

/**
 * __useTerminatePolicyMutation__
 *
 * To run a mutation, you first call `useTerminatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminatePolicyMutation, { data, loading, error }] = useTerminatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminatePolicyMutation,
    TerminatePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TerminatePolicyMutation, TerminatePolicyMutationVariables>(
    TerminatePolicyDocument,
    options
  );
}
export type TerminatePolicyMutationHookResult = ReturnType<typeof useTerminatePolicyMutation>;
export type TerminatePolicyMutationResult = Apollo.MutationResult<TerminatePolicyMutation>;
export type TerminatePolicyMutationOptions = Apollo.BaseMutationOptions<
  TerminatePolicyMutation,
  TerminatePolicyMutationVariables
>;
export const DuplicatePolicyDocument = gql`
  mutation DuplicatePolicy($input: DuplicatePolicyInput!) {
    duplicatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type DuplicatePolicyMutationFn = Apollo.MutationFunction<
  DuplicatePolicyMutation,
  DuplicatePolicyMutationVariables
>;

/**
 * __useDuplicatePolicyMutation__
 *
 * To run a mutation, you first call `useDuplicatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePolicyMutation, { data, loading, error }] = useDuplicatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicatePolicyMutation,
    DuplicatePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicatePolicyMutation, DuplicatePolicyMutationVariables>(
    DuplicatePolicyDocument,
    options
  );
}
export type DuplicatePolicyMutationHookResult = ReturnType<typeof useDuplicatePolicyMutation>;
export type DuplicatePolicyMutationResult = Apollo.MutationResult<DuplicatePolicyMutation>;
export type DuplicatePolicyMutationOptions = Apollo.BaseMutationOptions<
  DuplicatePolicyMutation,
  DuplicatePolicyMutationVariables
>;
export const UpdatePolicyAssetDocument = gql`
  mutation UpdatePolicyAsset($input: UpdatePolicyAssetInput!) {
    updatePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type UpdatePolicyAssetMutationFn = Apollo.MutationFunction<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;

/**
 * __useUpdatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAssetMutation, { data, loading, error }] = useUpdatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyAssetMutation,
    UpdatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyAssetMutation, UpdatePolicyAssetMutationVariables>(
    UpdatePolicyAssetDocument,
    options
  );
}
export type UpdatePolicyAssetMutationHookResult = ReturnType<typeof useUpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationResult = Apollo.MutationResult<UpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;
export const UpdatePolicyAssetGroupDocument = gql`
  mutation UpdatePolicyAssetGroup($input: UpdatePolicyAssetGroupInput!) {
    updatePolicyAssetGroup(input: $input) {
      policyAssets {
        id
      }
    }
  }
`;
export type UpdatePolicyAssetGroupMutationFn = Apollo.MutationFunction<
  UpdatePolicyAssetGroupMutation,
  UpdatePolicyAssetGroupMutationVariables
>;

/**
 * __useUpdatePolicyAssetGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAssetGroupMutation, { data, loading, error }] = useUpdatePolicyAssetGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAssetGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyAssetGroupMutation,
    UpdatePolicyAssetGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePolicyAssetGroupMutation,
    UpdatePolicyAssetGroupMutationVariables
  >(UpdatePolicyAssetGroupDocument, options);
}
export type UpdatePolicyAssetGroupMutationHookResult = ReturnType<
  typeof useUpdatePolicyAssetGroupMutation
>;
export type UpdatePolicyAssetGroupMutationResult =
  Apollo.MutationResult<UpdatePolicyAssetGroupMutation>;
export type UpdatePolicyAssetGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAssetGroupMutation,
  UpdatePolicyAssetGroupMutationVariables
>;
export const UpdatePolicyNoteDocument = gql`
  mutation UpdatePolicyNote($input: UpdatePolicyNoteInput!) {
    updatePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type UpdatePolicyNoteMutationFn = Apollo.MutationFunction<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;

/**
 * __useUpdatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyNoteMutation, { data, loading, error }] = useUpdatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyNoteMutation,
    UpdatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyNoteMutation, UpdatePolicyNoteMutationVariables>(
    UpdatePolicyNoteDocument,
    options
  );
}
export type UpdatePolicyNoteMutationHookResult = ReturnType<typeof useUpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationResult = Apollo.MutationResult<UpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;
export const UpdatePolicyDocumentDocument = gql`
  mutation UpdatePolicyDocument($input: UpdatePolicyDocumentInput!) {
    updatePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type UpdatePolicyDocumentMutationFn = Apollo.MutationFunction<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;

/**
 * __useUpdatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyDocumentMutation, { data, loading, error }] = useUpdatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyDocumentMutation,
    UpdatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyDocumentMutation, UpdatePolicyDocumentMutationVariables>(
    UpdatePolicyDocumentDocument,
    options
  );
}
export type UpdatePolicyDocumentMutationHookResult = ReturnType<
  typeof useUpdatePolicyDocumentMutation
>;
export type UpdatePolicyDocumentMutationResult =
  Apollo.MutationResult<UpdatePolicyDocumentMutation>;
export type UpdatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;
export const DeletePolicyDocument = gql`
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type DeletePolicyMutationFn = Apollo.MutationFunction<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePolicyMutation, DeletePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyMutation, DeletePolicyMutationVariables>(
    DeletePolicyDocument,
    options
  );
}
export type DeletePolicyMutationHookResult = ReturnType<typeof useDeletePolicyMutation>;
export type DeletePolicyMutationResult = Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;
export const DeletePolicyAssetDocument = gql`
  mutation DeletePolicyAsset($input: DeletePolicyAssetInput!) {
    deletePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type DeletePolicyAssetMutationFn = Apollo.MutationFunction<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;

/**
 * __useDeletePolicyAssetMutation__
 *
 * To run a mutation, you first call `useDeletePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyAssetMutation, { data, loading, error }] = useDeletePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyAssetMutation,
    DeletePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyAssetMutation, DeletePolicyAssetMutationVariables>(
    DeletePolicyAssetDocument,
    options
  );
}
export type DeletePolicyAssetMutationHookResult = ReturnType<typeof useDeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationResult = Apollo.MutationResult<DeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;
export const DeletePolicyNoteDocument = gql`
  mutation DeletePolicyNote($input: DeletePolicyNoteInput!) {
    deletePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type DeletePolicyNoteMutationFn = Apollo.MutationFunction<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;

/**
 * __useDeletePolicyNoteMutation__
 *
 * To run a mutation, you first call `useDeletePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyNoteMutation, { data, loading, error }] = useDeletePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyNoteMutation,
    DeletePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyNoteMutation, DeletePolicyNoteMutationVariables>(
    DeletePolicyNoteDocument,
    options
  );
}
export type DeletePolicyNoteMutationHookResult = ReturnType<typeof useDeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationResult = Apollo.MutationResult<DeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;
export const DeletePolicyDocumentDocument = gql`
  mutation DeletePolicyDocument($input: DeletePolicyDocumentInput!) {
    deletePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type DeletePolicyDocumentMutationFn = Apollo.MutationFunction<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;

/**
 * __useDeletePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useDeletePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyDocumentMutation, { data, loading, error }] = useDeletePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyDocumentMutation,
    DeletePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyDocumentMutation, DeletePolicyDocumentMutationVariables>(
    DeletePolicyDocumentDocument,
    options
  );
}
export type DeletePolicyDocumentMutationHookResult = ReturnType<
  typeof useDeletePolicyDocumentMutation
>;
export type DeletePolicyDocumentMutationResult =
  Apollo.MutationResult<DeletePolicyDocumentMutation>;
export type DeletePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;
export const GenerateCertificateDocument = gql`
  mutation GenerateCertificate($input: GenerateCertificateInput!) {
    generateCertificate(input: $input) {
      s3Load
      filesUrl
    }
  }
`;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCertificateMutation,
    GenerateCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(
    GenerateCertificateDocument,
    options
  );
}
export type GenerateCertificateMutationHookResult = ReturnType<
  typeof useGenerateCertificateMutation
>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;
export const DeleteCertificateDocument = gql`
  mutation DeleteCertificate($input: DeleteCertificateInput!) {
    deleteCertificate(input: $input) {
      certificate {
        id
      }
    }
  }
`;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;

/**
 * __useDeleteCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateMutation, { data, loading, error }] = useDeleteCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateMutation,
    DeleteCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCertificateMutation, DeleteCertificateMutationVariables>(
    DeleteCertificateDocument,
    options
  );
}
export type DeleteCertificateMutationHookResult = ReturnType<typeof useDeleteCertificateMutation>;
export type DeleteCertificateMutationResult = Apollo.MutationResult<DeleteCertificateMutation>;
export type DeleteCertificateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;
export const SignCertificateDocument = gql`
  mutation SignCertificate($input: SignCertificateInput!) {
    signCertificate(input: $input) {
      certificate {
        file
        signed
        signedAt
        signedBy {
          name
        }
      }
    }
  }
`;
export type SignCertificateMutationFn = Apollo.MutationFunction<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;

/**
 * __useSignCertificateMutation__
 *
 * To run a mutation, you first call `useSignCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signCertificateMutation, { data, loading, error }] = useSignCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignCertificateMutation,
    SignCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignCertificateMutation, SignCertificateMutationVariables>(
    SignCertificateDocument,
    options
  );
}
export type SignCertificateMutationHookResult = ReturnType<typeof useSignCertificateMutation>;
export type SignCertificateMutationResult = Apollo.MutationResult<SignCertificateMutation>;
export type SignCertificateMutationOptions = Apollo.BaseMutationOptions<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;
export const GetAllCertificatesByPolicyIdAndIsActiveDocument = gql`
  query GetAllCertificatesByPolicyIdAndIsActive($policy: ID!, $isValid: Boolean!) {
    allCertificates(policy: $policy, isValid: $isValid, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          uuid
          creationDate
          isValid
          language
          progressiveNumber
          createdBy {
            id
            name
          }
          createdAt
          signed
          signedBy {
            id
            name
          }
          metafields
          isSplitted
          signedAt
          lender
          lenderCountry
          policyAssetsCount
          totalInsuredValue {
            amount
            currency {
              code
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByPolicyIdAndIsActiveQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByPolicyIdAndIsActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByPolicyIdAndIsActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
 *   variables: {
 *      policy: // value for 'policy'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useGetAllCertificatesByPolicyIdAndIsActiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export function useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export type GetAllCertificatesByPolicyIdAndIsActiveQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveQueryResult = Apollo.QueryResult<
  GetAllCertificatesByPolicyIdAndIsActiveQuery,
  GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
>;
export const GetAllCertificatesByPolicyIdDocument = gql`
  query GetAllCertificatesByPolicyId($policy: ID!) {
    allCertificates(policy: $policy, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          uuid
          creationDate
          isValid
          language
          createdBy {
            id
            name
          }
          createdAt
          signed
          signedBy {
            id
            name
          }
          progressiveNumber
          metafields
          isSplitted
          signedAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByPolicyIdQuery({
 *   variables: {
 *      policy: // value for 'policy'
 *   },
 * });
 */
export function useGetAllCertificatesByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >(GetAllCertificatesByPolicyIdDocument, options);
}
export function useGetAllCertificatesByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >(GetAllCertificatesByPolicyIdDocument, options);
}
export type GetAllCertificatesByPolicyIdQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdQuery
>;
export type GetAllCertificatesByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdLazyQuery
>;
export type GetAllCertificatesByPolicyIdQueryResult = Apollo.QueryResult<
  GetAllCertificatesByPolicyIdQuery,
  GetAllCertificatesByPolicyIdQueryVariables
>;
export const GetAllCertificatesByTypeDocument = gql`
  query GetAllCertificatesByType($policyType: String!) {
    allCertificates(certificateType: $policyType, orderBy: "-created_at") {
      edges {
        node {
          id
          uuid
          certificateType
          file
          isValid
          language
          updatedAt
          createdAt
          progressiveNumber
          creationDate
          lenderCountry
          mainOfficeCountry
          policy {
            offerCode
            policyCode
            broker {
              fullName
            }
            isExpired
            insuranceCompany {
              fullName
            }
            contractingParty {
              fullName
            }
            exhibition {
              name
            }
            eventLocationEntity {
              name
            }
            totalInsuredValue {
              amount
              currency {
                code
              }
            }
          }
          lender
          shipper
          signed
          isValid
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *   },
 * });
 */
export function useGetAllCertificatesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export function useGetAllCertificatesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export type GetAllCertificatesByTypeQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeQuery
>;
export type GetAllCertificatesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeLazyQuery
>;
export type GetAllCertificatesByTypeQueryResult = Apollo.QueryResult<
  GetAllCertificatesByTypeQuery,
  GetAllCertificatesByTypeQueryVariables
>;
export const GetAllCertificateTemplatesByNameDocument = gql`
  query GetAllCertificateTemplatesByName($name: String!) {
    allCertificateTemplates(name: $name, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesByNameQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAllCertificateTemplatesByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export function useGetAllCertificateTemplatesByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export type GetAllCertificateTemplatesByNameQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameQuery
>;
export type GetAllCertificateTemplatesByNameLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameLazyQuery
>;
export type GetAllCertificateTemplatesByNameQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesByNameQuery,
  GetAllCertificateTemplatesByNameQueryVariables
>;
export const GetAllPolicyAssetsDocument = gql`
  query GetAllPolicyAssets {
    allPolicyAssets(orderBy: "-created_at") {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
          }
          ...policyAssetFields
          policy {
            offerCode
            policyCode
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPolicyAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export function useGetAllPolicyAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsQuery,
    GetAllPolicyAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export type GetAllPolicyAssetsQueryHookResult = ReturnType<typeof useGetAllPolicyAssetsQuery>;
export type GetAllPolicyAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsLazyQuery
>;
export type GetAllPolicyAssetsQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsQuery,
  GetAllPolicyAssetsQueryVariables
>;
export const GetAllPolicyAssetsByPolicyTypeDocument = gql`
  query GetAllPolicyAssetsByPolicyType($policy_PolicyType: String!) {
    allPolicyAssets(policy_PolicyType: $policy_PolicyType, orderBy: "-created_at") {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
            category
            genericDimensions
            technique
            inventoryNumber
            validated
            actualDateAndPeriod
            mainImage
            createdBy {
              name
            }
          }
          ...policyAssetFields
          policy {
            id
            offerCode
            policyCode
          }
          objectLocationEntity {
            fullName
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsByPolicyTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsByPolicyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsByPolicyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsByPolicyTypeQuery({
 *   variables: {
 *      policy_PolicyType: // value for 'policy_PolicyType'
 *   },
 * });
 */
export function useGetAllPolicyAssetsByPolicyTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export function useGetAllPolicyAssetsByPolicyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export type GetAllPolicyAssetsByPolicyTypeQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeQuery
>;
export type GetAllPolicyAssetsByPolicyTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeLazyQuery
>;
export type GetAllPolicyAssetsByPolicyTypeQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsByPolicyTypeQuery,
  GetAllPolicyAssetsByPolicyTypeQueryVariables
>;
export const GetLastPresentValueByAssetIdDocument = gql`
  query GetLastPresentValueByAssetId($id: ID!) {
    asset(id: $id) {
      presentValues(last: 1) {
        edges {
          node {
            amountCurrency
            amount {
              amount
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLastPresentValueByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetLastPresentValueByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPresentValueByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPresentValueByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLastPresentValueByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export function useGetLastPresentValueByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export type GetLastPresentValueByAssetIdQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdQuery
>;
export type GetLastPresentValueByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdLazyQuery
>;
export type GetLastPresentValueByAssetIdQueryResult = Apollo.QueryResult<
  GetLastPresentValueByAssetIdQuery,
  GetLastPresentValueByAssetIdQueryVariables
>;
export const GetAssetCarryingValueDocument = gql`
  query GetAssetCarryingValue($id: ID!) {
    asset(id: $id) {
      carryingValueAmount {
        amount
      }
      carryingValueAmountCurrency
    }
  }
`;

/**
 * __useGetAssetCarryingValueQuery__
 *
 * To run a query within a React component, call `useGetAssetCarryingValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetCarryingValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetCarryingValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetCarryingValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export function useGetAssetCarryingValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export type GetAssetCarryingValueQueryHookResult = ReturnType<typeof useGetAssetCarryingValueQuery>;
export type GetAssetCarryingValueLazyQueryHookResult = ReturnType<
  typeof useGetAssetCarryingValueLazyQuery
>;
export type GetAssetCarryingValueQueryResult = Apollo.QueryResult<
  GetAssetCarryingValueQuery,
  GetAssetCarryingValueQueryVariables
>;
export const GenerateSplitCertificateDocument = gql`
  mutation GenerateSplitCertificate($input: GenerateSplitCertificateInput!) {
    generateSplitCertificate(input: $input) {
      filesUrl
    }
  }
`;
export type GenerateSplitCertificateMutationFn = Apollo.MutationFunction<
  GenerateSplitCertificateMutation,
  GenerateSplitCertificateMutationVariables
>;

/**
 * __useGenerateSplitCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateSplitCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSplitCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSplitCertificateMutation, { data, loading, error }] = useGenerateSplitCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSplitCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSplitCertificateMutation,
    GenerateSplitCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSplitCertificateMutation,
    GenerateSplitCertificateMutationVariables
  >(GenerateSplitCertificateDocument, options);
}
export type GenerateSplitCertificateMutationHookResult = ReturnType<
  typeof useGenerateSplitCertificateMutation
>;
export type GenerateSplitCertificateMutationResult =
  Apollo.MutationResult<GenerateSplitCertificateMutation>;
export type GenerateSplitCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateSplitCertificateMutation,
  GenerateSplitCertificateMutationVariables
>;
export const GenerateGroupedCertificateDocument = gql`
  mutation GenerateGroupedCertificate($input: GenerateGroupedCertificateInput!) {
    generateGroupedCertificate(input: $input) {
      filesUrl
    }
  }
`;
export type GenerateGroupedCertificateMutationFn = Apollo.MutationFunction<
  GenerateGroupedCertificateMutation,
  GenerateGroupedCertificateMutationVariables
>;

/**
 * __useGenerateGroupedCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateGroupedCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGroupedCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGroupedCertificateMutation, { data, loading, error }] = useGenerateGroupedCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateGroupedCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateGroupedCertificateMutation,
    GenerateGroupedCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateGroupedCertificateMutation,
    GenerateGroupedCertificateMutationVariables
  >(GenerateGroupedCertificateDocument, options);
}
export type GenerateGroupedCertificateMutationHookResult = ReturnType<
  typeof useGenerateGroupedCertificateMutation
>;
export type GenerateGroupedCertificateMutationResult =
  Apollo.MutationResult<GenerateGroupedCertificateMutation>;
export type GenerateGroupedCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateGroupedCertificateMutation,
  GenerateGroupedCertificateMutationVariables
>;
export const GetAllCertificateTemplatesDocument = gql`
  query GetAllCertificateTemplates {
    allCertificateTemplates(orderBy: "-created_at") {
      edges {
        node {
          id
          file
          name
          uuid
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCertificateTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCertificateTemplatesQuery, GetAllCertificateTemplatesQueryVariables>(
    GetAllCertificateTemplatesDocument,
    options
  );
}
export function useGetAllCertificateTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >(GetAllCertificateTemplatesDocument, options);
}
export type GetAllCertificateTemplatesQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesQuery
>;
export type GetAllCertificateTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesLazyQuery
>;
export type GetAllCertificateTemplatesQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesQuery,
  GetAllCertificateTemplatesQueryVariables
>;
export const CreateCertificateTemplateDocument = gql`
  mutation CreateCertificateTemplate($input: CreateCertificateTemplateInput!) {
    createCertificateTemplate(input: $input) {
      certificateTemplate {
        id
      }
    }
  }
`;
export type CreateCertificateTemplateMutationFn = Apollo.MutationFunction<
  CreateCertificateTemplateMutation,
  CreateCertificateTemplateMutationVariables
>;

/**
 * __useCreateCertificateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateCertificateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificateTemplateMutation, { data, loading, error }] = useCreateCertificateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertificateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCertificateTemplateMutation,
    CreateCertificateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCertificateTemplateMutation,
    CreateCertificateTemplateMutationVariables
  >(CreateCertificateTemplateDocument, options);
}
export type CreateCertificateTemplateMutationHookResult = ReturnType<
  typeof useCreateCertificateTemplateMutation
>;
export type CreateCertificateTemplateMutationResult =
  Apollo.MutationResult<CreateCertificateTemplateMutation>;
export type CreateCertificateTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateCertificateTemplateMutation,
  CreateCertificateTemplateMutationVariables
>;
export const DeleteCertificateTemplateDocument = gql`
  mutation DeleteCertificateTemplate($input: DeleteCertificateTemplateInput!) {
    deleteCertificateTemplate(input: $input) {
      certificateTemplate {
        id
      }
    }
  }
`;
export type DeleteCertificateTemplateMutationFn = Apollo.MutationFunction<
  DeleteCertificateTemplateMutation,
  DeleteCertificateTemplateMutationVariables
>;

/**
 * __useDeleteCertificateTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateTemplateMutation, { data, loading, error }] = useDeleteCertificateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateTemplateMutation,
    DeleteCertificateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCertificateTemplateMutation,
    DeleteCertificateTemplateMutationVariables
  >(DeleteCertificateTemplateDocument, options);
}
export type DeleteCertificateTemplateMutationHookResult = ReturnType<
  typeof useDeleteCertificateTemplateMutation
>;
export type DeleteCertificateTemplateMutationResult =
  Apollo.MutationResult<DeleteCertificateTemplateMutation>;
export type DeleteCertificateTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateTemplateMutation,
  DeleteCertificateTemplateMutationVariables
>;
export const GetPolicyDiffChangesDocument = gql`
  query GetPolicyDiffChanges($id: ID!) {
    policy(id: $id) {
      diffChanges {
        changeReason
        changedAt
        field
        old
        new
        historyId
      }
      history {
        historyId
        historyChangeReason
        historyDate
      }
      ...policyCoreFields
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
`;

/**
 * __useGetPolicyDiffChangesQuery__
 *
 * To run a query within a React component, call `useGetPolicyDiffChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDiffChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDiffChangesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyDiffChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyDiffChangesQuery,
    GetPolicyDiffChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyDiffChangesQuery, GetPolicyDiffChangesQueryVariables>(
    GetPolicyDiffChangesDocument,
    options
  );
}
export function useGetPolicyDiffChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyDiffChangesQuery,
    GetPolicyDiffChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyDiffChangesQuery, GetPolicyDiffChangesQueryVariables>(
    GetPolicyDiffChangesDocument,
    options
  );
}
export type GetPolicyDiffChangesQueryHookResult = ReturnType<typeof useGetPolicyDiffChangesQuery>;
export type GetPolicyDiffChangesLazyQueryHookResult = ReturnType<
  typeof useGetPolicyDiffChangesLazyQuery
>;
export type GetPolicyDiffChangesQueryResult = Apollo.QueryResult<
  GetPolicyDiffChangesQuery,
  GetPolicyDiffChangesQueryVariables
>;
export const GetAllRegistriesDocument = gql`
  query GetAllRegistries {
    allRegistries(orderBy: "-created_at") {
      edges {
        node {
          ...RegistryFindingFields
          mainOfficeAddress
          role
          actualCompanyContactsNumber
          isAuthor
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export function useGetAllRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export type GetAllRegistriesQueryHookResult = ReturnType<typeof useGetAllRegistriesQuery>;
export type GetAllRegistriesLazyQueryHookResult = ReturnType<typeof useGetAllRegistriesLazyQuery>;
export type GetAllRegistriesQueryResult = Apollo.QueryResult<
  GetAllRegistriesQuery,
  GetAllRegistriesQueryVariables
>;
export const GetUniqueAnonymousRegistryDocument = gql`
  query GetUniqueAnonymousRegistry {
    allRegistries(isAnonymousTenantDefault: true) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetUniqueAnonymousRegistryQuery__
 *
 * To run a query within a React component, call `useGetUniqueAnonymousRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueAnonymousRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueAnonymousRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueAnonymousRegistryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUniqueAnonymousRegistryQuery, GetUniqueAnonymousRegistryQueryVariables>(
    GetUniqueAnonymousRegistryDocument,
    options
  );
}
export function useGetUniqueAnonymousRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >(GetUniqueAnonymousRegistryDocument, options);
}
export type GetUniqueAnonymousRegistryQueryHookResult = ReturnType<
  typeof useGetUniqueAnonymousRegistryQuery
>;
export type GetUniqueAnonymousRegistryLazyQueryHookResult = ReturnType<
  typeof useGetUniqueAnonymousRegistryLazyQuery
>;
export type GetUniqueAnonymousRegistryQueryResult = Apollo.QueryResult<
  GetUniqueAnonymousRegistryQuery,
  GetUniqueAnonymousRegistryQueryVariables
>;
export const GetUniqueUnknownRegistryDocument = gql`
  query GetUniqueUnknownRegistry {
    allRegistries(isUnknownTenantDefault: true) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetUniqueUnknownRegistryQuery__
 *
 * To run a query within a React component, call `useGetUniqueUnknownRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueUnknownRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueUnknownRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueUnknownRegistryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUniqueUnknownRegistryQuery,
    GetUniqueUnknownRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUniqueUnknownRegistryQuery, GetUniqueUnknownRegistryQueryVariables>(
    GetUniqueUnknownRegistryDocument,
    options
  );
}
export function useGetUniqueUnknownRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueUnknownRegistryQuery,
    GetUniqueUnknownRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUniqueUnknownRegistryQuery, GetUniqueUnknownRegistryQueryVariables>(
    GetUniqueUnknownRegistryDocument,
    options
  );
}
export type GetUniqueUnknownRegistryQueryHookResult = ReturnType<
  typeof useGetUniqueUnknownRegistryQuery
>;
export type GetUniqueUnknownRegistryLazyQueryHookResult = ReturnType<
  typeof useGetUniqueUnknownRegistryLazyQuery
>;
export type GetUniqueUnknownRegistryQueryResult = Apollo.QueryResult<
  GetUniqueUnknownRegistryQuery,
  GetUniqueUnknownRegistryQueryVariables
>;
export const GetAllRegistriesAuthorsDocument = gql`
  query GetAllRegistriesAuthors {
    allRegistries(orderBy: "-created_at", isAuthor: true) {
      edges {
        node {
          ...RegistryFindingFields
          alias
          birthDate
          deathDate
          authorType
          authoredAssetsCount
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesAuthorsQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesAuthorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesAuthorsQuery,
    GetAllRegistriesAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesAuthorsQuery, GetAllRegistriesAuthorsQueryVariables>(
    GetAllRegistriesAuthorsDocument,
    options
  );
}
export function useGetAllRegistriesAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesAuthorsQuery,
    GetAllRegistriesAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesAuthorsQuery, GetAllRegistriesAuthorsQueryVariables>(
    GetAllRegistriesAuthorsDocument,
    options
  );
}
export type GetAllRegistriesAuthorsQueryHookResult = ReturnType<
  typeof useGetAllRegistriesAuthorsQuery
>;
export type GetAllRegistriesAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesAuthorsLazyQuery
>;
export type GetAllRegistriesAuthorsQueryResult = Apollo.QueryResult<
  GetAllRegistriesAuthorsQuery,
  GetAllRegistriesAuthorsQueryVariables
>;
export const GetAllRegistriesPeopleDocument = gql`
  query GetAllRegistriesPeople {
    allRegistries(orderBy: "-created_at", isCompany: false) {
      edges {
        node {
          ...RegistryFindingFields
          role
          mainOfficeAddress
          actualCompanyContactsNumber
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesPeopleQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesPeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesPeopleQuery,
    GetAllRegistriesPeopleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesPeopleQuery, GetAllRegistriesPeopleQueryVariables>(
    GetAllRegistriesPeopleDocument,
    options
  );
}
export function useGetAllRegistriesPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesPeopleQuery,
    GetAllRegistriesPeopleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesPeopleQuery, GetAllRegistriesPeopleQueryVariables>(
    GetAllRegistriesPeopleDocument,
    options
  );
}
export type GetAllRegistriesPeopleQueryHookResult = ReturnType<
  typeof useGetAllRegistriesPeopleQuery
>;
export type GetAllRegistriesPeopleLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesPeopleLazyQuery
>;
export type GetAllRegistriesPeopleQueryResult = Apollo.QueryResult<
  GetAllRegistriesPeopleQuery,
  GetAllRegistriesPeopleQueryVariables
>;
export const GetAllRegistriesCompaniesDocument = gql`
  query GetAllRegistriesCompanies {
    allRegistries(orderBy: "-created_at", isCompany: true) {
      edges {
        node {
          ...RegistryFindingFields
          role
          mainOfficeAddress
          actualCompanyContactsNumber
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesCompaniesQuery, GetAllRegistriesCompaniesQueryVariables>(
    GetAllRegistriesCompaniesDocument,
    options
  );
}
export function useGetAllRegistriesCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >(GetAllRegistriesCompaniesDocument, options);
}
export type GetAllRegistriesCompaniesQueryHookResult = ReturnType<
  typeof useGetAllRegistriesCompaniesQuery
>;
export type GetAllRegistriesCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesCompaniesLazyQuery
>;
export type GetAllRegistriesCompaniesQueryResult = Apollo.QueryResult<
  GetAllRegistriesCompaniesQuery,
  GetAllRegistriesCompaniesQueryVariables
>;
export const GetRegistriesBySearchDocument = gql`
  query GetRegistriesBySearch($search: String!) {
    registries(search: $search) {
      ...RegistryAnagraphicFields
      ...RegistryInfoFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
  ${RegistryInfoFieldsFragmentDoc}
`;

/**
 * __useGetRegistriesBySearchQuery__
 *
 * To run a query within a React component, call `useGetRegistriesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetRegistriesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export function useGetRegistriesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export type GetRegistriesBySearchQueryHookResult = ReturnType<typeof useGetRegistriesBySearchQuery>;
export type GetRegistriesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesBySearchLazyQuery
>;
export type GetRegistriesBySearchQueryResult = Apollo.QueryResult<
  GetRegistriesBySearchQuery,
  GetRegistriesBySearchQueryVariables
>;
export const GetRegistriesByExactSearchDocument = gql`
  query GetRegistriesByExactSearch($exactSearch: String!) {
    registries(exactSearch: $exactSearch) {
      ...RegistryAnagraphicFields
      ...RegistryInfoFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
  ${RegistryInfoFieldsFragmentDoc}
`;

/**
 * __useGetRegistriesByExactSearchQuery__
 *
 * To run a query within a React component, call `useGetRegistriesByExactSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesByExactSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesByExactSearchQuery({
 *   variables: {
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetRegistriesByExactSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesByExactSearchQuery, GetRegistriesByExactSearchQueryVariables>(
    GetRegistriesByExactSearchDocument,
    options
  );
}
export function useGetRegistriesByExactSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >(GetRegistriesByExactSearchDocument, options);
}
export type GetRegistriesByExactSearchQueryHookResult = ReturnType<
  typeof useGetRegistriesByExactSearchQuery
>;
export type GetRegistriesByExactSearchLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesByExactSearchLazyQuery
>;
export type GetRegistriesByExactSearchQueryResult = Apollo.QueryResult<
  GetRegistriesByExactSearchQuery,
  GetRegistriesByExactSearchQueryVariables
>;
export const GetRegistryByIdDocument = gql`
  query GetRegistryById($id: ID!) {
    registry(id: $id) {
      ...RegistryAnagraphicFields
      ...RegistryInfoFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
  ${RegistryInfoFieldsFragmentDoc}
`;

/**
 * __useGetRegistryByIdQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export function useGetRegistryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export type GetRegistryByIdQueryHookResult = ReturnType<typeof useGetRegistryByIdQuery>;
export type GetRegistryByIdLazyQueryHookResult = ReturnType<typeof useGetRegistryByIdLazyQuery>;
export type GetRegistryByIdQueryResult = Apollo.QueryResult<
  GetRegistryByIdQuery,
  GetRegistryByIdQueryVariables
>;
export const GetRegistryByIdToEditDocument = gql`
  query GetRegistryByIdToEdit($id: ID!) {
    registry(id: $id) {
      ...RegistryAnagraphicFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRegistryByIdToEditQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdToEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdToEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistryByIdToEditQuery,
    GetRegistryByIdToEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdToEditQuery, GetRegistryByIdToEditQueryVariables>(
    GetRegistryByIdToEditDocument,
    options
  );
}
export function useGetRegistryByIdToEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistryByIdToEditQuery,
    GetRegistryByIdToEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdToEditQuery, GetRegistryByIdToEditQueryVariables>(
    GetRegistryByIdToEditDocument,
    options
  );
}
export type GetRegistryByIdToEditQueryHookResult = ReturnType<typeof useGetRegistryByIdToEditQuery>;
export type GetRegistryByIdToEditLazyQueryHookResult = ReturnType<
  typeof useGetRegistryByIdToEditLazyQuery
>;
export type GetRegistryByIdToEditQueryResult = Apollo.QueryResult<
  GetRegistryByIdToEditQuery,
  GetRegistryByIdToEditQueryVariables
>;
export const CreateRegistryDocument = gql`
  mutation CreateRegistry($input: CreateRegistryInput!) {
    createRegistry(input: $input) {
      registry {
        id
        fullName
        businessName
        alias
        displayName
      }
    }
  }
`;
export type CreateRegistryMutationFn = Apollo.MutationFunction<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;

/**
 * __useCreateRegistryMutation__
 *
 * To run a mutation, you first call `useCreateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryMutation, { data, loading, error }] = useCreateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRegistryMutation, CreateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRegistryMutation, CreateRegistryMutationVariables>(
    CreateRegistryDocument,
    options
  );
}
export type CreateRegistryMutationHookResult = ReturnType<typeof useCreateRegistryMutation>;
export type CreateRegistryMutationResult = Apollo.MutationResult<CreateRegistryMutation>;
export type CreateRegistryMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;
export const UpdateRegistryDocument = gql`
  mutation UpdateRegistry($input: UpdateRegistryInput!) {
    updateRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type UpdateRegistryMutationFn = Apollo.MutationFunction<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;

/**
 * __useUpdateRegistryMutation__
 *
 * To run a mutation, you first call `useUpdateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistryMutation, { data, loading, error }] = useUpdateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRegistryMutation, UpdateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRegistryMutation, UpdateRegistryMutationVariables>(
    UpdateRegistryDocument,
    options
  );
}
export type UpdateRegistryMutationHookResult = ReturnType<typeof useUpdateRegistryMutation>;
export type UpdateRegistryMutationResult = Apollo.MutationResult<UpdateRegistryMutation>;
export type UpdateRegistryMutationOptions = Apollo.BaseMutationOptions<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;
export const DeleteRegistryDocument = gql`
  mutation DeleteRegistry($input: DeleteRegistryInput!) {
    deleteRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type DeleteRegistryMutationFn = Apollo.MutationFunction<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;

/**
 * __useDeleteRegistryMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryMutation, { data, loading, error }] = useDeleteRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRegistryMutation, DeleteRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRegistryMutation, DeleteRegistryMutationVariables>(
    DeleteRegistryDocument,
    options
  );
}
export type DeleteRegistryMutationHookResult = ReturnType<typeof useDeleteRegistryMutation>;
export type DeleteRegistryMutationResult = Apollo.MutationResult<DeleteRegistryMutation>;
export type DeleteRegistryMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;
export const GetOfficesBySearchDocument = gql`
  query GetOfficesBySearch($search: String!, $registryId: ID) {
    offices(search: $search, registryId: $registryId) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficesBySearchQuery__
 *
 * To run a query within a React component, call `useGetOfficesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export function useGetOfficesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesBySearchQuery,
    GetOfficesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export type GetOfficesBySearchQueryHookResult = ReturnType<typeof useGetOfficesBySearchQuery>;
export type GetOfficesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetOfficesBySearchLazyQuery
>;
export type GetOfficesBySearchQueryResult = Apollo.QueryResult<
  GetOfficesBySearchQuery,
  GetOfficesBySearchQueryVariables
>;
export const GetOfficeByIdDocument = gql`
  query GetOfficeById($id: ID!) {
    office(id: $id) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficeByIdQuery__
 *
 * To run a query within a React component, call `useGetOfficeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfficeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export function useGetOfficeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export type GetOfficeByIdQueryHookResult = ReturnType<typeof useGetOfficeByIdQuery>;
export type GetOfficeByIdLazyQueryHookResult = ReturnType<typeof useGetOfficeByIdLazyQuery>;
export type GetOfficeByIdQueryResult = Apollo.QueryResult<
  GetOfficeByIdQuery,
  GetOfficeByIdQueryVariables
>;
export const CreateOfficeDocument = gql`
  mutation CreateOffice($input: CreateOfficeInput!) {
    createOffice(input: $input) {
      office {
        ...OfficeAnagraphicFields
      }
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;
export type CreateOfficeMutationFn = Apollo.MutationFunction<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfficeMutation, CreateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfficeMutation, CreateOfficeMutationVariables>(
    CreateOfficeDocument,
    options
  );
}
export type CreateOfficeMutationHookResult = ReturnType<typeof useCreateOfficeMutation>;
export type CreateOfficeMutationResult = Apollo.MutationResult<CreateOfficeMutation>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;
export const UpdateOfficeDocument = gql`
  mutation UpdateOffice($input: UpdateOfficeInput!) {
    updateOffice(input: $input) {
      office {
        id
      }
    }
  }
`;
export type UpdateOfficeMutationFn = Apollo.MutationFunction<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOfficeMutation, UpdateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfficeMutation, UpdateOfficeMutationVariables>(
    UpdateOfficeDocument,
    options
  );
}
export type UpdateOfficeMutationHookResult = ReturnType<typeof useUpdateOfficeMutation>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<UpdateOfficeMutation>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;
export const DeleteOfficeDocument = gql`
  mutation DeleteOffice($input: DeleteOfficeInput!) {
    deleteOffice(input: $input) {
      office {
        id
      }
    }
  }
`;
export type DeleteOfficeMutationFn = Apollo.MutationFunction<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;

/**
 * __useDeleteOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeMutation, { data, loading, error }] = useDeleteOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfficeMutation, DeleteOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOfficeMutation, DeleteOfficeMutationVariables>(
    DeleteOfficeDocument,
    options
  );
}
export type DeleteOfficeMutationHookResult = ReturnType<typeof useDeleteOfficeMutation>;
export type DeleteOfficeMutationResult = Apollo.MutationResult<DeleteOfficeMutation>;
export type DeleteOfficeMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;
export const GetOfficesByRegistryIdDocument = gql`
  query GetOfficesByRegistryId($registryId: ID!) {
    allOffices(registry: $registryId) {
      edges {
        node {
          id
          name
          address
          officeAddress {
            raw
            locality
            state
            country
            streetNumber
            route
          }
          city
          zip
          state
          country
          phone
          email
          main
          active
        }
      }
    }
  }
`;

/**
 * __useGetOfficesByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetOfficesByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export function useGetOfficesByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export type GetOfficesByRegistryIdQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdQuery
>;
export type GetOfficesByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdLazyQuery
>;
export type GetOfficesByRegistryIdQueryResult = Apollo.QueryResult<
  GetOfficesByRegistryIdQuery,
  GetOfficesByRegistryIdQueryVariables
>;
export const GetCompanyContactsByRegistryIdDocument = gql`
  query GetCompanyContactsByRegistryId($registryId: ID!) {
    allCompanyContacts(relatedCompany: $registryId) {
      edges {
        node {
          id
          main
          companyContact {
            id
            fullName
            alias
            role
            phone
            mobilePhone
            email
            note
            webSite
            printCode
            printName
            countryCode
            taxId
            billingAddress
          }
          relatedCompany {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyContactsByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyContactsByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyContactsByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyContactsByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetCompanyContactsByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >(GetCompanyContactsByRegistryIdDocument, options);
}
export function useGetCompanyContactsByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >(GetCompanyContactsByRegistryIdDocument, options);
}
export type GetCompanyContactsByRegistryIdQueryHookResult = ReturnType<
  typeof useGetCompanyContactsByRegistryIdQuery
>;
export type GetCompanyContactsByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyContactsByRegistryIdLazyQuery
>;
export type GetCompanyContactsByRegistryIdQueryResult = Apollo.QueryResult<
  GetCompanyContactsByRegistryIdQuery,
  GetCompanyContactsByRegistryIdQueryVariables
>;
export const DeleteCompanyContactDocument = gql`
  mutation DeleteCompanyContact($input: DeleteCompanyContactInput!) {
    deleteCompanyContact(input: $input) {
      companyContact {
        id
      }
    }
  }
`;
export type DeleteCompanyContactMutationFn = Apollo.MutationFunction<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;

/**
 * __useDeleteCompanyContactMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyContactMutation, { data, loading, error }] = useDeleteCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyContactMutation,
    DeleteCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyContactMutation, DeleteCompanyContactMutationVariables>(
    DeleteCompanyContactDocument,
    options
  );
}
export type DeleteCompanyContactMutationHookResult = ReturnType<
  typeof useDeleteCompanyContactMutation
>;
export type DeleteCompanyContactMutationResult =
  Apollo.MutationResult<DeleteCompanyContactMutation>;
export type DeleteCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;
export const CreateCompanyContactDocument = gql`
  mutation CreateCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      companyContact {
        ...CompanyContactAnagraphicFields
      }
    }
  }
  ${CompanyContactAnagraphicFieldsFragmentDoc}
`;
export type CreateCompanyContactMutationFn = Apollo.MutationFunction<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>;

/**
 * __useCreateCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyContactMutation, { data, loading, error }] = useCreateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyContactMutation,
    CreateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyContactMutation, CreateCompanyContactMutationVariables>(
    CreateCompanyContactDocument,
    options
  );
}
export type CreateCompanyContactMutationHookResult = ReturnType<
  typeof useCreateCompanyContactMutation
>;
export type CreateCompanyContactMutationResult =
  Apollo.MutationResult<CreateCompanyContactMutation>;
export type CreateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>;
export const UpdateCompanyContactDocument = gql`
  mutation UpdateCompanyContact($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      companyContact {
        id
        main
        companyContact {
          id
          fullName
          alias
          role
          phone
          mobilePhone
          email
          note
          webSite
          printCode
          printName
          countryCode
          taxId
          billingAddress
        }
        relatedCompany {
          id
        }
      }
    }
  }
`;
export type UpdateCompanyContactMutationFn = Apollo.MutationFunction<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyContactMutation,
    UpdateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(
    UpdateCompanyContactDocument,
    options
  );
}
export type UpdateCompanyContactMutationHookResult = ReturnType<
  typeof useUpdateCompanyContactMutation
>;
export type UpdateCompanyContactMutationResult =
  Apollo.MutationResult<UpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;
export const CreateRegistryWithCompanyContactDocument = gql`
  mutation CreateRegistryWithCompanyContact($input: CreateRegistryWithCompanyContactInput!) {
    createRegistryWithCompanyContact(input: $input) {
      companyContact {
        ...CompanyContactAnagraphicFields
      }
    }
  }
  ${CompanyContactAnagraphicFieldsFragmentDoc}
`;
export type CreateRegistryWithCompanyContactMutationFn = Apollo.MutationFunction<
  CreateRegistryWithCompanyContactMutation,
  CreateRegistryWithCompanyContactMutationVariables
>;

/**
 * __useCreateRegistryWithCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateRegistryWithCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryWithCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryWithCompanyContactMutation, { data, loading, error }] = useCreateRegistryWithCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegistryWithCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegistryWithCompanyContactMutation,
    CreateRegistryWithCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRegistryWithCompanyContactMutation,
    CreateRegistryWithCompanyContactMutationVariables
  >(CreateRegistryWithCompanyContactDocument, options);
}
export type CreateRegistryWithCompanyContactMutationHookResult = ReturnType<
  typeof useCreateRegistryWithCompanyContactMutation
>;
export type CreateRegistryWithCompanyContactMutationResult =
  Apollo.MutationResult<CreateRegistryWithCompanyContactMutation>;
export type CreateRegistryWithCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistryWithCompanyContactMutation,
  CreateRegistryWithCompanyContactMutationVariables
>;
export const GetAllRestorationsDocument = gql`
  query GetAllRestorations {
    allRestorations {
      edges {
        node {
          ...RestorationFindingFields
          createdAt
          actualMediaStatus
          mediaNumber
        }
      }
    }
  }
  ${RestorationFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRestorationsQuery__
 *
 * To run a query within a React component, call `useGetAllRestorationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRestorationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRestorationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRestorationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>(
    GetAllRestorationsDocument,
    options
  );
}
export function useGetAllRestorationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRestorationsQuery,
    GetAllRestorationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>(
    GetAllRestorationsDocument,
    options
  );
}
export type GetAllRestorationsQueryHookResult = ReturnType<typeof useGetAllRestorationsQuery>;
export type GetAllRestorationsLazyQueryHookResult = ReturnType<
  typeof useGetAllRestorationsLazyQuery
>;
export type GetAllRestorationsQueryResult = Apollo.QueryResult<
  GetAllRestorationsQuery,
  GetAllRestorationsQueryVariables
>;
export const GetRestorationByIdDocument = gql`
  query GetRestorationById($id: ID!) {
    restoration(id: $id) {
      ...RestorationAnagraphicFields
    }
  }
  ${RestorationAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRestorationByIdQuery__
 *
 * To run a query within a React component, call `useGetRestorationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestorationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestorationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRestorationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>(
    GetRestorationByIdDocument,
    options
  );
}
export function useGetRestorationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestorationByIdQuery,
    GetRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>(
    GetRestorationByIdDocument,
    options
  );
}
export type GetRestorationByIdQueryHookResult = ReturnType<typeof useGetRestorationByIdQuery>;
export type GetRestorationByIdLazyQueryHookResult = ReturnType<
  typeof useGetRestorationByIdLazyQuery
>;
export type GetRestorationByIdQueryResult = Apollo.QueryResult<
  GetRestorationByIdQuery,
  GetRestorationByIdQueryVariables
>;
export const CreateRestorationDocument = gql`
  mutation CreateRestoration($input: CreateRestorationInput!) {
    createRestoration(input: $input) {
      restoration {
        id
        restorationStatus
        restorationDirector {
          id
          fullName
        }
        restorationCompany {
          id
          fullName
          businessName
        }
        restorationAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        restorationRestoredBy
        restorationCost {
          amount
          currency {
            code
          }
        }
        restorationInventoryNumber
        restorationStartDate
        restorationEndDate
        restorationLocation
        restorationCollocationFeatures
        restorationDocumentDate
        conservativeDataHistoryInterventions
        conservativeDataConservationStatus
        conservativeDataAssetAlterations
        conservativeDataDeteriorationCauses
        conservativeDataExaminationMethod
        conservativeDataInterventionProposal
        conservativeDataPreliminaryOperations
        conservativeDataIntervention
        conservativeDataInterventionMaterials
        conservativeDataTechnicalMethodologicalConsiderations
        conservativeDataMaintenancePreparationAssembly
        conservativeDataRestorationBibliography
        conservativeDataNotesRestorationSheet
        media {
          edges {
            node {
              id
              name
              file
              description
              mediaType
              isMainImage
              imageType
              notes
              audioVideoFileType
              creationDate
              fileAuthor
              fileSize
              photographicEquipment
              matrixType
              isGlassPlexyAcetate
            }
          }
        }
      }
    }
  }
`;
export type CreateRestorationMutationFn = Apollo.MutationFunction<
  CreateRestorationMutation,
  CreateRestorationMutationVariables
>;

/**
 * __useCreateRestorationMutation__
 *
 * To run a mutation, you first call `useCreateRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestorationMutation, { data, loading, error }] = useCreateRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRestorationMutation,
    CreateRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRestorationMutation, CreateRestorationMutationVariables>(
    CreateRestorationDocument,
    options
  );
}
export type CreateRestorationMutationHookResult = ReturnType<typeof useCreateRestorationMutation>;
export type CreateRestorationMutationResult = Apollo.MutationResult<CreateRestorationMutation>;
export type CreateRestorationMutationOptions = Apollo.BaseMutationOptions<
  CreateRestorationMutation,
  CreateRestorationMutationVariables
>;
export const UpdateRestorationDocument = gql`
  mutation UpdateRestoration($input: UpdateRestorationInput!) {
    updateRestoration(input: $input) {
      restoration {
        id
        restorationStatus
        restorationDirector {
          id
          fullName
        }
        restorationCompany {
          id
          fullName
          businessName
        }
        restorationAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        restorationRestoredBy
        restorationCost {
          amount
          currency {
            code
          }
        }
        restorationInventoryNumber
        restorationStartDate
        restorationEndDate
        restorationLocation
        restorationCollocationFeatures
        restorationDocumentDate
        conservativeDataHistoryInterventions
        conservativeDataConservationStatus
        conservativeDataAssetAlterations
        conservativeDataDeteriorationCauses
        conservativeDataExaminationMethod
        conservativeDataInterventionProposal
        conservativeDataPreliminaryOperations
        conservativeDataIntervention
        conservativeDataInterventionMaterials
        conservativeDataTechnicalMethodologicalConsiderations
        conservativeDataMaintenancePreparationAssembly
        conservativeDataRestorationBibliography
        conservativeDataNotesRestorationSheet
        media {
          edges {
            node {
              id
              name
              file
              description
              mediaType
              isMainImage
              imageType
              notes
              audioVideoFileType
              creationDate
              fileAuthor
              fileSize
              photographicEquipment
              matrixType
              isGlassPlexyAcetate
            }
          }
        }
      }
    }
  }
`;
export type UpdateRestorationMutationFn = Apollo.MutationFunction<
  UpdateRestorationMutation,
  UpdateRestorationMutationVariables
>;

/**
 * __useUpdateRestorationMutation__
 *
 * To run a mutation, you first call `useUpdateRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestorationMutation, { data, loading, error }] = useUpdateRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestorationMutation,
    UpdateRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRestorationMutation, UpdateRestorationMutationVariables>(
    UpdateRestorationDocument,
    options
  );
}
export type UpdateRestorationMutationHookResult = ReturnType<typeof useUpdateRestorationMutation>;
export type UpdateRestorationMutationResult = Apollo.MutationResult<UpdateRestorationMutation>;
export type UpdateRestorationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestorationMutation,
  UpdateRestorationMutationVariables
>;
export const DeleteRestorationDocument = gql`
  mutation DeleteRestoration($input: DeleteRestorationInput!) {
    deleteRestoration(input: $input) {
      deleteRestoration {
        id
      }
    }
  }
`;
export type DeleteRestorationMutationFn = Apollo.MutationFunction<
  DeleteRestorationMutation,
  DeleteRestorationMutationVariables
>;

/**
 * __useDeleteRestorationMutation__
 *
 * To run a mutation, you first call `useDeleteRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestorationMutation, { data, loading, error }] = useDeleteRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRestorationMutation,
    DeleteRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRestorationMutation, DeleteRestorationMutationVariables>(
    DeleteRestorationDocument,
    options
  );
}
export type DeleteRestorationMutationHookResult = ReturnType<typeof useDeleteRestorationMutation>;
export type DeleteRestorationMutationResult = Apollo.MutationResult<DeleteRestorationMutation>;
export type DeleteRestorationMutationOptions = Apollo.BaseMutationOptions<
  DeleteRestorationMutation,
  DeleteRestorationMutationVariables
>;
export const CloseRestorationDocument = gql`
  mutation CloseRestoration($input: CloseRestorationInput!) {
    closeRestoration(input: $input) {
      closeRestoration {
        id
      }
    }
  }
`;
export type CloseRestorationMutationFn = Apollo.MutationFunction<
  CloseRestorationMutation,
  CloseRestorationMutationVariables
>;

/**
 * __useCloseRestorationMutation__
 *
 * To run a mutation, you first call `useCloseRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeRestorationMutation, { data, loading, error }] = useCloseRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseRestorationMutation,
    CloseRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseRestorationMutation, CloseRestorationMutationVariables>(
    CloseRestorationDocument,
    options
  );
}
export type CloseRestorationMutationHookResult = ReturnType<typeof useCloseRestorationMutation>;
export type CloseRestorationMutationResult = Apollo.MutationResult<CloseRestorationMutation>;
export type CloseRestorationMutationOptions = Apollo.BaseMutationOptions<
  CloseRestorationMutation,
  CloseRestorationMutationVariables
>;
export const DetachAssetFromRestorationDocument = gql`
  mutation DetachAssetFromRestoration($input: DetachAssetFromRestorationInput!) {
    detachAssetFromRestoration(input: $input) {
      restoration {
        id
      }
    }
  }
`;
export type DetachAssetFromRestorationMutationFn = Apollo.MutationFunction<
  DetachAssetFromRestorationMutation,
  DetachAssetFromRestorationMutationVariables
>;

/**
 * __useDetachAssetFromRestorationMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromRestorationMutation, { data, loading, error }] = useDetachAssetFromRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromRestorationMutation,
    DetachAssetFromRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromRestorationMutation,
    DetachAssetFromRestorationMutationVariables
  >(DetachAssetFromRestorationDocument, options);
}
export type DetachAssetFromRestorationMutationHookResult = ReturnType<
  typeof useDetachAssetFromRestorationMutation
>;
export type DetachAssetFromRestorationMutationResult =
  Apollo.MutationResult<DetachAssetFromRestorationMutation>;
export type DetachAssetFromRestorationMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromRestorationMutation,
  DetachAssetFromRestorationMutationVariables
>;
export const GetMediaRestorationByIdDocument = gql`
  query GetMediaRestorationById($id: ID!) {
    restoration(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaRestorationByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaRestorationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaRestorationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaRestorationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaRestorationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaRestorationByIdQuery,
    GetMediaRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaRestorationByIdQuery, GetMediaRestorationByIdQueryVariables>(
    GetMediaRestorationByIdDocument,
    options
  );
}
export function useGetMediaRestorationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaRestorationByIdQuery,
    GetMediaRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaRestorationByIdQuery, GetMediaRestorationByIdQueryVariables>(
    GetMediaRestorationByIdDocument,
    options
  );
}
export type GetMediaRestorationByIdQueryHookResult = ReturnType<
  typeof useGetMediaRestorationByIdQuery
>;
export type GetMediaRestorationByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaRestorationByIdLazyQuery
>;
export type GetMediaRestorationByIdQueryResult = Apollo.QueryResult<
  GetMediaRestorationByIdQuery,
  GetMediaRestorationByIdQueryVariables
>;
export const AddRestorationMediaDocument = gql`
  mutation AddRestorationMedia($input: AddRestorationMediaInput!) {
    addRestorationMedia(input: $input) {
      restoration {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddRestorationMediaMutationFn = Apollo.MutationFunction<
  AddRestorationMediaMutation,
  AddRestorationMediaMutationVariables
>;

/**
 * __useAddRestorationMediaMutation__
 *
 * To run a mutation, you first call `useAddRestorationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestorationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestorationMediaMutation, { data, loading, error }] = useAddRestorationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRestorationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRestorationMediaMutation,
    AddRestorationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRestorationMediaMutation, AddRestorationMediaMutationVariables>(
    AddRestorationMediaDocument,
    options
  );
}
export type AddRestorationMediaMutationHookResult = ReturnType<
  typeof useAddRestorationMediaMutation
>;
export type AddRestorationMediaMutationResult = Apollo.MutationResult<AddRestorationMediaMutation>;
export type AddRestorationMediaMutationOptions = Apollo.BaseMutationOptions<
  AddRestorationMediaMutation,
  AddRestorationMediaMutationVariables
>;
export const DeleteRestorationMediaDocument = gql`
  mutation DeleteRestorationMedia($input: DeleteRestorationMediaInput!) {
    deleteRestorationMedia(input: $input) {
      restoration {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteRestorationMediaMutationFn = Apollo.MutationFunction<
  DeleteRestorationMediaMutation,
  DeleteRestorationMediaMutationVariables
>;

/**
 * __useDeleteRestorationMediaMutation__
 *
 * To run a mutation, you first call `useDeleteRestorationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestorationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestorationMediaMutation, { data, loading, error }] = useDeleteRestorationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestorationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRestorationMediaMutation,
    DeleteRestorationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRestorationMediaMutation,
    DeleteRestorationMediaMutationVariables
  >(DeleteRestorationMediaDocument, options);
}
export type DeleteRestorationMediaMutationHookResult = ReturnType<
  typeof useDeleteRestorationMediaMutation
>;
export type DeleteRestorationMediaMutationResult =
  Apollo.MutationResult<DeleteRestorationMediaMutation>;
export type DeleteRestorationMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteRestorationMediaMutation,
  DeleteRestorationMediaMutationVariables
>;
export const ExportRestorationPdfDocument = gql`
  mutation ExportRestorationPdf($input: ExportRestorationPDFInput!) {
    exportRestorationPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportRestorationPdfMutationFn = Apollo.MutationFunction<
  ExportRestorationPdfMutation,
  ExportRestorationPdfMutationVariables
>;

/**
 * __useExportRestorationPdfMutation__
 *
 * To run a mutation, you first call `useExportRestorationPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportRestorationPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportRestorationPdfMutation, { data, loading, error }] = useExportRestorationPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportRestorationPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportRestorationPdfMutation,
    ExportRestorationPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportRestorationPdfMutation, ExportRestorationPdfMutationVariables>(
    ExportRestorationPdfDocument,
    options
  );
}
export type ExportRestorationPdfMutationHookResult = ReturnType<
  typeof useExportRestorationPdfMutation
>;
export type ExportRestorationPdfMutationResult =
  Apollo.MutationResult<ExportRestorationPdfMutation>;
export type ExportRestorationPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportRestorationPdfMutation,
  ExportRestorationPdfMutationVariables
>;
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
      user {
        name
        country {
          code
        }
        currency
        dateJoined
        email
        id
        isActive
        isStaff
        isSuperuser
        language
        lastLogin
        phone
        timezone
        userType
        username
        uuid
        userPermissions {
          edges {
            node {
              id
              codename
              name
              contentType {
                model
                id
              }
            }
          }
        }
      }
      algoliaUserKey
    }
  }
`;
export type TokenAuthMutationFn = Apollo.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
    TokenAuthDocument,
    options
  );
}
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    allUsers(orderBy: "-created_at") {
      edges {
        node {
          id
          name
          email
          phone
          country {
            code
          }
          userType
          isActive
          validFrom
          expiresAt
          createdBy {
            id
            name
            userType
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      lastLogin
      dateJoined
      name
      isActive
      email
      userType
      phone
      country {
        code
      }
      language
      timezone
      currency
      validFrom
      expiresAt
      ...UserSignatureFields
      userPermissions {
        edges {
          node {
            id
            codename
            name
            contentType {
              model
              id
            }
          }
        }
      }
    }
  }
  ${UserSignatureFieldsFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        password
        lastLogin
        username
        dateJoined
        name
        email
        phone
        country {
          code
        }
        language
        timezone
        currency
        userType
        isActive
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RequestUserResetPasswordDocument = gql`
  mutation RequestUserResetPassword($input: RequestUserResetPasswordInput!) {
    requestUserResetPassword(input: $input) {
      user {
        resetPasswordToken
      }
    }
  }
`;
export type RequestUserResetPasswordMutationFn = Apollo.MutationFunction<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;

/**
 * __useRequestUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUserResetPasswordMutation, { data, loading, error }] = useRequestUserResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUserResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >(RequestUserResetPasswordDocument, options);
}
export type RequestUserResetPasswordMutationHookResult = ReturnType<
  typeof useRequestUserResetPasswordMutation
>;
export type RequestUserResetPasswordMutationResult =
  Apollo.MutationResult<RequestUserResetPasswordMutation>;
export type RequestUserResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    ResetUserPasswordDocument,
    options
  );
}
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const GetAllPermissionDocument = gql`
  query getAllPermission {
    allPermissions {
      edges {
        node {
          contentType {
            model
          }
          codename
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllPermissionQuery__
 *
 * To run a query within a React component, call `useGetAllPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export function useGetAllPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export type GetAllPermissionQueryHookResult = ReturnType<typeof useGetAllPermissionQuery>;
export type GetAllPermissionLazyQueryHookResult = ReturnType<typeof useGetAllPermissionLazyQuery>;
export type GetAllPermissionQueryResult = Apollo.QueryResult<
  GetAllPermissionQuery,
  GetAllPermissionQueryVariables
>;
export const UpdateUserPermissionsDocument = gql`
  mutation UpdateUserPermissions($input: UpdateUserPermissionInput!) {
    updateUserPermissions(input: $input) {
      user {
        userPermissions {
          edges {
            node {
              codename
              name
            }
          }
        }
      }
    }
  }
`;
export type UpdateUserPermissionsMutationFn = Apollo.MutationFunction<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;

/**
 * __useUpdateUserPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionsMutation, { data, loading, error }] = useUpdateUserPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPermissionsMutation,
    UpdateUserPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPermissionsMutation, UpdateUserPermissionsMutationVariables>(
    UpdateUserPermissionsDocument,
    options
  );
}
export type UpdateUserPermissionsMutationHookResult = ReturnType<
  typeof useUpdateUserPermissionsMutation
>;
export type UpdateUserPermissionsMutationResult =
  Apollo.MutationResult<UpdateUserPermissionsMutation>;
export type UpdateUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;
export const ToggleUserIsActiveDocument = gql`
  mutation ToggleUserIsActive($input: ToggleUserIsActiveInput!) {
    toggleUserIsactive(input: $input) {
      user {
        isActive
      }
    }
  }
`;
export type ToggleUserIsActiveMutationFn = Apollo.MutationFunction<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;

/**
 * __useToggleUserIsActiveMutation__
 *
 * To run a mutation, you first call `useToggleUserIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserIsActiveMutation, { data, loading, error }] = useToggleUserIsActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserIsActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserIsActiveMutation,
    ToggleUserIsActiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserIsActiveMutation, ToggleUserIsActiveMutationVariables>(
    ToggleUserIsActiveDocument,
    options
  );
}
export type ToggleUserIsActiveMutationHookResult = ReturnType<typeof useToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationResult = Apollo.MutationResult<ToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;
export const ToggleUserUserTypeDocument = gql`
  mutation ToggleUserUserType($input: ToggleUserUserTypeInput!) {
    toggleUserUserType(input: $input) {
      user {
        userType
      }
    }
  }
`;
export type ToggleUserUserTypeMutationFn = Apollo.MutationFunction<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;

/**
 * __useToggleUserUserTypeMutation__
 *
 * To run a mutation, you first call `useToggleUserUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserUserTypeMutation, { data, loading, error }] = useToggleUserUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserUserTypeMutation,
    ToggleUserUserTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserUserTypeMutation, ToggleUserUserTypeMutationVariables>(
    ToggleUserUserTypeDocument,
    options
  );
}
export type ToggleUserUserTypeMutationHookResult = ReturnType<typeof useToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationResult = Apollo.MutationResult<ToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;
export const RenewUserDocument = gql`
  mutation RenewUser($input: RenewUserInput!) {
    renewUser(input: $input) {
      user {
        validFrom
        expiresAt
      }
    }
  }
`;
export type RenewUserMutationFn = Apollo.MutationFunction<
  RenewUserMutation,
  RenewUserMutationVariables
>;

/**
 * __useRenewUserMutation__
 *
 * To run a mutation, you first call `useRenewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewUserMutation, { data, loading, error }] = useRenewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RenewUserMutation, RenewUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenewUserMutation, RenewUserMutationVariables>(
    RenewUserDocument,
    options
  );
}
export type RenewUserMutationHookResult = ReturnType<typeof useRenewUserMutation>;
export type RenewUserMutationResult = Apollo.MutationResult<RenewUserMutation>;
export type RenewUserMutationOptions = Apollo.BaseMutationOptions<
  RenewUserMutation,
  RenewUserMutationVariables
>;
export const AddUserSignatureDocument = gql`
  mutation AddUserSignature($input: AddUserSignatureInput!) {
    addUserSignature(input: $input) {
      success
      userSignature {
        file
      }
    }
  }
`;
export type AddUserSignatureMutationFn = Apollo.MutationFunction<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;

/**
 * __useAddUserSignatureMutation__
 *
 * To run a mutation, you first call `useAddUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSignatureMutation, { data, loading, error }] = useAddUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserSignatureMutation,
    AddUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserSignatureMutation, AddUserSignatureMutationVariables>(
    AddUserSignatureDocument,
    options
  );
}
export type AddUserSignatureMutationHookResult = ReturnType<typeof useAddUserSignatureMutation>;
export type AddUserSignatureMutationResult = Apollo.MutationResult<AddUserSignatureMutation>;
export type AddUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;
export const DeleteUserSignatureDocument = gql`
  mutation DeleteUserSignature($input: DeleteUserSignatureInput!) {
    deleteUserSignature(input: $input) {
      userSignature {
        id
      }
    }
  }
`;
export type DeleteUserSignatureMutationFn = Apollo.MutationFunction<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;

/**
 * __useDeleteUserSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSignatureMutation, { data, loading, error }] = useDeleteUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSignatureMutation,
    DeleteUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserSignatureMutation, DeleteUserSignatureMutationVariables>(
    DeleteUserSignatureDocument,
    options
  );
}
export type DeleteUserSignatureMutationHookResult = ReturnType<
  typeof useDeleteUserSignatureMutation
>;
export type DeleteUserSignatureMutationResult = Apollo.MutationResult<DeleteUserSignatureMutation>;
export type DeleteUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const SetMainSignatureDocument = gql`
  mutation SetMainSignature($input: SetMainSignatureInput!) {
    setMainSignature(input: $input) {
      userSignature {
        id
      }
    }
  }
`;
export type SetMainSignatureMutationFn = Apollo.MutationFunction<
  SetMainSignatureMutation,
  SetMainSignatureMutationVariables
>;

/**
 * __useSetMainSignatureMutation__
 *
 * To run a mutation, you first call `useSetMainSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainSignatureMutation, { data, loading, error }] = useSetMainSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMainSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMainSignatureMutation,
    SetMainSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMainSignatureMutation, SetMainSignatureMutationVariables>(
    SetMainSignatureDocument,
    options
  );
}
export type SetMainSignatureMutationHookResult = ReturnType<typeof useSetMainSignatureMutation>;
export type SetMainSignatureMutationResult = Apollo.MutationResult<SetMainSignatureMutation>;
export type SetMainSignatureMutationOptions = Apollo.BaseMutationOptions<
  SetMainSignatureMutation,
  SetMainSignatureMutationVariables
>;
export const AddUserObjectPermissionDocument = gql`
  mutation AddUserObjectPermission($input: AddUserObjectPermissionInput!) {
    addUserObjectPermission(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AddUserObjectPermissionMutationFn = Apollo.MutationFunction<
  AddUserObjectPermissionMutation,
  AddUserObjectPermissionMutationVariables
>;

/**
 * __useAddUserObjectPermissionMutation__
 *
 * To run a mutation, you first call `useAddUserObjectPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserObjectPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserObjectPermissionMutation, { data, loading, error }] = useAddUserObjectPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserObjectPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserObjectPermissionMutation,
    AddUserObjectPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserObjectPermissionMutation,
    AddUserObjectPermissionMutationVariables
  >(AddUserObjectPermissionDocument, options);
}
export type AddUserObjectPermissionMutationHookResult = ReturnType<
  typeof useAddUserObjectPermissionMutation
>;
export type AddUserObjectPermissionMutationResult =
  Apollo.MutationResult<AddUserObjectPermissionMutation>;
export type AddUserObjectPermissionMutationOptions = Apollo.BaseMutationOptions<
  AddUserObjectPermissionMutation,
  AddUserObjectPermissionMutationVariables
>;
export const AddUserObjectPermissionFromCollectionDocument = gql`
  mutation AddUserObjectPermissionFromCollection(
    $input: AddUserObjectPermissionFromCollectionInput!
  ) {
    addUserObjectPermissionFromCollection(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AddUserObjectPermissionFromCollectionMutationFn = Apollo.MutationFunction<
  AddUserObjectPermissionFromCollectionMutation,
  AddUserObjectPermissionFromCollectionMutationVariables
>;

/**
 * __useAddUserObjectPermissionFromCollectionMutation__
 *
 * To run a mutation, you first call `useAddUserObjectPermissionFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserObjectPermissionFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserObjectPermissionFromCollectionMutation, { data, loading, error }] = useAddUserObjectPermissionFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserObjectPermissionFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserObjectPermissionFromCollectionMutation,
    AddUserObjectPermissionFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserObjectPermissionFromCollectionMutation,
    AddUserObjectPermissionFromCollectionMutationVariables
  >(AddUserObjectPermissionFromCollectionDocument, options);
}
export type AddUserObjectPermissionFromCollectionMutationHookResult = ReturnType<
  typeof useAddUserObjectPermissionFromCollectionMutation
>;
export type AddUserObjectPermissionFromCollectionMutationResult =
  Apollo.MutationResult<AddUserObjectPermissionFromCollectionMutation>;
export type AddUserObjectPermissionFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddUserObjectPermissionFromCollectionMutation,
  AddUserObjectPermissionFromCollectionMutationVariables
>;
export const RemoveUserObjectPermissionDocument = gql`
  mutation RemoveUserObjectPermission($input: RemoveUserObjectPermissionInput!) {
    removeUserObjectPermission(input: $input) {
      user {
        id
      }
    }
  }
`;
export type RemoveUserObjectPermissionMutationFn = Apollo.MutationFunction<
  RemoveUserObjectPermissionMutation,
  RemoveUserObjectPermissionMutationVariables
>;

/**
 * __useRemoveUserObjectPermissionMutation__
 *
 * To run a mutation, you first call `useRemoveUserObjectPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserObjectPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserObjectPermissionMutation, { data, loading, error }] = useRemoveUserObjectPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserObjectPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserObjectPermissionMutation,
    RemoveUserObjectPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUserObjectPermissionMutation,
    RemoveUserObjectPermissionMutationVariables
  >(RemoveUserObjectPermissionDocument, options);
}
export type RemoveUserObjectPermissionMutationHookResult = ReturnType<
  typeof useRemoveUserObjectPermissionMutation
>;
export type RemoveUserObjectPermissionMutationResult =
  Apollo.MutationResult<RemoveUserObjectPermissionMutation>;
export type RemoveUserObjectPermissionMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserObjectPermissionMutation,
  RemoveUserObjectPermissionMutationVariables
>;
