/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VStack, Stack } from "@chakra-ui/react";
import { RegistryWithOfficeInput } from "components/form";
import { FieldGroup, FormInputHook } from "components/ui";
import { useTranslation } from "react-i18next";

const ExhibitionFields = ({ watch, id, assets }: any) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="4">
      <FieldGroup title={t("Exhibition Details")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <VStack alignItems="flex-start">
            {/* <FormInputHook name="hostEntity" label="Host Entity" type="registry" /> */}
            <RegistryWithOfficeInput
              registryInputName="hostEntity"
              officeInputName="eventLocationEntity"
              officeLabel="Host Location"
              registryLabel="Host Entity"
            />
            <FormInputHook name="name" label={t("Name")} required />
            <FormInputHook name="description" label={t("Description")} />
            <FormInputHook name="permanent" label={t("Permanent")} type="checkbox" />
            <FormInputHook name="eventFrom" label={t("Event from")} type="datetime-local" />
            <FormInputHook name="eventTo" label={t("Event to")} type="datetime-local" />
            <FormInputHook name="notes" label={t("Notes")} />
            <FormInputHook name="image.0.file" label={t("Image")} type="file" />
            <FormInputHook name="curator" label={t("Curator")} type="registry" />
            <FormInputHook name="organizer" label={t("Organizer")} type="registry" />
          </VStack>
        </VStack>
      </FieldGroup>
    </Stack>
  );
};

export default ExhibitionFields;
