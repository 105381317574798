import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  VStack,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { BorderedBox, CsvImporter } from "components/ui";
import { BaseRow } from "react-csv-importer";
import { Trans } from "react-i18next";
import { BiDownload } from "react-icons/bi";

const IMPORT_FIELDS = [
  { name: "title", optional: false },
  { name: "author", optional: true },
  { name: "creationPeriod", optional: true },
  { name: "technique", optional: true },
  { name: "netHeight", optional: true },
  { name: "netWidth", optional: true },
  { name: "netDepth", optional: true },
  {
    name: "netLengthUnit",
    optional: true,
  },
  { name: "inventoryNumber", optional: true },
  { name: "assetPresentValueAmount", optional: true },
  { name: "assetPresentValueCurrency", optional: true },
  { name: "lender", optional: true },
  { name: "shipper", optional: true },
  { name: "lenderAddress", optional: true },
  { name: "lenderCountry", optional: true },
  { name: "coveredRiskType", optional: true },
  { name: "coverType", optional: true },
  { name: "evaluationType", optional: true },
  { name: "exemption", optional: true },
  { name: "coverTerrorism", optional: true },
  { name: "catNat", optional: true },
  { name: "dimensionsNotes", optional: true },
];
interface ImportAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  processChunk?: (rows: BaseRow[], { startIndex }: { startIndex: number }) => void;
  fields?: { name: string; optional?: boolean }[];
}

const ImportAssetsDrawer = ({
  show,
  onClose,
  processChunk,
  fields = IMPORT_FIELDS,
}: ImportAssetDrawerProps) => (
  <Drawer isOpen={show} placement="right" onClose={onClose} size="2xl">
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Import from CSV</DrawerHeader>
      <DrawerBody>
        {processChunk && (
          <CsvImporter fields={fields} processChunk={processChunk} onClose={onClose} />
        )}
        <VStack align="flex-start" my="8">
          <Heading fontSize="3xl">FAQ</Heading>
          <HStack justify="space-between" w="full">
            <Text>
              <Trans>Subtitle faq in import csv</Trans>
            </Text>
            <a href="/asset_import_example.csv" target="_blank">
              <HStack>
                <Text>
                  <Trans>Download here our csv format</Trans>
                </Text>
                <BiDownload />
              </HStack>
            </a>
          </HStack>
          <BorderedBox title="First question faq title import csv">
            <Text>
              <Trans>First answer question faq import csv</Trans>
            </Text>
          </BorderedBox>
          <BorderedBox title="Which values are accepted?">
            <Table size="sm" rounded="md" my="2">
              <Thead>
                <Th py="2">Field</Th>
                <Th py="2">Accepted values</Th>
                <Th py="2" />
              </Thead>
              <Tbody>
                <Tr>
                  <Td>assetPresentValueCurrency</Td>
                  <Td>EUR, USD</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>netLengthUnit</Td>
                  <Td>M, CM, MM, FT, IN</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>coveredRiskType</Td>
                  <Td>ALL_RISK, FIRST_RISK</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>coverType</Td>
                  <Td>NAIL_TO_NAIL, STAY_RISK, TRAVEL_RISK</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>evaluationType</Td>
                  <Td>AGREED_VALUE, DECLARED_VALUE</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>catNat</Td>
                  <Td>true, false</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>exemption</Td>
                  <Td>true, false</Td>
                  <Td />
                </Tr>
                <Tr>
                  <Td>terrorism</Td>
                  <Td>true, false</Td>
                  <Td />
                </Tr>
              </Tbody>
            </Table>
          </BorderedBox>
        </VStack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export default ImportAssetsDrawer;
