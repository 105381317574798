import { useGetRegistryByIdToEditQuery } from "graphql/queries/generated/queries";
import { DetailsPageSkeleton } from "components/ui";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditRegistryPage = () => {
  const { registryId } = useParams();

  const { data, loading, error, refetch } = useGetRegistryByIdToEditQuery({
    variables: {
      id: registryId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  if (data.registry != null && data.registry.authorType == null) data.registry.authorType = [];
  if (data.registry != null && data.registry.categories == null) data.registry.categories = [];
  if (data.registry != null && data.registry.isAuthor == null) data.registry.isAuthor = false;

  return <Page data={data} />;
};

export default EditRegistryPage;
