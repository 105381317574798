/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Options, AsyncCreatableSelect as CustomSelect } from "chakra-react-select";
import { Box, FormHelperText, FormLabel, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  useGetRegistriesBySearchLazyQuery,
  useCreateRegistryMutation,
  useGetRegistryByIdLazyQuery,
  useCreateOfficeMutation,
  useGetOfficesBySearchLazyQuery,
  useGetOfficeByIdLazyQuery,
  useGetOfficesByRegistryIdLazyQuery,
} from "graphql/queries/generated/queries";
import { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { FormInputProps } from "components/ui/FormInputHook/type";
import { Wrapper } from "components/ui/FormInputHook/Wrapper";
import isFieldRequired from "helpers/isFieldRequired";
import { handleMutation } from "middleware/Toaster";
import { useValidationContext } from "../ValidationContext";

export interface RegistryOption {
  label: string;
  value: string;
}

type RegistryWithOfficeProps = Omit<FormInputProps, "name"> & {
  registryInputName: string;
  officeInputName: string;
  registryLabel: string;
  officeLabel: string;
};

const RegistryWithOfficeInput = ({
  registryInputName,
  officeInputName,
  registryLabel,
  officeLabel,

  type,
  placeholder,
  disabled = false,

  required,
}: RegistryWithOfficeProps) => {
  const { schema } = useValidationContext() || {};

  const isRegistryRequired = schema ? isFieldRequired(schema, registryInputName) : false;
  const isOfficeRequired = schema ? isFieldRequired(schema, officeInputName) : false;

  const {
    watch,
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const [selectedRegistryValue, setSelectedRegistryValue] = useState(null);
  const [selectedOfficeValue, setSelectedOfficeValue] = useState(null);
  const [lastRegistryOptions, setLastRegistryOptions] = useState([]);
  const [lastOfficeOptions, setLastOfficeOptions] = useState([]);
  const [initialOfficesOptions, setInitialOfficesOptions] = useState([]);

  const [getRegistryBySearch] = useGetRegistriesBySearchLazyQuery();
  const [getOfficeBySearch] = useGetOfficesBySearchLazyQuery();
  const [createRegistry, { data, error, loading }] = useCreateRegistryMutation({
    ...handleMutation("Registry created!"),
  });
  const [
    createOffice,
    { data: createOfficeData, error: createOfficeError, loading: createOfficeLoading },
  ] = useCreateOfficeMutation({
    ...handleMutation("Office created!"),
  });

  const [getOfficesByRegistryId] = useGetOfficesByRegistryIdLazyQuery();
  const [getRegistryByIdLazy] = useGetRegistryByIdLazyQuery();
  const [getOfficeByIdLazy] = useGetOfficeByIdLazyQuery();

  const {
    field: { onChange: onRegistryInputChange, value: registryInputValue },
    fieldState: { isTouched: registryInputIsTouched },
  } = useController({
    name: registryInputName,
    control,
    // rules: { required: true },
    defaultValue: "",
  });
  const {
    field: { onChange: onOfficeInputChange, value: officeInputValue },
    fieldState: { isTouched: officeInputIsTouched },
  } = useController({
    name: officeInputName,
    control,
    // rules: { required: true },
    defaultValue: "",
  });

  useEffect(() => {
    const asyncGetRegistryByIdLazy = async () => {
      const { data } = await getRegistryByIdLazy({
        variables: {
          id: registryInputValue,
        },
      });

      if (data.registry) {
        const {
          registry: { fullName },
        } = data;
        setSelectedRegistryValue({ label: fullName, id: registryInputValue });
      }
    };

    if (registryInputValue && !registryInputIsTouched) {
      asyncGetRegistryByIdLazy().catch((e) => console.error(e));
    }
  }, [registryInputValue, registryInputIsTouched]);

  useEffect(() => {
    const asyncGetOfficeByIdLazy = async () => {
      const { data } = await getOfficeByIdLazy({
        variables: {
          id: officeInputValue,
        },
      });

      if (data.office) {
        const {
          office: { name },
        } = data;
        setSelectedOfficeValue({ label: name, id: officeInputValue });
      }
    };

    if (officeInputValue && !officeInputIsTouched) {
      asyncGetOfficeByIdLazy().catch((e) => console.error(e));
    }
  }, [officeInputValue, officeInputIsTouched]);

  const loadRegistryOptions = async ({
    inputValue,
    isTouched,
    value,
  }: {
    inputValue: string;
    isTouched: boolean;
    value: string | RegistryOption[];
  }) => {
    if (inputValue.length < 3) return [];

    const { data } = await getRegistryBySearch({
      variables: {
        search: inputValue,
      },
    });

    if (!data.registries || data.registries.length === 0) return [];
    const options = data.registries.map(({ id, fullName }) => ({
      label: fullName,
      value: id,
    }));

    setLastRegistryOptions(options);

    return options;
  };

  const loadOfficesOptions = async ({
    inputValue,
    isTouched,
    value,
  }: {
    inputValue: string;
    isTouched: boolean;
    value: string | RegistryOption[];
  }) => {
    const { data } = await getOfficeBySearch({
      variables: {
        search: inputValue,
        registryId: selectedRegistryValue.id ?? null,
      },
    });

    if (!data.offices || data.offices.length === 0) return [];
    const options = data.offices.map(({ id, name }) => ({
      label: name,
      value: id,
    }));

    setLastOfficeOptions(options);
    return options;
  };

  const handleCreateRegistryOption = async (inputValue: string) => {
    const { data } = await createRegistry({
      variables: {
        input: {
          registryData: {
            fullName: inputValue,
            isCompany: false,
          },
        },
      },
    });

    if (data) {
      const newValue = {
        value: data.createRegistry.registry.id,
        label: data.createRegistry.registry.fullName,
      };
      setSelectedRegistryValue(newValue);
      onRegistryInputChange(data.createRegistry.registry.id);
    }
  };

  const handleCreateOfficeOption = async (inputValue: string) => {
    const { data } = await createOffice({
      variables: {
        input: {
          officeData: {
            name: inputValue,
            main: false,
            active: true,
            registry: selectedRegistryValue.id,
          },
        },
      },
    });
    if (data) {
      const newValue = {
        value: data.createOffice.office.id,
        label: data.createOffice.office.name,
      };
      setSelectedOfficeValue(newValue);
      onOfficeInputChange(data.createOffice.office.id);
    }
  };

  const isOptionSelected = (option: any, values: Options<any>) =>
    values.some(({ value }) => option.value === value);

  return (
    <>
      <Wrapper name={registryInputName} error={errors[registryInputName]}>
        <FormLabel htmlFor={registryInputName} mb="1" fontSize="xs" display="inline-flex">
          <Text>
            <Trans>{registryLabel}</Trans>
          </Text>
          {isRegistryRequired && (
            <Text px="1" color="red.500">
              <Trans>(required)</Trans>
            </Text>
          )}
        </FormLabel>
        <Box minW="12rem">
          <CustomSelect
            cacheOptions
            isLoading={loading}
            isOptionSelected={isOptionSelected}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCreateOption={handleCreateRegistryOption}
            loadOptions={(inputValue) =>
              loadRegistryOptions({
                isTouched: registryInputIsTouched,
                inputValue,
                value: registryInputValue,
              })
            }
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            useBasicStyles
            value={selectedRegistryValue}
            // value={value}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async (newValue) => {
              onRegistryInputChange(newValue.value);

              try {
                const { data: officesData } = await getOfficesByRegistryId({
                  variables: { registryId: newValue.value },
                });
                const options = officesData.allOffices.edges.map(({ node: { id, name } }) => ({
                  label: name,
                  value: id,
                }));
                setInitialOfficesOptions(options);
              } catch (e) {
                console.log(e);
              }

              // setInitialOfficesOptions(officesData)
            }}
          />

          <FormHelperText fontSize="xs">
            {!data && !error && <Trans>Start typing to search (min 3 characters).</Trans>}

            {error && (
              <Text color="red.500">
                <Trans>Ops! Something went wrong creating a registry entry!</Trans>
                <Trans>Reason: {error.message}</Trans>
              </Text>
            )}
          </FormHelperText>
        </Box>
      </Wrapper>
      <Wrapper name={officeInputName} error={errors[officeInputName]}>
        <FormLabel htmlFor={officeInputName} mb="1" fontSize="xs" display="inline-flex">
          <Text>
            <Trans>{officeLabel}</Trans>
          </Text>
          {isOfficeRequired && (
            <Text px="1" color="red.500">
              <Trans>(required)</Trans>
            </Text>
          )}
        </FormLabel>
        <Box minW="12rem">
          <CustomSelect
            cacheOptions
            isLoading={loading}
            isOptionSelected={isOptionSelected}
            defaultOptions={initialOfficesOptions}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCreateOption={handleCreateOfficeOption}
            loadOptions={(inputValue) =>
              loadOfficesOptions({
                isTouched: officeInputIsTouched,
                inputValue,
                value: officeInputValue,
              })
            }
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            useBasicStyles
            value={selectedOfficeValue}
            // value={value}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => {
              onOfficeInputChange(newValue.value);
            }}
          />

          <FormHelperText fontSize="xs">
            {!data && !error && <Trans>Start typing to search (min 3 characters).</Trans>}
            {data && (
              <Text color="green.500">
                <Trans>Registry entry created successfully!</Trans>
              </Text>
            )}
            {error && (
              <Text color="red.500">
                <Trans>Ops! Something went wrong creating a registry entry!</Trans>
                <Trans>Reason: {error.message}</Trans>
              </Text>
            )}
          </FormHelperText>
        </Box>
      </Wrapper>
    </>
  );
};

export default RegistryWithOfficeInput;
