import { HStack, VStack, Heading, Text, Box, Badge } from "@chakra-ui/react";
import { CopyButton, Dot, Field, RegistryAwareField } from "components/ui";
import { UseFieldArrayRemove } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { RiArrowRightFill } from "react-icons/ri";
import { PolicyInputExtended, PolicyType } from "../types";
import OnCreationPolicyAssetsTable from "./new/OnCreationPolicyAssetsTable";

interface PolicyReviewValues {
  values: PolicyInputExtended;
  remove?: UseFieldArrayRemove;
}

const PolicyReview = ({ values, remove }: PolicyReviewValues) => {
  const { t } = useTranslation();

  if (!values) return null;

  const {
    policyCode,
    offerCode,
    issuedAt,
    expiresAt,
    policyStatus,
    autoRenewal,
    contractingPartyId,
    brokerId,
    insuranceCompanyId,
    catNat,
    coverTerrorism,
    hasVariableBasket,
    coverType,
    coveredRiskType,
    evaluationType,
    policyAssets,
    policyType,
    policyNote,
    policyDocuments,
  } = values;

  return (
    <Box bgColor="white" my="8">
      <Box rounded="md" shadow="lg" p="8">
        <HStack justifyContent="space-between" alignItems="flex-start">
          <VStack alignItems="flex-start" mb={8}>
            <Badge variant={policyStatus}>
              {policyStatus === "PENDING" && t("Offer")}
              {policyStatus === "APPROVED" && t("Policy")}
            </Badge>
            <Heading>
              <Trans>Policy #</Trans>
              {policyCode}
            </Heading>
            <Text fontSize="lg" variant="muted">
              <Trans>Offer #</Trans>
              {offerCode ?? ""} <CopyButton value={offerCode ?? ""} />
            </Text>
          </VStack>
        </HStack>
        <HStack align="flex-start" mb={8}>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Coverage</Trans>
            </Text>
            <HStack>
              <Text>{issuedAt}</Text> <RiArrowRightFill /> <Text>{expiresAt}</Text>
            </HStack>
          </VStack>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Auto renewal</Trans>
            </Text>
            <Dot status={autoRenewal} />
          </VStack>
        </HStack>
        <HStack mb="8">
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Contracting party</Trans>
            </Text>

            <RegistryAwareField id={contractingPartyId} />
          </VStack>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Broker</Trans>
            </Text>

            <RegistryAwareField id={brokerId} />
          </VStack>
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Insurance company</Trans>
            </Text>
            <RegistryAwareField id={insuranceCompanyId} />
          </VStack>
        </HStack>
        <HStack mb="8">
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Natural disasters</Trans>
            </Text>
            <Dot status={catNat} />
          </VStack>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Cover Terrorism</Trans>
            </Text>
            <Dot status={coverTerrorism} />
          </VStack>
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has variable basket</Trans>
            </Text>
            <Dot status={hasVariableBasket} />
          </VStack>
        </HStack>
        <HStack mb="8">
          <Field label="Cover type" value={t(coverType)} />
          <Field label="Covered risk type" value={t(coveredRiskType)} />
          <Field label="Evalutation Type" value={t(evaluationType)} />
        </HStack>
      </Box>

      <VStack my={16} align="flex-start">
        <Heading size="md">
          <Trans>Assets</Trans>
        </Heading>
        <Text variant="muted">Lorem ipsum dolor sit amet</Text>
        <OnCreationPolicyAssetsTable
          data={policyAssets}
          remove={remove}
          type={policyType as PolicyType}
        />
      </VStack>
      <VStack my={16} align="flex-start">
        <Heading size="md">
          <Trans>Notes</Trans>
        </Heading>
        <Text variant="muted">
          {policyNote && policyNote.length > 0 ? (
            policyNote.map(({ content }) => content)
          ) : (
            <Trans>No notes added yet</Trans>
          )}
        </Text>
      </VStack>
      <VStack my={16} align="flex-start">
        <Heading size="md">
          <Trans>Documents</Trans>
        </Heading>
        <Text variant="muted">
          <VStack>
            {policyDocuments ? (
              Array.from(policyDocuments).map(({ file }, index) => {
                const retypedFile = file as unknown as FileList;
                return (
                  <HStack>
                    <Box>{retypedFile[0]?.name}</Box>
                  </HStack>
                );
              })
            ) : (
              <Trans>No documents added yet</Trans>
            )}
          </VStack>
        </Text>
      </VStack>
    </Box>
  );
};

export default PolicyReview;
