/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Field } from "components/ui";
import ASSET_CONSTANTS from "constants/asset";
import findLabel from "./FindLabel";
import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const DimensionsView = ({ data }: AnyObject) => {
  const {
    category,
    netLengthUnit,
    netWidth,
    netHeight,
    netDepth,
    netDiameter,
    netWeightUnit,
    netWeight,
    netDimensionsAcquisition,
    grossLengthUnit,
    grossWidth,
    grossHeight,
    grossDepth,
    grossDiameter,
    grossWeightUnit,
    grossWeight,
    grossDimensionsAcquisition,
    dimensionsNotes,
    audioVideoLengthUnit,
    audioVideoLength,
    fileSizeUnit,
    fileSize,
    resolutionUnit,
    resolution,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Dimensions")} subtitle="Lorem ipsum">
      <VStack alignItems="flex-start">
        <HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="600">
                <Trans>Net dimensions</Trans>
              </Text>
              <HStack>
                  <Field label="Diameter" value={netDiameter} />
                  <Field label="Height" value={netHeight} />
                  <Field label="Width" value={netWidth} />
                  <Field label="Depth" value={netDepth} />

                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Length unit</Trans>
                    </Text>
                    { netLengthUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        netLengthUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !netLengthUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>

              </HStack>
              <HStack>
                <Field label="Weight" value={netWeight} />

                <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Weight unit</Trans>
                    </Text>
                    { netWeightUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.WEIGHT_UNIT,
                        netWeightUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !netWeightUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>
              </HStack>
              <HStack>
                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Acquisition</Trans>
                    </Text>
                    {
                      netDimensionsAcquisition && <Text>
                        {findLabel(
                          ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS,
                          netDimensionsAcquisition.toString()
                        )}
                      </Text>
                    }
                    {
                      !netDimensionsAcquisition && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>
              </HStack>
            </VStack>
        </HStack>

        <HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="600">
                <Trans>Gross dimensions</Trans>
              </Text>
              <HStack>
                  <Field label="Diameter" value={grossDiameter} />
                  <Field label="Height" value={grossHeight} />
                  <Field label="Width" value={grossWidth} />
                  <Field label="Depth" value={grossDepth} />

                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Length unit</Trans>
                    </Text>
                    { grossLengthUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        grossLengthUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !grossLengthUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>

              </HStack>
              <HStack>
                <Field label="Weight" value={grossWeight} />

                <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Weight unit</Trans>
                    </Text>
                    { grossWeightUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.WEIGHT_UNIT,
                        grossWeightUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !grossWeightUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>
              </HStack>
              <HStack>
                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Acquisition</Trans>
                    </Text>
                    {
                      grossDimensionsAcquisition && <Text>
                        {findLabel(
                          ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS,
                          grossDimensionsAcquisition.toString()
                        )}
                      </Text>
                    }
                    {
                      !grossDimensionsAcquisition && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>
              </HStack>
            </VStack>
        </HStack>

        {(category === "INSTALLATION" || category === "DIGITAL") &&
          <HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="600">
                <Trans>Audio/Video files dimension</Trans>
              </Text>
              <HStack>
                  <Field label="Length" value={audioVideoLength} />
                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Length unit</Trans>
                    </Text>
                    { audioVideoLengthUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.MEDIA_LENGTH_UNIT,
                        audioVideoLengthUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !audioVideoLengthUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>

                  <Field label="File size" value={fileSize} />
                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>File size unit</Trans>
                    </Text>
                    { fileSizeUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.FILE_SIZE_UNIT,
                        fileSizeUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !fileSizeUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>


                  <Field label="Resolution" value={resolution} />
                  <VStack alignItems="flex-start" pr={8}>
                    <Text fontStyle="normal" variant="muted">
                      <Trans>Resolution unit</Trans>
                    </Text>
                    { resolutionUnit &&
                    <Text>
                      {`${findLabel(
                        ASSET_CONSTANTS.RESOLUTION_UNIT,
                        resolutionUnit.toString()
                      )}`}
                    </Text>
                    }
                    {
                      !resolutionUnit && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                        <Trans>Value not found</Trans>
                      </Text>
                    }
                  </VStack>
              </HStack>
            </VStack>
          </HStack>
        }

      </VStack>

      {/* <HStack spacing="16" w="full">
        {((netLengthUnit && (netWidth || netHeight || netDepth || netDiameter)) ||
          (netWeightUnit && netWeight) ||
          netDimensionsAcquisition) && (
          <HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="600">
                <Trans>Net dimensions</Trans>
              </Text>

              {netLengthUnit && netWidth && netHeight && netDepth && (
                <VStack alignItems="flex-start">
                  <HStack>
                    <Text variant="muted">
                      <Trans>HxWxD</Trans>:
                    </Text>
                    <Text>
                      {`${netWidth}x${netHeight}x${netDepth} ${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        netLengthUnit.toString()
                      )}`}
                    </Text>
                  </HStack>
                  {netDiameter && (
                    <HStack>
                      <Text variant="muted">
                        <Trans>Diameter</Trans>:
                      </Text>
                      <Text>{netDiameter}</Text>
                    </HStack>
                  )}
                </VStack>
              )}
              {((netLengthUnit && (!netWidth || !netHeight || !netDepth)) || !netDiameter) && (
                <HStack>
                  <Field label="Diameter" value={netDiameter} />
                  <Field label="Height" value={netHeight} />
                  <Field label="Width" value={netWidth} />
                  <Field label="Depth" value={netDepth} />
                  {(netWidth || netHeight || netDepth || netDiameter) && (
                    <VStack alignItems="flex-start">
                      <Text variant="muted">
                        <Trans>Length unit</Trans>
                      </Text>
                      <Text>{`${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        netLengthUnit.toString()
                      )}`}</Text>
                    </VStack>
                  )}
                </HStack>
              )}

              {netWeightUnit && netWeight && (
                <HStack>
                  <Text variant="muted">
                    <Trans>Weight</Trans>:
                  </Text>
                  <Text>
                    {`${netWeight} ${findLabel(
                      ASSET_CONSTANTS.WEIGHT_UNIT,
                      netWeightUnit.toString()
                    )}`}
                  </Text>
                </HStack>
              )}
              {netDimensionsAcquisition && (
                <HStack>
                  <Text variant="muted">
                    <Trans>Acquisition</Trans>:
                  </Text>
                  <Text>
                    {findLabel(
                      ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS,
                      netDimensionsAcquisition.toString()
                    )}
                  </Text>
                </HStack>
              )}
            </VStack>
          </HStack>
        )}

        {((grossLengthUnit && (grossWidth || grossHeight || grossDepth || grossDiameter)) ||
          (grossWeightUnit && grossWeight) ||
          grossDimensionsAcquisition) && (
          <HStack>
            <VStack alignItems="flex-start">
              <Text fontWeight="600">
                <Trans>Gross dimensions</Trans>
              </Text>

              {grossLengthUnit && grossWidth && grossHeight && grossDepth && (
                <VStack alignItems="flex-start">
                  <HStack>
                    <Text variant="muted">
                      <Trans>HxWxD</Trans>:
                    </Text>
                    <Text>
                      {`${grossWidth}x${grossHeight}x${grossDepth} ${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        grossLengthUnit.toString()
                      )}`}
                    </Text>
                  </HStack>
                  {grossDiameter && (
                    <HStack>
                      <Text variant="muted">
                        <Trans>Diameter</Trans>:
                      </Text>
                      <Text>{grossDiameter}</Text>
                    </HStack>
                  )}
                </VStack>
              )}
              {grossLengthUnit && (!grossWidth || !grossHeight || !grossDepth || !grossDiameter) && (
                <HStack>
                  <Field label="Diameter" value={grossDiameter} />
                  <Field label="Height" value={grossHeight} />
                  <Field label="Width" value={grossWidth} />
                  <Field label="Depth" value={grossDepth} />
                  {grossLengthUnit && (grossWidth || grossHeight || grossDepth || grossDiameter) && (
                    <VStack alignItems="flex-start">
                      <Text variant="muted">
                        <Trans>Length unit</Trans>
                      </Text>
                      <Text>{`${findLabel(
                        ASSET_CONSTANTS.LENGTH_UNIT,
                        grossLengthUnit.toString()
                      )}`}</Text>
                    </VStack>
                  )}
                </HStack>
              )}
              {grossWeightUnit && grossWeight && (
                <HStack>
                  <Text variant="muted">
                    <Trans>Weight</Trans>:
                  </Text>
                  <Text>
                    {`${grossWeight} ${findLabel(
                      ASSET_CONSTANTS.WEIGHT_UNIT,
                      grossWeightUnit.toString()
                    )}`}
                  </Text>
                </HStack>
              )}
              {grossDimensionsAcquisition && (
                <HStack>
                  <Text variant="muted">
                    <Trans>Acquisition</Trans>:
                  </Text>
                  <Text>
                    {findLabel(
                      ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS,
                      grossDimensionsAcquisition.toString()
                    )}
                  </Text>
                </HStack>
              )}
            </VStack>
          </HStack>
        )}

        {(category === "INSTALLATION" || category === "DIGITAL") &&
          ((audioVideoLength && audioVideoLengthUnit) ||
            (fileSize && fileSizeUnit) ||
            (resolution && resolutionUnit)) && (
            <HStack>
              <VStack alignItems="flex-start">
                <Text fontWeight="600">
                  <Trans>Audio/Video files dimension</Trans>
                </Text>

                {audioVideoLength && audioVideoLengthUnit && (
                  <HStack>
                    <Text variant="muted">
                      <Trans>Length</Trans>:
                    </Text>
                    <Text>
                      {`${audioVideoLength} ${findLabel(
                        ASSET_CONSTANTS.MEDIA_LENGTH_UNIT,
                        audioVideoLengthUnit.toString()
                      )}`}
                    </Text>
                  </HStack>
                )}
                {fileSize && fileSizeUnit && (
                  <HStack>
                    <Text variant="muted">
                      <Trans>File size</Trans>:
                    </Text>
                    <Text>
                      {`${fileSize} ${findLabel(
                        ASSET_CONSTANTS.FILE_SIZE_UNIT,
                        fileSizeUnit.toString()
                      )}`}
                    </Text>
                  </HStack>
                )}
                {resolution && resolutionUnit && (
                  <HStack>
                    <Text variant="muted">
                      <Trans>Resolution</Trans>:
                    </Text>
                    <Text>
                      {`${resolution} ${findLabel(
                        ASSET_CONSTANTS.RESOLUTION_UNIT,
                        resolutionUnit.toString()
                      )}`}
                    </Text>
                  </HStack>
                )}
              </VStack>
            </HStack>
          )}
      </HStack> */}
      <Field label="Dimensions notes" value={dimensionsNotes} />
    </BorderedBox>
  );
};

export default DimensionsView;
