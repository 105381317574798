/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */

import { Trans } from "react-i18next";
import { useFormik } from "formik";
import { Select } from "chakra-react-select";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
// import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

// import { yupResolver } from '@hookform/resolvers/yup';
// import { AssetElementInputSchema } from 'graphql/queries/generated/validation-schema';
// import { FormInputHook } from "components/ui";
// import  cleanObject from 'helpers/cleanObject';
import {
  useGetUniqueUnknownRegistryLazyQuery,
  useCreateRegistryMutation,
  useChangeAssetCategoryMutation,
  Exact,
  GetAssetByIdQuery,
  AssetInput,
} from "graphql/queries/generated/queries";
// import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { ApolloQueryResult } from "@apollo/client";
import { UseFormSetValue } from "react-hook-form";
import { Wrapper } from "components/ui/FormInputHook/Wrapper";
import ASSET_CONSTANTS from "constants/asset";
// import findCategory from './FindCategory';
import { handleMutation } from "middleware/Toaster";
import findCategory from "./FindCategory";

const formatGroupLabel = (data: any) => (
  <Flex align="center" justify="space-between" mt="1">
    <Text variant="muted" textTransform="uppercase" fontWeight="600" fontSize="xs">
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {data.label}
    </Text>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
    <Badge>{data.options.length}</Badge>
  </Flex>
);

interface AssetCategoryFormProps {
  assetId: string;
  currentSubcategory: string;
  setValue: UseFormSetValue<AssetInput>;
  refetchAsset: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAssetByIdQuery>> | undefined;
}

const AssetCategoryForm = ({
  assetId,
  currentSubcategory,
  setValue,
  refetchAsset,
}: AssetCategoryFormProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [
    changeAssetCategory,
    {
      data: changeAssetCategoryData,
      loading: changeAssetCategoryLoading,
      error: changeAssetCategoryError,
    },
  ] = useChangeAssetCategoryMutation({
    ...handleMutation("Category changed!"),
  });

  const [
    createRegistry,
    { data: createRegistryData, error: createRegistryError, loading: createRegistryLoading },
  ] = useCreateRegistryMutation({
    ...handleMutation("Registry created!"),
  });
  const [getUnknown] = useGetUniqueUnknownRegistryLazyQuery();

  const getOrCreateUnknown = async () => {
    const { data: getUnknownData, error: getUnknownError } = await getUnknown();
    if (getUnknownData.allRegistries.edges.length === 0) {
      const { data: createRegistryData, errors: createRegistryErrors } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: "Unknown",
              isCompany: false,
              isUnknownTenantDefault: true,
            },
          },
        },
      });
      if (createRegistryErrors) throw new Error("Unable to create unknown registry entry!"); // return {value: registry.id, label:  registry.fullName}
      const {
        createRegistry: { registry },
      } = createRegistryData;

      return registry.id;
    }

    if (getUnknownError) throw new Error("Unable to retrieve unknown registry entry!");
    const {
      allRegistries: { edges },
    } = getUnknownData;
    const unknownRegistry = edges[0]?.node;
    // return { label: unknownRegistry.fullName, value: unknownRegistry.id };
    return unknownRegistry.id;
  };

  const formik = useFormik<{
    subcategory: string;
  }>({
    initialValues: {
      subcategory: currentSubcategory.toLowerCase(),
    },
    onSubmit: async (values) => {
      console.log(values);
      if (!values.subcategory) {
        return;
      }
      const newCategory = findCategory(
        ASSET_CONSTANTS.SUBCATEGORY,
        values.subcategory
      ).toUpperCase();
      const newSubcategory = values.subcategory.toUpperCase();
      /**
       * TODO impostare authorEntityId ad id dell'unknown
       */
      const variables: {
        variables: {
          input: { id: string; category: string; subcategory: string; authorEntityId?: string };
        };
      } = {
        variables: {
          input: {
            id: assetId,
            category: newCategory,
            subcategory: newSubcategory,
          },
        },
      };
      if (newCategory === "NATURALISTIC_GOODS") {
        const unknownRegistryId = await getOrCreateUnknown();
        variables.variables.input.authorEntityId = unknownRegistryId;
        setValue("authorEntityId", unknownRegistryId);
      } else {
        variables.variables.input.authorEntityId = null;
      }

      console.log(newCategory, newSubcategory);

      await changeAssetCategory(variables);

      setValue("category", newCategory);
      setValue("subcategory", newSubcategory);

      await refetchAsset({ id: assetId });
    },
  });

  const onChange = (value: string) => {
    formik.values.subcategory = value;
    // console.log("formik.values.subcategory");
    // console.log(formik.values.subcategory);
  };

  const onModalOpen = () => {
    formik.values.subcategory = currentSubcategory.toLowerCase();
    onOpen();
  };

  return (
    <>
      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onModalOpen} variant="primary">
          <Trans>Change asset category</Trans>
        </Button>
      </ButtonGroup>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Choose asset category</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form id="change-category" onSubmit={formik.handleSubmit}>
              <Wrapper name="subcategory">
                <FormLabel htmlFor="subcategory" mb="1" fontSize="xs" display="inline-flex">
                  <Text>
                    <Trans>New Category</Trans>
                  </Text>
                </FormLabel>
                <Box minW="12rem" background="white">
                  <Select<{ label: string; value: string }>
                    size="sm"
                    name="subcategory"
                    useBasicStyles
                    placeholder="Select a category..."
                    options={ASSET_CONSTANTS.SUBCATEGORY}
                    onChange={(newValue: { value: string; label: string }) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                      onChange(newValue.value);
                    }}
                    formatGroupLabel={formatGroupLabel}
                  />
                </Box>
              </Wrapper>
            </form>
          </ModalBody>
          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button
                variant="primary"
                onClick={(e) => formik.handleSubmit()}
                isLoading={changeAssetCategoryLoading}
                mr={1}
              >
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssetCategoryForm;
