import {
    Box,
    HStack,
    // IconButton,
    Stack,
    StackDivider,
  } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Field } from 'components/ui';
// import style from "components/ui/Table/scss/index.module.scss"





interface AssetElementViewProps {
  assetElement: {
    id: string,
    name: string,
    description: string,
    materials: string,
    notes: string,
  } | null
}

const AssetElementView = ({ assetElement } : AssetElementViewProps) => {
  if (!assetElement) return null;
    const {
      id,
      name,
      description,
      materials,
      notes,
    } = assetElement;


    const { t } = useTranslation();

    return <Box maxW="6xl">
    <Stack spacing="4" divider={<StackDivider />}>
          <HStack spacing="16" w="full">
            <Field label="Name" value={name} />
            <Field label="Description" value={description} />

          </HStack>
          <HStack spacing="16" w="full">
            <Field label="Materials" value={materials} />
            <Field label="Notes" value={notes} />

          </HStack>

    </Stack>
    </Box>


}


export default AssetElementView;