import { Avatar, VStack, Text, Divider, HStack, Tooltip, Box } from "@chakra-ui/react";
import parseDate from "helpers/parseDate";
import { isArray } from "lodash";
import { Trans, useTranslation } from "react-i18next";

interface Note {
  content: string;
}

interface UserActionInfo {
  name?: string;
  email: string;
}

interface SidebarInfoProps {
  createdBy: UserActionInfo;
  updatedBy: UserActionInfo;
  createdAt: string;
  updatedAt: string;
  notes?: Note[] | Note;
}

const SidebarInfo = ({ createdBy, createdAt, updatedAt, updatedBy, notes }: SidebarInfoProps) => {
  const { t } = useTranslation();
  return (
    <Box display="block" minW="10rem" w="20rem">
      <VStack flex="1" justify="flex-start" align="flex-start">
        <Text variant="muted">
          <Trans>Created by</Trans>
        </Text>
        <Tooltip label={createdBy?.email}>
          <HStack>
            <Avatar name={createdBy?.name} src="https://bit.ly/broken-link" size="xs" />
            <Text>{createdBy?.name}</Text>
          </HStack>
        </Tooltip>
        <Divider />
        <Text variant="muted">
          <Trans>Created at</Trans>
        </Text>
        <Text>{parseDate(createdAt)}</Text>
        <Divider />
        <Text variant="muted">
          <Trans>Updated by</Trans>
        </Text>
        <Tooltip label={updatedBy?.email}>
          <HStack>
            {updatedBy ? (
              <>
                <Avatar name={updatedBy?.name} src="https://bit.ly/broken-link" size="xs" />
                <Text>{updatedBy?.name}</Text>
              </>
            ) : (
              <Text>
                <Trans>User not found</Trans>
              </Text>
            )}
          </HStack>
        </Tooltip>
        <Divider />
        <Text variant="muted">
          <Trans>Updated at</Trans>
        </Text>
        <Text>{parseDate(updatedAt)}</Text>
        <Divider />
        <Text variant="muted">
          <Trans>Notes</Trans>
        </Text>
        {isArray(notes) ? (
          <VStack>
            {notes.map(({ content }) => (
              <Text>{content}</Text>
            ))}
          </VStack>
        ) : (
          <Text>{notes?.content ?? t("Note not found")}</Text>
        )}
      </VStack>
    </Box>
  );
};

export default SidebarInfo;
