const ENTITY_FIELDS = ["author", "lender", "shipper"];

export interface ProcessCSVRowsProps<T> {
  rows: T[];
  startIndex: number;
  parseRow: ({ row, index }: { row: T; index: number }) => Promise<T & { [key: string]: any }>;
  entityFields: Array<keyof T>;
  callback: (arg0: T & { [key: string]: any }) => void;
}

export const parseObjectToCreateEntity = async <T extends object>(
  node: T,
  getOrCreateRegistry: (search: string, category: string) => Promise<string>,
  entityFields: string[] = ENTITY_FIELDS
) => {
  const createdEntities = await Promise.all(
    entityFields.map((field) => {
      console.log(
        `[DEBUG CSV] Parsing field ${field as string} with value ${node[field] as string
        } of object`,
        node
      );

      if (!Object.hasOwn(node, field)) return null;
      if (!node[field] || (node[field] as unknown as string).trim() === "") {
        return null;
      }

      const isAuthor = field.toLowerCase() === "author";
      const registryId = getOrCreateRegistry(
        node[field] as unknown as string,
        isAuthor ? "author" : ""
      );
      return registryId;
    })
  );

  return createdEntities;
};

const processCsvRows = <T extends object>({
  rows,
  startIndex,
  parseRow,
  callback,
}: ProcessCSVRowsProps<T>) => {
  console.log("QUI?");
  // const entities = await Promise.all(
  //   rows.map((row) => {
  //     const registryEntityToBeCreated = parseObjectToCreateEntity(row, ENTITY_FIELDS);
  //     return registryEntityToBeCreated;
  //   })
  // );

  //   await Promise.all(
  //     // eslint-disable-next-line consistent-return
  //     rows.map(async (row: T, index: number) => {
  //       const parsedRow = await parseRow({ row, index });
  //       const {
  //         evaluationType: evaluationTypeRow,
  //         coverTerrorism: coverTerrorismRow,
  //         catNat: catNatRow,
  //         assetPresentValueAmount: assetPresentValueAmountRow,
  //         coveredRiskType: coveredRiskTypeRow,
  //         coverType: coverTypeRow,
  //         exemption: exemptionRow,
  //         lenderAddress: lenderAddressRow,
  //         authorEntityId,
  //         assetPresentValueCurrency,
  //         ...assetInput
  //       } = parsedRow;

  //       const { data } = await createAsset({
  //         variables: {
  //           input: {
  //             assetData: {
  //               ...assetInput,
  //               authorEntityId,
  //               validated: false,
  //               carryingValueAmount: {
  //                 amount: Number(assetPresentValueAmountRow),
  //                 currency: assetPresentValueCurrency ?? "EUR",
  //               },
  //             },
  //           },
  //         },
  //       });

  //       if (!data.createAsset) throw new Error("Error importing asset");
  //       const {
  //         createAsset: { asset },
  //       } = data;

  //       const { id, title, author, creationPeriod } = asset;

  //       const {
  //         evaluationType,
  //         catNat,
  //         coverTerrorism,
  //         coveredRiskType,
  //         coverType,
  //         eventEntityId,
  //         eventLocationEntityId,
  //         exemption,
  //       } = methods.getValues();

  //       append({
  //         ...policyAssetFields,
  //         asset: id,
  //         title,
  //         author,
  //         creationPeriod,
  //         evaluationType: row.evaluationType ?? evaluationType,
  //         catNat: parseBooleanString(catNatRow as unknown as string) ?? catNat,
  //         coverTerrorism:
  //           parseBooleanString(coverTerrorismRow as unknown as string) ?? coverTerrorism,
  //         exemption: parseBooleanString(exemptionRow as unknown as string) ?? exemption,
  //         coveredRiskType: row.coveredRiskType ?? coveredRiskType,
  //         coverType: row.coverType ?? coverType,
  //         objectLocationEntityId: eventEntityId,
  //         objectLocationOfficeId: eventLocationEntityId,
  //         insuredValue: {
  //           amount: Number(assetPresentValueAmountRow),
  //           currency: assetPresentValueCurrency ?? "EUR",
  //         },
  //         status: "NEW",
  //       });
  //     })
  //   );
};

export default processCsvRows;
