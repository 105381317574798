import { Badge, Divider, Heading, HStack, Skeleton, Text, VStack } from "@chakra-ui/react";
import { BorderedBox, RegistryAwareField } from "components/ui";
import { useGetBasketHistoryByPolicyIdQuery } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";

interface PolicyHistoryProps {
  id: string;
}

const PolicyBasketHistory = ({ id }: PolicyHistoryProps) => {
  const { data, error, loading } = useGetBasketHistoryByPolicyIdQuery({
    variables: {
      id,
    },
  });

  if (loading) return <Skeleton w="100%" h="20rem" />;

  if (!data || !data.policy)
    return (
      <Text>
        <Trans>No changes has been made yet.</Trans>
      </Text>
    );

  if (error) return <Text>There has been an error retrieving policy history.</Text>;

  const { policybaskethistorySet } = data.policy;

  const computeValue = (field: string, value: string) => {
    if (!value) return <Trans>&quot;No value was present in this field&quot;</Trans>;
    if (["event_location_entity"].includes(field)) {
      const gqlId = btoa(`RegistryNode:${value}`);
      return <RegistryAwareField id={gqlId} />;
    }

    const timestamp = Date.parse(value);
    if (!Number.isNaN(timestamp)) {
      return new Date(value).toLocaleString();
    }

    return value;
  };

  return (
    <VStack align="flex-start" w="full">
      <VStack align="flex-start" mb="4">
        <Heading size="md">
          <Trans>Basket changes </Trans>
        </Heading>
        <Text variant="muted">
          <Trans>Basket history heading subtitle</Trans>
        </Text>
      </VStack>

      {policybaskethistorySet.edges
        .reverse()
        .map(
          (
            {
              node: {
                createdAt,
                createdBy,
                policyassetbaskethistorySet,
                reason,
                status,
                uuid,
                overrideChangeDate,
              },
            },
            index
          ) => {
            if (policyassetbaskethistorySet.edges.length === 0) return null;
            return (
              <BorderedBox
                noTransTitle={`Change on ${
                  overrideChangeDate
                    ? new Date(overrideChangeDate as string).toLocaleString()
                    : new Date(createdAt).toLocaleString()
                }`}
                subtitle={`Change reason is: ${reason}`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <VStack spacing="4" align="flex-start" divider={<Divider />} w="full">
                  {policyassetbaskethistorySet?.edges?.map(({ node: { diff, asset } }) => {
                    console.log(asset);
                    try {
                      if (!diff)
                        return (
                          <VStack align="flex-start">
                            <HStack align="center">
                              <Text variant="muted">{reason}</Text>
                              <Text fontWeight="bold">
                                {asset.title}, {asset?.authorEntity?.fullName}
                              </Text>
                            </HStack>
                          </VStack>
                        );
                      const diffJson = JSON.parse(diff) as {
                        old: string;
                        new: string;
                        field: string;
                      }[];
                      return (
                        <VStack align="flex-start">
                          <HStack>
                            <Heading size="sm" fontWeight="bold">
                              {asset.title}, {asset.author}, {asset?.authorEntity?.fullName}
                            </Heading>
                          </HStack>
                          {diffJson.map(({ field, old, new: newValue }) => (
                            <HStack align="flex-start">
                              <HStack>
                                <Text>Field:</Text>
                                <Text fontWeight="bold">
                                  <Trans>{field}</Trans>
                                </Text>
                              </HStack>
                              <HStack>
                                <Badge bgColor="red.100 !important" color="red.500">
                                  <Text fontFamily="monospace">-</Text>
                                </Badge>
                                {old ? (
                                  <Text backgroundColor="red.100" color="red.900" p="0.5">
                                    {computeValue(field, old) ?? "..."}
                                  </Text>
                                ) : (
                                  <Text variant="muted">
                                    <Trans>No value was set on this field</Trans>
                                  </Text>
                                )}
                              </HStack>
                              <HStack>
                                <Badge bgColor="green.100 !important" color="green.500">
                                  <Text fontFamily="monospace">+</Text>
                                </Badge>
                                {newValue ? (
                                  <Text backgroundColor="green.100" color="green.900" p="0.5">
                                    {computeValue(field, newValue) ?? "..."}
                                  </Text>
                                ) : (
                                  <Text variant="muted">
                                    <Trans>No value was set on this field</Trans>
                                  </Text>
                                )}
                              </HStack>
                            </HStack>
                          ))}
                        </VStack>
                      );
                    } catch (e) {
                      console.log("ERROR PARSING", diff, asset);
                      return (
                        <Text>
                          <Trans>Unable to parse difference between previous items</Trans>
                        </Text>
                      );
                    }
                  })}
                </VStack>
              </BorderedBox>
            );
          }
        )}
    </VStack>
  );
};

export default PolicyBasketHistory;

//     ({ changeReason, changedAt, field, old, new: newValue }) => (
//       <VStack align="flex-start">
//         <HStack>
//           <Text>Field:</Text>
//           <Text fontWeight="bold">
//             <Trans>{field}</Trans>
//           </Text>
//         </HStack>
//         <HStack>
//           <Badge bgColor="red.100 !important" color="red.500">
//             <Text fontFamily="monospace">-</Text>
//           </Badge>
//           {old ? (
//             <Text backgroundColor="red.100" color="red.900" p="0.5">
//               {computeValue(field, old) ?? "..."}
//             </Text>
//           ) : (
//             <Text variant="muted">
//               <Trans>No value was set on this field</Trans>
//             </Text>
//           )}
//         </HStack>
//         <HStack>
//           <Badge bgColor="green.100 !important" color="green.500">
//             <Text fontFamily="monospace">+</Text>
//           </Badge>
//           {newValue ? (
//             <Text backgroundColor="green.100" color="green.900" p="0.5">
//               {computeValue(field, newValue) ?? "..."}
//             </Text>
//           ) : (
//             <Text variant="muted">
//               <Trans>No value was set on this field</Trans>
//             </Text>
//           )}
//         </HStack>
//       </VStack>
//     )
//   )}
