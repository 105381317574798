import { Box, Select } from "@chakra-ui/react";
import LOCALES from "i18n/locales";
import { useTranslation } from "react-i18next";

const languages = [
  { name: "English", code: LOCALES.ENGLISH, flag: "🇬🇧" },
  { name: "Français", code: LOCALES.FRENCH, flag: "🇫🇷" },
  { name: "Deutsche", code: LOCALES.GERMAN, flag: "🇩🇪" },
  { name: "Italian", code: LOCALES.ITALIAN, flag: "🇮🇹" },
  { name: "Spanish", code: LOCALES.SPANISH, flag: "🇪🇸" },
];

interface LanguageSelectorProps {
  onLanguageSelect: (e: React.FormEvent<HTMLSelectElement>) => void;
}

const LanguageSelector = ({ onLanguageSelect }: LanguageSelectorProps) => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;

  return (
    <Box>
      <Select onChange={onLanguageSelect} bgColor="white" size="xs" height="1.5rem">
        {languages.map(({ name, code, flag }) => (
          <option key={code} value={code} selected={code === resolvedLanguage}>
            {flag} {name}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelector;
