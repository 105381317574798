import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  VStack,
  Text,
} from "@chakra-ui/react";
import { FormInputHook } from "components/ui";

import { Trans } from "react-i18next";
import { RegistryWithOfficeInput } from "components/form";
import POLICY_CONSTANTS from "constants/policy";
import { PolicyType } from "pages/spin/types";

interface BatchEditPolicyAssetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  type: PolicyType;

  // setBatchValues: Dispatch<(prevState: undefined) => undefined>;
}

const BatchEditPolicyAssetModal = ({
  isOpen,
  onClose,
  onSubmit,
  type,
}: BatchEditPolicyAssetModalProps) => (
  <Portal>
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <form>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading>Batch update</Heading>
            <Text variant="muted">Batch update policy asset subtitle in policy new</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack width="full" spacing="6" align="left" p="4">
              <HStack justify="flex-end" />

              <HStack>
                <RegistryWithOfficeInput
                  registryLabel={
                    type === "TEMPORARY_EXHIBITION" ? "Institution/Museum" : "Museum/Collector"
                  }
                  officeLabel="Location"
                  registryInputName="objectLocationEntityId"
                  officeInputName="objectLocationOfficeId"
                />
              </HStack>
              {type === "TEMPORARY_EXHIBITION" && (
                <HStack align="flex-start">
                  <FormInputHook label="Lender" name="lenderId" type="registry" />
                  <FormInputHook label="Lender Address" name="lenderAddress" type="address" />
                </HStack>
              )}

              {type === "TEMPORARY_EXHIBITION" && (
                <HStack align="flex-start">
                  <FormInputHook label="Shipper" name="shipperId" type="registry" />
                  <FormInputHook label="Pickup Address" name="pickupAddress" type="address" />
                  <FormInputHook label="Delivery Address" name="deliveryAddress" type="address" />
                </HStack>
              )}
              <HStack>
                <FormInputHook
                  label="Policy covers natural disasters?"
                  type="checkbox"
                  name="catNat"
                />

                <FormInputHook
                  label="Policy covers terrorism?"
                  type="checkbox"
                  name="coverTerrorism"
                />
                <FormInputHook label="Exemption" type="checkbox" name="exemption" />
              </HStack>
              <HStack>
                <FormInputHook
                  label="Cover Type"
                  options={POLICY_CONSTANTS.COVER_TYPE}
                  name="coverType"
                />
                <FormInputHook
                  label="Covered Risk Type"
                  options={POLICY_CONSTANTS.RISK_TYPE}
                  name="coveredRiskType"
                />
                <FormInputHook
                  label="Evaluation Type"
                  options={POLICY_CONSTANTS.EVALUATION_TYPE}
                  name="evaluationType"
                />
                <FormInputHook name="asset" label="asset" hidden disabled />
              </HStack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button type="button" onClick={onSubmit} variant="primary">
              <Trans>Update selected policy assets</Trans>
            </Button>
            <Button variant="danger">Close</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  </Portal>
);

export default BatchEditPolicyAssetModal;
