import { BaseSyntheticEvent } from "react";
import { VStack, HStack } from "@chakra-ui/react";
import { FieldGroup, FormFooter, FormInputHook, MoneyInputHook } from "components/ui";
import { useTranslation } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";
import { AssetInput, CreateAssetMutation } from "graphql/queries/generated/queries";
import { ApolloError } from "@apollo/client";
import { UseFormReset } from "react-hook-form";
import { ConnectedSelects } from "components/form";

interface ShortAssetFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  data: CreateAssetMutation;
  loading: boolean;
  errors: ApolloError[];
  reset: UseFormReset<AssetInput>;
  onClose?: () => void;
}

const ShortAssetForm = ({
  onSubmit,
  data,
  loading,
  errors,
  reset,
  onClose,
}: ShortAssetFormProps) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={onSubmit} id="new-asset-form">
      <VStack width="full" spacing="6" align="left" p="4">
        <FieldGroup title={t("Asset category")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <ConnectedSelects
              required
              firstInputName="category"
              secondInputName="subcategory"
              firstInputOptions={ASSET_CONSTANTS.CATEGORY}
              secondInputOptions={ASSET_CONSTANTS.SUBCATEGORY}
            />
          </HStack>
        </FieldGroup>
        <FieldGroup title={t("Asset main details")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack justify="space-between" align="flex-start">
            <FormInputHook
              name="authorEntityId"
              label="author"
              type="registry"
              registryCategory="author"
              required
              acceptAnonymous
              acceptUnknown
            />
            <FormInputHook name="title" label="title" required />
          </HStack>
        </FieldGroup>
        <FieldGroup
          title={t("Insured value")}
          subtitle={t("Insured value subtitle in create new manual asset")}
          py={8}
        >
          <MoneyInputHook name="carryingValueAmount.amount" label="carryingValue" />
        </FieldGroup>

        <FieldGroup title={t("Dates")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <FormInputHook name="creationDateNotes" label="creationDateNotes" />
          </HStack>

          {/* <HStack justify="space-between" mt={4}>
            <FormInputHook name="hasFullDatetime" label="hasFullDatetime" type="checkbox" />
            <FormInputHook name="hasPeriod" label="hasPeriod" type="checkbox" />
            <FormInputHook name="hasYear" label="hasYear" type="checkbox" />
          </HStack> */}
        </FieldGroup>
        <FieldGroup
          title={t("Technique and dimensions")}
          subtitle={t("Lorem ipsum dolor sit amet")}
        >
          <VStack align="flex-start">
            {/* <FormInputHook label="genericDimensions" name="genericDimensions" /> */}
            <FormInputHook label="technique" name="technique" />
          </VStack>
          {/* <FormInputHook label="validated" name="validated" type="checkbox" /> */}
        </FieldGroup>
        <FieldGroup
          title={t("Net dimensions")}
          subtitle={t("Dimensions - Net dimensions - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <VStack width="full" align="left">
            <HStack alignItems="flex-end">
              <FormInputHook
                label="Length unit"
                options={ASSET_CONSTANTS.LENGTH_UNIT}
                name="netLengthUnit"
              />
              <FormInputHook label="Height" name="netHeight" type="number" step="0.01" />
              <FormInputHook label="Width" name="netWidth" type="number" step="0.01" />
              <FormInputHook label="Depth" name="netDepth" type="number" step="0.01" />
              <FormInputHook label="Diameter" name="netDiameter" type="number" step="0.01" />
            </HStack>
            <HStack alignItems="flex-end">
              <FormInputHook
                label="Weight unit"
                options={ASSET_CONSTANTS.WEIGHT_UNIT}
                name="netWeightUnit"
              />
              <FormInputHook label="Weight" name="netWeight" type="number" step="0.01" />
            </HStack>
            <HStack alignItems="flex-end">
              <FormInputHook
                label="Dimensions acquisition"
                options={ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS}
                step="0.01"
                name="netDimensionsAcquisition"
              />
            </HStack>
          </VStack>
        </FieldGroup>
        <FieldGroup title={t("Series")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <FormInputHook label="series" name="series" />
            <FormInputHook label="seriesDetails" name="seriesDetails" />
          </HStack>

          <HStack>
            <FormInputHook label="sku" name="sku" />
          </HStack>
          {/* <FormInputHook label="validated" name="validated" type="checkbox" /> */}
        </FieldGroup>
        <FieldGroup title={t("NFT")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <VStack align="flex-start">
            <FormInputHook label="nft" type="checkbox" name="nft" />
            <FormInputHook label="nftNotes" name="nftNotes" />
          </VStack>
        </FieldGroup>
        <HStack>
          <FormFooter
            data={data}
            loading={loading}
            errors={errors}
            title="Asset"
            cancelAction={() => {
              reset();
              onClose();
            }}
          />
        </HStack>
      </VStack>
    </form>
  );
};

export default ShortAssetForm;
