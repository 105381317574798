/* eslint-disable import/prefer-default-export */
import { Badge, Image } from "@chakra-ui/react";
import placeholderPNG from "assets/placeholder.png";
import { BooleanFilter, CreatedByFilter, RegistryAwareField } from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { PolicyAssetNode, RegistryNode } from "graphql/queries/generated/queries";
import { BooleanCell } from "helpers/getColumns";
import parseAmount from "helpers/parseAmount";
import { Trans } from "react-i18next";

export const PC_CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,

    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Category",
    Footer: "",
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Trans>{value}</Trans>,
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "authorEntity.fullName",
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: { authorEntity: RegistryNode; author: string } } };
    }) => {
      const { authorEntity, author } = original;
      return authorEntity ? authorEntity.fullName : author;
    },
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "actualDateAndPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    weight: 20,
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyAssetNode } };
    }) => {
      const { insuredValue } = original;
      return parseAmount(insuredValue);
    },
  },
  {
    Header: "Dimensions",
    Footer: "",
    accessor: "genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Technique",
    Footer: "",
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Inventory #",
    Footer: "",
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Object location",
    Footer: "",
    accessor: "objectLocationOfficeId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Museum/Collector",
    Footer: "",
    accessor: "objectLocationEntityId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 20,
    disableFilters: true,
  },
  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
    weight: 20,
  },
  {
    Header: "Validated",
    Footer: "",
    accessor: "validated",
    Cell: BooleanCell,
    sortType: "basic",
    Filter: BooleanFilter,
    weight: 20,
  },
  {
    Header: "",
    Footer: "",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

export const PC_CUSTOM_FIELDS = PC_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

export const PC_HIDDEN_FIELDS = [
  "asset",
  "creationPeriod",
  "pickupAddress",
  "lenderAddress",
  "deliveryAddress",
  // "catNat",
  // "coverTerrorism",
  // "exemption",
  "objectLocationEntityId",
  "objectLocationEntity",
  "objectLocationOffice",
  "lender",
  "shipper",
  "asset.createdBy",
  "totalInsuredValue",
  "lenderId",
  "shipperId",
];
