import { PropsValue, Select as CustomSelect } from "chakra-react-select";
import { Box, FormLabel, Text } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import isFieldRequired from "helpers/isFieldRequired";
import { useState } from "react";
import InputWrapper from "../InputWrapper";
import { useValidationContext } from "../ValidationContext";

export interface FormInputProps {
  firstInputName: string;
  secondInputName: string;
  firstInputOptions?: FormSelectOptionProps[];
  secondInputOptions?: FormSelectOptionProps[];
  disabled?: boolean;
  required?: boolean;
}

export interface FormSelectSubOptionProps {
  value: string;
  label: string;
}

export interface FormSelectOptionProps {
  value?: string;
  label: string;
  options?: FormSelectOptionProps[];
}

const Select = ({
  firstInputName,
  secondInputName,
  firstInputOptions,
  secondInputOptions,
  disabled = false,
  required,
}: FormInputProps) => {
  const { t } = useTranslation();
  const { schema } = useValidationContext() || {};

  const [selectedFirstValue, setSelectedFirstValue] = useState<string | null>("");

  const [selectedSecondValue, setSelectedSecondValue] =
    useState<PropsValue<FormSelectOptionProps> | null>(null);

  const isFirstInputRequired = schema ? isFieldRequired(schema, firstInputName) : required;
  const isSecondInputRequired = schema ? isFieldRequired(schema, secondInputName) : required;
  const {
    watch,
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const {
    field: {
      onChange: onFirstInputChange,
      onBlur: onFirstInputBlur,
      name: firstInputNameControlled,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value: firstInputValueControlled,
      ref: firstInputRefControlled,
    },
  } = useController({
    name: firstInputName,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange: onSecondInputChange, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: secondInputName,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  return (
    <>
      <InputWrapper name={firstInputName} error={errors[firstInputName]}>
        <FormLabel htmlFor={firstInputName} mb="1" fontSize="xs" display="inline-flex">
          <Text>
            <Trans>{firstInputName}</Trans>
          </Text>
          {isFirstInputRequired && (
            <Text px="1" color="red.500">
              <Trans>(required)</Trans>
            </Text>
          )}
        </FormLabel>
        <Box minW="12rem" background="white">
          <CustomSelect
            isDisabled={disabled}
            size="sm"
            // chakraStyles={{
            //   menuList: (provided, state) => ({
            //   ...provided,
            //   height: 100,
            // }),}}
            isClearable
            isRequired={isFirstInputRequired}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            options={firstInputOptions}
            useBasicStyles
            chakraStyles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "0.375rem",
              }),
            }}
            ref={firstInputRefControlled}
            name={firstInputNameControlled}
            value={firstInputOptions.find(({ value: optionValue }) => value === optionValue)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => {
              if (!newValue) {
                onFirstInputChange("");
                setSelectedFirstValue("");
                onSecondInputChange("");
                setSelectedSecondValue(null);
                return;
              }

              onFirstInputChange(newValue.value);
              setSelectedFirstValue(newValue.value);
              onSecondInputChange(null);
              setSelectedSecondValue(null);
            }}
            onBlur={onFirstInputBlur}
          />
        </Box>
      </InputWrapper>
      <InputWrapper name={secondInputName} error={errors[secondInputName]}>
        <FormLabel htmlFor={secondInputName} mb="1" fontSize="xs" display="inline-flex">
          <Text>
            <Trans>{secondInputName}</Trans>
          </Text>
          {isSecondInputRequired && (
            <Text px="1" color="red.500">
              <Trans>(required)</Trans>
            </Text>
          )}
        </FormLabel>
        <Box minW="12rem" background="white">
          <CustomSelect
            isDisabled={disabled}
            size="sm"
            chakraStyles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "0.375rem",
              }),
            }}
            // chakraStyles={{
            //   menuList: (provided, state) => ({
            //   ...provided,
            //   height: 100,
            // }),}}
            isRequired={isSecondInputRequired}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            options={secondInputOptions.filter((option) => option.value === selectedFirstValue)}
            useBasicStyles
            // value={secondInputOptions.find(({ value: optionValue, options }) => {
            //   if (!firstInputValueControlled) return null;
            //   if (options) {
            //     options.find(({ value: subOptionValue }) => subOptionValue === value);
            //   }
            //   return value === optionValue;
            // })}
            value={selectedSecondValue}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => {
              if (!newValue) {
                onSecondInputChange("");
                return;
              }
              onSecondInputChange(newValue.value);
              setSelectedSecondValue(newValue);
            }}
            onBlur={onBlur}
            ref={ref}
            name={secondInputName}
            isClearable
            isOptionSelected={() => false}
          />
        </Box>
      </InputWrapper>
    </>
  );
};

export default Select;
