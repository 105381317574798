/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Box,
  HStack,
  VStack,
  Button,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Badge,
  Heading,
  Flex,
} from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";
import {
  CreatePolicyMutationResult,
  PolicyAssetInput,
  useGetAllAssetsByTitleLazyQuery,
} from "graphql/queries/generated/queries";
import { useTranslation, Trans } from "react-i18next";
import { BaseSyntheticEvent, Dispatch, SetStateAction, useState } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  Control,
  UseFieldArrayUpdate,
} from "react-hook-form";

import { FormFooter, PolicyAssetSearchBox } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { RiArrowRightFill } from "react-icons/ri";
import { PolicyAssetInputExtended, PolicyInputExtended, PolicyType } from "../types";
import PolicyDetailsFields from "./PolicyFields";
import NotesDocumentsPolicyForm from "./PolicyNotesDocumentsForm";
import PolicyReview from "./PolicyReview";
import OnCreationPolicyAssetsTable, {
  BatchFields,
  defaultValues,
} from "./new/OnCreationPolicyAssetsTable";

interface ExtraFields {
  fields: FieldArrayWithId<PolicyInputExtended, "policyAssets", "id">[];
  append: UseFieldArrayAppend<PolicyInputExtended, "policyAssets">;
  remove: UseFieldArrayRemove;
  control?: Control<PolicyAssetInput[], any>;
  update?: UseFieldArrayUpdate<PolicyInputExtended, "policyAssets">;
}

interface PolicyFormProps {
  // onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => void;
  loading: boolean;
  data: CreatePolicyMutationResult["data"] | null | undefined;
  error: ApolloError[] | undefined;
  extraFields?: ExtraFields;
  getValues: UseFormGetValues<PolicyInputExtended>;
  policyAssets: PolicyAssetInputExtended[];
  setShowNewAssetDrawer: Dispatch<SetStateAction<boolean>>;
  setShowImportAssetsDrawer: Dispatch<SetStateAction<boolean>>;
  goToNextTab: () => void;
  goToPreviousTab: () => void;
  handleTabChange: (index: number) => void;
  tabIndex: number;
  addPolicyAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
  policyType: PolicyType;
}

const policyAssetFields = {
  asset: "",
  catNat: false,
  coverTerrorism: false,
  coveredRiskType: "",
  coverType: "",
  evaluationType: "",
  exemption: false,
  title: "",
  author: "",
  creationPeriod: "",
};

const NewPolicyForm = ({
  onSubmit,
  loading,
  data,
  error,
  extraFields,
  getValues,
  policyAssets,
  setShowNewAssetDrawer,
  setShowImportAssetsDrawer,
  goToNextTab,
  goToPreviousTab,
  handleTabChange,
  tabIndex,
  addPolicyAsset,
  policyType,
}: PolicyFormProps) => {
  const [showRecap, setShowRecap] = useState<boolean>(false);
  const { t } = useTranslation();

  const [loadAssets] = useGetAllAssetsByTitleLazyQuery();

  const policyValues = getValues();
  const batchUpdateDefaults: BatchFields = {
    ...defaultValues,
    objectLocationEntityId: policyValues.eventEntityId,
    objectLocationOfficeId: policyValues.eventLocationEntityId,
    catNat: policyValues.catNat,
    coverTerrorism: policyValues.coverTerrorism,
    coverType: policyValues.coverType,
    coveredRiskType: policyValues.coveredRiskType,
    evaluationType: policyValues.evaluationType,
    exemption: policyValues.exemption,
  };
  console.log({ policyAssets })
  return (
    <form onSubmit={onSubmit}>
      <Tabs
        colorScheme="brand"
        variant="enclosed"
        mt="4"
        index={tabIndex}
        onChange={handleTabChange}
      >
        <TabList>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              1
            </Badge>
            <Trans>Policy Details</Trans>
          </Tab>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              2
            </Badge>
            <Trans>Assets</Trans>
          </Tab>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              3
            </Badge>
            <Trans>Policy Assets</Trans>
          </Tab>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              4
            </Badge>
            <Trans>Notes & Documents</Trans>
          </Tab>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              5
            </Badge>
            <Trans>Review</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel py={6} px="0">
            <PolicyDetailsFields policyType={policyType} />
          </TabPanel>
          <TabPanel py={6} px="0">
            <VStack my="6" rounded="lg" align="left" py="6" spacing={0}>
              <Flex bg="gray.100" p="6" mb="16" rounded="lg">
                <HStack justify="space-between" w="100%" spacing="4">
                  <Box>
                    <Badge mr="2" mb="1">
                      Step 1
                    </Badge>
                    <Heading size="md">Search and select assets</Heading>
                    <Text variant="muted">
                      Use the search box here beneath to locate the assets you need. You can search
                      for any parameters you recall: author, title, date-related data, collection
                      and category are all filterable fields.
                    </Text>
                  </Box>
                  <RiArrowRightFill size="40" />
                  <Box>
                    <Badge mr="2" mb="1">
                      Step 2
                    </Badge>
                    <Heading size="md">Review selected assets and add them to policy</Heading>
                    <Text variant="muted">
                      Use the search box here beneath to locate the assets you need. You can search
                      for any parameters you recall: author, title, date-related data, collection
                      and category are all filterable fields.
                    </Text>
                  </Box>
                  <RiArrowRightFill size="40" />
                  <Box>
                    <Badge mr="2" mb="1">
                      Step 3
                    </Badge>
                    <Heading size="md">Review assets added</Heading>
                    <Text variant="muted">
                      Use the search box here beneath to locate the assets you need. You can search
                      for any parameters you recall: author, title, date-related data, collection
                      and category are all filterable fields.
                    </Text>
                  </Box>
                </HStack>
              </Flex>
              <HStack justifyContent="space-between" align="flex-start" pb={8}>
                <VStack align="flex-start" pr={16}>
                  <Badge mr="2" mb="1">
                    Step 1
                  </Badge>
                  <Heading size="lg">Search and select assets</Heading>{" "}
                  <Text mt={0}>
                    <Trans>
                      Puoi prelevarlo dai tuoi assets digitando qui sotto, oppure creane uno nuovo
                      cliccando su &quot;Crea nuovo asset&quot; o importa una lista di assets da un
                      .csv
                    </Trans>
                  </Text>
                </VStack>
                <HStack>
                  <Button onClick={() => setShowNewAssetDrawer(true)}>
                    <Trans>Crea un nuovo asset</Trans>
                  </Button>
                  <Button onClick={() => setShowImportAssetsDrawer(true)}>
                    <Trans>Importa da csv</Trans>
                  </Button>
                </HStack>
              </HStack>
              <PolicyAssetSearchBox
                index={`${process.env.REACT_APP_TENANT}_asset`}
                addPolicyAsset={addPolicyAsset}
                policyAssets={policyAssets}
              />
            </VStack>
          </TabPanel>
          <TabPanel py={6} px="0">
            <VStack align="flex-start" pr={16}>
              <Heading size="lg">Review selected assets</Heading>{" "}
              <Text mt={0}>
                <Trans>Review selected asset subtitle</Trans>
              </Text>
            </VStack>
            <OnCreationPolicyAssetsTable
              data={policyAssets}
              remove={extraFields.remove}
              update={extraFields.update}
              type={policyType}
              batchUpdateDefaults={batchUpdateDefaults}
            />
          </TabPanel>
          <TabPanel py={6} px="0">
            <NotesDocumentsPolicyForm />
          </TabPanel>
          <TabPanel py={6} px="0">
            <PolicyReview values={getValues()} remove={extraFields.remove} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <FormFooter errors={error} data={data} loading={loading} title="policy">
        <HStack width="full" justify="space-between" my="4">
          <HStack>
            {tabIndex > 0 && (
              <Button onClick={goToPreviousTab}>{t("Return to previous step")}</Button>
            )}
            {tabIndex < 3 && <Button onClick={goToNextTab}>{t("Proceed to next step")}</Button>}

            <Button isLoading={loading} loadingText="Loading" type="submit" variant="primary">
              {policyAssets?.length > 0 ? t("Save policy") : t("Save without adding assets")}
            </Button>

            <Button size="sm" variant="danger">
              {t("Cancel")}
            </Button>
          </HStack>
          <HStack />
        </HStack>
      </FormFooter>
      <Box my="8">
        {/* {error && (
          <Alert status="error" my="4">
            <>
              <AlertIcon />
              {error.message}
            </>
          </Alert>
        )} */}
      </Box>
    </form>
  );
};

export default NewPolicyForm;
