import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import {
  AssetInput,
  GetCollectionByIdQuery,
  CollectionInput,
  useUpdateCollectionMutation,
  useDetachAssetFromCollectionMutation,
  Exact,
  useCreateAssetMutation,
  AssetNode,
} from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { CollectionInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Alert, AlertIcon, VStack, Stack, Button, ButtonGroup, Text } from "@chakra-ui/react";
import { ApolloQueryResult } from "@apollo/client";
import { FormFooter, FieldGroup, FormInputHook, Table } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import {
  CUSTOM_FIELDS,
  CUSTOM_COLUMNS,
  HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import { BaseRow } from "react-csv-importer";
import getColumns from "helpers/getColumns";
import useGetOrCreateRegistry from "hooks/useGetOrCreateRegistry";
import { Navigate } from "react-router-dom";
import { handleMutation, toast } from "middleware/Toaster";
import { parseObjectToCreateEntity } from "helpers/processCsvRow";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import NewAssetDrawer from "./NewAssetDrawer";
import ImportAssetsDrawer, { IMPORT_FIELDS } from "./ImportAssetsDrawer";

interface PageProps {
  data: GetCollectionByIdQuery;
  refetchCollection: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetCollectionByIdQuery>>;
}

const Page = ({ data, refetchCollection }: PageProps) => {
  const {
    collection: { __typename, id, ...collection },
  } = data;

  const { t } = useTranslation();

  const preloadedAssets = data.collection.assetSet.edges;

  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [userWantsToCreateAssets, setUserWantsToCreateAssets] = useState<boolean>(false);
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [assetAdded, setAssetAdded] = useState(false);
  const getOrCreateRegistry = useGetOrCreateRegistry();
  const [createAsset, { data: createAssetData, error: createAssetError }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });
  const [updateCollection, { data: updateCollectionData, error, loading }] =
    useUpdateCollectionMutation({
      ...handleMutation("Collection updated!"),
    });
  const [detachAssetFromCollection, { data: detachAssetData }] =
    useDetachAssetFromCollectionMutation({ ...handleMutation("Asset detached!") });

  const addExistingOrNewAsset = ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [...prevState, gqlId]);

    toast({
      title: "Collection not saved.",
      description: "Press the 'save' button to finalize the changes.",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });

    setAssetAdded(true);
  };

  const onOpenExistingAssetDrawer = () => {
    setUserWantsToAddAssets(true);
  };

  const onOpenNewAssetDrawer = () => {
    setUserWantsToCreateAssets(true);
  };

  const methods = useForm<CollectionInput>({
    defaultValues: {
      name: collection.name,
      description: collection.description,
      notes: collection.notes,
      image: collection.image,
    },
    resolver: yupResolver(CollectionInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    console.log(errors);

    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof CollectionInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<CollectionInput> = async (formData, e) => {
    const fullFormData = formData;
    fullFormData.assetSet = [];

    for (const singleAsset of preloadedAssets) formData.assetSet.push(singleAsset.node.id);
    for (const newAsset of choosenAssets) formData.assetSet.push(newAsset);

    await updateCollection({
      variables: {
        input: {
          id,
          collectionData: {
            ...formData,
          },
        },
      },
    });

    setAssetAdded(false);
    setChoosenAssets([]);

    await refetchCollection({
      id,
    });

    return null;
  };

  if (collection.createdFrom === "spin") {
    toast({
      title: t(`Collection can't be edited!`),
      description: t(`This collection was created from a policy, it can't be edited`),
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    return <Navigate to={`/mgmt/collections/${id}`} />;
  }

  let dataToGetColumns;
  let columns;

  if (preloadedAssets && preloadedAssets.length > 0) {
    dataToGetColumns = preloadedAssets[0].node;

    columns = getColumns({
      data: dataToGetColumns,
      customFields: CUSTOM_FIELDS,
      customColumns: CUSTOM_COLUMNS,
    });
  }

  const onDetachAssetFromCollection = async (assetId: string) => {
    await detachAssetFromCollection({
      variables: {
        input: {
          id,
          assetId,
        },
      },
    });

    return "";
  };

  // const { fields, append, remove, update } = useFieldArray({ control: methods.control });

  const parseRow = async ({
    row,
    index,
  }: {
    row: AssetInput & { assetPresentValueAmount: string } & { author: string } & {
      assetPresentValueCurrency: string;
    };
    index: number;
  }) => {
    const { author, ...copiedRow } = row;

    if (!row.category) copiedRow.category = "not_validated";
    if (!row.subcategory) copiedRow.subcategory = "";
    if (row.title.trim() === "") {
      toast({
        title: t(`Asset at row ${index} couldn't be uploaded`),
        description: t(`Error: Asset is missing title, which is a required field.`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      return null;
    }
    try {
      const authorEntityId = await getOrCreateRegistry(row.author as unknown as string, "");
      const parsedAssetPresentValueAmount = row?.assetPresentValueAmount?.replace(/\D/g, "");

      return {
        ...copiedRow,
        category: "not_validated",
        subcategory: "",
        authorEntityId,
        netWidth: parseFloat(row.netWidth as unknown as string),
        netHeight: parseFloat(row.netHeight as unknown as string),
        netDepth: parseFloat(row.netDepth as unknown as string),
        assetPresentValueAmount: parsedAssetPresentValueAmount,
      };
    } catch (error) {
      console.log(error)
      throw (error)
    }
  };

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    // eslint-disable-next-line consistent-return
    await Promise.all(
      rows.map(
        async (
          row: AssetInput & { author: string } & { assetPresentValueAmount: string } & {
            assetPresentValueCurrency: string;
          },
          index: number
        ) => {
          const entities = await parseObjectToCreateEntity(row, getOrCreateRegistry);
          const parsedRow = await parseRow({ row, index });

          const {
            assetPresentValueAmount: assetPresentValueAmountRow,
            coverType: coverTypeRow,
            authorEntityId,
            assetPresentValueCurrency,
            ...assetInput
          } = parsedRow;

          const { data, errors } = await createAsset({
            variables: {
              input: {
                assetData: {
                  ...assetInput,
                  authorEntityId,
                  validated: false,
                  creationDateNotes: assetInput.creationPeriod,
                  carryingValueAmount: {
                    amount: Number(assetPresentValueAmountRow),
                    currency: assetPresentValueCurrency ?? "EUR",
                  },
                },
              },
            },
          });

          if (!data.createAsset) {
            const errorsString = errors.map(({ message }) => message).join(", ");
            toast({
              title: t(`Asset at row ${index} couldn't be uploaded`),
              description: t(`Error: ${errorsString}`),
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }

          const {
            createAsset: { asset },
          } = data;
          const { id, title, author, creationPeriod } = asset;
          const { description, name, notes } = methods.getValues();

          addExistingOrNewAsset({
            ...data.createAsset.asset,
            gqlId: data.createAsset.asset.id,
          });

          /* append({
          asset: id,
          title,
          author,
          creationPeriod,
          insuredValue: {
            amount: Number(assetPresentValueAmountRow),
            currency: assetPresentValueCurrency ?? "EUR",
          },
          status: "NEW",
        }); */
        }
      ) // fine rows.map()
    ); // fine Promise.all()
  }; // fine processCsvRows

  const BATCH_ACTIONS = [
    {
      name: <Trans>Remove from collection</Trans>,
      action: (selectedRows: { original: AssetNode }[]) => {
        selectedRows.map(async ({ original: { id } }) => {
          await onDetachAssetFromCollection(id);
          return null;
        });
      },
    },
  ];

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <ValidationProvider schema={CollectionInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <FieldGroup
                title={t("Collection Details")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="name" label={t("Name")} required />
                    <FormInputHook name="description" label={t("Description")} />
                    <FormInputHook name="notes" label={t("Notes")} />
                    <FormInputHook name="image.0.file" label={t("Image")} type="file" />
                  </VStack>
                </VStack>
              </FieldGroup>

              <FieldGroup title={t("Assets")} subtitle={t("Lorem ipsum dolor sit amet")}>
                <VStack width="full" align="left">
                  <VStack width="full" align="left">
                    <Table
                      hiddenColumns={HIDDEN_FIELDS}
                      batchActions={BATCH_ACTIONS}
                      columns={columns}
                      data={preloadedAssets.flatMap((edge) => edge?.node)}
                      deleteAction={onDetachAssetFromCollection}
                    />

                    <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                      <Button onClick={onOpenExistingAssetDrawer} variant="primary">
                        <Trans>Choose new object</Trans>
                      </Button>
                      <Button onClick={onOpenNewAssetDrawer} variant="primary">
                        <Trans>Create and add new object</Trans>
                      </Button>
                      <Button onClick={() => setShowImportAssetsDrawer(true)}>
                        <Trans>Csv import</Trans>
                      </Button>
                    </ButtonGroup>
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
            <FormFooter
              data={updateCollectionData}
              loading={loading}
              errors={[error]}
              title="Collection entry"
            />
          </form>
        </ValidationProvider>
      </FormProvider>

      {/**
       * Per lo meno il NewAssetDrawer DEVE stare fuori dal tag <form> (e quindi da ValidationProvider e FormProvider)
       * perche' altrimenti il submit del drawer causa il submit della form esterna.
       * Poi in generale non e' certo una buona idea annidare due form...
       */}
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addAsset={addExistingOrNewAsset}
        choosenAssets={choosenAssets}
      />
      <NewAssetDrawer
        show={userWantsToCreateAssets}
        onClose={() => setUserWantsToCreateAssets(false)}
        addAsset={addExistingOrNewAsset}
      />
      <ImportAssetsDrawer
        show={showImportAssetsDrawer}
        onClose={() => setShowImportAssetsDrawer(false)}
        processChunk={processCsvRows}
        fields={IMPORT_FIELDS}
      />

      {assetAdded && (
        <Alert status="warning">
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="Press the 'save' button to finalize the changes" />
            </Text>
          </VStack>
        </Alert>
      )}
    </Stack>
  );
};

export default Page;
