/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import { AssetDocumentInput } from 'graphql/queries/generated/queries';
import { Trans, useTranslation } from 'react-i18next';
import {  /* Heading, */ Heading, Text, VStack } from '@chakra-ui/react';
import { Table } from "components/ui";
import getColumns from "helpers/getColumns";
import {
  ELEMENTS_CUSTOM_COLUMNS,
  ELEMENTS_CUSTOM_FIELDS,
  ELEMENTS_HIDDEN_FIELDS,
} from './ElementsCustomColumns';
// import BorderedBox from "./BorderedBox";

interface AssetElementsValuesTableProps {
  data: any[];
  mode?: "view" | "edit";
  // remove?: UseFieldArrayRemove | ((arg0: number) => void);
  onDelete: (id: string) => any;
  // batchActions?: {
  //   name: string | React.ReactNode;
  //   action: (rows: { original: { id: string } }[]) => void;
  // }[];
  viewAction: (row: any) => any;
  editAction: (row: any) => any;
}



const OnViewAssetElementsTable = ({
  data,
  mode = "edit",
  onDelete,
  viewAction,
  editAction
}: AssetElementsValuesTableProps) => {
  const { t } = useTranslation();
  if (!data || data?.length === 0)
    return (

      // <Heading size="md" alignSelf="flex-start">
      <VStack alignItems="flex-start" w="full" pr={8}>
        <Heading size="md" alignSelf="flex-start">
          <Trans>Asset elements</Trans>
        </Heading>
        <Text><Trans>No object element has been added yet.</Trans></Text>

      </VStack>
      // </Heading>

    );


  const customColumns = ELEMENTS_CUSTOM_COLUMNS;
  const customFields = ELEMENTS_CUSTOM_FIELDS;
  const hiddenFields = ELEMENTS_HIDDEN_FIELDS;


  const columns = getColumns<any>({
    data: data[0],
    customColumns,
    customFields,
  })

  return (
      <VStack alignItems="flex-start" w="full" pr={8}>
        <Heading size="md" alignSelf="flex-start">
          <Trans>Asset elements</Trans>
        </Heading>
        <Table
          batchActions={[]}
          hiddenColumns={hiddenFields}
          columns={columns}
          data={data}
          viewAction={viewAction}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          deleteAction={(id: string) => onDelete(id)}
          editAction={editAction}
        />
      </VStack>

  );
};


export default OnViewAssetElementsTable;