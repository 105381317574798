import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

const SBox = ({ children, p = "8" }: { children: ReactNode; p?: string }) => (
  <Box
    w="full"
    h="full"
    borderWidth="1px"
    borderColor="gray.200"
    borderRadius="xl"
    flex="1"
    p={p}
    overflowY="scroll"
  >
    {children}
  </Box>
);

export default SBox;
