import {
  ButtonGroup,
  Flex,
  IconButton,
  Stack,
  Text,
  Image,
  Input,
  Button,
  Divider,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import logoSVG from "assets/logo.svg";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => (
  <Flex
    as="footer"
    role="contentinfo"
    px={{ base: "12", md: "16" }}
    bgColor="#24292f"
    direction="column"
  >
    <Stack
      spacing="16"
      direction={{ base: "column", md: "row" }}
      justify="flex-start"
      py={{ base: "12", md: "16" }}
      w="full"
    >
      <Stack spacing={{ base: "6", md: "8" }} align="start" direction="column">
        <Image src={logoSVG} h="3rem" />
        <Text color="white" my="4" fontWeight="semibold" maxW="20vw">
          <Trans>
            SpeakART rivoluziona il settore dei beni da collezione rendendo le informazioni sicure e
            affidabili nel tempo
          </Trans>
        </Text>
      </Stack>
      <Stack
        direction={{ base: "column-reverse", md: "column", lg: "row" }}
        spacing={{ base: "12", md: "8" }}
      >
        <Stack direction="row" spacing="8">
          <Stack spacing="4" minW="36" flex="1">
            <Text fontSize="sm" fontWeight="semibold" color="white">
              Product
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              <Link to="/">
                <Text color="white">
                  <Trans>How it works</Trans>
                </Text>
              </Link>
              <Link to="/">
                <Text color="white">
                  <Trans>Pricing</Trans>
                </Text>
              </Link>
              <Link to="/">
                <Text color="white">
                  <Trans>Use Cases</Trans>
                </Text>
              </Link>
            </Stack>
          </Stack>
          <Stack spacing="4" minW="36" flex="1">
            <Text fontSize="sm" fontWeight="semibold" color="white">
              Legal
            </Text>
            <Stack spacing="3" shouldWrapChildren>
              <Link to="/">
                <Text color="white">
                  <Trans>Privacy</Trans>
                </Text>
              </Link>
              <Link to="/">
                <Text color="white">
                  <Trans>Terms</Trans>
                </Text>
              </Link>
              <Link to="/">
                <Text color="white">
                  <Trans>License</Trans>
                </Text>
              </Link>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing="4">
          <Text fontSize="sm" fontWeight="semibold" color="white">
            Stay up to date
          </Text>
          <Stack spacing="4" direction={{ base: "column", sm: "row" }} maxW={{ lg: "360px" }}>
            <Input placeholder="Enter your email" type="email" required />
            <Button variant="primary" type="submit" flexShrink={0}>
              Subscribe
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <Divider />
    <Stack
      pt="8"
      pb="12"
      justify="space-between"
      direction={{ base: "column-reverse", md: "row" }}
      align="center"
    >
      <Text fontSize="sm" color="white">
        &copy; {new Date().getFullYear()} SpeakART S.r.l., All rights reserved.
      </Text>
      <ButtonGroup variant="ghost">
        <IconButton
          as="a"
          href="#"
          aria-label="LinkedIn"
          icon={<FaLinkedin fontSize="1.25rem" />}
        />
        <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
        <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
      </ButtonGroup>
    </Stack>
  </Flex>
);

export default Footer;
