/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { MediaInput } from "graphql/queries/generated/queries";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
interface AnyObject {
    [key: string]: any
}
export default (values : AnyObject) => {
    const mediaData = { ...values };

    switch(mediaData.mediaType) {
        case 'IMAGE':
          mediaData.audioVideoFileType = "";
          mediaData.creationDate = "";
          mediaData.fileAuthor = "";
          mediaData.fileSize = "";
          mediaData.photographicEquipment = "";
          mediaData.matrixType = ""
          mediaData.isGlassPlexyAcetate = Boolean(false);
          break;
        case 'AUDIO_VIDEO':
          mediaData.isMainImage = Boolean(false);
          mediaData.imageType = "";
          mediaData.photographicEquipment = "";
          mediaData.matrixType = ""
          mediaData.isGlassPlexyAcetate = Boolean(false);
          break;
        case 'FINGERPRINT':
          mediaData.isMainImage = Boolean(false);
          mediaData.imageType = "";
          mediaData.audioVideoFileType = "";
          mediaData.creationDate = "";
          mediaData.fileAuthor = "";
          mediaData.fileSize = "";
          break;
        default:
      }

      return mediaData as Partial<MediaInput>;
}