import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormInputHook, Table } from "components/ui";
import { useState, useEffect } from "react";
import cleanObject from "helpers/cleanObject";
import {
  useDeleteCompanyContactMutation,
  useGetCompanyContactsByRegistryIdLazyQuery,
  useCreateCompanyContactMutation,
  useUpdateCompanyContactMutation,
  useUpdateRegistryMutation,
  useCreateRegistryWithCompanyContactMutation,
  RegistryInput,
  CompanyContactInput,
  Scalars,
  RegistryNode,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { REGISTRY_ROUTES } from "routes/constants";
import { useNavigate } from "react-router-dom";
import {
  REGISTRY_CUSTOM_FIELDS,
  REGISTRY_HIDDEN_FIELDS,
  REGISTRY_CUSTOM_COLUMNS,
} from "../shared/RegistryDatatableCostants";

interface CompanyContactFieldsProps {
  registryId: string;
}

interface FlatCompanyContact {
  id?: string;
  main?: boolean;
  relatedCompanyId?: string;
  companyContactId?: string;
  fullName?: string;
  alias?: string;
  role?: string;
  phone?: string;
  mobilePhone?: string;
  email?: string;
  note?: string;
  webSite?: string;
  printCode?: string;
  printName?: string;
  countryCode?: string;
  taxId?: string;
  billingAddress?: string;
}

interface FlatSelectCompanyContact {
  companyContact: Scalars["ID"];
  main?: boolean;
}

function FlatCompanyContactInputSchema(): yup.SchemaOf<FlatCompanyContact> {
  return yup.object({
    main: yup.boolean().defined(),
    fullName: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable()
      .required(),
    alias: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    role: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    phone: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    mobilePhone: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    email: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    id: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    relatedCompanyId: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    companyContactId: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    note: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    webSite: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    printCode: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    printName: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    countryCode: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    taxId: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
    billingAddress: yup
      .string()
      .transform((value: string) => (value === null ? undefined : value))
      .nullable(),
  });
}

function FlatCompanyContactSelectInputSchema(): yup.SchemaOf<FlatSelectCompanyContact> {
  return yup.object({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    main: yup.boolean().defined(),
    companyContact: yup.string().required(),
  });
}

const CompanyContactForm = ({ registryId }: CompanyContactFieldsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const {
    isOpen: isOpenSelectModal,
    onOpen: onOpenSelectModal,
    onClose: onCloseSelectModal,
  } = useDisclosure();

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [newCompanyContactButtonDisabled, setNewCompanyContactButtonDisabled] = useState(true);
  const [selectCompanyContactButtonDisabled, setSelectCompanyContactButtonDisabled] =
    useState(true);

  const [loadCompanyContacts, refetch] = useGetCompanyContactsByRegistryIdLazyQuery();
  const [
    createCompanyContact,
    {
      data: createCompanyContactData,
      loading: createCompanyContactLoading,
      error: createCompanyContactError,
    },
  ] = useCreateCompanyContactMutation({
    ...handleMutation("Company created!"),
  });
  const [
    updateCompanyContact,
    {
      data: updateCompanyContactData,
      loading: updateCompanyContactLoading,
      error: updateCompanyContactError,
    },
  ] = useUpdateCompanyContactMutation({
    ...handleMutation("Company updated!"),
  });
  const [
    updateRegistry,
    { data: updateRegistryData, loading: updateRegistryLoading, error: updateRegistryError },
  ] = useUpdateRegistryMutation({
    ...handleMutation("Registry updated!"),
  });
  const [deleteCompanyContact, { loading: deleteLoading }] = useDeleteCompanyContactMutation({
    ...handleMutation("Company deleted!"),
  });
  const [
    createRegistryWithCompanyContact,
    {
      data: createRegistryWithCompanyContactData,
      loading: createRegistryWithCompanyContactLoading,
      error: createRegistryWithCompanyContactError,
    },
  ] = useCreateRegistryWithCompanyContactMutation({
    ...handleMutation("Registry and company created!"),
  });

  const [companyContacts, setCompanyContacts] = useState<Array<FlatCompanyContact>>([]);

  const editMethods = useForm<FlatCompanyContact>({
    defaultValues: {
      fullName: "",
      alias: "",
      role: "",
      phone: "",
      mobilePhone: "",
      email: "",
      main: false,
      relatedCompanyId: registryId,
      companyContactId: "",
      note: "",
      webSite: "",
      printCode: "",
      printName: "",
      countryCode: "",
      taxId: "",
      billingAddress: "",
    },
    resolver: yupResolver(FlatCompanyContactInputSchema()),
    mode: "onChange",
  });

  const selectMethods = useForm<FlatSelectCompanyContact>({
    defaultValues: {
      companyContact: "",
    },
    resolver: yupResolver(FlatCompanyContactSelectInputSchema()),
    mode: "onChange",
  });

  const { setValue: setValueEdit } = editMethods;
  const { setValue: setValueSelect } = selectMethods;

  // eslint-disable-next-line consistent-return
  const loadParsedCompanyContacts = async (registryId: string) => {
    const {
      data: companyContacts,
      loading: companyContactsLoading,
      error: companyContactsError,
    } = await loadCompanyContacts({
      variables: {
        registryId,
      },
    });

    if (!companyContacts || !companyContacts.allCompanyContacts) return null;

    const {
      allCompanyContacts: { edges },
    } = companyContacts;

    setCompanyContacts(
      edges.flatMap(({ node }) => ({
        id: node.id,
        main: node.main,
        relatedCompanyId: node.relatedCompany.id,
        companyContactId: node.companyContact.id,
        fullName: node.companyContact.fullName,
        alias: node.companyContact.alias,
        role: node.companyContact.role,
        phone: node.companyContact.phone,
        mobilePhone: node.companyContact.mobilePhone,
        email: node.companyContact.email,
        note: node.companyContact.note,
        webSite: node.companyContact.webSite,
        printCode: node.companyContact.printCode,
        printName: node.companyContact.printName,
        countryCode: node.companyContact.countryCode,
        taxId: node.companyContact.taxId,
        billingAddress: node.companyContact.billingAddress,
      }))
    );

    return companyContacts;
  };

  let dataToGetColumns;
  let columns;

  if (companyContacts && companyContacts.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    dataToGetColumns = companyContacts[0];

    columns = getColumns({
      data: dataToGetColumns,
      customFields: REGISTRY_CUSTOM_FIELDS,
      customColumns: REGISTRY_CUSTOM_COLUMNS,
    });
  }

  useEffect(() => {
    if (typeof registryId !== "undefined" && registryId !== "") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadParsedCompanyContacts(registryId);
      setNewCompanyContactButtonDisabled(false);
      setSelectCompanyContactButtonDisabled(false);
    } else {
      setNewCompanyContactButtonDisabled(true);
      setSelectCompanyContactButtonDisabled(true);
    }

    return undefined;
  }, []);

  const onEditModalOpen = ({
    row: { original },
  }: {
    row: {
      original: RegistryNode & {
        main: boolean;
        relatedCompanyId: string;
        companyContactId: string;
      };
    };
  }) => {
    onOpenEditModal();
    setSelectedId(original?.id);
    setValueEdit("main", original?.main);
    setValueEdit("fullName", original?.fullName);
    setValueEdit("alias", original?.alias);
    setValueEdit("role", original?.role);
    setValueEdit("phone", original?.phone);
    setValueEdit("mobilePhone", original?.mobilePhone);
    setValueEdit("email", original?.email);
    setValueEdit("relatedCompanyId", original?.relatedCompanyId);
    setValueEdit("companyContactId", original?.companyContactId);
    setValueEdit("note", original?.note);
    setValueEdit("webSite", original?.webSite);
    setValueEdit("printCode", original?.printCode);
    setValueEdit("printName", original?.printName);
    setValueEdit("countryCode", original?.countryCode);
    setValueEdit("taxId", original?.taxId);
    setValueEdit("billingAddress", original?.billingAddress);
  };

  const onEditModalClose = () => {
    setSelectedId(null);
    setValueEdit("main", false);
    setValueEdit("fullName", "");
    setValueEdit("alias", "");
    setValueEdit("role", "");
    setValueEdit("phone", "");
    setValueEdit("mobilePhone", "");
    setValueEdit("email", "");
    setValueEdit("relatedCompanyId", "");
    setValueEdit("companyContactId", "");
    setValueEdit("note", "");
    setValueEdit("webSite", "");
    setValueEdit("printCode", "");
    setValueEdit("printName", "");
    setValueEdit("countryCode", "");
    setValueEdit("taxId", "");
    setValueEdit("billingAddress", "");

    onCloseEditModal();
  };

  const onSelectModalOpen = () => {
    onOpenEditModal();
  };

  const onSelectModalClose = () => {
    setSelectedId(null);
    setValueSelect("main", false);
    setValueSelect("companyContact", "");

    onCloseSelectModal();
  };

  const onEditSubmit: SubmitHandler<FlatCompanyContact> = async (values, e) => {
    const companyContactData = cleanObject(values) as Partial<FlatCompanyContact>;
    let contacts;
    let response;

    const registryData: RegistryInput = {
      fullName: companyContactData.fullName,
      alias: companyContactData.alias,
      role: companyContactData.role,
      phone: companyContactData.phone,
      mobilePhone: companyContactData.mobilePhone,
      email: companyContactData.email,
      note: companyContactData.note,
      webSite: companyContactData.webSite,
      printCode: companyContactData.printCode,
      printName: companyContactData.printName,
      countryCode: companyContactData.countryCode,
      taxId: companyContactData.taxId,
      billingAddress: companyContactData.billingAddress,
      isCompany: false,
    };

    if (selectedId) {
      try {
        await updateRegistry({
          variables: {
            input: {
              id: companyContactData.companyContactId,
              registryData,
            },
          },
        });

        response = await updateCompanyContact({
          variables: {
            input: {
              companyContactData: {
                main: companyContactData.main,
                relatedCompany: registryId,
                companyContact: companyContactData.companyContactId,
              },
              id: selectedId,
            },
          },
        });

        if (
          response.data &&
          response.data.updateCompanyContact &&
          response.data.updateCompanyContact.companyContact &&
          response.data.updateCompanyContact.companyContact.id
        ) {
          contacts = await loadParsedCompanyContacts(registryId);

          onEditModalClose();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const registryData: RegistryInput = {
        fullName: companyContactData.fullName,
        alias: companyContactData.alias,
        role: companyContactData.role,
        phone: companyContactData.phone,
        mobilePhone: companyContactData.mobilePhone,
        email: companyContactData.email,
        webSite: companyContactData.webSite,
        printCode: companyContactData.printCode,
        printName: companyContactData.printName,
        countryCode: companyContactData.countryCode,
        taxId: companyContactData.taxId,
        billingAddress: companyContactData.billingAddress,
        isCompany: false,
      };

      const companyContactDataForMutation: CompanyContactInput = {
        main: companyContactData.main,
        relatedCompany: registryId,
        companyContact: "",
      };

      try {
        response = await createRegistryWithCompanyContact({
          variables: {
            input: {
              registryData,
              companyContactData: companyContactDataForMutation,
            },
          },
        });

        if (
          response.data &&
          response.data.createRegistryWithCompanyContact &&
          response.data.createRegistryWithCompanyContact.companyContact &&
          response.data.createRegistryWithCompanyContact.companyContact.id &&
          response.data.createRegistryWithCompanyContact.companyContact.relatedCompany &&
          response.data.createRegistryWithCompanyContact.companyContact.relatedCompany.id
        ) {
          contacts = await loadParsedCompanyContacts(registryId);

          onEditModalClose();
        }
      } catch (e) {
        console.error(e);
      }
    }

    return contacts;
  };

  const onSelectSubmit: SubmitHandler<FlatSelectCompanyContact> = async (values, e) => {
    let contacts;
    let response;

    const companyContactData = cleanObject(values) as Partial<FlatSelectCompanyContact>;

    try {
      response = await createCompanyContact({
        variables: {
          input: {
            companyContactData: {
              main: companyContactData.main,
              relatedCompany: registryId,
              companyContact: companyContactData.companyContact,
            },
          },
        },
      });

      if (
        response.data &&
        response.data.createCompanyContact &&
        response.data.createCompanyContact.companyContact &&
        response.data.createCompanyContact.companyContact &&
        response.data.createCompanyContact.companyContact.id
      ) {
        contacts = await loadParsedCompanyContacts(registryId);

        onSelectModalClose();
      }
    } catch (e) {
      console.log(e);
    }

    return contacts;
  };

  const onDelete = async (id: string) => {
    await deleteCompanyContact({
      variables: {
        input: {
          id,
        },
      },
    });

    const companyContacts = await loadParsedCompanyContacts(registryId);
    return companyContacts;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const handleEditSubmit = editMethods.handleSubmit(onEditSubmit, onError);
  const handleSelectSubmit = selectMethods.handleSubmit(onSelectSubmit, onError);

  useEffect(() => console.log({ selectedId }), [selectedId]);
  return (
    <>
      <Table
        hiddenColumns={REGISTRY_HIDDEN_FIELDS}
        columns={columns}
        data={companyContacts}
        deleteAction={onDelete}
        viewAction={({ original: { companyContactId } }: { original: FlatCompanyContact }) =>
          navigate(`${REGISTRY_ROUTES.REGISTRY}/${companyContactId}`)
        }
        // editAction={({ original: { companyContactId } }: { original: FlatCompanyContact }) =>
        //   navigate(`${REGISTRY_ROUTES.REGISTRY}/${companyContactId}`)
        // }
        editAction={onEditModalOpen}
      />

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button
          onClick={onOpenEditModal}
          isDisabled={newCompanyContactButtonDisabled}
          variant="primary"
        >
          <Trans>Add new contact</Trans>
        </Button>
        <Button
          onClick={onOpenSelectModal}
          isDisabled={selectCompanyContactButtonDisabled}
          variant="primary"
        >
          <Trans>Add existing contact</Trans>
        </Button>
      </ButtonGroup>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenEditModal}
        size="5xl"
        onClose={onEditModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? <Trans>Update contact</Trans> : <Trans>Add new contact</Trans>}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pb={6}>
            <FormProvider {...editMethods}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form id="addCompanyContact" onSubmit={handleEditSubmit}>
                <Box maxW="6xl">
                  <Stack spacing="4" divider={<StackDivider />}>
                    <HStack alignItems="flex-end">
                      <FormInputHook label={t("Main")} name="main" type="checkbox" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label={t("Full name")} name="fullName" />
                      <FormInputHook label={t("Alias")} name="alias" />
                      <FormInputHook label={t("Role")} name="role" />
                      <FormInputHook label={t("Web Site")} name="webSite" />
                      <FormInputHook label={t("Note")} name="note" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label={t("Print Code")} name="printCode" />
                      <FormInputHook label={t("Print name")} name="printName" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label={t("Country code")} name="countryCode" />
                      <FormInputHook label={t("Tax ID")} name="taxId" />
                      <FormInputHook label={t("Billing Address")} name="billingAddress" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label={t("Phone")} name="phone" />
                      <FormInputHook label={t("Mobile Phone")} name="mobilePhone" />
                      <FormInputHook label={t("Email")} name="email" />
                    </HStack>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button variant="primary" onClick={handleEditSubmit} mr={1}>
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onEditModalClose}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenSelectModal}
        size="xs"
        onClose={onSelectModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>Select existing contact</Trans>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pb={6}>
            <FormProvider {...selectMethods}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form id="selectCompanyContact" onSubmit={handleSelectSubmit}>
                <Box maxW="6xl">
                  <Stack spacing="4" divider={<StackDivider />}>
                    <FormInputHook label={t("Main")} name="main" type="checkbox" />
                    <FormInputHook
                      name="companyContact"
                      label={t("Select contact")}
                      type="registry"
                      required
                      disableCreation
                    />
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button variant="primary" onClick={handleSelectSubmit} mr={1}>
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onSelectModalClose}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CompanyContactForm;
