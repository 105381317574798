import { Route } from "use-react-router-breadcrumbs";
import Layout from "layouts/Layout";
import RequireAuth from "middleware/RequireAuth";
import {
  NotFoundInternalPage,
  OnboardPage,
  LoginPage,
  NotFoundPage,
  RecoverPasswordPage,
  UsersEditPage,
  UsersInvitePage,
  UsersNewPage,
  UsersPage,
  UsersViewPage,
  PCPoliciesEditPage,
  PCPoliciesNewPage,
  PCPoliciesPage,
  PCPoliciesViewPage,
  TEPoliciesEditPage,
  TEPoliciesNewPage,
  TEPoliciesPage,
  TEPoliciesViewPage,
  AssetsPage,
  SpinIndexPage,
} from "pages";
import RegistriesPage from "pages/registry";
import RegistriesAuthorsPage from "pages/registry/authors";
import RegistriesCompaniesPage from "pages/registry/companies";
import RegistriesPeoplePage from "pages/registry/people";
import RegistryViewPage from "pages/registry/view";
import EditRegistryPage from "pages/registry/edit";
import NewRegistryPage from "pages/registry/new";
import CertificatesPage from "pages/spin/certificates";
import InventoriesPage from "pages/spin/inventories";
import PcPoliciesObjectsPage from "pages/spin/pc-policies-objects";
import TePoliciesObjectsPage from "pages/spin/te-policies-objects";

import { Routes, useRoutes } from "react-router-dom";
import { BCrumb } from "components/ui";
import CollectionsPage from "pages/collection";
import NewCollectionPage from "pages/collection/new";
import EditCollectionPage from "pages/collection/edit";
import CollectionViewPage from "pages/collection/view";
import ExhibitionsPage from "pages/exhibition";
import NewExhibitionPage from "pages/exhibition/new";
import EditExhibitionPage from "pages/exhibition/edit";
import ExhibitionViewPage from "pages/exhibition/view";
import AssetsNewPage from "pages/assets/new";
import AssetEditPage from "pages/assets/edit";
import AssetViewPage from "pages/assets/view";
import CertificateTemplatePage from "pages/spin/certificates/templates";
import ConditionStatusReportsPage from "pages/crsr";
import NewConditionStatusReportPage from "pages/crsr/new";
import EditConditionStatusReportPage from "pages/crsr/edit";
import ConditionStatusReportViewPage from "pages/crsr/view";
import AppraisalPage from "pages/appraisal";
import AppraisalViewPage from "pages/appraisal/view";
import NewAppraisalPage from "pages/appraisal/new";
import EditAppraisalPage from "pages/appraisal/edit";
import RestorationPage from "pages/restoration";
import NewRestorationPage from "pages/restoration/new";
import EditRestorationPage from "pages/restoration/edit";
import RestorationViewPage from "pages/restoration/view";

import assetsSVG from "assets/icons/all_assets.svg";
import appraisalSVG from "assets/icons/appraisal.svg";
import collectionSVG from "assets/icons/collection.svg";
import crSVG from "assets/icons/cr.svg";
import exhibitionSVG from "assets/icons/exhibition.svg";
import restorationSVG from "assets/icons/restoration.svg";
import policySVG from "assets/icons/policy.svg";
import teSVG from "assets/icons/te.svg";
import pcSVG from "assets/icons/pc.svg";
import PolicyRedirect from "pages/spin/policy-redirect";
import { MGMT_ROUTES, SPIN_ROUTES, REGISTRY_ROUTES, USER_ROUTES, OTHER_ROUTES } from "./constants";
function ProtectedPage() {
  return <h3>Protected</h3>;
}

interface RoutingProps {
  setCurrentLocale?: (locale: string) => void;
}

const createRoutes = ({ createBreadcrumbs = false }: { createBreadcrumbs?: boolean }) => [
  {
    path: MGMT_ROUTES.ASSETS,
    element: createBreadcrumbs ? <BCrumb title="All objects" image={assetsSVG} /> : <AssetsPage />,
    breadcrumb: "Assets",
  },
  {
    path: MGMT_ROUTES.ASSETS_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new asset" image={assetsSVG} />
    ) : (
      <AssetsNewPage />
    ),
  },
  {
    path: MGMT_ROUTES.ASSETS_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit asset" image={assetsSVG} />
    ) : (
      <AssetEditPage />
    ),
  },
  {
    path: MGMT_ROUTES.ASSETS_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb showOnlyBackBtn /> // <BCrumb title="View Temporary Exhibition Policy" />
    ) : (
      <AssetViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.COLLECTIONS,
    element: createBreadcrumbs ? (
      <BCrumb title="All Collections" image={collectionSVG} />
    ) : (
      <CollectionsPage />
    ),
  },
  {
    path: MGMT_ROUTES.COLLECTIONS_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new collection" image={collectionSVG} />
    ) : (
      <NewCollectionPage />
    ),
  },
  {
    path: MGMT_ROUTES.COLLECTIONS_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit a collection entry" image={collectionSVG} />
    ) : (
      <EditCollectionPage />
    ),
  },
  {
    path: MGMT_ROUTES.COLLECTIONS_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb title="View Collection" image={collectionSVG} />
    ) : (
      <CollectionViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.EXHIBITIONS,
    element: createBreadcrumbs ? (
      <BCrumb title="All Exhibitions" image={exhibitionSVG} />
    ) : (
      <ExhibitionsPage />
    ),
  },
  {
    path: MGMT_ROUTES.EXHIBITIONS_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new exhibition" image={exhibitionSVG} />
    ) : (
      <NewExhibitionPage />
    ),
  },
  {
    path: MGMT_ROUTES.EXHIBITIONS_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit a exhibition entry" image={exhibitionSVG} />
    ) : (
      <EditExhibitionPage />
    ),
  },
  {
    path: MGMT_ROUTES.EXHIBITIONS_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb title="View Exhibition" image={exhibitionSVG} />
    ) : (
      <ExhibitionViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.LOGISTIC,
    element: createBreadcrumbs ? <BCrumb title="All logistics" /> : <ProtectedPage />,
  },
  {
    path: MGMT_ROUTES.INSURANCE,
    element: createBreadcrumbs ? <BCrumb title="All insurance" /> : <ProtectedPage />,
  },
  {
    path: MGMT_ROUTES.APPRAISAL,
    element: createBreadcrumbs ? (
      <BCrumb title="All appraisal" image={appraisalSVG} />
    ) : (
      <AppraisalPage />
    ),
  },
  {
    path: MGMT_ROUTES.APPRAISAL_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new appraisal" image={appraisalSVG} />
    ) : (
      <NewAppraisalPage />
    ),
  },
  {
    path: MGMT_ROUTES.APPRAISAL_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit an appraisal entry" image={appraisalSVG} />
    ) : (
      <EditAppraisalPage />
    ),
  },
  {
    path: MGMT_ROUTES.APPRAISAL_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb title="View appraisal" image={appraisalSVG} />
    ) : (
      <AppraisalViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.RESTORATION,
    element: createBreadcrumbs ? (
      <BCrumb title="All restorations" image={restorationSVG} />
    ) : (
      <RestorationPage />
    ),
  },
  {
    path: MGMT_ROUTES.RESTORATION_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new restoration" image={restorationSVG} />
    ) : (
      <NewRestorationPage />
    ),
  },
  {
    path: MGMT_ROUTES.RESTORATION_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit a restoration entry" image={restorationSVG} />
    ) : (
      <EditRestorationPage />
    ),
  },
  {
    path: MGMT_ROUTES.RESTORATION_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb title="View restoration" image={restorationSVG} />
    ) : (
      <RestorationViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.CRSR,
    element: createBreadcrumbs ? (
      <BCrumb title="All condition/status reports" image={crSVG} />
    ) : (
      <ConditionStatusReportsPage />
    ),
  },
  {
    path: MGMT_ROUTES.CRSR_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new condition/status report" image={crSVG} />
    ) : (
      <NewConditionStatusReportPage />
    ),
  },
  {
    path: MGMT_ROUTES.CRSR_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit a condition/status report entry" image={crSVG} />
    ) : (
      <EditConditionStatusReportPage />
    ),
  },
  {
    path: MGMT_ROUTES.CRSR_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb title="View condition/status report" image={crSVG} />
    ) : (
      <ConditionStatusReportViewPage />
    ),
  },
  {
    path: MGMT_ROUTES.QUOTATION,
    element: createBreadcrumbs ? <BCrumb title="All quotation" /> : <ProtectedPage />,
  },
  {
    path: MGMT_ROUTES.DOCS,
    element: createBreadcrumbs ? <BCrumb title="All docs" /> : <ProtectedPage />,
  },
  {
    path: SPIN_ROUTES.SPIN,
    element: createBreadcrumbs ? (
      <BCrumb title="All Insurances" image={policySVG} />
    ) : (
      <SpinIndexPage />
    ),
    breadcrumb: "Insurance",
  },
  {
    path: SPIN_ROUTES.POLICY_REDIRECT_VIEW,
    element: createBreadcrumbs ? <BCrumb showOnlyBackBtn /> : <PolicyRedirect />,
  },
  {
    path: SPIN_ROUTES.POLICY_REDIRECT_EDIT,
    element: createBreadcrumbs ? <BCrumb showOnlyBackBtn /> : <PolicyRedirect isEdit />,
  },
  {
    path: SPIN_ROUTES.POLICY_TE,
    element: createBreadcrumbs ? (
      <BCrumb title="Temporary Exhibition Policies" image={teSVG} />
    ) : (
      <TEPoliciesPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_TE_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit Temporary Exhibition Policy" image={teSVG} />
    ) : (
      <TEPoliciesEditPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_TE_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Temporary Exhibition Policy" image={teSVG} />
    ) : (
      <TEPoliciesNewPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_TE_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb showOnlyBackBtn /> // <BCrumb title="View Temporary Exhibition Policy" />
    ) : (
      <TEPoliciesViewPage />
    ),
  },

  {
    path: SPIN_ROUTES.CERTIFICATES,
    element: createBreadcrumbs ? <BCrumb title="All Certificates" /> : <CertificatesPage />,
  },
  {
    path: SPIN_ROUTES.TEMPLATES,
    element: createBreadcrumbs ? <BCrumb title="All Certificates" /> : <CertificateTemplatePage />,
  },

  {
    path: SPIN_ROUTES.POLICY_PC,
    element: createBreadcrumbs ? (
      <BCrumb title="All Permanent Collection Policies" image={pcSVG} />
    ) : (
      <PCPoliciesPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_PC_EDIT,
    element: createBreadcrumbs ? (
      <BCrumb title="Edit Permanent Collection Policy" image={pcSVG} />
    ) : (
      <PCPoliciesEditPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_PC_NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Permanent Collection Policy" image={pcSVG} />
    ) : (
      <PCPoliciesNewPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_PC_VIEW,
    element: createBreadcrumbs ? (
      <BCrumb showOnlyBackBtn /> // <BCrumb title="View Permanent Collection Policy" />
    ) : (
      <PCPoliciesViewPage />
    ),
  },

  {
    path: SPIN_ROUTES.INVENTORIES,
    element: createBreadcrumbs ? <BCrumb title="All Inventories" /> : <InventoriesPage />,
  },

  {
    path: SPIN_ROUTES.POLICY_TE_OBJECTS,
    element: createBreadcrumbs ? (
      <BCrumb title="All Temporary Exhibition Assets" image={teSVG} />
    ) : (
      <TePoliciesObjectsPage />
    ),
  },
  {
    path: SPIN_ROUTES.POLICY_PC_OBJECTS,
    element: createBreadcrumbs ? (
      <BCrumb title="All Permanent Collection Assets" image={pcSVG} />
    ) : (
      <PcPoliciesObjectsPage />
    ),
  },

  {
    path: OTHER_ROUTES.ANALYSIS,
    element: createBreadcrumbs ? <BCrumb title="Analysis" /> : <ProtectedPage />,
  },
  {
    path: REGISTRY_ROUTES.REGISTRY,
    element: createBreadcrumbs ? <BCrumb title="All Registries" /> : <RegistriesPage />,
  },
  {
    path: REGISTRY_ROUTES.PEOPLE,
    element: createBreadcrumbs ? <BCrumb title="All People" /> : <RegistriesPeoplePage />,
  },
  {
    path: REGISTRY_ROUTES.COPMANIES,
    element: createBreadcrumbs ? <BCrumb title="All Companies" /> : <RegistriesCompaniesPage />,
  },
  {
    path: REGISTRY_ROUTES.AUTHORS,
    element: createBreadcrumbs ? <BCrumb title="All Authors" /> : <RegistriesAuthorsPage />,
  },
  {
    path: REGISTRY_ROUTES.VIEW,
    element: createBreadcrumbs ? <BCrumb title="View Registry" /> : <RegistryViewPage />,
  },
  {
    path: REGISTRY_ROUTES.NEW,
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Registry entry" />
    ) : (
      <NewRegistryPage />
    ),
  },
  {
    path: REGISTRY_ROUTES.EDIT,
    element: createBreadcrumbs ? <BCrumb title="Edit a Registry entry" /> : <EditRegistryPage />,
  },

  {
    path: USER_ROUTES.USER,
    element: createBreadcrumbs ? <BCrumb title="All users" /> : <UsersPage />,
  },
  {
    path: USER_ROUTES.VIEW,
    element: createBreadcrumbs ? <BCrumb title="View User" /> : <UsersViewPage />,
  },
  {
    path: USER_ROUTES.EDIT,
    element: createBreadcrumbs ? <BCrumb title="Edit user" /> : <UsersEditPage />,
  },
  {
    path: USER_ROUTES.INVITE,
    element: createBreadcrumbs ? <BCrumb title="Invite user" /> : <UsersInvitePage />,
  },
  {
    path: USER_ROUTES.NEW,
    element: createBreadcrumbs ? <BCrumb title="Create a new user" /> : <UsersNewPage />,
  },

  { path: OTHER_ROUTES.NOT_FOUND, element: <NotFoundInternalPage /> },
];

export const appRouteObjects = createRoutes({ createBreadcrumbs: false });
export const breadcrumbRouteObjects = createRoutes({ createBreadcrumbs: true });

const Routing = ({ setCurrentLocale }: RoutingProps) => {
  const protectedRoutesGenerated = useRoutes(appRouteObjects);
  const breadcrumbsGenerated = useRoutes(breadcrumbRouteObjects);
  if (!protectedRoutesGenerated) return null;

  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLocaleChange={setCurrentLocale} />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route path="/recover-password/:token" element={<RecoverPasswordPage />} />
      <Route path="/onboard/:token" element={<OnboardPage />} />
      <Route element={<Layout onLocaleChange={setCurrentLocale} />} breadcrumb="Home">
        <Route
          path="/*"
          element={
            <RequireAuth>
              <>
                {breadcrumbsGenerated}
                {protectedRoutesGenerated}
              </>
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routing;
