import { Box, Divider, Heading, Stack, StackProps, Text } from "@chakra-ui/react";

interface FieldGroupProps extends StackProps {
  title?: string;
  subtitle?: string;
}

const FieldGroup = (props: FieldGroupProps) => {
  const { title, subtitle, children, ...flexProps } = props;
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Stack direction={{ base: "column", md: "row" }} spacing="4" py="2" {...flexProps}>
        <Box w="15rem">
          {title && (
            <Heading as="h2" fontWeight="semibold" fontSize="md" flexShrink={0}>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Text fontSize="sm" color="gray.500">
              {subtitle}
            </Text>
          )}
        </Box>
        <Box pl="12" minW="40vw" w="50%">
          {children}
        </Box>
      </Stack>
      <Divider borderColor="gray.100" borderWidth="0.5px" />
    </>
  );
};

export default FieldGroup;
