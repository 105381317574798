import { Button, ButtonGroup, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import { BorderedBox } from "components/ui";
import {
  useCreateRestorationMutation,
  RestorationInput,
  useGetAssetByIdLazyQuery,
  AssetNode,
} from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { RestorationInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { useNavigate } from "react-router-dom";
import { handleMutation, toast } from "middleware/Toaster";
import { MGMT_ROUTES } from "routes/constants";
import RestorationFields from "../shared/RestorationFields";
import SearchAssetsDrawer from "../edit/SearchAssetsDrawer";
import NewAssetDrawer from "../edit/NewAssetDrawer";


const NewRestorationPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const voidAsset: AssetNode = null;

  const [createRestoration, { data, loading, error, client, called }] =
    useCreateRestorationMutation({
      ...handleMutation("Restoration created!"),
    });

  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [userWantsToCreateAssets, setUserWantsToCreateAssets] = useState<boolean>(false);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [assetAdded, setAssetAdded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [choosenAssetBigData, setChoosenAssetBigData] = useState(voidAsset);

  let data2 = data;

  /**
   * onCompleteEvent e getAsset funzionano sia per il drawer degli
   * oggetti preesistenti sia per quello della creazione di nuovi
   * oggetti
   */

  const onCompleteEvent = (assetNode: AssetNode) => {
    toast({
      title: "Restoration not saved.",
      description: "Press the 'save' button to finalize the changes.",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });

    setDisabled(false);
    setChoosenAssetBigData(assetNode);
    setAssetAdded(true);
  };

  const [getAsset, { loading: loadingAsset, error: errorAsset, data: dataAsset }] =
    useGetAssetByIdLazyQuery({
      onCompleted: (result) => {
        onCompleteEvent(result.asset as AssetNode);
      },
    });

  const addExistingAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const onOpenDrawer = () => {
    setUserWantsToAddAssets(true);
  };

  const onOpenNewAssetDrawer = () => {
    setUserWantsToCreateAssets(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const methods = useForm<RestorationInput>({
    defaultValues: {
      restorationStatus: "draft",
      restorationCost: {
        amount: 0.0,
        currency: "EUR",
      },
    },
    resolver: yupResolver(RestorationInputSchema()),
  });

  const addNewAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof RestorationInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<RestorationInput> = async (formData, e) => {
    let selectedRestorationAsset: string = null;

    if (assetAdded) [selectedRestorationAsset] = choosenAssets;
    else selectedRestorationAsset = null;

    const result = await createRestoration({
      variables: {
        input: {
          restorationData: {
            restorationAsset: selectedRestorationAsset,
            ...formData,
          },
        },
      },
    });

    data2 = result.data;

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.RESTORATION}/edit/${result.data.createRestoration.restoration.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 1000);

    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <ValidationProvider schema={RestorationInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <BorderedBox title={t("Asset")} subtitle="Lorem ipsum dolor sit amet">
                <HStack justifyContent="space-between" alignItems="flex-start">
                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>ID</Trans>
                    </Text>
                    <Text>{assetAdded ? choosenAssetBigData.id : ""}</Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Title</Trans>
                    </Text>
                    <Text>{assetAdded ? choosenAssetBigData.title : ""}</Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Author</Trans>
                    </Text>
                    <Text>{assetAdded ? choosenAssetBigData?.authorEntity?.fullName : ""}</Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Date of creation</Trans>
                    </Text>
                    <Text>{assetAdded ? choosenAssetBigData?.actualDateAndPeriod : ""}</Text>
                  </VStack>

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button onClick={onOpenDrawer} variant="primary">
                      <Trans>Choose new object</Trans>
                    </Button>
                    <Button onClick={onOpenNewAssetDrawer} variant="primary">
                      <Trans>Create and add new object</Trans>
                    </Button>
                  </ButtonGroup>
                </HStack>
              </BorderedBox>

              <RestorationFields
                footerData={data}
                loading={loading}
                disabled={disabled}
                errors={[error]}
                watch={methods.watch}
                asset={choosenAssetBigData}
              />
            </Stack>
          </form>
        </ValidationProvider>
      </FormProvider>

      {/**
       * Per lo meno il NewAssetDrawer DEVE stare fuori dal tag <form> (e quindi da ValidationProvider e FormProvider)
       * perche' altrimenti il submit del drawer causa il submit della form esterna.
       * Poi in generale non e' certo una buona idea annidare due form...
       */}
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addAsset={addExistingAsset}
        choosenAssets={choosenAssets}
      />
      <NewAssetDrawer
        show={userWantsToCreateAssets}
        onClose={() => setUserWantsToCreateAssets(false)}
        addAsset={addNewAsset}
      />
    </Stack>
  );
};

export default NewRestorationPage;
