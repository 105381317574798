import { Skeleton, SkeletonText, VStack } from "@chakra-ui/react";

const DetailsPage = ({ loading }: { loading: boolean }) => (
  <VStack my={4} w="full" align="flex-start">
    <Skeleton w="50vw" h="10rem" />
    <SkeletonText w="50vw" h="5rem" />
    <Skeleton w="50vw" h="10rem" />
    <Skeleton w="50vw" h="10rem" />
    <Skeleton w="50vw" h="10rem" />
    <Skeleton w="50vw" h="10rem" />
    <Skeleton w="50vw" h="10rem" />
    <Skeleton w="50vw" h="10rem" />
  </VStack>
);

export default DetailsPage;
