import { Text, VStack, Image } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import noDataSVG from "assets/nodata.svg";

interface NoDataPlaceholderProps {
  title?: string;
  message?: string;
}

const NoDataPlaceholder = ({
  title = "Data not found",
  message = "Either you're not able to see these data or there's no data to see.",
}: NoDataPlaceholderProps) => (
  <VStack
    direction="column"
    backgroundColor="gray.50"
    py="12"
    rounded="lg"
    border="1px"
    borderColor="brand.200"
  >
    <Text fontSize="lg" color="brand.500" fontWeight="bold">
      <Trans>{title}</Trans>
    </Text>
    <Text fontSize="sm" color="brand.400" w="50%" align="center">
      <Trans>{message}</Trans>
    </Text>
    <Image src={noDataSVG} w="10rem" />
  </VStack>
);

export default NoDataPlaceholder;
