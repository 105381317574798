import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef, useState } from "react";

interface GeolocateAddressOptions {
  fetchAll: boolean;
}

const defaultOptions: GeolocateAddressOptions = {
  fetchAll: false,
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useGeolocation = () => {
  const [maps, setMaps] = useState<typeof google.maps | null>(null);
  const [result, setResult] = useState<google.maps.GeocoderAddressComponent[] | null>(null);
  const ref = useRef(null);
  const loader = new Loader({
    apiKey,
    version: "weekly",
    libraries: ["places"],
  });

  useEffect(() => {
    loader
      .load()
      .then((g) => {
        console.log(g);
        setMaps(g.maps);
      })
      .catch((e) => console.error(e));
  }, []);

  // function getDetails(place_id: google.maps.places.AutocompletePrediction.place_id) {
  //   const details = detailsAPI.getDetails({ placeId: place_id }, (e) => {
  //     setResult(e.address_components);
  //     ref.current = e.address_components;
  //   });
  // }

  function getDetailsWrapper(
    detailsApi: google.maps.places.PlacesService,
    maps: typeof google.maps,
    place_id: string
  ) {
    return new Promise<google.maps.GeocoderAddressComponent[]>((resolve, reject) => {
      detailsApi.getDetails({ placeId: place_id }, (e) => {
        resolve(e.address_components);
      });
    });
  }

  async function geolocateAddress(
    address: string,
    options: GeolocateAddressOptions = defaultOptions
  ) {
    try {
      const autocomplete = new maps.places.AutocompleteService();
      const results = await autocomplete.getPlacePredictions({
        input: address,
      });

      if (results.predictions.length === 0) {
        return null;
      }
      const obj = { raw: "", streetNumber: "", country: "", state: "", locality: "", route: "" };

      // if(options.fetchAll) return results
      const detailsAPI = new maps.places.PlacesService(document.createElement("div"));
      const parsedAddress: google.maps.GeocoderAddressComponent[] = await getDetailsWrapper(
        detailsAPI,
        maps,
        results.predictions[0].place_id
      );

      obj.raw = results.predictions[0].description;

      const geocodedAddressArray = parsedAddress.map(({ types, long_name, short_name }) => {
        if (types.includes("street_number")) {
          obj.streetNumber = short_name;
        }
        if (types.includes("route")) {
          obj.route = short_name;
        }
        if (types.includes("country")) {
          obj.country = short_name;
        }
        if (types.includes("administrative_area_level_1")) {
          obj.state = short_name;
        }
        if (types.includes("locality")) {
          obj.locality = short_name;
        }
        return null;
      });

      return obj;
    } catch (e) {
      return {
        raw: address,
      };
    }
  }

  return [geolocateAddress];
};

export default useGeolocation;
