/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from "@apollo/client";
import { Badge, Button, HStack, VStack, Stack, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { AssetNode, GetRestorationByIdQuery } from "graphql/queries/generated/queries";
import { FieldGroup, FormFooter, FormInputHook, MoneyInputHook } from "components/ui";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import DocumentsForm from "./Documents";
import MediaForm from "./Media";

export const restorationMotivationOptions: FormSelectOptionProps[] = [{ value: "sale", label: "Sale" }, { value: "cr", label: "CR" }, { value: "insurance_quote", label: "Insurance Quote" }, { value: "asset_analysis", label: "Asset Analysis" }, { value: "litigation_lawsuit", label: "Litigation/Lawsuit" }]

interface RestorationProps {
  watch: any,
  asset?: AssetNode,
  data?: GetRestorationByIdQuery,
  errors: ApolloError[],
  footerData: any | any[],
  loading: boolean,
  disabled?: boolean,
}

const RestorationFields = ({ watch, asset, data, footerData, loading, errors, disabled }: RestorationProps) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const goToNextTab = (repData?: GetRestorationByIdQuery) => {
    setTabIndex((prevIndex) => {
      const limit = repData != null ? 5 : 2;
      return prevIndex < limit && prevIndex >= 0 ? prevIndex + 1 : prevIndex;
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const goToPreviousTab = () => {
    setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Tabs colorScheme="brand" variant="enclosed" mt="4" index={tabIndex} onChange={(index) => setTabIndex(index)} >
        <TabList>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>1</Badge>
            <Trans>Restoration</Trans>
          </Tab>

          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>2</Badge>
            <Trans>Conservative data and interventions</Trans>
          </Tab>

          <Tab isDisabled={data == null}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>3</Badge>
            <Trans>Image editor</Trans>
          </Tab>

          <Tab isDisabled={data == null}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>4</Badge>
            <Trans>Documents</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Restoration")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="restorationDirector" label={t("Director")} type="registry" required />
                    <FormInputHook name="restorationCompany" label={t("Company")} type="registry" />
                    <FormInputHook name="restorationRestoredBy" label={t("Restored by")} />
                    <MoneyInputHook name="restorationCost.amount" label={t("Restoration cost")} />
                    <FormInputHook name="restorationInventoryNumber" label={t("Reference number from recognized institution")} />
                    <FormInputHook name="restorationStartDate" label={t("Start date")} type="datetime-local" />
                    <FormInputHook name="restorationEndDate" label={t("End date")} type="datetime-local" />
                    <FormInputHook name="restorationLocation" label={t("Location")} />
                    <FormInputHook name="restorationCollocationFeatures" label={t("Collocation features")} />
                    <FormInputHook name="restorationDocumentDate" label={t("Document date")} type="datetime-local" />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Conservative data")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook type="textarea" name="conservativeDataHistoryInterventions" label={t("History interventions")} />
                    <FormInputHook type="textarea" name="conservativeDataConservationStatus" label={t("Conservatio status")} />
                    <FormInputHook type="textarea" name="conservativeDataAssetAlterations" label={t("Object alterations")} />
                    <FormInputHook type="textarea" name="conservativeDataDeteriorationCauses" label={t("Deterioration causes")} />
                    <FormInputHook type="textarea" name="conservativeDataExaminationMethod" label={t("Examination method")} />
                    <FormInputHook type="textarea" name="conservativeDataInterventionProposal" label={t("Intervention proposal")} />
                    <FormInputHook type="textarea" name="conservativeDataPreliminaryOperations" label={t("Preliminary operations")} />
                    <FormInputHook type="textarea" name="conservativeDataIntervention" label={t("Intervention")} />
                    <FormInputHook type="textarea" name="conservativeDataInterventionMaterials" label={t("Intervention materials")} />
                    <FormInputHook type="textarea" name="conservativeDataTechnicalMethodologicalConsiderations" label={t("Technical methodological considerations")} />
                    <FormInputHook type="textarea" name="conservativeDataMaintenancePreparationAssembly" label={t("Maintenance, preparation, assembly")} />
                    <FormInputHook type="textarea" name="conservativeDataRestorationBibliography" label={t("Restoration bibliography")} />
                    <FormInputHook type="textarea" name="conservativeDataNotesRestorationSheet" label={t("Restoration sheet notes")} />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            {data && data.restoration && data.restoration.id &&
              <MediaForm restorationId={data.restoration.id} />
            }
          </TabPanel>

          <TabPanel p={3}>
            {data && data.restoration && data.restoration.id &&
              <DocumentsForm restorationId={data.restoration.id} />
            }
          </TabPanel>

        </TabPanels>
      </Tabs>
      <FormFooter errors={errors} data={footerData} loading={loading} title="Restoration entry">
        <HStack width="full" justify="space-between" my="4">
          <HStack>
            {tabIndex > 0 && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              <Button onClick={() => goToPreviousTab()}>{t("Return to previous step")}</Button>
            )}

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {data == null && tabIndex < 1 && <Button onClick={() => goToNextTab(data)}>{t("Proceed to next step")}</Button>}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {data != null && tabIndex < 3 && <Button onClick={() => goToNextTab(data)}>{t("Proceed to next step")}</Button>}
            <Button isLoading={loading} loadingText="Loading" type="submit" variant="primary" disabled={disabled} >
              <Trans>Save</Trans>
            </Button>
          </HStack>
        </HStack>
      </FormFooter>

    </>
  );
};

export default RestorationFields;