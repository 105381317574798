import { HStack, VStack, Heading, Badge, Text, Button } from "@chakra-ui/react";
import { PolicyNode } from "graphql/queries/generated/queries";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CopyButton from "../CopyButton";

type PolicyCardProps = Pick<
  PolicyNode,
  "policyCode" | "offerCode" | "policyStatus" | "isExpired" | "id" | "policyType"
>;

const PolicyCard = ({
  policyCode = "",
  offerCode = "",
  policyStatus = "",
  isExpired = false,
  id = "",
  policyType = "",
}: PolicyCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const viewPolicyUrl = `/spin/${
    policyType === "TEMPORARY_EXHIBITION" ? "te-policies" : "pc-policies"
  }/${id}`;

  return (
    <HStack
      justifyContent="space-between"
      alignItems="flex-start"
      w="full"
      borderWidth="2px"
      borderColor="brand.500"
      bgColor="brand.50"
      borderRadius="md"
      py="6"
      px="6"
    >
      <VStack alignItems="flex-start">
        <Heading size="lg">
          <Trans>Policy #</Trans>
          {policyCode}
        </Heading>
        <Text fontSize="lg" variant="muted">
          <Trans>Offer #</Trans>
          {offerCode ?? ""} <CopyButton value={offerCode ?? ""} />
        </Text>
        <HStack>
          <Badge variant={policyStatus}>
            {policyStatus === "PENDING" && t("Offer")}
            {policyStatus === "APPROVED" && t("Policy")}
          </Badge>
          <Badge variant={isExpired ? "EXPIRED" : "CURRENT"}>
            {isExpired ? t("Expired") : t("Current")}
          </Badge>
        </HStack>
      </VStack>
      {policyType && id && (
        <Button onClick={() => navigate(viewPolicyUrl)}>
          <Trans>View policy</Trans>
        </Button>
      )}
    </HStack>
  );
};
export default PolicyCard;
