import { useGetEventLogByIdQuery } from "graphql/queries/generated/queries";
import PageEventLogDrawer from "./PageEventLogDrawer";

interface EditEventLogDrawerProps {
    assetId: string;
    eventLogId : string;
    show: boolean;
    onClose: () => void;
  }

const EditEventLogDrawer = ({ assetId, eventLogId, show, onClose } : EditEventLogDrawerProps) => {

  const { data, loading, error, refetch } = useGetEventLogByIdQuery({
    variables: {
      id: eventLogId,
    },
  });

  if (loading) return null;
  if (!data) return null;

  return <PageEventLogDrawer assetId={assetId} eventLogId={eventLogId} show={show} data={data} refetchEventLog={refetch} onClose={onClose} />;  
};

export default EditEventLogDrawer;
