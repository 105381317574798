import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Heading,
  VStack,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { EventLogInput, useCreateEventLogMutation } from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { useEffect } from "react";
import { handleMutation } from "middleware/Toaster";
import { Trans } from "react-i18next";
import { EventLogInputSchema } from "graphql/queries/generated/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import EventLogFields from "./EventLogFields";

interface NewEventLogDrawerProps {
  assetId: string;
  show: boolean;
  onClose: () => void;
}

const newAssetSet: string[] = [];

const defaultValues = {
  externalEntity: "no_entity",
  actionType: "default",
  commerciallyAvailable: false,
  hideOwner: false,
  sold: false,
  assetSet: newAssetSet,
  value: {
    amount: 0.0,
    currency: "EUR",
  },
  minimumPrice: {
    amount: 0.0,
    currency: "EUR",
  },
  maximumPrice: {
    amount: 0.0,
    currency: "EUR",
  },
  reservePrice: {
    amount: 0.0,
    currency: "EUR",
  },
  auctionRights: {
    amount: 0.0,
    currency: "EUR",
  },
  document: "",
};

const NewEventLogDrawer = ({ assetId, show, onClose }: NewEventLogDrawerProps) => {
  const methods = useForm<EventLogInput>({
    defaultValues,
    resolver: yupResolver(EventLogInputSchema()),
  });

  const [createEventLog, { data, error, loading }] = useCreateEventLogMutation({
    ...handleMutation("Event log created!"),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  const onSubmit: SubmitHandler<EventLogInput> = async (formData, e) => {
    formData.assetSet.push(assetId);

    const { data, errors } = await createEventLog({
      variables: {
        input: {
          eventLogData: {
            ...formData,
          },
        },
      },
    });

    if (!errors) onClose();
  };

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof EventLogInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  return (
    <Drawer isOpen={show} onClose={onClose} placement="right" size="xl">
      <DrawerOverlay maxW="80vw" w="80vw" />
      <DrawerContent maxW="80vw" w="80vw">
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>Create a new Event Log</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>Here you can create a new Event Log and immediately add it to the Object.</Trans>
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <FormProvider {...methods}>
            <ValidationProvider schema={EventLogInputSchema()}>
              <form onSubmit={methods.handleSubmit(onSubmit)} id="new-event-log-form">
                <VStack width="full" spacing="6" align="left" p="4">
                  <EventLogFields loading={loading} errors={[error]} watch={methods.watch} />

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button
                      variant="primary"
                      onClick={async () => {
                        await methods.handleSubmit(onSubmit)();
                      }}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </ButtonGroup>
                </VStack>
              </form>
            </ValidationProvider>
          </FormProvider>
        </DrawerBody>

        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default NewEventLogDrawer;
