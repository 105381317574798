/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Text, HStack } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Field } from "components/ui";
import ASSET_CONSTANTS from "constants/asset";
import findLabel from "./FindLabel";
import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const TaxonomyView = ({ data }: AnyObject) => {
  const {
    category,
    taxonomyDomain,
    taxonomyKingdom,
    taxonomyPhylum,
    taxonomyClass,
    taxonomyOrder,
    taxonomyFamily,
    taxonomyTribe,
    taxonomyGenus,
    taxonomyNotes,
    classificationUsa,
    classificationIta,
    classificationEng,
    classificationFra,
    classificationSpa,
    classificationGer,
    classificationPhilatelic,
    rarityDegree,
    rarityDegreePhilatelic,
    conservationState,
    rarityDegreeNotes,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Taxonomy/Classification")} subtitle="Lorem ipsum">
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Domain" value={taxonomyDomain} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Kingdom" value={taxonomyKingdom} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Phylum" value={taxonomyPhylum} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Class" value={taxonomyClass} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Order" value={taxonomyOrder} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Family" value={taxonomyFamily} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Tribe" value={taxonomyTribe} />
      )}
      {category === "NATURALISTIC_GOODS" && (
        <Field label="Genus" value={taxonomyGenus} />
      )}
      {
        category === "NATURALISTIC_GOODS" &&
        <Field label="Taxonomy notes" value={taxonomyNotes} />
      }


      {/* classificationUsa && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification USA</Trans>:
            </Text>
            {classificationUsa && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_USA,
                classificationUsa.toString()
              )}`}
            </Text>}
            {
            !classificationUsa && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {/* classificationIta && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification Italy</Trans>:
            </Text>
            {
            classificationIta && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_ITA,
                classificationIta.toString()
              )}`}
            </Text>
            }
            {
            !classificationIta && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {/* classificationEng && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification UK/USA</Trans>:
            </Text>
            {classificationEng && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_ENG,
                classificationEng.toString()
              )}`}
            </Text>}
            {
            !classificationEng && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {/* classificationFra && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification France</Trans>:
            </Text>
            {classificationFra && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_FRA,
                classificationFra.toString()
              )}`}
            </Text>}
            {
            !classificationFra && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {/* classificationSpa && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification Spain</Trans>:
            </Text>
            {classificationSpa && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_SPA,
                classificationSpa.toString()
              )}`}
            </Text>}
            {
            !classificationSpa && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {/* classificationGer && */
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <HStack>
            <Text variant="muted">
              <Trans>Classification Germany</Trans>:
            </Text>
            {classificationGer && <Text>
              {`${findLabel(
                ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_GER,
                classificationGer.toString()
              )}`}
            </Text> }
            {
            !classificationGer && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
          </HStack>
        )}

      {
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <Field label="Conservation state" value={conservationState} />
        )}

      {/* classificationPhilatelic && */category === "PHILATELY" && (
        <HStack>
          <Text variant="muted">
            <Trans>Classification</Trans>:
          </Text>
          {classificationPhilatelic && <Text>
            {`${findLabel(
              ASSET_CONSTANTS.PHILATELIC_CONSERVATION,
              classificationPhilatelic.toString()
            )}`}
          </Text> }
          {
            !classificationPhilatelic && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
        </HStack>
      )}

      {/* rarityDegree && */(category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
        <HStack>
          <Text variant="muted">
            <Trans>Rarity degree</Trans>:
          </Text>
          { rarityDegree && <Text>
            {`${findLabel(ASSET_CONSTANTS.NUMISMATIC_RARITY_DEGREE, rarityDegree.toString())}`}
          </Text> }
          {
            !rarityDegree && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
        </HStack>
      )}

      {/* rarityDegreePhilatelic && */category === "PHILATELY" && (
        <HStack>
          <Text variant="muted">
            <Trans>Rarity degree</Trans>:
          </Text>
          { rarityDegreePhilatelic && <Text>
            {`${findLabel(
              ASSET_CONSTANTS.PHILATELIC_RARITY_DEGREE,
              rarityDegreePhilatelic.toString()
            )}`}
          </Text> }
          {
            !rarityDegreePhilatelic && <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
            }
        </HStack>
      )}
      {
        (category === "NUMISMATIC_COINS" || category === "NUMISMATIC_BANKNOTES") && (
          <Field label="Rarity degree notes" value={rarityDegreeNotes} />

        )}
    </BorderedBox>
  );
};

export default TaxonomyView;
