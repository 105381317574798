import { Heading, HStack, VStack, Skeleton, Text } from "@chakra-ui/react";
import {
  CopyButton,
  QuickActions,
  BorderedBox,
  Field,
  DetailsPageSkeleton,
  NoDataPlaceholder,
  SidebarInfo,
} from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { useGetRegistryByIdQuery } from "graphql/queries/generated/queries";
import { REGISTRY_ROUTES } from "routes/constants";
import OfficesViewPage from "./OfficesViewPage";
import CompanyContactsViewPage from "./CompanyContactsViewPage";

const RegistryViewPage = () => {
  const { t } = useTranslation();
  const { registryId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetRegistryByIdQuery({
    variables: { id: registryId ?? "" },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.registry) return null;
  if (data.registry != null && data.registry.authorType == null) data.registry.authorType = [];
  if (data.registry != null && data.registry.categories == null) data.registry.categories = [];
  if (data.registry != null && data.registry.isAuthor == null) data.registry.isAuthor = false;

  const {
    registry: {
      isCompany,
      fullName,
      businessName,
      alias,
      webSite,
      note,
      countryCode,
      taxId,
      invoiceAddress,
      printName,
      printCode,
      phone,
      mobilePhone,
      email,
      isAuthor,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      birthDate,
      birthPlace,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      deathDate,
      deathPlace,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      activityPeriod,
      activityPlace,
      artisticMovement,
      foundation,
      // categories,
      // authorType,
      id,
    },
  } = data;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Registry"),
      href: `${REGISTRY_ROUTES.REGISTRY}/edit/${id}`,
      icon: <BiEdit />,
    },
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          <Heading>{fullName}</Heading>
          <Text fontSize="lg" variant="muted">
            #{registryId ?? ""} <CopyButton value={registryId ?? ""} />
          </Text>
          <Text>{isCompany ? "Company" : ""}</Text>
          <Text>{data.registry.categories.map((categoryCode) => `${t(categoryCode)} `)} </Text>
        </VStack>

        <QuickActions noTitle actions={QUICK_ACTIONS} />
      </HStack>

      <HStack flex="1" w="full" spacing="6" align="flex-start">
        <VStack flex="3" direction="column" spacing="6">
          <BorderedBox title="Registry Details" subtitle="Lorem ipsum dolor sit amet">
            <HStack w="100%" my={4}>
              {isCompany && <Field label="Business Name" value={businessName} />}
              {!isCompany && <Field label="Alias" value={alias} />}
              <Field label="Web Site" value={webSite} />
              <Field label="Note" value={note} />
            </HStack>

            {isAuthor && (
              <HStack w="100%" my={4}>
                <Field
                  label="Author"
                  value={data.registry.authorType.map((typeCode) => `${t(typeCode)} `)}
                />
                <Field label="Artistic Movement" value={artisticMovement} />
                <Field label="Archive/Foundation" value={foundation} />
                <Field label="Birth Date" value={birthDate} />
                <Field label="Birth Place" value={birthPlace} />
                <Field label="Death Date" value={deathDate} />
                <Field label="Death Place" value={deathPlace} />
                <Field label="Activity Period" value={activityPeriod} />
                <Field label="Activity Place" value={activityPlace} />
              </HStack>
            )}
          </BorderedBox>

          <BorderedBox title="Print information" subtitle="Lorem ipsum dolor sit amet">
            <HStack w="100%" my={4}>
              <Field label="Print Code" value={printName} />
              <Field label="Print name" value={printCode} />
            </HStack>
          </BorderedBox>

          <BorderedBox title="Tax information" subtitle="Lorem ipsum dolor sit amet">
            <HStack w="100%" my={4}>
              <Field label="Country" value={countryCode} />
              <Field label="Tax ID" value={taxId} />
              <Field label="Billing Address" value={invoiceAddress?.raw} />
            </HStack>
          </BorderedBox>

          <BorderedBox title="Contacts" subtitle="Lorem ipsum dolor sit amet">
            <HStack w="100%" my={4}>
              <Field label="Phone" value={phone} />
              <Field label="Mobile Phone" value={mobilePhone} />
              <Field label="Email" value={email} />
            </HStack>
          </BorderedBox>

          {isCompany && <CompanyContactsViewPage registryId={registryId} />}
          <OfficesViewPage registryId={registryId} />
        </VStack>
        <SidebarInfo
          createdBy={createdBy}
          updatedBy={updatedBy}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </HStack>
    </Skeleton>
  );
};

export default RegistryViewPage;
