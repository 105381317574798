import { DetailsPageSkeleton } from "components/ui";
import { useGetConditionStatusReportByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditConditionStatusReportPage = () => {
  const { reportId } = useParams();

  const { data, loading, error, refetch } = useGetConditionStatusReportByIdQuery({
    variables: {
      id: reportId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  return <Page data={data} refetchConditionStatusReport={refetch} />;
};

export default EditConditionStatusReportPage;
