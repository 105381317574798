import { VStack, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Dot from "../Dot";

const BANNED_VALUES = ["1/1/1970, 01:00:00", "..."];

interface FieldProps {
  value: Date | string | string[] | number | boolean;
  label?: string;
  children?: React.ReactNode;
}

const Field = ({ value, label, children }: FieldProps) => {
  const { t } = useTranslation();

  const emptyField = (
    <Text fontStyle="italic" fontWeight="400" color="gray.300">
      <Trans>Value not found</Trans>
    </Text>
  );

  const formatValue = (value: FieldProps["value"]) => {
    if (!value) return emptyField;
    if (Array.isArray(value)) return value.join(" ");
    // TODO: remove this once refactored

    if (value instanceof Date) return <Text>{value.toLocaleString("it-IT")}</Text>;
    if (typeof value === "boolean") return <Dot status={value} />;
    if (typeof value === "string" && value.trim() === "") return emptyField;
    if (typeof value === "string" && BANNED_VALUES.includes(value)) return emptyField;

    return <Text>{value}</Text>;
  };

  return (
    <VStack alignItems="flex-start" pr={8}>
      <Text fontStyle="normal" variant="muted">
        <Trans>{label}</Trans>
      </Text>
      {formatValue(value)}
      {children}
    </VStack>
  );
};

export default Field;
