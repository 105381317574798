/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Trans } from "react-i18next";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Heading,
  AccordionItem,
} from "@chakra-ui/react";
import {
  useCreateAssetMutation,
  useUpdateAssetMutation,
  AssetInput,
  UpdateAssetInput,
  CreateAssetInput,
  useDeleteAssetCarryingValueFileMutation,
} from "graphql/queries/generated/queries";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// import cleanObject from "helpers/cleanObject";
import { AssetInputSchema } from "graphql/queries/generated/validation-schema";
import { FormFooter } from "components/ui";

import { ValidationProvider } from "components/form/ValidationContext";
import { handleMutation } from "middleware/Toaster";
import PanelNewCategoryForm from "./PanelNewCategoryForm";
import OriginNewCategoryForm from "./origin/OriginNewCategoryForm";
import BibliographyNewCategoryForm from "./origin/BibliographyNewCategoryForm";
import AssetCarryingValueForm from "./values/AssetCarryingValueForm";
import AssetPresentValueForm from "./values/AssetPresentValueForm";

import defaultValues from "../utils/AssetDefaultValues";
import clearHistoricDateValues from "../utils/ClearHistoricDateValues";
import clearPaintingFrameValues from "../utils/ClearPaintingFrameValues";
import AssetMediaForm from "./media/AssetMediaForm";
import AssetDocumentForm from "./documents/AssetDocumentForm";
import EventLogs from "./eventlog/EventLogs";

interface NewCategoryFormProps {
  category: string;
  subcategory: string;
  children?: React.ReactNode;

  resetSubCategory: () => void;
}

const all_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "note", label: "Notes" },
];

const archeo_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "rinvenimento", label: "Discovery" },
  { key: "note", label: "Notes" },
];

const natural_steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "tassonomia", label: "Taxonomy/Classification" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "rinvenimento", label: "Discovery" },
  { key: "note", label: "Notes" },
];

const NewCategoryForm = ({
  category,
  subcategory,
  children,
  resetSubCategory,
}: NewCategoryFormProps) => {
  let steps = all_steps;
  if (category.toUpperCase() === "ARCHEOLOGY") {
    steps = archeo_steps;
  }
  if (category.toUpperCase() === "NATURALISTIC_GOODS") {
    steps = natural_steps;
  }
  if (category.toUpperCase() === "NUMISMATIC_COINS") {
    steps = natural_steps;
  }
  if (category.toUpperCase() === "NUMISMATIC_BANKNOTES") {
    steps = natural_steps;
  }
  if (category.toUpperCase() === "PHILATELY") {
    steps = natural_steps;
  }

  const [id, setId] = useState<string | null>(null);
  const [currentCarryingValueFile, setCurrentCarryingValueFile] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  };

  const [
    deleteAssetCarryingValueFile,
    {
      data: deleteAssetCarryingValueFileData,
      loading: deleteAssetCarryingValueFileLoading,
      error: deleteAssetCarryingValueFileError,
    },
  ] = useDeleteAssetCarryingValueFileMutation({
    ...handleMutation("Value deleted!"),
  });

  const deleteCarryingValueFile = async (assetId: string) => {
    const response = await deleteAssetCarryingValueFile({
      variables: {
        input: {
          assetId,
        },
      },
    });
    if (
      response &&
      response.data &&
      response.data.deleteAssetCarryingValueFile &&
      response.data.deleteAssetCarryingValueFile.asset &&
      response.data.deleteAssetCarryingValueFile.asset.id
    ) {
      setCurrentCarryingValueFile(null);
    }
    return response.data.deleteAssetCarryingValueFile.asset;
  };

  const [
    createAsset,
    { data: createAssetData, loading: createAssetLoading, error: createAssetError },
  ] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });

  const [
    updateAsset,
    { data: updateAssetData, loading: updateAssetLoading, error: updateAssetError },
  ] = useUpdateAssetMutation({
    ...handleMutation("Asset updated!"),
  });

  const methods = useForm<AssetInput>({
    defaultValues: {
      ...defaultValues,
      category,
      subcategory,
      validated: false,
    },
    resolver: yupResolver(AssetInputSchema()),
    mode: "onChange",
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;
  const onError = (errors: any, e: any) => console.log(errors, e);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof AssetInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<AssetInput> = async (values, e) => {
    let response;
    let assetData = clearHistoricDateValues(values);
    assetData = clearPaintingFrameValues(assetData);

    assetData.carryingValueAmount = {
      ...assetData.carryingValueAmount,
      // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
      currency: assetData.carryingValueAmount.currency || "EUR",
    };

    if (id !== null) {
      response = await updateAsset({
        variables: {
          input: {
            assetData: {
              ...(assetData as UpdateAssetInput["assetData"]),
              category,
              subcategory,
              validated: category !== "NOT_VALIDATED",
            },
            id: String(id),
          },
        },
      });
      if (response.data && response.data.updateAsset && response.data.updateAsset.asset) {
        setCurrentCarryingValueFile(response.data.updateAsset.asset?.carryingValueFile ?? null);
      }
    } else {
      response = await createAsset({
        variables: {
          input: {
            assetData: {
              ...(assetData as CreateAssetInput["assetData"]),
              validated: category !== "NOT_VALIDATED",
              category,
              subcategory,
            },
          },
        },
      });
      if (
        response.data &&
        response.data.createAsset &&
        response.data.createAsset.asset &&
        response.data.createAsset.asset.id
      ) {
        // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
        setId(response.data.createAsset.asset.id);
        setCurrentCarryingValueFile(response.data.createAsset.asset?.carryingValueFile ?? null);

        // setAccordionDefaultIndex([...Array(steps.length).keys()])
      }
    }
  };

  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  return (
    <FormProvider {...methods}>
      <ValidationProvider schema={AssetInputSchema()}>
        <form
          id="info_generali"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit}
        >
          {category === "NOT_VALIDATED" && (
            <PanelNewCategoryForm
              category={category}
              subcategory={subcategory}
              id={id}
              panel={undefined}
              handleSubmit={handleSubmit}
              getValues={methods.getValues}
              register={methods.register}
              setValue={methods.setValue}
              setError={methods.setError}
              watch={methods.watch}
              refetchAsset={undefined}
            >
              {children}
            </PanelNewCategoryForm>
          )}
          {category !== "NOT_VALIDATED" && (
            <Tabs
              colorScheme="brand"
              variant="enclosed"
              mt="4"
              index={tabIndex}
              onChange={handleTabChange}
            >
              <TabList>
                <Tab>
                  <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                    1
                  </Badge>
                  <Trans>Info</Trans>
                </Tab>

                <Tab isDisabled={id === null}>
                  <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                    2
                  </Badge>
                  <Trans>Origin and bibliography</Trans>
                </Tab>

                <Tab isDisabled={id === null}>
                  <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                    3
                  </Badge>
                  <Trans>Value</Trans>
                </Tab>
                <Tab isDisabled={id === null}>
                    <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                      4
                    </Badge>
                    <Trans>Event log</Trans>
                  </Tab>
                <Tab isDisabled={id === null}>
                  <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                    5
                  </Badge>
                  <Trans>Documents</Trans>
                </Tab>
                <Tab isDisabled={id === null}>
                  <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                    6
                  </Badge>
                  <Trans>Media</Trans>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={3}>
                  <Accordion
                    allowToggle
                    defaultIndex={[...Array(steps.length).keys()]}
                    allowMultiple
                  >
                    {steps.map(({ label, key }, index) => {
                      const panelStyle = {
                        display: "block",
                        margin: "0 1.2rem",
                        borderLeft: "2px solid #e2e8f0",
                      };
                      if (index > 0 && !id) {
                        panelStyle.display = "none";
                      }
                      if (index === steps.length - 1) {
                        panelStyle.borderLeft = "none;";
                      }
                      if (
                        key === "info_generali" ||
                        key === "dimensioni" ||
                        key === "tassonomia" ||
                        key === "aspetti_legali" ||
                        key === "dettagli_tecnici" ||
                        key === "caratteristiche_fisiche" ||
                        key === "allestimento" ||
                        key === "condizioni_ambientali" ||
                        key === "note" ||
                        key === "rinvenimento"
                      ) {
                        return (
                          <AccordionItem isDisabled={index > 0 && !id} border={0}>
                            <h2>
                              <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                                <Badge
                                  style={{
                                    borderRadius: "50%",
                                    width: 32,
                                    height: 32,
                                    lineHeight: "28px",
                                    border: "2px solid #E2E8F0",
                                  }}
                                  colorScheme="blackAlpha"
                                  ml={0}
                                  mr={2}
                                  p={0}
                                  rounded="full"
                                  fontWeight={700}
                                >
                                  {index + 1}
                                </Badge>
                                <Heading as="h2" size="sm">
                                  <Trans>{label}</Trans>
                                </Heading>

                                <AccordionIcon style={{ marginLeft: "auto" }} />
                              </AccordionButton>
                              {index !== steps.length - 1 && (
                                <div
                                  style={{
                                    height: "1rem",
                                    marginLeft: "1.2rem",
                                    borderLeft: "2px solid #e2e8f0",
                                  }}
                                />
                              )}
                            </h2>
                            <AccordionPanel pt={3} pb={4} style={panelStyle} overflow="scroll">
                              <PanelNewCategoryForm
                                category={category}
                                subcategory={subcategory}
                                id={id}
                                panel={key}
                                handleSubmit={handleSubmit}
                                getValues={methods.getValues}
                                register={methods.register}
                                setValue={methods.setValue}
                                setError={methods.setError}
                                watch={methods.watch}
                                refetchAsset={undefined}
                              >
                                {children}
                              </PanelNewCategoryForm>
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }
                      return null;
                    })}
                  </Accordion>
                </TabPanel>
                <TabPanel p={3}>
                  <Accordion allowToggle defaultIndex={[0, 1]} allowMultiple>
                    <AccordionItem isDisabled={!id} border={0}>
                      <h2>
                        <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                          <Badge
                            style={{
                              borderRadius: "50%",
                              width: 32,
                              height: 32,
                              lineHeight: "28px",
                              border: "2px solid #E2E8F0",
                            }}
                            colorScheme="blackAlpha"
                            ml={0}
                            mr={2}
                            p={0}
                            rounded="full"
                            fontWeight={700}
                          >
                            1
                          </Badge>
                          <Heading as="h2" size="sm">
                            <Trans>Origin</Trans>
                          </Heading>

                          <AccordionIcon style={{ marginLeft: "auto" }} />
                        </AccordionButton>
                        <div
                          style={{
                            height: "1rem",
                            marginLeft: "1.2rem",
                            borderLeft: "2px solid #e2e8f0",
                          }}
                        />
                      </h2>
                      <AccordionPanel
                        pt={3}
                        pb={4}
                        style={{
                          display: "block",
                          margin: "0 1.2rem",
                          borderLeft: "2px solid #e2e8f0",
                        }}
                      >
                        <OriginNewCategoryForm
                          // handleSubmit={formik.handleSubmit}
                          onSubmit={handleSubmit}
                          getValues={methods.getValues}
                          register={methods.register}
                          setValue={methods.setValue}
                          setError={methods.setError}
                          watch={methods.watch}
                          category={category}
                        >
                          {children}
                        </OriginNewCategoryForm>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem isDisabled={!id} border={0}>
                      <h2>
                        <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                          <Badge
                            style={{
                              borderRadius: "50%",
                              width: 32,
                              height: 32,
                              lineHeight: "28px",
                              border: "2px solid #E2E8F0",
                            }}
                            colorScheme="blackAlpha"
                            ml={0}
                            mr={2}
                            p={0}
                            rounded="full"
                            fontWeight={700}
                          >
                            2
                          </Badge>
                          <Heading as="h2" size="sm">
                            <Trans>Bibliography</Trans>
                          </Heading>

                          <AccordionIcon style={{ marginLeft: "auto" }} />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pt={3}
                        pb={4}
                        style={{ display: "block", margin: "0 1.2rem" }}
                      >
                        <BibliographyNewCategoryForm
                          onSubmit={handleSubmit}
                          getValues={methods.getValues}
                          register={methods.register}
                          setValue={methods.setValue}
                          setError={methods.setError}
                          watch={methods.watch}
                          category={category}
                        >
                          {children}
                        </BibliographyNewCategoryForm>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel p={3}>
                  <Accordion allowToggle defaultIndex={[0, 1]} allowMultiple>
                    <AccordionItem isDisabled={!id} border={0}>
                      <h2>
                        <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                          <Badge
                            style={{
                              borderRadius: "50%",
                              width: 32,
                              height: 32,
                              lineHeight: "28px",
                              border: "2px solid #E2E8F0",
                            }}
                            colorScheme="blackAlpha"
                            ml={0}
                            mr={2}
                            p={0}
                            rounded="full"
                            fontWeight={700}
                          >
                            1
                          </Badge>
                          <Heading as="h2" size="sm">
                            <Trans>Carrying value</Trans>
                          </Heading>

                          <AccordionIcon style={{ marginLeft: "auto" }} />
                        </AccordionButton>
                        <div
                          style={{
                            height: "1rem",
                            marginLeft: "1.2rem",
                            borderLeft: "2px solid #e2e8f0",
                          }}
                        />
                      </h2>
                      <AccordionPanel
                        pt={3}
                        pb={4}
                        style={{
                          display: "block",
                          margin: "0 1.2rem",
                          borderLeft: "2px solid #e2e8f0",
                        }}
                      >
                        <AssetCarryingValueForm
                          // handleSubmit={formik.handleSubmit}
                          onSubmit={handleSubmit}
                          getValues={methods.getValues}
                          register={methods.register}
                          setValue={methods.setValue}
                          setError={methods.setError}
                          watch={methods.watch}
                          // handleChange={formik.handleChange}
                          // handleRadioButtons={handleRadioButtons}
                          // values={formik.values}

                          category={category}
                          assetId={id}
                          currentCarryingValueFile={currentCarryingValueFile}
                          deleteCarryingValueFile={deleteCarryingValueFile}
                          deleteCarryingValueFileLoading={deleteAssetCarryingValueFileLoading}
                        >
                          {children}
                        </AssetCarryingValueForm>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem isDisabled={!id} border={0}>
                      <h2>
                        <AccordionButton mb={0} pl={1} style={{ width: "100%", border: "0" }}>
                          <Badge
                            style={{
                              borderRadius: "50%",
                              width: 32,
                              height: 32,
                              lineHeight: "28px",
                              border: "2px solid #E2E8F0",
                            }}
                            colorScheme="blackAlpha"
                            ml={0}
                            mr={2}
                            p={0}
                            rounded="full"
                            fontWeight={700}
                          >
                            2
                          </Badge>
                          <Heading as="h2" size="sm">
                            <Trans>Present values</Trans>
                          </Heading>

                          <AccordionIcon style={{ marginLeft: "auto" }} />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pt={3}
                        pb={4}
                        style={{ display: "block", margin: "0 1.2rem" }}
                      >
                        {id && <AssetPresentValueForm assetId={id} />}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <EventLogs assetId={id} />
                </TabPanel>
                <TabPanel>
                  <AssetDocumentForm assetId={id} />
                </TabPanel>
                <TabPanel>
                  <AssetMediaForm assetId={id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
          {id && (
            <FormFooter
              sticky
              cancelAction={resetSubCategory}
              data={updateAssetData}
              errors={[updateAssetError]}
              loading={updateAssetLoading}
              title="Asset entry"
            />
          )}
          {!id && (
            <FormFooter
              sticky
              cancelAction={resetSubCategory}
              data={createAssetData}
              errors={[createAssetError]}
              loading={createAssetLoading}
              title="Asset entry"
            />
          )}
        </form>
      </ValidationProvider>
    </FormProvider>
  );
};

export default NewCategoryForm;
