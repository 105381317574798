/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FormLabel, HStack, Input, Select, VStack, Text } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { useFormContext } from "react-hook-form";
import CURRENCIES from "constants/currencies.json";
import { WheelEvent } from "react";
import { useTenantContext } from "contexts/TenantContext";
import { ClientNode } from "graphql/queries/generated/queries";
import { Wrapper } from "../FormInput";
interface MoneyInputProps {
  name: string;
  label: string;
  onChange?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  helperText?: string;
}

const MoneyInputHook = ({ name, label, helperText }: MoneyInputProps) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  const lastIndex = name.lastIndexOf(".");
  const { tenant } = useTenantContext();
  // .filter((value) => currency === value.value).
  const currencyInputName = `${name.slice(0, lastIndex)}.currency`;

  const getCurrencyOpts = (
    currencies: { name: string; value: string }[],
    tenant: Pick<ClientNode, "currency">
  ) => {
    if (tenant) {
      // console.log({ tenant }, tenant.currency);
      return currencies
        .filter((currency) => currency.value === tenant.currency)
        .map(({ name, value }) => <option value={value}>{name}</option>);
    }
    return currencies.map(({ name, value }) => <option value={value}>{name}</option>);
  };

  const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
    (event.target as HTMLElement).blur();
    event.stopPropagation();
    setTimeout(() => {
      (event.target as HTMLElement).focus();
    }, 0);
  };

  return (
    <Wrapper>
      <HStack>
        <VStack align="flex-start" spacing="0">
          <FormLabel htmlFor={name}>{t(label)}</FormLabel>
          <Input
            step="0.01"
            id={name}
            name={name}
            type="number"
            {...register(name, {
              valueAsNumber: true,
            })}
            placeholder="Please enter a number"
            onWheel={handleOnWheel}
          />
        </VStack>
        <VStack align="flex-start" spacing="0">
          <FormLabel htmlFor={currencyInputName}>{t("Currency")}</FormLabel>
          <Select
            id={currencyInputName}
            name={currencyInputName}
            {...register(currencyInputName)}
            defaultValue="EUR"
            placeholder="Please enter a currency"
          >
            {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
            {getCurrencyOpts(CURRENCIES, tenant)}
          </Select>
        </VStack>
      </HStack>
      <Text mt={1} variant="muted">
        <Trans>{helperText}</Trans>
      </Text>
    </Wrapper>
  );
};

export default MoneyInputHook;
