import { Box, Checkbox, CheckboxGroup, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { SBox } from "components/ui";
import { PermissionNodeEdge } from "graphql/queries/generated/queries";
import { Dispatch, SetStateAction } from "react";
import { Trans } from "react-i18next";

export default function IndeterminateCheckbox({
  objectPermission,
  allowedPermissions,
  setAllowedPermissions,
  permissionGroupKey,
  readonly = false,
}: {
  objectPermission: PermissionNodeEdge[];
  allowedPermissions: string[];
  setAllowedPermissions: Dispatch<SetStateAction<string[]>>;
  permissionGroupKey: string;
  readonly?: boolean;
}) {
  const flattenedPermissionSet = objectPermission.map(({ node }) => node?.id);
  const allChecked = objectPermission.every(({ node }) =>
    allowedPermissions.includes(node?.id as string)
  );
  const isIndeterminate =
    objectPermission.some(({ node }) => allowedPermissions.includes(node?.id as string)) &&
    !allChecked;

  const handlePermission = (id: string) =>
    allowedPermissions.includes(id)
      ? setAllowedPermissions((prevState) => prevState.filter((allowedId) => allowedId !== id))
      : setAllowedPermissions((prevState) => [id, ...prevState]);

  const togglePermissionSet = () => {
    const allCheckboxChecked = flattenedPermissionSet.every((toBeCheckedId) =>
      allowedPermissions.includes(toBeCheckedId as string)
    );

    if (allCheckboxChecked) {
      flattenedPermissionSet.map((permissionId) =>
        setAllowedPermissions((prevState) =>
          prevState.filter((allowedId) => allowedId !== permissionId)
        )
      );
      return;
    }

    flattenedPermissionSet.map((permissionId) =>
      allowedPermissions.includes(permissionId as string)
        ? null
        : setAllowedPermissions((prevState) => [...prevState, permissionId] as string[])
    );
  };

  return (
    <SBox>
      <CheckboxGroup colorScheme="brand">
        <VStack align="flex-start">
          <HStack>
            <Checkbox
              disabled={readonly}
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={togglePermissionSet}
            />
            <HStack>
              <Heading size="md" color="gray.600">
                <Trans>All permissions on</Trans>
              </Heading>
              <Heading size="md">
                &quot;<Trans>{permissionGroupKey}</Trans>
                &quot;
              </Heading>
            </HStack>
          </HStack>
          <Box>
            <HStack spacing="4">
              {objectPermission.map((permission) => {
                if (!permission || !permission.node?.id) return null;
                const {
                  node: { id, codename },
                } = permission;
                return (
                  <HStack>
                    <Checkbox
                      disabled={readonly}
                      isChecked={!!allowedPermissions.includes(id)}
                      onChange={() => handlePermission(id)}
                    />
                    <Text>
                      <Trans>{codename}</Trans>
                    </Text>
                  </HStack>
                );
              })}
            </HStack>
          </Box>
        </VStack>
      </CheckboxGroup>
    </SBox>
  );
}
