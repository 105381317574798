import {
  Exact,
  GetAssetByIdQuery,
  MediaInput,
  MediaNode,
  useDeleteAssetMediaMutation,
} from "graphql/queries/generated/queries";
import { Trans, useTranslation } from "react-i18next";
import { Heading, Text, useToast, VStack } from "@chakra-ui/react";
import { Table } from "components/ui";
import getColumns from "helpers/getColumns";
import { ApolloQueryResult } from "@apollo/client";
import { useParams } from "react-router-dom";
import { handleMutation } from "middleware/Toaster";
import {
  MEDIA_IMAGE_CUSTOM_COLUMNS,
  MEDIA_IMAGE_CUSTOM_FIELDS,
  MEDIA_IMAGE_HIDDEN_FIELDS,
  MEDIA_AUDIO_VIDEO_CUSTOM_COLUMNS,
  MEDIA_AUDIO_VIDEO_CUSTOM_FIELDS,
  MEDIA_AUDIO_VIDEO_HIDDEN_FIELDS,
  MEDIA_FINGERPRINT_CUSTOM_COLUMNS,
  MEDIA_FINGERPRINT_CUSTOM_FIELDS,
  MEDIA_FINGERPRINT_HIDDEN_FIELDS,
  MEDIA_GENERIC_CUSTOM_COLUMNS,
  MEDIA_GENERIC_CUSTOM_FIELDS,
  MEDIA_GENERIC_HIDDEN_FIELDS,
} from "./MediaCustomColumns";

interface AssetMediaTableProps {
  data: MediaInput[];
  mode?: "view" | "edit";
  // remove?: UseFieldArrayRemove | ((arg0: number) => void);
  type: string;
  onDelete: (id: string) => any;
  editAction: (row: any) => any;
  // batchActions?: {
  //   name: string | React.ReactNode;
  //   action: (rows: { original: { id: string } }[]) => void;
  // }[];
  refetch?: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAssetByIdQuery>>;
}

const OnViewAssetMediaTable = ({
  data,
  type,
  mode = "edit",
  onDelete,
  editAction,
  refetch,
}: AssetMediaTableProps) => {
  const { assetId } = useParams();
  const toast = useToast();
  const { t } = useTranslation();
  const [
    deleteMedia,
    { data: deleteMediaData, error: deleteMediaError, loading: deleteMediaLoading },
  ] = useDeleteAssetMediaMutation({
    ...handleMutation("Media deleted!"),
  });

  let title = "";
  let noItemsText = "";
  switch (type) {
    case "IMAGE":
      title = "Images";
      noItemsText = "No image has been added yet.";
      break;
    case "AUDIO_VIDEO":
      title = "Audio/Video files";
      noItemsText = "No audio/video file has been added yet.";
      break;
    case "FINGERPRINT":
      title = "Fingerprints";
      noItemsText = "No fingerprint has file been added yet.";
      break;
    default:
      title = "Media";
      noItemsText = "No media file has been added yet.";
  }
  if (!data || data?.length === 0) {
    return (
      <VStack alignItems="flex-start" w="full" pr={8} my="4">
        <Heading size="md" alignSelf="flex-start">
          <Trans>{title}</Trans>
        </Heading>
        <Text>
          <Trans>{noItemsText}</Trans>
        </Text>
      </VStack>
    );
  }
  let customColumns;
  let customFields;
  let hiddenFields;
  switch (type) {
    case "AUDIO_VIDEO":
      customColumns = MEDIA_AUDIO_VIDEO_CUSTOM_COLUMNS;
      customFields = MEDIA_AUDIO_VIDEO_CUSTOM_FIELDS;
      hiddenFields = MEDIA_AUDIO_VIDEO_HIDDEN_FIELDS;
      break;
    case "FINGERPRINT":
      customColumns = MEDIA_FINGERPRINT_CUSTOM_COLUMNS;
      customFields = MEDIA_FINGERPRINT_CUSTOM_FIELDS;
      hiddenFields = MEDIA_FINGERPRINT_HIDDEN_FIELDS;
      break;
    case "IMAGE":
      customColumns = MEDIA_IMAGE_CUSTOM_COLUMNS;
      customFields = MEDIA_IMAGE_CUSTOM_FIELDS;
      hiddenFields = MEDIA_IMAGE_HIDDEN_FIELDS;
      break;
    default:
      customColumns = MEDIA_GENERIC_CUSTOM_COLUMNS;
      customFields = MEDIA_GENERIC_CUSTOM_FIELDS;
      hiddenFields = MEDIA_GENERIC_HIDDEN_FIELDS;
  }

  const columns = getColumns<MediaInput>({
    data: data[0],
    customColumns,
    customFields,
  });

  const BATCH_ACTIONS = [
    {
      name: <Trans>Download files</Trans>,
      action: (selectedRows: { original: MediaNode }[]) => {
        selectedRows.map(({ original }) => {
          window.open(original?.file);
          return null;
        });
      },
    },
    {
      name: <Trans>Delete media</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: MediaNode }[]) => {
        selectedRows.map(async ({ original: { id } }) => {
          await deleteMedia({
            variables: {
              input: {
                id: assetId,
                mediaId: id,
              },
            },
          });
          return null;
        });

        await refetch();
      },
    },
  ];

  return (
    <VStack alignItems="flex-start" w="full" pr={8} my="4">
      <Heading size="md" alignSelf="flex-start">
        <Trans>{title}</Trans>
      </Heading>
      <Table
        batchActions={BATCH_ACTIONS}
        hiddenColumns={hiddenFields}
        columns={columns}
        data={data}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        deleteAction={(id: string) => onDelete(id)}
        editAction={editAction}
      />
    </VStack>
  );
};

export default OnViewAssetMediaTable;
