/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { BaseSyntheticEvent } from "react";


import {
  AssetInput,
  Exact,
  GetAssetByIdQuery,
} from "graphql/queries/generated/queries";


import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormSetError,
} from "react-hook-form";


import { ApolloQueryResult } from "@apollo/client";
import GeneralInfoPanelNewCategoryForm from "./info/GeneralInfoPanelNewCategoryForm";
import DimensionsPanelNewCategoryForm from "./info/DimensionsPanelNewCategoryForm";
import LegalAspectsPanelNewCategoryForm from "./info/LegalAspectsNewCategoryForm";
import TechnicalDetailsNewCategoryForm from "./info/TechnicalDetailsNewCategoryForm";
import PhysicalFeaturesNewCategoryForm from './info/PhysicalFeaturesNewCategoryForm';
import SettingNewCategoryForm from './info/SettingNewCategoryForm';
import EnvironmentalConditionsNewCategoryForm from './info/EnvironmentalConditionsNewCategoryForm';
import NotesNewCategoryForm from './info/NotesNewCategoryForm';
import DiscoveryNewCategoryForm from "./info/DiscoveryNewCategoryForm";
import TaxonomyNewCategoryForm from "./info/TaxonomyNewCategoryForm";



interface PanelNewCategoryFormProps {
  category: string;
  subcategory: string;
  panel: string;

  children?: React.ReactNode;
  id: string;

  handleSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;

  refetchAsset: (variables?: Partial<Exact<{
    id: string;
}>>) => Promise<ApolloQueryResult<GetAssetByIdQuery>> | undefined;
}

const PanelNewCategoryForm = ({
  category,
  subcategory,
  panel,

  children,
  id,

  handleSubmit,
  register,
  getValues,
  watch,
  setValue,
  setError,

  refetchAsset,
}: PanelNewCategoryFormProps) => {
  let formPart = null;

  if (category === 'NOT_VALIDATED') {
    formPart = (
      <GeneralInfoPanelNewCategoryForm
        // handleSubmit={formik.handleSubmit}
        onSubmit={handleSubmit}
        getValues={getValues}
        register={register}
        setValue={setValue}
        setError={setError}
        watch={watch}
        category={category}
        subcategory={subcategory}
        refetchAsset={refetchAsset}
        id={id}
      >
        {children}
      </GeneralInfoPanelNewCategoryForm>
    );
  }
  else {
    switch (panel) {
      case "info_generali":
        formPart = (
          <GeneralInfoPanelNewCategoryForm
            // handleSubmit={formik.handleSubmit}
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}

            category={category}
            subcategory={subcategory}
            refetchAsset={refetchAsset}
            id={id}
          >
            {children}
          </GeneralInfoPanelNewCategoryForm>
        );
        break;

      case "dimensioni":
        formPart = (
          <DimensionsPanelNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            category={category}
          >
            {children}
          </DimensionsPanelNewCategoryForm>
        );
        break;
        case "tassonomia":
          formPart = (
            <TaxonomyNewCategoryForm
              onSubmit={handleSubmit}
              getValues={getValues}
              register={register}
              setValue={setValue}
              setError={setError}
              watch={watch}
              category={category}
            >
              {children}
            </TaxonomyNewCategoryForm>
          );
          break;
      case "aspetti_legali":
        formPart = (
          <LegalAspectsPanelNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            category={category}
          >
            {children}
          </LegalAspectsPanelNewCategoryForm>
        );
        break;
      case "dettagli_tecnici":
        formPart = (
          <TechnicalDetailsNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            category={category}
          >
            {children}
          </TechnicalDetailsNewCategoryForm>
        );
        break;
      case "caratteristiche_fisiche":
          formPart = (
            <PhysicalFeaturesNewCategoryForm
              onSubmit={handleSubmit}
              getValues={getValues}
              register={register}
              setValue={setValue}
              setError={setError}
              watch={watch}
              assetId={id}
              category={category}
            >
              {children}
            </PhysicalFeaturesNewCategoryForm>
          );
          break;
      case "allestimento":
        formPart = (
          <SettingNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            category={category}
          >
            {children}
          </SettingNewCategoryForm>
        );
        break;
      case "condizioni_ambientali":
        formPart = (
          <EnvironmentalConditionsNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            category={category}
          >
            {children}
          </EnvironmentalConditionsNewCategoryForm>
        );
        break;
        case "rinvenimento":
          formPart = (
            <DiscoveryNewCategoryForm
              onSubmit={handleSubmit}
              getValues={getValues}
              register={register}
              setValue={setValue}
              setError={setError}
              watch={watch}
              category={category}
            >
              {children}
              {/* {id && (
                <Button
                  isDisabled={activeStep === steps.length-1}
                  mr={4}
                  onClick={nextStep}
                  variant="ghost"
                >
                  Next
                </Button>
              )}
              <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
                Prev
              </Button> */}
            </DiscoveryNewCategoryForm>
          );
          break;

      case "note":
          formPart = (
            <NotesNewCategoryForm
              onSubmit={handleSubmit}
              getValues={getValues}
              register={register}
              setValue={setValue}
              setError={setError}
              watch={watch}
              category={category}
            >
              {children}
            </NotesNewCategoryForm>
          );
          break;
      default:
        formPart = (
          <div>
            {children}
          </div>
        );
    }
  }

  return formPart;
};

export default PanelNewCategoryForm;
