import { useAuth } from "contexts/AuthProvider";
import canNavigateRoute from "helpers/canNavigateRoute";
import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.jwtToken || auth.jwtToken === "waiting" || auth.jwtToken === "") {
    console.log("[AUTH] Redirecting user to LoginPage with from:", location);
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // const { permissions } = auth;
  // console.log("[AUTH] Trying to access route", location, permissions);

  const { user } = auth;

  const permissions = user.userPermissions?.edges?.map(({ node }) => node);
  const userCanNavigate = useMemo(
    () => canNavigateRoute(permissions, location, user.isSuperuser),
    [permissions, location]
  );

  console.log("[AUTH] User trying to access this route", location, permissions);
  if (userCanNavigate) {
    console.log("[AUTH] Rendering children, user is OK", location);
    return children;
  }
  console.log("[AUTH] User cannot access this route", location, permissions);
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Navigate to="/" state={{ from: location }} replace />;
}

export default RequireAuth;
