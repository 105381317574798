import { Trans, useTranslation } from "react-i18next";
import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import { NoDataPlaceholder, QuickActions, Table } from "components/ui";
import { RiAddLine } from "react-icons/ri";
import getColumns from "helpers/getColumns";
import {
  PolicyNode,
  useDeletePolicyMutation,
  useGetAllPoliciesByTypeQuery,
} from "graphql/queries/generated/queries";
import { isArray } from "@chakra-ui/utils";
import { handleMutation } from "middleware/Toaster";
import { SPIN_ROUTES } from "routes/constants";
import CUSTOM_FIELDS from "../shared/constants/allPoliciesTableCustomFields";
import CUSTOM_COLUMNS from "../shared/constants/allPoliciesTableCustomColumns";
import HIDDEN_COLUMNS from "../shared/constants/allPoliciesTableHiddenColumns";
const QUICK_ACTIONS = [
  {
    name: "Add new policy",
    href: "new",
    icon: <RiAddLine />,
  },
];

const TEPoliciesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllPoliciesByTypeQuery({
    variables: {
      policyType: "TEMPORARY_EXHIBITION",
    },
  });
  const [
    deletePolicy,
    { loading: deletePolicyLoading, data: deletePolicyData, error: deletePolicyError },
  ] = useDeletePolicyMutation({ ...handleMutation("Policy deleted!") });

  if (loading || deletePolicyLoading)
    return <Skeleton h="15rem" isLoaded={!loading && !deletePolicyLoading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;
  if (!data || !data.allPolicies || data.allPolicies.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicies: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  if (!CUSTOM_COLUMNS.some((e) => e.Header === "Exhibition Name")) {
    // CUSTOM_COLUMNS.push({
    //   Header: "Exhibition Name",
    //   Footer: "Exhibition Name",
    //   disableFilters: true,
    //   accessor: "exhibition.name",
    //   Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    // },
    // )
    CUSTOM_COLUMNS.splice(6, 0, {
      Header: "Exhibition Name",
      Footer: "Exhibition Name",
      disableFilters: true,
      accessor: "exhibition.name",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    });
  }
  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });
  if (!HIDDEN_COLUMNS.includes("eventEntity.fullName")) {
    HIDDEN_COLUMNS.push("eventEntity.fullName");
    console.log(HIDDEN_COLUMNS);
  }
  if (HIDDEN_COLUMNS.includes("exhibition.name")) {
    const index = HIDDEN_COLUMNS.indexOf("exhibition.name");
    HIDDEN_COLUMNS.splice(index, 1);
  }

  const onBatchDelete = (selectedRows: { original: PolicyNode }[]) => {
    if (isArray(selectedRows) && selectedRows.length > 0) {
      selectedRows.map(async ({ original: { id } }) => {
        await deletePolicy({
          variables: {
            input: {
              id,
            },
          },
        });

        await refetch();
      });
    }
  };

  const onDelete = async (id: string) => {
    await deletePolicy({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete policies</Trans>,
      intent: "danger",
      action: (selectedRows: { original: PolicyNode }[]) => onBatchDelete(selectedRows),
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allPolicies.edges.flatMap((e) => e?.node)}
          pathname={`${SPIN_ROUTES.POLICY_TE}/`}
          deleteAction={(id: string) => onDelete(id)}
          hiddenColumns={HIDDEN_COLUMNS}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default TEPoliciesPage;
