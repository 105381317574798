/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Dot, Field } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const LegalAspectsView = ({ data }: AnyObject) => {
  const {
    isNotifiedCulturalProperty,
    notifiedPropertyNote,
    legalStatus,
    copyrightAndPropertyRestrictions,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Legal aspects")} subtitle="Lorem ipsum">
      <HStack spacing="16" w="full">
        <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Notified Cultural Property</Trans>
          </Text>
          <Dot status={isNotifiedCulturalProperty} />
        </VStack>
        <Field label="Property notes" value={notifiedPropertyNote} />
      </HStack>

      <Field label="Legal status" value={legalStatus} />

      <Field
          label="Copyright and property restrictions"
          value={copyrightAndPropertyRestrictions}
      />

    </BorderedBox>
  );
};

export default LegalAspectsView;
