/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */

import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  /* IconButton, */ Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  /* Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal */ Stack,
  StackDivider,
  /* Table, Tbody, Td, Th, Thead, Tr, */ useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { AssetElementInputSchema } from "graphql/queries/generated/validation-schema";
import { FormInputHook } from "components/ui";
import { useState, useEffect } from "react";
import cleanObject from "helpers/cleanObject";
import {
  AssetElementInput,
  useDeleteAssetElementMutation,
  useUpdateAssetElementMutation,
  useCreateAssetElementMutation,
  useGetAssetElementsByAssetIdLazyQuery,
  AssetElementNode,
} from "graphql/queries/generated/queries";
// import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
// import truncate from "pages/assets/utils/TruncateString";
import { handleMutation } from "middleware/Toaster";
import OnViewAssetElementsTable from "../../../shared/OnViewAssetElementsTable";

interface AssetElementFormProps {
  assetId: string;
}

const AssetElementForm = ({ assetId }: AssetElementFormProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();
  const methods = useForm<AssetElementInput>({
    defaultValues: {
      name: "",
      description: "",
      materials: "",
      notes: "",
      assetId,
    },
    resolver: yupResolver(AssetElementInputSchema()),
    mode: "onChange",
  });

  const { setValue } = methods;

  const [selectedId, setSelectedId] = useState(null);

  const onEditModalOpen = (
    id: string,
    name: string,
    description: string,
    materials: string,
    notes: string
  ) => {
    setSelectedId(id);
    setValue("name", name);
    setValue("description", description);
    setValue("materials", materials);
    setValue("notes", notes);
    onOpen();
  };

  const [loadAssetElements, refetch] = useGetAssetElementsByAssetIdLazyQuery();

  const [
    deleteAssetElement,
    {
      data: deleteAssetElementData,
      error: deleteAssetElementError,
      loading: deleteAssetElementLoading,
    },
  ] = useDeleteAssetElementMutation({
    ...handleMutation("Asset element deleted!"),
  });

  const [assetElements, setAssetElements] = useState<
    Array<{
      id: string;
      name: string;
      description: string;
      materials: string;
      notes: string;
      assetId: string;
    }>
  >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedAssetElements = async () => {
    const {
      data: assetElements,
      loading: assetElementsLoading,
      error: assetElementsError,
    } = await loadAssetElements({
      variables: {
        assetId,
      },
    });

    if (!assetElements || !assetElements.allAssetElements) return null;
    const {
      allAssetElements: { edges },
    } = assetElements;

    setAssetElements(
      edges.flatMap(({ node }) => ({
        id: node.id,
        name: node.name,
        description: node.description,
        materials: node.materials,
        notes: node.notes,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        assetId,
      }))
    );
    return assetElements;
  };

  const onDelete = async (id: string) => {
    await deleteAssetElement({
      variables: {
        input: {
          id,
        },
      },
    });

    const assetElements = await loadParsedAssetElements();
    return assetElements;
  };

  const [
    createAssetElement,
    {
      data: createAssetElementData,
      loading: createAssetElementLoading,
      error: createAssetElementError,
    },
  ] = useCreateAssetElementMutation({
    ...handleMutation("Asset element created!"),
  });

  const [
    updateAssetElement,
    {
      data: updateAssetElementData,
      loading: updateAssetElementLoading,
      error: updateAssetElementError,
    },
  ] = useUpdateAssetElementMutation({
    ...handleMutation("Asset element updated!"),
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadParsedAssetElements();
    return undefined;
  }, []);

  // useEffect(() => {}, [assetElements]);

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("description", "");
    setValue("materials", "");
    setValue("notes", "");
    onClose();
  };

  const onSubmit: SubmitHandler<AssetElementInput> = async (values: any, e: any) => {
    const assetElementData = cleanObject(values);
    let assetElements;
    let response;
    if (selectedId) {
      response = await updateAssetElement({
        variables: {
          input: {
            assetElementData: {
              name: assetElementData.name,
              description: assetElementData.description,
              materials: assetElementData.materials,
              notes: assetElementData.notes,
            },
            id: selectedId,
          },
        },
      });
      if (
        response.data &&
        response.data.updateAssetElement &&
        response.data.updateAssetElement.assetElement &&
        response.data.updateAssetElement.assetElement.id
      ) {
        assetElements = await loadParsedAssetElements();
        onCloseModal();
      }
    } else {
      response = await createAssetElement({
        variables: {
          input: {
            assetElementData: {
              name: assetElementData.name,
              description: assetElementData.description,
              materials: assetElementData.materials,
              notes: assetElementData.notes,
              assetId,
            },
          },
        },
      });

      if (
        response.data &&
        response.data.createAssetElement &&
        response.data.createAssetElement.assetElement &&
        response.data.createAssetElement.assetElement.id
      ) {
        assetElements = await loadParsedAssetElements();
        onCloseModal();
      }
    }

    return assetElements;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  return (
    <>
      <OnViewAssetElementsTable
        data={assetElements}
        mode="edit"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        editAction={({ row }: { row: { original: AssetElementNode } }) =>
          onEditModalOpen(
            row?.original?.id,
            row?.original?.name,
            row?.original?.description,
            row?.original?.materials,
            row?.original?.notes
          )
        }
        // viewAction={(row: any) => openElementModal(row?.values?.elementId)}
        viewAction={null}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        onDelete={(id: string) => onDelete(id)}
      />

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onOpen} variant="primary">
          <Trans>Add new asset element</Trans>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? (
              <Trans>Update asset element</Trans>
            ) : (
              <Trans>Add new asset element</Trans>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormProvider {...methods}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form id="addAssetElement" onSubmit={handleSubmit}>
                <Box maxW="6xl">
                  <Stack spacing="4" divider={<StackDivider />}>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Name" name="name" />
                      <FormInputHook label="Description" name="description" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Materials" name="materials" />
                      <FormInputHook label="Notes" name="notes" />
                    </HStack>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button
                variant="primary"
                onClick={handleSubmit}
                isLoading={createAssetElementLoading || updateAssetElementLoading}
                mr={1}
              >
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onCloseModal}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssetElementForm;
