import { Trans } from "react-i18next";
import {
  VStack,
  HStack,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Badge,
  Text
 } from "@chakra-ui/react";
 import { AssetSearchBox } from "components/ui";
import { SelectedAsset } from "components/ui/AssetSearchBox/types";
import { Element } from "react-scroll";

interface NewAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  addAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
  choosenAssets: string[] | [];
}

const SearchAssetsDrawer = ({
  show,
  onClose,
  addAsset,
  choosenAssets,
}: NewAssetDrawerProps) => (
  <Drawer isOpen={show} placement="right" onClose={onClose} size="xl" >
    <DrawerOverlay maxW="80vw" w="80vw" />
    <DrawerContent maxW="80vw" w="80vw">
      <DrawerCloseButton />

      <DrawerBody>
        <HStack justifyContent="space-between" align="flex-start" py={8}>
          <VStack align="flex-start" pr={16} id="test">
            <Element name="userWantsToAddAssetsRefToScrollTo">
              <Badge mr="2" mb="1">
                Step 1
              </Badge>
            </Element>
            <Heading size="lg">Search and select assets</Heading>{" "}
            <Text mt={0}>
              <Trans>
                Puoi prelevarlo dai tuoi assets digitando qui sotto, oppure creane uno nuovo
                cliccando su &quot;Crea nuovo asset&quot; o importa una lista di assets da un .csv
              </Trans>
            </Text>
          </VStack>
        </HStack>

        <VStack align="flex-start">
          <AssetSearchBox model="cr/sr" index={`${process.env.REACT_APP_TENANT}_asset`} addAsset={addAsset} choosenAssets={choosenAssets} singleSelectionMode />
        </VStack>
      </DrawerBody>

    </DrawerContent>
  </Drawer>
);

export default SearchAssetsDrawer;