import { HStack, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface PolicyNote {
  content?: string;
  id: string;
}

const PolicyNotesSummary = ({ policyNotes }: { policyNotes: PolicyNote[] }) => {
  if (!policyNotes || policyNotes.length === 0)
    return (
      <Text>
        <Trans>No notes added</Trans>
      </Text>
    );
  return (
    <>
      {policyNotes.map(({ content }) => (
        <HStack align="center">
          <Text variant="muted">
            <Trans>Content: </Trans>
          </Text>

          {content.length === 0 ? (
            <Text>
              <Trans>Current note is empty.</Trans>
            </Text>
          ) : (
            <Text>{content}</Text>
          )}
        </HStack>
      ))}
    </>
  );
};

export default PolicyNotesSummary;
