import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import ASSET_CONSTANTS from "constants/asset";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface TaxonomyNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}

const VOID_OPTION = [{ label: "-- Select --", value: "" }];

const TaxonomyNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: TaxonomyNewCategoryFormProps) => {
  const { t } = useTranslation();
 // const { title } = getValues();


  return (
    <>
        {
          category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <><FieldGroup title={t("Botanical Classification")} subtitle={t("Taxonomy - Botanical Classification - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                    <FormInputHook label="Domain" name="taxonomyDomain" />
                    <FormInputHook label="Kingdom" name="taxonomyKingdom" />
                    <FormInputHook label="Phylum" name="taxonomyPhylum" />
                    <FormInputHook label="Class" name="taxonomyClass" />
                    <FormInputHook label="Order" name="taxonomyOrder" />
                    <FormInputHook label="Family" name="taxonomyFamily" />
                    <FormInputHook label="Tribe" name="taxonomyTribe" />
                    <FormInputHook label="Genus" name="taxonomyGenus" />
                </VStack>
            </FieldGroup>
            <FieldGroup title={t("Taxonomy notes")} subtitle={t("Taxonomy - Taxonomy notes - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                    <HStack alignItems="flex-end">
                        <FormInputHook label="Taxonomy notes" name="discoveryNotes" type="textarea" />
                    </HStack>
                </VStack>
            </FieldGroup></>
        }

        {
          ((category === 'NUMISMATIC_COINS') || (category === 'NUMISMATIC_BANKNOTES')) &&
          // <FormInputHook
          //     label=""
          //     options={[{ label: "- Length unit -", value: "" }].concat(
          //       ASSET_CONSTANTS.LENGTH_UNIT
          //     )}
          //     name="netLengthUnit"
          //   />
          <>
            <FieldGroup title={t("Conservation state")} subtitle={t("Taxonomy - NUMISMATIC - Conservation state - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Classification USA"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_USA}
                    name="classificationUsa"
                  />
                  <FormInputHook
                    label="Classification Italy"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_ITA}

                    name="classificationIta"
                  />
                  <FormInputHook
                    label="Classification UK/USA"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_ENG}
                    name="classificationEng"
                  />

                </HStack>
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Classification France"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_FRA}
                    name="classificationFra"
                  />
                  <FormInputHook
                    label="Classification Spain"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_SPA}
                    name="classificationSpa"
                  />
                  <FormInputHook
                    label="Classification Germany"
                    options={ASSET_CONSTANTS.NUMISMATIC_CONSERVATION_GER}
                    name="classificationGer"
                  />

                </HStack>
                <HStack alignItems="flex-end">
                  <FormInputHook label="Conservation state notes" name="conservationState" type="textarea" />
                </HStack>
              </VStack>


            </FieldGroup>
            <FieldGroup title={t("Rarities")} subtitle={t("Taxonomy - NUMISMATIC - Rarities - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                      <HStack alignItems="flex-end">
                          <FormInputHook
                            label="Rarity degree"
                            options={ASSET_CONSTANTS.NUMISMATIC_RARITY_DEGREE}
                            name="rarityDegree"
                          />
                      </HStack>
                    <HStack alignItems="flex-end">
                        <FormInputHook label="Rarity degree notes" name="rarityDegreeNotes" type="textarea" />
                    </HStack>
                </VStack>
            </FieldGroup>
          </>
        }

      {
          category === 'PHILATELY' &&
          // <FormInputHook
          //     label=""
          //     options={[{ label: "- Length unit -", value: "" }].concat(
          //       ASSET_CONSTANTS.LENGTH_UNIT
          //     )}
          //     name="netLengthUnit"
          //   />
          <>
            <FieldGroup title={t("Conservation state")} subtitle={t("Taxonomy - PHILATELY - Conservartion state - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Philatelic classification"
                    options={ASSET_CONSTANTS.PHILATELIC_CONSERVATION}
                    name="classificationPhilatelic"
                  />


                </HStack>
                <HStack alignItems="flex-end">
                  <FormInputHook label="Conservation state notes" name="conservationState" type="textarea" />
                </HStack>
              </VStack>


            </FieldGroup>
            <FieldGroup title={t("Rarities")} subtitle={t("Taxonomy - PHILATELY - Rarities - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                      <HStack alignItems="flex-end">
                          <FormInputHook
                            label="Rarity degree"
                            options={ASSET_CONSTANTS.PHILATELIC_RARITY_DEGREE}
                            name="rarityDegreePhilatelic"
                          />
                      </HStack>
                    <HStack alignItems="flex-end">
                        <FormInputHook label="Rarity degree notes" name="rarityDegreeNotes" type="textarea" />
                    </HStack>
                </VStack>
            </FieldGroup>
          </>
        }
    </>
  );

};

export default TaxonomyNewCategoryForm;
