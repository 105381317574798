import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AssetInput, Money, useCreateAssetMutation } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";
import ShortAssetForm from "pages/mgmt/ShortAssetForm";
import cleanObject from "helpers/cleanObject";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { handleMutation } from "middleware/Toaster";

interface NewAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  addAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
}

const defaultValues = {
  validated: false,
};

const NewAssetDrawer = ({ show, onClose, addAsset }: NewAssetDrawerProps) => {
  const methods = useForm<AssetInput & { insuredValue: Money }>({
    defaultValues,
  });

  const [createAsset, { data, error, loading }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });

  const onSubmit: SubmitHandler<AssetInput & { insuredValue: Money }> = async (formData, e) => {
    const { insuredValue, ...rest } = formData;
    const assetData = cleanObject(rest) as AssetInput;
    const { data: createAssetData } = await createAsset({
      variables: {
        input: {
          assetData: {
            ...assetData,
            validated: true,
          },
        },
      },
    });

    addAsset({
      ...createAssetData.createAsset.asset,
      gqlId: createAssetData.createAsset.asset.id,
    });
  };

  const {
    formState: { errors },
  } = methods;

  return (
    <Drawer isOpen={show} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay maxW="80vw" w="80vw" />
      <DrawerContent maxW="80vw" w="80vw">
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>Create a new asset</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>
              Here you can create a new asset and immediately add it to the appraisal. Note that the
              asset will be inserted even if the appraisal is not saved.
            </Trans>
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <FormProvider {...methods}>
            <ShortAssetForm
              onSubmit={methods.handleSubmit(onSubmit)}
              data={data}
              errors={error && [error]}
              loading={loading}
              reset={() => methods.reset()}
            />
          </FormProvider>
        </DrawerBody>

        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default NewAssetDrawer;
