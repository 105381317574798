/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
import {
  Button,
  HStack,
  VStack,
  Text,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDeleteAssetElementMutation } from "graphql/queries/generated/queries";

import { Dot, Field } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";
import { handleMutation } from "middleware/Toaster";
import findLabel from "./FindLabel";
import BorderedBox from "./BorderedBox";
import OnViewAssetElementsTable from "./OnViewAssetElementsTable";
import AssetElementView from "./AssetElementView";

interface AnyObject {
  [key: string]: any;
}

const PhysicalFeaturesView = ({ data, refetchAsset, assetId }: AnyObject) => {
  const {
    category,
    stateOfConservation,
    isComposed,
    numberOfElements,
    physicalFeaturesElements,
    surfaceCharacteristics,
    hasBase,
    baseDetails,
    hasContainer,
    containerDetails,
    hasOriginalFrame,
    frameMaterial,
    isValuableFrame,
    frameManufacturer,
    frameDescription,
    hasFrame,
    hasWoodFrame,
    woodFrameDetails,
    compositionDescription,
    methodOfUse,
    relatedPhysicalWork,
    conservationMethod,
    usage,
    finishing,
    distribution,
    edge,
    orientation,
    shape,
    rim,
    rotationAxes,
    legend,
    coinTitle,
    mintMadeErrors,
    signings,
    relief,
    watermark,
    holographicStripe,
    securityThread,
    printingErrors,
    color,
    postageMark,
    rubber,
    stampCenteringAndMargins,
    stampPrintingTechnique,
    stampFormat,
    stampRealization,
    stampIndentationType,
    bookContainer,
    dustJacket,
    frontCover,
    rearCover,
    topEdge,
    foreEdge,
    footEdge,
    bookbinding,
    interiorDecorations,
    externalDecorations,
    flyleaf,
    glueing,
    headband,
    headcap,
    corner,
    square,
    slots,
    spine,
    clips,
    raisedBands,
    label,
    clothCovering,
    replacedParts,
  } = data;

  const [selectedElement, setSelectedElement] = useState<{
    id: string;
    name: string;
    description: string;
    materials: string;
    notes: string;
  } | null>(null);

  const parsedForTableElements = physicalFeaturesElements.edges.flatMap(
    (e: {
      node: {
        id: string;
        name: string;
        description: string;
        materials: string;
        notes: string;
      };
    }) => ({
      ...e.node,
      elementId: e.node.id,
    })
  );

  const {
    isOpen: isOpenElementModal,
    onOpen: onOpenElementModal,
    onClose: onCloseElementModal,
  } = useDisclosure();

  const loadSelectedElement = (id: string) => {
    const element = parsedForTableElements.find(
      (e: { id: string; name: string; description: string; materials: string; notes: string }) =>
        e.id === id
    );
    if (element) {
      setSelectedElement({
        id: element.elementId,
        name: element.name,
        description: element.description,
        materials: element.materials,
        notes: element.notes,
      });
    }
  };

  const closeElementModal = () => {
    setSelectedElement(null);
    onCloseElementModal();
  };

  const openElementModal = (id: string) => {
    loadSelectedElement(id);
    onOpenElementModal();
  };

  const [
    deleteAssetElement,
    {
      data: deleteAssetElementData,
      error: deleteAssetElementError,
      loading: deleteAssetElementLoading,
    },
  ] = useDeleteAssetElementMutation({
    ...handleMutation("Asset element deleted!"),
  });

  const onDeleteElement = async (id: string) => {
    await deleteAssetElement({
      variables: {
        input: {
          id,
        },
      },
    });
    const result = await refetchAsset({
      id: assetId,
    });

    const edges = result?.data?.asset?.physicalFeaturesElements?.edges ?? [];
    return edges.flatMap(
      (e: {
        node: {
          id: string;
          name: string;
          description: string;
          materials: string;
          notes: string;
        };
      }) => ({
        ...e.node,
        elementId: e.node.id,
      })
    );
  };

  const { t } = useTranslation();
  return (
    <BorderedBox title={t("Physical features")} subtitle="Lorem ipsum">
      <Field label="State of conservation" value={stateOfConservation} />

      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Several elements</Trans>
              </Text>
              <Dot status={isComposed} />
            </VStack>
            <Field label="Number of elements" value={numberOfElements} />
          </HStack>
        )}
      {category !== "NUMISMATIC_COINS" &&
        category !== "NUMISMATIC_BANKNOTES" &&
        category !== "PHILATELY" && (
          <OnViewAssetElementsTable
            data={parsedForTableElements}
            mode="view"
            editAction={null}
            viewAction={(row: any) => openElementModal(row?.values?.elementId)}
            onDelete={onDeleteElement}
          />
        )}

      {category === "OTHER" && <Field label="Replaced parts" value={replacedParts} />}

      {category === "OTHER" && <Field label="Usage" value={usage} />}

      {(category === "SCULPTURE" || category === "URBAN" || category === "DESIGN_OR_ANTIQUE") && (
        <Field label="Surface characteristics" value={surfaceCharacteristics} />
      )}

      {category === "DESIGN_OR_ANTIQUE" && <Field label="Usage" value={usage} />}

      {category === "DESIGN_OR_ANTIQUE" && <Field label="Finishing" value={finishing} />}

      {(category === "SCULPTURE" || category === "URBAN" || category === "DESIGN_OR_ANTIQUE") && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has base</Trans>
            </Text>
            <Dot status={hasBase} />
          </VStack>
          <Field label="Base details" value={baseDetails} />
        </HStack>
      )}

      {(category === "PAINTING" || category === "PRINT_OR_PHOTO") && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has woodframe</Trans>
            </Text>
            <Dot status={hasWoodFrame} />
          </VStack>
          <Field label="Woodframe details" value={woodFrameDetails} />
        </HStack>
      )}

      {(category === "PAINTING" || category === "PRINT_OR_PHOTO") && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has frame</Trans>
            </Text>
            <Dot status={hasFrame} />
          </VStack>
          {hasFrame && (
            <VStack alignItems="flex-start">
              <HStack>
                <VStack>
                  <Text variant="muted">
                    <Trans>Original frame</Trans>
                  </Text>
                  <Dot status={hasOriginalFrame} />
                </VStack>
                <VStack>
                  <Text variant="muted">
                    <Trans>Material</Trans>
                  </Text>
                  <Text>{frameMaterial}</Text>
                </VStack>
              </HStack>
              <Text variant="muted">
                <Trans>Valuable frame</Trans>
              </Text>
              <Dot status={isValuableFrame} />

              <Text variant="muted">
                <Trans>Frame manufacturer</Trans>
              </Text>
              <Text>{frameManufacturer}</Text>

              <Field label="Frame description" value={frameDescription} />
            </VStack>
          )}
        </HStack>
      )}

      {category === "INSTALLATION" && (
        <Field label="Compositiom description" value={compositionDescription} />
      )}

      {(category === "NUMISMATIC_COINS" ||
        category === "NUMISMATIC_BANKNOTES" ||
        category === "PHILATELY") && <Field label="Distribution" value={distribution} />}
      {(category === "NUMISMATIC_COINS" || category === "PHILATELY") && (
        <Field label="Edge" value={edge} />
      )}

      {category === "NUMISMATIC_COINS" && <Field label="Orientation" value={orientation} />}

      {(category === "NUMISMATIC_COINS" || category === "PHILATELY") && (
        <Field label="Shape" value={shape} />
      )}

      {category === "PHILATELY" && <Field label="Color" value={color} />}

      {category === "NUMISMATIC_COINS" && <Field label="Rim" value={rim} />}

      {category === "NUMISMATIC_COINS" && <Field label="Rotation axes" value={rotationAxes} />}

      {category === "NUMISMATIC_COINS" && <Field label="Legend" value={legend} />}

      {category === "NUMISMATIC_COINS" && <Field label="Coint title" value={coinTitle} />}

      {category === "NUMISMATIC_COINS" && <Field label="Minting errors" value={mintMadeErrors} />}

      {category === "NUMISMATIC_BANKNOTES" && <Field label="Signings" value={signings} />}
      {category === "NUMISMATIC_BANKNOTES" && <Field label="Relief" value={relief} />}

      {(category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
        <Field label="Watermark" value={watermark} />
      )}

      {category === "PHILATELY" && <Field label="Postage mark" value={postageMark} />}

      {category === "PHILATELY" && <Field label="Rubber" value={rubber} />}

      {(stampPrintingTechnique || stampRealization) && category === "PHILATELY" && (
        <HStack>
          <VStack alignItems="flex-start" pr={8}>
            <Text fontStyle="normal" variant="muted">
              <Trans>Stamp printing Technique</Trans>
            </Text>
            {stampPrintingTechnique && (
              <Text>
                {`${findLabel(
                  ASSET_CONSTANTS.STAMP_PRINTING_TECHNIQUE,
                  stampPrintingTechnique.toString()
                )}`}
              </Text>
            )}
            {!stampPrintingTechnique && (
              <Text fontStyle="italic" fontWeight="400" color="gray.300">
                <Trans>Value not found</Trans>
              </Text>
            )}
          </VStack>
          <VStack alignItems="flex-start" pr={8}>
            <Text fontStyle="normal" variant="muted">
              <Trans>Realization</Trans>
            </Text>
            {stampRealization && (
              <Text>
                {`${findLabel(ASSET_CONSTANTS.STAMP_REALIZATION, stampRealization.toString())}`}
              </Text>
            )}
            {!stampRealization && (
              <Text fontStyle="italic" fontWeight="400" color="gray.300">
                <Trans>Value not found</Trans>
              </Text>
            )}
          </VStack>
        </HStack>
      )}

      {(stampIndentationType || stampCenteringAndMargins || stampFormat) &&
        category === "PHILATELY" && (
          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Stamp indentation type</Trans>
              </Text>
              {stampIndentationType && (
                <Text>
                  {`${findLabel(
                    ASSET_CONSTANTS.STAMP_INDENTATION_TYPES,
                    stampIndentationType.toString()
                  )}`}
                </Text>
              )}
              {!stampIndentationType && (
                <Text fontStyle="italic" fontWeight="400" color="gray.300">
                  <Trans>Value not found</Trans>
                </Text>
              )}
            </VStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Centering and margins</Trans>
              </Text>
              {stampCenteringAndMargins && (
                <Text>
                  {`${findLabel(
                    ASSET_CONSTANTS.STAMP_CENTERING,
                    stampCenteringAndMargins.toString()
                  )}`}
                </Text>
              )}
              {!stampCenteringAndMargins && (
                <Text fontStyle="italic" fontWeight="400" color="gray.300">
                  <Trans>Value not found</Trans>
                </Text>
              )}
            </VStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Format</Trans>
              </Text>
              {stampFormat && (
                <Text>{`${findLabel(ASSET_CONSTANTS.STAMP_FORMATS, stampFormat.toString())}`}</Text>
              )}
              {!stampFormat && (
                <Text fontStyle="italic" fontWeight="400" color="gray.300">
                  <Trans>Value not found</Trans>
                </Text>
              )}
            </VStack>
          </HStack>
        )}

      {category === "NUMISMATIC_BANKNOTES" && (
        <Field label="Holographic stripe" value={holographicStripe} />
      )}

      {category === "NUMISMATIC_BANKNOTES" && (
        <Field label="Security thread" value={securityThread} />
      )}

      {(category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
        <Field label="Printing errors" value={printingErrors} />
      )}

      {category === "BOOKS" && <Field label="Container" value={bookContainer} />}

      {category === "BOOKS" && <Field label="Dust jacket" value={dustJacket} />}

      {category === "BOOKS" && <Field label="Front cover" value={frontCover} />}

      {category === "BOOKS" && <Field label="Rear cover" value={rearCover} />}

      {category === "BOOKS" && <Field label="Top edge" value={topEdge} />}

      {foreEdge && category === "BOOKS" && <Field label="Fore edge" value={foreEdge} />}

      {footEdge && category === "BOOKS" && <Field label="Foot edge" value={footEdge} />}

      {category === "BOOKS" && <Field label="Bookbinding" value={bookbinding} />}

      {category === "BOOKS" && <Field label="Interior decorations" value={interiorDecorations} />}

      {category === "BOOKS" && <Field label="External decorations" value={externalDecorations} />}

      {category === "BOOKS" && <Field label="Flyleaf" value={flyleaf} />}

      {category === "BOOKS" && <Field label="Glueing" value={glueing} />}

      {category === "BOOKS" && <Field label="Headband" value={headband} />}

      {category === "BOOKS" && <Field label="Headcap" value={headcap} />}

      {category === "BOOKS" && <Field label="Corner" value={corner} />}

      {category === "BOOKS" && <Field label="Square" value={square} />}

      {category === "BOOKS" && <Field label="Slots" value={slots} />}

      {category === "BOOKS" && <Field label="Spine" value={spine} />}

      {category === "BOOKS" && <Field label="Clips" value={clips} />}

      {category === "BOOKS" && <Field label="Raised bands" value={raisedBands} />}

      {category === "BOOKS" && <Field label="Label" value={label} />}

      {category === "BOOKS" && <Field label="Cloth covering" value={clothCovering} />}

      {category !== "DIGITAL" && (
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>Has protection, shield, glass, container etc.</Trans>
            </Text>
            <Dot status={hasContainer} />
          </VStack>
          <Field label="Container details" value={containerDetails} />
        </HStack>
      )}

      {(category === "ARCHEOLOGY" ||
        category === "NUMISMATIC_COINS" ||
        category === "NUMISMATIC_BANKNOTES" ||
        category === "PHILATELY") && (
        <Field label="Conservation method" value={conservationMethod} />
      )}

      {category === "DIGITAL" && <Field label="Method of use" value={methodOfUse} />}
      {category === "DIGITAL" && (
        <Field label="Related physical work" value={relatedPhysicalWork} />
      )}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenElementModal}
        size="xl"
        onClose={closeElementModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Trans>View asset element value</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <AssetElementView assetElement={selectedElement} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeElementModal}>
              <Trans>Cancel</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </BorderedBox>
  );
};

export default PhysicalFeaturesView;
