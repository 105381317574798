import { Badge, Button, Divider, Heading, HStack, Skeleton, Text, VStack } from "@chakra-ui/react";
import { BorderedBox, RegistryAwareField } from "components/ui";
import { DiffChangesNode, useGetPolicyDiffChangesQuery } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";
import { CSVLink } from "react-csv";

interface PolicyHistoryProps {
  id: string;
}

const PolicyHistory = ({ id }: PolicyHistoryProps) => {
  const { data, error, loading } = useGetPolicyDiffChangesQuery({
    variables: {
      id,
    },
  });

  if (loading) return <Skeleton w="100%" h="20rem" />;

  if (!data)
    return (
      <Text>
        <Trans>No changes has been made yet.</Trans>
      </Text>
    );

  if (error) return <Text>There has been an error retrieving policy history.</Text>;

  const { diffChanges } = data?.policy ?? {};

  if (!diffChanges)
    return (
      <Text>
        <Trans>No changes has been made yet.</Trans>
      </Text>
    );

  const diffChangesGroupedByHistoryId = diffChanges.reduce((groups, item) => {
    if (!item.changeReason) return groups;
    return {
      ...groups,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [item.historyId]: [...(groups[item.historyId] || []), item],
    };
  }, {});

  const computeValue = (field: string, value: string) => {
    if (!value) return <Trans>&quot;No value was present in this field&quot;</Trans>;
    if (["event_location_entity"].includes(field)) {
      const gqlId = btoa(`RegistryNode:${value}`);
      return <RegistryAwareField id={gqlId} />;
    }

    const timestamp = Date.parse(value);
    if (!Number.isNaN(timestamp)) {
      return new Date(value).toLocaleString();
    }

    return value;
  };

  const getDataForExport = () => {
    const orderedChanges = Object.keys(diffChangesGroupedByHistoryId).reverse();
    const rows = orderedChanges.map((key, index) => {
      // eslint-disable-next-line
      let row = {
        change_reason: "",
        changed_at: "",
        expires_at: "",
        issued_at: "",
        total_insured_premium: "",
        total_insured_value: "",
      };

      const changes = diffChangesGroupedByHistoryId[key] as DiffChangesNode[];
      changes.forEach(
        ({ changeReason, changedAt, field, historyId, new: newValue, old: oldValue }) => {
          row.change_reason = changeReason;
          row.changed_at = new Date(changedAt).toLocaleString();
          if (Object.hasOwn(row, field)) row[field] = newValue;
        }
      );
      return row;
    });

    console.log(rows);
    return rows;
  };

  const exportData = getDataForExport();

  return (
    <VStack align="flex-start" w="full">
      <HStack w="full" justify="space-between">
        <VStack align="flex-start" mb="4">
          <Heading size="md">
            <Trans>Changes </Trans>
            <Badge>{Object.keys(diffChangesGroupedByHistoryId)?.length}</Badge>
          </Heading>
          <Text variant="muted">
            <Trans>History heading subtitle</Trans>
          </Text>
        </VStack>

        <Button>
          <CSVLink data={exportData}>
            <Trans>Export to csv</Trans>
          </CSVLink>
        </Button>
      </HStack>

      {Object.keys(diffChangesGroupedByHistoryId)
        .reverse()
        .map((key, index) => {
          const changes = diffChangesGroupedByHistoryId[key] as DiffChangesNode[];
          console.log({ changes }, key);
          return (
            <BorderedBox
              title={`Change on ${new Date(changes[0]?.changedAt).toLocaleString()}`}
              subtitle={
                <>
                  Change reason is:
                  <Badge mx={1} variant={changes[0]?.changeReason}>
                    {changes[0]?.changeReason}
                  </Badge>
                </>
              }
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <VStack spacing="4" align="flex-start" divider={<Divider />} w="full">
                {changes.map(({ changeReason, changedAt, field, old, new: newValue }) => (
                  <VStack align="flex-start">
                    <HStack>
                      <Text>Field:</Text>
                      <Text fontWeight="bold">
                        <Trans>{field}</Trans>
                      </Text>
                    </HStack>
                    <HStack>
                      <Badge bgColor="red.100 !important" color="red.500">
                        <Text fontFamily="monospace">-</Text>
                      </Badge>
                      {old ? (
                        <Text backgroundColor="red.100" color="red.900" p="0.5">
                          {computeValue(field, old) ?? "..."}
                        </Text>
                      ) : (
                        <Text variant="muted">
                          <Trans>No value was set on this field</Trans>
                        </Text>
                      )}
                    </HStack>
                    <HStack>
                      <Badge bgColor="green.100 !important" color="green.500">
                        <Text fontFamily="monospace">+</Text>
                      </Badge>
                      {newValue ? (
                        <Text backgroundColor="green.100" color="green.900" p="0.5">
                          {computeValue(field, newValue) ?? "..."}
                        </Text>
                      ) : (
                        <Text variant="muted">
                          <Trans>No value was set on this field</Trans>
                        </Text>
                      )}
                    </HStack>
                  </VStack>
                ))}
              </VStack>
            </BorderedBox>
          );
        })}
    </VStack>
  );
};

export default PolicyHistory;
