/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VStack, Stack } from "@chakra-ui/react";
import { FieldGroup, FormInputHook } from "components/ui";
import { useTranslation } from "react-i18next";

const CollectionFields = ({ watch, id, assets }: any) => {
  const { t } = useTranslation();

  return <Stack spacing="4">
          <FieldGroup title={t("Collection Details")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <VStack alignItems="flex-start">
                <FormInputHook name="name" label={t("Name")} required />
                <FormInputHook name="description" label={t("Description")} />
                <FormInputHook name="notes" label={t("Notes")} />
                <FormInputHook name="image.0.file" label={t("Image")} type="file" />
              </VStack>
            </VStack>
          </FieldGroup>
        </Stack>;
};

export default CollectionFields;