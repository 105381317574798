import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Text, Heading, VStack, ButtonGroup, Button } from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { EventLogInput, useUpdateEventLogMutation, GetEventLogByIdQuery, Exact } from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { useEffect } from "react";
import { handleMutation } from "middleware/Toaster";
import { Trans, useTranslation } from "react-i18next";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { EventLogInputSchema } from "graphql/queries/generated/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApolloQueryResult } from "@apollo/client";
import EventLogFields from "./EventLogFields";

export const eventTypeOptions: FormSelectOptionProps[] = [
  { value: "acquisition", label: "Acquisition" },
  { value: "change_location", label: "Change location" },
  { value: "consignment", label: "Consignment" },
  { value: "auction", label: "Auction" },
  { value: "sale", label: "Sale" },
  { value: "transfer_donation", label: "Transfer/Donation" },
  { value: "lost_stolen", label: "Lost/Stolen" },
  { value: "destruction", label: "Destruction" }
];

interface PageEventLogDrawerProps {
  assetId: string;
  eventLogId : string;
  data: GetEventLogByIdQuery;
  refetchEventLog: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetEventLogByIdQuery>>;
  show: boolean;
  onClose: () => void;
}

const newAssetSet : string[] = []

const defaultValues = {
  acquisitionType: '',
  collectionPublicName: '',
  endDate: '',
  eventType: '', 
  actionType: 'default',
  externalEntity: 'no_entity',
  commerciallyAvailable: false,
  hideOwner: false,
  locationDescription: '',
  note: '',
  commission: '',
  auctionDescription: '',
  lotNumber: '',
  owner : { id : '', fullName: '' },
  origin : { id : '', fullName: '' },
  host : { id : '', fullName: '' },
  broker : { id : '', fullName: '' },
  newOwner : { id : '', fullName: '' },
  sold: false,
  startDate: '',
  sellingLocation: '',
  assetSet: newAssetSet,
  value: {
    amount: 0.0,
    currency: "EUR",
  },
  minimumPrice: {
    amount: 0.0,
    currency: "EUR",
  },
  maximumPrice: {
    amount: 0.0,
    currency: "EUR",
  },
  reservePrice: {
    amount: 0.0,
    currency: "EUR",
  },
  auctionRights: {
    amount: 0.0,
    currency: "EUR",
  },
  externalId: 0,
  document: ''
};

const PageEventLogDrawer = ({ assetId, eventLogId, data, show, onClose } : PageEventLogDrawerProps) => {
  const { t } = useTranslation();
  
  const [ updateEventLog, { data: updateEventLogData, error, loading }] = useUpdateEventLogMutation(
    {
      ...handleMutation("Event Log updated!")
    }
  );

  const eventLog = defaultValues;

  if (data && data.eventLog) { 
    eventLog.acquisitionType = data.eventLog.acquisitionType;
    eventLog.collectionPublicName = data.eventLog.collectionPublicName;
    eventLog.commerciallyAvailable = data.eventLog.commerciallyAvailable;
    eventLog.endDate = data.eventLog.endDate;
    eventLog.eventType = data.eventLog.eventType;
    eventLog.actionType = data.eventLog.actionType;
    eventLog.hideOwner = data.eventLog.hideOwner;
    eventLog.locationDescription = data.eventLog.locationDescription;
    eventLog.note = data.eventLog.note;
    eventLog.commission = data.eventLog.commission;
    eventLog.auctionDescription = data.eventLog.auctionDescription;
    eventLog.lotNumber = data.eventLog.lotNumber;
    eventLog.owner = data.eventLog.owner ? { id : data.eventLog.owner.id, fullName : data.eventLog.owner.fullName } : { id : '', fullName: '' };
    eventLog.origin = data.eventLog.origin ? { id : data.eventLog.origin.id, fullName : data.eventLog.origin.fullName } : { id : '', fullName: '' };
    eventLog.host = data.eventLog.host ? { id : data.eventLog.host.id, fullName : data.eventLog.host.fullName } : { id : '', fullName: '' };
    eventLog.broker = data.eventLog.broker ? { id : data.eventLog.broker.id, fullName : data.eventLog.broker.fullName } : { id : '', fullName: '' };
    eventLog.newOwner = data.eventLog.newOwner ? { id : data.eventLog.newOwner.id, fullName : data.eventLog.newOwner.fullName } : { id : '', fullName: '' };
    eventLog.sold = data.eventLog.sold;
    eventLog.startDate = data.eventLog.startDate;
    eventLog.sellingLocation = data.eventLog.sellingLocation;
    eventLog.value = data.eventLog.value ? { amount: data.eventLog.value.amount, currency: data.eventLog.value.currency.code } : { amount: 0, currency: "EUR" };
    eventLog.minimumPrice = data.eventLog.minimumPrice ? { amount: data.eventLog.minimumPrice.amount, currency: data.eventLog.minimumPrice.currency.code } : { amount: 0, currency: "EUR" };
    eventLog.maximumPrice = data.eventLog.maximumPrice ? { amount: data.eventLog.maximumPrice.amount, currency: data.eventLog.maximumPrice.currency.code } : { amount: 0, currency: "EUR" };    
    eventLog.reservePrice = data.eventLog.reservePrice ? { amount: data.eventLog.reservePrice.amount, currency: data.eventLog.reservePrice.currency.code } : { amount: 0, currency: "EUR" };
    eventLog.auctionRights = data.eventLog.auctionRights ? { amount: data.eventLog.auctionRights.amount, currency: data.eventLog.auctionRights.currency.code } : { amount: 0, currency: "EUR" };
    eventLog.externalId = data.eventLog.externalId;
    eventLog.externalEntity = data.eventLog.externalEntity;
    eventLog.document = data.eventLog.document;
  }

  const methods = useForm<EventLogInput>({
    defaultValues : {
        acquisitionType : eventLog.acquisitionType,
        collectionPublicName: eventLog.collectionPublicName,
        commerciallyAvailable: eventLog.commerciallyAvailable,
        endDate: eventLog.endDate,
        eventType: eventLog.eventType,
        actionType : eventLog.actionType,
        hideOwner: eventLog.hideOwner,
        locationDescription: eventLog.locationDescription,
        note: eventLog.note,
        commission: eventLog.commission,
        auctionDescription: eventLog.auctionDescription,
        lotNumber: eventLog.lotNumber,
        owner: eventLog.owner ? eventLog.owner.id : "",
        origin: eventLog.origin ? eventLog.origin.id : "",
        host: eventLog.host ? eventLog.host.id : "",
        broker: eventLog.broker ? eventLog.broker.id : "",
        newOwner: eventLog.newOwner ? eventLog.newOwner.id : "",
        sold: eventLog.sold,
        startDate : eventLog.startDate,
        sellingLocation: eventLog.sellingLocation,
        value : eventLog.value ? { amount: eventLog.value.amount, currency: eventLog.value.currency } : { amount: 0, currency: "EUR" },
        minimumPrice : data.eventLog.minimumPrice ? { amount: data.eventLog.minimumPrice.amount, currency: data.eventLog.minimumPrice.currency.code } : { amount: 0, currency: "EUR" },
        maximumPrice : data.eventLog.maximumPrice ? { amount: data.eventLog.maximumPrice.amount, currency: data.eventLog.maximumPrice.currency.code } : { amount: 0, currency: "EUR" },    
        reservePrice : data.eventLog.reservePrice ? { amount: data.eventLog.reservePrice.amount, currency: data.eventLog.reservePrice.currency.code } : { amount: 0, currency: "EUR" },
        auctionRights : data.eventLog.auctionRights ? { amount: data.eventLog.auctionRights.amount, currency: data.eventLog.auctionRights.currency.code } : { amount: 0, currency: "EUR" },
        externalId : eventLog.externalId,
        externalEntity : eventLog.externalEntity,
        document : eventLog.document,
        assetSet : []
    },
    resolver: yupResolver(EventLogInputSchema()),
  });

  const { formState: { errors }, setFocus } = methods;

  const onSubmit: SubmitHandler<EventLogInput> = async (formData, e) => {
    console.log(formData);

    formData.assetSet.push(assetId);

    await updateEventLog({
        variables : {
            input : {
                id : eventLogId,
                eventLogData : {
                    ...formData
                }
            }
        }
    });

    onClose();
  }

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a), null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof EventLogInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  return (
    <Drawer isOpen={show} onClose={onClose} placement="right" size="xl" >
      <DrawerOverlay maxW="80vw" w="80vw" />
      <DrawerContent maxW="80vw" w="80vw">
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>Create a new Event Log</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>
              Here you can create a new Event Log and immediately add it to the Object.
            </Trans>
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <FormProvider {...methods}>
            <ValidationProvider schema={EventLogInputSchema()}>
              <form onSubmit={methods.handleSubmit(onSubmit)} id="new-event-log-form">
                <VStack width="full" spacing="6" align="left" p="4">                  
                  <EventLogFields loading={loading} errors={[error]} watch={methods.watch} />

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button variant="primary" onClick={async () => { await methods.handleSubmit(onSubmit)(); }}>
                      <Trans>Update</Trans>
                    </Button>
                  </ButtonGroup>                      
                </VStack>
              </form>
            </ValidationProvider>
          </FormProvider>          
        </DrawerBody>

        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default PageEventLogDrawer;
