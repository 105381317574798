import {
  HStack,
  VStack,
  Text,
  Box,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { Dot, RegistryAwareField, BorderedBox, SidebarInfo } from "components/ui";
import { Action, QuickLink } from "components/ui/QuickActions";
import { ViewPolicyByIdQuery } from "graphql/queries/generated/queries";
import parseAmount from "helpers/parseAmount";
import parseDate from "helpers/parseDate";
import { Trans, useTranslation } from "react-i18next";
import { RiArrowRightFill } from "react-icons/ri";

const PolicySummary = ({
  policy,
  actions,
}: {
  policy: ViewPolicyByIdQuery["policy"];
  actions?: (Partial<Action> | Partial<QuickLink>)[];
}) => {
  const { t } = useTranslation();

  const {
    policyCode,
    offerCode,
    issuedAt,
    expiresAt,
    policyStatus,
    autoRenewal,
    contractingParty,
    broker,
    insuranceCompany,
    catNat,
    coverTerrorism,
    hasVariableBasket,
    coverType,
    coveredRiskType,
    evaluationType,
    eventEntity,
    eventLocationEntity,
    eventFrom,
    eventName,
    eventTo,
    policyType,
    totalInsuredPremium,
    totalInsuredValue,
    createdBy,
    updatedBy,
    createdAt,
    updatedAt,
  } = policy;

  const { name, email } = createdBy;
  const isExpired = new Date(expiresAt).getTime() < new Date().getTime();

  return (
    <VStack spacing="6" align="flex-start" w="full">
      <HStack flex="1" w="full" spacing="6" align="flex-start">
        <VStack flex="3" direction="column" spacing="6">
          <BorderedBox
            title={policyType === "PERMANENT_COLLECTION" ? t("Collection") : t("Exhibition")}
            subtitle={
              policyType === "PERMANENT_COLLECTION"
                ? "Collection subtitle in policy summary"
                : "Exhibition subtitle in policy summary"
            }
          >
            <HStack spacing="16" w="full">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  {policyType === "PERMANENT_COLLECTION" ? (
                    <Trans>Collection name</Trans>
                  ) : (
                    <Trans>Event name</Trans>
                  )}
                </Text>
                <Text fontWeight="600">{eventName}</Text>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>
                    {policyType === "PERMANENT_COLLECTION"
                      ? "Museum/Collector"
                      : "Institution/Museum/Location name"}
                  </Trans>
                </Text>
                <RegistryAwareField id={eventEntity?.id} />
              </VStack>
            </HStack>

            {eventFrom && eventTo && (
              <HStack spacing="16">
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Event dates</Trans>
                  </Text>
                  {eventFrom && eventTo && (
                    <HStack mt="0 !important">
                      <Text>{parseDate(eventFrom)}</Text> <RiArrowRightFill />{" "}
                      <Text>{parseDate(eventTo)}</Text>
                    </HStack>
                  )}
                </VStack>
              </HStack>
            )}
          </BorderedBox>
          <BorderedBox title="Coverage" subtitle="Coverage subtitle in policy summary">
            <HStack align="flex-start" spacing="16">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Coverage</Trans>
                </Text>
                <HStack>
                  <Text>{parseDate(issuedAt)}</Text> <RiArrowRightFill />{" "}
                  <Text>{parseDate(expiresAt)}</Text>
                </HStack>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Auto renewal</Trans>
                </Text>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <Dot status={autoRenewal} />
              </VStack>
            </HStack>
            <HStack spacing="16">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Contracting party</Trans>
                </Text>
                <RegistryAwareField id={contractingParty.id} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Broker</Trans>
                </Text>
                <RegistryAwareField id={broker?.id} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Insurance company</Trans>
                </Text>
                <RegistryAwareField id={insuranceCompany.id} />
              </VStack>
            </HStack>
          </BorderedBox>

          <BorderedBox title="Parameters" subtitle="Parameters subtitle in policy summary">
            <VStack align="flex-start" spacing="4">
              <HStack spacing="16">
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Natural disasters</Trans>
                  </Text>
                  <Dot status={catNat as boolean} />
                </VStack>
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Cover Terrorism</Trans>
                  </Text>
                  <Dot status={coverTerrorism as boolean} />
                </VStack>
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Has variable basket</Trans>
                  </Text>
                  <Dot status={hasVariableBasket as boolean} />
                </VStack>
              </HStack>
              <HStack spacing="16">
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Cover type</Trans>
                  </Text>
                  <Text>
                    <Badge mt={1} variant={coverType}>
                      <Trans>{coverType}</Trans>
                    </Badge>
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Covered risk type</Trans>
                  </Text>
                  <Text>
                    <Badge mt={1} variant={coveredRiskType}>
                      <Trans>{coveredRiskType}</Trans>
                    </Badge>
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text variant="muted">
                    <Trans>Evalutation Type</Trans>
                  </Text>
                  <Text>
                    <Badge mt={1} variant={evaluationType}>
                      <Trans>{evaluationType}</Trans>
                    </Badge>
                  </Text>
                </VStack>
              </HStack>
              <HStack justify="flex-start" spacing="12" w="full">
                <Box>
                  <Stat>
                    <StatLabel>
                      <Trans>Total insured premium</Trans>
                    </StatLabel>
                    <StatNumber>{parseAmount(totalInsuredPremium)}</StatNumber>
                    <StatHelpText fontSize="xs">
                      <Trans>Helper text for total insured premium</Trans>
                    </StatHelpText>
                  </Stat>
                </Box>
                <Box>
                  <Stat>
                    <StatLabel>
                      <Trans>Total insured value</Trans>
                    </StatLabel>
                    <StatNumber>{parseAmount(totalInsuredValue)}</StatNumber>
                    <StatHelpText fontSize="xs">
                      <Trans>Helper text for total insured value</Trans>
                    </StatHelpText>
                  </Stat>
                </Box>
              </HStack>
            </VStack>
          </BorderedBox>
        </VStack>
        <SidebarInfo
          createdBy={createdBy}
          updatedBy={updatedBy}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </HStack>
    </VStack>
  );
};

export default PolicySummary;
