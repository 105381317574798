// import { useMutation, useQuery } from "@apollo/client";
import {
  //  Badge,
  Box,
  Button,
  Divider,
  //  Heading,
  //  HStack,
  // Image,
  Icon,
  Image,
  Skeleton,
} from "@chakra-ui/react";
// import { FocusableElement } from "@chakra-ui/utils";
import {
  DropdownFilter,
  NoDataPlaceholder,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
} from "components/ui";
// import placeholderPNG from "assets/placeholder.png";
// import { GET_ALL_ASSETS } from "graphql/queries";
// import { SEARCH_ASSETS } from "graphql/queries/assets.gql";
import {
  AssetNode,
  useGetAllAssetExceptUserQuery,
  useAddUserObjectPermissionMutation,
  Exact,
  GetAllAssetsForUserQuery,
  // GetAllAssetsDocument,
  // GetAllAssets_allAssets_edges,
  // GetAllAssets_allAssets_edges_node,
} from "graphql/queries/generated/queries";

import getColumns from "helpers/getColumns";
// import { MutableRefObject, useRef /* , useState, useEffect */ } from "react";
import { Trans } from "react-i18next";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
// import { Link } from "react-router-dom";

// import { SearchAssets_assets, SearchAssetsVariables, SearchAssets } from '../../graphql/queries/types/SearchAssets';
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { handleMutation } from "middleware/Toaster";
import { ApolloQueryResult } from "@apollo/client";

// const CUSTOM_FIELDS = ["userType", "country"];
// const CUSTOM_COLUMNS = [
//   {
//     Header: "User Type",
//     Footer: "User Type",
//     accessor: "userType",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
//   {
//     Header: "Country",
//     Footer: "Country",
//     accessor: "country.code",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
// ];

// interface GetAllAssetsNodeDimensions extends GetAllAssets_allAssets_edges_node{
//   dimensions?: string
// }

const CUSTOM_FIELDS = [
  "genericDimensions",
  "netLengthUnit",
  "creationDate",
  "nft",
  "sku",
  "description",
  "validated",
  "inventoryNumber",
  "archiveNumber",
  "catalogNumber",
  "deleted",
  "createdBy",
  "authorEntity",
  "mainImage",
  "title",
  "category",
  "subcategory",
  "technique",
  "actualDateAndPeriod",
  "author",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Thumbnail",
    footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button
          style={{ height: "auto", maxWidth: "120px" }}
          variant="unstyled"
          title="Open in new window"
          onClick={() => window.open(value)}
        >
          <Image src={value} alt="" h="3rem" w="full" />
        </Button>
      ) : (
        ""
      ),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: "Category",
    Footer: "Category",
    disableFilters: true,
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Author",
    Footer: "Author",
    disableFilters: true,
    accessor: "authorEntity.fullName",
    sortInverted: true,
    sortType: "basic",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Title",
    Footer: "Title",
    disableFilters: true,
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  {
    Header: "Subcategory",
    Footer: "Subcategory",
    disableFilters: true,
    accessor: "subcategory",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Creation Date",
    Footer: "Creation Date",
    accessor: "actualDateAndPeriod",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  // {
  //   Header: "Creation Date",
  //   Footer: "Creation Date",
  //   accessor: "creationDate",
  //   disableFilters: true,
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => {
  //     if (value) {
  //       const d = new Date(value).toLocaleDateString("it-IT");
  //       return <div>{d}</div>;
  //     }
  //     return <div />;
  //   },
  // },

  {
    Header: "Dimensions",
    Footer: "Dimensions",
    disableFilters: true,
    accessor: "genericDimensions",
    disableSortBy: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Technique",
    Footer: "Technique",
    disableFilters: true,
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "N.inventory",
    Footer: "N.inventory",
    disableFilters: true,
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "N.archive",
    Footer: "N.archive",
    disableFilters: true,
    accessor: "archiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  // {
  //   Header: "N.catalog",
  //   Footer: "N.catalog",
  //   disableFilters: true,
  //   accessor: "catalogNumber",
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{ value }</div>
  // },
  {
    Header: "nft",
    Footer: "nft",
    accessor: "nft",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  {
    Header: "validated",
    Footer: "validated",
    accessor: "validated",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

const FILTERABLE_COLUMNS = ["deleted" /* , "status" */];

interface AssetsAddableToUserProps {
  userId: string;
  refetchVisibleAssets: (
    variables?: Partial<
      Exact<{
        userId: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAllAssetsForUserQuery>>;
}

const AssetsAddableToUser = ({ userId, refetchVisibleAssets }: AssetsAddableToUserProps) => {
  const { data, loading, error, refetch } = useGetAllAssetExceptUserQuery({
    variables: {
      userId,
    },
  });

  const [
    addObjectPermission,
    {
      data: addObjectPermissionData,
      error: addObjectPermissionError,
      loading: addObjectPermissionLoading,
    },
  ] = useAddUserObjectPermissionMutation({
    ...handleMutation("Objects added to user!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.notUserAssets || data.notUserAssets.edges.length === 0)
    return (
      <Box>
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  if (!data || !data.notUserAssets || data.notUserAssets.edges.length === 0) return null;
  const {
    notUserAssets: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = data.notUserAssets.edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onAddAssetPermission = async (assetIds: string[]) => {
    await addObjectPermission({
      variables: {
        input: {
          userId,
          assetIds,
        },
      },
    });
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Add asset(s) to user</Trans>,
      action: async (selectedRows: { original: AssetNode }[]) => {
        const assetIds = selectedRows.map(({ original: { id } }) => id);
        await onAddAssetPermission(assetIds);
        await refetch();
        await refetchVisibleAssets();
      },
      loading: addObjectPermissionLoading,
    },
  ];

  return (
    <Skeleton isLoaded={!loading} w="full">
      <Table
        batchActions={BATCH_ACTIONS}
        columns={columns}
        data={data.notUserAssets.edges.map(({ node }) => node)}
        pathname="/mgmt/assets/"
        isLoading={addObjectPermissionLoading}
      />
    </Skeleton>
  );
};

export default AssetsAddableToUser;
