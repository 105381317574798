export default {
    authorEntityId: "",
    printerId: "",
    videomakerId: "",

    manufacturerId: "",
    producerId: "",
    paperType: "",
    productionMode: "",
    placement: "",
    title: "",
    technique: "",
    // genericDimensions: "",
    // creationDate: "",

    // campi datazione
    datingType: "",
    creationPeriod: "",
    isDateInterval: Boolean(false),
    dateStartDate: null,
    dateEndDate: null,
    isDateStartDateBc: Boolean(false),
    isDateEndDateBc: Boolean(false),
    isDateApprox: Boolean(false),
    isYearInterval: Boolean(false),
    yearStartDate: undefined,
    yearEndDate: undefined,
    isYearStartDateBc: Boolean(false),
    isYearEndDateBc: Boolean(false),
    isYearApprox: Boolean(false),
    isCenturyInterval: Boolean(false),
    centuryStartDate: undefined,
    centuryEndDate: undefined,
    isCenturyStartDateBc: Boolean(false),
    isCenturyEndDateBc: Boolean(false),
    isCenturyApprox: Boolean(false),
    centuryStartDateWindow: "",
    centuryEndDateWindow: "",
    otherModel: "",
    // fine campi datazione

    // creationPeriod: "",
    hasFullDatetime: Boolean(false),
    hasPeriod: Boolean(false),
    hasYear: Boolean(false),
    series: "",
    seriesDetails: "",
    commonName: "",
    scientificName: "",
    material: "",
    internationalCode: "",

    description: "",
    inventoryNumber: "",
    archiveNumber: "",
    catalogNumber: "",
    criticalCatalogue: Boolean(false),
    nft: Boolean(false),
    nftNotes: "",
    coinMintIssuer: "",
    coinPlacement: "",
    coinPeriod: "",
    coinPlacementNotes: "",
    coinIssueReason: "",
    coinCirculationPeriod: "",
    coinComposition: "",
    banknotePrinter: "",


    coinIdentificationCode: "",
    coverType: "",
    printingType: "",



    netWidth: undefined,
    netHeight: undefined,
    netDepth: undefined,
    netDiameter: undefined,
    netLengthUnit: "",
    netWeightUnit: "",
    netWeight: undefined,
    netDimensionsAcquisition: "",

    grossWidth: undefined,
    grossHeight: undefined,
    grossDepth: undefined,
    grossDiameter: undefined,
    grossLengthUnit: "",

    grossWeightUnit: "",
    grossWeight: undefined,
    grossDimensionsAcquisition: "",

    audioVideoLengthUnit: "",
    audioVideoLength: undefined,
    fileSizeUnit: "",
    fileSize: undefined,
    resolutionUnit: "",
    resolution: undefined,

    dimensionsNotes: "",

    isNotifiedCulturalProperty: Boolean(false),
    notifiedPropertyNote: "",
    legalStatus: "",
    copyrightAndPropertyRestrictions: "",

    isArtistProof: Boolean(false),
    artistProofNotes: "",
    isEdition: Boolean(false),
    editionNotes: "",
    isArtMultiple: Boolean(false),
    multipleNumber: "",
    isSignedObject: Boolean(false),
    signedObjectNotes: "",
    isTitledObject: Boolean(false),
    titledObjectNotes: "",
    isDatedObject: Boolean(false),
    datedObjectNotes: "",
    hasLabelsStamps: Boolean(false),
    labelsStampsNotes: "",
    hasOtherAutographed: Boolean(false),
    otherAutographedNotes: "",
    relevantAwards: "",
    isDynamic: Boolean(false),
    dynamicNotes: "",
    hasHumanAnimalPresence: Boolean(false),
    humanAnimalPresenceNotes: "",
    isAudio: Boolean(false),
    isVideo: Boolean(false),
    hasLoop: Boolean(false),
    isMaster: Boolean(false),
    masterLocation: "",
    coloring: "",
    variants: "",
    nominalValue: null,
    fiscalValue: null,
    currentValue: null,
    currency: "",
    score: "",
    circulation: "",
    manufactoringTechniques: "",
    mintingLastDate: null,
    mintingLastDateNotes: "",
    reminting: "",
    printingLastDate: null,
    printingLastDateNotes: "",
    reprinting: "",
    isFirstDraft: Boolean(false),
    firstDraftNotes: "",
    mechanism: "",
    carat: "",



    surfaceCharacteristics: "",
    hasBase: Boolean(false),
    baseDetails: "",
    hasContainer: Boolean(false),
    containerDetails: "",
    hasWoodFrame: Boolean(false),
    woodFrameDetails: "",
    hasFrame: Boolean(false),
    hasOriginalFrame: Boolean(false),
    frameMaterial: "",
    isValuableFrame: Boolean(false),
    frameManufacturer: "",
    frameDescription: "",
    compositionDescription: "",
    methodOfUse: "",
    relatedPhysicalWork: "",
    conservationMethod: "",
    distribution: "",
    edge: "",
    orientation: "",
    shape: "",
    rim: "",
    rotationAxes: "",
    legend: "",
    coinTitle: "",
    mintMadeErrors: "",

    signings: "",
    relief: "",
    watermark: "",
    holographicStripe: "",
    securityThread: "",
    printingErrors: "",

    color: "",
    postageMark: "",
    rubber: "",
    stampPrintingTechnique: "",
    stampRealization: "",
    stampIndentationType: "",
    stampCenteringAndMargins: "",
    stampFormat: "",
    bookContainer: "",
    dustJacket: "",
    frontCover: "",
    rearCover: "",
    topEdge: "",
    foreEdge: "",
    footEdge: "",
    bookbinding: "",
    interiorDecorations: "",
    externalDecorations: "",
    flyleaf: "",
    glueing: "",
    headband: "",
    headcap: "",
    corner: "",
    square: "",
    slots: "",
    spine: "",
    clips: "",
    raisedBands: "",
    label: "",
    clothCovering: "",
    usage: "",
    replacedParts: "",
    stateOfConservation: "",



    hasBarriers: Boolean(false),
    barriersDetails: "",
    handlingRequirements: "",
    injuryRisk: "",
    injuryRiskDescription: "",
    mouldingSupportBase: "",
    hardwareToolsInstallation: "",
    conservation: "",
    lenderRequests: "",
    settingNotes: "",


    optimalLightLevelIntensity: undefined,
    lightUnit: "",
    optimalTemperature: undefined,
    temperatureUnit: "",
    optimalHumidity: undefined,
    enviromentalConditionsNotes: "",
    infoNotes: "",

    originNotes: "",
    bibliographyNotes: "",

    // valore di carico
    carryingValueOrigin: "",
    carryingValueAcquisition: "",
    carryingValueNotes: "",
    carryingValueLastAcquisitionDate: null,
    carryingValueAmount: {
        amount: 0,
        currency: "EUR",
    },
    carryingValueFileTitle: "",
    carryingValueFile: "",


    // campi discovery
    discoveryPlace: "",
    discoverySite: "",
    discoveryDate: null,
    discoveryNotes: "",
    discoveryAuthor: "",
    catalogingAuthor: "",
    objectSetupAuthor: "",


    // campi taxonomy
    taxonomyDomain: "",
    taxonomyKingdom: "",
    taxonomyPhylum: "",
    taxonomyClass: "",
    taxonomyOrder: "",
    taxonomyFamily: "",
    taxonomyTribe: "",
    taxonomyGenus: "",
    taxonomyNotes: "",
    classificationUsa: "",
    classificationIta: "",
    classificationEng: "",
    classificationFra: "",
    classificationSpa: "",
    classificationGer: "",
    classificationPhilatelic: "",
    rarityDegreePhilatelic: "",
    rarityDegree: "",
    conservationState: "",
    rarityDegreeNotes: "",


    validated: false,
}