/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import any from "ramda/src/any";
import assoc from "ramda/src/assoc";
import curry from "ramda/src/curry";
import find from "ramda/src/find";
import isEmpty from "ramda/src/isEmpty";
import map from "ramda/src/map";
import propEq from "ramda/src/propEq";
import reduce from "ramda/src/reduce";
import when from "ramda/src/when";
import without from "ramda/src/without";

type QueuedItem = {
  args: any[];
  id: string;
  result?: any;
  status: "inProgress" | "resolved" | "pending";
};

class Target extends EventTarget {}

/**
 * useStagger is a higher order hook that returns a function, which when
 * invoked multiple times resolves each invocation sequentially; one at a time.
 * This effectively staggers a function when invoked multiple times.
 *
 * @param executable
 */
export default function useStagger<Args, Resolve>(executable: Function) {
  const [queue, setQueue] = useState<QueuedItem[]>([]);
  const target = useMemo(() => new Target(), []);

  useEffect(() => {
    const isItemToProcess = !isEmpty(queue);
    const isItemInProgress = any(propEq("status", "inProgress"), queue);

    if (!isItemToProcess || isItemInProgress) return;

    const resolvedItem = find(propEq("status", "resolved"), queue) as QueuedItem;

    if (resolvedItem) {
      const { result } = resolvedItem;
      target.dispatchEvent(new CustomEvent(resolvedItem.id, { detail: { result } }));
      setQueue((_queue) => without([resolvedItem], _queue));
    } else {
      const itemToProcess = queue[0];
      setQueue((_queue) => alter({ key: "status", value: "inProgress" }, itemToProcess.id, _queue));
      (async () => {
        const result = await executable(...itemToProcess.args);
        setQueue((_queue) =>
          alterAll(
            [
              { key: "status", value: "resolved" },
              { key: "result", value: result },
            ],
            itemToProcess.id,
            _queue
          )
        );
      })();
    }
  }, [queue]);

  return useCallback(
    (...args: Args[]) => {
      const deferred = new Deferred<Resolve>();

      const id = uuid();
      setQueue((_queue) => [..._queue, { id, args, status: "pending" }]);

      target.addEventListener(id, (e) => {
        if (!isCustomEvent(e)) {
          throw new Error("not a custom event");
        }
        deferred.resolve(e.detail.result);
      });

      return deferred.promise;
    },
    [executable, queue]
  );
}

/**
 * alter a specific object's properties within an array
 */
const alter = curry(({ key, value }: { key: string; value: any }, id: string, items: any[]) =>
  map(when(propEq("id", id), assoc(key, value)), items)
);

/**
 * alter multiple properties of a specific object within an array
 */
const alterAll = (keyValuePairs: { key: string; value: any }[], id: string, items: any[]) =>
  reduce((acc, { key, value }) => alter({ key, value }, id, acc), items, keyValuePairs);

/**
 * class which enables promises to be resolved/rejected outside their internal scope
 */
class Deferred<Resolve> {
  promise: Promise<Resolve>;

  reject!: (reason?: any) => void;

  resolve!: (value: PromiseLike<Resolve> | Resolve) => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

/**
 * type guard to narrow Event type to a CustomEvent
 *
 * @param event
 */
function isCustomEvent(event: Event): event is CustomEvent {
  return "detail" in event;
}
