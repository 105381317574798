import { Box, Divider, Skeleton, useDisclosure, Badge } from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import {
  CreatedByFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  TablePageSkeleton,
} from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import {
  useDeleteRegistryMutation,
  useGetAllRegistriesPeopleQuery,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { MutableRefObject, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { REGISTRY_ROUTES } from "routes/constants";

// const BATCH_ACTIONS = [
//   {
//     name: <Trans>Disable selected users</Trans>,
//     action: (selectedRows: UserNodeEdge) => console.log(selectedRows),
//   },
// ];
// const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new registry entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const CUSTOM_FIELDS = [
  "createdBy",
  "role",
  "mainOfficeAddress",
  "actualCompanyContactsNumber",
  "fullName",
  "businessName",
  "email",
  "phone",
  "mobilePhone",
  "officesCount",
  "categories",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Category",
    Footer: "Category",
    disableFilters: true,
    accessor: "categories",
    Cell: ({ cell: { value } }: { cell: { value: string[] } }) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {value?.map((v) => (
          <Badge variant={v} mr="1">
            <Trans>{v}</Trans>
          </Badge>
        ))}
      </>
    ),
  },
  {
    Header: "Full Name",
    Footer: "Full Name",
    disableFilters: true,
    accessor: "fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Business Name",
    Footer: "Business Name",
    disableFilters: true,
    accessor: "businessName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Role",
    Footer: "Role",
    disableFilters: true,
    accessor: "role",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Email",
    Footer: "Email",
    disableFilters: true,
    accessor: "email",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Phone",
    Footer: "Phone",
    disableFilters: true,
    accessor: "phone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Mobile Phone",
    Footer: "Mobile Phone",
    disableFilters: true,
    accessor: "mobilePhone",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Main Office Address",
    Footer: "Main Office Address",
    disableFilters: true,
    accessor: "mainOfficeAddress",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Offices Count",
    Footer: "Offices Count",
    disableFilters: true,
    accessor: "officesCount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Contacts Number",
    Footer: "Contacts Number",
    disableFilters: true,
    accessor: "actualCompanyContactsNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Created by",
    Footer: "Created by",
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
];

const RegistriesPeoplePage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllRegistriesPeopleQuery();
  const [deleteRegistry, { loading: deleteLoading }] = useDeleteRegistryMutation({
    ...handleMutation("Registry deleted!"),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.allRegistries || data.allRegistries.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allRegistries: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    // filterableColumns: FILTERABLE_COLUMNS,
  });

  // const onDelete = (id: string) => console.log(id);

  const onDelete = async (id: string) => {
    await deleteRegistry({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allRegistries.edges.flatMap((edge) => edge?.node)}
          pathname={`${REGISTRY_ROUTES.REGISTRY}/`}
          deleteAction={(id: string) => onDelete(id)}
          hiddenColumns={["categories"]}
        />
      </Skeleton>
    </Box>
  );
};

export default RegistriesPeoplePage;
