/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button, Heading, HStack, VStack, Text, Divider } from "@chakra-ui/react";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import {
  AssetInput,
  PolicyNode,
  useCreateAssetMutation,
  useCreatePolicyAssetMutation,
  useDeletePolicyAssetMutation,
  useUpdatePolicyAssetGroupMutation,
  GroupedPolicyAssetInput,
  PolicyAssetNode,
  UpdatePolicyInputData,
  GetPolicyByIdQuery,
  Exact,
  useGetAssetByIdForReviewLazyQuery,
  GetAssetByIdForReviewQuery,
} from "graphql/queries/generated/queries";
import { PolicyAssetInputExtended, PolicyType } from "pages/spin/types";
import { useCallback, useEffect, useState } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { scroller } from "react-scroll";
import { BorderedBox, FormFooter, FormInputHook } from "components/ui";
import { BaseRow } from "react-csv-importer";
import useGetAssetCurrentValue from "hooks/useGetAssetCurrentValue";
import useGetOrCreateRegistry from "hooks/useGetOrCreateRegistry";
import stripProperty from "helpers/stripProperty";
import parseBooleanString from "helpers/parseBooleanString";
import { ApolloQueryResult, LazyQueryExecFunction } from "@apollo/client";
import useStagger from "helpers/useStagger";
import { handleMutation, toast } from "middleware/Toaster";
import { parseObjectToCreateEntity } from "helpers/processCsvRow";
import NewAssetDrawer from "../NewAssetDrawer";
import OnEditPolicyAssetsTable, { BatchFields, defaultValues } from "./OnEditPolicyAssetsTable";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import ImportAssetsDrawer from "../ImportAssetsDrawer";

interface PolicyAssetsFormProps {
  type: PolicyType;
  policy: Partial<PolicyNode>;
  data: Partial<PolicyAssetInputExtended>[];
  policyAssetFields: any;
  refetch: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetPolicyByIdQuery>>;
  policyDetailsFormMethods: UseFormReturn<UpdatePolicyInputData, object>;
}

const PolicyAssetsForm = ({
  policy,
  type,
  data,
  policyAssetFields,
  refetch,
  policyDetailsFormMethods,
}: PolicyAssetsFormProps) => {
  const [BROKEN_getAssetByIdForReview] = useGetAssetByIdForReviewLazyQuery();
  const getAssetByIdForReview: LazyQueryExecFunction<
    GetAssetByIdForReviewQuery,
    Exact<{
      id: string;
    }>
  > = useStagger(BROKEN_getAssetByIdForReview);
  const { t } = useTranslation();

  const getOrCreateRegistry = useGetOrCreateRegistry();
  const getAssetCurrentValue = useGetAssetCurrentValue();
  const [, updateState] = useState<object | null>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [watchedPolicyAsset, setWatchedPolicyAssets] = useState([]);
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [showNewAssetDrawer, setShowNewAssetDrawer] = useState<boolean>(false);
  const [createAsset, { data: createAssetData, error: createAssetError }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });
  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [
    createPolicyAsset,
    {
      data: createPolicyAssetData,
      error: createPolicyAssetError,
      loading: createPolicyAssetLoading,
      reset: createPolicyAssetReset,
    },
  ] = useCreatePolicyAssetMutation({ ...handleMutation("Asset added to policy!") });

  const [
    updatePolicyAssetGroup,
    {
      data: updatePolicyAssetData,
      error: updatePolicyAssetError,
      loading: updatePolicyAssetLoading,
      reset: updatePolicyAssetReset,
    },
  ] = useUpdatePolicyAssetGroupMutation({ ...handleMutation("Assets updated!") });

  const [
    deletePolicyAsset,
    {
      data: deletePolicyAssetData,
      error: deletePolicyAssetError,
      loading: deletePolicyAssetLoading,
    },
  ] = useDeletePolicyAssetMutation({ ...handleMutation("Asset removed from policy!") });

  // ---- START FORM INITIALIZATION ----
  // This is needed bc Policy Assets Table has subrows that contain each policy asset individual form
  // in this shape: policyAsset.0.lenderId, policyAsset.1.lenderId etc etc...
  // but the data that we get has registry obj in full {typename, fullName, id}
  // so we must parse it and set lenderId, shipperId or any Registry obj by hand
  const parsePolicyAssetsForForm = (data: Partial<PolicyAssetInputExtended>[]) =>
    data.map(({ lender, shipper, objectLocationEntity, objectLocationOffice, ...rest }) => ({
      lenderId: lender?.id,
      shipperId: shipper?.id,
      objectLocationEntityId: objectLocationEntity?.id,
      objectLocationOfficeId: objectLocationOffice?.id,
      ...rest,
    }));

  // We do it once for default values
  const defaultPolicyAssetsValues = parsePolicyAssetsForForm(data);

  const methods = useForm<{ policyAssets: PolicyAssetInputExtended[]; overrideChangeDate: string }>(
    {
      defaultValues: {
        // We pass it to default values
        policyAssets: defaultPolicyAssetsValues,
      },
    }
  );

  useEffect(() => {
    // We update and parse policyAssets each time they change
    const parsedData = parsePolicyAssetsForForm(data);
    methods.setValue("policyAssets", parsedData);
  }, [methods, data]);
  // ---- END FORM INITIALIZATION ----

  const onSubmit: SubmitHandler<{
    policyAssets: PolicyAssetInputExtended[];
    overrideChangeDate: string;
  }> = async (
    formData,
    e
    // eslint-disable-next-line @typescript-eslint/require-await
  ) => {
    createPolicyAssetReset();
    updatePolicyAssetReset();

    const { policyAssets, overrideChangeDate } = formData;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const parsedPolicyAssets = policyAssets.map((policyAsset) => {
      const {
        title,
        creationPeriod,
        image,
        mainImage,
        author,
        category,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        createdBy,
        genericDimensions,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        inventoryNumber,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        objectLocationEntity,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        objectLocationOffice,
        actualDateAndPeriod,
        technique,
        validated,
        lender,
        shipper,
        authorEntity,
        certificateProgressiveNumber,
        ...policyAssetData
      } = policyAsset;
      return policyAssetData;
    });

    const strippedPolicyAssets = parsedPolicyAssets.map((pa) => stripProperty(pa));

    const newlyAddedPolicyAssets = strippedPolicyAssets.filter(({ status }) => status === "NEW");
    const updatedPolicyAssets = strippedPolicyAssets.filter(({ id }) => id);

    newlyAddedPolicyAssets.every(async (policyAsset) => {
      await createPolicyAsset({
        variables: {
          input: {
            overrideChangeDate,
            policyAssetData: {
              policy: policy.id,
              ...policyAsset,
            },
          },
        },
      });
    });

    const policyAssetsData: GroupedPolicyAssetInput[] = updatedPolicyAssets.map((policyAsset) => {
      const { id, insuredValue, asset, ...rest } = policyAsset;

      let parsedInsuredValue = insuredValue;

      if (!parsedInsuredValue.amount)
        parsedInsuredValue = {
          amount: 0,
          currency: "EUR",
        };

      return {
        id: id ?? "ERROR",
        ...rest,
        insuredValue: {
          ...parsedInsuredValue,
        },
      };
    });

    if (policyAssetsData.length > 0) {
      const result = await updatePolicyAssetGroup({
        variables: {
          input: {
            policyAssetsData,
            overrideChangeDate,
          },
        },
      });
    }

    await refetch();
  };

  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: "policyAssets",
  });

  const {
    formState: { isDirty },
  } = methods;
  const watchPolicyAssets = methods.watch("policyAssets");

  const addPolicyAsset = async ({
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => {
    const {
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      eventEntity,
      eventLocationEntity,
    } = policy;

    const { amount, currency } = (await getAssetCurrentValue(gqlId)) || {
      amount: "0.00",
      currency: "EUR",
    };

    const {
      data: getAssetByIdForReviewData,
      loading: getAssetByIdForReviewLoading,
      error: getAssetByIdForReviewError,
    } = await getAssetByIdForReview({ variables: { id: gqlId } });

    const { id, creationDate, ...asset } = getAssetByIdForReviewData.asset;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    append({
      ...policyAssetFields,
      asset: gqlId,
      title,
      author,
      creationPeriod,
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      objectLocationEntityId: eventEntity?.id,
      objectLocationOfficeId: eventLocationEntity?.id,
      insuredValue: {
        amount: amount ?? null,
        currency: currency ?? "EUR",
      },
      ...asset,
      status: "NEW",
    });
  };

  const parseRow = async ({
    row,
    index,
  }: {
    row: AssetInput &
      PolicyAssetNode & { assetPresentValueAmount: string } & { lenderCountry: string } & {
        author: string;
      } & { assetPresentValueCurrency: string };
    index: number;
  }) => {
    const { author, lender, shipper, ...copiedRow } = row;
    if (!row.category) {
      copiedRow.category = "not_validated";
    }

    if (!row.subcategory) {
      copiedRow.subcategory = "";
    }

    if (row.title.trim() === "") {
      console.log(row.title, row.title.trim(), row.title.trim() === "");
      toast({
        title: t(`Asset at row ${index} couldn't be uploaded`),
        description: t(`Error: Asset is missing title, which is a required field.`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      return null;
    }
    try {
      const authorEntityId = await getOrCreateRegistry(row.author as unknown as string);

      const lenderId = lender ? await getOrCreateRegistry(row.lender as unknown as string) : null;
      const shipperId = shipper
        ? await getOrCreateRegistry(row.shipper as unknown as string)
        : null;

      const parsedAssetPresentValueAmount = row?.assetPresentValueAmount?.replace(/\D/g, "");

      return {
        ...copiedRow,
        category: "not_validated",
        subcategory: "",
        authorEntityId,
        lenderId,
        shipperId,
        netWidth: parseFloat(row.netWidth as unknown as string),
        netHeight: parseFloat(row.netHeight as unknown as string),
        netDepth: parseFloat(row.netDepth as unknown as string),
        assetPresentValueAmount: parsedAssetPresentValueAmount,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    await Promise.all(
      // eslint-disable-next-line consistent-return
      rows.map(
        async (
          row: AssetInput &
            PolicyAssetNode & { assetPresentValueAmount: string } & { lenderCountry: string } & {
              author: string;
            } & { assetPresentValueCurrency: string },
          index: number
          // eslint-disable-next-line consistent-return
        ) => {
          const entities = await parseObjectToCreateEntity(row, getOrCreateRegistry);
          const parsedRow = await parseRow({ row, index });
          const {
            evaluationType: evaluationTypeRow,
            coverTerrorism: coverTerrorismRow,
            catNat: catNatRow,
            assetPresentValueAmount: assetPresentValueAmountRow,
            lenderCountry: lenderCountryRow,
            coveredRiskType: coveredRiskTypeRow,
            coverType: coverTypeRow,
            exemption: exemptionRow,
            lenderAddress: lenderAddressRow,
            assetPresentValueCurrency,
            authorEntityId,
            lenderId,
            shipperId,
            ...assetInput
          } = parsedRow;

          const { data } = await createAsset({
            variables: {
              input: {
                assetData: {
                  ...assetInput,
                  authorEntityId,
                  validated: false,
                  creationDateNotes: assetInput.creationPeriod,
                  carryingValueAmount: {
                    amount: Number(assetPresentValueAmountRow),
                    currency: "EUR",
                  },
                },
              },
            },
          });

          if (!data.createAsset) throw new Error("Error importing asset");
          const {
            createAsset: { asset },
          } = data;

          const { id, title, author, creationPeriod } = asset;

          const {
            evaluationType,
            coveredRiskType,
            coverType,
            catNat,
            coverTerrorism,
            eventEntityId,
            eventLocationEntityId,
            exemption,
          } = policyDetailsFormMethods.getValues();

          if (type === "TEMPORARY_EXHIBITION") {
            try {
              append({
                ...policyAssetFields,
                asset: id,
                title,
                author,
                creationPeriod,
                evaluationType: evaluationTypeRow ?? evaluationType,
                catNat: parseBooleanString(catNatRow as unknown as string) ?? catNat,
                coverTerrorism:
                  parseBooleanString(coverTerrorismRow as unknown as string) ?? coverTerrorism,
                exemption: parseBooleanString(exemptionRow as unknown as string) ?? exemption,
                coveredRiskType: coveredRiskTypeRow ?? coveredRiskType,
                coverType: coverTypeRow ?? coverType,
                lenderId: lenderId ?? "",
                shipperId: shipperId ?? "",
                lenderAddress: {
                  raw: (lenderAddressRow as string) ?? "",
                },
                insuredValue: {
                  amount: Number(assetPresentValueAmountRow),
                  currency: "EUR",
                },
                objectLocationEntityId: eventEntityId,
                objectLocationOfficeId: eventLocationEntityId,
                status: "NEW",
              });
            } catch (error) {
              console.log(error);
              toast({
                title: t("Something went wrong while importing assets"),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                description: error instanceof Error ? error.message : "",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              throw error;
            }
          }

          if (type === "PERMANENT_COLLECTION") {
            try {
              append({
                ...policyAssetFields,
                asset: id,
                title,
                author,
                creationPeriod,
                evaluationType: row.evaluationType ?? evaluationType,
                catNat: parseBooleanString(catNatRow as unknown as string) ?? catNat,
                coverTerrorism:
                  parseBooleanString(coverTerrorismRow as unknown as string) ?? coverTerrorism,
                coveredRiskType: row.coveredRiskType ?? coveredRiskType,
                coverType: row.coverType ?? coverType,
                exemption: parseBooleanString(exemptionRow as unknown as string) ?? exemption,
                objectLocationEntityId: eventEntityId,
                objectLocationOfficeId: eventLocationEntityId,
                insuredValue: {
                  amount: Number(assetPresentValueAmountRow),
                  currency: "EUR",
                },
                status: "NEW",
              });
            } catch (error) {
              console.log(error);
              toast({
                title: t("Something went wrong while importing assets"),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                description: error instanceof Error ? error.message : "",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              throw error;
            }
          }
        }
      )
    );
  };

  const removePolicyAsset = async (id: string) => {
    const { overrideChangeDate } = methods.getValues();
    try {
      const decodedId = atob(id);
      await deletePolicyAsset({
        variables: {
          input: {
            id,
            overrideChangeDate,
          },
        },
      });

      await refetch();
      // TODO: optmistic removing policy assets

      const removedPolicyAssetIndex = watchPolicyAssets.findIndex(
        ({ id: removedAssetId }) => removedAssetId === id
      );
      remove(removedPolicyAssetIndex);

      forceUpdate();
    } catch (e) {
      remove(id as unknown as number);
    }
  };

  const policyValues = policyDetailsFormMethods.getValues();
  const batchUpdateDefaults: BatchFields = {
    ...defaultValues,
    objectLocationEntityId: policyValues.eventEntityId,
    objectLocationOfficeId: policyValues.eventLocationEntityId,
    catNat: policyValues.catNat,
    coverTerrorism: policyValues.coverTerrorism,
    coverType: policyValues.coverType,
    coveredRiskType: policyValues.coveredRiskType,
    evaluationType: policyValues.evaluationType,
    exemption: policyValues.exemption,
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <VStack alignItems="flex-start" mt={8}>
            <Heading size="md">Assets currently in policy</Heading>
            <Text variant="muted">
              <Trans>Assets currently in policy subtitle</Trans>
            </Text>
            <HStack pt={2} pb={8}>
              <Button
                onClick={() => {
                  setUserWantsToAddAssets(true);
                  setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    scroller.scrollTo("userWantsToAddAssetsRefToScrollTo", {
                      duration: 800,
                      delay: 100,
                      smooth: "easeInOutQuart",
                    });
                  }, 500);
                }}
              >
                <Trans>Select an existing asset</Trans>
              </Button>

              <Button onClick={() => setShowNewAssetDrawer(true)}>
                <Trans>Create new asset</Trans>
              </Button>
              <Button onClick={() => setShowImportAssetsDrawer(true)}>
                <Trans>Import from csv</Trans>
              </Button>
            </HStack>
          </VStack>
          <OnEditPolicyAssetsTable
            data={watchPolicyAssets}
            type={type}
            remove={removePolicyAsset}
            update={update}
            batchUpdateDefaults={batchUpdateDefaults}
          />
          <Divider my="4" />
          <BorderedBox
            title="Override basket change date"
            subtitle="override basket change date subtitle"
          >
            <FormInputHook name="overrideChangeDate" type="date" label="overrideChangeDate" />
          </BorderedBox>
          <FormFooter
            sticky
            errors={[createPolicyAssetError, updatePolicyAssetError]}
            data={[createPolicyAssetData, updatePolicyAssetData]}
            title="Policy asset set"
            loading={createPolicyAssetLoading || updatePolicyAssetLoading}
            disabled={!isDirty}
          />
        </form>
      </FormProvider>
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addPolicyAsset={addPolicyAsset}
        policyAssets={watchPolicyAssets}
      />
      <NewAssetDrawer
        show={showNewAssetDrawer}
        onClose={() => setShowNewAssetDrawer(false)}
        addPolicyAsset={addPolicyAsset}
      />
      <ImportAssetsDrawer
        show={showImportAssetsDrawer}
        onClose={() => setShowImportAssetsDrawer(false)}
        processChunk={processCsvRows}
      />
    </>
  );
};

export default PolicyAssetsForm;
