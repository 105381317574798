import { Box, Heading, HStack, VStack, Text, Badge, Divider } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  CreatedByFilter,
  BooleanFilter,
  TablePageSkeleton,
} from "components/ui";
import { RiAddLine } from "react-icons/ri";
import getColumns, { BooleanCell } from "helpers/getColumns";
import {
  PolicyNode,
  useDeletePolicyMutation,
  useGetAllPoliciesQuery,
} from "graphql/queries/generated/queries";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { handleMutation } from "middleware/Toaster";
// import { BooleanCell } from "helpers/getColumns";
import { SPIN_ROUTES } from "routes/constants";
import parseAmount from "helpers/parseAmount";
const CUSTOM_FIELDS = [
  "policyType",
  "policyStatus",
  "totalInsuredValue",
  "totalInsuredPremium",
  "coveredRiskType",
  "coverType",
  "evaluationType",
  "contractingParty",
  "insuranceCompany",
  "broker",
  "policyCode",
  "offerCode",
  "createdBy",
  "isExpired",
  "hasValidCertificate",
  'assetsCount',
];
const CUSTOM_COLUMNS = [
  {
    Header: "Policy Type",
    accessor: "policyType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      const label = value === "TEMPORARY_EXHIBITION" ? "TE" : "PC";
      return <Badge variant={value}>{label}</Badge>;
    },
    weight: 10,
  },
  {
    Header: "Type",
    accessor: "policyStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      const label = value === "PENDING" ? "O" : "P";
      return <Badge variant={value}>{label}</Badge>;
    },
    weight: 10,
  },
  {
    Header: "Policy #",
    Footer: "Policy #",
    accessor: "policyCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
  },
  {
    Header: "Offer #",
    Footer: "Offer #",
    accessor: "offerCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
  },

  {
    Header: "Contracting party",
    Footer: "Contracting party",
    disableFilters: true,
    accessor: "contractingParty.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 8,
  },
  {
    Header: "broker",
    Footer: "broker",
    disableFilters: true,
    accessor: "broker.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 9,
  },
  {
    Header: "N. assets",
    Footer: "N. assets",
    disableFilters: true,
    accessor: "assetsCount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge colorScheme="blackAlpha">{value}</Badge>
    ),
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "totalInsuredValue.amount",
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyNode } };
    }) => {
      const { totalInsuredValue } = original;
      return parseAmount(totalInsuredValue);
    },
  },
  {
    Header: "insurance Company",
    Footer: "insurance company",
    disableFilters: true,
    accessor: "insuranceCompany.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
  },

  {
    Header: "Covered Risk Type", // No header
    Footer: "",
    accessor: "coveredRiskType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Cover Type", // No header
    Footer: "",
    accessor: "coverType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Evaluation Type", // No header
    Footer: "",
    accessor: "evaluationType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
  {
    Header: "",
    Footer: "",
    disableFilters: false,
    Filter: BooleanFilter,
    accessor: "isExpired",
    Cell: () => <div />,
  },
  {
    Header: "Output",
    Footer: "Output",
    accessor: "hasValidCertificate",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
];

const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new Temporary Exhibition Policy",
    href: SPIN_ROUTES.POLICY_TE_NEW,
    icon: <RiAddLine />,
  },
  {
    name: "Add new Permanent Collection Policy",
    href: SPIN_ROUTES.POLICY_PC_NEW,
    icon: <RiAddLine />,
  },
];

const SpinIndexPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllPoliciesQuery();
  const [
    deletePolicy,
    { data: deletePolicyData, error: deletePolicyError, loading: deletePolicyLoading },
  ] = useDeletePolicyMutation({
    ...handleMutation("Policy deleted!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;
  if (!data || !data.allPolicies || data.allPolicies.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicies: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onBatchDelete = async (selectedRows: { original: PolicyNode }[]) => {
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      await Promise.all(
        selectedRows.map(async ({ original: { id } }) => {
          await deletePolicy({
            variables: {
              input: {
                id,
              },
            },
          });
        })
      );
      await refetch();
    }
  };

  const onDelete = async (id: string) => {
    await deletePolicy({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete policies</Trans>,
      intent: "danger",
      action: (selectedRows: { original: PolicyNode }[]) => onBatchDelete(selectedRows),
    },
  ];

  return (
    <VStack align="flex-start">
      <HStack spacing="12" justify="flex-start">
        <Link to={SPIN_ROUTES.POLICY_PC_NEW}>
          <Box p="4" rounded="md" shadow="md">
            <Heading size="md">
              <Trans>Insure a permanent collection</Trans>
            </Heading>

            <Text>
              <Trans>Insure a permanent collection button description</Trans>
            </Text>
          </Box>
        </Link>
        <Link to={SPIN_ROUTES.POLICY_TE_NEW}>
          <Box p="4" rounded="md" shadow="md">
            <Heading size="md">
              <Trans>Insure a temporary exhibition</Trans>
            </Heading>
            <Text>
              <Trans>Insure a temporary exhibition button description</Trans>
            </Text>
          </Box>
        </Link>
      </HStack>
      <Table
        batchActions={BATCH_ACTIONS}
        hiddenColumns={[
          "coveredRiskType",
          "coverType",
          "totalInsuredPremium.amount",
          "evaluationType",
        ]}
        columns={columns}
        data={data.allPolicies.edges.flatMap((e) => e?.node)}
        pathname={SPIN_ROUTES.POLICY_REDIRECT}
      />
    </VStack>
  );
};

export default SpinIndexPage;
