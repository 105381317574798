import { BorderedBox, Table } from "components/ui";
import { useEffect, useState } from "react";
import { useGetCompanyContactsByRegistryIdLazyQuery } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import {
  REGISTRY_CUSTOM_FIELDS,
  REGISTRY_HIDDEN_FIELDS,
  REGISTRY_CUSTOM_COLUMNS,
} from "../shared/RegistryDatatableCostants";

interface CompanyContactsViewParameters {
  registryId: string;
}

interface DataGridCompanyContact {
  main: boolean;
  fullName: string;
  alias: string;
  role: string;
  phone: string;
  mobilePhone: string;
  email: string;
}

const CompanyContactsViewPage = ({ registryId }: CompanyContactsViewParameters) => {
  const [loadCompanyContacts, refetch] = useGetCompanyContactsByRegistryIdLazyQuery();

  const [companyContacts, setCompanyContacts] = useState<Array<DataGridCompanyContact>>([]);

  // eslint-disable-next-line consistent-return
  const loadParsedCompanyContacts = async (registryId: string) => {
    const {
      data: companyContacts,
      loading: companyContactsLoading,
      error: companyContactsError,
    } = await loadCompanyContacts({
      variables: {
        registryId,
      },
    });

    if (
      !companyContacts ||
      !companyContacts.allCompanyContacts ||
      !companyContacts.allCompanyContacts.edges ||
      companyContacts.allCompanyContacts.edges.length <= 0
    )
      return null;

    const result: DataGridCompanyContact[] = [];

    for (let i = 0; i < companyContacts.allCompanyContacts.edges.length; i += 1) {
      const element: DataGridCompanyContact = {
        main: companyContacts.allCompanyContacts.edges[i].node.main,
        fullName: companyContacts.allCompanyContacts.edges[i].node.companyContact.fullName,
        alias: companyContacts.allCompanyContacts.edges[i].node.companyContact.alias,
        role: companyContacts.allCompanyContacts.edges[i].node.companyContact.role,
        phone: companyContacts.allCompanyContacts.edges[i].node.companyContact.phone,
        mobilePhone: companyContacts.allCompanyContacts.edges[i].node.companyContact.mobilePhone,
        email: companyContacts.allCompanyContacts.edges[i].node.companyContact.email,
      };

      result.push(element);
    }

    const {
      allCompanyContacts: { edges },
    } = companyContacts;

    setCompanyContacts(result);

    return result;
  };

  useEffect(() => {
    if (typeof registryId !== "undefined" && registryId !== "") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadParsedCompanyContacts(registryId);
    }

    return undefined;
  }, []);

  let dataToGetColumns;
  let columns;

  if (companyContacts && companyContacts.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    dataToGetColumns = companyContacts[0];

    columns = getColumns({
      data: dataToGetColumns,
      customFields: REGISTRY_CUSTOM_FIELDS,
      customColumns: REGISTRY_CUSTOM_COLUMNS,
    });
  }

  return (
    <BorderedBox title="Company Contacts" subtitle="Lorem ipsum dolor sit amet">
      <Table hiddenColumns={REGISTRY_HIDDEN_FIELDS} columns={columns} data={companyContacts} />
    </BorderedBox>
  );
};

export default CompanyContactsViewPage;
