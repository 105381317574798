import { Icon, /* Image, Button */ } from "@chakra-ui/react";
// import placeholderPNG from "assets/placeholder.png";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { DropdownFilter } from 'components/ui';
import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";

// import ASSET_CONSTANTS from 'constants/asset';
// import findLabel from "./FindLabel";



// const document_types = {};
// ASSET_CONSTANTS.ASSET_DOCUMENT_TYPES.forEach(e => {
//     document_types[e.value] = e.label;
// });


export const DOCUMENTS_CUSTOM_COLUMNS = [
    // {
    //     Header: "File",
    //     footer: "",
    //     accessor: "file",
    //     Cell: ({ cell: { value } }: { cell: { value: string } }) =>
    //     value ? <Button style={{height: "auto", maxWidth: "120px"}} variant="unstyled" title="Open in new window" onClick={() => window.open(value)} ><img src={value} alt="" /></Button> : <Image src={placeholderPNG} alt="" />,

    //     disableFilters: true,
    // },
    {
        Header: "Type",
        Footer: "",
        accessor: "documentType",
        // Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div> {document_types[value]}
        // </div> : <div>{value}</div>,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "Title",
        Footer: "",
        accessor: "title",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Editor",
        Footer: "",
        accessor: "editor",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Curator",
        Footer: "",
        accessor: "curator",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Release date",
        Footer: "",
        accessor: "releaseDate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Subscription date",
        Footer: "",
        accessor: "subscriptionDate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Publishing date",
        Footer: "",
        accessor: "publishingDate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    {
        Header: "Expiration date",
        Footer: "",
        accessor: "expirationDate",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

        disableFilters: true,
    },
    // {
    //     Header: "COA type",
    //     Footer: "",
    //     accessor: "coaType",
    //     Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

    //     disableFilters: true,
    // },
    {
        Header: "Issuer",
        Footer: "",
        accessor: "issuer",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Recipient",
        Footer: "",
        accessor: "recipient",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Classified",
        Footer: "",
        accessor: "isClassified",
        Cell: ({ cell: { value } }: { cell: { value: string } }) =>
            value ? (
                <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
            ) : (
                <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
            ),
        Filter: DropdownFilter,
        // disableFilters: true,
    },
    {
        Header: "Attachments number",
        Footer: "",
        accessor: "attachmentCount",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Created by",
        Footer: "Created by",
        disableFilters: false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        Filter: CreatedByFilter,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filter: createdByFilterFunc,
        accessor: "createdBy.name",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    },
    // {
    //     Header: "Main",
    //     Footer: "",
    //     accessor: "isMainImage",
    //     Cell: ({ cell: { value } }: { cell: { value: string } }) =>
    //     value ? (
    //         <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
    //     ) : (
    //         <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
    //     ),
    //     Filter: DropdownFilter,
    // }
];


export const DOCUMENTS_CUSTOM_FIELDS = DOCUMENTS_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);


export const DOCUMENTS_HIDDEN_FIELDS = [
    /* "coaType",
    "issuer",
    "recipient", */
    "coaType",
    "archiveNumber",
    "fineArtsIssueType",
    "isTemporaryExport",
    "isTemporaryImport",
    "isCriticalCatalog",
    /* "editor",
    "curator", */
    "createdBy",
    "description",
    "contractSubscriber",
    "otherDocumentType",
    "attachments",
    "notes",
    "documentId",
    "assetId"
];

