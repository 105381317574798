// import { Image, Button } from "@chakra-ui/react";
// import placeholderPNG from "assets/placeholder.png";
// import placeholderPNG from "assets/placeholder.png";
// import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import truncate from "pages/assets/utils/TruncateString";
// import { DropdownFilter } from 'components/ui';
// import { Money } from "graphql/queries/generated/queries";
// import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";

// import ASSET_CONSTANTS from 'constants/asset';
// import findLabel from "./FindLabel";



// const document_types = {};
// ASSET_CONSTANTS.ASSET_DOCUMENT_TYPES.forEach(e => {
//     document_types[e.value] = e.label;
// });


export const ELEMENTS_CUSTOM_COLUMNS = [
    // {
    //     Header: "File",
    //     footer: "",
    //     accessor: "file",
    //     Cell: ({ cell: { value } }: { cell: { value: string } }) =>
    //     value ? <Button style={{height: "auto", maxWidth: "120px"}} variant="unstyled" title="Open in new window" onClick={() => window.open(value)} ><img src={value} alt="" /></Button> : <Image src={placeholderPNG} alt="" />,

    //     disableFilters: true,
    // },
    {
        Header: "Name",
        Footer: "",
        accessor: "name",
        // Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div> {document_types[value]}
        // </div> : <div>{value}</div>,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
        // Filter: DropdownFilter,
        disableFilters: true,
    },
    {
        Header: "Description",
        Footer: "",
        accessor: "description",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{truncate(value, 20)}</div>,

        disableFilters: true,
    },
    {
        Header: "Materials",
        Footer: "",
        accessor: "materials",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

        disableFilters: true,
    },
    {
        Header: "Notes",
        Footer: "",
        accessor: "notes",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{truncate(value, 20)}</div>,

        disableFilters: true,
    }


    // {
    //   Header: "Created by",
    //   Footer: "Created by",
    //   disableFilters: false,
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //   Filter: CreatedByFilter,
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //   filter: createdByFilterFunc,
    //   accessor: "createdBy.name",
    //   Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    // }
];


export const ELEMENTS_CUSTOM_FIELDS = ELEMENTS_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);


export const ELEMENTS_HIDDEN_FIELDS = [
    /* "coaType",
    "issuer",
    "recipient", */
    "elementId",
    "assetId",
    // "author",
    // "amount",
];

