import { Location } from "react-router-dom";
import { MGMT_ROUTES, REGISTRY_ROUTES, SPIN_ROUTES, USER_ROUTES } from "routes/constants";

interface ModifiedPermissionNode {
  id: string;
  codename: string;
  name: string;
  contentType: {
    model: string;
    id: string;
  };
}

const hasPermissionForRoute = (permissions: ModifiedPermissionNode[], permissionNeeded: string) =>
  permissions.find(({ codename }) => codename === permissionNeeded);

const getPermissionForRoute = (
  permissions: ModifiedPermissionNode[],
  pathname: Location["pathname"],
  isSuperuser: boolean
) => {
  const tenantCanUseMGMT = process.env.REACT_APP_MGMT_ENABLED === "true" ?? false;
  const tenantCanUseSPIN = process.env.REACT_APP_SPIN_ENABLED === "true" ?? false;

  if (pathname === "/") return true;

  if (pathname.includes(USER_ROUTES.USER)) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_user");
  }

  if (pathname.includes(REGISTRY_ROUTES.REGISTRY)) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_registry");
  }

  if (pathname.includes(MGMT_ROUTES.ASSETS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_asset");
  }

  if (pathname.includes(MGMT_ROUTES.COLLECTIONS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_collection");
  }

  if (pathname.includes(MGMT_ROUTES.EXHIBITIONS) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_exhibition");
  }

  if (pathname.includes(MGMT_ROUTES.APPRAISAL) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_appraisal");
  }

  if (pathname.includes(MGMT_ROUTES.RESTORATION) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_restoration");
  }

  if (pathname.includes(MGMT_ROUTES.CRSR) && tenantCanUseMGMT) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_conditionstatusreport");
  }

  if (pathname.includes(SPIN_ROUTES.SPIN) && tenantCanUseSPIN) {
    return isSuperuser ? true : hasPermissionForRoute(permissions, "view_policy");
  }

  return false;
};

const canNavigateRoute = (
  permissions: ModifiedPermissionNode[],
  route: Location,
  isSuperuser: boolean
) => {
  if (!permissions) return false;
  const viewPermissions = permissions.filter(({ codename }) => codename.includes("view"));
  const permissionFound = getPermissionForRoute(viewPermissions, route.pathname, isSuperuser);
  console.log(`[AUTH] Checking permission for ${route.pathname}`, permissionFound, viewPermissions);
  if (permissionFound) return true;
  return false;
};

export default canNavigateRoute;
