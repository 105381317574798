/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Box, Button, Stack, StackDivider } from "@chakra-ui/react";

// import { FieldGroup } from "components/ui";
// import { Form, useFormik } from "formik";
// import { CreateUser, CreateUserVariables } from "graphql/queries/types/CreateUser";
// import {
//   getAllPermission,
//   getAllPermission_allPermissions_edges,
//   getAllPermission_allPermissions_edges_node,
// } from "graphql/queries/types/getAllPermission";
// import {
//   UpdateUserPermissions,
//   UpdateUserPermissionsVariables,
// } from "graphql/queries/types/UpdateUserPermissions";
// import { CREATE_USER, GET_ALL_PERMISSIONS, UPDATE_PERMISSIONS } from "graphql/queries/users.gql";
// import cleanObject from "helpers/cleanObject";
// import countryCodes from "helpers/countryCodes.json";
// import currencies from "helpers/currencies.json";
// import { Dictionary } from "lodash";
// import groupBy from "lodash/groupBy";
// import { useRef, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import { useFormik } from "formik";

import ASSET_CONSTANTS from 'constants/asset';
// import { FormEvent } from "react";
import ChooseCategoryForm from "./ChooseCategoryForm";
// eslint-disable-next-line import/order
import NewCategoryForm from "./NewCategoryForm";

import findCategory from "../shared/FindCategory";


const AssetsNewPage = () => {
  // const formik = useFormik(
  //   {
  //     initialValues: {
  //       category: ''
  //     },
  //     onSubmit: values => alert(JSON.stringify(values, null, 2))
  //   }
  // )

  const formik = useFormik<{
    subcategory: string;
  }>({
    initialValues: {
      subcategory: "",
    },
    // onSubmit: (): void => {}
    onSubmit: (values) => {
      console.log(values);
    },
  });

  // eslint-disable-next-line no-return-assign
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  // eslint-disable-next-line no-return-assign
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const handleRadioButtons = (e: React.FormEvent<HTMLInputElement>) => {
    formik.values.subcategory = (e.target as HTMLInputElement).value;
  };

  const resetSubCategory = (/* e: React.MouseEvent<HTMLButtonElement> */) => {
    formik.values.subcategory = "";
    formik.resetForm();
    console.log(formik.values);
  };

  const onChange = (value: string) => {
    formik.values.subcategory = value;
    console.log("formik.values.subcategory");
    console.log(formik.values.subcategory);
  };


  const category = findCategory(ASSET_CONSTANTS.SUBCATEGORY, formik.values.subcategory);

  switch (category.toUpperCase()) {
    case "":
      return (
        <Box maxW="6xl">
          <Stack spacing="4" divider={<StackDivider />}>
            {/* <ChooseCategoryForm formik={formik} /> */}
            <ChooseCategoryForm
              resetSubCategory={resetSubCategory}
              // handleRadioButtons={handleRadioButtons}
              onSubmit={formik.handleSubmit}
              // value={formik.values.category}
              onChange={onChange}
            />
          </Stack>
        </Box>
      );
    case "SCULPTURE":
    case "PAINTING":
    case "NOT_VALIDATED":
    case "PRINT_OR_PHOTO":
    case "URBAN":
    case "INSTALLATION":
    case "DIGITAL":
    case "DESIGN_OR_ANTIQUE":
    case "ARCHEOLOGY":
    case "NATURALISTIC_GOODS":
    case "NUMISMATIC_COINS":
    case "NUMISMATIC_BANKNOTES":
    case "PHILATELY":
    case "BOOKS":
    case "OTHER":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return <NewCategoryForm resetSubCategory={resetSubCategory} category={category.toUpperCase()} subcategory={formik.values.subcategory.toUpperCase()} />;
      // eslint-disable-next-line no-lone-blocks
      {
        /* <Button type="reset" onClick={(e) => resetCategory(e)}>
              Annulla
            </Button>
          </NewCategoryForm> */
      }

    default:
      return (
        <div>
          {formik.values.subcategory}

          <Button type="reset" onClick={resetSubCategory}>
            Annulla
          </Button>
        </div>
      );
  }
};

export default AssetsNewPage;
