import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

// interface LegalAspectsPanelNewCategoryFormProps {
//     handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
//     handleChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
//     handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void
//     values: {
//         netWidth: string
//         netHeight: string
//         netDepth: string
//     }
//     category: string
//     children?: React.ReactNode
//     createAssetData: CreateAssetMutation | null | undefined
//     updateAssetData: UpdateAssetMutation | null | undefined
//     createAssetError: ApolloError | undefined
//     updateAssetError: ApolloError | undefined
//     createAssetLoading: boolean
//     updateAssetLoading: boolean
// }

interface LegalAspectsPanelNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}

const VOID_OPTION = [{ label: "-- Select --", value: "" }];

const LegalAspectsPanelNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: LegalAspectsPanelNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isNotifiedCulturalProperty = watch("isNotifiedCulturalProperty");
  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}
          <FieldGroup title={t("Property")} subtitle={t("Legal aspects - Property - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Is notified cultural property?"
                  type="checkbox"
                  name="isNotifiedCulturalProperty"
                />

                {isNotifiedCulturalProperty && (
                  <FormInputHook label="Property notes"  name="notifiedPropertyNote" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>

          <FieldGroup title={t("Legal status")} subtitle={t("Legal aspects - Legal status - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Legal status" name="legalStatus" type="textarea" />
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Copyright")} subtitle={t("Legal aspects - Copyright - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Copyright, restrictions and rights"
                  name="copyrightAndPropertyRestrictions"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup>


          {/* <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
          >
            {t("Save")}
          </Button>
          {children} */}
        {/* </Stack>
      </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default LegalAspectsPanelNewCategoryForm;
