/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from "@apollo/client";
import {
  Badge,
  Button,
  VStack,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Text,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { AssetNode, GetConditionStatusReportByIdQuery } from "graphql/queries/generated/queries";
import { Dot, FieldGroup, FormInputHook, FormFooter, MoneyInputHook, Field } from "components/ui";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";
import findLabel from "../../assets/shared/FindLabel";
import MediaForm from "./Media";
import DocumentsForm from "./Documents";

export const reportTypeOptions: FormSelectOptionProps[] = [
  { value: "condition_report", label: "Condition Report" },
  { value: "status_report", label: "Status Report" },
];
export const reportMotivationTypeOptions: FormSelectOptionProps[] = [
  { value: "exhibition", label: "Exhibition" },
  { value: "restauration", label: "Restauration" },
  { value: "loan", label: "Loan" },
  { value: "evaluation", label: "Evaluation" },
  { value: "auction", label: "Auction" },
  { value: "sale", label: "Sale" },
  { value: "transport", label: "Transport" },
  { value: "asset_evaluation", label: "Asset Evaluation" },
  { value: "damage", label: "Damage" },
  { value: "catalog", label: "Catalog" },
  { value: "other", label: "Other" },
];
export const reportExaminationTypeOptions: FormSelectOptionProps[] = [
  { value: "in_presence", label: "In presence" },
  { value: "remotely", label: "Remotely" },
];
export const reportLightUnitsTypeOptions: FormSelectOptionProps[] = [
  { value: "LUX", label: "Lux" },
  { value: "FOOT_CANDLES", label: "Foot candles" },
];
export const reportTemperatureUnitsTypeOptions: FormSelectOptionProps[] = [
  { value: "C", label: "C°" },
  { value: "F", label: "°F" },
];
export const reportExamConditionTypeOptions: FormSelectOptionProps[] = [
  { value: "outdoor", label: "Outdoor" },
  { value: "inside_warehouse_assembled", label: "Warehouse (assembled)" },
  { value: "inside_warehouse_not_assembled", label: "Warehouse (not assembled)" },
  { value: "monitor_picture", label: "Monitor/Picture" },
  { value: "exhibition_public_place", label: "Exhibition (public place)" },
  { value: "exhibition_private_place", label: "Exhibition (private place)" },
  { value: "gallery", label: "Gallery" },
  { value: "other", label: "Other" },
];
export const reportConditionTypeOptions: FormSelectOptionProps[] = [
  { value: "excellent", label: "Excellent" },
  { value: "very_good", label: "Very good" },
  { value: "good", label: "Good" },
  { value: "sufficient", label: "Sufficient" },
  { value: "poor", label: "Poor" },
  { value: "very_poor", label: "Very poor" },
  { value: "inherent_problems", label: "Inherent problems" },
  { value: "other", label: "Other" },
];

interface ConditionStatusReportProps {
  watch: any;
  asset?: AssetNode;
  data?: GetConditionStatusReportByIdQuery;
  errors: ApolloError[];
  footerData: any | any[];
  loading: boolean;
  disabled?: boolean;
}

const ConditionStatusReportFields = ({ watch, asset, data, footerData, loading, disabled, errors}: ConditionStatusReportProps) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const reportDetailsExamConditions = watch("reportDetailsExamConditions");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const reportType = watch("reportType");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const logisticsNumberOfPackages: string = watch("logisticsNumberOfPackages");

  const goToNextTab = (repType: string, repData?: GetConditionStatusReportByIdQuery) => {
    setTabIndex(
      (prevIndex) => {
        let result = prevIndex;
        let limit = 7;
        switch(repType) {
          case 'condition_report':
            if (repData != null) {
              limit = 9;
            }
            if (prevIndex < limit && prevIndex >=0 ) {
              result += 1 ;
            }
            break;
          default:
            if (repData != null) {
              if (prevIndex === 0) {
                result = 5;
              }
              if (prevIndex === 5 ) {
                result = 7;
              }
              if (prevIndex === 7 ) {
                result = 8;
              }
            } else if (prevIndex === 0) {
                result = 5;
            }
        }
        return result;
      }
      // (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex)
      );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const goToPreviousTab = (repType: string, repData?: GetConditionStatusReportByIdQuery) => {
    setTabIndex((prevIndex) => {
        let result = prevIndex;
        switch(repType) {
          case 'condition_report':
            if (prevIndex > 0) {
              result -= 1;
            }
            break;
          default:
            if (repData != null) {
              if (prevIndex === 5) {
                result = 0;
              }
              if (prevIndex === 7 ) {
                result = 5;
              }
              if (prevIndex === 8 ) {
                result = 7;
              }
            } else if (prevIndex === 5) {
                result = 0;
            }
        }

        // prevIndex > 0 ? prevIndex - 1 : prevIndex

        return result;
      }
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Tabs
        colorScheme="brand"
        variant="enclosed"
        mt="4"
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
      >
        <TabList>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              1
            </Badge>
            <Trans>Report</Trans>
          </Tab>

          <Tab isDisabled={reportType !== "condition_report"}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              2
            </Badge>
            <Trans>Exhibition details</Trans>
          </Tab>

          <Tab isDisabled={reportType !== "condition_report"}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              3
            </Badge>
            <Trans>Logistics</Trans>
          </Tab>

          <Tab isDisabled={reportType !== "condition_report"}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              4
            </Badge>
            <Trans>Settings</Trans>
          </Tab>

          <Tab isDisabled={reportType !== "condition_report"}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              5
            </Badge>
            <Trans>Environmental Conditions</Trans>
          </Tab>

          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              6
            </Badge>
            <Trans>Report details</Trans>
          </Tab>

          <Tab isDisabled={reportType !== "condition_report"}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              7
            </Badge>
            <Trans>Value</Trans>
          </Tab>

          <Tab isDisabled={data == null}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              8
            </Badge>
            <Trans>Image editor</Trans>
          </Tab>

          <Tab isDisabled={data == null}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>
              9
            </Badge>
            <Trans>Documents</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup
                title={t("Condition Report/Status Report Details")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook
                      name="reportType"
                      type="select"
                      label={t("Report type")}
                      options={reportTypeOptions}
                      required
                    />
                    <FormInputHook
                      name="reportMotivation"
                      type="select"
                      label={t("Report motivation")}
                      options={reportMotivationTypeOptions}
                      required
                    />
                    <FormInputHook
                      name="reportMotivationDescription"
                      label={t("Motivation Description")}
                    />



                    <FormInputHook name="reportCompany" label={t("Report Company")} type="registry" />
                    <FormInputHook name="reportExaminer" label={t("Examiner")} type="registry" />
                    <FormInputHook
                      name="reportExaminationType"
                      type="select"
                      label={t("Examination type")}
                      options={reportExaminationTypeOptions}
                      required
                    />
                    <FormInputHook name="reportDate" label={t("Date")} type="datetime-local" />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup
                title={t("Exhibition details")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="exhibitionLender" label={t("Lender")} type="registry" />
                    <FormInputHook
                      name="exhibitionHostEntity"
                      label={t("Host Entity")}
                      type="registry"
                    />
                    <FormInputHook
                      name="exhibitionPlanner"
                      label={t("Exhibition Planner")}
                      type="registry"
                    />
                    <FormInputHook name="exhibitionCurator" label={t("Curator")} type="registry" />
                    <FormInputHook name="exhibitionTitle" label={t("Exhibition Title CR")} />
                    <FormInputHook name="exhibitionName" label={t("Collection public name")} />
                    <FormInputHook
                      name="exhibitionPermanent"
                      label={t("Permanent")}
                      type="checkbox"
                    />
                    <FormInputHook type="textarea" name="exhibitionLocation" label={t("Object location")} />
                    <FormInputHook
                      name="exhibitionEventFrom"
                      label={t("Exhibition from")}
                      type="datetime-local"
                    />
                    <FormInputHook
                      name="exhibitionEventTo"
                      label={t("Exhibition to")}
                      type="datetime-local"
                    />
                    <FormInputHook
                      name="exhibitionLoanFrom"
                      label={t("Loan from")}
                      type="datetime-local"
                    />
                    <FormInputHook
                      name="exhibitionLoanTo"
                      label={t("Loan to")}
                      type="datetime-local"
                    />
                    <FormInputHook name="exhibitionNotes" label={t("Notes")} />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Logistics")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="logisticsCarrier" label={t("Carrier")} type="registry" />
                    <HStack>
                      <FormInputHook name="logisticsId" label={t("Transport ID")} />
                      <FormInputHook
                        name="logisticsNumberOfPackages"
                        label={t("Number of packages")}
                      />
                    </HStack>
                  </VStack>
                </VStack>
              </FieldGroup>

              <FieldGroup title={t("Pickup")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="logisticsSender" label={t("Sender")} type="registry" />
                    <FormInputHook
                      name="logisticsPickupDate"
                      label={t("Date")}
                      type="datetime-local"
                    />
                    <FormInputHook name="logisticsPickupAddress" label={t("Address")} />
                    <FormInputHook name="logisticsPickupZip" label={t("Zip")} />
                    <FormInputHook name="logisticsPickupCity" label={t("City")} />
                    <FormInputHook name="logisticsPickupState" label={t("State")} />
                    <FormInputHook name="logisticsPickupCountry" label={t("Country")} />
                  </VStack>
                </VStack>
              </FieldGroup>

              <FieldGroup title={t("Delivery")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="logisticsRecipient" label={t("Recipient")} type="registry" />
                    <FormInputHook
                      name="logisticsDeliveryDate"
                      label={t("Date")}
                      type="datetime-local"
                    />
                    <FormInputHook name="logisticsDeliveryAddress" label={t("Address")} />
                    <FormInputHook name="logisticsDeliveryZip" label={t("Zip")} />
                    <FormInputHook name="logisticsDeliveryCity" label={t("City")} />
                    <FormInputHook name="logisticsDeliveryState" label={t("State")} />
                    <FormInputHook name="logisticsDeliveryCountry" label={t("Country")} />
                  </VStack>
                </VStack>
              </FieldGroup>

              <FieldGroup
                title={t("Air Conditioning and Transport Conditions")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook
                      name="logisticsAirConditioningTransportConditions"
                      label={t("")}
                      type="textarea"
                    />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Settings")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <VStack alignItems="flex-start">
                      <Text variant="muted">
                        <Trans>Needs barriers</Trans>
                      </Text>
                      <Dot status={asset?.hasBarriers as boolean} />
                    </VStack>

                    <Field label="Handling requirements" value={asset?.handlingRequirements} />

                    <VStack alignItems="flex-start" pr={8}>
                      <Text variant="muted">
                        <Trans>Injury Risk</Trans>
                      </Text>
                      <Text>
                        {asset?.injuryRisk === "NONE" && (
                          <Badge mt={1} variant="solid" colorScheme="green">
                            <Trans>{asset?.injuryRisk}</Trans>
                          </Badge>
                        )}
                        {asset?.injuryRisk === "LOW" && (
                          <Badge mt={1} variant="solid" colorScheme="yellow">
                            <Trans>{asset?.injuryRisk}</Trans>
                          </Badge>
                        )}
                        {asset?.injuryRisk === "AVERAGE" && (
                          <Badge mt={1} variant="solid" colorScheme="orange">
                            <Trans>{asset?.injuryRisk}</Trans>
                          </Badge>
                        )}
                        {asset?.injuryRisk === "HIGH" && (
                          <Badge mt={1} variant="solid" colorScheme="red">
                            <Trans>{asset?.injuryRisk}</Trans>
                          </Badge>
                        )}
                        {asset?.injuryRisk === "" && (
                        <Text fontStyle="italic" fontWeight="400" color="gray.300">
                          <Trans>Value not found</Trans>
                        </Text>
                        )}
                      </Text>
                    </VStack>

                    <Field label="Injury Risk Description" value={asset?.injuryRiskDescription} />

                    <Field label="Conservation" value={asset?.conservation} />

                    <Field label="Lender requests" value={asset?.lenderRequests} />

                    <FormInputHook name="settingsNotes" label={t("Note")} />
                  </VStack>
                </VStack>
              </FieldGroup>

              <FieldGroup
                title={t("Examiner comments")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="settingsExaminerComments" label={t("")} type="textarea" />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup
                title={t("Environmental Conditions")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                  <GridItem colSpan={1} w="100%">
                    <VStack alignItems="flex-start" pr={8}>
                      <Text variant="muted">
                        <Trans>Registered Optimal light</Trans>
                      </Text>
                      <HStack>
                        <Text fontWeight="600">
                          {`${
                            asset?.optimalLightLevelIntensity ? asset?.optimalLightLevelIntensity : ""
                          } ${
                            asset?.lightUnit
                              ? findLabel(ASSET_CONSTANTS.LIGHT_UNIT, asset?.lightUnit.toString())
                              : ""
                          }`}
                        </Text>
                      </HStack>
                    </VStack>
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsOptimalLightLevelIntensity"
                      label={t("Optimal Light Level")}
                      type="number"
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsLightUnit"
                      label={t("Light Unit")}
                      type="select"
                      options={reportLightUnitsTypeOptions}
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsAbnormalLightEvent"
                      label={t("Abnormal Light Event")}
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <VStack alignItems="flex-start">
                      <Text variant="muted">
                        <Trans>Registered Optimal temperature</Trans>
                      </Text>
                      <HStack>
                        <Text fontWeight="600">
                          {`${asset?.optimalTemperature ? asset?.optimalTemperature : ""} ${
                            asset?.temperatureUnit
                              ? findLabel(
                                  ASSET_CONSTANTS.TEMPERATURE_UNIT,
                                  asset?.temperatureUnit.toString()
                                )
                              : ""
                          }`}
                        </Text>
                      </HStack>
                    </VStack>
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsOptimalTemperature"
                      label={t("Optimal Temperature")}
                      type="number"
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsTemperatureUnit"
                      label={t("Temperature Unit")}
                      type="select"
                      options={reportTemperatureUnitsTypeOptions}
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsAbnormalTemperatureEvent"
                      label={t("Abnormal Temperature Event")}
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <VStack alignItems="flex-start">
                      <Text variant="muted">
                        <Trans>Registered Optimal humidity</Trans>
                      </Text>
                      <HStack>
                        <Text fontWeight="600">
                          {asset?.optimalHumidity && `${asset?.optimalHumidity} %`}
                        </Text>
                      </HStack>
                    </VStack>
                  </GridItem>

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsOptimalHumidity"
                      label={t("Optimal Humidity (%)")}
                      type="number"
                    />
                  </GridItem>

                  <GridItem colSpan={1} w="100%" />

                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="environmentalConditionsAbnormalHumidityEvent"
                      label={t("Abnormal Humidity Event")}
                    />
                  </GridItem>
                </Grid>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup
                title={t("Report details")}
                subtitle={t("Lorem ipsum dolor sit amet")}
                py={8}
              >
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="reportDetailsExamConditions"
                      label={t("Exam conditions")}
                      type="select"
                      options={reportExamConditionTypeOptions}
                    />
                  </GridItem>
                  <GridItem colSpan={2} w="100%">
                    {reportDetailsExamConditions === "other" && (
                      <FormInputHook
                        name="reportDetailsExamConditionsNote"
                        label={t("Exam conditions notes")}
                        type="textarea"
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook name="reportDetailsExamLocation" label={t("Exam location")} />
                  </GridItem>
                  <GridItem colSpan={2} w="100%" />
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="reportDetailsExaminedPackages"
                      label={t("Examined Packages/Total Packages")}
                      type="numbersuffix"
                      suffix={`/${logisticsNumberOfPackages}`}
                    />
                  </GridItem>
                  <GridItem colSpan={2} w="100%" />
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="reportDetailsPackagingGeneralConditions"
                      label={t("Packaging general conditions")}
                      type="select"
                      options={reportConditionTypeOptions}
                    />
                  </GridItem>
                  <GridItem colSpan={2} w="100%">
                    <FormInputHook
                      name="reportDetailsPackagingStatusDescription"
                      label={t("Packaging status description")}
                      type="textarea"
                    />
                  </GridItem>
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="reportDetailsAssetCondition"
                      label={t("Object condition")}
                      type="select"
                      options={reportConditionTypeOptions}
                    />
                  </GridItem>
                  <GridItem colSpan={2} w="100%">
                    <FormInputHook
                      name="reportDetailsAssetDamageDescription"
                      label={t("Object damage description")}
                      type="textarea"
                    />
                  </GridItem>
                  <GridItem colSpan={1} w="100%">
                    <FormInputHook
                      name="reportDetailsProtectionsSupportsCondition"
                      label={t("Frame/case/protection/base general condition")}
                      type="select"
                      options={reportConditionTypeOptions}
                    />
                  </GridItem>
                  <GridItem colSpan={2} w="100%">
                    <FormInputHook
                      name="reportDetailsProtectionsSupportsDamageDescription"
                      label={t("Frame/case/protection/base damage description")}
                      type="textarea"
                    />
                  </GridItem>
                  <GridItem colSpan={1} w="100%" />
                  <GridItem colSpan={2} w="100%">
                    <FormInputHook
                      name="reportDetailsExhaminationTimeTechnicalDescription"
                      label={t("Exhamination time technical description")}
                      type="textarea"
                    />
                  </GridItem>
                </Grid>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Value")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <MoneyInputHook name="valueEstimatedValue.amount" label="Estimated value" />
                    <MoneyInputHook
                      name="valueEstimatedValueExaminationTime.amount"
                      label="Examination time estimated value"
                    />
                    <FormInputHook
                      name="valuePercentageDepreciation"
                      label={t("Percentage Depreciation (%)")}
                      type="number"
                      step="0.01"
                    />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            {data && data.conditionStatusReport && data.conditionStatusReport.id && (
              <MediaForm reportId={data.conditionStatusReport.id} />
            )}
          </TabPanel>

          <TabPanel p={3}>
            {data && data.conditionStatusReport && data.conditionStatusReport.id && (
              <DocumentsForm reportId={data.conditionStatusReport.id} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <FormFooter errors={errors} data={footerData} loading={loading} title="policy">
        <HStack width="full" justify="space-between" my="4">
          <HStack>
            {tabIndex > 0 && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              <Button onClick={() => goToPreviousTab(reportType, data)}>{t("Return to previous step")}</Button>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {reportType === 'condition_report' && data == null &&  tabIndex < 6 && <Button onClick={() => goToNextTab(reportType, data)}>{t("Proceed to next step")}</Button>}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {reportType === 'condition_report' && data != null &&  tabIndex < 8 && <Button onClick={() => goToNextTab(reportType, data)}>{t("Proceed to next step")}</Button>}

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {reportType !== 'condition_report' && data == null &&  tabIndex < 5 && <Button onClick={() => goToNextTab(reportType, data)}>{t("Proceed to next step")}</Button>}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {reportType !== 'condition_report' && data != null &&  tabIndex < 8 && <Button onClick={() => goToNextTab(reportType, data)}>{t("Proceed to next step")}</Button>}


            <Button
                isLoading={loading}
                loadingText="Loading"
                type="submit"
                variant="primary"
                disabled={disabled}
              >
                <Trans>Save</Trans>
              </Button>
          </HStack>
          <HStack />
        </HStack>
      </FormFooter>



      {/* <FormFooter data={footerData} loading={loading} disabled={disabled} errors={errors} title="Condition Report / Status Report entry" /> */}
    </>
  );
};

export default ConditionStatusReportFields;
