/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    // Badge,
    Box,
    Button,
    Heading,
    HStack,
    Text,
    VStack,

  } from "@chakra-ui/react";

import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactNode } from "react";




interface BorderedBoxProps {
    title: string;
    subtitle?: string;
    children: ReactNode;
    action?: {
      href: string;
      title: string;
    };
}

const BorderedBox = ({ title, subtitle, children, action }: BorderedBoxProps) => (
    <Box mt="0.5rem" borderWidth="1px" borderColor="gray.200" borderRadius="xl" flex="1" w="full" h="full">
        <VStack align="flex-start" borderBottomColor="gray.200" borderBottomWidth="1px" spacing="0">
        {/* <RiMapPinTimeLine size="2rem" /> */}
        <HStack justify="space-between" align="center" w="full" bgColor="cardBg.50" p="4">
            <VStack align="flex-start" spacing="0">
            <Heading size="md">
                <Trans>{title}</Trans>
            </Heading>
            <Text variant="muted">{subtitle}</Text>
            </VStack>
            {action && (
            <Button>
                <Link to={action.href}>
                <Trans>{action.title}</Trans>
                </Link>
            </Button>
            )}
        </HStack>
        </VStack>
        <VStack align="flex-start" spacing="4" p="4">
        {children}
        </VStack>
    </Box>
);


export default BorderedBox;