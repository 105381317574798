import { Stack } from "@chakra-ui/react";
import {
  AssetInput,
  Exact,
  GetAssetByIdForReviewQuery,
  PolicyAssetNode,
  useCreateAssetMutation,
  useCreatePolicyDocumentMutation,
  useCreatePolicyMutation,
  useCreatePolicyNoteMutation,
  useGetAssetByIdForReviewLazyQuery,
} from "graphql/queries/generated/queries";
import { NewPolicyForm, NewAssetDrawer, ImportAssetsDrawer } from "pages/spin/shared";
import { useForm, FormProvider, useFieldArray, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PolicyInputSchema } from "graphql/queries/overrides";
import { useEffect, useState } from "react";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { PolicyInputExtended } from "pages/spin/types";
import { ValidationProvider } from "components/form/ValidationContext";
import defaultValues from "pages/spin/shared/defaultValues";
import { BaseRow } from "react-csv-importer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGetOrCreateRegistry from "hooks/useGetOrCreateRegistry";
import useGetAssetCurrentValue from "hooks/useGetAssetCurrentValue";
import parseBooleanString from "helpers/parseBooleanString";
import useStagger from "helpers/useStagger";
import { LazyQueryExecFunction } from "@apollo/client";
import Overlay from "components/ui/Overlay";
import { handleMutation, toast } from "middleware/Toaster";
import { SPIN_ROUTES } from "routes/constants";
import { parseObjectToCreateEntity } from "helpers/processCsvRow";
import useGeolocation from "hooks/useGeolocation";

const policyAssetFields = {
  asset: "",
  catNat: false,
  coverTerrorism: false,
  coveredRiskType: "",
  coverType: "",
  evaluationType: "",
  exemption: false,
  title: "",
  author: "",
  creationPeriod: "",
  genericDimensions: "",
  inventoryNumber: "",
  category: "",
  technique: "",
  lenderId: "",
  lenderAddress: {
    raw: "",
  },
  pickupAddress: {
    raw: "",
  },
  deliveryAddress: {
    raw: "",
  },
  shipperId: "",
  insuredValue: {
    amount: null as number | null,
    currency: "EUR",
  },
};

const TEPoliciesNewPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createAsset, { data: createAssetData, error: createAssetError }] = useCreateAssetMutation({
    ...handleMutation("Asset created!"),
  });
  const [showNewAssetDrawer, setShowNewAssetDrawer] = useState<boolean>(false);
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const getOrCreateRegistry = useGetOrCreateRegistry();
  const getAssetCurrentValue = useGetAssetCurrentValue();
  const [geolocateAddress] = useGeolocation();

  const methods = useForm<PolicyInputExtended>({
    defaultValues: { ...defaultValues, policyType: "TEMPORARY_EXHIBITION" },
    resolver: yupResolver(PolicyInputSchema()),
  });

  const [
    createPolicy,
    { data: createPolicyData, error: createPolicyError, loading: createPolicyLoading },
  ] = useCreatePolicyMutation({
    ...handleMutation("Policy created!"),
  });

  const [BROKEN_getAssetByIdForReview] = useGetAssetByIdForReviewLazyQuery();
  const getAssetByIdForReview: LazyQueryExecFunction<
    GetAssetByIdForReviewQuery,
    Exact<{
      id: string;
    }>
  > = useStagger(BROKEN_getAssetByIdForReview);

  const [
    createPolicyDocument,
    { data: createPolicyDocumentData, error: createPolicyDocumentError },
  ] = useCreatePolicyDocumentMutation({
    ...handleMutation("Policy document created!"),
  });
  const [createPolicyNote, { data: createPolicyNoteData, error: createPolicyNoteError }] =
    useCreatePolicyNoteMutation({
      ...handleMutation("Policy note created!"),
    });

  useEffect(() => {
    if (createPolicyData) {
      toast({
        title: t("Policy has been created."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      const {
        createPolicy: { policy },
      } = createPolicyData;
      setTimeout(() => {
        navigate(`${SPIN_ROUTES.POLICY_TE}/${policy.id}`);
      }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 3000);
    }

    if (createPolicyError)
      toast({
        title: t("Policy couldn't be created."),
        description: t(`Error: ${createPolicyError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [createPolicyData, createPolicyError]);

  const {
    formState: { errors },
    setFocus,
    setValue,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof PolicyInputExtended);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: "policyAssets",
  });

  const {
    fields: noteFields,
    append: appendNote,
    remove: removeNote,
  } = useFieldArray({
    control: methods.control,
    name: "policyNote",
  });
  const {
    fields: documentFields,
    append: appendDocument,
    remove: removeDocument,
  } = useFieldArray({
    control: methods.control,
    name: "policyDocuments",
  });

  const watchPolicyAssets = methods.watch("policyAssets");
  const watchEveryPolicyAssets = watchPolicyAssets?.map((_, index) =>
    methods.watch(`policyAssets.${index}.insuredValue`)
  );

  useEffect(() => {
    console.log({ watchEveryPolicyAssets });
    if (!watchPolicyAssets || watchPolicyAssets.length === 0) return;
    // eslint-disable-next-line array-callback-return
    const totalValue = watchPolicyAssets.reduce((accumulator, { insuredValue }) => {
      if (!Number.isNaN(insuredValue.amount)) {
        return accumulator + Number(insuredValue.amount);
      }
      return accumulator;
    }, 0);

    setValue("totalInsuredValue.amount", totalValue, {
      shouldTouch: true,
      shouldDirty: true,
    });
  }, [watchPolicyAssets]);

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const goToPreviousTab = () => {
    setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const addPolicyAsset = async ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => {
    const {
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      eventEntityId,
      eventLocationEntityId,
      exemption,
    } = methods.getValues();

    const { amount, currency } = (await getAssetCurrentValue(gqlId)) || {};
    // const asset_for_review = (await )
    const {
      data: getAssetByIdForReviewData,
      loading: getAssetByIdForReviewLoading,
      error: getAssetByIdForReviewError,
    } = await getAssetByIdForReview({ variables: { id: gqlId } });
    // const assetForRewview = getAssetByIdForReviewData.asset;
    append({
      ...policyAssetFields,
      asset: gqlId,
      title,
      author,
      creationPeriod,
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      exemption,
      insuredValue: {
        amount: amount ?? null,
        currency: currency ?? "EUR",
      },
      ...getAssetByIdForReviewData.asset,
      objectLocationEntityId: eventEntityId,
      objectLocationOfficeId: eventLocationEntityId,
    });
  };

  const onSubmit: SubmitHandler<PolicyInputExtended> = async (formData, e) => {
    const { policyAssets, policyDocuments, policyNote, ...policyData } = formData;

    const cleanPolicyAssets = policyAssets.map((policyAsset) => {
      const {
        title,
        creationPeriod,
        category,
        creationDate,
        genericDimensions,
        inventoryNumber,
        technique,
        image,
        mainImage,
        id,
        author,
        authorEntity,
        actualDateAndPeriod,
        __typename,
        ...policyAssetData
      } = policyAsset;
      return policyAssetData;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const hasFile = policyDocuments.some(({ file }) => (file as unknown as FileList).length! > 0);

    const { data: createPolicyEvalutedData, errors: createPolicyEvaluatedErrors } =
      await createPolicy({
        variables: {
          input: {
            policyData: {
              ...policyData,
              policyAssets: cleanPolicyAssets,
              policyDocuments: hasFile ? policyDocuments : null,
              policyNote,
            },
          },
        },
      });

    if (createPolicyEvaluatedErrors) return;

    const {
      createPolicy: {
        policy: { id: policyId },
      },
    } = createPolicyEvalutedData;
  };

  const parseRow = async ({
    row,
    index,
  }: {
    row: AssetInput &
      PolicyAssetNode & { assetPresentValueAmount: string } & {
        assetPresentValueCurrency: string;
      } & { lenderCountry: string } & {
        author: string;
      };
    index: number;
  }) => {
    const { author, lender, shipper, ...copiedRow } = row;
    if (!row.category) {
      copiedRow.category = "not_validated";
    }

    if (!row.subcategory) {
      copiedRow.subcategory = "";
    }

    if (row.title.trim() === "") {
      console.log(row.title, row.title.trim(), row.title.trim() === "");
      toast({
        title: t(`Asset at row ${index} couldn't be uploaded`),
        description: t(`Error: Asset is missing title, which is a required field.`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      return null;
    }
    try {
      const authorEntityId = await getOrCreateRegistry(row.author as unknown as string, "author");

      const lenderId = lender ? await getOrCreateRegistry(row.lender as unknown as string) : null;
      const shipperId = shipper
        ? await getOrCreateRegistry(row.shipper as unknown as string)
        : null;

      const parsedAssetPresentValueAmount = row?.assetPresentValueAmount?.replace(/\D/g, "");

      return {
        ...copiedRow,
        category: "not_validated",
        subcategory: "",
        authorEntityId,
        lenderId,
        shipperId,
        netWidth: parseFloat(row.netWidth as unknown as string),
        netHeight: parseFloat(row.netHeight as unknown as string),
        netDepth: parseFloat(row.netDepth as unknown as string),
        assetPresentValueAmount: parsedAssetPresentValueAmount,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    await Promise.all(
      // eslint-disable-next-line consistent-return
      rows.map(
        async (
          row: AssetInput &
            PolicyAssetNode & { assetPresentValueAmount: string } & {
              assetPresentValueCurrency: string;
            } & { lenderCountry: string } & {
              author: string;
            },
          index: number
          // eslint-disable-next-line consistent-return
        ) => {
          const entities = await parseObjectToCreateEntity(row, getOrCreateRegistry);

          const parsedRow = await parseRow({ row, index });
          const {
            evaluationType: evaluationTypeRow,
            coverTerrorism: coverTerrorismRow,
            catNat: catNatRow,
            assetPresentValueAmount: assetPresentValueAmountRow,
            lenderCountry: lenderCountryRow,
            coveredRiskType: coveredRiskTypeRow,
            coverType: coverTypeRow,
            exemption: exemptionRow,
            lenderAddress: lenderAddressRow,
            assetPresentValueCurrency,
            authorEntityId,
            lenderId,
            shipperId,
            ...assetInput
          } = parsedRow;

          const lenderAddressLocated = await geolocateAddress(lenderAddressRow as string);
          console.log({ lenderAddressRow, lenderAddressLocated });

          const { data } = await createAsset({
            variables: {
              input: {
                assetData: {
                  ...assetInput,
                  authorEntityId,
                  validated: false,
                  creationDateNotes: assetInput.creationPeriod,
                  carryingValueAmount: {
                    amount: Number(assetPresentValueAmountRow),
                    currency: "EUR",
                  },
                },
              },
            },
          });

          if (!data.createAsset) throw new Error("Error importing asset");
          const {
            createAsset: { asset },
          } = data;
          console.log({ asset });
          const {
            id,
            title,
            author,
            creationPeriod,
            genericDimensions,
            inventoryNumber,
            category,
            technique,
          } = asset;

          const {
            evaluationType,
            catNat,
            coverTerrorism,
            coveredRiskType,
            coverType,
            eventEntityId,
            eventLocationEntityId,
            exemption,
          } = methods.getValues();
          console.log({
            id,
            title,
            author,
            creationPeriod,
            genericDimensions,
            inventoryNumber,
            category,
            technique,
          });
          try {
            append({
              ...policyAssetFields,
              asset: id,
              title,
              author,
              creationPeriod,
              genericDimensions,
              inventoryNumber,
              category,
              technique,
              evaluationType: evaluationTypeRow ?? evaluationType,

              coveredRiskType: coveredRiskTypeRow ?? coveredRiskType,
              coverType: coverTypeRow ?? coverType,
              catNat: parseBooleanString(catNatRow as unknown as string) ?? catNat,
              coverTerrorism:
                parseBooleanString(coverTerrorismRow as unknown as string) ?? coverTerrorism,
              exemption: parseBooleanString(exemptionRow as unknown as string) ?? exemption,
              lenderId: lenderId ?? "",
              shipperId: shipperId ?? "",
              lenderAddress: lenderAddressLocated,
              insuredValue: {
                amount: Number(assetPresentValueAmountRow),
                currency: "EUR",
              },
              objectLocationEntityId: eventEntityId,
              objectLocationOfficeId: eventLocationEntityId,
              status: "NEW",
            });
          } catch (error) {
            console.log(error);
            toast({
              title: t("Something went wrong while importing assets"),
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              description: error instanceof Error ? error.message : "",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            throw error;
          }
        }
      )
    );
  };

  return (
    <>
      <Overlay show={createPolicyLoading} />
      <Stack spacing="4">
        <FormProvider {...methods}>
          <ValidationProvider schema={PolicyInputSchema()}>
            <NewPolicyForm
              getValues={methods.getValues}
              policyAssets={watchPolicyAssets}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={methods.handleSubmit(onSubmit)}
              goToNextTab={goToNextTab}
              goToPreviousTab={goToPreviousTab}
              handleTabChange={handleTabChange}
              tabIndex={tabIndex}
              data={createPolicyData}
              loading={methods.formState.isSubmitting}
              error={[createPolicyError, createPolicyNoteError, createPolicyDocumentError]}
              extraFields={{ fields, append, remove, update }}
              setShowNewAssetDrawer={setShowNewAssetDrawer}
              setShowImportAssetsDrawer={setShowImportAssetsDrawer}
              addPolicyAsset={addPolicyAsset}
              policyType="TEMPORARY_EXHIBITION"
            />
          </ValidationProvider>
        </FormProvider>
        <NewAssetDrawer
          show={showNewAssetDrawer}
          onClose={() => setShowNewAssetDrawer(false)}
          addPolicyAsset={addPolicyAsset}
        />
        <ImportAssetsDrawer
          show={showImportAssetsDrawer}
          onClose={() => setShowImportAssetsDrawer(false)}
          processChunk={processCsvRows}
        />
      </Stack>
    </>
  );
};
export default TEPoliciesNewPage;
