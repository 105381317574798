import { useTranslation } from "react-i18next";
import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import {
  BooleanFilter,
  CountryCell,
  HiddenHoverableCell,
  NoDataPlaceholder,
  Table,
  TablePageSkeleton,
} from "components/ui";
import {
  RiAddLine,
  // RiFile2Fill,
  RiSignalTowerFill,
} from "react-icons/ri";
import getColumns, { BooleanCell } from "helpers/getColumns";
import {
  CertificateNode,
  PolicyNode,
  useDeleteCertificateMutation,
  useGetAllCertificatesByTypeQuery,
  useSignCertificateMutation,
} from "graphql/queries/generated/queries";
import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";
import parseAmount from "helpers/parseAmount";
import parseDate from "helpers/parseDate";
import { handleMutation } from "middleware/Toaster";
const CUSTOM_COLUMNS = [
  {
    Header: "#",
    Footer: "",
    accessor: "progressiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      String(value) === "0" ? "1" : value,
    weight: 101,
    disableFilters: true,
  },
  {
    Header: "#",
    Footer: "",
    accessor: "uuid",
    Cell: HiddenHoverableCell,
    weight: 100,
    disableFilters: true,
  },
  {
    Header: "Created on",
    Footer: "",
    accessor: "creationDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => parseDate(value),
  },
  {
    Header: "Offer #",
    Footer: "",
    accessor: "policy.offerCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Policy #",
    Footer: "",
    accessor: "policy.policyCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Institution/Museum",
    Footer: "",
    accessor: "policy.eventLocationEntity.name",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Country",
    Footer: "",
    accessor: "mainOfficeCountry",
    disableFilters: true,
    Cell: CountryCell,
  },
  {
    Header: "Insurance Company",
    Footer: "",
    accessor: "policy.insuranceCompany.fullName",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Broker",
    Footer: "",
    accessor: "policy.broker.fullName",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Contracting Party",
    Footer: "",
    accessor: "policy.contractingParty.fullName",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Sum Insured",
    Footer: "",
    accessor: "policy.totalInsuredValue.amount",
    disableFilters: true,
    Cell: ({
      cell: {
        row: {
          original: { policy },
        },
      },
    }: {
      cell: { row: { original: { policy: PolicyNode } } };
    }) => {
      const { totalInsuredValue } = policy;
      return parseAmount(totalInsuredValue);
    },
  },
  {
    Header: "Signed",
    Footer: "",
    accessor: "signed",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
  {
    Header: "Expired",
    Footer: "",
    accessor: "policy.is_expired",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
  {
    Header: "Status",
    Footer: "",
    accessor: "isValid",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
  // {
  //   Header: "File",
  //   Footer: "",
  //   accessor: "file",
  //   disableFilters: true,
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => (
  //     <a href={value} target="_blank" rel="noreferrer">
  //       <RiFile2Fill />
  //     </a>
  //   ),
  // },
  {
    Header: "Created by",
    Footer: "Created by",
    disableFilters: false,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
  },
];

const CUSTOM_FIELDS = CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

const QUICK_ACTIONS = [
  {
    name: "Add new policy",
    href: "new",
    icon: <RiAddLine />,
  },
];

const InventoriesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useGetAllCertificatesByTypeQuery({
    variables: {
      policyType: "PERMANENT_COLLECTION",
    },
  });
  const [
    signCertificate,
    { data: signCertificateData, error: signCertificateError, loading: signCertificateLoading },
  ] = useSignCertificateMutation({
    ...handleMutation("Certificate signed!"),
  });

  const [
    deleteCertificate,
    {
      data: deleteCertificateData,
      error: deleteCertificateError,
      loading: deleteCertificateLoading,
    },
  ] = useDeleteCertificateMutation({
    ...handleMutation("Certificate deleted!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;
  if (!data || !data?.allCertificates || data?.allCertificates.edges.length === 0)
    return (
      <Box>
        {/* <QuickActions actions={QUICK_ACTIONS} /> */}
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allCertificates: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });
  const onDelete = async (id: string) => {
    await deleteCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onSignCertificate = async ({
    row: { original },
  }: {
    row: { original: CertificateNode };
  }) => {
    if (!original.id) throw new Error("Missing id in row certificate.");
    const { id } = original;
    await signCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <Box>
      {/* <QuickActions actions={QUICK_ACTIONS} /> */}
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={columns}
          hiddenColumns={[
            "certificateType",
            "createdAt",
            "updatedAt",
            "language",
            "lender",
            "shipper",
            "file",
          ]}
          data={data?.allCertificates.edges.flatMap((e) => e?.node)}
          deleteAction={(id: string) => onDelete(id)}
          refetch={refetch}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          viewAction={(row: any) => window.open(row?.original?.file)}
          customActions={[
            {
              icon: <RiSignalTowerFill />,
              name: "Sign inventory",
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              onClick: (row: any) => onSignCertificate({ row }),
            },
          ]}
          isLoading={deleteCertificateLoading || loading}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default InventoriesPage;
