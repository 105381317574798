import { Heading, HStack, VStack, Skeleton, Text } from "@chakra-ui/react";
import ASSET_CONSTANTS from "constants/asset";
import {
  BorderedBox,
  CopyButton,
  DetailsPageSkeleton,
  Dot,
  Field,
  NoDataPlaceholder,
  QuickActions,
  RegistryAwareField,
  Table,
} from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import {
  useGetConditionStatusReportByIdQuery,
  useGetMediaConditionStatusReportByIdQuery,
  useExportConditionStatusReportPdfMutation,
  MediaNode,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import {
  DOCUMENT_CUSTOM_FIELDS,
  DOCUMENT_CUSTOM_COLUMNS,
  DOCUMENT_HIDDEN_FIELDS,
  MEDIA_CUSTOM_FIELDS,
  MEDIA_CUSTOM_COLUMNS,
  MEDIA_HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import parseAmount from "helpers/parseAmount";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import parseDate from "helpers/parseDate";
import {
  reportTypeOptions,
  reportMotivationTypeOptions,
  reportExaminationTypeOptions,
  reportLightUnitsTypeOptions,
  reportTemperatureUnitsTypeOptions,
  reportExamConditionTypeOptions,
  reportConditionTypeOptions,
} from "../shared/ConditionStatusReportFields";

const ConditionStatusReportViewPage = () => {
  const { t } = useTranslation();
  const { reportId } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetConditionStatusReportByIdQuery({
    variables: { id: reportId ?? "" },
  });

  const {
    data: dataMedia,
    loading: loadingMedia,
    error: errorMedia,
    refetch: refetchMedia,
  } = useGetMediaConditionStatusReportByIdQuery({
    variables: {
      id: reportId,
    },
  });

  const [
    exportConditionStatusReportPdf,
    {
      data: exportConditionStatusReportPdfData,
      loading: exportConditionStatusReportPdfLoading,
      error: exportConditionStatusReportPdfError,
    },
  ] = useExportConditionStatusReportPdfMutation({
    ...handleMutation("Report exported!"),
  });

  const onGeneratePdf = async () => {
    const { data } = await exportConditionStatusReportPdf({
      variables: {
        input: {
          conditionStatusReportId: reportId,
        },
      },
    });

    const {
      exportConditionStatusReportPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.conditionStatusReport) return null;

  const {
    conditionStatusReport: { ...formData },
  } = data;

  let dataToGetColumns;
  let columnsDocument;
  let columnsMedia;
  const documents: MediaNode[] = [];
  const media: MediaNode[] = [];

  if (
    dataMedia &&
    dataMedia.conditionStatusReport &&
    dataMedia.conditionStatusReport.media &&
    dataMedia.conditionStatusReport.media.edges &&
    dataMedia.conditionStatusReport.media.edges.length > 0
  ) {
    dataToGetColumns = dataMedia.conditionStatusReport.media.edges[0].node;

    columnsDocument = getColumns({
      data: dataToGetColumns,
      customFields: DOCUMENT_CUSTOM_FIELDS,
      customColumns: DOCUMENT_CUSTOM_COLUMNS,
    });

    columnsMedia = getColumns({
      data: dataToGetColumns,
      customFields: MEDIA_CUSTOM_FIELDS,
      customColumns: MEDIA_CUSTOM_COLUMNS,
    });

    for (let i = 0; i < dataMedia.conditionStatusReport.media.edges.length; i += 1)
      if (
        dataMedia.conditionStatusReport.media.edges[i].node.mediaType ===
        ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value
      )
        documents.push(dataMedia.conditionStatusReport.media.edges[i].node as MediaNode);
      else media.push(dataMedia.conditionStatusReport.media.edges[i].node as MediaNode);
  }

  let reportType = "";
  for (const option of reportTypeOptions)
    if (option.value === formData.reportType) reportType = option.label;

  let reportMotivation = "";
  for (const option of reportMotivationTypeOptions)
    if (option.value === formData.reportMotivation) reportMotivation = option.label;

  let reportExaminationType = "";
  for (const option of reportExaminationTypeOptions)
    if (option.value === formData.reportExaminationType) reportExaminationType = option.label;

  let reportLightUnits = "";
  for (const option of reportLightUnitsTypeOptions)
    if (option.value === formData.environmentalConditionsLightUnit) reportLightUnits = option.label;

  let reportTemperatureUnits = "";
  for (const option of reportTemperatureUnitsTypeOptions)
    if (option.value === formData.environmentalConditionsTemperatureUnit)
      reportTemperatureUnits = option.label;

  let reportExamConditions = "";
  for (const option of reportExamConditionTypeOptions)
    if (option.value === formData.reportDetailsExamConditions) reportExamConditions = option.label;

  let reportDetailsPackagingGeneralConditions = "";
  for (const option of reportConditionTypeOptions)
    if (option.value === formData.reportDetailsPackagingGeneralConditions)
      reportDetailsPackagingGeneralConditions = option.label;

  let reportDetailsProtectionsSupportsConditions = "";
  for (const option of reportConditionTypeOptions)
    if (option.value === formData.reportDetailsProtectionsSupportsCondition)
      reportDetailsProtectionsSupportsConditions = option.label;

  let reportDetailsAssetConditions = "";
  for (const option of reportConditionTypeOptions)
    if (option.value === formData.reportDetailsAssetCondition)
      reportDetailsAssetConditions = option.label;

  const valueEstimatedValue = parseAmount(formData.valueEstimatedValue);
  const valueEstimatedValueExaminationTime = parseAmount(
    formData.valueEstimatedValueExaminationTime
  );

  const QUICK_ACTIONS = [
    {
      name: t("Edit Report"),
      href: `/mgmt/cr-sr/edit/${formData.id}`,
      icon: <BiEdit />,
    },
    {
      name: t("Export pdf"),
      action: onGeneratePdf,
      icon: <BiExport />,
      loading: exportConditionStatusReportPdfLoading,
    },
  ];

  const onViewDocument = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (row && row.original && row.original.id && row.original.file) window.open(row.original.file);
  };

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          {formData.reportAsset !== null &&
          formData.reportAsset.title !== null &&
          formData.reportAsset.title !== "" ? (
            <Heading>
              {formData.reportAsset.title} - {reportType}
            </Heading>
          ) : (
            <Heading>{reportType}</Heading>
          )}

          {formData.reportAsset !== null && formData.reportAsset.authorEntity !== null && (
            <RegistryAwareField id={formData.reportAsset.authorEntity.id} />
          )}

          {formData.reportAsset.actualDateAndPeriod !== null &&
            formData.reportAsset.actualDateAndPeriod !== "" && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Field label="Date of creation" value={formData.reportAsset?.actualDateAndPeriod} />
            )}

          <Text fontSize="lg" variant="muted">
            #{reportId ?? ""} <CopyButton value={reportId ?? ""} />
          </Text>
          <Dot
            positiveLabel={t("Draft")}
            negativeLabel={t("Closed")}
            status={formData.reportStatus === "draft"}
          />
          <Text>{formData.reportDate ? parseDate(formData.reportDate) : "..."}</Text>
        </VStack>

        {formData.reportStatus === "draft" && <QuickActions noTitle actions={QUICK_ACTIONS} />}
      </HStack>

      <VStack flex="3" direction="column" spacing="6">
        <BorderedBox
          title="Condition Report/Status Report Details"
          subtitle="Lorem ipsum dolor sit amet"
        >
          <HStack w="100%" my={4}>
            <Field label="Report motivation" value={reportMotivation} />
            <Field label="Motivation Description" value={formData.reportMotivationDescription} />
            <Field
              label="Report Company"
              value={formData.reportCompany ? formData.reportCompany.fullName : "..."}
            />
            <Field
              label="Examiner"
              value={formData.reportExaminer ? formData.reportExaminer.fullName : "..."}
            />
            <Field label="Examination type" value={reportExaminationType} />
          </HStack>
        </BorderedBox>

        <BorderedBox title="Exhibition details" subtitle="Lorem ipsum dolor sit amet">
          <VStack alignItems="flex-start" pr={8}>
            <HStack w="100%" my={4}>
              <Field label="Lender" value={formData?.exhibitionLender?.fullName ?? "..."} />
              <Field
                label="Host Entity"
                value={
                  formData.exhibitionHostEntity ? formData.exhibitionHostEntity.fullName : "..."
                }
              />
              <Field
                label="Exhibition Planner"
                value={formData.exhibitionPlanner ? formData.exhibitionPlanner.fullName : "..."}
              />
              <Field
                label="Curator"
                value={formData.exhibitionCurator ? formData.exhibitionCurator.fullName : "..."}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field label="Title" value={formData.exhibitionTitle} />
              <Field label="Collection public name" value={formData.exhibitionName} />
              <Field label="Permanent" value={formData.exhibitionPermanent ? "Yes" : "No"} />
              <Field label="Object location" value={formData.exhibitionLocation} />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Exhibition from"
                value={
                  formData.exhibitionEventFrom ? parseDate(formData.exhibitionEventFrom) : "..."
                }
              />
              <Field
                label="Exhibition to"
                value={formData.exhibitionEventTo ? parseDate(formData.exhibitionEventTo) : "..."}
              />
              <Field
                label="Loan from"
                value={formData.exhibitionLoanFrom ? parseDate(formData.exhibitionLoanFrom) : "..."}
              />
              <Field
                label="Loan to"
                value={formData.exhibitionLoanTo ? parseDate(formData.exhibitionLoanTo) : "..."}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field label="Notes" value={formData.exhibitionNotes} />
            </HStack>
          </VStack>
        </BorderedBox>

        <BorderedBox title="Logistics">
          <VStack alignItems="flex-start" pr={8}>
            <HStack w="100%" my={4}>
              <Field
                label="Carrier"
                value={formData.logisticsCarrier ? formData.logisticsCarrier.fullName : "..."}
              />
              <Field label="Transport ID" value={formData.logisticsId} />
              <Field label="Number of packages" value={formData.logisticsNumberOfPackages} />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Sender"
                value={formData.logisticsSender ? formData.logisticsSender.fullName : "..."}
              />

              <VStack alignItems="flex-start" pr={8}>
                <Text variant="muted">
                  <Trans>Pickup date</Trans>
                </Text>
                <Text>
                  {formData.logisticsPickupDate ? parseDate(formData.logisticsPickupDate) : "..."}
                </Text>
              </VStack>

              <Field label="Pickup Address" value={formData.logisticsPickupAddress} />
              <Field label="Pickup Zip" value={formData.logisticsPickupZip} />
              <Field label="Pickup City" value={formData.logisticsPickupCity} />
              <Field label="Pickup Province/Region/State" value={formData.logisticsPickupState} />
              <Field label="Pickup Country" value={formData.logisticsPickupCountry} />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Recipient"
                value={formData.logisticsRecipient ? formData.logisticsRecipient.fullName : "..."}
              />

              <VStack alignItems="flex-start" pr={8}>
                <Text variant="muted">
                  <Trans>Delivery date</Trans>
                </Text>
                <Text>
                  {formData.logisticsDeliveryDate
                    ? parseDate(formData.logisticsDeliveryDate)
                    : "..."}
                </Text>
              </VStack>

              <Field label="Delivery Address" value={formData.logisticsDeliveryAddress} />
              <Field label="Delivery Zip" value={formData.logisticsDeliveryZip} />
              <Field label="Delivery City" value={formData.logisticsDeliveryCity} />
              <Field
                label="Delivery Province/Region/State"
                value={formData.logisticsDeliveryState}
              />
              <Field label="Delivery Country" value={formData.logisticsDeliveryCountry} />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Air Conditioning and Transport Conditions"
                value={formData.logisticsAirConditioningTransportConditions}
              />
            </HStack>
          </VStack>
        </BorderedBox>

        <BorderedBox title="Settings" subtitle="Lorem ipsum dolor sit amet">
          <HStack w="100%" my={4}>
            <Field label="Needs barriers" value={formData.reportAsset.hasBarriers ? "Yes" : "no"} />
            <Field
              label="Handling requirements"
              value={formData.reportAsset.handlingRequirements}
            />
            <Field label="Injury Risk" value={formData.reportAsset.injuryRisk} />
            <Field label="Conservation" value={formData.reportAsset.conservation} />
            <Field label="Lender requests" value={formData.reportAsset.lenderRequests} />
            <Field label="Notes" value={formData.settingsNotes} />
            <Field label="Examiner comments" value={formData.settingsExaminerComments} />
          </HStack>
        </BorderedBox>

        <BorderedBox title="Environmental Conditions" subtitle="Lorem ipsum dolor sit amet">
          <HStack w="100%" my={4}>
            <Field
              label="Optimal Light Level Intensity"
              value={formData.environmentalConditionsOptimalLightLevelIntensity}
            />
            <Field label="Light Unit" value={reportLightUnits} />
            <Field
              label="Abnormal Light Event"
              value={formData.environmentalConditionsAbnormalLightEvent}
            />
            <Field
              label="Optimal Temperature"
              value={formData.environmentalConditionsOptimalTemperature}
            />
            <Field label="Temperature Unit" value={reportTemperatureUnits} />
            <Field
              label="Abnormal Temperature Event"
              value={formData.environmentalConditionsAbnormalTemperatureEvent}
            />
            <Field
              label="Optimal Humidity (%)"
              value={formData.environmentalConditionsOptimalHumidity}
            />
            <Field
              label="Abnormal Humidity Event"
              value={formData.environmentalConditionsAbnormalHumidityEvent}
            />{" "}
          </HStack>
        </BorderedBox>

        <BorderedBox title="Report details" subtitle="Lorem ipsum dolor sit amet">
          <VStack alignItems="flex-start" pr={8}>
            <HStack w="100%" my={4}>
              <Field label="Exam conditions" value={reportExamConditions} />
              <Field
                label="Exam conditions notes"
                value={formData.reportDetailsExamConditionsNote}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field label="Exam location" value={formData.reportDetailsExamLocation} />
              <Field
                label="Examined Packages/Total Packages"
                value={`${formData.reportDetailsExaminedPackages}/${formData.logisticsNumberOfPackages}`}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Packaging general conditions"
                value={reportDetailsPackagingGeneralConditions}
              />
              <Field
                label="Packaging status description"
                value={formData.reportDetailsPackagingStatusDescription}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field label="Object condition" value={reportDetailsAssetConditions} />
              <Field
                label="Object damage description"
                value={formData.reportDetailsAssetDamageDescription}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Frame/case/protection/base general condition"
                value={reportDetailsProtectionsSupportsConditions}
              />
              <Field
                label="Frame/case/protection/base damage description"
                value={formData.reportDetailsProtectionsSupportsDamageDescription}
              />
            </HStack>

            <HStack w="100%" my={4}>
              <Field
                label="Exhamination time technical description"
                value={formData.reportDetailsExhaminationTimeTechnicalDescription}
              />
            </HStack>
          </VStack>
        </BorderedBox>

        <BorderedBox title="Value" subtitle="Lorem ipsum dolor sit amet">
          <VStack alignItems="flex-start" pr={8}>
            <HStack w="100%" my={4}>
              <Field label="Estimated value" value={valueEstimatedValue} />
              <Field
                label="Examination time estimated value"
                value={valueEstimatedValueExaminationTime}
              />
              <Field
                label="Percentage Depreciation (%)"
                value={formData.valuePercentageDepreciation}
              />
            </HStack>
          </VStack>
        </BorderedBox>

        <BorderedBox title="Image editor" subtitle="Lorem ipsum dolor sit amet">
          <Table
            hiddenColumns={MEDIA_HIDDEN_FIELDS}
            columns={columnsMedia}
            data={media}
            viewAction={onViewDocument}
          />
        </BorderedBox>

        <BorderedBox title="Documents" subtitle="Lorem ipsum dolor sit amet">
          <Table
            hiddenColumns={DOCUMENT_HIDDEN_FIELDS}
            columns={columnsDocument}
            data={documents}
            viewAction={onViewDocument}
          />
        </BorderedBox>
      </VStack>
    </Skeleton>
  );
};

export default ConditionStatusReportViewPage;
