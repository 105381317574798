import { Box, Skeleton, Button, Image } from "@chakra-ui/react";
import {
  DropdownFilter,
  NoDataPlaceholder,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
} from "components/ui";
import {
  useGetAllCollectionsQuery,
  CollectionNode,
  useAddUserObjectPermissionFromCollectionMutation,
  Exact,
  AssetNode,
  GetAllAssetsForUserQuery,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { Trans, useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import placeholderPNG from "assets/placeholder.png";
import { useNavigate } from "react-router-dom";
import { handleMutation, toast } from "middleware/Toaster";
import { MGMT_ROUTES } from "routes/constants";
import { ApolloQueryResult } from "@apollo/client";

const QUICK_ACTIONS = [
  {
    name: "Add new collection entry",
    href: "new",
    icon: <RiAddLine />,
  },
];
const CUSTOM_FIELDS = [
  "createdBy",
  "totalAssetsNumber",
  "validatedAssetsNumber",
  "createdFrom",
  "actualStatusObjects",
  "createdAt",
  "image",
  "name",
];
const HIDDEN_FIELDS = ["description", "notes"];
const CUSTOM_COLUMNS = [
  {
    Header: "Image",
    footer: "",
    accessor: "image",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button
          style={{ height: "auto", maxWidth: "120px" }}
          variant="unstyled"
          title="Open in new window"
          onClick={() => window.open(value)}
        >
          <img src={value} alt="" />
        </Button>
      ) : (
        <Image src={placeholderPNG} alt="" />
      ),

    disableFilters: true,
  },
  {
    Header: "Name",
    Footer: "",
    accessor: "name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    disableFilters: true,
  },
  {
    Header: "Creation date",
    Footer: "",
    accessor: "createdAt",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "Total Objects N.",
    Footer: "Total Objects N.",
    accessor: "totalAssetsNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "Validated Objects N.",
    Footer: "Validated Objects N.",
    accessor: "validatedAssetsNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 0,
    disableFilters: true,
  },
  {
    Header: "Created In",
    Footer: "Created In",
    accessor: "createdFrom",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: DropdownFilter,
    weight: 0,
    disableFilters: false,
  },
  // TODO: REMOVE VISIBLE COLUMN LEAVE ONLY FILTER
  {
    Header: "",
    accessor: "actualStatusObjects",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    weight: 0,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

interface CollectionsAddableToUserProps {
  userId: string;
  refetchVisibleAssets: (
    variables?: Partial<
      Exact<{
        userId: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAllAssetsForUserQuery>>;
}

const CollectionsAddableToUser = ({
  userId,
  refetchVisibleAssets,
}: CollectionsAddableToUserProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllCollectionsQuery();
  const [
    addCollectionPermission,
    {
      data: addCollectionPermissionData,
      loading: addCollectionPermissionLoading,
      error: addCollectionPermissionError,
    },
  ] = useAddUserObjectPermissionFromCollectionMutation({
    ...handleMutation("Objects from collection added!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;

  if (!data || !data.allCollections || data.allCollections.edges.length === 0)
    return (
      <Box>
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allCollections: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onEdit = ({ row: { original } }: { row: { original: CollectionNode } }) => {
    const { id, createdFrom } = original;
    if (createdFrom === "spin") {
      toast({
        title: t("Collection can't be edited."),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    navigate(`${MGMT_ROUTES.COLLECTIONS}/edit/${id}`);
  };

  const onAddCollectionPermission = async (collectionIds: string[]) => {
    await addCollectionPermission({
      variables: {
        input: {
          userId,
          collectionIds,
        },
      },
    });
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Add asset(s) to user</Trans>,
      action: async (selectedRows: { original: AssetNode }[]) => {
        const assetIds = selectedRows.map(({ original: { id } }) => id);
        await onAddCollectionPermission(assetIds);
        await refetch();
        await refetchVisibleAssets();
      },
      loading: addCollectionPermissionLoading,
    },
  ];

  return (
    <Box>
      <Skeleton isLoaded={!loading}>
        <Table
          hiddenColumns={HIDDEN_FIELDS}
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allCollections.edges.flatMap((edge) => edge?.node)}
          pathname="/mgmt/collections/"
          editAction={onEdit}
        />
      </Skeleton>
    </Box>
  );
};

export default CollectionsAddableToUser;
