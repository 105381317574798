import { HStack, Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { JSXElementConstructor, ReactElement } from "react";
import QuickActionsContainer from "./QuickActionsContainer";

export interface Action {
  name: string;
  icon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  href?: never;
  action?: (arg0?: any) => void | Promise<void>;
  intent?: string;
  loading?: boolean;
  disabled?: boolean;
}

export interface QuickLink {
  name: string;
  icon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  href?: string;
  action?: (arg0?: any) => void | Promise<void>;
  intent?: string;
  loading?: boolean;
  disabled?: boolean;
}

export interface QuickActionsProps {
  actions: (Partial<Action> | Partial<QuickLink>)[];
  noTitle?: boolean;
}

const QuickActions = ({ noTitle = false, actions }: QuickActionsProps) => (
  <QuickActionsContainer>
    <>
      {!noTitle && (
        <Text fontSize="lg" color="brand.500" fontWeight="bold" mb="3">
          Quick actions
        </Text>
      )}

      <HStack>
        {actions.map((action) => {
          if (!action) return null;
          if (action?.href) {
            return (
              <Link to={action?.href}>
                <Button
                  disabled={action?.disabled}
                  leftIcon={action?.icon}
                  size="sm"
                  variant={action?.intent ?? "outline"}
                  isLoading={action?.loading}
                >
                  <Trans>{action?.name}</Trans>
                </Button>
              </Link>
            );
          }

          return (
            <Button
              disabled={action?.disabled}
              variant={action?.intent ?? "outline"}
              leftIcon={action?.icon}
              size="sm"
              isLoading={action?.loading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={action?.action}
            >
              <Trans>{action?.name}</Trans>
            </Button>
          );
        })}
      </HStack>
    </>
  </QuickActionsContainer>
);

export default QuickActions;
