import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";
import ASSET_CONSTANTS from 'constants/asset';

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import AssetElementForm from './asset_elements/AssetElementForm';
interface PhysicalFeaturesNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  assetId: string;
  category: string;
  children?: React.ReactNode;
}

const VOID_OPTION = [{ name: "-- Select --", value: "" }];



const PhysicalFeaturesNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  assetId,
  category,
  children,
}: PhysicalFeaturesNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();



  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasBase = watch("hasBase");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasWoodFrame = watch("hasWoodFrame");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasFrame = watch("hasFrame");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasContainer = watch("hasContainer");


// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isComposed = watch("isComposed");

  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}

          <FieldGroup title={t("State of conservation")} subtitle={t("Physical features - State of conservation - Lorem ipsum dolor sit amet")} py={8}>
              <FormInputHook name="stateOfConservation" label="State of conservation" />
          </FieldGroup>

          {
            category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <FieldGroup title={t("Asset elements")} subtitle={t("Physical features - Asset elements - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label="Asset is composed of several elements"
                    type="checkbox"
                    name="isComposed"
                  />

                  {isComposed && (
                    <FormInputHook label="Number of elements" name="numberOfElements" type="number" />
                  )}
                </HStack>

                {isComposed && <AssetElementForm assetId={assetId} />}
              </VStack>
            </FieldGroup>
          }

          {
            category === 'OTHER' &&
            <FieldGroup title={t("Details")} subtitle={t("Physical features - OTHER - Details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <FormInputHook label="Replaced parts" name="replacedParts" />
                <FormInputHook label="Usage" name="usage" />
              </VStack>
            </FieldGroup>
          }

          {
            category === 'BOOKS' &&
            <FieldGroup title={t("Details")} subtitle={t("Physical features - BOOKS - Details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <FormInputHook label="Book container" name="bookContainer" />
                <FormInputHook label="Dust jacket" name="dustJacket" />
                <FormInputHook label="Front cover" name="frontCover" />
                <FormInputHook label="Rear cover" name="rearCover" />
                <FormInputHook label="Top edge" name="topEdge" />
                <FormInputHook label="Fore edge" name="foreEdge" />
                <FormInputHook label="Foot edge" name="footEdge" />
                <FormInputHook label="Bookbinding" name="bookbinding" />
                <FormInputHook label="Interior decorations" name="interiorDecorations" />
                <FormInputHook label="External decorations" name="externalDecorations" />
                <FormInputHook label="Flyleaf" name="flyleaf" />
                <FormInputHook label="Glueing" name="glueing" />
                <FormInputHook label="Headband" name="headband" />
                <FormInputHook label="Headcap" name="headcap" />
                <FormInputHook label="Corner" name="corner" />
                <FormInputHook label="Square" name="square" />
                <FormInputHook label="Slots" name="slots" />
                <FormInputHook label="Spine" name="spine" />
                <FormInputHook label="Clips" name="clips" />
                <FormInputHook label="Raised bands" name="raisedBands" />
                <FormInputHook label="Label" name="label" />
                <FormInputHook label="Cloth covering" name="clothCovering" />
              </VStack>
            </FieldGroup>
          }

          {
            category === 'NUMISMATIC_COINS' &&
            <FieldGroup title={t("Details")} subtitle={t("Physical features - NUMISMATIC_COINS - Details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <FormInputHook label="Distribution" name="distribution" />
                <FormInputHook label="Edge" name="edge" />
                <FormInputHook label="Orientation" name="orientation" />
                <FormInputHook label="Shape" name="shape" />
                <FormInputHook label="Rim" name="rim" />
                <FormInputHook label="Rotation axes" name="rotationAxes" />
                <FormInputHook label="Legend" name="legend" />
                <FormInputHook label="Title" name="coinTitle" />
                <FormInputHook label="Minting errors" name="mintMadeErrors" />
              </VStack>
            </FieldGroup>
          }

          {
            category === 'NUMISMATIC_BANKNOTES' &&
            <FieldGroup title={t("Details")} subtitle={t("Physical features - NUMISMATIC_BANKNOTES - Details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <FormInputHook label="Distribution" name="distribution" />
                <FormInputHook label="Signings" name="signings" />
                <FormInputHook label="Relief" name="relief" />
                <FormInputHook label="Watermark" name="watermark" />
                <FormInputHook label="Holographic stripe" name="holographicStripe" />
                <FormInputHook label="Security thread" name="securityThread" />
                <FormInputHook label="Printing errors" name="printingErrors" />
              </VStack>
            </FieldGroup>
          }


          {
            category === 'PHILATELY' &&
            <FieldGroup title={t("Details")} subtitle={t("Physical features - PHILATELY - Details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <FormInputHook label="Distribution" name="distribution" />
                <FormInputHook label="Edge" name="edge" />
                <FormInputHook label="Shape" name="shape" />
                <FormInputHook label="Color" name="color" />
                <FormInputHook label="Watermark" name="watermark" />
                <FormInputHook label="Postage mark" name="postageMark" />
                <FormInputHook label="Rubber" name="rubber" />
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Stamp printing technique"
                    options={ASSET_CONSTANTS.STAMP_PRINTING_TECHNIQUE}
                    name="stampPrintingTechnique"
                  />
                  <FormInputHook
                    label="Realization"
                    options={ASSET_CONSTANTS.STAMP_REALIZATION}

                    name="stampRealization"
                  />
                </HStack>
                <HStack alignItems="flex-end">
                    <FormInputHook
                      label="Stamp indentation type"
                      options={ASSET_CONSTANTS.STAMP_INDENTATION_TYPES}
                      name="stampIndentationType"
                    />
                    <FormInputHook
                      label="Stamp centering and margins"
                      options={ASSET_CONSTANTS.STAMP_CENTERING}
                      name="stampCenteringAndMargins"
                    />
                    <FormInputHook
                      label="Format"
                      options={ASSET_CONSTANTS.STAMP_FORMATS}
                      name="stampFormat"
                    />
                </HStack>

                <FormInputHook label="Printing errors" name="printingErrors" />
              </VStack>
            </FieldGroup>
          }


          { category === 'INSTALLATION' &&
            <FieldGroup title={t("Composition description")} subtitle={t("Physical features - Composition description - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label=""
                    name="compositionDescription"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>
          }



          { (category === 'SCULPTURE' ||
              category === 'URBAN' ||
              category === 'DESIGN_OR_ANTIQUE') && <FieldGroup title={t("Surface characteristics")} subtitle={t("Physical features - Surface characteristics - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label=""
                  name="surfaceCharacteristics"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup> }



          {
            (category === 'SCULPTURE' ||
            category === 'URBAN' ||
            category === 'DESIGN_OR_ANTIQUE') && <FieldGroup title={t("Base")} subtitle={t("Physical features - Base - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label="Has base"
                    type="checkbox"
                    name="hasBase"
                  />

                  {hasBase && (
                    <FormInputHook label="Base details" name="baseDetails" />
                  )}
                </HStack>
              </VStack>
            </FieldGroup>
          }


          { // telaio
            (category === 'PAINTING' || category === 'PRINT_OR_PHOTO') && <FieldGroup title={t("Wood frame")} subtitle={t("Physical features - Wood frame - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label="Has wood frame"
                    type="checkbox"
                    name="hasWoodFrame"
                  />

                  {hasWoodFrame && (
                    <FormInputHook label="Wood frame details" name="woodFrameDetails" />
                  )}
                </HStack>
              </VStack>
            </FieldGroup>
          }


          { // cornice
            (category === 'PAINTING' || category === 'PRINT_OR_PHOTO')&& <FieldGroup title={t("Frame")} subtitle={t("Physical features - Frame - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label="Has frame"
                    type="checkbox"
                    name="hasFrame"
                  />

                  {hasFrame && (<VStack width="full" align="left">
                  <HStack>
                    <FormInputHook
                      label="Has original frame"
                      type="checkbox"
                      name="hasOriginalFrame"
                    />
                    <FormInputHook
                      label="Material"
                      options={ASSET_CONSTANTS.ASSET_FRAME_MATERIALS}
                      name="frameMaterial"
                    />
                  </HStack>
                  <FormInputHook
                      label="Is valuable frame"
                      type="checkbox"
                      name="isValuableFrame"
                    />
                    <FormInputHook
                      label="Frame manufacturer"
                      name="frameManufacturer" />
                    <FormInputHook
                      label="Frame description"
                      name="frameDescription"
                      type="textarea"
                      />
                  </VStack>

                  )}
                </HStack>
              </VStack>
            </FieldGroup>
          }


          {
            category === 'DESIGN_OR_ANTIQUE' &&
            <FieldGroup title={t("Usage")} subtitle={t("Physical features - Usage - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label=""
                    name="usage"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>
          }
          {
            category === 'DESIGN_OR_ANTIQUE' &&
            <FieldGroup title={t("Finishing")} subtitle={t("Physical features - Finishing - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label=""
                    name="finishing"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>
          }



          {
            category !== 'DIGITAL' &&
            <FieldGroup title={t("Protection presence")} subtitle={t("Physical features - Protection presence - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Has protection, shield, glass, container etc."
                  type="checkbox"
                  name="hasContainer"
                />

                {hasContainer && (
                  <FormInputHook label="Container details" name="containerDetails" />
                )}
              </HStack>
            </VStack>
            </FieldGroup>
          }


          { (category === 'DIGITAL') && <FieldGroup title={t("Method of use")} subtitle={t("Physical features - Method of use - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="methodOfUse"
                  name="methodOfUse"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup> }
          { (category === 'DIGITAL') && <FieldGroup title={t("Related physical artwork")} subtitle={t("Physical features - Related physical artwork - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Related physical artwork"
                  name="relatedPhysicalWork"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup> }

          { ((category === 'ARCHEOLOGY') || (category === 'NATURALISTIC_GOODS') || (category === 'NUMISMATIC_COINS') || (category === 'NUMISMATIC_BANKNOTES') || (category === 'PHILATELY')) &&
          <FieldGroup title={t("Conservation method")} subtitle={t("Physical features - Conservation method - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label=""
                  name="conservationMethod"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup> }




          {/* <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
          >
            {t("Save")}
          </Button>
          {children} */}
        {/* </Stack>
      </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default PhysicalFeaturesNewCategoryForm;
