import { DetailsPageSkeleton } from "components/ui";
import { useGetAppraisalByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditAppraisalPage = () => {
  const { appraisalId } = useParams();

  const { data, loading, error, refetch } = useGetAppraisalByIdQuery({
    variables: {
      id: appraisalId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  return <Page data={data} refetchAppraisal={refetch} />;
};

export default EditAppraisalPage;
