import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import { FileCard, FormFooter } from "components/ui";
import {
  PolicyDocumentNode,
  useCreatePolicyDocumentMutation,
  useDeletePolicyDocumentMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import PolicyDocumentsFields from "../PolicyDocumentsFields";

interface PolicyDocumentsFormProps {
  data: Partial<PolicyDocumentNode>[];
  policyId: string;
  refetch: () => void;
}

const PolicyDocumentsForm = ({ data, policyId, refetch }: PolicyDocumentsFormProps) => {
  const methods = useForm();
  console.log({ data });

  const [
    createPolicyDocument,
    {
      data: createPolicyDocumentData,
      error: createPolicyDocumentError,
      loading: createPolicyDocumentLoading,
    },
  ] = useCreatePolicyDocumentMutation({
    ...handleMutation("Document created!"),
  });
  const [
    deletePolicyDocument,
    {
      data: deletePolicyDocumentData,
      error: deletePolicyDocumentError,
      loading: deletePolicyDocumentLoading,
    },
  ] = useDeletePolicyDocumentMutation({
    ...handleMutation("Document deleted!"),
  });

  const onDelete = async (id: string) => {
    await deletePolicyDocument({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  const onSubmit: SubmitHandler<{ policyDocuments: { file: FileList[] }[] }> = async (
    formData,
    e
  ) => {
    const { policyDocuments } = formData;

    const data = await createPolicyDocument({
      variables: {
        input: {
          file: policyDocuments[0].file[0],
          name: "TEST",
          policyId,
        },
      },
    });

    methods.reset();

    refetch();
  };

  return (
    <Box my={4}>
      <Heading size="lg" mb={4}>
        <Trans>Files</Trans>
      </Heading>
      <HStack>
        {data.length > 0 ? (
          data.map(({ id, file, uuid, createdAt }, index) => (
            <FileCard
              index={index}
              id={id}
              file={file}
              success={deletePolicyDocumentData}
              error={deletePolicyDocumentError}
              loading={deletePolicyDocumentLoading}
              createdAt={createdAt}
              uuid={uuid}
              handleDelete={onDelete}
              refetch={refetch}
            />
          ))
        ) : (
          <Text>
            <Trans>No files have been uploaded yet!</Trans>
          </Text>
        )}
      </HStack>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PolicyDocumentsFields />
          <FormFooter data={[]} errors={[]} title="Policy document" loading={false} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default PolicyDocumentsForm;
