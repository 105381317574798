import { useGetEventLogByIdQuery } from "graphql/queries/generated/queries";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  VStack,
  HStack,
  Button,
  Text,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { BorderedBox, Field } from "components/ui";
import parseAmount from "helpers/parseAmount";
import parseDate from "helpers/parseDate";
import { acquisitionTypeOptions, eventTypeOptions } from "./EventLogFields";

interface ViewEventLogDrawerProps {
  assetId: string;
  eventLogId: string;
  show: boolean;
  onClose: () => void;
}

const WiewEventLogDrawer = ({ assetId, eventLogId, show, onClose }: ViewEventLogDrawerProps) => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useGetEventLogByIdQuery({
    variables: {
      id: eventLogId,
    },
  });

  if (loading) return null;
  if (!data) return null;

  const { eventLog } = data;
  const { eventType, sold } = eventLog;

  let eventTypeLabel = "";
  for (const option of eventTypeOptions)
    if (option.value === eventLog.eventType) eventTypeLabel = option.label;

  let acquisitionTypeLabel = "";
  for (const option of acquisitionTypeOptions)
    if (option.value === eventLog.acquisitionType) acquisitionTypeLabel = option.label;

  const value = parseAmount(eventLog.value);
  const minimumPrice = parseAmount(eventLog.minimumPrice);
  const maximumPrice = parseAmount(eventLog.maximumPrice);
  const reservePrice = parseAmount(eventLog.reservePrice);
  const auctionRights = parseAmount(eventLog.auctionRights);

  return (
    <Drawer isOpen={show} onClose={onClose} placement="right" size="xl">
      <DrawerOverlay maxW="80vw" w="80vw" />
      <DrawerContent maxW="80vw" w="80vw">
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>{eventTypeLabel}</Trans>
          </Heading>
        </DrawerHeader>

        <DrawerBody>
          <VStack flex="3" direction="column" spacing="6">
            <BorderedBox title="Event log Details" subtitle="Lorem ipsum dolor sit amet">
              <Field label="Owner" value={eventLog.owner ? eventLog.owner.fullName : "..."} />
              {eventType === "acquisition" && (
                <Field
                  label={t("Come from")}
                  value={eventLog.origin ? eventLog.origin.fullName : "..."}
                />
              )}
              {(eventType === "change_location" || eventType === "auction") && (
                <Field label={t("Host")} value={eventLog.host ? eventLog.host.fullName : "..."} />
              )}
              {(eventType === "sale" || eventType === "transfer_donation") && (
                <Field
                  label={t("New owner")}
                  value={eventLog.newOwner ? eventLog.newOwner.fullName : "..."}
                />
              )}
              {(eventType === "consignment" || eventType === "sale") && (
                <Field
                  label={t("Broker")}
                  value={eventLog.broker ? eventLog.broker.fullName : "..."}
                />
              )}

              {eventType !== "sale" && eventType !== "lost_stolen" && eventType !== "destruction" && (
                <>
                  <Field label="Collection public name" value={eventLog.collectionPublicName} />
                  <Field label="Location description" value={eventLog.locationDescription} />
                </>
              )}

              {eventType === "acquisition" && (
                <HStack>
                  <Field label={t("Hide owner")} value={eventLog.hideOwner ? "Yes" : "No"} />
                  <Field
                    label={t("Commercially available")}
                    value={eventLog.commerciallyAvailable ? "Yes" : "No"}
                  />
                </HStack>
              )}

              {eventType !== "consignment" &&
                eventType !== "auction" &&
                eventType !== "lost_stolen" && (
                  <Field
                    label={t("Data")}
                    value={eventLog.startDate ? parseDate(eventLog.startDate) : "..."}
                  />
                )}

              {(eventType === "consignment" ||
                eventType === "auction" ||
                eventType === "lost_stolen") && (
                <HStack>
                  <Field
                    label={t("Data inizio")}
                    value={eventLog.startDate ? parseDate(eventLog.startDate) : "..."}
                  />
                  <Field
                    label={t("Data fine")}
                    value={eventLog.endDate ? parseDate(eventLog.endDate) : "..."}
                  />
                </HStack>
              )}

              {eventType === "acquisition" && (
                <HStack>
                  <Field label={t("Acquisition type")} value={acquisitionTypeLabel} />
                  <Field label={t("Carrying value")} value={value} />
                </HStack>
              )}

              {eventType === "consignment" && (
                <Field label={t("Commission")} value={eventLog.commission} />
              )}

              {eventType === "sale" && (
                <>
                  <Field label={t("Commission")} value={eventLog.commission} />
                  <HStack>
                    <Field label={t("Selling location")} value={eventLog.sellingLocation} />
                    <Field label={t("Selling price")} value={value} />
                  </HStack>
                </>
              )}

              {eventType === "auction" && (
                <>
                  <Field label={t("Auction description")} value={eventLog.auctionDescription} />
                  <Field label={t("Lot number")} value={eventLog.lotNumber} />
                  <HStack>
                    <Field label={t("Minimum price")} value={minimumPrice} />
                    <Field label={t("Maximum price")} value={maximumPrice} />
                    <Field label={t("Reserve price")} value={reservePrice} />
                  </HStack>
                  <HStack>
                    <Field label={t("Sold")} value={eventLog.sold ? "Yes" : "No"} />
                    {sold && <Field label={t("Selling price")} value={value} />}
                    {sold && <Field label={t("Auction rights")} value={auctionRights} />}
                  </HStack>
                </>
              )}

              <Field label="Note" value={eventLog.note} />
            </BorderedBox>

            <BorderedBox title="Document" subtitle="Lorem ipsum dolor sit amet">
              {eventLog.document && (
                <Button
                  title="Open in new window"
                  onClick={() => window.open(eventLog.document)}
                  fontWeight="600"
                >
                  <Trans>Vedi file</Trans>
                </Button>
              )}
              {!eventLog.document && (
                <Text fontStyle="italic" fontWeight="400" color="gray.300">
                  <Trans>Value not found</Trans>
                </Text>
              )}
            </BorderedBox>
          </VStack>
        </DrawerBody>

        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default WiewEventLogDrawer;
