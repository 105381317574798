import { ApolloQueryResult } from "@apollo/client";
import { Box, Button, SkeletonCircle, SkeletonText, Text } from "@chakra-ui/react";
import {
  BooleanFilter,
  CountryCell,
  CreatedByFilter,
  HiddenHoverableCell,
  Table,
} from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import {
  CertificateNode,
  Exact,
  GetAllCertificatesByPolicyIdAndIsActiveQuery,
  useDeleteCertificateMutation,
  UserNode,
  useSignCertificateMutation,
  Money,
} from "graphql/queries/generated/queries";
import downloadFile from "helpers/downloadFile";
import getColumns, { BooleanCell } from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { Trans } from "react-i18next";
import parseAmount from "helpers/parseAmount";

const CUSTOM_COLUMNS = [
  {
    Header: "N.",
    Footer: "N.",
    accessor: "progressiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      String(value) === "0" ? "1" : value,
    weight: 101,
    disableFilters: true,
  },
  {
    Header: "Cod.",
    Footer: "Cod.",
    accessor: "uuid",
    Cell: HiddenHoverableCell,
    weight: 100,
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lender",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "lenderCountry",
    Footer: "",
    accessor: "lenderCountry",
    disableFilters: true,
    Cell: CountryCell,
  },
  {
    Header: "Signed",
    Footer: "",
    accessor: "signed",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },
  {
    Header: "Signed by",
    Footer: "Signed by",
    disableFilters: true,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "signedBy.name",
  },
  {
    Header: "Signed at",
    Footer: "Signed at",
    disableFilters: false,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "signedAt",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
  },
  {
    Header: "Status",
    Footer: "",
    accessor: "isValid",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    sortType: "basic",
  },

  // {
  //   Header: "File",
  //   Footer: "",
  //   accessor: "file",
  //   disableFilters: true,
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => (
  //     <a href={value} target="_blank" rel="noreferrer">
  //       <RiFile2Fill />
  //     </a>
  //   ),
  // },
  {
    Header: "Total Insured Value",
    Footer: "Value",
    accessor: "totalInsuredValue",
    Cell: ({ cell: { value } }: { cell: { value: Money } }) => parseAmount(value),
    weight: 0,
    disableFilters: true,
  },
  {
    Header: "File",
    footer: "",
    accessor: "file",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button
          style={{ height: "auto", maxWidth: "120px" }}
          variant="unstyled"
          title="Download"
          onClick={() => window.open(value)}
        >
          <Text fontWeight="400" textDecor="underline">
            Download file
          </Text>
        </Button>
      ) : (
        <Trans>No file was attached</Trans>
      ),

    disableFilters: true,
  },
  {
    Header: "Created by",
    Footer: "Created by",
    disableFilters: true,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
  },
  {
    Header: "Created at",
    Footer: "",
    accessor: "createdAt",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",
    disableFilters: true,
  },
];

const CUSTOM_FIELDS = CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

const PolicyCertificatesSummary = ({
  policyCertificates,
  refetchCertificates,
  loading,
  type,
}: {
  policyCertificates: Partial<
    Omit<CertificateNode, "signedBy" | "createdBy"> & {
      signedBy: Pick<UserNode, "name" | "id">;
    } & { createdBy: Pick<UserNode, "name" | "id"> }
  >[];
  refetchCertificates?: (
    variables?: Partial<Exact<{ policy: string; isValid: boolean }>>
  ) => Promise<ApolloQueryResult<GetAllCertificatesByPolicyIdAndIsActiveQuery>>;
  loading: boolean;
  type: "TEMPORARY_EXHIBITION" | "PERMANENT_COLLECTION";
}) => {
  const [
    signCertificate,
    { data: signCertificateData, error: signCertificateError, loading: signCertificateLoading },
  ] = useSignCertificateMutation({ ...handleMutation("Certificate signed!") });
  const [
    deleteCertificate,
    {
      data: deleteCertificateData,
      error: deleteCertificateError,
      loading: deleteCertificateLoading,
    },
  ] = useDeleteCertificateMutation({ ...handleMutation("Certificate deleted!") });

  if (loading) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>
    );
  }

  if (!policyCertificates || policyCertificates.length === 0)
    return (
      <Text>
        <Trans>No documents found.</Trans>
      </Text>
    );

  const dataToGetColumns = policyCertificates[0];
  const columns = getColumns({
    data: dataToGetColumns,
    customColumns: CUSTOM_COLUMNS,
    customFields: CUSTOM_FIELDS,
  });

  const onDelete = async (id: string) => {
    await deleteCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetchCertificates();
  };

  const onSignCertificate = async ({
    row: { original },
  }: {
    row: { original: CertificateNode };
  }) => {
    if (!original.id) throw new Error("Missing id in row certificate.");
    const { id } = original;
    await signCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetchCertificates();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete certificate(s)</Trans>,
      intent: "danger",
      loading: deleteCertificateLoading,
      action: async (selectedRows: { original: CertificateNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await deleteCertificate({
              variables: {
                input: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  id: original.id,
                },
              },
            });
            return null;
          })
        );
        await refetchCertificates();
      },
    },
    {
      name: <Trans>Sign certificate(s)</Trans>,
      loading: signCertificateLoading,
      action: async (selectedRows: { original: CertificateNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onSignCertificate({ row });
          })
        );
        await refetchCertificates();
      },
    },
    {
      name: <Trans>Download certificate(s)</Trans>,
      action: (selectedRows: { original: CertificateNode }[]) => {
        selectedRows.map((row) => downloadFile(row.original.file));
      },
    },
  ];

  console.log({ columns });

  return (
    <>
      {/* <VStack
        overflowX="scroll"
        w="100%"
        py={1}
        spacing={2}
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="md"
      >
        {policyCertificates.map((certificate) => (
          <CertificateCard
            certificate={certificate}
            refetchCertificates={refetchCertificates}
            type={type}
          />
        ))}
      </VStack> */}
      <Table
        data={policyCertificates}
        columns={columns}
        hiddenColumns={["creationDate", "metafields", "language", "lender", "lenderCountry"]}
        batchActions={BATCH_ACTIONS}
      />
    </>
  );
};

export default PolicyCertificatesSummary;
