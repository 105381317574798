import { Heading, HStack, Text, VStack, Image, Button } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

interface BCrumbProps {
  description?: string;
  title?: string;
  image?: string;
  showOnlyBackBtn?: boolean;
}

const BCrumb = ({ description = "", title, image, showOnlyBackBtn = false }: BCrumbProps) => {
  const navigate = useNavigate();

  if (showOnlyBackBtn)
    return (
      <Button variant="ghost" mt="4" mb="4" p="0" onClick={() => navigate(-1)}>
        <HStack>
          <BiArrowBack color="gray.600" size={20} />
          <Text color="gray.600">
            <Trans>Go back</Trans>
          </Text>
        </HStack>
      </Button>
    );

  return (
    <HStack spacing="24px" mb="6">
      <VStack align="flex-start" mb={4} w="100%">
        <HStack justifyContent="flex-start">
          {image && <Image src={image} h="5rem" w="auto" mr="4" />}
          <VStack align="flex-start" my={4}>
            <Button variant="ghost" p="0" onClick={() => navigate(-1)}>
              <HStack>
                <BiArrowBack color="gray.600" size={20} />
                <Text color="gray.600">
                  <Trans>Go back</Trans>
                </Text>
              </HStack>
            </Button>
            <Heading size="2xl" fontWeight="extrabold">
              <Trans>{title}</Trans>
            </Heading>
            <Text fontSize="md" variant="muted">
              <Trans>{`${title} breadcrumb description`}</Trans>
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default BCrumb;
