import { BorderedBox, Table } from "components/ui";
import { useEffect, useState } from "react";
import { OfficeNode, useGetOfficesByRegistryIdLazyQuery } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { OFFICE_CUSTOM_FIELDS, OFFICE_HIDDEN_FIELDS, OFFICE_CUSTOM_COLUMNS } from "../shared/RegistryDatatableCostants";

interface OfficesViewParameters {
  registryId: string;
}

const OfficesViewPage = ({ registryId }: OfficesViewParameters) => {
  const [loadOffices, refetch] = useGetOfficesByRegistryIdLazyQuery();

  const [offices, setOffices] = useState< Array<OfficeNode> >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedOffices = async (registryId: string) => {
    const { data: offices, loading: officesLoading, error: officesError } = await loadOffices({
      variables: {
        registryId
      }
    });

    if (!offices || !offices.allOffices || !offices.allOffices.edges || offices.allOffices.edges.length <= 0) return null;

    const result : OfficeNode[] = []

    for (let i = 0; i < offices.allOffices.edges.length; i += 1) result.push(offices.allOffices.edges[i].node as OfficeNode);

    setOffices(result);

    return result;
  };

  useEffect(() => {
    if (typeof registryId !== "undefined" && registryId !== "") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadParsedOffices(registryId);
    }

    return undefined;
  }, []);

  let dataToGetColumns;
  let columns;

  if ( offices && offices.length > 0 ) {
    // eslint-disable-next-line prefer-destructuring
    dataToGetColumns = offices[0];

    columns = getColumns({
      data: dataToGetColumns,
      customFields: OFFICE_CUSTOM_FIELDS,
      customColumns: OFFICE_CUSTOM_COLUMNS,
    });
  }

  return (
    <BorderedBox title="Offices" subtitle="Lorem ipsum dolor sit amet">
      <Table hiddenColumns={OFFICE_HIDDEN_FIELDS} columns={columns} data={offices} />
    </BorderedBox>
  );
};

export default OfficesViewPage;
