import { ApolloError } from "@apollo/client";
import { createStandaloneToast } from "@chakra-ui/react";
import i18n from "i18n/i18n";

export const { ToastContainer, toast } = createStandaloneToast();

export const handleMutation = (successMessage: string) => ({
  onError: (error: ApolloError) => {
    toast({
      title: `${i18n.t("Something went wrong!")}`,
      description: `${error.message}`,
      status: "error",
      isClosable: true,
      position: "bottom",
    });
  },
  onCompleted: () => {
    toast({
      title: `${i18n.t(successMessage)}`,
      status: "success",
      isClosable: true,
      position: "bottom",
    });
  },
});
