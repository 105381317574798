import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFooter } from "components/ui";
import { ValidationProvider } from "components/form/ValidationContext";
import { useCreateExhibitionMutation, ExhibitionInput } from "graphql/queries/generated/queries";
import { ExhibitionInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MGMT_ROUTES } from "routes/constants";
import { handleMutation } from "middleware/Toaster";
import ExhibitionFields from "../shared/ExhibitionFields";

const NewExhibitionPage = () => {
  const [createExhibition, { data, loading, error, client, called }] = useCreateExhibitionMutation({
    ...handleMutation("Exhibition created!"),
  });
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const methods = useForm<ExhibitionInput>({
    defaultValues: {},
    resolver: yupResolver(ExhibitionInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof ExhibitionInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<ExhibitionInput> = async (formData, e) => {
    console.log(formData);

    const result = await createExhibition({
      variables: {
        input: {
          exhibitionData: {
            ...formData,
          },
        },
      },
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.EXHIBITIONS}/edit/${result.data.createExhibition.exhibition.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 3000);

    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <ValidationProvider schema={ExhibitionInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ExhibitionFields watch={methods.watch} />
            <FormFooter data={data} loading={loading} errors={[error]} title="Exhibition entry" />
          </form>
        </ValidationProvider>
      </FormProvider>
    </Stack>
  );
};

export default NewExhibitionPage;
