/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Flex, HStack, VStack, Text, Divider, Button } from "@chakra-ui/react";

import { useTranslation, Trans } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import {
  ClearRefinements,
  HierarchicalMenu,
  useCurrentRefinements,
  useDynamicWidgets,
} from "react-instantsearch-hooks-web";
import RefinementList from "./RefinementList";

interface HierarchicalAttribute {
  title: string;
  attributes: string[];
}

interface FacetsProps {
  hierarchicalAttributes: HierarchicalAttribute[];
}

const Facets = ({ hierarchicalAttributes }: FacetsProps) => {
  const { attributesToRender } = useDynamicWidgets();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { items } = useCurrentRefinements();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  return (
    <VStack justify="flex-start" height="100%" alignItems="flex-start">
      {items.length > 0 && (
        <Button>
          <HStack>
            <RiCloseFill />
            <ClearRefinements />
          </HStack>
        </Button>
      )}

      {attributesToRender.map(
        (attribute) =>
          !["title", "category", "subcategory"].includes(attribute) && (
            <Flex direction="column" key={attribute} pb={4}>
              <Text textTransform="uppercase" fontWeight="semibold">
                <Trans>{attribute}</Trans>
              </Text>
              <Divider borderColor="black" my={1} />{" "}
              <RefinementList attribute={attribute} limit={3} showMoreLimit={10} />
            </Flex>
          )
      )}
      {hierarchicalAttributes &&
        hierarchicalAttributes.map(({ title, attributes }) => (
          <Flex direction="column" pb={4}>
            <Text variant="muted">
              <Trans>{title}</Trans>
            </Text>
            <Divider borderColor="gray.500" my={1} />{" "}
            <HierarchicalMenu attributes={attributes} limit={5} showMoreLimit={10} />
          </Flex>
        ))}
    </VStack>
  );
};

export default Facets;
