import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { Controller, FieldValues, useController, UseFormSetValue } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef, useState } from "react";
import { InputWrapper } from "components/form";
import { FormInputProps } from "./type";
import { Wrapper } from "./Wrapper";

const Address = ({
  name,
  label,
  errors,
  placeholder,
  control,
  disabled = false,
  register,
  required,
  setValue,
}: FormInputProps & { setValue: UseFormSetValue<FieldValues> }) => {
  const {
    field: { onChange: onCountryChange, ...restCountry },
  } = useController({
    name: `${name}.country`,
    control,
  });
  const {
    field: { onChange: onStateChange, ...restState },
  } = useController({
    name: `${name}.state`,
    control,
  });
  const {
    field: { onChange: onLocalityChange, ...restLocality },
  } = useController({
    name: `${name}.locality`,
    control,
  });
  const {
    field: { onChange: onStreetNumberChange, ...restStreetNumber },
  } = useController({
    name: `${name}.streetNumber`,
    control,
  });

  const {
    field: { onChange: onRouteChange, ...restRoute },
  } = useController({
    name: `${name}.route`,
    control,
  });

  const [showHiddenInputs, setShowHiddenInputs] = useState(false);

  const popoverContentRef = useRef(null);
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [maps, setMaps] = useState<typeof google.maps | null>(null);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [locality, setLocality] = useState("");
  const [route, setRoute] = useState("");
  const [streetNumber, setStreetNumber] = useState("");

  const [results, setResults] = useState<google.maps.places.AutocompleteResponse | null>(null);
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  // TODO clean before changing address
  useEffect(() => {
    loader
      .load()
      .then((g) => {
        console.log(g);
        setMaps(g.maps);
      })
      .catch((e) => console.error(e));
  }, []);

  const { t } = useTranslation();

  useOutsideClick({
    ref: popoverContentRef,
    handler: () => onClose(),
  });

  useEffect(() => {
    console.log({ state, country, locality });
  }, [state, country, locality]);
  // useEffect(() => {
  //   if (!maps?.places) return;
  //   autoCompleteRef.current = new maps.places.Autocomplete(inputRef?.current, gOptions);
  // }, [maps]);

  const setAddressFields = (address: google.maps.GeocoderAddressComponent[]) => {
    const results = address.map(({ types, long_name, short_name }) => {
      console.log({ types, long_name, short_name });
      if (types.includes("street_number")) {
        setValue(`${name}.streetNumber`, short_name);
        // onCountryChange({ target: { name: `${name}.country`, value: short_name } });
        return {
          streetNumber: short_name,
        };
      }
      if (types.includes("route")) {
        setValue(`${name}.route`, short_name);
        // onCountryChange({ target: { name: `${name}.country`, value: short_name } });
        return {
          route: short_name,
        };
      }
      if (types.includes("country")) {
        setValue(`${name}.country`, short_name);
        // onCountryChange({ target: { name: `${name}.country`, value: short_name } });
        return {
          country: short_name,
        };
      }
      if (types.includes("administrative_area_level_1")) {
        setValue(`${name}.state`, short_name);
        // onStateChange(short_name);
        return {
          state: short_name,
        };
      }
      if (types.includes("locality")) {
        setValue(`${name}.locality`, short_name);
        // onLocalityChange(short_name);
        return {
          locality: short_name,
        };
      }
      return null;
    });
  };

  return (
    <div ref={popoverContentRef}>
      <Wrapper name={name} error={errors[name]}>
        {label && (
          <FormLabel htmlFor={name} mb="1" fontSize="xs" display="flex">
            <Text>{t(label)}</Text>{" "}
            {required && (
              <Text color="red.500" mx="1">
                (<Trans>required</Trans>)
              </Text>
            )}
          </FormLabel>
        )}
        <div className="hidden" ref={autoCompleteRef} />
        <Controller
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          control={control}
          name={name}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          render={({ field: { onChange, value, name, ref } }) => (
            // split and get naive datetime and timezone separated

            <Popover
              returnFocusOnClose={false}
              isOpen={isOpen}
              onClose={onClose}
              placement="bottom-start"
              closeOnBlur={false}
              autoFocus={false}
              closeDelay={200}
            >
              <PopoverTrigger>
                <HStack>
                  <Input
                    width="15rem"
                    id={name}
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    value={value?.raw as string | number | readonly string[]}
                    borderRadius="md"
                    borderWidth="2px"
                    ref={inputRef}
                    onClick={() => {
                      if (results) onOpen();
                    }}
                    borderColor="gray.200"
                    onChange={async (e) => {
                      onChange({ raw: e.target.value });

                      if (e.target.value.trim().length < 3) {
                        setResults(null);
                        setValue(`${name}.country`, null);
                        setValue(`${name}.state`, null);
                        setValue(`${name}.locality`, null);
                        if (isOpen) onClose();
                        return;
                      }

                      onOpen();

                      const autocomplete = new maps.places.AutocompleteService();
                      const results = await autocomplete.getPlacePredictions({
                        input: e.target.value,
                      });

                      if (results.predictions.length === 0) {
                        setValue(`${name}.country`, null);
                        setValue(`${name}.state`, null);
                        setValue(`${name}.locality`, null);
                      }
                      setResults(results);
                    }}
                  />
                  <Button onClick={() => setShowHiddenInputs((prevState) => !prevState)}>
                    {showHiddenInputs ? t("Hide hidden inputs") : t("Show hidden inputs")}
                  </Button>
                </HStack>
              </PopoverTrigger>
              {results && (
                <PopoverContent w="fit-content">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Box h="fit-content" zIndex="modal">
                      <VStack align="flex-start" overflowY="scroll" spacing={0}>
                        {results?.predictions.map((item) => (
                          <Button
                            variant="ghost"
                            py="0"
                            px="2"
                            mt="0"
                            type="button"
                            onClick={() => {
                              onChange({ raw: item.description });
                              const detailsAPI = new maps.places.PlacesService(
                                autoCompleteRef?.current
                              );
                              const details = detailsAPI.getDetails(
                                { placeId: item.place_id },
                                (e) => setAddressFields(e.address_components)
                              );
                              onClose();
                            }}
                          >
                            {item.description}
                          </Button>
                        ))}
                      </VStack>
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          )}
        />
      </Wrapper>
      {showHiddenInputs && (
        <HStack align="center">
          <InputWrapper
            name="Country"
            error={null}
            visibility={showHiddenInputs ? "visible" : "hidden"}
          >
            <VStack align="flex-start">
              <FormLabel htmlFor={`${name}.country`} mb="1" fontSize="xs" display="inline-flex">
                <Text>
                  <Trans>Country</Trans>
                </Text>
              </FormLabel>
              <Input {...restCountry} />
            </VStack>
          </InputWrapper>
          <InputWrapper
            name="Locality"
            error={null}
            visibility={showHiddenInputs ? "visible" : "hidden"}
          >
            <VStack align="flex-start">
              <FormLabel htmlFor={`${name}.locality`} mb="1" fontSize="xs" display="inline-flex">
                <Text>
                  <Trans>Locality</Trans>
                </Text>
              </FormLabel>
              <Input {...restLocality} />
            </VStack>
          </InputWrapper>
          <InputWrapper
            name="State"
            error={null}
            visibility={showHiddenInputs ? "visible" : "hidden"}
          >
            <VStack align="flex-start">
              <FormLabel htmlFor={`${name}.state`} mb="1" fontSize="xs" display="inline-flex">
                <Text>
                  <Trans>State</Trans>
                </Text>
              </FormLabel>
              <Input {...restState} />
            </VStack>
          </InputWrapper>
          <InputWrapper
            name="Street number"
            error={null}
            visibility={showHiddenInputs ? "visible" : "hidden"}
          >
            <VStack align="flex-start">
              <FormLabel
                htmlFor={`${name}.streetNumber`}
                mb="1"
                fontSize="xs"
                display="inline-flex"
              >
                <Text>
                  <Trans>Street number</Trans>
                </Text>
              </FormLabel>
              <Input {...restStreetNumber} />
            </VStack>
          </InputWrapper>
          <InputWrapper
            name="Route"
            error={null}
            visibility={showHiddenInputs ? "visible" : "hidden"}
          >
            <VStack align="flex-start">
              <FormLabel htmlFor={`${name}.route`} mb="1" fontSize="xs" display="inline-flex">
                <Text>
                  <Trans>Route</Trans>
                </Text>
              </FormLabel>
              <Input {...restRoute} />
            </VStack>
          </InputWrapper>
        </HStack>
      )}
    </div>
  );
};

export default Address;
