import { Box, Divider, Skeleton, useDisclosure } from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import {
  CreatedByFilter,
  DropdownFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
  TablePageSkeleton,
} from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import {
  useDeleteRegistryMutation,
  useGetAllRegistriesAuthorsQuery,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import { MutableRefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { REGISTRY_ROUTES } from "routes/constants";
// const BATCH_ACTIONS = [
//   {
//     name: <Trans>Disable selected users</Trans>,
//     action: (selectedRows: UserNodeEdge) => console.log(selectedRows),
//   },
// ];
// const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new registry entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const CUSTOM_FIELDS = [
  "fullName",
  "alias",
  "birthDate",
  "deathDate",
  "authorType",
  "authoredAssetsCount",
  "createdBy",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Full name",
    Footer: "Full name",
    accessor: "fullName",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Alias",
    Footer: "Alias",
    accessor: "alias",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Birth date",
    Footer: "Birth date",
    accessor: "birthDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Death date",
    Footer: "Death date",
    accessor: "deathDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Author type",
    Footer: "Author type",
    accessor: "authorType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Assets",
    Footer: "Assets",
    accessor: "authoredAssetsCount",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Created by",
    Footer: "Created by",
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
];

const RegistriesAuthorsPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllRegistriesAuthorsQuery();
  const [deleteRegistry, { loading: deleteLoading }] = useDeleteRegistryMutation({
    ...handleMutation("Registry deleted!"),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  if (loading) return <TablePageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.allRegistries || data.allRegistries.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allRegistries: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    // filterableColumns: FILTERABLE_COLUMNS,
  });

  // const onDelete = (id: string) => console.log(id);

  const onDelete = async (id: string) => {
    await deleteRegistry({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={CUSTOM_COLUMNS}
          data={data.allRegistries.edges.flatMap((edge) => edge?.node)}
          pathname={`${REGISTRY_ROUTES.REGISTRY}/`}
          deleteAction={(id: string) => onDelete(id)}
          hiddenColumns={[
            "businessName",
            "email",
            "phone",
            "mobilePhone",
            "officesCount",
            "categories",
          ]}
        />
      </Skeleton>
    </Box>
  );
};

export default RegistriesAuthorsPage;
