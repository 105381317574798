import { Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFooter } from "components/ui";
import { ValidationProvider } from "components/form/ValidationContext";
import { useCreateRegistryMutation, RegistryInput } from "graphql/queries/generated/queries";
import { RegistryInputSchema } from "graphql/queries/generated/validation-schema";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { handleMutation } from "middleware/Toaster";
import { REGISTRY_ROUTES } from "routes/constants";
import RegistryFields from "../shared/RegistryFields";

const NewRegistryPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [registryId, setRegistryId] = useState("");
  const [createRegistry, { data, loading, error }] = useCreateRegistryMutation({
    ...handleMutation("Registry created!"),
  });

  const methods = useForm<RegistryInput>({
    defaultValues: {
      isCompany: false,
      authorType: [],
      categories: [],
    },
    resolver: yupResolver(RegistryInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof RegistryInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<RegistryInput> = async (formData, e) => {
    const result = await createRegistry({
      variables: {
        input: {
          registryData: {
            ...formData,
          },
        },
      },
    });

    setTimeout(() => {
      navigate(`${REGISTRY_ROUTES.REGISTRY}/edit/${result.data.createRegistry.registry.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 1000);

    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <ValidationProvider schema={RegistryInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RegistryFields
              watch={methods.watch}
              setValue={methods.setValue}
              registryId={registryId}
              key={registryId}
            />
            <FormFooter data={data} loading={loading} errors={[error]} title="Registry entry" />
          </form>
        </ValidationProvider>
      </FormProvider>
    </Stack>
  );
};

export default NewRegistryPage;
