import { ApolloQueryResult } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationProvider } from "components/form/ValidationContext";
import { Field, Dot } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import {
  AppraisalInput,
  AssetNode,
  Exact,
  GetAppraisalByIdQuery,
  useCloseAppraisalMutation,
  useGetAssetByIdLazyQuery,
  useUpdateAppraisalMutation,
} from "graphql/queries/generated/queries";
import { AppraisalInputSchema } from "graphql/queries/generated/validation-schema";
import BorderedBox from "pages/assets/shared/BorderedBox";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleMutation, toast } from "middleware/Toaster";
import { MGMT_ROUTES } from "routes/constants";
import AppraisalFields from "../shared/AppraisalFields";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import NewAssetDrawer from "./NewAssetDrawer";

interface PageProps {
  data: GetAppraisalByIdQuery;
  asset?: AssetNode;
  refetchAppraisal: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAppraisalByIdQuery>>;
}

const Page = ({ data, asset, refetchAppraisal }: PageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (data != null && data.appraisal != null && data.appraisal.appraisalStatus === "closed") {
    console.log("Appraisal chiuso, procedo a visualizzazione.");

    toast({
      title: "Only view.",
      description: "This appraisal is closed: you will be redirect to the view page.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.APPRAISAL}/${data.appraisal.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  }

  const preloadedAsset: AssetNode = data.appraisal.appraisalAsset as AssetNode;

  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [userWantsToCreateAssets, setUserWantsToCreateAssets] = useState<boolean>(false);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [assetAdded, setAssetAdded] = useState(false);
  const [choosenAssetBigData, setChoosenAssetBigData] = useState(preloadedAsset);

  /**
   * onCompleteEvent e getAsset funzionano sia per il drawer degli
   * oggetti preesistenti sia per quello della creazione di nuovi
   * oggetti
   */

  const onCompleteEvent = (assetNode: AssetNode) => {
    toast({
      title: "Appraisal not saved.",
      description: "Press the 'save' button to finalize the changes.",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });

    setChoosenAssetBigData(assetNode);
    setAssetAdded(true);
  };

  const [getAsset, { loading: loadingAsset, error: errorAsset, data: dataAsset }] =
    useGetAssetByIdLazyQuery({
      onCompleted: (result) => {
        onCompleteEvent(result.asset as AssetNode);
      },
    });

  const addExistingAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const onOpenExistingAssetDrawer = () => {
    setUserWantsToAddAssets(true);
  };

  const onOpenNewAssetDrawer = () => {
    setUserWantsToCreateAssets(true);
  };

  const {
    appraisal: { __typename, id, ...appraisal },
  } = data;

  const [updateAppraisal, { data: updateAppraisalData, error, loading }] =
    useUpdateAppraisalMutation({
      ...handleMutation("Appraisal updated!"),
    });

  const methods = useForm<AppraisalInput>({
    defaultValues: {
      appraisalAsset: appraisal.appraisalAsset ? appraisal.appraisalAsset.id : "",
      appraisalStatus: appraisal.appraisalStatus,
      appraisalEvaluator: appraisal.appraisalEvaluator ? appraisal.appraisalEvaluator.id : "",
      appraisalDate: appraisal.appraisalDate,
      appraisalConservationStatus: appraisal.appraisalConservationStatus,
      appraisalMotivation: appraisal.appraisalMotivation,
      appraisalLocation: appraisal.appraisalLocation,
      valueInformationAuthorCoefficient: appraisal.valueInformationAuthorCoefficient,
      valueInformationInfo: appraisal.valueInformationInfo,
      valueInformationQuotesSimilarAssets: appraisal.valueInformationQuotesSimilarAssets,
      valueInformationNote: appraisal.valueInformationNote,
      valueCarryingValue: appraisal.valueCarryingValue
        ? {
            amount: appraisal.valueCarryingValue.amount,
            currency: appraisal.valueCarryingValue.currency.code,
          }
        : {
            amount: 0,
            currency: "EUR",
          },
      valueEstimatedValue: appraisal.valueEstimatedValue
        ? {
            amount: appraisal.valueEstimatedValue.amount,
            currency: appraisal.valueEstimatedValue.currency.code,
          }
        : {
            amount: 0,
            currency: "EUR",
          },
    },
    resolver: yupResolver(AppraisalInputSchema()),
  });

  const addNewAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    console.log(errors);

    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof AppraisalInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const [closeAppraisal, { loading: closeLoading }] = useCloseAppraisalMutation({
    ...handleMutation("Appraisal has been closed!"),
  });

  const onCloseAppraisal = async (id: string) => {
    await closeAppraisal({
      variables: {
        input: {
          id,
        },
      },
    });

    toast({
      title: "Appraisal succefully closed.",
      description: "This appraisal is closed: you will be redirect to the view page.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.APPRAISAL}/${data.appraisal.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  };

  const onSubmit: SubmitHandler<AppraisalInput> = async (formData, e) => {
    let selectedAppraisalAsset: string = null;
    let payload = null;

    if (assetAdded) {
      selectedAppraisalAsset = choosenAssetBigData.id;

      payload = {
        variables: {
          input: {
            id,
            appraisalData: {
              ...formData,
              appraisalAsset: selectedAppraisalAsset,
            },
          },
        },
      };
    } else {
      payload = {
        variables: {
          input: {
            id,
            appraisalData: {
              ...formData,
            },
          },
        },
      };
    }

    await updateAppraisal(payload);

    setAssetAdded(false);
    setChoosenAssets([]);

    toast({
      title: "Appraisal succefully saved.",
      description: "Appraisal succesfully saved.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    await refetchAppraisal({ id });

    return null;
  };

  return (
    <Stack spacing="4">
      <VStack alignItems="flex-start" pr={8}>
        <Dot
          positiveLabel={t("Draft")}
          negativeLabel={t("Closed")}
          status={data.appraisal.appraisalStatus === "draft"}
        />
        {data.appraisal.appraisalStatus === "draft" && (
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button variant="primary">
                    <Trans>Close</Trans>
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                    <PopoverArrow />
                    <PopoverHeader>{t("You are closing the appraisal")}</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>{t("Warning! the operation is not reversible!")}</PopoverBody>

                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pb={4}
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="danger"
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            onCloseAppraisal(id);
                            onClose();
                          }}
                        >
                          <Trans>Close it</Trans>
                        </Button>
                        <Button onClick={onClose}>
                          <Trans>Cancel</Trans>
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        )}
      </VStack>

      <FormProvider {...methods}>
        <ValidationProvider schema={AppraisalInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)} id="pippo">
            <Stack spacing="4">
              <BorderedBox title={t("Asset")} subtitle="Lorem ipsum dolor sit amet">
                <HStack justifyContent="space-between" alignItems="flex-start">
                  <Field
                    label="ID"
                    value={
                      choosenAssetBigData != null && choosenAssetBigData.id != null
                        ? choosenAssetBigData.id
                        : ""
                    }
                  />
                  <Field
                    label="Title"
                    value={
                      choosenAssetBigData != null && choosenAssetBigData.title != null
                        ? choosenAssetBigData.title
                        : ""
                    }
                  />
                  <Field
                    label="Author"
                    value={
                      choosenAssetBigData.authorEntity != null &&
                      choosenAssetBigData.authorEntity.fullName
                        ? choosenAssetBigData.authorEntity.fullName
                        : ""
                    }
                  />
                  <Field
                    label="Date of creation"
                    value={
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      choosenAssetBigData.actualDateAndPeriod !== null &&
                      choosenAssetBigData.actualDateAndPeriod !== ""
                        ? choosenAssetBigData.actualDateAndPeriod
                        : ""
                    }
                  />

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button onClick={onOpenExistingAssetDrawer} variant="primary">
                      <Trans>Choose new object</Trans>
                    </Button>
                    <Button onClick={onOpenNewAssetDrawer} variant="primary">
                      <Trans>Create and add new object</Trans>
                    </Button>
                  </ButtonGroup>
                </HStack>
              </BorderedBox>

              <AppraisalFields
                footerData={updateAppraisalData}
                loading={loading}
                errors={[error]}
                disabled={data.appraisal.appraisalStatus !== "draft"}
                watch={methods.watch}
                data={data}
                asset={choosenAssetBigData}
              />
            </Stack>
          </form>
        </ValidationProvider>
      </FormProvider>

      {/**
       * Per lo meno il NewAssetDrawer DEVE stare fuori dal tag <form> (e quindi da ValidationProvider e FormProvider)
       * perche' altrimenti il submit del drawer causa il submit della form esterna.
       * Poi in generale non e' certo una buona idea annidare due form...
       */}
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addAsset={addExistingAsset}
        choosenAssets={choosenAssets}
      />
      <NewAssetDrawer
        show={userWantsToCreateAssets}
        onClose={() => setUserWantsToCreateAssets(false)}
        addAsset={addNewAsset}
      />

      {assetAdded && (
        <Alert status="warning">
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="Press the 'save' button to finalize the changes" />
            </Text>
          </VStack>
        </Alert>
      )}
    </Stack>
  );
};

export default Page;
