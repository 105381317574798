import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DetailsPageSkeleton, FieldGroup, NoDataPlaceholder } from "components/ui";
import { useFormik } from "formik";

import COUNTRY_CODES from "constants/countryCodes.json";
import CURRENCIES from "constants/currencies.json";
import groupBy from "lodash/groupBy";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiFile } from "react-icons/bi";
import { Navigate, useParams } from "react-router-dom";
import {
  useAddUserSignatureMutation,
  useChangePasswordMutation,
  useGetAllPermissionQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useUpdateUserPermissionsMutation,
} from "graphql/queries/generated/queries";
import { USER_ROUTES } from "routes/constants";
import { handleMutation } from "middleware/Toaster";
import { useAuth } from "contexts/AuthProvider";
import PermissionsForm from "../PermissionsForm";
import { DictionaryPermissions } from "../types";
import UserSignatureCard from "../shared/UserSignatureCard";

const UsersEditPage = () => {
  const auth = useAuth();
  console.log({ auth });
  const { userId = "" } = useParams();
  const { t } = useTranslation();
  const signatureUploadRef = useRef<HTMLInputElement>(null);

  const [formattedPermissions, setFormattedPermissions] = useState<DictionaryPermissions | null>();

  const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

  const { data, loading, error, refetch } = useGetUserByIdQuery({
    variables: { id: userId },
  });

  useEffect(() => {
    if (!data?.user) return;
    const { user } = data;

    if (!user.userPermissions) return;

    const flattenedPermissions = user.userPermissions.edges.map((edge) => edge?.node?.id ?? "");
    setAllowedPermissions(flattenedPermissions);
  }, [data]);

  const {
    data: allPermissions,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useGetAllPermissionQuery({
    onCompleted(permissionData) {
      if (!permissionData?.allPermissions?.edges) return;

      const {
        allPermissions: { edges },
      } = permissionData;

      const groupedPermissions = groupBy(
        edges,
        (permission) => permission?.node?.contentType.model
      );

      setFormattedPermissions(groupedPermissions);
    },
  });

  const [
    changePassword,
    { data: changePasswordData, loading: changePasswordLoading, error: changePasswordError },
  ] = useChangePasswordMutation({ ...handleMutation("Password updated!") });

  const [updateUser, { data: updateUserData, loading: updateUserLoading, error: updateUserError }] =
    useUpdateUserMutation({ ...handleMutation("User updated!") });

  const [
    updateUserPermissions,
    {
      data: updateUserPermissionsData,
      loading: updateUserPermissionsLoading,
      error: updateUserPermissionsError,
    },
  ] = useUpdateUserPermissionsMutation({ ...handleMutation("Permissions updated!") });

  const [
    addUserSignature,
    { data: addUserSignatureData, loading: addUserSignatureLoading, error: addUserSignatureError },
  ] = useAddUserSignatureMutation({ ...handleMutation("Signature added!") });

  const formikUpdateUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.user?.name,
      phone: data?.user?.phone,
      country: data?.user?.country,
      // language: data?.user?.language,
      // timezone: data?.user?.timezone,
      currency: data?.user?.currency,
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      await updateUser({
        variables: {
          input: {
            id: data?.user?.id ?? "",
            userData: {
              ...values,
              country: values?.country?.code,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            },
          },
        },
      });
    },
  });

  const formikChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: data?.user?.email || "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      await changePassword({
        variables: {
          input: {
            ...values,
          },
        },
      });
    },
  });

  const formikUpdateSignature = useFormik({
    enableReinitialize: true,
    initialValues: {
      signature: "",
    },
    onSubmit: async (values) => {
      await addUserSignature({
        variables: {
          input: {
            file: values.signature,
            userId: userId ?? "",
          },
        },
      });
      await refetch();
    },
  });

  const formikUpdatePermissions = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...allPermissions,
    },
    onSubmit: async () => {
      await updateUserPermissions({
        variables: {
          input: {
            email: data?.user?.email ?? "",
            permissions: allowedPermissions,
          },
        },
      });
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  // TODO: add route paths to something that stores them (routes.ts?), we mustn't write strings on navigate arguments
  if (!userId || !data || !data.user) return <Navigate to={USER_ROUTES.USER} replace />;

  const { user } = data;
  const {
    usersignatureSet: { edges: userSignatureEdges },
  } = user;

  const isMyself = user.email === auth?.user?.email;

  return (
    <Stack spacing="4" divider={<StackDivider />}>
      <form id="updateUser" onSubmit={formikUpdateUser.handleSubmit}>
        <FieldGroup title={t("Personal Info")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <VStack width="full" spacing="6" p="8" rounded="lg" align="left">
            <Text variant="muted">
              {t("User created at ")}
              {user.dateJoined}
            </Text>
            <Text variant="muted">
              {t("User last login at")}
              {user.lastLogin}
            </Text>
            {updateUserError && (
              <Alert status="error">
                <>
                  <AlertIcon />
                  {updateUserError.message}
                </>
              </Alert>
            )}
            {updateUserData && (
              <Alert status="success">
                <>
                  <AlertIcon />
                  {t("Your account has been updated!")}
                </>
              </Alert>
            )}
            <FormControl>
              <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
              <Input
                width="auto"
                id="email"
                name="email"
                type="email"
                disabled
                value={user.email}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="name">{t("Name")}</FormLabel>
              <Input
                width="auto"
                id="name"
                name="name"
                onChange={formikUpdateUser.handleChange}
                value={formikUpdateUser.values.name}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="phone">{t("Phone number")}</FormLabel>
              <Input
                width="auto"
                id="phone"
                name="phone"
                type="phone"
                onChange={formikUpdateUser.handleChange}
                value={formikUpdateUser.values.phone ?? ""}
              />
            </FormControl>

            <Divider borderColor="gray.100" />
            <HStack width="full">
              <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                {t("Save Changes")}
              </Button>
              <Button size="sm" variant="danger">
                {t("Cancel")}
              </Button>
            </HStack>
          </VStack>
        </FieldGroup>

        <FieldGroup
          title={t("Internationalization options")}
          subtitle={t("Lorem ipsum dolor sit amet")}
        >
          <Stack direction="column" spacing="6" align="center" width="full" p="8" rounded="lg">
            <FormControl>
              <FormLabel htmlFor="country">{t("Country")}</FormLabel>
              <Select
                width="auto"
                id="country"
                name="country.code"
                onChange={formikUpdateUser.handleChange}
                value={formikUpdateUser.values?.country?.code ?? ""}
              >
                {COUNTRY_CODES.map(({ name, code }) => (
                  <option value={code}>{name}</option>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl>
                <FormLabel htmlFor="language">{t("Language")}</FormLabel>
                <Input
                  width="auto"
                  id="language"
                  name="language"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.language ?? ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="timezone">{t("Timezone")}</FormLabel>
                <Input
                  width="auto"
                  id="timezone"
                  name="timezone"
                  onChange={formikUpdateUser.handleChange}
                  value={formikUpdateUser.values.timezone ?? ""}
                />
              </FormControl> */}
            <FormControl>
              <FormLabel htmlFor="currency">{t("Currency")}</FormLabel>
              <Select
                width="auto"
                id="currency"
                name="currency"
                onChange={formikUpdateUser.handleChange}
                value={formikUpdateUser.values.currency ?? ""}
              >
                {CURRENCIES.map(({ name, value }) => (
                  <option value={value} selected={formikUpdateUser.values.currency === value}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Divider borderColor="gray.100" />
            <HStack width="full">
              <Button size="sm" isLoading={loading} loadingText="Loading" type="submit">
                {t("Save Changes")}
              </Button>
              <Button size="sm" variant="danger">
                {t("Cancel")}
              </Button>
            </HStack>
          </Stack>
        </FieldGroup>
      </form>
      <form id="updateSignature" onSubmit={formikUpdateSignature.handleSubmit}>
        <FieldGroup title={t("Signature")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack spacing="4" p="8">
            {userSignatureEdges.length > 0 ? (
              userSignatureEdges.map(({ node }, index) => (
                <UserSignatureCard {...node} index={index} refetch={refetch} userId={userId} />
              ))
            ) : (
              <Text>
                <Trans>Once you upload a signature it will be displayed here.</Trans>
              </Text>
            )}
          </HStack>
          <Stack direction="column" spacing="6" align="center" width="full" p="8" rounded="lg">
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BiFile} />
                </InputLeftElement>
                <input
                  type="file"
                  onChange={(e) => {
                    if (!e.currentTarget.files) return;
                    formikUpdateSignature
                      .setFieldValue("signature", e?.currentTarget?.files[0])
                      .then((evt) => console.log(evt))
                      .catch((err) => console.log(err));
                  }}
                  accept="image/*"
                  name="signature"
                  ref={signatureUploadRef}
                  style={{ display: "none" }}
                />
                <Input
                  placeholder="Your file ..."
                  onClick={(): void => {
                    if (!signatureUploadRef || !signatureUploadRef.current) return;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    signatureUploadRef.current.click();
                  }}
                  // onChange={(e) => {}}
                  readOnly
                  value={formikUpdateSignature.values?.signature}
                />
              </InputGroup>
            </FormControl>
            <HStack width="full">
              <Button
                size="sm"
                isLoading={addUserSignatureLoading}
                loadingText="Loading"
                type="submit"
                disabled={userSignatureEdges.length >= 3}
              >
                {t("Upload new signature")}
              </Button>
            </HStack>
          </Stack>
        </FieldGroup>
      </form>
      <form id="updateUser" onSubmit={formikChangePassword.handleSubmit}>
        <FieldGroup title={t("Change Password")} subtitle="Lorem ipsum dolor sit amet">
          <VStack width="full" spacing="6" p="8" rounded="lg">
            {changePasswordError && (
              <Alert status="error">
                <>
                  <AlertIcon />
                  {changePasswordError.message}
                </>
              </Alert>
            )}
            {changePasswordData && (
              <Alert status="success">
                <>
                  <AlertIcon />
                  {t("Your password has been updated")}
                </>
              </Alert>
            )}

            <FormControl isRequired>
              <FormLabel htmlFor="oldPassword">{t("Old Password")}</FormLabel>
              <Input
                width="auto"
                type="password"
                id="oldPassword"
                name="oldPassword"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.oldPassword}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="newPassword">{t("New password")}</FormLabel>
              <Input
                width="auto"
                type="password"
                id="newPassword"
                name="newPassword"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.newPassword}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="confirmPassword">{t("Confirm Password")}</FormLabel>
              <Input
                width="auto"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formikChangePassword.handleChange}
                value={formikChangePassword.values.confirmPassword}
              />
            </FormControl>
            <Divider borderColor="gray.100" />
            <HStack width="full">
              <Button
                size="sm"
                isLoading={changePasswordLoading}
                loadingText="Loading"
                type="submit"
              >
                {t("Save Changes")}
              </Button>
              <Button size="sm" variant="danger">
                {t("Cancel")}
              </Button>
            </HStack>
          </VStack>
        </FieldGroup>
      </form>
      <PermissionsForm
        readonly={isMyself}
        handleSubmit={formikUpdatePermissions.handleSubmit}
        success={!!updateUserPermissionsData}
        permissions={formattedPermissions}
        loading={updateUserPermissionsLoading}
        error={updateUserPermissionsError}
        allowedPermissions={allowedPermissions}
        setAllowedPermissions={setAllowedPermissions}
      />
    </Stack>
  );
};

export default UsersEditPage;
