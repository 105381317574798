import { DetailsPageSkeleton } from "components/ui";
import { useGetCollectionByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditCollectionPage = () => {
  const { collectionId } = useParams();

  const { data, loading, error, refetch } = useGetCollectionByIdQuery({
    variables: {
      id: collectionId
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  return <Page data={data} refetchCollection={refetch} />;
};

export default EditCollectionPage;
