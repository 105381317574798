/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */
import {
    Box,
    Button,
    HStack,
    // IconButton,
    Stack,
    StackDivider,
    VStack,
    Text,
  } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { RegistryAwareField } from 'components/ui';
// import style from "components/ui/Table/scss/index.module.scss"


import ASSET_CONSTANTS from 'constants/asset';
import findLabel from "./FindLabel";



interface AssetPresentValueViewProps {
  presentValue: {
    id: string,
    authorId: string,
    authorName: string,
    estimateDate: string,
    estimateReason: string,
    notes: string,
    fileTitle: string,
    file: string,
    amount: string,
  } | null
}

const PresentValueView = ({ presentValue } : AssetPresentValueViewProps) => {
  if (!presentValue) return null;
    const {
      id,
      authorId,
      authorName,
      estimateDate,
      estimateReason,
      notes,
      fileTitle,
      file,
      amount,
    } = presentValue;


    const { t } = useTranslation();

    return <Box maxW="6xl">
    <Stack spacing="4" divider={<StackDivider />}>
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Author</Trans></Text>
              <RegistryAwareField id={authorId} />
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Amount</Trans></Text>
              <Text fontWeight="600">
                {amount}
                </Text>
            </VStack>

          </HStack>
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Date</Trans></Text>
              <Text fontWeight="600">
                {estimateDate}
                </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>Reason</Trans></Text>
              <Text fontWeight="600">
                {findLabel(ASSET_CONSTANTS.ASSET_VALUES_REASONS, estimateReason)}
                </Text>
            </VStack>
          </HStack>
          <HStack spacing="16" w="full">
            <VStack alignItems="flex-start">
              <Text variant="muted"><Trans>File</Trans></Text>
              <Text fontWeight="600">
                {/* <Button paddingX="6" title="Download" onClick={() => window.open(currentCarryingValueFile)} fontWeight="600"><Trans>Download</Trans></Button> */}
                {fileTitle && file && <Button variant="link" title="Download" onClick={() => window.open(file)}>fileTitle</Button>}
                {!fileTitle && file && <Button variant="link" title="Download" onClick={() => window.open(file)}><Trans>Download</Trans></Button>}
                </Text>
            </VStack>
          </HStack>
          {
              notes &&
              <HStack spacing="16" w="full">
                <Text variant="muted">
                  <Trans>Notes</Trans>
                </Text>
                <Text>
                  {notes}
                </Text>
              </HStack>
          }
    </Stack>
    </Box>


}


export default PresentValueView;