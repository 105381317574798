import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import {
  GetRestorationByIdQuery,
  RestorationInput,
  useUpdateRestorationMutation,
  useCloseRestorationMutation,
  Exact,
  AssetNode,
  useGetAssetByIdLazyQuery,
} from "graphql/queries/generated/queries";
import { ValidationProvider } from "components/form/ValidationContext";
import { RestorationInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  VStack,
  Stack,
  Text,
  HStack,
  ButtonGroup,
  Button,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import BorderedBox from "pages/assets/shared/BorderedBox";
import { ApolloQueryResult } from "@apollo/client";
import { Dot } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { handleMutation, toast } from "middleware/Toaster";
import { MGMT_ROUTES } from "routes/constants";
import RestorationFields from "../shared/RestorationFields";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import NewAssetDrawer from "./NewAssetDrawer";

interface PageProps {
  data: GetRestorationByIdQuery;
  asset?: AssetNode;
  refetchRestoration: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetRestorationByIdQuery>>;
}

const Page = ({ data, asset, refetchRestoration }: PageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (data != null && data.restoration != null && data.restoration.restorationStatus === "closed") {
    console.log("Restoration chiusa, procedo a visualizzazione.");

    toast({
      title: "Only view.",
      description: "This restoration is closed: you will be redirect to the view page.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.RESTORATION}/${data.restoration.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  }

  const preloadedAsset: AssetNode = data.restoration.restorationAsset as AssetNode;

  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [userWantsToCreateAssets, setUserWantsToCreateAssets] = useState<boolean>(false);
  const [choosenAssets, setChoosenAssets] = useState<string[] | []>([]);
  const [assetAdded, setAssetAdded] = useState(false);
  const [choosenAssetBigData, setChoosenAssetBigData] = useState(preloadedAsset);

  /**
   * onCompleteEvent e getAsset funzionano sia per il drawer degli
   * oggetti preesistenti sia per quello della creazione di nuovi
   * oggetti
   */

  const onCompleteEvent = (assetNode: AssetNode) => {
    toast({
      title: "Restoration not saved.",
      description: "Press the 'save' button to finalize the changes.",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });

    setChoosenAssetBigData(assetNode);
    setAssetAdded(true);
  };

  const [getAsset, { loading: loadingAsset, error: errorAsset, data: dataAsset }] =
    useGetAssetByIdLazyQuery({
      onCompleted: (result) => {
        onCompleteEvent(result.asset as AssetNode);
      },
    });

  const addExistingAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const onOpenExistingAssetDrawer = () => {
    setUserWantsToAddAssets(true);
  };

  const onOpenNewAssetDrawer = () => {
    setUserWantsToCreateAssets(true);
  };

  const {
    restoration: { __typename, id, ...restoration },
  } = data;

  const [updateRestoration, { data: updateRestorationData, error, loading }] =
    useUpdateRestorationMutation({
      ...handleMutation("Restoration updated!"),
    });

  const methods = useForm<RestorationInput>({
    defaultValues: {
      restorationStatus: restoration.restorationStatus,
      restorationDirector: restoration.restorationDirector
        ? restoration.restorationDirector.id
        : "",
      restorationCompany: restoration.restorationCompany ? restoration.restorationCompany.id : "",
      restorationAsset: restoration.restorationAsset ? restoration.restorationAsset.id : "",
      restorationRestoredBy: restoration.restorationRestoredBy,
      restorationInventoryNumber: restoration.restorationInventoryNumber,
      restorationStartDate: restoration.restorationStartDate,
      restorationEndDate: restoration.restorationEndDate,
      restorationLocation: restoration.restorationLocation,
      restorationCollocationFeatures: restoration.restorationCollocationFeatures,
      restorationDocumentDate: restoration.restorationDocumentDate,
      conservativeDataHistoryInterventions: restoration.conservativeDataHistoryInterventions,
      conservativeDataConservationStatus: restoration.conservativeDataConservationStatus,
      conservativeDataAssetAlterations: restoration.conservativeDataAssetAlterations,
      conservativeDataDeteriorationCauses: restoration.conservativeDataDeteriorationCauses,
      conservativeDataExaminationMethod: restoration.conservativeDataExaminationMethod,
      conservativeDataInterventionProposal: restoration.conservativeDataInterventionProposal,
      conservativeDataPreliminaryOperations: restoration.conservativeDataPreliminaryOperations,
      conservativeDataIntervention: restoration.conservativeDataIntervention,
      conservativeDataInterventionMaterials: restoration.conservativeDataInterventionMaterials,
      conservativeDataTechnicalMethodologicalConsiderations:
        restoration.conservativeDataTechnicalMethodologicalConsiderations,
      conservativeDataMaintenancePreparationAssembly:
        restoration.conservativeDataMaintenancePreparationAssembly,
      conservativeDataRestorationBibliography: restoration.conservativeDataRestorationBibliography,
      conservativeDataNotesRestorationSheet: restoration.conservativeDataNotesRestorationSheet,
      restorationCost: restoration.restorationCost
        ? {
          amount: restoration.restorationCost.amount,
          currency: restoration.restorationCost.currency.code,
        }
        : {
          amount: 0,
          currency: "EUR",
        },
    },
    resolver: yupResolver(RestorationInputSchema()),
  });

  const addNewAsset = async ({ gqlId }: Partial<SelectedAsset>) => {
    setChoosenAssets((prevState) => [gqlId]);

    await getAsset({
      variables: {
        id: gqlId,
      },
    });
  };

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    console.log(errors);

    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof RestorationInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const [closeRestoration, { loading: closeLoading }] = useCloseRestorationMutation({
    ...handleMutation("Restoration closed!"),
  });

  const onCloseRestoration = async (id: string) => {
    await closeRestoration({
      variables: {
        input: {
          id,
        },
      },
    });

    setTimeout(() => {
      navigate(`${MGMT_ROUTES.RESTORATION}/${data.restoration.id}`);
    }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 100);
  };

  const onSubmit: SubmitHandler<RestorationInput> = async (formData, e) => {
    let selectedRestorationAsset: string = null;
    let payload = null;

    if (assetAdded) {
      selectedRestorationAsset = choosenAssetBigData.id;

      payload = {
        variables: {
          input: {
            id,
            restorationData: {
              ...formData,
              restorationAsset: selectedRestorationAsset,
            },
          },
        },
      };
    } else {
      payload = {
        variables: {
          input: {
            id,
            restorationData: {
              ...formData,
            },
          },
        },
      };
    }

    await updateRestoration(payload);
    
    setAssetAdded(false);
    setChoosenAssets([]);

    await refetchRestoration({ id });

    return null;
  };

  return (
    <Stack spacing="4">
      <VStack alignItems="flex-start" pr={8}>
        <Dot
          positiveLabel={t("Draft")}
          negativeLabel={t("Closed")}
          status={data.restoration.restorationStatus === "draft"}
        />
        {data.restoration.restorationStatus === "draft" && (
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button variant="primary">
                    <Trans>Close</Trans>
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                    <PopoverArrow />
                    <PopoverHeader>{t("You are closing the restoration")}</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>{t("Warning! the operation is not reversible!")}</PopoverBody>

                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pb={4}
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="danger"
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            onCloseRestoration(id);
                            onClose();
                          }}
                        >
                          <Trans>Close it</Trans>
                        </Button>
                        <Button onClick={onClose}>
                          <Trans>Cancel</Trans>
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        )}
      </VStack>

      <FormProvider {...methods}>
        <ValidationProvider schema={RestorationInputSchema()}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing="4">
              <BorderedBox title={t("Asset")} subtitle="Lorem ipsum dolor sit amet">
                <HStack justifyContent="space-between" alignItems="flex-start">
                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>ID</Trans>
                    </Text>
                    <Text>
                      {choosenAssetBigData != null && choosenAssetBigData.id != null
                        ? choosenAssetBigData.id
                        : ""}
                    </Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Title</Trans>
                    </Text>
                    <Text>
                      {choosenAssetBigData != null && choosenAssetBigData.title != null
                        ? choosenAssetBigData.title
                        : ""}
                    </Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Author</Trans>
                    </Text>
                    <Text>
                      {choosenAssetBigData != null && choosenAssetBigData.authorEntity != null
                        ? choosenAssetBigData.authorEntity?.fullName
                        : ""}
                    </Text>
                  </VStack>

                  <VStack alignItems="flex-start" pr={8}>
                    <Text variant="muted">
                      <Trans>Date of creation</Trans>
                    </Text>
                    <Text>
                      {choosenAssetBigData != null &&
                        choosenAssetBigData.actualDateAndPeriod != null &&
                        choosenAssetBigData.actualDateAndPeriod !== ""
                        ? choosenAssetBigData.actualDateAndPeriod
                        : ""}
                    </Text>
                  </VStack>

                  <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                    <Button onClick={onOpenExistingAssetDrawer} variant="primary">
                      <Trans>Choose new object</Trans>
                    </Button>
                    <Button onClick={onOpenNewAssetDrawer} variant="primary">
                      <Trans>Create and add new object</Trans>
                    </Button>
                  </ButtonGroup>
                </HStack>
              </BorderedBox>

              <RestorationFields
                footerData={updateRestorationData}
                loading={loading}
                errors={[error]}
                disabled={data.restoration.restorationStatus !== "draft"}
                watch={methods.watch}
                data={data}
                asset={choosenAssetBigData}
              />
            </Stack>
          </form>
        </ValidationProvider>
      </FormProvider>

      {/**
       * Per lo meno il NewAssetDrawer DEVE stare fuori dal tag <form> (e quindi da ValidationProvider e FormProvider)
       * perche' altrimenti il submit del drawer causa il submit della form esterna.
       * Poi in generale non e' certo una buona idea annidare due form...
       */}
      <SearchAssetsDrawer
        show={userWantsToAddAssets}
        onClose={() => setUserWantsToAddAssets(false)}
        addAsset={addExistingAsset}
        choosenAssets={choosenAssets}
      />
      <NewAssetDrawer
        show={userWantsToCreateAssets}
        onClose={() => setUserWantsToCreateAssets(false)}
        addAsset={addNewAsset}
      />

      {assetAdded && (
        <Alert status="warning">
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="Press the 'save' button to finalize the changes" />
            </Text>
          </VStack>
        </Alert>
      )}
    </Stack>
  );
};

export default Page;
