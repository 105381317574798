import { useToast } from "@chakra-ui/react";
import { FormFooter } from "components/ui";
import {
  PolicyNoteInput,
  PolicyNoteNode,
  useCreatePolicyNoteMutation,
  useUpdatePolicyNoteMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PolicyNotesFields from "../PolicyNotesFields";

interface PolicyNoteFormProps {
  data: Partial<PolicyNoteNode>[];
}

const PolicyNotesForm = ({ data }: PolicyNoteFormProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [
    updatePolicyNote,
    { data: updatePolicyNoteData, loading: updatePolicyNoteLoading, error: updatePolicyNoteError },
  ] = useUpdatePolicyNoteMutation({ ...handleMutation("Note updated!") });
  const [
    createPolicyNote,
    { data: createPolicyNoteData, loading: createPolicyNoteLoading, error: createPolicyNoteError },
  ] = useCreatePolicyNoteMutation({ ...handleMutation("Note created!") });

  const methods = useForm<{ policyNotes: Array<PolicyNoteInput> }>({
    defaultValues: {
      policyNotes: [
        {
          content: data[0]?.content,
        },
      ],
    },
  });

  const onSubmit: SubmitHandler<any> = async (
    formData: { policyNotes: Array<PolicyNoteInput> },
    e
  ) => {
    if (data[0]?.id) {
      await updatePolicyNote({
        variables: {
          input: {
            id: data[0].id,
            policyNoteData: {
              content: formData?.policyNotes[0]?.content as unknown as string,
            },
          },
        },
      });
      return;
    }

    await createPolicyNote({
      variables: {
        input: {
          policyNoteData: { content: formData?.policyNotes[0]?.content as unknown as string },
        },
      },
    });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PolicyNotesFields />
        <FormFooter
          data={[updatePolicyNoteData, createPolicyNoteData]}
          errors={[updatePolicyNoteError, createPolicyNoteError]}
          loading={updatePolicyNoteLoading && createPolicyNoteLoading}
          title="Policy note"
        />
      </form>
    </FormProvider>
  );
};

export default PolicyNotesForm;
