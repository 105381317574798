import {
  Badge,
  Heading,
  HStack,
  VStack,
  Skeleton,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  CopyButton,
  DateDialog,
  QuickActions,
  Field,
  DetailsPageSkeleton,
  NoDataPlaceholder,
  SBox,
  BorderedBox,
} from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import {
  useGetAllAssetsForUserQuery,
  useGetUserByIdQuery,
  useToggleUserIsActiveMutation,
  useToggleUserUserTypeMutation,
} from "graphql/queries/generated/queries";
import { handleMutation } from "middleware/Toaster";
import { USER_ROUTES } from "routes/constants";
import { useAuth } from "contexts/AuthProvider";
import parseDate from "helpers/parseDate";
import { useRef } from "react";
import AssetsAddableToUser from "./AssetsAddableToUser";
import VisibleAssetsTable from "./VisibleAssetsTable";
import CollectionsAddableToUser from "./CollectionsAddableToUser";

const UsersViewPage = () => {
  const {
    isOpen: isAddObjectDrawerOpen,
    onOpen: onAddObjectDrawerOpen,
    onClose: onAddObjectDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isAddCollectionDrawerOpen,
    onOpen: onAddCollectionDrawerOpen,
    onClose: onAddCollectionDrawerClose,
  } = useDisclosure();
  const btnRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useAuth() || {};
  const { t } = useTranslation();
  const { userId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetUserByIdQuery({
    variables: { id: userId ?? "" },
  });

  const {
    data: allAssetForUserData,
    loading: allAssetForUserLoading,
    error: allAssetForUserError,
    refetch: allAssetForUserRefetch,
  } = useGetAllAssetsForUserQuery({
    variables: {
      userId,
    },
  });

  const [
    toggleIsActiveUser,
    {
      data: toggleIsActiveUserData,
      loading: toggleIsActiveUserLoading,
      error: toggleIsActiveUserError,
    },
  ] = useToggleUserIsActiveMutation({ ...handleMutation("User updated!") });

  const [
    toggleUserUserType,
    {
      data: toggleUserUserTypeData,
      loading: toggleUserUserTypeLoading,
      error: toggleUserUserTypeError,
    },
  ] = useToggleUserUserTypeMutation({ ...handleMutation("User updated!") });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.user) return null;

  const {
    user: {
      isActive,
      userType,
      country,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateJoined,
      email,
      language,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      lastLogin,
      name,
      phone,
      userPermissions,
      validFrom,
      expiresAt,
      id,
    },
  } = data;

  const getMaxExpiresAt = (currentExpiresAt: string, userType: string) => {
    let startingDate = new Date();
    if (currentExpiresAt) {
      startingDate = new Date(currentExpiresAt);
    }
    if (userType !== "INTERNAL") {
      const maxExpiresAt = new Date(startingDate.setFullYear(startingDate.getFullYear() + 20));
      return maxExpiresAt.toISOString();
    }

    const maxExpiresAt = new Date(startingDate.setMonth(startingDate.getMonth() + 3));
    return maxExpiresAt.toISOString();
  };

  const onDelete = async () => {
    await toggleIsActiveUser({
      variables: {
        input: {
          id: userId,
        },
      },
    });

    await refetch();
  };

  const onToggleUserType = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    await toggleUserUserType({
      variables: {
        input: {
          id: userId,
          expiration: endDate,
        },
      },
    });

    await refetch();
  };

  const QUICK_ACTIONS = [
    {
      name: userType === "INTERNAL" ? t("Set user as guest") : t("Set user as internal"),
      action: onOpen,
      icon: <RiAddLine />,
      intent: "outline",
      disabled: user.email === email,
    },
    {
      name: isActive ? t("Disable user") : t("Enable user"),
      action: () => onDelete(),
      icon: <RiAddLine />,
      intent: isActive ? "danger" : "outline",
    },
    {
      name: t("Edit user"),
      href: `${USER_ROUTES.USER}/edit/${id}`,
      icon: <BiEdit />,
    },
  ];

  const canSeeUserAssets = user.isSuperuser;
  return (
    <Skeleton isLoaded={!loading} w="full">
      <VStack w="full" align="flex-start" spacing="4">
        <SBox>
          <HStack justifyContent="space-between" alignItems="flex-start">
            <VStack alignItems="flex-start">
              <Heading>{name}</Heading>
              <Text fontSize="lg" variant="muted">
                #{userId ?? ""} <CopyButton value={userId ?? ""} />
              </Text>
            </VStack>
            <QuickActions noTitle actions={QUICK_ACTIONS} />
          </HStack>
        </SBox>
        <BorderedBox title="Details">
          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>User Type</Trans>
              </Text>
              <Badge
                variant="subtle"
                colorScheme={userType === "INTERNAL" ? "green" : "purple"}
                mr={4}
              >
                <Trans>{userType}</Trans>
              </Badge>
            </VStack>
            <Field label="Is active" value={isActive} />
            <Field label="Last login" value={parseDate(dateJoined)} />
          </HStack>

          <HStack w="100%" my={4}>
            <Field label="Email" value={email} />
            {/* <Field label={"Preferred language"} value={language} /> */}
            <Field label="Country" value={country?.code} />
            <Field label="Phone" value={phone} />
          </HStack>
          <HStack w="100%" my={4}>
            <Field label="Valid from" value={validFrom ? new Date(validFrom) : ""} />
            <Field label="Expires at" value={new Date(expiresAt)} />
          </HStack>
        </BorderedBox>

        {canSeeUserAssets && (
          <SBox>
            <VStack spacing={2} alignItems="start">
              <HStack justify="space-between" w="full">
                <Heading size="md">
                  <Trans>Visible assets</Trans>
                </Heading>
                {user.isSuperuser && (
                  <HStack>
                    <Button ref={btnRef} colorScheme="teal" onClick={onAddObjectDrawerOpen}>
                      <Trans>Add objects</Trans>
                    </Button>
                    <Button ref={btnRef} colorScheme="teal" onClick={onAddCollectionDrawerOpen}>
                      <Trans>Add collections</Trans>
                    </Button>
                  </HStack>
                )}
              </HStack>

              <Text variant="muted">
                <Trans>Visible assets subtitle</Trans>
              </Text>
              <VisibleAssetsTable
                userId={userId}
                canRemoveAssets={user.isSuperuser}
                data={allAssetForUserData}
                loading={allAssetForUserLoading}
                error={allAssetForUserError}
                refetch={allAssetForUserRefetch}
              />
            </VStack>
          </SBox>
        )}
        <Modal isOpen={isAddObjectDrawerOpen} onClose={onAddObjectDrawerClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Trans>Add objects to </Trans>
              {user.name}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AssetsAddableToUser userId={userId} refetchVisibleAssets={allAssetForUserRefetch} />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onAddObjectDrawerClose}>
                Close
              </Button>
              <Button variant="ghost">Secondary Action</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isAddCollectionDrawerOpen} onClose={onAddCollectionDrawerClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Trans>Add collections to {user.name}</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CollectionsAddableToUser
                userId={userId}
                refetchVisibleAssets={allAssetForUserRefetch}
              />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onAddCollectionDrawerClose}>
                Close
              </Button>
              <Button variant="ghost">Secondary Action</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <DateDialog
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          maxDate={getMaxExpiresAt(validFrom, userType)}
          loading={toggleUserUserTypeLoading}
          disabledStartDate
          title="Set user as guest"
          message="Set an expiration date to mark user as guest"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={onToggleUserType}
        />
      </VStack>
    </Skeleton>
  );
};

export default UsersViewPage;
