import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";


import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

// import ASSET_CONSTANTS from "constants/asset";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface DiscoveryNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}


const DiscoveryNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: DiscoveryNewCategoryFormProps) => {
  const { t } = useTranslation();
 // const { title } = getValues();


  return (
    <>
        <FieldGroup title={t("Place and date")} subtitle={t("Discovery - Place and date -Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                    <FormInputHook label="Discovery place" name="discoveryPlace" />
                    <FormInputHook label="Discovery site" name="discoverySite" />
                    <FormInputHook label="Discovery date" type="date" name="discoveryDate" />
                </HStack>
                <HStack>
                    <FormInputHook label="Discovery notes" name="discoveryNotes" type="textarea" />
                </HStack>
            </VStack>
        </FieldGroup>

        <FieldGroup title={t("Authors")} subtitle={t("Discovery - Authors - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                    <FormInputHook label="Discovery author" name="discoveryAuthor" />
                    <FormInputHook label="Cataloging author" name="catalogingAuthor" />
                    <FormInputHook label="Setup author" name="objectSetupAuthor" />
                </HStack>
            </VStack>
        </FieldGroup>
    </>
  );
};

export default DiscoveryNewCategoryForm;
