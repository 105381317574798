import {
  Box,
  VStack,
  Stack,
  StackDivider,
  HStack,
  Grid,
  GridItem,
  ButtonGroup,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { useState } from "react";
import {
  useAddRestorationMediaMutation,
  useDeleteRestorationMediaMutation,
  useUpdateMediaMutation,
  useGetMediaRestorationByIdQuery,
  MediaInput,
  MediaNode,
} from "graphql/queries/generated/queries";
import ASSET_CONSTANTS from "constants/asset";
import { yupResolver } from "@hookform/resolvers/yup";
import { MediaInputSchema } from "graphql/queries/generated/validation-schema";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { ValidationProvider } from "components/form/ValidationContext";
import { FormInputHook, Table } from "components/ui";
import clearAssetMediaValues from "pages/assets/utils/ClearAssetMediaValues";
import {
  MEDIA_CUSTOM_FIELDS,
  MEDIA_CUSTOM_COLUMNS,
  MEDIA_HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";

interface AnyObject {
  [key: string]: any;
}

export const findLabelByValue = (value: string, array: { value: string; label: string }[]) => {
  const obj = array.find((x) => x.value === value);
  return obj ? obj.label : "";
};

interface MediaFormProps {
  restorationId: string;
}

const MediaForm = ({ restorationId }: MediaFormProps) => {
  const mediaTypeOnlyMedia = [];

  for (let i = 0; i < ASSET_CONSTANTS.MEDIA_TYPES.length; i += 1)
    if (i !== ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION)
      mediaTypeOnlyMedia.push(ASSET_CONSTANTS.MEDIA_TYPES[i]);

  const { t } = useTranslation();

  const methods = useForm<MediaInput>({
    defaultValues: {
      name: "",
      file: "",
      description: "",
      mediaType: "",
      isMainImage: Boolean(false),
      imageType: "",
      notes: "",
      audioVideoFileType: "",
      creationDate: "",
      fileAuthor: "",
      fileSize: "",
      photographicEquipment: "",
      matrixType: "",
      isGlassPlexyAcetate: Boolean(false),
    },
    resolver: yupResolver(MediaInputSchema()),
    mode: "onChange",
  });

  const { setValue, watch } = methods;

  const { data, loading, error, refetch } = useGetMediaRestorationByIdQuery({
    variables: {
      id: restorationId,
    },
  });

  const [selectedId, setSelectedId] = useState<string>(null);
  const [currentFile, setCurrentFile] = useState<string>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [
    addRestorationMedia,
    {
      data: addRestorationMediaData,
      loading: addRestorationMediaLoading,
      error: addRestorationMediaError,
    },
  ] = useAddRestorationMediaMutation({
    ...handleMutation("Media added!"),
  });
  const [
    updateMedia,
    { data: updateMediaData, loading: updateMediaLoading, error: updateMediaError },
  ] = useUpdateMediaMutation({
    ...handleMutation("Media updated!"),
  });
  const [
    deleteRestorationMedia,
    {
      data: deleteRestorationMediaData,
      error: deleteRestorationMediaError,
      loading: deleteRestorationMediaLoading,
    },
  ] = useDeleteRestorationMediaMutation({
    ...handleMutation("Media deleted!"),
  });

  let dataToGetColumns;
  let columnsMedia;
  const media: MediaNode[] = [];

  if (
    data &&
    data.restoration &&
    data.restoration.media &&
    data.restoration.media.edges &&
    data.restoration.media.edges.length > 0
  ) {
    dataToGetColumns = data.restoration.media.edges[0].node;

    columnsMedia = getColumns({
      data: dataToGetColumns,
      customFields: MEDIA_CUSTOM_FIELDS,
      customColumns: MEDIA_CUSTOM_COLUMNS,
    });

    for (let i = 0; i < data.restoration.media.edges.length; i += 1)
      if (
        data.restoration.media.edges[i].node.mediaType !==
        ASSET_CONSTANTS.MEDIA_TYPES[ASSET_CONSTANTS.MEDIA_TYPE_DOCUMENT_POSITION].value
      )
        media.push(data.restoration.media.edges[i].node as MediaNode);
  }

  const onEditModalOpen = ({ row }: { row: { original: MediaNode } }) => {
    if (
      row &&
      row &&
      row.original &&
      row.original.id &&
      row.original.name &&
      row.original.description &&
      row.original.file
    ) {
      setSelectedId(row.original.id);

      setValue("name", row.original.name);

      setValue("file", row.original.file);

      setValue("description", row.original.description);

      setCurrentFile(row.original.file);

      setValue("mediaType", row.original.mediaType);

      setValue("isMainImage", row.original.isMainImage);

      setValue("imageType", row.original.imageType);

      setValue("notes", row.original.notes);

      setValue("audioVideoFileType", row.original.audioVideoFileType);

      setValue("creationDate", row.original.creationDate);

      setValue("fileAuthor", row.original.fileAuthor);

      setValue("fileSize", row.original.fileSize);

      setValue("photographicEquipment", row.original.photographicEquipment);

      setValue("matrixType", row.original.matrixType);

      setValue("isGlassPlexyAcetate", row.original.isGlassPlexyAcetate);

      onOpen();
    }
  };

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("file", "");
    setValue("description", "");
    setValue("mediaType", "");
    setValue("isMainImage", Boolean(false));
    setValue("imageType", "");
    setValue("notes", "");
    setValue("audioVideoFileType", "");
    setValue("creationDate", null);
    setValue("fileAuthor", "");
    setValue("fileSize", "");
    setValue("photographicEquipment", "");
    setValue("matrixType", "");
    setValue("isGlassPlexyAcetate", Boolean(false));
    setCurrentFile(null);

    onClose();
  };

  const onViewMedia = (row: { original: MediaNode }) => {
    if (row && row.original && row.original.id && row.original.file) window.open(row.original.file);
  };

  const onDelete = async (id: string) => {
    await deleteRestorationMedia({
      variables: {
        input: {
          mediaId: id,
          id: restorationId,
        },
      },
    });
  };

  const onSubmit: SubmitHandler<MediaInput> = async (values: AnyObject, e: any) => {
    const mediaData = clearAssetMediaValues(values);

    let response;

    if (selectedId) {
      response = await updateMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
              mediaType: mediaData.mediaType,
              isMainImage: mediaData.isMainImage,
              imageType: mediaData.imageType,
              notes: mediaData.notes,
              audioVideoFileType: mediaData.audioVideoFileType,
              creationDate:
                mediaData.creationDate === "" ? null : (mediaData.creationDate as string),
              fileAuthor: mediaData.fileAuthor,
              fileSize: mediaData.fileSize,
              photographicEquipment: mediaData.photographicEquipment,
              matrixType: mediaData.matrixType,
              isGlassPlexyAcetate: mediaData.isGlassPlexyAcetate,
            },
            id: selectedId,
          },
        },
      });

      if (
        response.data &&
        response.data.updateMedia &&
        response.data.updateMedia.media &&
        response.data.updateMedia.media.id
      )
        onCloseModal();
    } else {
      response = await addRestorationMedia({
        variables: {
          input: {
            mediaData: {
              name: mediaData.name,
              file: mediaData.file,
              description: mediaData.description,
              mediaType: mediaData.mediaType,
              isMainImage: mediaData.isMainImage,
              imageType: mediaData.imageType,
              notes: mediaData.notes,
              audioVideoFileType: mediaData.audioVideoFileType,
              creationDate:
                mediaData.creationDate === "" ? null : (mediaData.creationDate as string),
              fileAuthor: mediaData.fileAuthor,
              fileSize: mediaData.fileSize,
              photographicEquipment: mediaData.photographicEquipment,
              matrixType: mediaData.matrixType,
              isGlassPlexyAcetate: mediaData.isGlassPlexyAcetate,
            },
            restorationId,
          },
        },
      });

      if (
        response.data &&
        response.data.addRestorationMedia &&
        response.data.addRestorationMedia.restoration
      )
        onCloseModal();

      await refetch();
    }
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const handleSubmit = methods.handleSubmit(onSubmit, onError);
  const mediaType = watch("mediaType");

  return (
    <Stack spacing="4">
      <Table
        hiddenColumns={MEDIA_HIDDEN_FIELDS}
        columns={columnsMedia}
        data={media}
        deleteAction={onDelete}
        editAction={onEditModalOpen}
        viewAction={onViewMedia}
      />

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onOpen} variant="primary">
          <Trans>Add new report media</Trans>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? <Trans>Update report media</Trans> : <Trans>Add new report media</Trans>}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormProvider {...methods}>
              <ValidationProvider schema={MediaInputSchema()}>
                <form id="addRestorationMedia" onSubmit={handleSubmit}>
                  <Box maxW="6xl">
                    <Stack spacing="4" divider={<StackDivider />}>
                      <HStack alignItems="flex-end">
                        <FormInputHook
                          label="Media type"
                          options={mediaTypeOnlyMedia}
                          name="mediaType"
                        />

                        {mediaType && <FormInputHook label="Media name" name="name" />}
                      </HStack>

                      {mediaType && (
                        <HStack alignItems="flex-end">
                          <FormInputHook
                            minW="30rem"
                            label="Description"
                            name="description"
                            type="textarea"
                          />
                        </HStack>
                      )}

                      {mediaType.toString() === "IMAGE" && (
                        <HStack alignItems="flex-end">
                          <VStack>
                            <FormInputHook
                              label="Image type"
                              options={ASSET_CONSTANTS.IMAGE_TYPES}
                              name="imageType"
                            />
                          </VStack>
                          <VStack>
                            <FormInputHook
                              label="Is main image?"
                              name="isMainImage"
                              type="checkbox"
                            />
                          </VStack>
                        </HStack>
                      )}

                      {mediaType.toString() === "AUDIO_VIDEO" && (
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook
                              label="Audio video file type"
                              name="audioVideoFileType"
                            />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook label="File size" name="fileSize" />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook label="Author" name="fileAuthor" />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook label="Creation date" name="creationDate" type="date" />
                          </GridItem>
                        </Grid>
                      )}

                      {mediaType.toString() === "FINGERPRINT" && (
                        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook
                              label="Matrix type"
                              options={ASSET_CONSTANTS.MATRIX_TYPES}
                              name="matrixType"
                            />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <br />
                            <FormInputHook
                              label="Glass/Plexy/Acetate?"
                              name="isGlassPlexyAcetate"
                              type="checkbox"
                            />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <FormInputHook
                              label="Photographic equipment"
                              name="photographicEquipment"
                            />
                          </GridItem>
                          <GridItem colSpan={1} w="100%">
                            <br />
                          </GridItem>
                        </Grid>
                      )}

                      <HStack alignItems="flex-end">
                        {currentFile &&
                          mediaType === "IMAGE" &&
                          typeof currentFile === "string" && (
                            <img
                              alt=""
                              style={{ width: "60px", height: "auto" }}
                              src={currentFile as string}
                            />
                          )}
                        {currentFile && mediaType !== "IMAGE" && typeof currentFile === "string" && (
                          <Button
                            title="Open in new window"
                            onClick={() => window.open(currentFile)}
                            fontWeight="600"
                          >
                            Vedi file
                          </Button>
                        )}

                        {mediaType && <FormInputHook label="File" name="file.0.file" type="file" />}
                      </HStack>

                      {mediaType && (
                        <HStack alignItems="flex-end">
                          <FormInputHook minW="30rem" label="notes" name="notes" type="textarea" />
                        </HStack>
                      )}
                    </Stack>
                  </Box>
                </form>
              </ValidationProvider>
            </FormProvider>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <Button
                variant="primary"
                onClick={handleSubmit}
                mr={1}
                disabled={mediaType.length === 0}
                isLoading={addRestorationMediaLoading || updateMediaLoading}
                loadingText="Loading"
              >
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onCloseModal}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default MediaForm;
