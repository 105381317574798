import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";
import ASSET_CONSTANTS from "constants/asset";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

// interface DimensionsPanelNewCategoryFormProps {
//     handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
//     handleChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
//     handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void
//     values: {
//         netWidth: string
//         netHeight: string
//         netDepth: string
//     }
//     category: string
//     children?: React.ReactNode
//     createAssetData: CreateAssetMutation | null | undefined
//     updateAssetData: UpdateAssetMutation | null | undefined
//     createAssetError: ApolloError | undefined
//     updateAssetError: ApolloError | undefined
//     createAssetLoading: boolean
//     updateAssetLoading: boolean
// }
interface DimensionsPanelNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}

const VOID_OPTION = [{ name: "-- Select --", value: "" }];

const DimensionsPanelNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: DimensionsPanelNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();
  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}

      <FieldGroup title={t("Net dimensions")} subtitle={t("Dimensions - Net dimensions - Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Length unit"
              options={ASSET_CONSTANTS.LENGTH_UNIT}
              name="netLengthUnit"
            />
            <FormInputHook
              label="Height"
              name="netHeight"
              type="number"
              step="0.01"
            />
            <FormInputHook label="Width" name="netWidth" type="number" step="0.01" />
            <FormInputHook label="Depth" name="netDepth" type="number" step="0.01" />
            <FormInputHook
              label="Diameter"
              name="netDiameter"
              type="number"
              step="0.01"
            />
          </HStack>
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Weight unit"
              options={ASSET_CONSTANTS.WEIGHT_UNIT}
              name="netWeightUnit"
            />
            <FormInputHook
              label="Weight"
              name="netWeight"
              type="number"
              step="0.01"
            />
          </HStack>
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Dimensions acquisition"
              options={ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS}
              step="0.01"
              name="netDimensionsAcquisition"
            />
          </HStack>
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Gross dimensions")} subtitle={t("Dimensions - Gross dimensions - Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Length unit"
              options={ASSET_CONSTANTS.LENGTH_UNIT}
              name="grossLengthUnit"
            />
            <FormInputHook
              label="Height"
              name="grossHeight"
              type="number"
              step="0.01"
            />
            <FormInputHook
              label="Width"
              name="grossWidth"
              type="number"
              step="0.01"
            />
            <FormInputHook
              label="Depth"
              name="grossDepth"
              type="number"
              step="0.01"
            />
            <FormInputHook
              label="Diameter"
              name="grossDiameter"
              type="number"
              step="0.01"
            />
          </HStack>
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Weight unit"
              options={ASSET_CONSTANTS.WEIGHT_UNIT}
              name="grossWeightUnit"
              step="0.01"
            />
            <FormInputHook
              label="Weight"
              name="grossWeight"
              type="number"
              step="0.01"
            />
          </HStack>
          <HStack alignItems="flex-end">
            <FormInputHook
              label="Dimensions acquisition"
              options={ASSET_CONSTANTS.DIMENSIONS_ACQUISITIONS}
              step="0.01"
              name="grossDimensionsAcquisition"
            />
          </HStack>
        </VStack>
      </FieldGroup>

      {
            (category === 'INSTALLATION' || category === 'DIGITAL') &&
            <FieldGroup title={t("Audio/Video files dimension")} subtitle={t("Dimensions - Audio/Video files dimension -Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook label="Audio/video length" name="audioVideoLength"  type="number" />
                  <FormInputHook
                    label="Unit"
                    options={ASSET_CONSTANTS.MEDIA_LENGTH_UNIT}
                    name="audioVideoLengthUnit"
                  />


                </HStack>
                <HStack alignItems="flex-end">
                  <FormInputHook label="File size" name="fileSize" type="number" />
                  <FormInputHook
                    label="Unit"
                    options={ASSET_CONSTANTS.FILE_SIZE_UNIT}
                    name="fileSizeUnit"
                  />


                </HStack>
                <HStack alignItems="flex-end">
                  <FormInputHook label="Resolution" name="resolution" type="number" />
                  <FormInputHook
                    label="Unit"
                    options={ASSET_CONSTANTS.RESOLUTION_UNIT}
                    name="resolutionUnit"
                  />


                </HStack>
              </VStack>
            </FieldGroup>
          }

      <FieldGroup title={t("Dimensions Notes")} subtitle={t("Dimensions - Dimensions notes - Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <HStack>
            <FormInputHook label="Dimensions notes" name="dimensionsNotes" type="textarea" />
          </HStack>
        </VStack>
      </FieldGroup>

      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default DimensionsPanelNewCategoryForm;
