/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from "@apollo/client";
import { Badge, Button, HStack, VStack, Stack, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { AssetNode, GetAppraisalByIdQuery } from "graphql/queries/generated/queries";
import { FieldGroup, FormFooter, FormInputHook, MoneyInputHook } from "components/ui";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import DocumentsForm from "./Documents";

export const appraisalMotivationOptions : FormSelectOptionProps[] = [ { value : "sale", label : "Sale" }, { value : "cr", label : "CR" }, { value : "insurance_quote", label : "Insurance Quote" }, { value : "asset_analysis", label : "Asset Analysis" }, { value : "litigation_lawsuit", label : "Litigation/Lawsuit" }]

interface AppraisalProps {
  watch : any,
  asset? : AssetNode,
  data? : GetAppraisalByIdQuery,
  errors: ApolloError[],
  footerData: any | any[],
  loading: boolean,
  disabled?: boolean,
}

const AppraisalFields = ({ watch, asset, data, footerData, loading, errors, disabled }: AppraisalProps) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const goToNextTab = (repData?: GetAppraisalByIdQuery) => {
    setTabIndex((prevIndex) => {
      const limit = repData != null ? 3 : 2;
      return prevIndex < limit && prevIndex >= 0 ? prevIndex + 1 : prevIndex;
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const goToPreviousTab = () => {
    setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Tabs colorScheme="brand" variant="enclosed" mt="4" index={tabIndex} onChange={(index) => setTabIndex(index)} >
        <TabList>
          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>1</Badge>
            <Trans>Appraisal</Trans>
          </Tab>

          <Tab>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>2</Badge>
            <Trans>Value informations</Trans>
          </Tab>

          <Tab isDisabled={data == null}>
            <Badge mr={2} px={2} rounded="full" fontWeight={700}>3</Badge>
            <Trans>Documents</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Appraisal details")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="appraisalEvaluator" label={t("Evaluator")} type="registry" required />
                    <FormInputHook name="appraisalConservationStatus" label={t("Conservation status")} type="textarea" />
                    <FormInputHook name="appraisalMotivation" type="select" label={t("Appraisal motivation")} options={appraisalMotivationOptions} required />
                    <FormInputHook name="appraisalLocation" label={t("Appraisal location")} />
                    <FormInputHook name="appraisalDate" label={t("Date")} type="datetime-local" />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            <Stack spacing="4">
              <FieldGroup title={t("Value")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <VStack alignItems="flex-start">
                    <FormInputHook name="valueInformationAuthorCoefficient" label={t("Author coefficient")} />
                    <FormInputHook type="textarea" name="valueInformationInfo" label={t("Information from previous sales")} />
                    <FormInputHook name="valueInformationQuotesSimilarAssets" label={t("Quotes similar objects")} type="textarea" />
                    <MoneyInputHook name="valueCarryingValue.amount" label="Carrying value" />
                    <MoneyInputHook name="valueEstimatedValue.amount" label="Estimated value" />
                    <FormInputHook name="valueInformationNote" label={t("Value information notes")} />
                  </VStack>
                </VStack>
              </FieldGroup>
            </Stack>
          </TabPanel>

          <TabPanel p={3}>
            {data && data.appraisal && data.appraisal.id &&
              <DocumentsForm appraisalId={data.appraisal.id} />
            }
          </TabPanel>

        </TabPanels>
      </Tabs>
      <FormFooter errors={errors} data={footerData} loading={loading} title="Appraisal entry">
        <HStack width="full" justify="space-between" my="4">
            <HStack>
              {tabIndex > 0 && (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                <Button onClick={() => goToPreviousTab()}>{t("Return to previous step")}</Button>
              )}

              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
              {data == null &&  tabIndex < 1 && <Button onClick={() => goToNextTab(data)}>{t("Proceed to next step")}</Button>}
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
              {data != null &&  tabIndex < 2 && <Button onClick={() => goToNextTab(data)}>{t("Proceed to next step")}</Button>}
              <Button isLoading={loading} loadingText="Loading" type="submit" variant="primary" disabled={disabled} >
                <Trans>Save</Trans>
              </Button>
            </HStack>
        </HStack>
      </FormFooter>
    </>
  );
};

export default AppraisalFields;