import { Button, Text } from "@chakra-ui/react";
// import placeholderPNG from "assets/placeholder.png";
// import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { DropdownFilter } from "components/ui";
import { Money } from "graphql/queries/generated/queries";
import parseAmount from "helpers/parseAmount";
import { Trans } from "react-i18next";
// import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";

// import ASSET_CONSTANTS from 'constants/asset';
// import findLabel from "./FindLabel";

// const document_types = {};
// ASSET_CONSTANTS.ASSET_DOCUMENT_TYPES.forEach(e => {
//     document_types[e.value] = e.label;
// });

export const PRESENT_VALUES_CUSTOM_COLUMNS = [
  // {
  //     Header: "File",
  //     footer: "",
  //     accessor: "file",
  //     Cell: ({ cell: { value } }: { cell: { value: string } }) =>
  //     value ? <Button style={{height: "auto", maxWidth: "120px"}} variant="unstyled" title="Open in new window" onClick={() => window.open(value)} ><img src={value} alt="" /></Button> : <Image src={placeholderPNG} alt="" />,

  //     disableFilters: true,
  // },
  {
    Header: "Estimated Reason",
    Footer: "",
    accessor: "estimateReason",
    // Cell: ({ cell: { value } }: { cell: { value: string } }) => value ? <div> {document_types[value]}
    // </div> : <div>{value}</div>,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
    Filter: DropdownFilter,
    // disableFilters: true,
  },
  {
    Header: "Amount",
    Footer: "",
    accessor: "amount",
    Cell: ({ cell: { value } }: { cell: { value: Money } }) => {
      console.log(value);
      const test = parseAmount(value);
      return test;
    },

    disableFilters: true,
  },
  {
    Header: "Date",
    Footer: "",
    accessor: "estimateDate",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <div>{new Date(value).toLocaleDateString("it-IT")}</div> : "",

    disableFilters: true,
  },
  {
    Header: "Evaluator",
    Footer: "",
    accessor: "author.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,

    disableFilters: true,
  },
  {
    Header: "File",
    footer: "",
    accessor: "file",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Button
          style={{ height: "auto", maxWidth: "120px" }}
          variant="unstyled"
          title="Download"
          onClick={() => window.open(value)}
        >
          <Text fontWeight="400" textDecor="underline">
            Download file
          </Text>
        </Button>
      ) : (
        <Trans>No file was attached</Trans>
      ),

    disableFilters: true,
  },

  // {
  //   Header: "Created by",
  //   Footer: "Created by",
  //   disableFilters: false,
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   Filter: CreatedByFilter,
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   filter: createdByFilterFunc,
  //   accessor: "createdBy.name",
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  // }
];

export const PRESENT_VALUES_CUSTOM_FIELDS = PRESENT_VALUES_CUSTOM_COLUMNS.map(
  ({ accessor }) => accessor.split(".")[0]
);

export const PRESENT_VALUES_HIDDEN_FIELDS = [
  /* "coaType",
    "issuer",
    "recipient", */
  "presentValueId",
  // "author",
  "fileTitle",
  "notes",
  "assetId",
  // "amount",
];
