/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import { AssetDocumentInput } from 'graphql/queries/generated/queries';
import { Trans, useTranslation } from 'react-i18next';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { Table } from "components/ui";
import getColumns from "helpers/getColumns";
import {
  PRESENT_VALUES_CUSTOM_COLUMNS,
  PRESENT_VALUES_CUSTOM_FIELDS,
  PRESENT_VALUES_HIDDEN_FIELDS,
} from './PresentValuesCustomColumns';
// import BorderedBox from "./BorderedBox";

interface AssetPresentValuesTableProps {
  data: any[];
  mode?: "view" | "edit";
  showTitle?: boolean;
  // remove?: UseFieldArrayRemove | ((arg0: number) => void);
  onDelete: (id: string) => any;
  // batchActions?: {
  //   name: string | React.ReactNode;
  //   action: (rows: { original: { id: string } }[]) => void;
  // }[];
  viewAction: (row: any) => any;
  editAction: (row: any) => any;
}



const OnViewAssetPresentValuesTable = ({
  data,
  mode = "edit",
  onDelete,
  viewAction,
  editAction,
  showTitle,
}: AssetPresentValuesTableProps) => {
  const { t } = useTranslation();
  if (!data || data?.length === 0)
    return (

      // <Heading size="md" alignSelf="flex-start">
      // <BorderedBox title={t("Present values")} subtitle="Lorem ipsum">
        <VStack alignItems="flex-start" w="full" pr={8}>
          {showTitle && <Heading size="md" alignSelf="flex-start">
            <Trans>Present values</Trans>
          </Heading>}
          <Text><Trans>No present value has been added yet.</Trans></Text>
        </VStack>
      // </BorderedBox>
      // </Heading>

    );


  const customColumns = PRESENT_VALUES_CUSTOM_COLUMNS;
  const customFields = PRESENT_VALUES_CUSTOM_FIELDS;
  const hiddenFields = PRESENT_VALUES_HIDDEN_FIELDS;


  const columns = getColumns<any>({
    data: data[0],
    customColumns,
    customFields,
  })

  return (
    // <BorderedBox title={t("Present values")} subtitle="Lorem ipsum">
    <VStack alignItems="flex-start" w="full" pr={8}>
        {showTitle && <Heading size="md" alignSelf="flex-start">
            <Trans>Present values</Trans>
          </Heading>}
        <Table
          batchActions={[]}
          hiddenColumns={hiddenFields}
          columns={columns}
          data={data}
          viewAction={viewAction}
          editAction={editAction}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          deleteAction={(id: string) => onDelete(id)}
        />
      </VStack>
    // </BorderedBox>
  );
};


export default OnViewAssetPresentValuesTable;