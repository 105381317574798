/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Heading, HStack, VStack, Skeleton, Text, Image } from "@chakra-ui/react";
import {
  BorderedBox,
  CopyButton,
  QuickActions,
  Field,
  DetailsPageSkeleton,
  SidebarInfo,
  Table,
  NoDataPlaceholder,
} from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import {
  useGetCollectionByIdQuery,
  useDetachAssetFromCollectionMutation,
  useExportCollectionPdfMutation,
  AssetNode,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import {
  CUSTOM_FIELDS,
  CUSTOM_COLUMNS,
  HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";

const CollectionViewPage = () => {
  const { t } = useTranslation();
  const { collectionId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetCollectionByIdQuery({
    variables: { id: collectionId ?? "" },
  });

  const [detachAssetFromCollection, { data: detachAssetData }] =
    useDetachAssetFromCollectionMutation({
      ...handleMutation("Asset removed from collection!"),
    });

  const [
    exportCollectionPdf,
    {
      data: exportCollectionPdfData,
      loading: exportCollectionPdfLoading,
      error: exportCollectionPdfError,
    },
  ] = useExportCollectionPdfMutation({
    ...handleMutation("Collection exported!"),
  });

  const onGeneratePdf = async () => {
    const { data } = await exportCollectionPdf({
      variables: {
        input: {
          collectionId,
        },
      },
    });
    const {
      exportCollectionPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.collection) return null;

  const {
    collection: {
      name,
      description,
      notes,
      image,
      id,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      createdFrom,
    },
  } = data;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Collection"),
      href: `/mgmt/collections/edit/${id}`,
      icon: <BiEdit />,
      disabled: createdFrom === "spin",
    },
    {
      name: t("Export pdf"),
      action: onGeneratePdf,
      loading: exportCollectionPdfLoading,
      icon: <BiExport />,
    },
  ];

  const assets = data.collection.assetSet.edges;

  let dataToGetColumns;
  let columns;

  if (assets && assets.length > 0) {
    dataToGetColumns = assets[0].node;

    columns = getColumns({
      data: dataToGetColumns,
      customFields: CUSTOM_FIELDS,
      customColumns: CUSTOM_COLUMNS,
    });
  }

  const onDetachAssetFromCollection = async (assetId: string) => {
    await detachAssetFromCollection({
      variables: {
        input: {
          id,
          assetId,
        },
      },
    });

    return "";
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Remove from collection</Trans>,
      action: async (selectedRows: { original: AssetNode }[]) => {
        selectedRows.map(async ({ original: { id } }) => {
          await onDetachAssetFromCollection(id);
          return null;
        });

        await refetch();
      },
    },
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          <Heading>{name}</Heading>
          <Text fontSize="lg" variant="muted">
            #{collectionId ?? ""} <CopyButton value={collectionId ?? ""} />
          </Text>
        </VStack>

        <QuickActions noTitle actions={QUICK_ACTIONS} />
      </HStack>

      <VStack spacing="6" align="flex-start" w="full">
        <HStack flex="1" w="full" spacing="6" align="flex-start">
          <VStack flex="3" direction="column" spacing="6">
            <BorderedBox title="Collections Details" subtitle="Lorem ipsum dolor sit amet">
              <Field label={t("Description")} value={description} />
              <Field label={t("Notes")} value={notes} />
            </BorderedBox>

            <BorderedBox title="Objects" subtitle="Lorem ipsum dolor sit amet">
              <Table
                hiddenColumns={HIDDEN_FIELDS}
                columns={columns}
                data={assets.flatMap((edge) => edge?.node)}
                deleteAction={onDetachAssetFromCollection}
              />
            </BorderedBox>

            <BorderedBox title="Image" subtitle="Lorem ipsum dolor sit amet">
              <Image src={image} boxSize="300px" />
            </BorderedBox>
          </VStack>
          <SidebarInfo
            createdBy={createdBy}
            updatedBy={updatedBy}
            createdAt={createdAt}
            updatedAt={updatedAt}
          />
        </HStack>
      </VStack>
    </Skeleton>
  );
};

export default CollectionViewPage;
