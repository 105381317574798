import { HStack, Text } from "@chakra-ui/react";
import countryFlags from "helpers/countryFlags";
import { useCallback } from "react";

const CountryCell =
  // eslint-disable-next-line react/no-unused-prop-types
  ({ cell: { value } }: { cell: { value: string } }) => {
    if (!value) return <span>Missing country</span>;

    const findFlag = useCallback(
      (value: string) => countryFlags.find(({ code }) => value === code),
      [value]
    );
    const foundFlag = findFlag(value);

    if (!foundFlag) return <span>Missing country</span>;

    return (
      <HStack spacing={2} align="end">
        <div>{foundFlag.emoji}</div>
        <Text fontWeight="semibold">{foundFlag.code}</Text>
        <Text fontWeight="normal">(&nbsp;{foundFlag.name}&nbsp;)</Text>
      </HStack>
    );
  };

export default CountryCell;
