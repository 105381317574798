import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import {
  NoDataPlaceholder,
  QuickActions,
  Table,
  CreatedByFilter,
  TablePageSkeleton,
  DropdownFilter,
  // RegistryAwareField,
} from "components/ui";


import {
  useGetAllExhibitionsQuery,
  useDeleteExhibitionMutation,
  ExhibitionNode,
  useExportExhibitionPdfMutation,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { Trans, useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
const QUICK_ACTIONS = [
  {
    name: "Add new exhibition entry",
    href: "new",
    icon: <RiAddLine />,
  },
];
const CUSTOM_FIELDS = [
  "createdBy",
  "totalAssetsNumber",
  "eventLocation",
  "actualTimingStatus",
  "eventFrom",
  "eventTo",
  "permanent",
  "eventLocationEntity"
];
const HIDDEN_FIELDS = ["description", "notes", 'eventLocation'];
const CUSTOM_COLUMNS = [
  {
    Header: "Location",
    Footer: "Location",
    accessor: "eventLocationEntity.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "From",
    Footer: "",
    accessor: "eventFrom",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "To",
    Footer: "",
    accessor: "eventTo",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "Total Exhibition Objects.",
    Footer: "Total asset N.",
    accessor: "totalAssetsNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 0,
  },
  {
    Header: "Created By",
    Footer: "Created By",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
  // TODO: REMOVE VISIBLE COLUMN LEAVE ONLY FILTER
  {
    Header: "",
    accessor: "actualTimingStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div />,
    weight: 0,
  },
];
const ExhibitionPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllExhibitionsQuery();

  const [deleteExhibition, { data: deleteData, error: deleteError, loading: deleteLoading }] =
    useDeleteExhibitionMutation({
      ...handleMutation("Exhibition deleted!"),
    });

  const [
    exportExhibition,
    { data: exportExhibitionData, error: exportExhibitionError, loading: exportExhibitionLoading },
  ] = useExportExhibitionPdfMutation({
    ...handleMutation("Exhibition exported!"),
  });

  if (loading) return <TablePageSkeleton loading={loading} />;

  if (!data || !data.allExhibitions || data.allExhibitions.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allExhibitions: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;
  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await deleteExhibition({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onGeneratePdf = async (exhibitionId: string) => {
    const { data } = await exportExhibition({
      variables: {
        input: {
          exhibitionId,
        },
      },
    });

    const {
      exportExhibitionPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete exhibition(s)</Trans>,
      intent: "danger",
      action: async (selectedRows: { original: ExhibitionNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onDelete(original.id);
            return null;
          })
        );
        await refetch();
      },
      loading: deleteLoading,
    },
    {
      name: <Trans>Export PDF</Trans>,
      action: async (selectedRows: { original: ExhibitionNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await onGeneratePdf(original.id);
            return null;
          })
        );
      },
      loading: exportExhibitionLoading,
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          hiddenColumns={HIDDEN_FIELDS}
          columns={columns}
          data={data.allExhibitions.edges.flatMap((edge) => edge?.node)}
          pathname="/mgmt/exhibitions/"
          deleteAction={(id: string) => onDelete(id)}
        />
      </Skeleton>
    </Box>
  );
};

export default ExhibitionPage;
function toast(arg0: {
  title: string;
  description: string;
  status: string;
  duration: number;
  isClosable: boolean;
}) {
  throw new Error("Function not implemented.");
}
