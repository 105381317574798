/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useRef } from "react";
import Fallback from "components/ui/Fallback";
import {
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
  RiMoreFill,
  RiFilterOffFill,
} from "react-icons/ri";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import {
  Box,
  Badge,
  IconButton,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverFooter,
  PopoverBody,
  useDisclosure,
  TableContainer,
  Skeleton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Pagination } from "./Pagination";
import styles from "./scss/index.module.scss";
import { Link, router, useNavigate } from "react-router-dom";
import { GlobalFilter, fuzzyTextFilterFn, DefaultColumnFilter } from "./GlobalFilter";
import TableCSVDownload from "./ExportCsv";
import { BiChevronDown, BiTime } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import classNames from "classnames";
import { RiRefreshLine } from "react-icons/ri";
import { renderCustomActions } from "./renderCustomActions";
import * as Sentry from "@sentry/react";

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (!resolvedRef || !resolvedRef?.current) return;
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const Table = ({
  loading = false,
  hiddenColumns = [],
  pathname = null,
  data,
  columns,
  viewAction = null,
  canView = true,
  editAction = null,
  canEdit = true,
  deleteAction = null,
  deleteActionLoading = false,
  canDelete = true,
  batchActions = [],
  renderRowSubComponent = null,
  debugActions = true,
  refetch = null,
  isLoading = false,
  customActions = [],
}) => {
  if (!data) return null;
  if (!columns) return null;
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  // console.log("filterTypes:", filterTypes)
  // generate all three actionable columns, filter out null values based on action existence
  const columnsWithActions = [
    debugActions &&
    renderCustomActions({
      pathname,
      viewAction,
      editAction,
      deleteAction,
      renderRowSubComponent,
      customActions,
    }),
  ].filter((e) => e);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const instance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Box pr="1" py="2">
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </Box>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <Box pr="1" py="2">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </Box>
          ),
          disableSortBy: true,
        },
        // {
        //   Header: "ID",
        //   Footer: "ID",
        //   accessor: "id",
        //   Cell: ({ row: { original } }) => <Badge>{original?.id.slice(0, 5)}...</Badge>,
        // },
        ...columns,
        ...columnsWithActions,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    selectedFlatRows,
    setFilter,
    setAllFilters,
    state: { pageIndex, pageSize, globalFilter, filters: selectedFilters, expanded },
  } = instance;
  useEffect(() => {
    headerGroups.map((headerGroup) =>
      headerGroup.headers.map((column) => {
        const columnFilter = column.canFilter
          ? column.render("Filter", {
            column,
            title: column.id,
          })
          : null;

        setFilters((prevState) => [...prevState, columnFilter]);
      })
    );
  }, []);
  // console.log("preGlobalFilteredRows", preGlobalFilteredRows)
  // console.log("globalFilter", globalFilter)
  // console.log("setGlobalFilter", setGlobalFilter)

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Fallback error={error} componentStack={componentStack} resetError={resetError} />
      )}
      showDialog
    >
      <Box flex="1" w="100%">
        <HStack justifyContent={"space-between"}>
          <HStack justifyContent={"flex-start"} spacing="4">
            Filters: {filters}
            <Box py={4} pr="8" justifyContent="flex-end">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Box>
          </HStack>

          <HStack pl="4">
            {selectedFilters && selectedFilters.length > 0 && (
              <Button
                onClick={() => setAllFilters([])}
                leftIcon={<RiFilterOffFill />}
                rightIcon={
                  <Badge bgColor="black !important" color="white" rounded="full" fontWeight="bold">
                    {selectedFilters.length}
                  </Badge>
                }
              >
                <Trans>Reset filters</Trans>
              </Button>
            )}
            <Box py={4} justifyContent="flex-end">
              <TableCSVDownload data={rows} columns={columns} selectedFlatRows={selectedFlatRows} />
            </Box>
            {batchActions && batchActions.length > 0 && (
              <Box py={4} justifyContent="flex-end">
                <Menu>
                  {batchActions.some(({ loading }) => loading) ? (
                    <MenuButton size="sm" as={Button} rightIcon={<BiChevronDown />} disabled>
                      <HStack align="center">
                        <Spinner size="sm" />

                        <Text>
                          <Trans>Actions</Trans>
                        </Text>
                      </HStack>
                    </MenuButton>
                  ) : (
                    <MenuButton size="sm" as={Button} rightIcon={<BiChevronDown />}>
                      <HStack align="center">
                        <Text>
                          <Trans>Actions</Trans>
                        </Text>
                      </HStack>
                    </MenuButton>
                  )}

                  <MenuList>
                    {batchActions.map(({ name, action, intent }) => {
                      return (
                        <MenuItem
                          key={name}
                          onClick={() => action(selectedFlatRows)}
                          variant={intent}
                        >
                          <Text variant={intent}>{name}</Text>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
            )}
            {refetch && <IconButton icon={<RiRefreshLine />} onClick={() => refetch} />}
          </HStack>
        </HStack>
        <Skeleton isLoaded={!isLoading}>
          <TableContainer>
            <ChakraTable {...getTableProps()} size={"sm"}>
              <Thead borderTop="1px" borderColor="gray.100">
                {headerGroups.map((headerGroup, index) => (
                  <>
                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, i) => {
                        return (
                          <>
                            <Th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              width="min-content"
                              key={i}
                            >
                              {/* <div>
                          {column.canFilter &&
                            filters.push(
                              column.render("Filter", {
                                column,
                                title: <Trans>Only {column.id}</Trans>,
                              })
                            )}
                        </div> */}
                              <p style={{ display: "inline-flex", alignItems: "center" }}>
                                <Trans>{column.render("Header")}</Trans>
                                <Box ml="1">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <FaSortDown />
                                    ) : (
                                      <FaSortUp />
                                    )
                                  ) : (
                                    <FaSort />
                                  )}
                                </Box>
                              </p>
                            </Th>
                          </>
                        );
                      })}
                    </Tr>
                  </>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <>
                      <Tr
                        {...row.getRowProps()}
                        className={classNames(styles["hoverable"], {
                          [[styles.striped]]: i % 2 === 0,
                        })}
                        bg={row.isExpanded ? "brand.100" : "inherit"}
                        key={i}
                        tabIndex={0}
                        data-id={row?.original?.id}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <Td
                              {...cell.getCellProps()}
                              py={1}
                              color="gray.700"
                              width="min-content"
                              key={index}
                            >
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                      {row.isExpanded ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            <Box bg="gray.100">
                              {renderRowSubComponent({
                                remove: deleteAction,
                                index: row.id,
                                type: row.policyType,
                                data: row.original,
                                t: t,
                                getToggleRowExpandedProps: row?.getToggleRowExpandedProps,
                              })}
                            </Box>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              </Tbody>
            </ChakraTable>
          </TableContainer>
        </Skeleton>
      </Box>

      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Sentry.ErrorBoundary>
  );
};

export default Table;
