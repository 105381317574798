import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface TechnicalDetailsNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
}


const TechnicalDetailsNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  children,
}: TechnicalDetailsNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isArtistProof = watch("isArtistProof");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isEdition = watch("isEdition");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isArtMultiple = watch("isArtMultiple");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isSignedObject = watch("isSignedObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isTitledObject = watch("isTitledObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isDatedObject = watch("isDatedObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasLabelsStamps = watch("hasLabelsStamps");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasOtherAutographed = watch("hasOtherAutographed");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isDynamic = watch("isDynamic");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasHumanAnimalPresence = watch("hasHumanAnimalPresence");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isAudio = watch("isAudio");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isVideo = watch("isVideo");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isMaster = watch("isMaster");


  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}


          {
            category !== 'NATURALISTIC_GOODS' && category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' && category !== 'BOOKS' &&
            <FieldGroup title={t("Artist proof")} subtitle={t("Technical details - Artist proof - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                <FormInputHook label="Is artist proof?" type="checkbox" name="isArtistProof" />

                {isArtistProof && <FormInputHook label="Artist proof notes" name="artistProofNotes" />}
              </HStack>
              </VStack>
            </FieldGroup>
          }

          {
            category === 'BOOKS' &&
            <FieldGroup title={t("Draft")} subtitle={t("Technical details - Draft - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                <FormInputHook label="Is first draft?" type="checkbox" name="isFirstDraft" />

                {isEdition && <FormInputHook label="First draft notes"  name="firstDraftNotes" />}
              </HStack>
              </VStack>
            </FieldGroup>

          }

          {
            ((category === 'PRINT_OR_PHOTO') || (category === 'DIGITAL') || (category === 'BOOKS')) &&
            <FieldGroup title={t("Edition")} subtitle={t("Technical details - Edition - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                <FormInputHook label="Is edition?" type="checkbox" name="isEdition" />

                {isEdition && <FormInputHook label="Edition notes" name="editionNotes" />}
              </HStack>
              </VStack>
            </FieldGroup>

          }

          {
            category === 'DIGITAL' &&
            <FieldGroup title={t("Master")} subtitle={t("Technical details - Master - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                <FormInputHook label="Is master?" type="checkbox" name="isMaster" />
                {isMaster && <FormInputHook label="Master location"  name="masterLocation" />}
              </HStack>
              </VStack>
            </FieldGroup>

          }


          {
            category !== 'NATURALISTIC_GOODS' && category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' && category !== 'BOOKS' &&
              <FieldGroup title={t("Multiple")} subtitle={t("Technical details - Multiple - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <HStack>
                    <FormInputHook label="Is multiple?" type="checkbox" name="isArtMultiple" />

                    {isArtMultiple && (
                      <FormInputHook label="Multiple number"  name="multipleNumber" />
                    )}
                  </HStack>
                </VStack>
              </FieldGroup>
          }

          {
            category !== 'NATURALISTIC_GOODS' && category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
              <FieldGroup title={t("Sign")} subtitle={t("Technical details - Sign - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <HStack>
                    <FormInputHook label="Is signed?" type="checkbox" name="isSignedObject" />

                    {isSignedObject && (
                      <FormInputHook label="Sign notes" name="signedObjectNotes" />
                    )}
                  </HStack>
                </VStack>
              </FieldGroup>
          }

          {
            category !== 'NATURALISTIC_GOODS' && category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
              <FieldGroup title={t("Title")} subtitle={t("Technical details - Title - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <HStack>
                    <FormInputHook label="Is titled?" type="checkbox" name="isTitledObject" />

                    {isTitledObject && (
                      <FormInputHook label="Title notes" name="titledObjectNotes" />
                    )}
                  </HStack>
                </VStack>
              </FieldGroup>
          }

          {
            category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <FieldGroup title={t("Date")} subtitle={t("Technical details - Date - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook label="Is dated?" type="checkbox" name="isDatedObject" />

                  {isDatedObject && <FormInputHook label="Date notes"  name="datedObjectNotes" />}
                </HStack>
              </VStack>
            </FieldGroup>
          }

          {
            category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <FieldGroup title={t("Labels & Stamps")} subtitle={t("Technical details - Labels & Stamps - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook label="Labels and Stamps" type="checkbox" name="hasLabelsStamps" />

                  {hasLabelsStamps && (
                    <FormInputHook label="Label and Stamps notes" name="labelsStampsNotes" />
                  )}
                </HStack>
              </VStack>
            </FieldGroup>
          }


          {
            category !== 'NUMISMATIC_COINS'  && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <FieldGroup title={t("Autographs")} subtitle={t("Technical details - Autographs - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label="Has other autographed occurrencies"
                    type="checkbox"
                    name="hasOtherAutographed"
                  />

                  {hasOtherAutographed && (
                    <FormInputHook label="Autographed occurrencies notes" name="otherAutographedNotes" />
                  )}
                </HStack>
              </VStack>
            </FieldGroup>
          }


          {
            category !== 'NUMISMATIC_COINS' && category !== 'NUMISMATIC_BANKNOTES' && category !== 'PHILATELY' &&
            <FieldGroup title={t("Awards and acknowledgments")} subtitle={t("Technical details - Awards and acknowledgments - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Awards and acknowledgments"
                    name="relevantAwards"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>
          }

          {
            category === 'OTHER' &&
            <FieldGroup title={t("Other details")} subtitle={t("Technical details - Other details - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook label="Mechanism/engine" name="mechanism" />
                  <FormInputHook label="Carats" name="carat" />

                </HStack>
              </VStack>
            </FieldGroup>
          }

          {
            category === 'INSTALLATION' &&
            <FieldGroup title={t("Dynamic")} subtitle={t("Technical details - Dynamic - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Is dynamic installation"
                  type="checkbox"
                  name="isDynamic"
                />

                {isDynamic && (
                  <FormInputHook label="Dynamic inst. notes" name="dynamicNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>
          }

          {
            category === 'INSTALLATION' &&
            <FieldGroup title={t("Human/animal presence")} subtitle={t("Technical details - Human/animal presence - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Has human/animal presence"
                  type="checkbox"
                  name="hasHumanAnimalPresence"
                />

                {
                  hasHumanAnimalPresence && (
                  <FormInputHook label="Human/animal presence notes"  name="humanAnimalPresenceNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>
          }

          {
            ((category === 'INSTALLATION') || (category === 'DIGITAL')) &&
            <FieldGroup title={t("Audio/Video")} subtitle={t("Technical details - Audio/Video - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <VStack>
                  <FormInputHook
                    label="Is audio"
                    type="checkbox"
                    name="isAudio"
                  />
                  <FormInputHook
                    label="Is video"
                    type="checkbox"
                    name="isVideo"
                  />
                </VStack>


                {
                  (isAudio || isVideo) && (
                    <FormInputHook
                      label="Has audio/video loop"
                      type="checkbox"
                      name="hasLoop"
                    />
                )}
              </HStack>
            </VStack>
          </FieldGroup>
          }

          {
            category === 'DIGITAL' &&
            <FieldGroup title={t("Coloring")} subtitle={t("Technical details - Coloring - Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack alignItems="flex-end">
                <FormInputHook
                  label="Coloring"
                  name="coloring"
                />
              </HStack>
            </VStack>
          </FieldGroup>

          }



          {
            category === 'NUMISMATIC_COINS' &&
            <>
              <FieldGroup title={t("Variants and values")} subtitle={t("Technical details - NUMISMATIC_COINS - Variants and values - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Variants" name="variants"/>
                  <FormInputHook step="0.01" label="Nominal value" name="nominalValue" type="number" />
                  <FormInputHook step="0.01" label="Fiscal value" name="fiscalValue" type="number" />
                  <HStack alignItems="flex-end">
                    <FormInputHook step="0.01" label="Current value" name="currentValue" type="number" />
                    <FormInputHook  placeholder="Currency" name="currency" />
                  </HStack>
                  <FormInputHook label="Score" name="score" />
                  <FormInputHook label="Circulation" name="circulation" />
                </VStack>
              </FieldGroup>
              <FieldGroup title={t("Minting details")} subtitle={t("Technical details - Minting details - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Minting/Manufactoring techniques" name="manufactoringTechniques"/>
                  <FormInputHook label="Minting last date" name="mintingLastDate" type="date" />
                  <FormInputHook label="Minting last date notes" name="mintingLastDateNotes" type="textarea" />
                  <FormInputHook label="Reminting" name="reminting" />
                </VStack>
              </FieldGroup>
            </>
          }

          {
            category === 'NUMISMATIC_BANKNOTES' &&
            <>
              <FieldGroup title={t("Variants and values")} subtitle={t("Technical details - NUMISMATIC_BANKNOTES - Variants and values - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Variants" name="variants"/>
                  <FormInputHook step="0.01" label="Nominal value" name="nominalValue" type="number" />
                  <FormInputHook step="0.01" label="Fiscal value" name="fiscalValue" type="number" />
                  <HStack alignItems="flex-end">
                    <FormInputHook step="0.01" label="Current value" name="currentValue" type="number" />
                    <FormInputHook  placeholder="Currency" name="currency" />
                  </HStack>
                  <FormInputHook label="Score" name="score" />
                  <FormInputHook label="Circulation" name="circulation" />
                </VStack>
              </FieldGroup>
              <FieldGroup title={t("Printing details")} subtitle={t("Technical details - NUMISMATIC_BANKNOTES - Printing details - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Printing/Manufactoring techniques" name="manufactoringTechniques"/>
                  <FormInputHook label="Printing last date" name="printingLastDate" type="date" />
                  <FormInputHook label="Printing last date notes" name="printingLastDateNotes" type="textarea" />
                  <FormInputHook label="Reprinting" name="reprinting" />
                </VStack>
              </FieldGroup>
            </>
          }
          

          {
            category === 'PHILATELY' &&
            <>
              <FieldGroup title={t("Variants and values")} subtitle={t("Technical details - PHILATELY - Variants and values - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Variants" name="variants"/>
                  <FormInputHook step="0.01" label="Nominal value" name="nominalValue" type="number" />
                  <HStack alignItems="flex-end">
                    <FormInputHook step="0.01" label="Current value" name="currentValue" type="number" />
                    <FormInputHook  placeholder="Currency" name="currency" />
                  </HStack>
                  <FormInputHook label="Circulation" name="circulation" />
                </VStack>
              </FieldGroup>
              <FieldGroup title={t("Printing details")} subtitle={t("Technical details - PHILATELY - Printing details - Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                  <FormInputHook label="Printing last date" name="printingLastDate" type="date" />
                  <FormInputHook label="Printing last date notes" name="printingLastDateNotes" type="textarea" />
                </VStack>
              </FieldGroup>
            </>
          }




    </>
  );
};

export default TechnicalDetailsNewCategoryForm;
