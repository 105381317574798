/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  HStack,
  VStack,
  Text,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  // StatHelpText,
} from "@chakra-ui/react";

import { Trans, useTranslation } from "react-i18next";
import { useDeleteAssetCarryingValueFileMutation } from "graphql/queries/generated/queries";
import { Field } from "components/ui";
import ASSET_CONSTANTS from "constants/asset";
import parseAmount from "helpers/parseAmount";
import { handleMutation } from "middleware/Toaster";
import findLabel from "./FindLabel";

import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const CarryingValueView = ({ data }: AnyObject) => {
  const {
    assetId,
    carryingValueAmountCurrency,
    carryingValueAcquisition,
    carryingValueAmount,
    carryingValueFile,
    carryingValueFileTitle,
    carryingValueLastAcquisitionDate,
    carryingValueNotes,
    carryingValueOrigin,
  } = data;

  const { t } = useTranslation();

  const [
    deleteAssetCarryingValueFile,
    {
      data: deleteAssetCarryingValueFileData,
      error: AssetCarryingValueFileError,
      loading: AssetCarryingValueFileLoading,
    },
  ] = useDeleteAssetCarryingValueFileMutation({
    ...handleMutation("Asset's carrying value file deleted!"),
  });

  const onDelete = async (id: string) => {
    await deleteAssetCarryingValueFile({
      variables: {
        input: {
          assetId,
        },
      },
    });
  };

  return (
    <BorderedBox title={t("Carrying value")} subtitle="Lorem ipsum">
      <HStack justify="flex-start" spacing="12" w="full">
        <Box>
          <Stat>
            <StatLabel>
              <Trans>Value</Trans>
            </StatLabel>
            <StatNumber>
              <>{parseAmount(carryingValueAmount)}</>
            </StatNumber>
            {/* <StatHelpText>
              <Trans>Helper text for total insured premium</Trans>
            </StatHelpText> */}
          </Stat>
        </Box>
        <Field
          label="Date of last acquisition (yyyy-mm-dd)"
          value={carryingValueLastAcquisitionDate}
        />
      </HStack>
      <HStack justify="flex-start" spacing="12" w="full">
        <Field label="Asset value origin" value={carryingValueOrigin} />

        <VStack alignItems="flex-start" pr={8}>
          <Text fontStyle="normal" variant="muted">
            <Trans>Acquisition</Trans>
          </Text>
          {carryingValueAcquisition && (
            <Text>
              {findLabel(
                ASSET_CONSTANTS.ASSET_VALUES_ACQUISITIONS,
                carryingValueAcquisition.toString()
              )}
            </Text>
          )}
          {!carryingValueAcquisition && (
            <Text fontStyle="italic" fontWeight="400" color="gray.300">
              <Trans>Value not found</Trans>
            </Text>
          )}
        </VStack>
        {/* <Field
          label="Acquisition"
          value={findLabel(
            ASSET_CONSTANTS.ASSET_VALUES_ACQUISITIONS,
            carryingValueAcquisition.toString()
          )}
        /> */}
      </HStack>
      {/* file caricato */}

      {carryingValueFile && (
        <HStack justify="flex-start" spacing="12" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>File</Trans>:
            </Text>
            <HStack>
              {carryingValueFile && (
                <>
                  <Text>{carryingValueFileTitle}</Text>
                  <Button onClick={() => window.open(carryingValueFile)}>
                    <Text fontWeight="600">
                      <Trans>Download</Trans>
                    </Text>
                  </Button>
                </>
              )}
              {!carryingValueFile && (
                <Text>
                  <Trans>No files have been uploaded yet!</Trans>
                </Text>
              )}
            </HStack>
          </VStack>
        </HStack>
      )}
      <Field label="Carrying value notes" value={carryingValueNotes} />
      {/* {carryingValueNotes && (
        <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Carrying value notes</Trans>:
          </Text>
          <Text>{carryingValueNotes}</Text>
        </VStack>
      )} */}
    </BorderedBox>
  );
};

export default CarryingValueView;
