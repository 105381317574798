/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Field } from "components/ui";
import ASSET_CONSTANTS from "constants/asset";
import findLabel from "./FindLabel";

import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const EnvironmentalConditionsView = ({ data }: AnyObject) => {
  const {
    optimalLightLevelIntensity,
    lightUnit,
    optimalTemperature,
    temperatureUnit,
    optimalHumidity,
    enviromentalConditionsNotes,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Environmental conditions")} subtitle="Lorem ipsum">
      <HStack spacing="16" w="full">

          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Optimal light</Trans>
              </Text>
              {optimalLightLevelIntensity && <Text>
                  {`${optimalLightLevelIntensity} ${findLabel(
                    ASSET_CONSTANTS.LIGHT_UNIT,
                    lightUnit.toString()
                  )}`}
              </Text>}
              {
              !optimalLightLevelIntensity && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                <Trans>Value not found</Trans>
              </Text>
              }
            </VStack>
          </HStack>


          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Optimal temperature</Trans>
              </Text>
                {optimalTemperature && <Text>
                  {`${optimalTemperature} ${findLabel(
                    ASSET_CONSTANTS.TEMPERATURE_UNIT,
                    temperatureUnit.toString()
                  )}`}
                </Text>}
                {
              !optimalTemperature && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                <Trans>Value not found</Trans>
              </Text>
              }
            </VStack>
          </HStack>


          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text fontStyle="normal" variant="muted">
                <Trans>Optimal humidity</Trans>
              </Text>
              {optimalHumidity && <Text>{`${optimalHumidity} %`}</Text>}
              {
              !optimalHumidity && <Text fontStyle="italic" fontWeight="400" color="gray.300">
                <Trans>Value not found</Trans>
              </Text>
              }
            </VStack>
          </HStack>

      </HStack>
      <Field label="Environmental conditions notes" value={enviromentalConditionsNotes} />

    </BorderedBox>
  );
};

export default EnvironmentalConditionsView;
