import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  VStack,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";

import React, { useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import RangePicker from "../RangePicker";

interface DateDialogProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  onSubmit: ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => void | Promise<void>;
  title?: string;
  message?: string;
  onlyEndDate?: boolean;
  defaultStartDate?: string;
  defaultEndDate?: string;
  disabledStartDate?: boolean;
  disabledEndDate?: boolean;
  minDate?: string | boolean;
  maxDate?: string | boolean;
  loading?: boolean;
  children?: React.ReactNode;
  extraDefaultValue?: Record<string, string | number | Record<string, string | number>>;
}

const DateDialog = ({
  onOpen,
  onClose,
  isOpen,
  onSubmit,
  title = "",
  message = "",
  onlyEndDate = true,
  defaultStartDate,
  defaultEndDate,
  minDate,
  maxDate,
  loading = false,
  disabledStartDate,
  disabledEndDate,
  children,
  extraDefaultValue,
}: DateDialogProps) => {
  const [startDate, setStartDate] = useState<string | null>(defaultStartDate ?? null);
  const [endDate, setEndDate] = useState<string | null>(defaultEndDate ?? null);
  const cancelRef = useRef();

  const methods = useForm({
    defaultValues: {
      startDate,
      endDate,
      ...extraDefaultValue,
    },
  });

  const handleFormSubmit: SubmitHandler<{ startDate: string; endDate: string }> = async (
    formData,
    e
  ) => {
    await onSubmit({ ...formData });
    onClose();
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="2xl">
      <AlertDialogOverlay>
        <AlertDialogContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <Trans>{title}</Trans>
              </AlertDialogHeader>

              <AlertDialogBody>
                <VStack align="flex-start">
                  <RangePicker
                    names={["startDate", "endDate"]}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabledStartDate={disabledStartDate}
                    disabledEndDate={disabledEndDate}
                  />
                  {children}
                </VStack>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  ml={3}
                  type="submit"
                  variant="primary"
                  isLoading={loading}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    // onSubmit({ startDate, endDate });
                    // onClose();
                  }}
                >
                  <Trans>Save</Trans>
                </Button>
              </AlertDialogFooter>
            </form>
          </FormProvider>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DateDialog;
