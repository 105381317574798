import { Badge, Image } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { RegistryAwareField } from "components/ui";
import placeholderPNG from "assets/placeholder.png";
import { PolicyAssetNode } from "graphql/queries/generated/queries";
import parseAmount from "helpers/parseAmount";

export const CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "mainImage",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,
    weight: 13,
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lenderId",
    weight: 13,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },
  {
    Header: "Category",
    Footer: "",
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Trans>{value}</Trans>,
    weight: 12,
    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 12,
    disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 12,
    disableFilters: true,
  },

  {
    Header: "Creation Period",
    Footer: "",
    accessor: "creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    weight: 10,
    Cell: ({
      cell: {
        row: { original },
      },
    }: {
      cell: { row: { original: PolicyAssetNode } };
    }) => {
      const { insuredValue } = original;
      return parseAmount(insuredValue);
    },
  },
  {
    Header: "Generic Dimensions",
    Footer: "",
    accessor: "genericDimensions",
    weight: 9,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Technique",
    Footer: "",
    weight: 9,
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "inventory Number",
    Footer: "",
    accessor: "inventoryNumber",
    weight: 9,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Location",
    Footer: "",
    accessor: "objectLocationEntityId",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },

  {
    Header: "Shipper",
    Footer: "",
    accessor: "shipperId",
    weight: 9,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },

  {
    Header: "Lender address",
    Footer: "",
    accessor: "lenderAddress.raw",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Pickup address",
    Footer: "",
    accessor: "pickupAddress.raw",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Delivery address",
    Footer: "",
    accessor: "deliveryAddress.raw",
    weight: 8,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
];
