/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */

import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { AssetPresentValueInputSchema } from "graphql/queries/generated/validation-schema";
import { FormInputHook, MoneyInputHook } from "components/ui";
import { useState, useEffect } from "react";
import cleanObject from "helpers/cleanObject";
import {
  AssetPresentValueInput,
  useDeleteAssetPresentValueMutation,
  useUpdateAssetPresentValueMutation,
  useCreateAssetPresentValueMutation,
  useGetAssetPresentValuesByAssetIdLazyQuery,
} from "graphql/queries/generated/queries";
import ASSET_CONSTANTS from "constants/asset";
import { ValidationProvider } from "components/form/ValidationContext";
import OnViewAssetPresentValuesTable from "pages/assets/shared/OnViewAssetPresentValuesTable";
import { handleMutation } from "middleware/Toaster";

interface AssetPresentValueFormProps {
  assetId: string;
  // children: any;
}

const AssetPresentValueForm = ({ assetId }: AssetPresentValueFormProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const methods = useForm<AssetPresentValueInput>({
    defaultValues: {
      amount: {
        amount: 0,
        currency: "EUR",
      },
      authorId: "",
      estimateDate: "",
      estimateReason: "",
      fileTitle: "",
      file: "",
      notes: "",
      assetId,
    },
    resolver: yupResolver(AssetPresentValueInputSchema()),
    mode: "onChange",
  });

  const { setValue } = methods;

  const [selectedId, setSelectedId] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);

  const toast = useToast();

  const { t } = useTranslation();

  const onEditModalOpen = (
    id: string,
    amount: {
      amount: number;
      currency: string;
    },
    author: {
      id: string;
      fullName: string;
    },
    estimateDate: string,
    estimateReason: string,
    fileTitle: string,
    file: string,
    notes: string
  ) => {
    setSelectedId(id);
    setValue("amount", amount);
    setValue("authorId", author.id);
    setValue("estimateDate", estimateDate);
    setValue("estimateReason", estimateReason);
    setValue("fileTitle", fileTitle);
    setValue("file", file);
    setValue("notes", notes);
    setCurrentFile(file);
    onOpen();
  };

  const [loadAssetPresentValues, refetch] = useGetAssetPresentValuesByAssetIdLazyQuery();

  const [
    deleteAssetPresentValue,
    {
      data: deleteAssetPresentValueData,
      error: deleteAssetPresentValueError,
      loading: deleteAssetPresentValueLoading,
    },
  ] = useDeleteAssetPresentValueMutation({
    ...handleMutation("Asset value deleted!"),
  });

  const [assetPresentValues, setAssetPresentValues] = useState<
    Array<{
      id: string;
      amount: {
        amount: number;
        currency: string;
      };
      author: {
        id: string;
        fullName: string;
      };
      estimateDate: string;
      estimateReason: string;
      fileTitle: string;
      file: string;
      notes: string;
      assetId: string;
    }>
  >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedAssetPresentValues = async () => {
    const {
      data: assetPresentValues,
      loading: assetPresentValuesLoading,
      error: assetPresentValuesError,
    } = await loadAssetPresentValues({
      variables: {
        assetId,
      },
    });

    if (!assetPresentValues || !assetPresentValues.allAssetPresentValues) return null;
    const {
      allAssetPresentValues: { edges },
    } = assetPresentValues;

    setAssetPresentValues(
      edges.flatMap(({ node }) => ({
        id: node.id,
        amount: {
          amount: node.amount.amount,
          currency: node.amount.currency.code,
        },
        author: {
          id: node?.author?.id,
          fullName: node?.author?.fullName,
        },
        estimateDate: node.estimateDate,
        estimateReason: node.estimateReason,
        fileTitle: node.fileTitle,
        file: node.file,
        notes: node.notes,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        assetId,
      }))
    );
    return assetPresentValues;
  };

  const onDelete = async (id: string) => {
    await deleteAssetPresentValue({
      variables: {
        input: {
          id,
        },
      },
    });

    const assetPresentValues = await loadParsedAssetPresentValues();
    return assetPresentValues;
  };

  const [
    createAssetPresentValue,
    {
      data: createAssetPresentValueData,
      loading: createAssetPresentValueLoading,
      error: createAssetPresentValueError,
    },
  ] = useCreateAssetPresentValueMutation({
    ...handleMutation("Asset value created!"),
  });

  const [
    updateAssetPresentValue,
    {
      data: updateAssetPresentValueData,
      loading: updateAssetPresentValueLoading,
      error: updateAssetPresentValueError,
    },
  ] = useUpdateAssetPresentValueMutation({
    ...handleMutation("Asset value updated!"),
  });

  const findLabelByValue = (value: string, array: { value: string; label: string }[]) => {
    const obj = array.find((x) => x.value === value);
    return obj ? obj.label : "";
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadParsedAssetPresentValues();
    return undefined;
  }, []);

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("authorId", "");
    setValue("amount", {
      amount: 0,
      currency: "EUR",
    });
    setValue("estimateDate", "");
    setValue("estimateReason", "");
    setValue("fileTitle", "");
    setValue("file", "");
    setValue("notes", "");
    setCurrentFile(null);
    onClose();
  };

  const onSubmit: SubmitHandler<AssetPresentValueInput> = async (values: any, e: any) => {
    const assetPresentValueData = cleanObject(values);
    let assetPresentValues;
    let response;
    if (selectedId) {
      response = await updateAssetPresentValue({
        variables: {
          input: {
            assetPresentValueData: {
              amount: assetPresentValueData.amount,
              authorId: assetPresentValueData.authorId,
              estimateDate: assetPresentValueData.estimateDate,
              estimateReason: assetPresentValueData.estimateReason,
              fileTitle: assetPresentValueData.fileTitle,
              file: assetPresentValueData.file,
              notes: assetPresentValueData.notes,
            },
            id: selectedId,
          },
        },
      });
      if (
        response.data &&
        response.data.updateAssetPresentValue &&
        response.data.updateAssetPresentValue.assetPresentValue &&
        response.data.updateAssetPresentValue.assetPresentValue.id
      ) {
        assetPresentValues = await loadParsedAssetPresentValues();
        onCloseModal();
      }
    } else {
      response = await createAssetPresentValue({
        variables: {
          input: {
            assetPresentValueData: {
              amount: assetPresentValueData.amount,
              authorId: assetPresentValueData.authorId,
              estimateDate: assetPresentValueData.estimateDate,
              estimateReason: assetPresentValueData.estimateReason,
              fileTitle: assetPresentValueData.fileTitle,
              file: assetPresentValueData.file,
              notes: assetPresentValueData.notes,
              assetId,
            },
          },
        },
      });

      if (
        response.data &&
        response.data.createAssetPresentValue &&
        response.data.createAssetPresentValue.assetPresentValue &&
        response.data.createAssetPresentValue.assetPresentValue.id
      ) {
        assetPresentValues = await loadParsedAssetPresentValues();
        onCloseModal();
      }
    }

    return assetPresentValues;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  return (
    <>
      <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}>
          <OnViewAssetPresentValuesTable
            data={assetPresentValues}
            mode="edit"
            editAction={({ row }) =>
              onEditModalOpen(
                row?.original?.id,
                row?.original?.amount,
                row?.original?.author,
                row?.original?.estimateDate.toString(),
                row?.original?.estimateReason,
                row?.original?.fileTitle,
                row?.original?.file,
                row?.original?.notes
              )
            }
            viewAction={null}
            onDelete={(id: string) => onDelete(id)}
          />

          <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
            <Button onClick={onOpen} variant="primary">
              <Trans>Add new asset present value</Trans>
            </Button>
          </ButtonGroup>

          <Modal closeOnOverlayClick={false} isOpen={isOpen} size="xl" onClose={onCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {selectedId ? (
                  <Trans>Update asset present value</Trans>
                ) : (
                  <Trans>Add new asset present value</Trans>
                )}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormProvider {...methods}>
                  <ValidationProvider schema={AssetPresentValueInputSchema()}>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <form id="addAssetPresentValue" onSubmit={handleSubmit}>
                      <Box maxW="6xl">
                        <Stack spacing="4" divider={<StackDivider />}>
                          <HStack alignItems="flex-end">
                            <FormInputHook label="Estimate date" name="estimateDate" type="date" />
                            <FormInputHook
                              label="Estimate reason"
                              options={ASSET_CONSTANTS.ASSET_VALUES_REASONS}
                              name="estimateReason"
                            />

                            {/* <FormInputHook label="Estimate reason" name="estimateReason" /> */}
                          </HStack>
                          <HStack alignItems="flex-end">
                            <MoneyInputHook name="amount.amount" label="Amount" />
                          </HStack>
                          <HStack alignItems="flex-end">
                            <FormInputHook name="authorId" label="Author" type="registry" />
                            {/* <FormInputHook label="Author" name="author" /> */}
                            <FormInputHook label="Present value notes" name="notes" />
                          </HStack>
                          <HStack alignItems="flex-end">
                            <FormInputHook name="fileTitle" label="File title" />

                            <HStack paddingBottom="2">
                              {currentFile && typeof currentFile === "string" && (
                                <Button
                                  paddingX="6"
                                  title="Download"
                                  onClick={() => window.open(currentFile)}
                                  fontWeight="600"
                                >
                                  <Trans>Download</Trans>
                                </Button>
                              )}
                              <FormInputHook label="File" name="file.0.file" type="file" />
                            </HStack>
                          </HStack>

                          <input type="hidden" name="assetId" />
                        </Stack>
                      </Box>
                    </form>
                  </ValidationProvider>
                </FormProvider>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    mr={1}
                    isLoading={createAssetPresentValueLoading || updateAssetPresentValueLoading}
                    loadingText="Loading"
                  >
                    <Trans>Save</Trans>
                  </Button>
                  <Button onClick={onCloseModal}>
                    <Trans>Cancel</Trans>
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* { children } */}
        </Stack>
      </Box>
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
                {children}
            </ButtonGroup> */}
    </>
  );
};

export default AssetPresentValueForm;
