import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OfficeInputSchema } from "graphql/queries/generated/validation-schema";
import { FormInputHook, Table } from "components/ui";
import { useState, useEffect } from "react";
import cleanObject from "helpers/cleanObject";
import {
  useDeleteOfficeMutation,
  useGetOfficesByRegistryIdLazyQuery,
  OfficeInput,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  OfficeNode,
} from "graphql/queries/generated/queries";
import COUNTRY_CODES from "constants/countryCodes.json";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import {
  OFFICE_CUSTOM_FIELDS,
  OFFICE_HIDDEN_FIELDS,
  OFFICE_CUSTOM_COLUMNS,
} from "../shared/RegistryDatatableCostants";

interface OfficeFormProps {
  registryId: string;
}

const OfficeForm = ({ registryId }: OfficeFormProps) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const countryOptions: FormSelectOptionProps[] = COUNTRY_CODES.map(({ name, code }) => ({
    value: code,
    label: name,
  }));

  const methods = useForm<OfficeInput>({
    defaultValues: {
      name: "",
      officeAddress: {
        raw: "",
      },
      city: "",
      zip: "",
      state: "",
      country: "",
      phone: "",
      email: "",
      main: false,
      active: true,
      registry: registryId,
    },
    resolver: yupResolver(OfficeInputSchema()),
    mode: "onChange",
  });

  const { setValue } = methods;

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [newOfficeButtonDisabled, setNewOfficeButtonDisabled] = useState(true);

  const onEditModalOpen = (row: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setSelectedId(row.row.original.id);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("name", row.row.original.name);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("officeAddress", row.row.original.officeAddress);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("phone", row.row.original.phone);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("email", row.row.original.email);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("main", row.row.original.main);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setValue("active", row.row.original.active);

    onOpen();
  };

  const [loadOffices, refetch] = useGetOfficesByRegistryIdLazyQuery();
  const [deleteOffice, { loading: deleteLoading }] = useDeleteOfficeMutation({
    ...handleMutation("Office deleted!"),
  });

  const [offices, setOffices] = useState<Array<OfficeNode>>([]);

  // eslint-disable-next-line consistent-return
  const loadParsedOffices = async (registryId: string) => {
    const {
      data: offices,
      loading: officesLoading,
      error: officesError,
    } = await loadOffices({
      variables: {
        registryId,
      },
    });

    if (
      !offices ||
      !offices.allOffices ||
      !offices.allOffices.edges ||
      offices.allOffices.edges.length <= 0
    )
      return null;

    const result: OfficeNode[] = [];

    for (let i = 0; i < offices.allOffices.edges.length; i += 1)
      result.push(offices.allOffices.edges[i].node as OfficeNode);

    setOffices(result);

    return result;
  };

  let dataToGetColumns;
  let columns;

  if (offices && offices.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    dataToGetColumns = offices[0];

    columns = getColumns({
      data: dataToGetColumns,
      customFields: OFFICE_CUSTOM_FIELDS,
      customColumns: OFFICE_CUSTOM_COLUMNS,
    });
  }

  const onDelete = async (id: string) => {
    await deleteOffice({
      variables: {
        input: {
          id,
        },
      },
    });

    const assetElements = await loadParsedOffices(registryId);
    return assetElements;
  };

  const [
    createOffice,
    { data: createOfficeData, loading: createOfficeLoading, error: createOfficeError },
  ] = useCreateOfficeMutation({
    ...handleMutation("Office created!"),
  });
  const [
    updateOffice,
    { data: updateOfficeData, loading: updateOfficeLoading, error: updateOfficeError },
  ] = useUpdateOfficeMutation({
    ...handleMutation("Office updated!"),
  });

  useEffect(() => {
    if (typeof registryId !== "undefined" && registryId !== "") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadParsedOffices(registryId);
      setNewOfficeButtonDisabled(false);
    } else setNewOfficeButtonDisabled(true);

    return undefined;
  }, []);

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("officeAddress", {
      raw: "",
    });
    setValue("city", "");
    setValue("zip", "");
    setValue("state", "");
    setValue("country", "");
    setValue("phone", "");
    setValue("email", "");
    setValue("main", false);
    setValue("active", true);

    onClose();
  };

  const onSubmit: SubmitHandler<OfficeInput> = async (values, e) => {
    const officeData = cleanObject(values) as Partial<OfficeInput>;
    let offices;
    let response;

    if (selectedId) {
      response = await updateOffice({
        variables: {
          input: {
            officeData: {
              name: officeData.name,
              officeAddress: officeData.officeAddress,
              phone: officeData.phone,
              email: officeData.email,
              main: officeData.main,
              active: officeData.active,
              registry: registryId,
            },
            id: selectedId,
          },
        },
      });

      if (
        response.data &&
        response.data.updateOffice &&
        response.data.updateOffice.office &&
        response.data.updateOffice.office.id
      ) {
        offices = await loadParsedOffices(registryId);
        onCloseModal();
      }
    } else {
      response = await createOffice({
        variables: {
          input: {
            officeData: {
              name: officeData.name,
              officeAddress: officeData.officeAddress,
              phone: officeData.phone,
              email: officeData.email,
              main: officeData.main,
              active: officeData.active,
              registry: registryId,
            },
          },
        },
      });

      if (
        response.data &&
        response.data.createOffice &&
        response.data.createOffice.office &&
        response.data.createOffice.office.id
      ) {
        offices = await loadParsedOffices(registryId);
        onCloseModal();
      }
    }

    return offices;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  return (
    <>
      <Table
        hiddenColumns={OFFICE_HIDDEN_FIELDS}
        columns={columns}
        data={offices}
        deleteAction={onDelete}
        editAction={onEditModalOpen}
      />

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onOpen} isDisabled={newOfficeButtonDisabled} variant="primary">
          <Trans>Add new office</Trans>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="5xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? <Trans>Update office</Trans> : <Trans>Add new office</Trans>}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pb={6}>
            <FormProvider {...methods}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form id="addOffice" onSubmit={handleSubmit}>
                <Box maxW="6xl">
                  <Stack spacing="4" divider={<StackDivider />}>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Name" name="name" />
                      <FormInputHook label={t("Is main?")} name="main" type="checkbox" />
                      <FormInputHook label={t("Is active?")} name="active" type="checkbox" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Address" name="officeAddress" type="address" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Phone" name="phone" />
                      <FormInputHook label="Email" name="email" />
                    </HStack>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button variant="primary" onClick={handleSubmit} mr={1}>
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onCloseModal}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OfficeForm;
