/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { ApolloQueryResult } from "@apollo/client";
import { useTranslation, Trans } from "react-i18next";
// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import {
  AssetInput,
  Exact,
  GetAssetByIdQuery,
  useGetUniqueUnknownRegistryLazyQuery,
  useCreateRegistryMutation,
} from "graphql/queries/generated/queries";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { FieldGroup, FormInputHook } from "components/ui";
import { useEffect, BaseSyntheticEvent } from "react";

import ASSET_CONSTANTS from "constants/asset";
import HistoricDateForm from "pages/assets/shared/HistoricDateForm";
import { handleMutation } from "middleware/Toaster";
import AssetCategoryForm from "../../shared/AssetCategoryForm";
import findLabel from "../../shared/FindLabel";

// interface GeneralInfoPanelNewCategoryFormProps {
//     handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
//     handleChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
//     handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void
//     values: {
//         author: string;
//         title: string;
//         series: string;
//         series_details: string;
//         description: string;
//         inventoryNumber: string;
//         archiveNumber: string;
//         catalogNumber: string;
//         criticalCatalogue: boolean;
//         nft: boolean | null;
//         nftNotes: string
//     }
//     category: string
//     children?: React.ReactNode
//     createAssetData: CreateAssetMutation | null | undefined
//     updateAssetData: UpdateAssetMutation | null | undefined
//     createAssetError: ApolloError | undefined
//     updateAssetError: ApolloError | undefined
//     createAssetLoading: boolean
//     updateAssetLoading: boolean
// }

interface GeneralInfoPanelNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  subcategory: string;
  children?: React.ReactNode;
  refetchAsset: (
    variables?: Partial<
      Exact<{
        id: string;
      }>
    >
  ) => Promise<ApolloQueryResult<GetAssetByIdQuery>> | undefined;
  id: string | null;
}

const GeneralInfoPanelNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  category,
  subcategory,
  refetchAsset,
  children,
  id,
}: GeneralInfoPanelNewCategoryFormProps) => {
  // useEffect(() => {
  //     register("archiveNumber")
  // }, []);
  const { t } = useTranslation();
  const { title } = getValues();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const nft = watch("nft");

  const categoryLabel = findLabel(ASSET_CONSTANTS.CATEGORY, category.toLowerCase());
  const subcategoryLabel = findLabel(ASSET_CONSTANTS.SUBCATEGORY, subcategory.toLowerCase());

  const [
    createRegistry,
    { data: createRegistryData, error: createRegistryError, loading: createRegistryLoading },
  ] = useCreateRegistryMutation({
    ...handleMutation("Registry created!"),
  });
  const [getUnknown] = useGetUniqueUnknownRegistryLazyQuery();

  const getOrCreateUnknown = async () => {
    const { data: getUnknownData, error: getUnknownError } = await getUnknown();
    if (getUnknownData.allRegistries.edges.length === 0) {
      const { data: createRegistryData, errors: createRegistryErrors } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: "Unknown",
              isCompany: false,
              isUnknownTenantDefault: true,
            },
          },
        },
      });
      if (createRegistryErrors) throw new Error("Unable to create unknown registry entry!"); // return {value: registry.id, label:  registry.fullName}
      const {
        createRegistry: { registry },
      } = createRegistryData;

      return registry.id;
    }

    if (getUnknownError) throw new Error("Unable to retrieve unknown registry entry!");
    const {
      allRegistries: { edges },
    } = getUnknownData;
    const unknownRegistry = edges[0]?.node;
    // return { label: unknownRegistry.fullName, value: unknownRegistry.id };
    return unknownRegistry.id;
  };

  useEffect(() => {
    const setUnknown = async () => {
      if (category === "NATURALISTIC_GOODS") {
        const unknownAuthorId = await getOrCreateUnknown();
        setValue("authorEntityId", unknownAuthorId);
      }
    };

    setUnknown().catch(console.error);
  }, []);

  return (
    <>
      {/* <Box p={6} maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}> */}
      <Box alignItems="flex-start">
        <Heading as="h2" fontWeight="semibold" fontSize="md" flexShrink={0}>
          <Trans>Category</Trans>
        </Heading>
        <Text fontSize="sm" color="gray.500">
          {categoryLabel}
        </Text>
        {categoryLabel !== subcategoryLabel && (
          <>
            <Heading as="h2" fontWeight="semibold" fontSize="md" flexShrink={0}>
              <Trans>Subcategory</Trans>
            </Heading>
            <Text fontSize="sm" color="gray.500">
              {subcategoryLabel}
            </Text>
          </>
        )}

        {id && refetchAsset && (
          <AssetCategoryForm
            setValue={setValue}
            assetId={id}
            currentSubcategory={subcategory}
            refetchAsset={refetchAsset}
          />
        )}
      </Box>

      <FieldGroup
        title={t("Name and author")}
        subtitle={t("General info - Name and author - Lorem ipsum dolor sit amet")}
        py={8}
      >
        <VStack width="full" align="left">
          <HStack>
            {(category === "NATURALISTIC_GOODS" ||
              category === "NUMISMATIC_COINS" ||
              category === "DESIGN_OR_ANTIQUE" ||
              category === "ARCHEOLOGY") && <FormInputHook label="Name" name="title" />}
            {category !== "NATURALISTIC_GOODS" &&
              category !== "NUMISMATIC_COINS" &&
              category !== "DESIGN_OR_ANTIQUE" &&
              category !== "ARCHEOLOGY" && <FormInputHook label="Title" name="title" />}
            {/* {category !== 'DESIGN_OR_ANTIQUE' && <FormInputHook label="Author" name="author" />}
              {category === 'DESIGN_OR_ANTIQUE' && <FormInputHook label="Artisan/Designer" name="author" />} */}
            {category !== "DESIGN_OR_ANTIQUE" && category !== "NATURALISTIC_GOODS" && (
              <FormInputHook
                name="authorEntityId"
                acceptAnonymous
                acceptUnknown
                label="Author entity"
                type="registry"
                registryCategory="author"
              />
            )}

            {category === "DESIGN_OR_ANTIQUE" && (
              <FormInputHook
                name="authorEntityId"
                label="Artisan/Designer"
                type="registry"
                acceptAnonymous
                acceptUnknown
                registryCategory="author"
              />
            )}
          </HStack>
          {category === "NATURALISTIC_GOODS" && (
            <HStack>
              <FormInputHook label="Common name" name="commonName" />
              <FormInputHook label="Scientific name" name="scientificName" />
              <FormInputHook label="International code of nomenclature" name="internationalCode" />
            </HStack>
          )}
          {/* <HStack>
              {category !== 'DESIGN_OR_ANTIQUE' && <FormInputHook name="authorEntityId" label="Author entity" type="registry" />}
              {category === 'DESIGN_OR_ANTIQUE' && <FormInputHook name="authorEntityId" label="Artisan/Designer" type="registry" />}
            </HStack> */}
          {category === "PRINT_OR_PHOTO" && (
            <HStack>
              <FormInputHook name="printerId" label="Printer" type="registry" />
            </HStack>
          )}
          {category === "DIGITAL" && (
            <HStack>
              <FormInputHook name="videomakerId" label="Videomaker" type="registry" />
            </HStack>
          )}
          {/* {
              category === 'DESIGN_OR_ANTIQUE' &&
              <HStack>
                <FormInputHook name="artisanId" label="Artisan/designer" type="registry" />
              </HStack>
            } */}
          {category === "DESIGN_OR_ANTIQUE" && (
            <HStack>
              <FormInputHook name="manufacturerId" label="Manufacturer/producer" type="registry" />
            </HStack>
          )}
          {category === "OTHER" && (
            <HStack>
              <FormInputHook name="producerId" label="Producer" type="registry" />
            </HStack>
          )}
        </VStack>
      </FieldGroup>

      <FieldGroup
        title={
          // eslint-disable-next-line no-nested-ternary
          category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY"
            ? t("Issuing date")
            : category === "BOOKS"
            ? t("Publishing date")
            : t("Date of creation")
        }
        subtitle={
          // eslint-disable-next-line no-nested-ternary
          category === "NUMISMATIC_COINS" ||
          category === "NUMISMATIC_BANKNOTES" ||
          category === "PHILATELY"
            ? t("Global info - Issuing date - Lorem ipsum dolor sit amet")
            : category === "BOOKS"
            ? t("Global info - Publishing date - Lorem ipsum dolor sit amet")
            : t("Global info - Creation date - Lorem ipsum dolor sit amet")
        }
        py={8}
      >
        <VStack width="full" align="left">
          {/* <HStack>
              <FormInputHook label="Creation date" type="date" name="creationDate" />
              <FormInputHook name="creationPeriod" label="Creation period" />
            </HStack>

            <HStack>
              <FormInputHook name="hasFullDatetime" label="hasFullDatetime" type="checkbox" />
              <FormInputHook name="hasPeriod" label="hasPeriod" type="checkbox" />
              <FormInputHook name="hasYear" label="hasYear" type="checkbox" />
            </HStack> */}
          <HistoricDateForm
            onSubmit={onSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            watch={watch}
            // handleChange={formik.handleChange}
            // handleRadioButtons={handleRadioButtons}
            // values={formik.values}
            category={category}
          />
          {(category === "NUMISMATIC_COINS" ||
            category === "NUMISMATIC_BANKNOTES" ||
            category === "PHILATELY") && (
            <HStack>
              <FormInputHook label="Circulation period" name="coinCirculationPeriod" />
            </HStack>
          )}
        </VStack>
      </FieldGroup>

      {category === "NUMISMATIC_COINS" && (
        <FieldGroup
          title={t("Issuing info")}
          subtitle={t("Global info - Issuing info - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <FormInputHook label="Minter and issuing authority" name="coinMintIssuer" />
          <HStack>
            <FormInputHook label="Placement" name="coinPlacement" />
          </HStack>
          <FormInputHook
            label="Placement and period notes"
            name="coinPlacementNotes"
            type="textarea"
          />

          <FormInputHook label="Issuing reason" name="coinIssueReason" />
        </FieldGroup>
      )}

      {category === "NUMISMATIC_BANKNOTES" && (
        <FieldGroup
          title={t("Issuing info")}
          subtitle={t("Global info - Issuing info - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <FormInputHook label="Minter and issuing authority" name="coinMintIssuer" />
          <FormInputHook label="Printer" name="banknotePrinter" />
          <HStack>
            <FormInputHook label="Placement" name="coinPlacement" />
            <FormInputHook label="Period" name="coinPeriod" />
          </HStack>
          <FormInputHook
            label="Placement and period notes"
            name="coinPlacementNotes"
            type="textarea"
          />

          <FormInputHook label="Issuing reason" name="coinIssueReason" />
        </FieldGroup>
      )}

      {category === "PHILATELY" && (
        <FieldGroup
          title={t("Issuing info")}
          subtitle={t("Global info - Issuing info - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <FormInputHook label="Issuing authority" name="coinMintIssuer" />
          <FormInputHook label="Printer" name="banknotePrinter" />
          <HStack>
            <FormInputHook label="Placement" name="coinPlacement" />
            <FormInputHook label="Period" name="coinPeriod" />
          </HStack>
          <FormInputHook
            label="Placement and period notes"
            name="coinPlacementNotes"
            type="textarea"
          />

          <FormInputHook label="Issuing reason" name="coinIssueReason" />
        </FieldGroup>
      )}

      {category === "NATURALISTIC_GOODS" && (
        <FieldGroup
          title={t("Description")}
          subtitle={t("Global info - Description - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <FormInputHook label="" name="description" type="textarea" />
        </FieldGroup>
      )}

      {category !== "NATURALISTIC_GOODS" && (
        <FieldGroup
          title={t("Generic features")}
          subtitle={t("Global info - Generic features - Lorem ipsum dolor sit amet")}
          py={8}
        >
          <VStack width="full" align="left">
            {category === "URBAN" && (
              <HStack>
                <FormInputHook name="placement" label="Placement" />
              </HStack>
            )}
            {category !== "PRINT_OR_PHOTO" &&
              category !== "NUMISMATIC_COINS" &&
              category !== "OTHER" &&
              category !== "NUMISMATIC_BANKNOTES" &&
              category !== "PHILATELY" &&
              category !== "BOOKS" && (
                <HStack>
                  <FormInputHook label="Technique" name="technique" />
                  {/* <FormInputHook label="Generic dimensions" name="genericDimensions" /> */}
                </HStack>
              )}
            {category === "NUMISMATIC_COINS" && (
              <HStack>
                <FormInputHook label="Composition" name="coinComposition" />
                {/* <FormInputHook label="Generic dimensions" name="genericDimensions" /> */}
              </HStack>
            )}
            {category === "PRINT_OR_PHOTO" && (
              <>
                <HStack>
                  <FormInputHook label="Technique" name="technique" />
                  <FormInputHook label="Paper type" name="paperType" />
                </HStack>
                <HStack>
                  <FormInputHook label="Production mode" name="productionMode" />
                  {/* <FormInputHook label="Generic dimensions" name="genericDimensions" /> */}
                </HStack>
              </>
            )}

            {category === "OTHER" && (
              <HStack>
                <FormInputHook label="Model" name="otherModel" />
                <FormInputHook label="Material" name="material" />
              </HStack>
            )}
            {category === "BOOKS" && (
              <HStack>
                <FormInputHook label="Paper type" name="paperType" />
                <FormInputHook label="Cover type" name="coverType" />
                <FormInputHook label="Printing type" name="printingType" />
              </HStack>
            )}
            {(category === "NUMISMATIC_BANKNOTES" || category === "PHILATELY") && (
              <FormInputHook label="Paper type" name="paperType" />
            )}

            <HStack>
              <FormInputHook label="Description" name="description" type="textarea" />
            </HStack>
          </VStack>
        </FieldGroup>
      )}

      <FieldGroup
        title={t("Series")}
        subtitle={t("Global info - Series - Lorem ipsum dolor sit amet")}
        py={8}
      >
        <VStack width="full" align="left">
          <HStack>
            <FormInputHook label="Series" name="series" />
            <FormInputHook label="Series Details" name="seriesDetails" />
          </HStack>
        </VStack>
      </FieldGroup>

      {category === "NATURALISTIC_GOODS" && (
        <FieldGroup title={t("Material")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            <HStack>
              <FormInputHook label="" name="material" type="textarea" />
            </HStack>
          </VStack>
        </FieldGroup>
      )}

      <FieldGroup
        title={t("Cataloguing")}
        subtitle={t("Global info - Cataloguing - Lorem ipsum dolor sit amet")}
        py={8}
      >
        <VStack width="full" align="left">
          <HStack>
            <FormInputHook label="Inventory number" name="inventoryNumber" />
          </HStack>

          <HStack>
            <FormInputHook label="Archive number" name="archiveNumber" />
          </HStack>

          <HStack>
            <FormInputHook label="Catalog number" name="catalogNumber" />
            <FormInputHook label="Critical catalogue?" type="checkbox" name="criticalCatalogue" />
          </HStack>

          {(category === "NUMISMATIC_COINS" ||
            category === "NUMISMATIC_BANKNOTES" ||
            category === "PHILATELY") && (
            <HStack>
              <FormInputHook label="Identification code" name="coinIdentificationCode" />
            </HStack>
          )}
        </VStack>
      </FieldGroup>

      <FieldGroup
        title={t("NFT")}
        subtitle={t("Global info - NFT - Lorem ipsum dolor sit amet")}
        py={8}
      >
        <VStack width="full" align="left">
          <HStack>
            <FormInputHook label="Has related NFT?" type="checkbox" name="nft" />

            {nft && <FormInputHook label="NFT notes" name="nftNotes" />}
          </HStack>
        </VStack>
      </FieldGroup>

      {/* </Stack>

    </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
      <Button
          isLoading={createAssetLoading || updateAssetLoading}
          loadingText="Loading"
          type="submit"
          variant="primary"
        >
          {id  ? t("Uddate") : t("Save")}
        </Button>
        {children}
    </ButtonGroup> */}
    </>
  );
};

export default GeneralInfoPanelNewCategoryForm;
