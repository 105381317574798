import {
    HStack,
    VStack,
  } from "@chakra-ui/react";

  import { useTranslation } from "react-i18next";
  // import { CreateAsset } from 'graphql/queries/types/CreateAsset';
  // import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
  import {
    AssetInput,
  } from "graphql/queries/generated/queries";
  import {
    UseFormGetValues,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
  } from "react-hook-form";
  import { FieldGroup, FormInputHook } from "components/ui";
  import { BaseSyntheticEvent } from "react";



  interface OriginPanelNewCategoryFormProps {
    onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
    register: UseFormRegister<AssetInput>;
    getValues: UseFormGetValues<AssetInput>;
    watch: any; // UseFormWatch<AssetInput>,
    setValue: UseFormSetValue<AssetInput>;
    setError: UseFormSetError<AssetInput>;
    category: string;
    children?: React.ReactNode;
  }

  const OriginNewCategoryForm = ({
    // eslint-disable-next-line react/prop-types
    onSubmit,
    // handleChange,
    // handleRadioButtons,
    // values,
    register,
    setValue,
    setError,
    getValues,
    watch,
    category,
    children,
  }: OriginPanelNewCategoryFormProps) => {
    const { t } = useTranslation();
    const { title } = getValues();



    return (
      <>
        {/* <Box p={6} maxW="6xl">
          <Stack spacing="4" divider={<StackDivider />}> */}
            <FieldGroup title={t("Origin notes")} subtitle={t("Provenance and literature - Origin - Origin notes - Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label="Origin"
                    name="originNotes"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>

      </>
    );
  };

  export default OriginNewCategoryForm;
