/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */

import cloneDeep from "lodash/cloneDeep";

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
function stripProperty<T, K extends keyof T>(object: T, propToDelete = "__typename") {
  if (!object) return object;
  const obj = cloneDeep(object);
  for (const property in obj) {
    if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
      const nestedObjectClone = cloneDeep(obj[property]);
      delete obj[property];
      const newData = stripProperty(nestedObjectClone, propToDelete);
      obj[property] = newData;
    } else if (property === propToDelete) {
      delete obj[property];
    }
  }
  return obj;
}

export default stripProperty;
