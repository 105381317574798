/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Dot, Field } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";
import findLabel from "./FindLabel";

import BorderedBox from "./BorderedBox";

interface AnyObject {
  [key: string]: any;
}

const SettingView = ({ data }: AnyObject) => {
  const {
    hasBarriers,
    barriersDetails,
    handlingRequirements,
    injuryRisk,
    injuryRiskDescription,
    mouldingSupportBase,
    hardwareToolsInstallation,
    conservation,
    lenderRequests,
    settingNotes,
  } = data;

  const { t } = useTranslation();

  return (
    <BorderedBox title={t("Setting")} subtitle="Lorem ipsum">
      <HStack spacing="16" w="full">
        <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Barriers and protections</Trans>
          </Text>
          <Dot status={hasBarriers} />
        </VStack>
        <Field label="Barriers details" value={barriersDetails} />
      </HStack>

      <Field label="Handling requirements" value={handlingRequirements} />

      {injuryRisk && (
        <HStack>
          <Text variant="muted">
            <Trans>Injury risk</Trans>:
          </Text>
          <Text>{findLabel(ASSET_CONSTANTS.INJURY_RISK, injuryRisk.toString())}</Text>

          <Field label="Injury risk description" value={injuryRiskDescription} />

        </HStack>
      )}
      <Field label="Moulding support base" value={mouldingSupportBase} />
      <Field label="Hardware tools / Installation" value={hardwareToolsInstallation} />

      <Field label="Conservation" value={conservation} />
      <Field label="Lender requests" value={lenderRequests} />
      <Field label="Setting notes" value={settingNotes} />
    </BorderedBox>
  );
};

export default SettingView;
