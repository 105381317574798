/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useValidationContext } from "components/form/ValidationContext";
import isFieldRequired from "helpers/isFieldRequired";
import { MultiSelect } from "components/form";
import { ObjectSchema } from "yup";
import { AnyObject } from "yup/lib/types";
import { AssertsShape, TypeOfShape } from "yup/lib/object";
import { WheelEvent } from "react";
import { FormInputProps } from "./type";
import Switch from "./Switch";
import TextArea from "./TextArea";
import FileUpload from "./FileUpload";
import Select from "./Select";
import { Wrapper } from "./Wrapper";
import DateTime from "./DateTime";
import Registry from "./Registry";
import Date from "./Date";
import Address from "./Address";

// this needs to have FormProvider from 'react-hook-form' to consume its context
const FormInputHook = ({
  name,
  label,
  type,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  hidden = false,
  helperText,
  isMulti = false,
  step,
  min,
  max,
  minW,
  suffix,
  registryCategory,
  acceptAnonymous = false,
  acceptUnknown = false,
  disableCreation = false,
  required = false,
  accept = "*",
}: FormInputProps) => {
  const { t } = useTranslation();
  const { schema } = useValidationContext() || {};

  const isRequiredFn = (
    required: boolean,
    schema: ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>>,
    name: string
  ) => {
    if (required) return true;
    if (!schema) return false;
    return isFieldRequired(schema, name);
  };

  const isRequired = isRequiredFn(required, schema, name);

  const {
    watch,
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  // HACK to prevent https://github.com/speakart-sw/speakart.io/issues/578
  const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
    if (type !== "number") return;
    (event.target as HTMLElement).blur();
    event.stopPropagation();
    setTimeout(() => {
      (event.target as HTMLElement).focus();
    }, 0);
  };

  if (type === "registry") {
    return (
      <Registry
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
        value={value}
        registryCategory={registryCategory}
        acceptAnonymous={acceptAnonymous}
        acceptUnknown={acceptUnknown}
        disableCreation={disableCreation}
      />
    );
  }

  if (type === "address") {
    return (
      <Address
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
        value={value}
        registryCategory={registryCategory}
        acceptAnonymous={acceptAnonymous}
        acceptUnknown={acceptUnknown}
        disableCreation={disableCreation}
        setValue={setValue}
      />
    );
  }

  if (type === "file") {
    return (
      <FileUpload
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
        accept={accept}
      />
    );
  }

  if (type === "textarea") {
    return (
      <TextArea
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        minW={minW ?? "40rem"}
        register={register}
      />
    );
  }

  // if we have to render a switch
  if (type === "checkbox")
    return (
      <Switch
        errors={errors}
        register={register}
        watch={watch}
        name={name}
        label={label}
        disabled={disabled}
        required={isRequired}
      />
    );

  // If is a select
  if (options && typeof value !== "boolean") {
    if (isMulti) {
      return (
        <MultiSelect
          name={name}
          label={label}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          required={isRequired}
        />
      );
    }
    return (
      <Select
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        required={isRequired}
        control={control}
      />
    );
  }

  if (type === "datetime-local") {
    return (
      <DateTime
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        required={isRequired}
        control={control}
      />
    );
  }

  if (type === "numbersuffix" && typeof value !== "boolean") {
    return (
      <Wrapper name={name} error={errors[name]} visibility={hidden ? "hidden" : "visible"}>
        {label && (
          <FormLabel htmlFor={name} mb="1" fontSize="xs" display="flex">
            <Text>{t(label)}</Text> {isRequired && <Text color="red">*</Text>}
          </FormLabel>
        )}
        <InputGroup size="sm">
          <Input
            width="full"
            step={step}
            id={name}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            {...register(name)}
            value={value}
            borderRadius="md"
            borderWidth="2px"
            borderColor="gray.200"
          />
          <InputRightAddon>{suffix}</InputRightAddon>
        </InputGroup>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Wrapper>
    );
  }

  if (type === "date") {
    return (
      <Date
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        control={control}
        required={isRequired}
      />
    );
  }

  // if all else but value isn't boolean
  return typeof value !== "boolean" ? (
    <Wrapper name={name} error={errors[name]} visibility={hidden ? "hidden" : "visible"}>
      {label && (
        <FormLabel htmlFor={name} mb="1" fontSize="xs" display="flex">
          <Text>{t(label)}</Text>{" "}
          {isRequired && (
            <Text color="red.500" mx="1">
              (<Trans>required</Trans>)
            </Text>
          )}
        </FormLabel>
      )}
      <Input
        width="full"
        step={step}
        min={min}
        max={max}
        id={name}
        required={isRequired}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        {...register(name, {
          valueAsNumber: type === "number",
        })}
        value={value}
        borderRadius="md"
        borderWidth="2px"
        borderColor="gray.200"
        onWheel={handleOnWheel}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Wrapper>
  ) : null;
};
export default FormInputHook;
