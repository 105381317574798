/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VStack, Text, HStack, Box, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Dot, Field } from "components/ui";
import { PolicyAssetNode } from "graphql/queries/generated/queries";
import parseAmount from "helpers/parseAmount";
import { TFunction, Trans } from "react-i18next";

interface TEPolicyAssetSummaryProps {
  data: PolicyAssetNode & {
    author: string;
    title: string;
    creationPeriod: string;
  };
  t: TFunction<"translation", undefined>;
}

const TEPolicyAssetSummary = ({ data, t }: TEPolicyAssetSummaryProps) => {
  const {
    asset,
    author,
    title,
    creationPeriod,
    catNat,
    coverTerrorism,
    coverType,
    coveredRiskType,
    evaluationType,
    exemption,
    lenderAddress,
    pickupAddress,
    deliveryAddress,
    lender,
    shipper,
    objectLocationEntity,
    objectLocationOffice,
    insuredValue,
  } = data;

  return (
    <VStack width="full" spacing="6" align="left" p="4">
      <HStack>
        <Field label="Author" value={author} />
        <Field label="Creation period" value={creationPeriod} />
        <Field label="Title" value={title} />
      </HStack>
      <HStack mb="8">
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Natural disasters</Trans>
          </Text>
          <Dot status={catNat as boolean} />
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Cover Terrorism</Trans>
          </Text>
          <Dot status={coverTerrorism as boolean} />
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Exemption</Trans>
          </Text>
          <Dot status={exemption as boolean} />
        </VStack>
      </HStack>
      <HStack mb="8">
        <Field label="Cover type" value={t(coverType)} />
        <Field label="Covered risk type" value={t(coveredRiskType)} />
        <Field label="Evalutation Type" value={t(evaluationType)} />
      </HStack>
      <HStack mb="8">
        <Field label="Lender" value={lender ? lender?.fullName : "..."} />
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Shipper</Trans>
          </Text>
          <Text>{shipper ? shipper?.fullName : "..."}</Text>{" "}
        </VStack>
      </HStack>
      <HStack mb="8">
        <Field label="Lender Address" value={lenderAddress?.raw ?? "..."} />
        <Field label="Pickup Address" value={pickupAddress?.raw ?? "..."} />
        <Field label="Delivery Address" value={deliveryAddress?.raw ?? "..."} />
      </HStack>
      <HStack mb="8">
        <Field label="Museum/Institution" value={objectLocationEntity?.fullName ?? "..."} />
        <Field label="Object location" value={objectLocationOffice?.name ?? "..."} />
      </HStack>
      <Box>
        <Stat>
          <StatLabel>
            <Trans>Insured value</Trans>
          </StatLabel>
          <StatNumber fontSize="md">{parseAmount(insuredValue)}</StatNumber>
        </Stat>
      </Box>
    </VStack>
  );
};
export default TEPolicyAssetSummary;
