import { Icon } from "@chakra-ui/react";
import { BooleanFilter } from "components/ui";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { Trans } from "react-i18next";
import parseDate from "./parseDate";

interface CustomColumn {
  Header?: string;
  Footer?: string;
  accessor: string | (() => string);
  Cell?: (arg0: any) => JSX.Element | Element | Element[] | string | JSX.Element[];
  isVisible?: boolean;
  weight?: number;
}

interface GetColumnProps<T> {
  data: T;
  customFields?: string[];
  customColumns?: CustomColumn[];
  filterableColumns?: string[];
}

export const BooleanCell =
  // eslint-disable-next-line react/no-unused-prop-types
  ({ cell: { value } }: { cell: { value: boolean } }) =>
    value ? (
      <Icon as={RiCheckFill} color="primaryGreen" size="lg" w={6} h={6} />
    ) : (
      <Icon as={RiCloseFill} color="primaryFuchsia" size="lg" w={6} h={6} />
    );

function getColumns<T>({
  data,
  customFields = [],
  customColumns = [],
  filterableColumns = [],
}: GetColumnProps<T>) {
  if (!data) throw new Error("Can't render columns without data");

  const columns = Object.keys(data)
    .filter((key) => key !== "__typename")
    .filter((key) => key !== "id")
    .filter((key) => (customFields.length > 0 ? customFields.indexOf(key) === -1 : true))
    .map((key) => {
      if (
        typeof data[key as keyof typeof data] === "string" &&
        ["issuedAt", "expiresAt", "eventFrom", "eventTo", "validFrom"].includes(key)
      ) {
        return {
          Header: <Trans>{key}</Trans>,
          Footer: <Trans>{key}</Trans>,
          accessor: key,
          Cell: ({ cell: { value } }: { cell: { value: string } }) => parseDate(value),
          disableFilters: filterableColumns.length > 0 ? !filterableColumns.includes(key) : true,
          weight: 0,
        };
      }
      if (typeof data[key as keyof typeof data] === "boolean") {
        return {
          Header: <Trans>{key}</Trans>,
          Footer: <Trans>{key}</Trans>,
          accessor: key,
          Cell: BooleanCell,
          sortType: "basic",
          Filter: BooleanFilter,
          disableFilters: filterableColumns.length > 0 ? !filterableColumns.includes(key) : true,
          weight: 0,
        };
      }
      return {
        Header: <Trans>{key}</Trans>,
        Footer: <Trans>{key}</Trans>,
        accessor: key,
        disableFilters: filterableColumns.length > 0 ? !filterableColumns.includes(key) : true,
        weight: 0,
      };
    });

  if (!customColumns) return columns;

  const orderedColumns = [...columns, ...customColumns].sort(
    (a, b) => (b?.weight ?? 0) - (a?.weight ?? 0)
  );
  return orderedColumns;
}

export default getColumns;
