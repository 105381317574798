import { DetailsPageSkeleton } from "components/ui";
import { useGetRestorationByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditRestorationPage = () => {
  const { restorationId } = useParams();

  const { data, loading, error, refetch } = useGetRestorationByIdQuery({
    variables: {
      id: restorationId,
    },
  });

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data) return null;

  return <Page data={data} refetchRestoration={refetch} />;
};

export default EditRestorationPage;
