import { ApolloError } from "@apollo/client";
import { FormFooter } from "components/ui";
import { UpdatePolicyMutation } from "graphql/queries/generated/queries";
import { PolicyType } from "pages/spin/types";
import { BaseSyntheticEvent } from "react";
import PolicyDetailsFields from "../PolicyFields";

interface PolicyDetailsFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => void;
  policyType: PolicyType;
  data: UpdatePolicyMutation;
  error: ApolloError;
  loading: boolean;
  isDirty?: boolean;
}

const PolicyDetailsForm = ({
  onSubmit,
  data,
  error,
  loading,
  policyType,
  isDirty,
}: PolicyDetailsFormProps) => {
  console.log(data, error, loading);
  return (
    <form onSubmit={onSubmit}>
      <PolicyDetailsFields policyType={policyType} />
      <FormFooter
        data={data}
        errors={[error]}
        loading={loading}
        title="Policy"
        sticky
        disabled={!isDirty}
      />
    </form>
  );
};

export default PolicyDetailsForm;
