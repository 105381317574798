/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VStack, Stack, HStack } from "@chakra-ui/react";
import { FieldGroup, FormInputHook } from "components/ui";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { RegistryInput } from "graphql/queries/generated/queries";
import CompanyContactForm from "../edit/CompanyContactForm";
import OfficeForm from "../edit/OfficeForm";

interface RegistryFieldsProps {
  watch: UseFormWatch<RegistryInput>;
  setValue: UseFormSetValue<RegistryInput>;
  registryId: string;
}

const RegistryFields = ({ watch, setValue, registryId }: RegistryFieldsProps) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const isCompany = watch("isCompany");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const isAuthor = watch("isAuthor");

  useEffect(() => {
    setValue("categories", []);
    setValue("categoriesPerson", []);
    setValue("categoriesCompany", []);
  }, [isCompany]);

  const personOptions = [
    { value: "collector", label: "Collector" },
    { value: "advisor", label: "Advisor" },
    { value: "art_critic_curator", label: "Art Critic/Curator" },
    { value: "expert", label: "Expert" },
    { value: "restorer", label: "Restorer" },

    { value: "registrar", label: "Registrar" },
    { value: "photo_service", label: "Photo service" },
    { value: "artisan_service", label: "Artisan Service" },
    { value: "print_service", label: "Print service" },
    { value: "exhibition_planner", label: "Exhibition Planner" },

    { value: "publisher", label: "Publisher" },
    { value: "other_category", label: "Other" },
  ];

  const companyOptions = [
    { value: "collector", label: "Collector" },
    { value: "museum", label: "Museum" },
    { value: "private_institution_foundation", label: "Private Istitution/Foundation" },
    { value: "public_institution", label: "Public istitution" },
    { value: "archive", label: "Archive" },

    { value: "gallery", label: "Gallery" },
    { value: "auction_house", label: "Auction house" },
    { value: "advisor", label: "Advisor" },

    { value: "insurance_company", label: "Insurance company" },
    { value: "broker_seller_agency", label: "Broker/Seller/Agency" },
    { value: "liquidator", label: "Liquidator" },

    { value: "restorer", label: "Restorer" },
    { value: "registrar", label: "Registrar" },
    { value: "photo_service", label: "Photo service" },
    { value: "artisan_service", label: "Artisan Service" },
    { value: "print_service", label: "Print service" },

    { value: "warehouse", label: "Warehouse" },
    { value: "logistics", label: "Logistics" },

    { value: "exhibition_planner", label: "Exhibition Planner" },
    { value: "publisher", label: "Publisher" },
    { value: "other_category", label: "Other" },
  ];

  const authorOptions = [
    { value: "painter", label: "Painter" },
    { value: "sculptor", label: "Sculptor" },
    { value: "designer", label: "Designer" },
    { value: "digital_artist", label: "Digital Artist" },
    { value: "crypto_artist", label: "Crypto Artist" },
    { value: "videomaker", label: "Videomaker" },
    { value: "director", label: "Director" },
    { value: "composer_musician", label: "Composer/Musician" },
    { value: "photographer", label: "Photographer" },
    { value: "carpenter", label: "Carpenter" },
    { value: "artisan", label: "Artisan" },
    { value: "architect", label: "Architect" },
    { value: "writer", label: "Writer" },
    { value: "graphic_illustrator", label: "Graphic/Illustrator" },
    { value: "scriptwriter", label: "Scriptwriter" },
    { value: "scenographer", label: "Scenographer" },
    { value: "goldsmith", label: "Goldsmith" },
    { value: "miniaturist_calligrapher", label: "Miniaturist/Calligrapher" },
    { value: "glassworker", label: "Glassworker" },
    { value: "potter", label: "Potter" },
    { value: "other_author", label: "Other" },
  ];

  return (
    <Stack spacing="4">
      <FieldGroup title={t("Registry Details")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <VStack alignItems="flex-start">
            <FormInputHook name="isCompany" label={t("Is Company")} type="checkbox" />
            <FormInputHook name="fullName" label={t("Full name")} />
            {isCompany && <FormInputHook name="businessName" label={t("Business name")} />}
            {!isCompany && <FormInputHook name="alias" label={t("Alias")} />}
            {!isCompany && !isAuthor && <FormInputHook name="role" label={t("Role")} />}
            <FormInputHook name="webSite" label={t("Web Site")} />
            <FormInputHook name="note" label={t("Note")} />
          </VStack>
          <VStack width="full" align="left">
            <VStack alignItems="flex-start">
              {isCompany && (
                <FormInputHook
                  options={companyOptions}
                  isMulti
                  label={t("Categories")}
                  name="categoriesCompany"
                />
              )}
              {!isCompany && (
                <FormInputHook
                  options={personOptions}
                  isMulti
                  label={t("Categories")}
                  name="categoriesPerson"
                />
              )}

              <FormInputHook name="isAuthor" label={t("Is Author")} type="checkbox" />

              {isAuthor && (
                <VStack alignItems="flex-start">
                  <FormInputHook
                    options={authorOptions}
                    isMulti
                    label={t("Type")}
                    name="authorType"
                  />

                  <HStack justifyContent="space-between">
                    <FormInputHook name="birthDate" label={t("Birth Date")} />
                    <FormInputHook name="birthPlace" label={t("Birth Place")} />
                  </HStack>

                  <HStack justifyContent="space-between">
                    <FormInputHook name="deathDate" label={t("Death Date")} />
                    <FormInputHook name="deathPlace" label={t("Death Place")} />
                  </HStack>

                  <HStack justifyContent="space-between">
                    <FormInputHook name="activityPeriod" label={t("Activity Period")} />
                    <FormInputHook name="activityPlace" label={t("Activity Place")} />
                  </HStack>

                  <HStack justifyContent="space-between">
                    <FormInputHook name="artisticMovement" label={t("Artistic Movement")} />
                    <FormInputHook name="foundation" label={t("Archive/Foundation")} />
                  </HStack>
                </VStack>
              )}
            </VStack>
          </VStack>
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Print information")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <VStack alignItems="flex-start">
            <FormInputHook name="printCode" label={t("Print Code")} />
            <FormInputHook name="printName" label={t("Print name")} />
          </VStack>
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Tax information")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <VStack alignItems="flex-start">
            <FormInputHook name="countryCode" label={t("Country code")} />
            <FormInputHook name="taxId" label={t("Tax ID")} />
            <FormInputHook name="invoiceAddress" type="address" label={t("Billing Address")} />
          </VStack>
        </VStack>
      </FieldGroup>

      <FieldGroup title={t("Contacts")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <VStack alignItems="flex-start">
            <FormInputHook name="phone" label={t("Phone")} />
            <FormInputHook name="mobilePhone" label={t("Mobile phone")} />
            <FormInputHook name="email" label={t("Email")} />
          </VStack>
        </VStack>
      </FieldGroup>

      {isCompany && (
        <FieldGroup title={t("Contacts")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <VStack width="full" align="left">
            <VStack width="full" align="left">
              <CompanyContactForm registryId={registryId} key={registryId} />
            </VStack>
          </VStack>
        </FieldGroup>
      )}

      <FieldGroup title={t("Offices")} subtitle={t("Lorem ipsum dolor sit amet")}>
        <VStack width="full" align="left">
          <VStack width="full" align="left">
            <OfficeForm registryId={registryId} key={registryId} />
          </VStack>
        </VStack>
      </FieldGroup>
    </Stack>
  );
};

export default RegistryFields;
