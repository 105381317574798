import {
  VStack,
  Heading,
  Text,
  Box,
  useDisclosure,
  HStack,
  Button,
  Tabs,
  TabList,
  Tab,
  Badge,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import {
  CopyButton,
  DateDialog,
  DetailsPageSkeleton,
  MoneyInputHook,
  QuickActions,
} from "components/ui";
import AddNotesModal from "components/ui/AddNotesModal";
import {
  MoneyInput,
  useDuplicatePolicyMutation,
  useExtendPolicyMutation,
  useGenerateCertificateMutation,
  useDeletePolicyAssetMutation,
  useGetAllCertificatesByPolicyIdAndIsActiveQuery,
  useGetAllCertificateTemplatesByNameLazyQuery,
  useGetPolicyDocumentsByPolicyIdQuery,
  useGetPolicyNotesByPolicyIdQuery,
  useRenewPolicyMutation,
  useTerminatePolicyMutation,
  useUpdatePolicyMutation,
  useViewPolicyByIdQuery,
  useGenerateSplitCertificateMutation,
  useDeletePolicyMutation,
  useGetAllCertificatesByPolicyIdQuery,
  PolicyAssetNode,
  useGenerateGroupedCertificateMutation,
} from "graphql/queries/generated/queries";
import downloadFile from "helpers/downloadFile";
import parseAmount from "helpers/parseAmount";
import parseDateForInput from "helpers/parseDateForInput";
import { handleMutation, toast } from "middleware/Toaster";
import PolicyCertificatesSummary from "pages/spin/shared/PolicyCertificatesSummary";
import PolicyDocumentsSummary from "pages/spin/shared/PolicyDocumentsSummary";
import PolicyNotesSummary from "pages/spin/shared/PolicyNotesSummary";
import PolicySummary from "pages/spin/shared/PolicySummary";
import OnViewPolicyAssetsTable from "pages/spin/shared/view/OnViewPolicyAssetsTable";
import PolicyBasketHistory from "pages/spin/shared/view/PolicyBasketHistory";
import PolicyHistory from "pages/spin/shared/view/PolicyHistory";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SPIN_ROUTES } from "routes/constants";

interface FromToDates {
  startDate: string;
  endDate: string;
}
export type CertificateMeta = {
  policyNote?: string;
};

const TEPoliciesViewPage = () => {
  const navigate = useNavigate();
  const [selectedObjects, setSelectedObjects] = useState<PolicyAssetNode[] | []>([]);
  const [getOnlyActiveCertificates, setGetOnlyActiveCertificates] = useState<boolean>(true);
  const [certificateMeta, setCertificateMeta] = useState<CertificateMeta>({
    policyNote: "",
  });
  const { isOpen: isRenewOpen, onOpen: onRenewOpen, onClose: onRenewClose } = useDisclosure();
  const { isOpen: isExtendOpen, onOpen: onExtendOpen, onClose: onExtendClose } = useDisclosure();
  const {
    isOpen: isTerminateOpen,
    onOpen: onTerminateOpen,
    onClose: onTerminateClose,
  } = useDisclosure();
  const {
    isOpen: isCertificateNotesOpen,
    onOpen: onCertificateNotesOpen,
    onClose: onCertificateCloseOpen,
  } = useDisclosure();
  const {
    isOpen: isSplittedCertificateNotesOpen,
    onOpen: onSplittedCertificateNotesOpen,
    onClose: onSplittedCertificateClose,
  } = useDisclosure();
  const {
    isOpen: isGroupedCertificateNotesOpen,
    onOpen: onGroupedCertificateNotesOpen,
    onClose: onGroupedCertificateNotesClose,
  } = useDisclosure();

  const {
    isOpen: isDuplicateOpen,
    onOpen: onDuplicateOpen,
    onClose: onDuplicateClose,
  } = useDisclosure();
  const { policyId } = useParams();
  const { t } = useTranslation();

  const [getAllCertificateTemplatesByName] = useGetAllCertificateTemplatesByNameLazyQuery({
    variables: {
      name: "certificate_template_1",
    },
  });

  const [
    renewPolicyMutation,
    { data: renewPolicyData, loading: renewPolicyLoading, error: renewPolicyError },
  ] = useRenewPolicyMutation({ ...handleMutation("Policy renewed!") });
  const [
    extendPolicyMutation,
    { data: extendPolicyData, loading: extendPolicyLoading, error: extendPolicyError },
  ] = useExtendPolicyMutation({ ...handleMutation("Policy extended!") });
  const [
    duplicatePolicyMutation,
    { data: duplicatePolicyData, loading: duplicatePolicyLoading, error: duplicatePolicyError },
  ] = useDuplicatePolicyMutation({ ...handleMutation("Policy duplicated!") });
  const [
    terminatePolicyMutation,
    { data: terminatePolicyData, loading: terminatePolicyLoading, error: terminatePolicyError },
  ] = useTerminatePolicyMutation({ ...handleMutation("Policy terminated!") });
  const [
    deletePolicy,
    { data: deletePolicyData, loading: deletePolicyLoading, error: deletePolicyError },
  ] = useDeletePolicyMutation({ ...handleMutation("Policy deleted!") });

  const [
    deletePolicyAssetMutation,
    {
      data: deletePolicyAssetData,
      loading: deletePolicyAssetLoading,
      error: deletePolicyAssetError,
    },
  ] = useDeletePolicyAssetMutation({ ...handleMutation("Asset removed from policy!") });

  const { data, loading, error, refetch } = useViewPolicyByIdQuery({
    variables: {
      id: policyId,
    },
  });

  const {
    data: certificateSet,
    loading: certificateSetLoading,
    error: certificateSetError,
    refetch: refetchCertificates,
  } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
    variables: {
      policy: policyId,
      isValid: getOnlyActiveCertificates,
    },
  });

  const {
    data: allCertificateSet,
    loading: allCertificateSetLoading,
    error: allCertificateSetError,
    refetch: AllCertificateRefetchCertificates,
  } = useGetAllCertificatesByPolicyIdQuery({
    variables: {
      policy: policyId,
    },
  });

  const [
    generateSplitCertificate,
    {
      data: generateSplitCertificateData,
      error: generateSplitCertificateError,
      loading: generateSplitCertificateLoading,
    },
  ] = useGenerateSplitCertificateMutation({ ...handleMutation("Splitted certificate generated!") });

  const [
    generateGroupedCertificate,
    {
      data: generateGroupedCertificateData,
      error: generateGroupedCertificateError,
      loading: generateGroupedCertificateLoading,
    },
  ] = useGenerateGroupedCertificateMutation({
    ...handleMutation("Generated certificates for selected assets!"),
  });

  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation({ ...handleMutation("Policy updated!") });

  const {
    data: policyDocumentsData,
    loading: policyDocumentsLoading,
    error: policyDocumentsError,
  } = useGetPolicyDocumentsByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const {
    data: policyNotesData,
    loading: policyNotesLoading,
    error: policyNotesError,
  } = useGetPolicyNotesByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const [
    generateCertificate,
    {
      data: generateCertificateData,
      loading: generateCertificateLoading,
      error: generateCertificateError,
    },
  ] = useGenerateCertificateMutation({ ...handleMutation("Certificate generated!") });

  useEffect(() => {
    if (!data?.policy) return;
    const {
      policy: {
        policyNotes: { edges: policyNotesArray },
      },
    } = data;

    if (policyNotesArray.length === 0) return;
    const { content } = policyNotesArray[0]?.node ?? {};
    setCertificateMeta({ policyNote: content });
  }, [data]);

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (!data || !data?.policy) return null;

  const {
    policy: {
      id,
      policyStatus,
      policyCode,
      offerCode,
      policyAssets,
      policyType,
      expiresAt,
      issuedAt,
      isExpired,
      totalInsuredPremium,
    },
  } = data;

  const parsedForTablePolicyAssets = policyAssets.edges.flatMap((e) => {
    const { asset, insuredValue, ...rest } = e.node;

    return {
      ...rest,
      insuredValue: {
        amount: insuredValue?.amount,
        currency: insuredValue?.currency?.code,
      },
      ...asset,
      assetId: asset.id,
      id: rest.id,
    };
  });

  console.log({ parsedForTablePolicyAssets });

  const { allPolicyDocuments } = policyDocumentsData || {};
  const { allPolicyNotes } = policyNotesData || {};

  const deletePolicyAsset = async (id: string) => {
    const { data } = await deletePolicyAssetMutation({
      variables: {
        input: { id },
      },
    });
    await refetch();
  };

  const duplicatePolicy = async () => {
    const { data } = await duplicatePolicyMutation({
      variables: {
        input: {
          policyId: id,
        },
      },
    });
    await refetch();
  };
  const renewPolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await renewPolicyMutation({
      variables: {
        input: {
          policyId: id,
          startDate,
          endDate,
          totalInsuredPremium,
        },
      },
    });

    await refetch();
  };
  const extendPolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await extendPolicyMutation({
      variables: {
        input: {
          policyId: id,
          endDate,
          totalInsuredPremium,
        },
      },
    });

    await refetch();
  };

  const terminatePolicy = async ({
    startDate,
    endDate,
    totalInsuredPremium,
  }: FromToDates & { totalInsuredPremium: MoneyInput }) => {
    await terminatePolicyMutation({
      variables: {
        input: {
          policyId: id,
          endDate,
          totalInsuredPremium,
        },
      },
    });
    await refetch();
  };

  const generateSplittedCertificates = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    ) {
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: No certificate templates has been set!`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;

    const policyAssetsId = selectedObjects.map(({ id }) => id);
    const data = await generateSplitCertificate({
      variables: {
        input: {
          policyAssetsId,
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
          metafields: certificateMeta,
        },
      },
    });

    await refetchCertificates();
    setSelectedObjects([]);
  };

  const generateGroupedCertificates = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    ) {
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: No certificate templates has been set!`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;

    const policyAssetsId = selectedObjects.map(({ id }) => id);
    const data = await generateGroupedCertificate({
      variables: {
        input: {
          policyAssetsId,
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
          metafields: certificateMeta,
        },
      },
    });

    await refetchCertificates();
    setSelectedObjects([]);
  };

  const generateCertificates = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    ) {
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: Missing certificate template!`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;

    await generateCertificate({
      variables: {
        input: {
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
          metafields: certificateMeta,
        },
      },
    });

    await refetchCertificates();
  };

  const onDeletePolicy = async () => {
    const { data } = await deletePolicy({
      variables: {
        input: {
          id: policyId,
        },
      },
    });

    if (data) {
      navigate(SPIN_ROUTES.SPIN);
    }
  };

  const QUICK_ACTIONS = [
    {
      name: t("Edit"),
      href: `${SPIN_ROUTES.POLICY_TE}/edit/${policyId}`,
      intent: "primary",
    },
    {
      name: t("Duplicate"),
      action: duplicatePolicy,
      loading: duplicatePolicyLoading,
    },
    {
      name: t("Extend"),
      action: onExtendOpen,
      loading: extendPolicyLoading,
    },
    {
      name: t("Generate certificates"),
      action: onCertificateNotesOpen,
      loading: generateCertificateLoading,
    },

    {
      name: t("Terminate"),
      action: onTerminateOpen,
      intent: "warn",
      disabled: isExpired,
      loading: terminatePolicyLoading,
    },
    {
      name: t("Delete"),
      action: onDeletePolicy,
      intent: "danger",
      loading: deletePolicyLoading,
    },
  ];

  const openAllCertificates = () => {
    certificateSet?.allCertificates?.edges?.map(async ({ node }) => downloadFile(node?.file));
  };

  const issuedAtDate = new Date(issuedAt);
  const expiresAtDate = new Date(expiresAt);

  const parsedIssuedAtDate = parseDateForInput(issuedAtDate);
  const parsedExpiresAtDate = parseDateForInput(expiresAtDate);

  const parsedIssuedAt = issuedAt.split("+")[0];
  const parsedExpiresAt = expiresAt.split("+")[0];

  const diffTime = Math.abs(
    new Date(parsedExpiresAt).getTime() - new Date(parsedIssuedAt).getTime()
  );

  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  const renewIssuedAt = issuedAtDate.setDate(issuedAtDate.getDate() + diffDays);
  const renewExpiresAt = expiresAtDate.setDate(expiresAtDate.getDate() + diffDays);

  const parsedRenewIssuedAtDate = parseDateForInput(new Date(renewIssuedAt));
  const parsedRenewExpiresAtDate = parseDateForInput(new Date(renewExpiresAt));

  const policyCertificates = certificateSet?.allCertificates?.edges?.map(({ node }) => node);
  const pastCertificateMetas = allCertificateSet?.allCertificates?.edges
    .map(({ node: { metafields, createdAt, creationDate, createdBy } }) => {
      try {
        const parsedMetafields = JSON.parse(metafields as string) as CertificateMeta;
        if (!parsedMetafields?.policyNote || parsedMetafields?.policyNote === "") return null;
        return {
          ...parsedMetafields,
          createdAt,
          createdBy,
          creationDate,
        };
      } catch {
        toast({
          status: "warning",
          title: t("Couldn't retrieve past certificate notes"),
        });
        return null;
      }
    })
    .filter((e) => e);

  console.log({ pastCertificateMetas });

  const BATCH_ACTIONS = [
    {
      name: <Trans>Generate certificates for selected assets</Trans>,
      loading: generateGroupedCertificateLoading,
      action: (selectedRows: { original: PolicyAssetNode }[]) => {
        setSelectedObjects(selectedRows.map(({ original }) => original));
        onGroupedCertificateNotesOpen();
      },
    },
    {
      name: <Trans>Generate ONE certificate per asset</Trans>,
      loading: generateSplitCertificateLoading,
      action: (selectedRows: { original: PolicyAssetNode }[]) => {
        setSelectedObjects(selectedRows.map(({ original }) => original));
        onSplittedCertificateNotesOpen();
      },
    },
  ];

  return (
    <>
      <Box backgroundColor="transparent">
        <HStack justifyContent="space-between" alignItems="flex-start" w="full">
          <VStack alignItems="flex-start">
            <Heading>
              <Trans>Policy #</Trans>
              {policyCode}
            </Heading>
            <Text fontSize="lg" variant="muted">
              <Trans>Offer #</Trans>
              {offerCode ?? ""} <CopyButton value={offerCode ?? ""} />
            </Text>
            <HStack>
              <Badge variant={policyType}>
                <Trans>{policyType}</Trans>
              </Badge>
              <Badge variant={policyStatus}>
                {policyStatus === "PENDING" && t("Offer")}
                {policyStatus === "APPROVED" && t("Policy")}
              </Badge>
              <Badge variant={isExpired ? "EXPIRED" : "CURRENT"}>
                {isExpired ? t("Expired") : t("Current")}
              </Badge>
            </HStack>
          </VStack>
          <QuickActions noTitle actions={QUICK_ACTIONS} />
        </HStack>

        <Tabs colorScheme="brand" variant="enclosed" mt="8">
          <TabList>
            <Tab>
              <Trans>Summary</Trans>
            </Tab>
            <Tab>
              <Trans>Objects</Trans>
            </Tab>
            <Tab>
              <Trans>Documents</Trans>
            </Tab>
            <Tab>
              <Trans>Certificates</Trans>
            </Tab>
            <Tab>
              <Trans>History</Trans>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel py={6} px="0">
              <PolicySummary policy={data.policy} actions={QUICK_ACTIONS} />
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <Heading size="md">
                  <Trans>Objects</Trans>
                </Heading>
                <Text variant="muted">
                  <Trans>Objects subtitle in policy view page</Trans>
                </Text>

                {/* TODO: refactor this */}
                <OnViewPolicyAssetsTable
                  data={parsedForTablePolicyAssets}
                  type="TEMPORARY_EXHIBITION"
                  mode="view"
                  batchActions={BATCH_ACTIONS}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <Heading size="md">
                  <Trans>Notes</Trans>
                </Heading>
                <PolicyNotesSummary policyNotes={allPolicyNotes?.edges.map(({ node }) => node)} />
              </VStack>
              <VStack my={16} align="flex-start">
                <Heading size="md">
                  <Trans>Documents</Trans>
                </Heading>
                <PolicyDocumentsSummary
                  policyDocuments={allPolicyDocuments?.edges.map(({ node }) => node)}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack align="flex-start">
                <HStack>
                  <Heading size="md">
                    <Trans>{t("Emitted certificates")}</Trans>
                  </Heading>
                  <Button variant="ghost" onClick={openAllCertificates}>
                    <Text decoration="underline">
                      <Trans>Download all</Trans>
                    </Text>
                  </Button>
                  <Button
                    variant="ghost"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      setGetOnlyActiveCertificates((prevState) => !prevState);
                      await refetchCertificates({
                        isValid: getOnlyActiveCertificates,
                        policy: policyId,
                      });
                    }}
                  >
                    <Text decoration="underline">
                      {getOnlyActiveCertificates ? (
                        <Trans>Show only invalid</Trans>
                      ) : (
                        <Trans>Show only valid</Trans>
                      )}
                    </Text>
                  </Button>
                </HStack>
                <PolicyCertificatesSummary
                  policyCertificates={policyCertificates}
                  refetchCertificates={refetchCertificates}
                  loading={certificateSetLoading}
                  type={policyType as "TEMPORARY_EXHIBITION" | "PERMANENT_COLLECTION"}
                />
              </VStack>
            </TabPanel>
            <TabPanel py={6} px="0">
              <VStack spacing="8" align="flex-start" w="full">
                <PolicyHistory id={id} />
                <PolicyBasketHistory id={id} />
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <DateDialog
        isOpen={isRenewOpen}
        onClose={onRenewClose}
        onOpen={onRenewOpen}
        title="Renew"
        message="If dates aren't updated, we'll renew this policy using the original range you set up."
        defaultStartDate={parsedRenewIssuedAtDate}
        defaultEndDate={parsedRenewExpiresAtDate}
        minDate={parsedRenewIssuedAtDate}
        loading={renewPolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={renewPolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>{" "}
          </Text>
        </VStack>
      </DateDialog>
      <DateDialog
        isOpen={isExtendOpen}
        onClose={onExtendClose}
        onOpen={onExtendOpen}
        title="Extend policy expiration"
        message="Set a new policy expiration that extends your policy"
        defaultStartDate={parsedIssuedAtDate}
        defaultEndDate={parsedExpiresAtDate}
        disabledStartDate
        loading={extendPolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={extendPolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>
          </Text>
        </VStack>
      </DateDialog>
      <DateDialog
        isOpen={isTerminateOpen}
        onClose={onTerminateClose}
        onOpen={onTerminateOpen}
        title="Terminate policy"
        message="Set a new expiration date prior to the one of your policy"
        defaultStartDate={parsedIssuedAtDate}
        defaultEndDate={parsedExpiresAtDate}
        maxDate={parsedExpiresAtDate}
        disabledStartDate
        loading={terminatePolicyLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={terminatePolicy}
        extraDefaultValue={{
          totalInsuredPremium: {
            amount: totalInsuredPremium?.amount,
            currency: totalInsuredPremium.currency?.code,
          },
        }}
      >
        <VStack align="flex-start">
          <MoneyInputHook name="totalInsuredPremium.amount" label="totalInsuredPremium" />
          <Text>
            <Trans>
              You can specify a new premium while updating the policy, previous premium was&nbsp;
              {parseAmount(totalInsuredPremium)}
            </Trans>
          </Text>
        </VStack>
      </DateDialog>
      <AddNotesModal<CertificateMeta>
        title="Change certificate notes"
        message="Here you can edit default notes on your certificate"
        isInputTextArea
        isOpen={isCertificateNotesOpen}
        onOpen={onCertificateNotesOpen}
        onClose={onCertificateCloseOpen}
        onSubmit={generateCertificates}
        fields={certificateMeta}
        setFields={setCertificateMeta}
        submitActionTitle="Generate certificate"
        pastNotes={pastCertificateMetas}
      />
      <AddNotesModal<CertificateMeta>
        title="Change certificate notes"
        message="Here you can edit default notes on your certificate"
        isInputTextArea
        isOpen={isSplittedCertificateNotesOpen}
        onOpen={onSplittedCertificateNotesOpen}
        onClose={onSplittedCertificateClose}
        onSubmit={generateSplittedCertificates}
        fields={certificateMeta}
        setFields={setCertificateMeta}
        submitActionTitle="Generate certificate"
        pastNotes={pastCertificateMetas}
      />
      <AddNotesModal<CertificateMeta>
        title="Change certificate notes"
        message="Here you can edit default notes on your certificate"
        isInputTextArea
        isOpen={isGroupedCertificateNotesOpen}
        onOpen={onGroupedCertificateNotesOpen}
        onClose={onGroupedCertificateNotesClose}
        onSubmit={generateGroupedCertificates}
        fields={certificateMeta}
        setFields={setCertificateMeta}
        submitActionTitle="Generate certificate"
        pastNotes={pastCertificateMetas}
      />
    </>
  );
};

export default TEPoliciesViewPage;
