/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Heading, HStack, VStack, Skeleton, Text, Image } from "@chakra-ui/react";
import {
  BorderedBox,
  CopyButton,
  QuickActions,
  Field,
  DetailsPageSkeleton,
  SidebarInfo,
  Table,
  NoDataPlaceholder,
} from "components/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit, BiExport } from "react-icons/bi";
import {
  useGetExhibitionByIdQuery,
  useDetachAssetFromExhibitionMutation,
  useExportExhibitionPdfMutation,
} from "graphql/queries/generated/queries";
import {
  CUSTOM_FIELDS,
  CUSTOM_COLUMNS,
  HIDDEN_FIELDS,
} from "pages/assets/shared/externalpagesassetconstants/DatatableConstants";
import getColumns from "helpers/getColumns";
import { handleMutation } from "middleware/Toaster";
import downloadFile from "helpers/downloadFile";
import parseDate from "helpers/parseDate";

const ExhibitionViewPage = () => {
  const { t } = useTranslation();
  const { exhibitionId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetExhibitionByIdQuery({
    variables: { id: exhibitionId ?? "" },
  });

  const [detachAssetFromExhibition, { data: detachAssetData }] =
    useDetachAssetFromExhibitionMutation({
      ...handleMutation("Asset removed from exhibition!"),
    });

  const [
    exportExhibitionPdf,
    {
      data: exportExhibitionPdfData,
      loading: exportExhibitionPdfLoading,
      error: exportExhibitionPdfError,
    },
  ] = useExportExhibitionPdfMutation({
    ...handleMutation("Exhibition exported!"),
  });

  const onGeneratePdf = async () => {
    const { data } = await exportExhibitionPdf({
      variables: {
        input: {
          exhibitionId,
        },
      },
    });

    const {
      exportExhibitionPdf: { url },
    } = data;

    await downloadFile(url);
    // window.open(url);
  };

  if (loading) return <DetailsPageSkeleton loading={loading} />;
  if (error) return <NoDataPlaceholder title={error.message} />;

  if (!data || !data.exhibition) return null;

  const {
    exhibition: {
      name,
      description,
      eventFrom,
      eventTo,
      notes,
      image,
      permanent,
      curator,
      organizer,
      hostEntity,
      id,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
    },
  } = data;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Exhibition"),
      href: `/mgmt/exhibitions/edit/${id}`,
      icon: <BiEdit />,
    },
    {
      name: t("Export exhibition"),
      action: onGeneratePdf,
      icon: <BiExport />,
      loading: exportExhibitionPdfLoading,
    },
  ];

  const assets = data.exhibition.assetSet.edges;

  let dataToGetColumns;
  let columns;

  if (assets && assets.length > 0) {
    dataToGetColumns = assets[0].node;

    columns = getColumns({
      data: dataToGetColumns,
      customFields: CUSTOM_FIELDS,
      customColumns: CUSTOM_COLUMNS,
    });
  }

  const onDetachAssetFromExhibition = async (assetId: string) => {
    await detachAssetFromExhibition({
      variables: {
        input: {
          id,
          assetId,
        },
      },
    });

    return "";
  };

  return (
    <Skeleton isLoaded={!loading}>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <VStack alignItems="flex-start" mb={8}>
          <Heading>{name}</Heading>
          <Text fontSize="lg" variant="muted">
            #{exhibitionId ?? ""} <CopyButton value={exhibitionId ?? ""} />
          </Text>
          <Text>{permanent ? "Permanent" : ""}</Text>
        </VStack>

        <QuickActions noTitle actions={QUICK_ACTIONS} />
      </HStack>

      <VStack spacing="6" align="flex-start" w="full">
        <HStack flex="1" w="full" spacing="6" align="flex-start">
          <VStack flex="3" direction="column" spacing="6">
            <BorderedBox title="Exhibition Details" subtitle="Lorem ipsum dolor sit amet">
              <HStack w="100%" my={4}>
                <Field label={t("Host entity")} value={hostEntity?.fullName} />
                <Field label={t("Curator")} value={curator?.fullName} />
                <Field label={t("Organizer")} value={organizer?.fullName} />
                <Field label={t("Event from")} value={parseDate(eventFrom)} />
                <Field label={t("Event to")} value={parseDate(eventTo)} />
              </HStack>
              <Field label={t("Description")} value={description} />
              <Field label={t("Notes")} value={notes} />
            </BorderedBox>

            <BorderedBox title="Image" subtitle="Lorem ipsum dolor sit amet">
              <Image src={image} boxSize="300px" />
            </BorderedBox>

            <BorderedBox title="Objects" subtitle="Lorem ipsum dolor sit amet">
              <Table
                hiddenColumns={HIDDEN_FIELDS}
                columns={columns}
                data={assets.flatMap((edge) => edge?.node)}
                deleteAction={onDetachAssetFromExhibition}
              />
            </BorderedBox>
          </VStack>
          <SidebarInfo
            createdBy={createdBy}
            updatedBy={updatedBy}
            createdAt={createdAt}
            updatedAt={updatedAt}
          />
        </HStack>
      </VStack>
    </Skeleton>
  );
};

export default ExhibitionViewPage;
